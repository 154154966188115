 body{
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #F3F6F9;
  font-family: 'Source Sans Pro', 'sans-serif';
  font-family: 'Avenir'!important;
  overflow: hidden;
}
html {
  overflow-x: scroll;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
a:hover{
  text-decoration: none;
}
a {
  display: inline-block;
}
ul{
  margin:0;
  padding:0;
  list-style: none;
}
button {
  padding: 0;
  border: none;
  outline: none !important;
}

/*Start Humber Icon Design Css*/
.admin-page .mobile-menu-icon {
  display: none;
  position: relative;
}
.admin-page .mobile-menu-icon::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 2px;
  height: 54px;
  right: -6px;
  top: -17px;
}
.admin-page .mobile-search-icon {
  width: 18px;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9999;
  display: none;
}
.admin-page .mobile-search-icon::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 2px;
  height: 54px;
  left: -20px;
  top: -12px;
}
.admin-page .mobile-search-icon img {
  width: 18px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 4px;
}
.admin-page .all-p-humber {
  width: 30px;
  height: 18px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 99;
}
.admin-page .all-p-humber span {
  display: block;
  position: absolute;
  height: 3px;
  width: 25px;
  background: #A6ADB1;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
/ Icon 3 /
.admin-page .all-p-humber span:nth-child(1) {
  top: 6px;
}
.admin-page .all-p-humber span:nth-child(2), .all-p-humber span:nth-child(3) {
  top: 8px;
}
.admin-page .all-p-humber span:nth-child(4) {
  top: 16px;
}
.admin-page .all-p-humber.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.admin-page .all-p-humber.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.admin-page .all-p-humber.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.admin-page .all-p-humber.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
/*End Humber Icon Design Css*/

/*================================================================================================
  ====                                    START HOME PAGE CSS                                 ====
  ================================================================================================*/

/*=============================================================================
  ====                        Start Header Area Css                        ====
  =============================================================================*/
  .admin-page .header-top-area {
    background: #FFFFFF;
    padding-bottom: 5px;
  }
 .admin-page .header-top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .header-top-left-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    flex: 0 0 39%;
    flex: 0 0 38%;
  }
  .admin-page  .header-top-center-content {
    -webkit-box-flex: 0;
	 flex: 0 0 22%;
	 display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
 .admin-page  .header-top-right-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    flex: 0 0 39%;
    flex: 0 0 40%;
  }
  .admin-page .header-top-center-content img{
	  margin-left:10px;cursor:pointer;
  }
 .admin-page  .header-logo-dropdown::after {
    content: "";
    position: absolute;
    background: #E4E6EF;
    width: 1px;
    height: 65px;
    right: 0;
    top: 0;
  }
 .admin-page  .header-logo-dropdown img {
    width: 250px;
    height: auto;
  }
  .admin-page .header-top-left-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .header-logo-dropdown {
    max-width: 290px;
    max-width: 245px;
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .admin-page .dropdown-toggle::after {
    display: none;
  }
  .admin-page .down-icon img {
    width: 12px;
    height: 8px;
    right: 22px;
    top: 25px;
    position: absolute;
  }
  .admin-page .dropdown-item.width {
    width: 275px;
    margin: 8px 0;
  }
 .admin-page  .dropdown-item.width:hover {
    background: #DBE6EE;
  }
  .admin-page .dropdown-item:hover {
    background-color: #DBE6EE;
  }
  .admin-page .dropdown-menu {
    padding: 0 !important;
  }
 .admin-page .dropdown-item {
    font-weight: 400;
    font-size: 14px;
    font-size: 13px;
    color: #3F4254;
    text-transform: inherit;
    padding: 12px 20px;
  }
  .admin-page .dropdown-menu.new-menu a {
    padding: 12px 20px;
  }
  .admin-page .header-dropdown::after {
    content: '';
    position: absolute;
    background: url('/media/home/down-arrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    right: 15px;
    top: 26px;
    width: 12px;
    height: 8px;
    pointer-events: none;
  }
 .admin-page .header-dropdown select {
    -webkit-appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 275px;
    height: 52px;
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .admin-page .header-dropdown select option {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    cursor: pointer;
  }
 .admin-page .header-flag-dropdown {
    padding-left: 15px;
    padding-left: 0px;
	padding-right: 10px;
  }
  /* Start Custom Flug Select Option Css */
 .admin-page .vodiapicker{
    display: none; 
  }
  .admin-page #a{
    padding-left: 0px;
  }
  .admin-page #a img, .btn-select img {
    width: 14px!important;
    height: 10px!important;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -5px;
  }
  .admin-page #a li {
    font-weight: 400;
    font-size: 13px;
    color: #375F7F;
    text-transform: capitalize;
    padding: 7px 10px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
 .admin-page  #a li:hover{
   background-color: #F4F3F3;
 }
 .admin-page #a li span, .btn-select li span {
  margin-left: 15px;
  margin-left: 5px!important;
  cursor: pointer;
  line-height: 20px;
  font-size:13px;
}
/* item list */
.admin-page .b {
  display: none;
  max-width: 350px;
  -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.1);
  box-shadow: 0 6px 10px rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,.15);
  position: absolute;
  width: 105px;
  z-index: 2;
}
.admin-page .open{
  display: show !important;
}
.admin-page .btn-select {
  font-weight: 400;
  font-size: 14px;
  color: #375F7F;
  text-transform: capitalize;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: none;
  width: 105px;
  position: relative;
  margin-left:-10px;
}
.admin-page .btn-select::after {
  content: "";
  position: absolute;
  background: url('/media/home/down-arrow.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 0;
  top: 14px;
  width: 12px;
  height: 8px;
}
.admin-page .btn-select li{
  list-style: none;
  float: left;
  padding-bottom: 0px;
}
.admin-page .btn-select:hover li{
  margin-left: 0px;
}
.admin-page .btn-select:focus{
 outline:none;
}
/* End Custom Flug Select Option Css */

/* Start heading code*/
.admin-page .dropdown-item.width-two:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #DBE6EE;
}
.admin-page .dropdown-item.width-two {
  width: 223px;
  margin: 8px 0;
}
.admin-page .dropdown-item.width-two span {
  background: #05233B;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 8px;
}
/*End Heading code*/
.admin-page .profile-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .profile-text {
  text-align: left;
  margin-left: 10px;
}
.admin-page .profile-image img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.admin-page .profile-text h5 {
  font-weight: 400;
  font-size: 15px;
  color: #05233B;
  text-transform: capitalize;
}
.admin-page .profile-text p {
  font-weight: 400;
  font-size: 12px;
  color: #707070;
  text-transform: capitalize;
}
.admin-page #dropdownMenuButton {
  background: transparent;
}
.admin-page .header-top-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .header-profile-dropdown {
  padding: 0 42px 0 30px;
  padding: 0 42px 0 10px;
  position: relative;
}
.admin-page .header-profile-dropdown::before {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 1px;
  height: 65px;
  left: 0;
  top: -12px;
}
.admin-page .header-profile-dropdown::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 1px;
  height: 65px;
  right: 0;
  top: -12px;
}
.admin-page .help-contetn a span {
  font-weight: 300;
  font-size: 12px;
  color: #05233B;
  text-transform: capitalize;
}
.admin-page .help-contetn img {
  width: 12px;
  height: auto;
  margin-top: -5px;
}
.admin-page .help-contetn {
  padding-left: 30px;
  padding-left: 10px;
    display: flex;
    justify-content: space-between;
}
.admin-page .dropdown-item.width-two {
  width: 223px;
}
.admin-page .dropdown-menu.adjust {
  top: 13px !important;
  left: -30px !important;

}
.admin-page .down-icon-two img {
  width: 12px;
  height: 8px;
  right: -22px;
  top: 5px;
  top: 12px;
  position: absolute;
}
.admin-page .header-logo-dropdown .down-icon-two img {
  width: 12px;
  height: 8px;
  right: revert;
  top: 5px;
  position: absolute;
}
/* Style the tab content */
.admin-page .tabcontent {
  display: none;
} 
/* Style the tab content */
/* Start Header Bottom Area Css */
.admin-page .header-bottom-area {
  background: #FAFBFC;
}
.admin-page .header-bottom-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .header-bottom-content ul li a,.header-bottom-content ul li button.menu-drop,#mu_accordion li .link a {
  font-weight: 400;
  font-size: 14px;
  color: #05233B;
  text-transform: capitalize;
  padding: 18px 15px;
  position: relative;
}
.admin-page #mu_accordion li .link a {
 padding: 0;
 display: block;
}
.admin-page .tablinks i {
  top: 5px !important;
}
.admin-page .header-bottom-content ul li button.menu-drop {
  padding-right: 30px;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
}
.admin-page .header-bottom-content ul li button.menu-drop:hover {
  color: #0078BF;
  border-bottom: 3px solid #0078BF;
}
.admin-page .header-bottom-content ul li a.actives,.admin-page .header-bottom-content ul li a:hover {
  color: #0078BF;
}
.admin-page .header-bottom-content ul li a:hover::after,.admin-page .header-bottom-content ul li button.menu-drop:hover::after {
  content: '';
  position: absolute;
  background: #0078BF;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.admin-page .header-bottom-content ul li a.actives::after{
  content: '';
  position: absolute;
  background: #0078BF;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.admin-page .header-bottom-content ul li a img,.admin-page .header-bottom-content ul li button.menu-drop img,.admin-page #mu_accordion li .link img {
  height: 14px;
  width: auto;
  margin-top: -7px;
  margin-right: 2px;
}
.admin-page .header-bottom-content ul li a span,.mu_accordion li .link a span {
  background: #145B94;
  font-weight: 400;
  font-size: 10px;
  color: #FFF;
  padding: 1px 5px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}
.admin-page .down-icon-three img {
  width: 12px !important;
  height: 8px !important;
  right: 10px;
  bottom: 24px;
  position: absolute;
}
.admin-page .down-icon-three.new-class img {
  bottom: 20px;
}
/* End Header Bottom Area Css */

/* Start Home Text Area Css */
.admin-page .home-text-area {
  background: url('/media/home/home-text.png');
  padding: 18px 0;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #05233B;
}
.admin-page .home-text-content h4 {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 1.2;
}
/* End Home Text Area Css */

/*=============================================================================
  ====                         End Header Area Css                         ====
  =============================================================================*/

/*=============================================================================
  ====                     Start Home Progress Area Css                    ====
  =============================================================================*/
  .admin-page .home-progress-area {
    background: #F3F6F9;
    padding-bottom: 25px;
  }
  .admin-page .home-progress-content {
    padding-top: 30px;
  }
  .admin-page .top-progress-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .single-top-progress-area {
    background: url('/media/home/background_image.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 24.75%;
    border: 3px solid transparent;
    cursor: pointer;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    position: relative;
  }
 .admin-page .single-top-progress-area:hover {
   border: 3px solid #006365;
 }
 .admin-page .top-progress-area .single-top-progress-area:nth-child(2) {
  background: url('/media/home/background-image-two.png');
  background: url('/media/home/background-image-two_a2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.admin-page .single-top-progress-heading-text p {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 1.2;
}
.admin-page .single-top-progress-count-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 15px;
}
.admin-page .single-top-pera-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .single-top-progress-count-text h1,.single-top-progress-count-text span {
  font-weight: 900;
  font-size: 28px;
  color: #FFFFFF;
  text-transform: inherit;
  line-height: 1;
}
.admin-page .single-top-progress-count-text h5 {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: lowercase;
  line-height: 1.2;
  margin-left: 8px;
}
.admin-page .single-top-progress-last-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .single-top-progress-last-text .single-top-progress-count-text:nth-child(2) {
  margin-left: 15px;
}
/* Start All Progressbar Css */
.admin-page .active-progress {
  width: 215px;
  height: 7px;
  background: #E4E6EF;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 8px;
}
.admin-page .myclassroom-single-left-cross-progress {
  width: 150px;
  height: 7px;
  background: #E4E6EF;
  border-radius: 5px;
}
.admin-page .barra-nivel {
  height: 7px;
  background: #709BBE;
  width: 1px;
  -webkit-transition: width 2s ease;
  -o-transition: width 2s ease;
  transition: width 2s ease;
  border-radius: 5px;
}
/* End All Progressbar Css */

/* Start MyClassroom Area Css */
.admin-page .myclassroom-area {
  padding-top: 20px;
}
.admin-page .myclassroom-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-header-left p {
  font-weight: 300;
  font-size: 18px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.admin-page .myclassroom-header-left span {
  font-weight: 400;
  font-size: 13px;
  color: #05233B;
  text-transform: inherit;
  background: #DBE6EE;
  padding: 0 10px;
  border-radius: 10px;
  margin-left: 15px;
}
.admin-page .myclassroom-header-single p {
  font-weight: 400;
  font-size: 14px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.admin-page .myclassroom-header-single img {
  height: 14px;
  width: auto;
  margin-left: 25px;
  margin-top: -6px;
}
.admin-page .myclassroom-content {
  background: #FFFFFF;
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.2);
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  border-radius: 6px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-single-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}
.admin-page .myclassroom-single-left-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 62%;
  flex: 0 0 62%;
  cursor: pointer;
  padding: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.admin-page .myclassroom-single-right-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  padding: 20px;
}
.admin-page .myclassroom-single-left-content:hover {
  background: #E4E6E7;
}
.admin-page .myclassroom-single-left-content-image {
  position: relative;
  width: 70px;
}
.admin-page .myclassroom-single-left-content-image img {
  width: 50px;
  height: auto;
  border-radius: 3px;
}
.admin-page .myclassroom-single-left-new-button button {
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
  background: #00A2A7;
  padding: 2px 7px;
  color: #FFFFFF;
  border-radius: 3px;
  position: absolute;
  right: 18px;
  bottom: 0px;
}
.admin-page .myclassroom-single-right-content-text {
  width: 185px;
}
.admin-page .myclassroom-single-left-content-top-text h1 {
  font-weight: 300;
  font-size: 18px;
  color: #181C32;
  text-transform: inherit;
}
.admin-page .myclassroome-single-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* Start Course Progressbar Css */
.admin-page .meter {
  background: #E4E6EF;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  display: block;
  height: 7px;
  position: relative;
  width: 150px;
}
.admin-page .meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: inset 0 2px 9px rgba(255,255,255,0.3) inset 0 -2px 6px rgba(0,0,0,0.4);
  box-shadow: inset 0 2px 9px rgba(255,255,255,0.3) inset 0 -2px 6px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
  -webkit-transition: width 2s ease-out;
  -o-transition: width 2s ease-out;
  transition: width 2s ease-out;
  background: #00A2A7;
}

.red > span {
  background-color: #f0a3a3;
  background-image: -o-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0a3a3), to(#f42323));
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
}
.cadetblue >span {
  background-color: #5f9ea0;
  background-image: -o-linear-gradient(top, #0ff, #1e90ff);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0ff), to(#1e90ff));
  background-image: linear-gradient(to bottom, #0ff, #1e90ff);
}
/* End Course Progressbar Css */

.admin-page .myclassroom-single-left-content-top-text p {
  font-weight: 500;
  font-size: 11px;
  color: #3E3F42;
  text-transform: capitalize;
}
.admin-page .myclassroom-single-left-content-course-progress p {
  font-weight: 400;
  font-size: 10px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.admin-page .course-progress-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-single-left-content-course-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.admin-page .course-progress-right h5, .course-progress-right span {
  font-weight: 400;
  font-size: 10px;
  color: #3F4254;
  text-transform: inherit;
  line-height: 9px;
}
.admin-page .course-progress-left {
  padding-top: 5px;
}
.admin-page .profile-left-section-content img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.admin-page .profile-right-section-content p {
  font-weight: 500;
  font-size: 14px;
  color: #3F4254;
  text-transform: capitalize;
  line-height: 10px;
}
.admin-page .profile-right-section-content span {
  font-weight: 400;
  font-size: 12px;
  color: #3F4254;
  text-transform: capitalize;
  line-height: normal;
}
.admin-page .profile-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}
.admin-page .profile-right-section-content {
  margin-top: 8px;
  margin-left: 10px;
}
.admin-page .single-myclass-right-content span {
  background: #05233B;
  padding: 1px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 6px;
}
.admin-page .single-myclass-right-content.myclassroom-slider span {
  padding: 0 8px;
}
.admin-page .single-myclass-right-content img {
  width: 16px !important;
  height: auto;
}
.admin-page .myclassroom-single-right-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .single-myclass-right-content {
  width: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
}
.admin-page .single-myclass-right-content.myclassroom-slider {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .myclassroom-single-right-right-content .dropdown button {
  font-weight: 400;
  font-size: 12px;
  color: #0078BF;
  border: 1px solid #0078BF;
  padding: 4px 15px;
  border-radius: 25px;
  position: relative;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.admin-page .myclassroom-single-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .dropdown-menu.course {
  right: 0 !important;
  left: inherit !important;
}
/* End MyClassroom Area Css */
.admin-page .see-all-class-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: center;
  display: block;
  background: #FFFFFF;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 5px;
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.2);
  box-shadow: 0 0 4px rgba(0,0,0,.2);
}
/* Start Inbox Messages Area Css */
.admin-page .inbox-messages-area {
  background: #B2D8EF;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.admin-page .inbox-message-left-header h1 {
  font-weight: 300;
  font-size: 18px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.admin-page .home-progress-right-content {
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.2);
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  border-radius: 5px;
}
.admin-page .single-inbox-messages-content {
  background: #FFFFFF;
  padding: 10px 20px;
  border-bottom: 1px solid #EAEDF3;
}
.admin-page .inbox-message-left-header a {
  font-weight: 500;
  font-size: 12px;
  color: #145B94;
  text-transform: capitalize;
  line-height: 1.2;
}
.admin-page .inbox-message-left-header a span {
  background: #145B94;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 4px;
}
.admin-page .inbox-message-right-header img {
  width: 18px;
  height: auto;
}
.admin-page .inbox-message-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.admin-page .single-inbox-messages-content p {
  font-weight: 500;
  font-size: 12px;
  color: #3E3F42;
  text-transform: inherit;
}
.admin-page .single-inbox-messages-content p span {
  color: #983E00;
}
.admin-page .single-inbox-date-button p {
  font-weight: 400;
  font-size: 10px;
  color: #3E3F43;
  text-transform: inherit;
  line-height: normal;
}
.admin-page .single-inbox-date-button.announcement a,.admin-page .single-inbox-date-button a {
  font-weight: 400;
  font-size: 11px;
  color: #FFFFFF;
  background: #C55A11;
  padding: 3px 10px;
  border-radius: 5px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.admin-page .single-inbox-date-button a {
  background: #E4E6EF;
  padding: 3px 15px;
  color: #3F4254;
}
.admin-page .single-inbox-date-button a:hover {
  background: #C55A11;
  color: #FFFFFF;
}
.admin-page .single-inbox-date-button.announcement a:hover {
  background: #E4E6EF;
  color: #3F4254;
}
.admin-page .single-inbox-date-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 5px;
}
.admin-page .inbox-messages-content .single-inbox-messages-content:nth-child(1) {
  position: relative;
}
.admin-page .inbox-messages-content .single-inbox-messages-content:nth-child(1)::after {
  content: "";
  position: absolute;
  background: #C55A11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.admin-page .inbox-messages-content .single-inbox-messages-content:nth-child(3) {
  position: relative;
}
.admin-page .inbox-messages-content .single-inbox-messages-content:nth-child(3)::after {
  content: "";
  position: absolute;
  background: #C55A11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.admin-page .view-all-message a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: center;
  display: block;
  background: #FFFFFF;
  padding: 15px 20px;
  border-radius: 5px;
}
/* End Inbox Messages Area Css */

/*=============================================================================
  ====                      End Home Progress Area Css                     ====
  =============================================================================*/
 .admin-page .upcoming-area {
    background-color: #f3f6f9;
    padding-bottom: 90px;
  }
 .admin-page .upcoming-area tbody td {
    border-right: 1px solid #EAEDF3;
    height: 400px;
    padding: 0;
    border-bottom: 1px solid #EAEDF3;
  }
  .admin-page .upcoming-area .monday {
    background-color: #b2d8ef !important;
    color: #FFFFFF !important;
  }
  .admin-page .upcoming-area .monday-first-card {
    background-color: #b2d8ef;
    border-left: 2px solid #387ebe;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .admin-page .upcoming-area p {
    font-size: 12px;
    text-align: left;
    padding: 2px;
  }
  .admin-page .upcoming-area tr {
    position: relative;
  }
  .admin-page .shasha#dropdownMenuButton {
    padding: 0;
    padding-right: 20px;
  }
  .admin-page .upcoming-area .monday-second-card {
    position: absolute;
    background-color: #ebecf3;
    border-left: 2px solid #c8cbde;
    bottom: 50px;
    width: 14%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .admin-page .down-icon-last img {
    width: 11px;
    height: auto;
    position: absolute;
    right: 0;
    top: 10px;
  }
  .admin-page .upcoming-area .tudesday-card {
    position: absolute;
    background-color: #dbe6ee;
    border-left: 2px solid #b2d8ef;
    bottom: 111px;
    width: 13.8%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .admin-page .upcoming-area .friday-card {
    position: absolute;
    background-color: #e5f4f4;
    border-left: 2px solid #b2d8ef;
    top: 172px;
    width: 12%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .admin-page .upcoming-area .events p {
    font-weight: 900;
    font-size: 18px;
    color: #05233B;
  }
 .admin-page .upcoming-area .btn-group .btn {
    border: 1px solid #e8e8e8;
    background-color: transparent;
  }
  .admin-page .upcoming-area .btn-group .first-btn {
    padding-top: 14px;
  }
  .admin-page .btn.first-btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
 .admin-page #dropdownMenuButton {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
 .admin-page .btn.first-btn {
    cursor: pointer;
  }
 .admin-page .btn.first-btn img {
    width: 8px;
    height: auto;
    margin-top: -9px;
  }
 .admin-page .upcoming-heading-content.homepage {
    background: #FFFFFF;
  }
  .admin-page .upcoming-heading-content p {
    font-weight: 300;
    font-size: 18px;
    color: #05233B;
  }
 .admin-page .table thead th {
    font-weight: 300;
  }
  .admin-page .upcoming-heading-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    padding: 5px 15px;
  }
  .admin-page .dropdown-toggle.shfocus:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
 .admin-page .btn {
    font-size: 14px;
  }
  .admin-page .btn.items.shasha {
    padding-right: 25px;
  }
/*=============================================================================
  ====                      Start Footer Area Area Css                     ====
  =============================================================================*/
  .admin-page .footer-area {
    background: #080F1E;
    padding-top: 48px;
    padding-bottom: 28px;
  }
 .admin-page .footer-area .container {
    position: relative;
  }
  .admin-page .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 80px;
  }
  .admin-page .single-footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    z-index: 9;
  }
  .admin-page .footer-links ul li a {
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: capitalize;
    line-height: normal;
    padding-bottom: 10px;
  }
  .admin-page .footer-logo img {
    width: 140px;
    height: auto;
    margin-right: 20px;
  }
  .admin-page .need-help-content p {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: inherit;
    padding-bottom: 5px;
  }
 .admin-page .contact-button a {
    font-weight: 300;
    font-size: 12px;
    color: #FFF;
    border: 1px solid #FFF;
    padding: 4px 25px;
    border-radius: 15px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    background: transparent;
  }
  .admin-page .contact-button a:hover{
    background: #0078BF;
    border-color: #0078BF;
    color: #FFF;
  }
  .admin-page .copyright-text {
    padding-top: 10px;
    border-top: 1px solid #7E8299;
  }
  .admin-page .copyright-text p,.copyright-text p a {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: inherit;
    text-align: center;
  }

  /* Start Chating Area Css */
 .admin-page .chating-area {
   max-width: 1140px;
   width: 100%;
 }
.admin-page .chating-content {
  max-width: 265px;
  width: 100%;
  border: 1px solid #ADDEDC;
  border-top-right-radius: 25px;
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 1;
  border-top-left-radius: 25px;
  z-index: 99;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.admin-page .chating-content.show {
  bottom: -100%;
}
.admin-page .chating-heading,.chating-heading-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #0078BF;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 7px 20px;
}
.admin-page .chating-heading-area {
  position: fixed;
  right: 15px;
  bottom: 15px;
  max-width: 265px;
  width: 100%;
  border-radius: 25px;
  z-index: 99;
}
.admin-page .chating-heading-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.admin-page .chating-heading-left p {
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 1.2;
  text-transform: capitalize;
}
.admin-page .chating-heading-left img {
  width: 18px;
  height: auto;
  margin-top: -5px;
  margin-right: 10px;
}
.admin-page .chating-heading-left p span {
  background: #FFFFFF;
  padding: 1px 10px;
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  border-radius: 8px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.admin-page .chating-heading-right button {
  background: none;
}
.admin-page .chating-heading-right button img {
  width: 16px;
  height: auto;
  margin-left: 10px;
  margin-top: -6px;
}
.admin-page .single-chating-left-image img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.admin-page .single-chating-content {
  background: #ADDEDC;
  padding: 10px;
  border-bottom: 1px solid #EAEDF3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.admin-page .single-chating-content:hover {
  background: #FFFFFF;
}
.admin-page .single-chating-left-content p {
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  text-transform: inherit;
  line-height: 1.5;
}
.admin-page .single-chating-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .single-chating-right-content p {
  font-weight: 400;
  font-size: 10px;
  color: #3E3F43;
  text-transform: inherit;
  line-height: 1.5;
}
.admin-page .single-chating-left-content p:nth-child(2) {
  color: #686868;
}
.admin-page .view-all-button a {
  background: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-transform: inherit;
  text-align: center;
  display: block;
  padding: 8px 10px;
}
/* End Chating Area Css */
/*=============================================================================
  ====                       End Footer Area Area Css                      ====
  =============================================================================*/

/*================================================================================================
  ====                                     END HOME PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                                 START HOME CARD PAGE CSS                               ====
  ================================================================================================*/

/*=============================================================================
  ====                    Start Myclassroom Slider Area Css                ====
  =============================================================================*/
 .admin-page  .myclassroom-slider-area {
    padding-left: 35px;
  }
  .admin-page .single-myclassroom-slider-content {
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 15px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
 .admin-page .single-myclassroom-slider-content:hover {
    border: 2px solid #0078BF;
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
    margin-left: 5px;
  }
 .admin-page .single-myclassroom-slider {
    padding: 7px;
  }
 .admin-page .single-myclassroom-slider-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .single-myclassroom-slider-middle-heading h1 {
    font-weight: 500;
    font-size: 15px;
    color: #3E3F42;
    text-transform: capitalize;
    line-height: 1.2;
  }
  .admin-page .single-myclassroom-slider-middle-heading p {
    font-weight: 500;
    font-size: 11px;
    color: #3E3F42;
    text-transform: capitalize;
  }
  .admin-page .single-myclassroom-slider-middle-heading {
    padding-top: 12px;
    padding-bottom: 2px;
  }
 .admin-page .slider-course-progress-left p {
    font-weight: 400;
    font-size: 9px;
    color: #707070;
    text-transform: inherit;
  }
  .admin-page .slider-course-progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
 .admin-page .course-progress-right {
    margin-left: 12px;
    margin-top: 14px;
  }
 .admin-page .single-slider-profile-left img {
    width: 25px;
    height: 25px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
  }
 .admin-page .single-slider-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 12px;
  }
  .admin-page .single-slider-profile-right {
    margin-left: 8px;
  }
  .admin-page .single-slider-profile-right h2 {
    font-weight: 400;
    font-size: 11px;
    color: #181C32;
    text-transform: capitalize;
    line-height: normal;
  }
 .admin-page .single-slider-profile-right p {
    font-weight: 400;
    font-size: 9px;
    color: #3F4254;
    text-transform: capitalize;
    line-height: normal;
  }
 .admin-page .launch-course-button button {
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    width: 180px;
    padding: 5px 10px;
    border-radius: 25px;
    text-align: center;
    text-transform: inherit;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    margin: 0 auto;
    display: block;
  }
 .admin-page .launch-course-button button:hover {
   background: transparent;
   color: #0078BF;
 }
 .admin-page .view-course-button button {
  font-weight: 500;
  font-size: 11px;
  color: #0078BF;
  background: transparent;
  border: none;
  width: 180px;
  padding: 5px 10px;
  border-radius: 25px;
  text-align: center;
  text-transform: inherit;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  margin: 0 auto;
  margin-top: 3px;
  display: block;
}
.admin-page .view-course-button button:hover {
 background: #0078BF;
 color: #FFFFFF;
}
.admin-page .slider-course-progress-left p {
  font-weight: 400;
  font-size: 9px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.admin-page .single-myclassroom-slider-heading a,.admin-page .single-myclassroom-slider-heading p {
  font-weight: 500;
  font-size: 9px;
  color: #3F4254;
  border-radius: ;
  border-radius: 3px;
  text-transform: capitalize;
}
.admin-page .single-myclassroom-slider-heading a {
  width: 45px;
  text-align: center;
}
.admin-page .single-myclassroom-slider-heading a {
 background: #DBE6EE;
 padding: 2px 6px;
}
.admin-page .single-myclassroom-slider-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .single-myclassroom-slider-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #B2D8EF;
  padding: 7px;
}
.admin-page .single-myclassroom-slider-bottom-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 135px;
}
.admin-page .single-myclassroom-slider-bottom-right img {
  width: 20px !important;
  height: auto;
}
.admin-page .see-all-class-slider-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: right;
  display: block;
}
/* Start Owl Carousel Customize Css */
.admin-page .myclassroom-slider-area::after {
  content: "";
  position: absolute;
  background: url('/media/home-card/myclassroom-slider-after.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: -12px;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 1;
}
.admin-page .owl-theme .owl-nav {
  margin-top: 0;
  height: 0;
}
.admin-page .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
}
.admin-page .owl-theme .owl-nav [class*="owl-"]:hover {
  color: transparent;
}
.admin-page .owl-theme .owl-nav [class*="owl-"] {
  margin: 0;
}
.admin-page .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: 0px;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.admin-page .owl-theme .owl-nav .owl-prev img:hover {
  opacity: .8;
}
.admin-page .owl-theme .owl-dots .owl-dot.active span, .admin-page .owl-theme .owl-dots .owl-dot:hover span {
  width: 12px;
  height: 12px;
  background: #3F4254;
  border: 2px solid #3F4254;
}
.admin-page .owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: transparent;
  border: 2px solid #3F4254;
}

/*Rakib start*/
.admin-page .ra_resources_area .owl-theme .owl-dots {
  bottom: -29px;
}
.admin-page .ra_resources_content .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.admin-page .ra_resources_content .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999;
}
.admin-page .ra_event_area .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.admin-page .ra_event_area .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999;
}
.admin-page .ra_event_main_area_slider{
  padding: 0 35px 0 45px;
}
.admin-page .ra_event_main_area_slider .owl-theme .owl-nav .owl-next img {
  right: -33px;
}
.admin-page .btn.first-btn.extra_btn_btnclass {
  padding: 7.5px 13px;
}
.admin-page .btn {
  padding: .380rem .75rem;
}
/*Rakib end*/


/* End Owl Carousel Customize Css */

/*=============================================================================
  ====                     End Myclassroom Slider Area Css                 ====
  =============================================================================*/

/*================================================================================================
  ====                                  END HOME CARD PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                                   START LOGIN PAGE CSS                                 ====
  ================================================================================================*/
  .login-header {
    background: #FFFFFF;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,.1);
    box-shadow: 0 0 8px rgba(0,0,0,.1);
  }
  .login-logo img {
   /* width: 250px;
    height: auto;*/
  }
  .login-content {
    max-width: 485px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.1);
    box-shadow: 0 0 4px rgba(0,0,0,.1);
    margin: 10px auto;
    padding: 25px 68px;
  }
  .login-heading h1 {
    font-weight: 500;
    font-size: 35px;
    color: #05233B;
    text-transform: inherit;
    line-height: 55px;
    padding-bottom: 15px;
    text-align: center;
  }
  .single-login-form {
    margin-bottom: 15px;
  }
  .single-login-form label {
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    display: block;
    text-align: left;
    margin-bottom: 5px !important;
  }
  .single-login-form input {
    width: 100%;
    border: 1px solid #3F4254;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    outline: none;
  }
  .single-login-form input:focus {
    border: 2px solid #0078BF;
    border-radius: 5px;
  }
  .single-login-form input::-webkit-input-placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-form input::-moz-placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-form input:-ms-input-placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-form input::-ms-input-placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-form input::placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-submit-button input {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  background: #0078BF;
  border: none;
  width: 100%;
  padding: 8px 15px;
  border-radius: 30px;
  margin-top: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.single-login-submit-button input:hover {
  background: #3F4254;
}
.login-form-text a {
  font-weight: 500;
  font-size: 14px;
  color: #0078BF;
  text-transform: inherit;
  line-height: normal;
}
.login-form-text a:nth-child(1) {
  padding: 20px 0;
}
.login-form-text a span {
  color: #05233B;
}
.login-form-logo {
  text-align: center;
  margin-top: 15px;
  position: relative;
}
.login-form-logo::before {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 78px;
  height: 2px;
  left: 0;
  top: 22px;
}
.login-form-logo::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 78px;
  height: 2px;
  right: 0;
  top: 22px;
}
.login-form-logo img {
  width: 160px;
  height: auto;
}
.single-login-checkbox-form input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.single-login-checkbox-form label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  display: block;
  text-align: left;
  margin-bottom: 5px !important;
}
.single-login-checkbox-form label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #05233B;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
  top: -5px;
}
.single-login-checkbox-form input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #05233B;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.login-footer-area {
  background: #080F1E;
  padding-top: 55px;
  padding-bottom: 30px;
}
.login-footer-content {
  max-width: 485px;
  width: 100%;
  margin: 0 auto;
}
.login-footer-top-left-content img {
  width: 145px;
  height: auto;
}
.login-footer-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #7E8299;
}
.login-footer-top-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-login-footer-left a {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: inherit;
  margin-right: 15px;
}
.single-login-footer-right a {
  font-weight: 300;
  font-size: 12px;
  color: #FFF;
  border: 1px solid #FFF;
  padding: 4px 25px;
  border-radius: 15px;
}
.login-footer-bottom-content p {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: inherit;
  text-align: center;
  padding-top: 12px;
}
.login-footer-bottom-content p a {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  line-height: 10px;
}
.login-footer-bottom-content p span {
  color: #7E8299;
}
/*================================================================================================
  ====                                    END LOGIN PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                           Start Resource Cards Page (10)(Munna)                        ====
  ================================================================================================*/
  /*Start Alert Area*/
  .admin-page .mu_resource_cards{
    background: #F3F6F9;
  }
  .admin-page .mu_alert_box, .custom_alert_design {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ADDEDC;
    padding: 14px;
    margin-top: 24px;
    border-radius: 4px;
  }
  .admin-page .mu_alert_box_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_alert_box_left img{
    width: 22px;
    height: 22px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 17px;
  }
  .admin-page .mu_alert_box_left p{
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .mu_alert_box_right a,.custom_alert_right a {
    font-size: 12px;
    font-weight: 300;
    color: #3F4254;
    display: inherit;
    margin-right: 10px;
  }
  /*End Alert Area*/

  /*================== Start Dropdown Menu & Search Area ==================*/
  /*Start Dropdown*/
  .admin-page .dropdown_search_flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .admin-page .mu_dropdown_menu_content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
  }
  .admin-page .mu_search_bar{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%
  }
  .admin-page .mu_dropdown_menu_area {
    background: #F3F6F9;
    margin-top: 39px;
  }
  .admin-page .mu_dropdown_menu_content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dropdown_menu_content ul li{
    color: #3F4254;
    margin-right: 20px;
    font-size: 12px;
  }
  .admin-page .mu_dropdown_menu_content ul li a, .mu_dropdown_menu_content ul li button.menu-drop {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    padding: 18px 0;
    position: relative;
    background: transparent;
  }
  .admin-page .mu_dropdown_menu_content ul li button.menu-drop {
    padding-right: 30px;
  }
  .admin-page .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
    color: #0078BF;
    border-bottom: 3px solid #0078BF;
  }
  .admin-page .mu_dropdown_menu_content ul li a.active,.admin-page .mu_dropdown_menu_content ul li a:hover {
    color: #0078BF;
  }

  .admin-page .mu_dropdown_menu_content ul li a img,.admin-page .mu_dropdown_menu_content ul li button.menu-drop img {
    height: 14px;
    width: auto;
    margin-top: -7px;
    margin-right: 2px;
  }
  .admin-page .mu_dropdown_menu_content ul li a span {
    background: #145B94;
    font-weight: 400;
    font-size: 10px;
    color: #FFF;
    padding: 1px 5px;
    border-radius: 15px;
    width: 10px;
    margin-left: 5px;
  }
  /*End Dropdown*/

  /*Start Mobile Menu Icon*/
  .admin-page #nav-icon3{ 
    display: none;
  }
 .admin-page  #nav-icon1, .admin-page #nav-icon2, .admin-page #nav-icon3, .admin-page #nav-icon4 {
    width: 25px;
    height: 20px;
    position: relative;
    /*margin: 0px auto;*/
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .admin-page #nav-icon1 span, .admin-page #nav-icon3 span, .admin-page #nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #A6ADB1;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  /* Icon 3 */
  .admin-page #nav-icon3 span:nth-child(1) {
    top: 0px;
  }
  .admin-page #nav-icon3 span:nth-child(2),.admin-page #nav-icon3 span:nth-child(3) {
    top: 8px;
  }
  .admin-page #nav-icon3 span:nth-child(4) {
    top: 16px;
  }
  .admin-page #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  .admin-page #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .admin-page #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .admin-page #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  /*End Mobile Menu Icon*/

  /* Start Mobile Search Box Css */
  .admin-page #search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: 2;
  }
  .admin-page #search input[type="search"] {
    position: absolute;
    top: 50%;
    max-width: 450px;
    width: 100%;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    outline: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-bottom: 1px solid #FFF;
    padding: 5px 15px;
  }
  .admin-page #search input[type="search"]::-webkit-input-placeholder {
    color: #FFFFFF;
  }
 .admin-page #search input[type="search"]::-moz-placeholder {
    color: #FFFFFF;
  }
  .admin-page #search input[type="search"]:-ms-input-placeholder {
    color: #FFFFFF;
  }
  .admin-page #search input[type="search"]::-ms-input-placeholder {
    color: #FFFFFF;
  }
  .admin-page #search input[type="search"]::-webkit-input-placeholder {
    color: #FFFFFF;
  }
  .admin-page #search input[type="search"]::-moz-placeholder {
    color: #FFFFFF;
  }
 .admin-page #search input[type="search"]:-ms-input-placeholder {
    color: #FFFFFF;
  }
 .admin-page #search input[type="search"]::placeholder {
    color: #FFFFFF;
  }
  .admin-page #search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #0078BF;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 8px 30px;
    text-transform: uppercase;
    margin-top: 20px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    border: none;
  }
  .admin-page .volver-button a {
    font-weight: 400;
    font-size: 17px;
    color: #231F20;
    background: #00F1A4;
    padding: 8px 45px;
    border-radius: 10px;
    margin-top: 60px;
    text-transform: uppercase;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page #search .btn:hover {
    background: #ADDEDC;
    color: #3E3F42;
  }
  .admin-page #search .btn.btn-primary:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .admin-page #search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: #0078BF;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    font-size: 15px;
    outline: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page #search .close:hover {
    background: #ADDEDC;
    color: #3E3F42;
  }
  .admin-page #search button.close::after {
    content: "";
    position: absolute;
    background: url(/media/ico/cross.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    left: 12px;
    top: 12px;
    -webkit-transform: rotate(133deg);
    -ms-transform: rotate(133deg);
    transform: rotate(133deg);
  }
  .admin-page #search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  /* End Mobile Search Box Css */

  /*Start Mobile Menu Responsive*/
  .admin-page .mu_full_menu_area{
    position: relative;
  }
  .admin-page .mu_accordion {
    display: none;
    position: absolute;
    width: 100%;
    background: #FAFBFC;
    top: 55px;
    left: 0;
    z-index: 9991;
  }
  .admin-page .mu_accordion.hide {
    display: none !important;
  }
  .admin-page .mu_accordion .link {
    cursor: pointer;
    display: block;
    padding: 22px 0px 20px 36px;
    color: #05233B;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #CCC;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .admin-page .mu_accordion li:last-child .link { 
    border-bottom: 0; 
  }
  .admin-page .mu_accordion li i {
    position: absolute;
    top: 20px;
    left: 43px;
    font-size: 23px;
    color: #A6ADB1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
 .admin-page .mu_accordion li i.fa-chevron-down {
    right: 35px;
    left: auto;
    font-size: 16px;
    top: 2px;
    color: #000000;
  }
  .admin-page .mu_accordion li.open i.fa-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  /*submenu*/
  .admin-page .mu_submenu {
    display: none;
    background: #FFF;
    font-size: 14px;
  }
  .admin-page .mu_submenu li {
   border-bottom: 1px solid #E4E6EF; 
 }
 .admin-page .mu_submenu a {
  display: block;
  text-decoration: none;
  color: #05233B;
  padding: 20px;
  padding-left: 36px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.admin-page .mu_submenu a:hover {
  background: #FAFBFC;
  color: #05233B;
}
/*End Mobile Menu Responsive*/

/*Start Search Bar*/
.admin-page .mu_search_bar button{
  font-size: 14px;
  color: #FFFFFF;
  position: relative;
}
.admin-page .mu_search_bar input[type="search"] {
  background: #FFF;
  border: 1px solid #A6ADB1;
  padding: 7px 0px 7px 40px;
  outline: none;
  width: 260px;
  color: #505355;
  font-size: 14px;
  border-radius: 3px 0 0 3px;
}
.admin-page .mu_search_bar input[type="search"]:focus{
  -webkit-box-shadow: 0px 0px 10px #bbb;
  box-shadow: 0px 0px 10px #bbb;
}
.admin-page .mu_search_bar button {
  border: none;
  background: #05233B;
  color: #fff;
  outline: none;
  padding: 8px 15px;
  margin-left: -5px;
  border-radius: 0 3px 3px 0;
}
.admin-page .mu_search_bar button img {
  margin: -2px 0 0 40px;
}
.admin-page .search_box_area,.admin-page .mu_search_bar {
  position: relative;
}
.admin-page .mu_search_bar form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.admin-page .search_icon {
  position: absolute;
  top: 3px;
  padding: 0 10px;
}
.admin-page .search_icon img{
  width: 19px;
  height: auto;
  cursor: pointer;
}
.admin-page .mu_dropdown {
  background: #fff;
  width: 170px;
  border: 1px solid #ddd;
  position: absolute;
  right: 0;
  bottom: -135px;
  border-radius: 4px;
  z-index: 999;
  display: none;
}
.admin-page .mu_dropdown ul li a{
  color: #3F4254;
  font-size: 14px;
  display: block;
  padding: 8px 20px;
  border-bottom: 3px solid transparent;
}
.admin-page .mu_dropdown ul li a:hover {
  background-color: #E4E6EFA3;
}
/* End Search Bar */

/* Start View icons*/
.admin-page .mu_view_icons ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .mu_view_icons ul li{
  margin-right: 20px;
  font-size: 14px;
  color: #3F4254;
}
.admin-page .mu_view_icons ul li:last-child{
  margin-right: 0;
}
.admin-page .mu_view_icons ul li a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .view_img_1{
  width: 11px;
}
.admin-page .view_img_2{
  width: 17px;
}
/* End View icons*/
/*=================== End Dropdown Menu Area =================*/

/*========================== Start Grid Box Area ====================*/
.admin-page .mu_grid_box_area {
  background: #F3F6F9;
}
.admin-page .mu_single_all_grid_boxs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.admin-page .mu_single_grid_boxs {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18.5%;
  flex: 0 0 18.5%;
  margin-bottom: 30px;
}
.admin-page .mu_single_grid_box {
  background: #fff;
  padding: 9px;
  border: 1px solid #E4E6EF;
  border-radius: 3px;
}
.admin-page .mu_date_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}
.admin-page .mu_date_img ul li{
  font-size: 9px;
}
.admin-page .mu_date_img img{
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}
.admin-page .mu_single_grid_box h6{
  font-size: 15px;
  font-weight: 400;
  color: #3E3F42;
  margin-bottom: 7px;
}
.admin-page .mu_single_grid_box p {
  font-size: 12px;
  font-weight: 400;
  color: #3E3F42;
  line-height: 15px;
}
.admin-page .mu_grid_box_pdf_left{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .mu_grid_box_pdf_left img{
  width: 13px;
  height: 13px;
  margin-right: 7px;
}
.admin-page .mu_grid_box_pdf_left a{
  font-size: 9px;
  color: #145B94;
  text-decoration: underline;
}
.admin-page .mu_grid_box_pdf{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.admin-page .mu_grid_box_pdf_right{
  margin: 3px 0;
}
.admin-page .mu_grid_box_pdf_right a{
  color: #3F4254;
  font-size: 9px;
}
/*PDF Client*/
.admin-page .mu_pdf_client{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #B2D8EF;
  padding: 6px 9px;
}
.admin-page .mu_pdf_client_left{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-page .mu_pdf_client_left img{
  margin-right: 5px;
  width: 23px;
  height: 23px;
}
.admin-page .mu_pdf_client_content h6 {
  font-size: 12px;
  color: #181C32;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.admin-page .mu_pdf_client_content p {
  font-size: 10px;
  color: #3F4254;
}
.admin-page .mu_pdf_client_right a img{
  width: 20px;
  height: 20px;
}

/*Footer Button (10)(Munna)*/
.admin-page .mu_footer_btn{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}
.admin-page .mu_footer_btn a img{
  width: 120px;
  border: 1px solid #FFF;
  margin-right: 10px;
}
/*===============================================================================================
  ====                           End Resource Cards Page (10)(Munna)                         ====
  ===============================================================================================*/

/*================================================================================================
  ====                                     START STUDENT PAGE CSS                             ====
  ================================================================================================*/

/*=============================================================================
  ====                      Start student_pages_content_area Css           ====
  =============================================================================*/

  /*student_action_alert_area*/
  .admin-page .student_action_alert_area .custom_alert_design {
    background: rgba(230,119,43,0.29);
    margin-top: 0;
    margin-bottom: 5px;
  }
  /*End student_action_alert_area*/

  /*student_pages_content_area*/
  .admin-page .student_pages_content_area {
    padding-bottom: 462px;
    position: relative;
  }
 .admin-page  .infor_heading_area{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

  }
  .admin-page .main_student_information_area {
    background: #FFF;
    margin-top: 14px;
  }
 .admin-page .left_infor_area .infor_profile img {
    max-width: 60px;
    width: 100%;
    height: auto;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: 7px;
    margin-left: 11px;
  }
  .admin-page .infor_profile{
    position: relative;
  }
  .admin-page .infor_profile a {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
  }
  .admin-page .right_infor_area a img {
    max-width: 13px;
    width: 100%;
    height: auto;
  }
  .admin-page .right_infor_area a span {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    margin-left: 2px;
  }
  .admin-page .right_infor_area a {
    margin-top: 15px;
    margin-right: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .left_infor_area .infor_profile a img {
    max-width: 21px;
    width: 100%;
    height: auto;
  }
  .admin-page .information_biodata h4 {
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    color: #3F4254;
    margin-top: -15px;
  }
 .admin-page .infor_bio_top {
    max-width: 197px;
    width: 100%;
    margin: auto;
    margin-top: 19px;
  }
  .admin-page .infor_bio_top > ul li a,.admin-page .infor_comment_area > ul li a, 
  .admin-page .infor_address_area > ul li a, .admin-page .infor_parent_area > ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    color: #3F4254;
    font-size: 11px;
  }
  .admin-page .infor_bio_top ul li:not(:last-child) {
    margin-bottom: 6px;
  }
  .admin-page .infor_bio_top ul li a span{
    margin-left: 20px;
    display: inline-block;
  }
  .admin-page .infor_bio_top > ul li:nth-child(4) a span, 
  .admin-page .infor_comment_area > ul li:nth-child(4) a span {
    margin-left: 35px;
  }
  .admin-page .infor_comment_area > p{
    margin-top: 45px;
    margin-bottom: 39px;
  }
  .admin-page .infor_comment_area > ul li:nth-child(3) a span {
    margin-left: 35px;
  }
  .admin-page .infor_address_area h5 {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-top: 46px;
    margin-bottom: 20px;
  }
 .admin-page .infor_address_area ul li:nth-child(1) a span{
    margin-left: 0;
  }
 .admin-page .infor_address_area > ul li:nth-child(4) a span {
    margin-left: 30px;
  }
  .admin-page .infor_address_area > ul li:nth-child(5) a span {
    margin-left: 10px;
  }
 .admin-page .infor_address_area > ul > li > a, .infor_parent_area > ul > li > a{
    position: relative;
  }
 .admin-page .infor_address_area > ul > li > a::after,.admin-page .infor_parent_area > ul > li > a::after {
    content: "";
    position: absolute;
    right: -30px;
    top: 2px;
    width: 1px;
    height: 12px;
    background: #C7C8CA;
  }
 .admin-page .infor_parent_area {
    margin-right: -20px;
    padding-bottom: 24px;
  }
 .admin-page .infor_parent_area h5{
    margin-top: 48px;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 14px;
  }
  .admin-page .infor_address_area ul li:not(:last-child), .admin-page .infor_parent_area ul li:not(:last-child) {
    margin-bottom: 7px;
  }
  .admin-page .infor_parent_area > ul > li > a span{
    margin-left: 15px;
  }
 .admin-page  .infor_parent_area > ul > li:nth-child(3) a span{
    margin-left: 10px;
  }
  .admin-page .infor_parent_area > ul > li:nth-child(4) a span{
    margin-left: 10px;
  }
 .admin-page .infor_parent_area > ul > li > a::after {
    right: -11px;
  }
  /*student_action_help_area css*/
  .admin-page .student_action_help_area {
    margin-top: 14px;
  }
 .admin-page .student_action_heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .admin-page .student_action_heading h5{
    font-size: 16px;
    font-weight: 300;
    color: #05233B;
  }
  .admin-page .student_action_heading a{
    color: #0078BF;
    font-weight: 300;
  }
  .admin-page .action_st_btn_img img {
    max-width: 58px;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    margin-top: 14px;
    margin-bottom: 26px;
  }
 .admin-page .Student_action_single_items,.admin-page .st_another_single_deffrent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .admin-page .st_single_action {
    background: #FFF;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 6px);
    flex: 0 0 calc(50% - 6px);
    margin-bottom: 10px;
  }
  .admin-page .st_single_action h5 {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    padding-top: 15px;
    padding-left: 20px;
  }
 .admin-page .action_st_btn_img {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .admin-page .btn_style_1 {
    font-weight: 300;
    color: #FFF;
    font-size: 12px;
    background: #0078BF;
    border: 1px solid #0078BF;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
 .admin-page .btn_style_1:hover{
    background: #FFF;
    color: #0078BF;
  }
 .admin-page .btn_style_2 {
    font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    background: #FFF;
    border: 1px solid #0078BF;
    padding: 4px 17px;
    display: inline-block;
    border-radius: 50px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
 .admin-page #launchcourse {
    background: transparent;
  }
 .admin-page .btn_style_2:hover,#launchcourse:hover{
    background:  #0078BF;
    color: #FFF;
  }
 .admin-page .Student_action_single_items .action_st_btn_img a.btn_style_2 {
    margin-top: 14px;
    margin-bottom: 20px;
  }
  .admin-page .Student_action_single_items .st_single_action:nth-child(2) a.btn_style_1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .admin-page .Student_action_single_items .st_single_action:nth-child(2) .action_st_btn_img img {
    max-width: 53px;
    margin-top: 35px;
  }
  .admin-page .st_another_single_deffrent .st_single_action:nth-child(1) .action_st_btn_img img{
    max-width: 53px;
    margin-top: 13px;
    margin-bottom: 0;
  }
  .admin-page .st_another_single_deffrent .st_single_action:nth-child(2) .action_st_btn_img img{
    max-width: 56px;
    margin-top: 13px;
    margin-bottom: 0;
  }
  .admin-page .st_another_single_deffrent .st_single_action:nth-child(1) .action_st_btn_img a.btn_style_1{
    margin-top: 18px;
    margin-bottom: 24px;
  }
  .admin-page .st_another_single_deffrent .st_single_action:nth-child(2) .action_st_btn_img a.btn_style_1,.admin-page .btn_style_1.new{
    margin-top: 19px;
    margin-bottom: 24px;
  }
  .admin-page .action_st_btn_img.new img {
    margin-bottom: 0;
  }
  .admin-page .btn_style_1.notifications {
    width: 175px;
  }
/*=============================================================================
  ====                       End student_pages_content_area Css            ====
  =============================================================================*/

/*================================================================================================
  ====                                      END STUDENT PAGE CSS                              ====
  ================================================================================================*/

/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/
 .admin-page .resources-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
  .admin-page .same-close-button-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 20px;
  }
  .admin-page .same-close-button-left button {
    font-weight: 300;
    font-size: 18px;
    color: #3F4254;
    text-transform: capitalize;
    line-height: 1.2;
    background: none;
  }
  .admin-page .same-close-button-left button img {
    width: 14px;
    height: auto;
    margin-top: -6px;
    margin-right: 15px;
  }
 .admin-page  .same-close-right-link-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .single-same-close-right-link-button a {
    font-weight: 300;
    font-size: 14px;
    color: #0078BF;
    text-transform: inherit;
    border: 1px solid #0078BF;
    padding: 5px 18px;
    border-radius: 30px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page .single-same-close-right-link-button a:hover {
    background: #0078BF;
    color: #FFFFFF;
  }
  .admin-page .same-close-right-link-button .single-same-close-right-link-button:nth-child(2) a {
    margin-left: 15px;
  }
  .admin-page .resources-main-image-area {
    text-align: right;
    padding-top: 25px;
  }
  .admin-page .resources-main-image-area img {
    max-width: 880px;
    width: 100%;
    height: auto;
  }
/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/

/*================================================================================================
  ====                          START REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                 ====
  ================================================================================================*/
  .admin-page .request-mobile-area {
    background: #FFFFFF;
  }
 .admin-page .request-transcript-main-mobile-design {
    display: none;
  }
 .admin-page .request-mobile-content-area {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
 .admin-page .request-mobile-content-heading-text p {
    padding-bottom: 20px;
  }
 .admin-page .request-mobile-content-area p,.single-request-transcript-heading-content p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    line-height: 1.2;
  }
  .admin-page .single-request-mobile-middle-text p:nth-child(2) {
    font-size: 12px;
    padding-top: 5px;
    width: 88%;
  }
  .admin-page .single-request-mobile-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #F3F6F9;
    padding: 25px 20px;
    margin-bottom: 20px;
  }
  .admin-page .single-request-mobile-middle-text {
    margin-left: 25px;
    max-width: 385px;
    width: 100%;
  }
  .admin-page .single-request-mobile-middle-text a,.admin-page .single-request-transcript-bottom-head a {
    font-weight: 300;
    font-size: 12px;
    color: #0B7DC1;
    text-transform: inherit;
    border-bottom: 1px solid #0B7DC1;
    line-height: 10px;
  }
 .admin-page .single-request-mobile-left-image img {
    width: 50px;
    height: auto;
  }
 .admin-page .single-request-mobile-right-text a {
    background: transparent;
  }
/*================================================================================================
  ====                          END REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST TRANSCRIPT LIST  PAGE CSS                     ====
  ================================================================================================*/
  .admin-page .request-transcript-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
  .admin-page .request-transcript-main-content {
    padding-top: 55px;
  }
  .admin-page .request-transcript-main-content {
    width: 95%;
    margin: 0 auto;
  }
  .admin-page .single-request-transcript-content {
    background: #F3F6F9;
    padding: 15px 20px 60px 20px;
    height: 100%;
    position: relative;
  }
  .admin-page .single-request-transcript-heading-content img {
    height: 55px;
    width: auto;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .admin-page .single-request-transcript-bottom-content p {
   font-weight: 300;
   font-size: 12px;
   color: #05233B;
   line-height: 1.2;
 }
 .admin-page .single-request-transcript-bottom-button a {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 185px;
}
.admin-page .single-request-transcript-heading-content p {
  text-align: left;
}
.admin-page .single-request-transcript-bottom-button a:hover {
  background: transparent;
}
/*================================================================================================
  ====                             END REQUEST TRANSCRIPT LIST  PAGE CSS                      ====
  ================================================================================================*/

/*================================================================================================
  ====                              START REQUEST ENROLLMENT PAGE CSS                         ====
  ================================================================================================*/
  .admin-page .request-enrollment-area {
   background: #FFFFFF;
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   z-index: 999;
   display: none;
 }
 .admin-page .need-help-link a {
  font-weight: 300;
  font-size: 12px;
  color: #0B7DC1;
  text-transform: inherit;
  border-bottom: 1px solid #0B7DC1;
  line-height: 11px;
}
.admin-page .request-enrollment-main-content {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.admin-page .request-enrollment-main-content-heading p {
  font-weight: 300;
  font-size: 15px;
  color: #05233B;
  text-transform: inherit;
  line-height: 1.4;
  padding-bottom: 25px;
}
.admin-page .request-enrollment-option {
  margin-bottom: 10px;
}
.admin-page .request-enrollment-single-select-option {
  margin-bottom: 20px;
}
.admin-page .request-enrollment-option p, .single-request-enrollment-option label, .admin-page .request-enrollment-single-select-option p,.request-enrollment-single-datepiker p,.admin-page .request-enrollment-textarea-content p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  line-height: 1.2;
  margin-bottom: 8px;
}
.admin-page .request-enrollment-option p span, .admin-page .request-enrollment-single-select-option p span,.admin-page .request-enrollment-single-datepiker p span,.admin-page .request-enrollment-textarea-content p span {
  color: #FF0000;
  vertical-align: middle;
}

/* Start Custom Radio Checkbox Css */
.admin-page .single-request-enrollment-option input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.admin-page .single-request-enrollment-option label {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 30px;
}
.admin-page .single-request-enrollment-option label::before {
  content: '';
  background: url('/media/enrollment/check-before.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
.admin-page .single-request-enrollment-option input:checked + label::after {
  content: '';
  background: url('/media/enrollment/check-after.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
/* End Custom Radio Checkbox Css */

/* Start Dropdown Select Option Filter Css */
.admin-page #select-chose-course {
  border: 1px solid #3F4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
}
.admin-page #select-chose-course::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 6px;
  right: 12px;
  top: 13px;
}
.admin-page .hierarchy-select.dropdown .hs-searchbox {
  padding: 4px 5px 4px 5px;
  position: relative;
}
.admin-page .hs-searchbox img {
  position: absolute;
  left: 12px;
  top: 15px;
  width: 15px;
  height: auto;
  cursor: pointer;
}
.admin-page .dropdown-menu.select-course {
  border: 1px solid #E4E6EF;
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,.1);
  box-shadow: 0 0 2px rgba(0,0,0,.1);
  max-width: 275px;
  width: 100%;
}
.admin-page .form-control.select-course::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.admin-page .form-control.select-course::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.admin-page .form-control.select-course:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.admin-page .form-control.select-course::-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.admin-page .form-control.select-course::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.admin-page .form-control.select-course {
  border: 1px solid #A6ADB1;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 5px 10px 5px 30px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  outline: none;
}
.admin-page .dropdown-item.enrollment {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
} 
.admin-page .dropdown-item.enrollment:hover {
  color: inherit;
  background-color: #E4E6EF;
}
.admin-page .dropdown-item.enrollment.active, .admin-page .dropdown-item.enrollment:active {
  color: #3F4254;
  text-decoration: none;
  background-color: #DBE6EE;
  font-weight: 300;
}
.admin-page .form-control.select-course:focus {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  outline: none !important;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border: 1px solid #A6ADB1;
}

/* Start Dropdown Select Option Filter Css */

/* Start Custom Datepiker Css */
.admin-page .form-groups {
  max-width: 280px;
  width: 100%;
  position: relative;
}
.admin-page .form-groups::after,.info-form-group::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/datepiker.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 18px;
  top: 8px;
  right: 9px;
  pointer-events: none;
}
.admin-page .form-groups::before,.info-form-group::before {
  content: "";
  position: absolute;
  background: #3F4254;
  width: 1px;
  height: 34px;
  top: 0;
  right: 32px;
  z-index: 1;
}
.admin-page .form-groups .input-field {
  border: 1px solid #3F4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.admin-page .form-groups input::-webkit-input-placeholder {
  font-size: 14px;
}
.admin-page .form-groups input::-moz-placeholder {
  font-size: 14px;
}
.admin-page .form-groups input:-ms-input-placeholder {
  font-size: 14px;
}
.admin-page .form-groups input::-ms-input-placeholder {
  font-size: 14px;
}
.admin-page .form-groups input::placeholder {
  font-size: 14px;
}
.admin-page .form-groups .input-field:focus {
  outline: none;
}
.admin-page .ui-datepicker {
  display: none;
  background-color: #fff;
  -webkit-box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.admin-page .ui-datepicker-calendar table {
  border-collapse: collapse;
  border-spacing: 0;
}
.admin-page .ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: #353535;
}
.admin-page .ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.admin-page .ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 50%;
  line-height: 2rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  color: #353535;
  font-size: 0.875rem;
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.admin-page .ui-datepicker-calendar tbody td a.ui-state-active {
  background: #d33a47;
  color: #ffffff;
}
.admin-page .ui-datepicker-calendar tbody td a.ui-state-highlight {
  color: #0078BF;
  border: 1px solid #0078BF;
}
.admin-page .ui-datepicker-calendar tbody td a:hover {
  background: #0078BF;
  color: #FFFFFF;
}
.admin-page .ui-datepicker-header a span {
  display: none;
}
.admin-page .ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 8px 20px;
}
.admin-page .ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
}
.admin-page .ui-datepicker-header a.ui-datepicker-prev::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 12px;
}
.admin-page .ui-datepicker-header a.ui-datepicker-next {
  right: 0;
}
.admin-page .ui-datepicker-header a.ui-datepicker-next::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 12px;
}
.admin-page .ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.admin-page .ui-datepicker-week-col {
  color: #353535;
  font-weight: 400;
  font-size: 0.75rem;
}
/* End Custom Datepiker Css */

.admin-page .all-same-button-design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 35px;
}
.admin-page .single-same-button-design button {
  font-weight: 300;
  font-size: 12px;
  color: #0078BF;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #0078BF;
  width: 104px;
  text-align: center;
  padding: 3px 5px;
  border-radius: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.admin-page .single-same-button-design button:hover {
 background: #0078BF;
 border: 1px solid #0078BF;
 color: #FFFFFF;
}
.admin-page .all-same-button-design .single-same-button-design:nth-child(2) button {
  background: #0078BF;
  border: 1px solid #0078BF;
  color: #FFFFFF;
  margin-left: 15px;
}
.admin-page .all-same-button-design .single-same-button-design:nth-child(2) button:hover {
 background: transparent;
 border: 1px solid #0078BF;
 color: #0078BF;
}
.admin-page .reason-paragraf-text p {
  font-weight: 300;
  font-size: 14px;
  color: #05233B;
  text-transform: inherit;
  line-height: 1.2;
  padding-top: 25px;
  padding-bottom: 45px;
}
.admin-page .single-same-button-design.change {
  margin-left: 15px;
}
/*================================================================================================
  ====                               END REQUEST ENROLLMENT PAGE CSS                          ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST RECOMMENDATION PAGE CSS                       ====
  ================================================================================================*/

 .admin-page .request-recommendation-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
 .admin-page .request-recommendation-opition-area {
    max-width: 685px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .admin-page .request-recommendation-heading p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    padding-bottom: 10px;
  }
  .admin-page .request-recommendation-option-content:nth-child(2) .single-request-recommendation-option-area {
    padding-bottom: 20px;
  }
  .admin-page .new-request-recommendation-option-content {
    margin-bottom: 25px;
  }
  .admin-page .request-recommendation-option-head p {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    text-transform: capitalize;
    padding-bottom: 15px;
  }
  .admin-page .single-request-recommendation-option-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 95%;
    cursor: pointer;
    position: relative;
    background: #F3F6F9;
    padding: 20px 30px;
    border: 2px solid transparent;
  }
  .admin-page .single-request-recommendation-option-content.show {
    border: 2px solid #0078BF;
  }
  .admin-page .single-request-recommendation-left img {
    width: 45px;
    height: auto;
  }
  .admin-page .single-request-recommendation-right {
    margin-left: 30px;
  }
  .admin-page .single-request-recommendation-right h5 {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    text-transform: inherit;
  }
  .admin-page .single-request-recommendation-right p {
    font-weight: 300;
    font-size: 12px;
    color: #05233B;
    text-transform: inherit;
    max-width: 360px;
    width: 100%;
    padding-top: 8px;
    line-height: 1.2;
  }
  .admin-page .single-request-recommendation-right p:nth-child(1) {
    padding-top: 0;
  } 
  .admin-page .cross-button-image button {
    background: transparent;
  }
  .admin-page .cross-button-image img {
    width: 14px;
    height: auto;
    position: absolute;
    right: 28px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
/*================================================================================================
  ====                             END REQUEST RECOMMENDATION PAGE CSS                        ====
  ================================================================================================*/

/*================================================================================================
  ====                           START REQUEST RECOMMENDATION INFO PAGE CSS                   ====
  ================================================================================================*/
  .admin-page .recommendation-myinfo-area {
    background: #FFFFFF;
  } 
  .admin-page .recommendation-myinfo-content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
 .admin-page  .recommendation-save-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
 .admin-page  .recommendation-save-text img {
    width: 13px;
    height: auto;
    margin-top: -5px;
    margin-right: 2px;
  }
  .admin-page .recommendation-save-text span {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .admin-page .recommendation-tab-button img {
    max-width: 530px;
    width: 100%;
    height: auto;
    margin-left: 15px;
    padding: 8px 0;
  }
  .admin-page .recommendation-tab-top-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 540px;
    width: 100%;
    margin-left: 12px;
  }
  .admin-page .recommendation-tab-top-button button {
    font-weight: 300;
    font-size: 12px;
    color: #7E8299;
    background: none;
    text-transform: capitalize;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page .recommendation-tab-top-button button:hover {
    color: #0B7DC1;
  }
 .admin-page .recommendation-tab-top-button button.actives {
    color: #0078BF;
  }
 .admin-page .recommendation-myinfo-form-heading-text p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    padding-top: 25px;
    padding-bottom: 20px;
    margin-left: 10px;
  }
  .admin-page .recommendation-myinfo-form-heading-text.second p {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 22px;
    margin-left: 5px;
  }
  .admin-page .recommendation-myinfo-form-heading-text.salf-evaluation p {
    margin-left: 0;
  }
  .admin-page .recommendation-myinfo-first-form form {
    max-width: 340px;
    width: 100%;
  }
 .admin-page  .info-form-group {
    position: relative;
    cursor: pointer;
  }
 .admin-page .info-form-group input {
    cursor: pointer;
  }
 .admin-page .recommendation-myinfo-first-form {
    margin-left: 10px;
  }
 .admin-page .single-recommendation-myinfo-second {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 15px;
  }
 .admin-page .single-recommendation-info-second-half {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
  }
 .admin-page .single-myinfo-second-right,.single-myinfo-second-half {
    max-width: 340px;
    width: 100%;
  }
  .admin-page .single-myinfo-second-left p,.single-myinfo-second-left-pera p {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-right: 20px;
    text-transform: inherit;
  }
  .admin-page .single-myinfo-second-left p {
    margin-top: 34px;
  }
 .admin-page .single-myinfo-second-left-pera p {
    margin-top: 8px;
  }
  .admin-page .single-myinfo-form label,.single-recommendation-myinfo-second label,.admin-page .single-recommendation-myinfo-second-bottom label {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    display: block;
    margin-bottom: 6px;
  }
  .admin-page .single-myinfo-form {
    padding-bottom: 15px;
  }
  .admin-page .single-myinfo-form input,.single-recommendation-myinfo-second input,.admin-page .single-recommendation-myinfo-second-bottom input {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding: 6px 10px;
    border: 1px solid #3F4254;
    border-radius: 5px;
    outline: none;
  }
  .admin-page .single-recommendation-myinfo-second input,.admin-page .single-recommendation-myinfo-second-bottom input {
    margin-bottom: 15px;
  }
  .admin-page .single-myinfo-second-half {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
 .admin-page .single-myinfo-second-half input {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
  }
  .admin-page .single-myinfo-form input::-webkit-input-placeholder,.admin-page .single-recommendation-myinfo-second input::-webkit-input-placeholder, .admin-page .single-recommendation-myinfo-second-bottom input::-webkit-input-placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .admin-page .single-myinfo-form input::-moz-placeholder, .admin-page .single-recommendation-myinfo-second input::-moz-placeholder, .admin-page .single-recommendation-myinfo-second-bottom input::-moz-placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .admin-page .single-myinfo-form input:-ms-input-placeholder, .admin-page .single-recommendation-myinfo-second input:-ms-input-placeholder, .admin-page .single-recommendation-myinfo-second-bottom input:-ms-input-placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .admin-page .single-myinfo-form input::-ms-input-placeholder,.admin-page .single-recommendation-myinfo-second input::-ms-input-placeholder, .admin-page .single-recommendation-myinfo-second-bottom input::-ms-input-placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .admin-page .single-myinfo-form input::placeholder,.admin-page .single-recommendation-myinfo-second input::placeholder,.admin-page .single-recommendation-myinfo-second-bottom input::placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .admin-page .myinfo-teacher-name {
  position: relative;
}
.admin-page .myinfo-teacher-name::after {
  content: "";
  position: absolute;
  background: url('/media/recommendation/down-arrow.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10px;
  height: 7px;
  right: 10px;
  bottom: 15px;
  pointer-events: none;
}
.admin-page .myinfo-teacher-name select {
  width: 100%;
  border: 1px solid #3F4254;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
}
.admin-page .myinfo-teacher-name option {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
}
.admin-page .recommendation-myinfo-second-form-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 15px;
}
.admin-page .recommendation-myinfo-second-form-heading p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: capitalize;
  padding-left: 10px;
}
.admin-page .recommendation-myinfo-second-form-heading img {
 width: 12px;
 height: auto;
 margin-left: 10px;
}
.admin-page .custom_checkbox_area.recommendation_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  padding-bottom: 25px;
  padding-left: 10px;
}
.admin-page .custom_checkbox_area.recommendation_checkbox.middle {
  padding-top: 138px;
}
.admin-page .recommendation-myinfo-bottom-design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.admin-page .recommendation-myinfo-second-form-right {
  padding-top: 52px;
  padding-left: 20px;
}
.admin-page .recommendation_checkbox .single-recommendation-checkbox:nth-child(1) {
  margin-right: 20px;
}
.admin-page .single-recommendation-checkbox label span {
  cursor: pointer;
}
.admin-page .add-anoter-button a img {
  width: 18px;
  height: auto;
  margin-right: 5px;
  margin-top: -6px;
}
.admin-page .add-anoter-button a {
  font-weight: 300;
  font-size: 12px;
  color: #0078BF;
  text-transform: inherit;
}
.admin-page .recommendation-myinfo-bottom-peragraf p {
  font-weight: 300;
  font-size: 11px;
  color: #3F4254;
  max-width: 515px;
  width: 100%;
  line-height: 1.3;
  padding-top: 10px;
}
.admin-page .all-same-button-design-with-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
}
/*================================================================================================
  ====                            END REQUEST RECOMMENDATION INFO PAGE CSS                    ====
  ================================================================================================*/


/*================================================================================================
  ====                     START REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS              ====
  ================================================================================================*/
  .admin-page .recommendation-salf-evaluation,.admin-page .recommendation-reflection {
    background: #FFFFFF;
  }
  .admin-page .recommendation-myinfo-form-heading-text p:nth-child(2) {
    padding-top: 0;
    font-weight: 300;
    font-size: 11px;
    color: #3F4254;
    text-transform: inherit;
    max-width: 500px;
    width: 100%;
  }
  .admin-page .recommendation-salf-evaluation-form form {
   max-width: 510px;
   width: 100%;
 }
 .admin-page .adjetive-input-textarea-content {
  padding-bottom: 25px;
}
.admin-page .recommendation-salf-evaluation-form-textarea {
  padding-bottom: 35px;
}
.admin-page .recommendation-salf-evaluation-form-textarea label, .admin-page .recommendation-salf-evaluation-form-peragraf p, .admin-page .single-recommendation-slef-input-textarea-right label {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  display: block;
  text-transform: inherit;
  line-height: 1.3;
}
.admin-page .recommendation-salf-evaluation-form-peragraf p:nth-child(1) {
  padding-bottom: 15px;
}
.admin-page .recommendation-salf-evaluation-form-textarea label {
 margin-bottom: 5px;
}
.admin-page .single-recommendation-self-input-textarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
  padding-top: 25px;
}
.admin-page .single-recommendation-slef-input-textarea-right {
  max-width: 490px;
  width: 100%;
}
.admin-page .recommendation-salf-evaluation-form-textarea textarea,.admin-page .single-recommendation-slef-input-textarea-right textarea {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  padding: 5px 10px;
  border: 1px solid #3F4254;
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: none;
  height: 90px;
}
.admin-page .single-recommendation-slef-input-textarea-right input {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 padding: 5px 10px;
 border: 1px solid #3F4254;
 max-width: 185px;
 width: 100%;
 border-radius: 5px;
 margin-bottom: 15px;
}
.admin-page .single-recommendation-slef-input-textarea-right input::-webkit-input-placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.admin-page .single-recommendation-slef-input-textarea-right input::-moz-placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.admin-page .single-recommendation-slef-input-textarea-right input:-ms-input-placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.admin-page .single-recommendation-slef-input-textarea-right input::-ms-input-placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.admin-page .single-recommendation-slef-input-textarea-right input::placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.admin-page .single-recommendation-slef-input-textarea-right textarea {
  height: 60px;
}
.admin-page .recommendation-salf-evaluation-form-textarea p {
  font-weight: 300;
  font-size: 11px;
  color: #3F4254;
  padding-top: 8px;
}
.admin-page .single-recommendation-slef-input-textarea-left p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  padding-right: 12px;
}
/*================================================================================================
  ====                     END REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS                ====
  ================================================================================================*/

/*================================================================================================
  ====                      START REQUEST RECOMMENDATION REFLECTION PAGE CSS                  ====
  ================================================================================================*/

 .admin-page .recommendation-salf-evaluation-form-textarea.reflection label {
    margin-bottom: 10px;
  }
  .admin-page .recommendation-salf-evaluation-form-textarea.reflection {
    padding-bottom: 45px;
  }
/*================================================================================================
  ====                       END REQUEST RECOMMENDATION REFLECTION PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                                  START SETTINGS PAGE CSS                               ====
  ================================================================================================*/
  .admin-page .change-password-area {
    padding-bottom: 900px;
  }
  .admin-page .change-password-content {
    max-width: 700px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-left: 60px;
    padding-top: 20px;
  }
  .admin-page .change-password-left-content {
    background: #FFFFFF;
    padding: 5px 0;
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    max-width: 270px;
    width: 100%;
    border-radius: 4px;
  }
 .admin-page .change-password-left-content button {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    width: 100%;
    text-align: left;
    padding: 12px 20px;
    background: #FFFFFF;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page .change-password-left-content button:hover {
    background: #DBE6EE;
  }
  .admin-page .change-password-right-content {
    background: #FFFFFF;
    padding: 30px 40px;
    max-width: 415px;
    width: 100%;
    border-radius: 4px;
  }
 .admin-page .change-password-right-heading p {
    font-weight: 300;
    font-size: 20px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
    padding-bottom: 20px;
  }
  .admin-page .single-change-password-right-input {
    padding-bottom: 15px;
  }
 .admin-page .single-change-password-right-input label {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .admin-page .single-change-password-right-input input {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
    border: 1px solid #3F4254;
    width: 100%;
    padding: 5px 15px;
    border-radius: 5px;
    outline: none;
  }
 .admin-page .single-change-password-right-input input::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .single-change-password-right-input input::-moz-placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .single-change-password-right-input input:-ms-input-placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .single-change-password-right-input input::-ms-input-placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .single-change-password-right-input input::placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .single-change-password-right-input input:focus {
    border: 2px solid #0078BF;
  }
  .admin-page .change-password-submit-button {
    text-align: center;
  }
  .admin-page .change-password-submit-button input {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    -webkit-box-ordinal-group: ;
    -ms-flex-order: ;
    order: ;
    border: 1px solid #0078BF;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page .change-password-submit-button input:hover {
    background: transparent;
    color: #0078BF;
  }
 .admin-page  .field-icon {
    float: right;
    position: relative;
    z-index: 2;
    top: -30px;
    right: 10px;
    cursor: pointer;
  }
  .admin-page .field-icon::before {
    content: "";
    position: absolute;
    background: url('/media/enrollment/eyes.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 13px;
    right: 0;
    top: 2px;
  }
/*================================================================================================
  ====                                   END SETTINGS PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                               Start Progress tracking pages css                        ====
  ================================================================================================ */
  /* start progress_tracking_main_area css*/
  .admin-page .pro_college_heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 25px;
  }
  .admin-page .left_pro_college_area h3 {
    font-weight: 300;
    color: #05233B;
    font-size: 20px;
    margin-top: 12px;
  }
  .admin-page .right_pro_college_area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .pro_download_area {
    position: relative;
  }
  .admin-page .pro_search_clg {
    margin-right: 30px;
  }
  .admin-page .pro_download_area .down-icon {
    position: absolute;
    right: -95px;
    top: -16px;
  }
  .admin-page .pro_download_area .dropdown-menu {
    min-width: 170px !important;
    border: 1px solid #E4E6EF;
    width: 100%;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 6px 0 !important;
    position: absolute !important;
    left: -75px !important;
    top: 5px !important;
  }
 .admin-page .drop_as{
    font-family: 'Avenir';
    font-weight: 300;
    color: #3F4254;
    padding: 10px 0;
    padding-left: 18px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
	padding: 10px 18px;
  }
  .admin-page .drop_as:hover{
    background: #DBE6EE;
  }
  /*progress tracking pages progress*/
 .admin-page .rt_progress_funel {
    background: url('/media/progress/rt_progress.png') no-repeat scroll 0 0 / cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 45px;
    cursor: pointer;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    position: relative;
  }
  .admin-page .rt_progress_main_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  .admin-page .left_rt_progress_sys {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .admin-page .rt_progress_txt h3{
    font-weight: 500;
    color: #FFF;
    text-align: center;
  }
 .admin-page .right_rt_progress_sys {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(75% - 15px);
    flex: 0 0 calc(75% - 15px);
    margin-left: 15px;
    background: #FFF;
    padding: 13px 15px;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-radius: 5px;
  }
  .admin-page .rt_progress_bar_design {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .rt_progress_left_side h5 {
    font-size: 18px;
    font-weight: 300;
    color: #05233B;
    margin-bottom: 13px;
  }
 .admin-page .rt_progress_left_side p {
    font-size: 12px;
    color: #05233B;
    font-weight: 300;
    margin-top: 12px;
  }
  .admin-page .rt_progress_left_side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
  }
  .admin-page .rt_progress_right_side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(5% - 10px);
    flex: 0 0 calc(5% - 10px);
  }
  .admin-page .rt_progress_left_side .myclassroom-single-left-cross-progress {
    width: 100%;
    height: 16px;
    border-radius: 50px;
  }
  .admin-page .rt_progress_left_side .barra-nivel {
    height: 15px;
    background: #00A2A7;
    border-radius: 50px;
  }
 .admin-page .rt_progress_right_side h5, .admin-page .rt_progress_right_side span {
    color: #05233B;
    font-weight: 300;
    font-size: 12px;
  }
  /* startpt_progress_main_area css*/
 .admin-page .pt_view_main_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 45px;
  }
  .admin-page .pt_left_view h3{
    font-weight: 400;
    color: #3F4254;
    font-size: 14px;
  }
 .admin-page .pt_right_view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .pt_right_view a{
    margin-left: 16px;
  }
  .admin-page .btn_style_3 {
    color: #0078BF;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    background: transparent;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .admin-page .btn_style_3:hover{
    color: #FFF;
    background: #0078BF;
  }
  .admin-page .btn_style_4{
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    background: #0078BF;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
 .admin-page .btn_style_4:hover{
    color: #0078BF;
    background: transparent;
  }
  .admin-page .pt_right_view .btn_style_3.change {
    background: #05233B;
    color: #FFFFFF;
  }
 .admin-page .pt_right_view .btn_style_3.change:hover {
    background: transparent;
    color: #05233B;
    border: 1px solid #05233B;
  }
/*================================================================================================
  ====                                End Progress tracking pages css                         ====
  ================================================================================================ */

 /*================================================================================================
  ====                           Start Messages Page (11)(Munna)                                ====
  ================================================================================================*/
 .admin-page .mu_messages_page .mu_messages_all {
    padding: 17px 0px 0px 0px;
  }
 .admin-page .mu_messages_all{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
 .admin-page .mu_discussion_header_top_text p{
    font-size: 16px;
    color: #05233B;
    margin-left: 20px;
    font-weight: 300;
  }
  /*===================== Start Right Site Messages page =================*/
  .admin-page .mu_messages_page_left_site {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
            flex: 0 0 25%;/*
            padding-bottom: 100px;*/
            background: #FFF;
          }

          /* Start Message Text*/
          .admin-page .mu_messages_area{
            background: #FFF;
            padding: 30px 45px 20px 30px;
          }
         .admin-page  .mu_messages_left img {
            width: 25px;
            margin-right: 10px;
          }
          .admin-page .mu_messages {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            border-bottom: 3px solid #145B94;
            padding-bottom: 10px;
          }
         .admin-page .mu_messages_left{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
         .admin-page .mu_messages_left h4{
            font-size: 20px;
            color: #145B94;
          }
          .admin-page .mu_messages_right {
            background: #00A2A7;
            padding: 4px 10px;
            border-radius: 5px;
          }
         .admin-page .mu_messages_right p{
            font-size: 12px;
            color: #fff;
          }
          /* End Message Text*/

          /* Start Accordion Messages box*/
         .admin-page .mu_accordion2 {
            width: 100%;
            max-width: 360px;
            background: #FFF;
          }
          .admin-page .mu_accordion2 .link {
            cursor: pointer;
            display: block;
            padding: 10px 30px;
            color: #3E3F42;
            font-size: 14px;
            position: relative;
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
          }
         .admin-page .mu_accordion2 .link:hover{
            background: #DBE6EE;
          }
         .admin-page .mu_accordion2 li i {
            position: absolute;
            top: 14px;
            left: 12px;
            font-size: 18px;
            color: #595959;
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
          }
          .admin-page .mu_accordion2 li i.fa-chevron-down {
            right: 25px;
            left: auto;
            font-size: 12px;
            color: #A6ADB1;
          }
          .admin-page .mu_accordion2 li.open i.fa-chevron-down {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
          /*.accordion li.open .link { background: #DBE6EE; }*/

          /*.accordion li.open i { color: #3E3F42; }*/
          .admin-page .mu_submenu2 {
            display: none;
            background: #444359;
            font-size: 14px;
          }
         .admin-page .mu_submenu2 li { 
            background: #FFF; 
          }
         .admin-page .mu_submenu2 a {
            display: block;
            text-decoration: none;
            color: #3E3F42;
            padding: 12px;
            padding-left: 50px;
            -webkit-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
          }
          .admin-page .mu_submenu2 a:hover {
            background: #DBE6EE;
            color: #3E3F42;
          }
          /* End Accordion Messages box*/

          /*Start Recent Client */
         .admin-page .mu_recent_client li { 
            background: #FFF; 
          }
          .admin-page .mu_recent_client li img {
            margin-right: 17px;
          }
          .admin-page .mu_li_flex{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_recent_client li img{
            width: 28px;
            height: auto;
          }
         .admin-page .mu_accrodion_li_flex {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 12px 30px;
          }
         .admin-page .mu_accrodion_li_flex p {
            color: #3F4254;
            font-size: 14px;
            line-height: 1.6;
          }
          /*End Recent Client */

          /*Start Button*/
         .admin-page .mu_message_button {
            background: #FFF;
            padding: 25px 20px 0px 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
         .admin-page .mu_message_button a {
            text-align: center;
            font-size: 14px;
            color: #0078BF;
            border: 1px solid #0078BF;
            border-radius: 20px;
            padding: 6px 0;
            margin-bottom: 16px;
            font-weight: 400;
            max-width: 240px;
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
          }
         .admin-page .mu_message_button a:hover {
            background: #0078BF;
            color: #FFF;
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
          }
          /*End Button*/
          /*===================== End LEFT Site Messages page =================*/

          /*===================== Start Right Site Messages page =================*/
          .admin-page .mu_right_site_border{
            border: 1px solid #EAEDF3;
            border-radius: 6px 5px 0 0;
          }
          .admin-page .mu_messages_page_right_site {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
          }
          /* Start Chart Text*/
          .admin-page .mu_chart_area {
            width: 270px;
            background: #E4E6EF;
            padding: 20px 0 28px 0;
            border: 4px solid #FFF;
          }
         .admin-page .mu_chart_left img {
            width: 25px;
            margin-right: 10px;
          }
          .admin-page .mu_chart {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }
          .admin-page .mu_chart_left{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_chart_left h4{
            font-size: 20px;
            color: #707070;
          }
         .admin-page .mu_chart_right {
            background: #707070;
            padding: 4px 10px;
            border-radius: 5px;
            margin-left: 12px;
          }
         .admin-page .mu_chart_right p{
            font-size: 12px;
            color: #fff;
          }
          /* End Chart Text*/

          /*=======Start search Header*/
         .admin-page .mu_chart_header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #FFF;
            padding: 23px 15px 15px 27px;
            border-radius: 6px 5px 0 0;
          }
          .admin-page .mu_chart_header.mu_chart_header2 {
            -webkit-box-pack: normal;
            -ms-flex-pack: normal;
            justify-content: normal;
          }
          /*Start Header button*/
          .admin-page .mu_header_btn {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
          .admin-page .mu_header_btn.mu_header_btn2 {
            margin-right: 40px;
          }
          .admin-page .mu_header_btn a {
            display: block;
            font-size: 14px;
            color: #0078BF;
            border: 1px solid #0078BF;
            border-radius: 20px;
            padding: 6px 20px;
            font-weight: 400;
            margin-right: 16px;
          }
          .admin-page .mu_header_btn a:hover {
            background: #0078BF;
            color: #FFF;
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
          }
         .admin-page .mu_header_btn.mu_header_btn12 a {
            margin: 0px;
          }
          .admin-page .mu_header_btn.mu_header_btn12f a {
            margin: 0px 12px 0px 0px;
          }
         .admin-page .mu_header_btn.mu_header_btn12f{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
         .admin-page .mu_header_btn.mu_header_btn12f img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
          /*End Header button*/

          /*Start search Bar*/
         .admin-page .mu_search_bar.mu_search_bar_reply {
            -webkit-box-flex: inherit;
            -ms-flex: inherit;
            flex: inherit;
            max-width: 352px;
            width: 100%;
          }
          /*End search Bar*/

          /*Start Next button*/
         .admin-page .mu_header_next_btn img{
            width: 8px;
            height: auto;
          }
          .admin-page .pagination_left_icon {
            padding-right: 33px;
          }
         .admin-page .pagination_right_icon {
            padding-left: 33px;
          }
          .admin-page .mu_header_next_btn{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_pagination_count_button a {
            font-weight: 400;
            font-size: 14px;
            background: transparent;
            color: #3E3F43;
            padding: 5px 12px;
            border-radius: 4px;
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
            margin: 0 3px;
          }
          .admin-page .mu_pagination_count_button a.active {
            background: #0078BF;
            color: #FFF;
            padding: 5px 12px;
            border-radius: 4px;
          }
          .admin-page .mu_pagination_count_button a:hover {
            background: #0078BF;
            color: #FFF;
            border-radius: 4px;
          }
          /*End Next button*/
          /*======End search Header*/

          /*======Start icon Header*/
          .admin-page .mu_icon_header {
            background: #FFF;
            padding: 9px 27px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            border-top: 1px solid #EAEDF3;
          }
          .admin-page .mu_icon_header_left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_icon_header_left_flex{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          /*Start check box*/
          .admin-page .mu_icon_header_checkbox{
            margin-right: 10px;
          }
          .admin-page .mu-form-group {
            display: block;
          }

          .admin-page .mu-form-group input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }

          .admin-page .mu-form-group label {
            position: relative;
            cursor: pointer;
          }

          .admin-page .mu-form-group label::before {
            content: '';
            -webkit-appearance: none;
            background-color: #F6F7F9;
            border: 1px solid #D8DCE6;
            -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 7px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 4px;
          }

          .admin-page .mu-form-group input:checked + label::after {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            left: 5px;
            width: 7px;
            height: 12px;
            border: solid #707070;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          /*End check box*/

          /*Start icons*/
          .admin-page .mu_icon_header_icons{
            margin-right: 15px;
          }
          .admin-page .mu_icon_header_icons ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_icon_header_icons ul li a{
            margin: 0 13px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_icon_header_icons ul li span{
            color: #D8DCE6;
          }
          .admin-page .mu_icon_header_icons ul li a img{
            width: 14px;
            height: auto;
          }
          /*End icons*/
          .admin-page .mu_filter_announcement p{
            font-size: 14px;
            color: #3E3F43;
            font-weight: 300;
          }
         .admin-page .mu_icon_header_right p{
            font-size: 14px;
            color: #3E3F43;
          }
          /*======End icon Header*/

          /*========Start People List*/
         .admin-page .mu_people_list{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            background: #FFF;
            padding: 16px 27px;
            border-top: 1px solid #EAEDF3;
          }
          /*Start People Left Site*/
         .admin-page .mu_people_list_left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_peo_left_img {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 0 15px 0 6px;
          }
          .admin-page .mu_peo_left_img a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
          .admin-page .mu_peo_left_img img{
            width: 16px;
            height: auto;
          }
          .admin-page .mu_people_left_left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 220px;
          }
          .admin-page .mu_people_profile{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
         .admin-page .mu_people_profile img{
            width: 28px;
            height: auto;
            margin-right: 10px;
          }
         .admin-page .mu_people_profile p{
            font-size: 14px;
            color: #3E3F43;
          }

         .admin-page .mu_people_left_right p{
            font-size: 14px;
            color: #3E3F43;
          }
          /*Start People Left Site*/

          /*Start People Right Site*/
          .admin-page .mu_people_list_right{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_people_list_right p{
            font-size: 14px;
            color: #3E3F43;
          }
          .admin-page .mu_people_list_margin {
            margin: 0 20px 0 12px;
          }
          .admin-page .mu_people_list_right a img{
            width: 20px;
            height: auto;
          }
         .admin-page .mu_people_list_right a{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .admin-page .mu_people_right_img {
            width: 16px;
            height: auto;
            margin-right: 15px;
            cursor: pointer;
          }
          /*End People Right Site*/
          /*======End People List*/

          /*===================== End Right Site Messages page =================*/

          /*Start Bottom Next Page*/
         .admin-page .mu_main_bottom {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 10px 0;
          }
          .admin-page .mu_main_bottom_left{
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
          }
         .admin-page .mu_main_bottom_right{
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
          }
         .admin-page .mu_main_bottom_next_change{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
          /*End Bottom Next Page*/

   /*===============================================================================================
  ====                           End Messages Page (11)(Munna)                                  ====
  ================================================================================================*/

  /*================================================================================================
  ====                           Start Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/
  .admin-page .mu_single_letter {
    background: #FFF;
    padding: 0 0 80px 27px;
    border-top: 1px solid #EAEDF3;
  }
  /*Start Letter Top Button*/
  .admin-page .mu_letter_top_button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 17px 27px 17px 0;
  }
  /*End Letter Top Button*/

  /*Start Letter Content Area*/
  .admin-page .mu_profile_img_area_top{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 37px;
    padding-right: 27px;
  }
  .admin-page .mu_profile_img_area_left{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_profile_img_area_left img{
    width: 52px;
    height: auto;
    margin-right: 19px;
  }
  .admin-page .mu_profile_img_area_left p{
    font-size: 16px;
    color: #3E3F43;
  }
  .admin-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2 a {
    margin-left: 15px;
  }

 .admin-page .mu_profile_img_area_content h6{
    font-size: 16px;
    color: #3E3F43;
    font-weight: 300;
  }
  .admin-page .mu_profile_img_area_content p{
    font-size: 14px;
    color: #3E3F43;
    margin-top: 0px; /*19px;*/
  }
  /*End Letter Content Area*/

  /*Start Letter bottom Button*/
  .admin-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 a {
    margin: 0 15px 0 0 ;
  }
 .admin-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 {
    margin-top: 100px;
  }
  /*End Letter bottom Button*/
  /*================================================================================================
  ====                           End Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/

    /*================================================================================================
  ====                           Start Discussions Page (13)(Munna)                     ====
  ================================================================================================*/
  .admin-page .mu_main_discussion_page{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /*================================ Start Left Site discussion Page ============================*/
  .admin-page .mu_main_left_discussion {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    background: #FFF;
  }
  /*Overflow Scroll*/
  .admin-page .mu_message_scroll_left{
    height: 715px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*Start left site header*/
  .admin-page .mu_dis_left_header{
    padding: 22px 30px 0px 30px;
    border-bottom: 1px solid #EAEDF3;
  }
  .admin-page .mu_dis_left_header ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
 .admin-page .mu_dis_left_header ul li a{
    font-size: 14px;
    color: #3F4254;
    border-bottom: 3px solid transparent;
    padding-bottom: 21px;
  }
 .admin-page .mu_dis_left_header ul li a:hover{
    border-bottom: 3px solid #145B94;
    color: #3E3F42;
  }
  .admin-page .mu_dis_left_li_flex a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_left_li_flex a img{
    width: 15px;
    height: auto;
    margin-right: 5px;
  }
  /*End left site header*/

  /*Start My Teacher*/
  .admin-page .mu_my_teacher_dis{
    border-bottom: 1px solid #EAEDF3;
  }
  .admin-page .mu_my_teacher_dis p{
    font-size: 12px;
    color: #181C32;
    padding: 13px 30px;
    font-weight: 300;
  }
 .admin-page .mu_dis_teacher_img {
    border-bottom: 1px solid #EAEDF3;
    padding: 8px 20px 15px 20px;
  }
 .admin-page .mu_dis_teacher_img ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .admin-page .mu_dis_teacher_img ul li a img{
    width: 40px;
    height: auto;
  }
  /*End My Teacher*/

  /*Start Dropdown Menu*/
  .admin-page .mu_dis_dropdown_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 21px 15px 18px 30px;
    border-bottom: 1px solid #EAEDF3;
  }
 .admin-page .mu_header_btn.mu_dis_le_dropdown_btn a {
    margin: 0;
    padding: 6px 36px;
  }
  .admin-page .mu_dis_le_dropdown ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .mu_dis_le_dropdown_li_btn{
    font-size: 14px;
    color: #3F4254;
    margin-right: 50px;
  }
 .admin-page .mu_dis_li_img img {
    width: 12px !important;
    height: 8px !important;
    right: 30px;
    top: 12px;
    position: absolute;
  }
  /*End Dropdown Menu*/

  /*Start Discussion Full Area*/
 .admin-page .mu_dis_content_left {
    border-bottom: 1px solid #EAEDF3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px 7px;
    background: #FFFFFF;
    cursor: pointer;
  }
 .admin-page .mu_dis_content_left:hover {
    background: #F6F9FD;
  }
 .admin-page .mu_dis_content_description_left p:first-child{
    font-size: 11px;
    color: #9EA0A5;
  }
 .admin-page .mu_dis_con_pad h4{
    font-size: 16px;
    color: #3E3F42;
    font-weight: 700;
    margin-bottom: 3px;
  }
 .admin-page .mu_dis_con_pad p{
    font-size: 12px !important;
    margin-bottom: 7px;
    color: #6B6C6F;
  }
 .admin-page .mu_dis_content_description_left p{
    font-size: 11px;
    color: #6B6C6F;
  }
 .admin-page .mu_dis_img_con_flex_left{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
 .admin-page .mu_dis_con_img_img1 {
    margin-bottom: -21px;
  }
 .admin-page .mu_dis_content_img_posi img {
    width: 40px;
    height: auto;
  }
  .admin-page .mu_dis_con_p_pos a {
    font-size: 12px;
    color: #FFF;
    background: #009651;
    padding: 3px 10px;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
  }
  .admin-page .mu_dis_content_img_posi{
    position: relative;
    text-align: center;
    margin-bottom: 15px;
  }
  .admin-page .mu_dis_con_p_pos {
    position: absolute;
    bottom: -13px;
    right: 32px;
  }

  /*Start Class Discussion Area*/
  .admin-page .mu_dis_con_single_img img{
    width: 25px;
    height: auto;
    border: 2px solid #FFF;
    border-radius: 50%;
  }
  .admin-page .mu_dis_con_img_to_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .admin-page .mu_dis_con_img_to_btn2{
    position: absolute;
    bottom: 18px;
    left: 15px;
  }
  .admin-page .mu_dis_con_img_to_btn3{
    position: absolute;
    bottom: 20px;
    left: 27px;
  }
  .admin-page .mu_dis_con_single_img:nth-child(2){
    margin-left: -11px;
  }
  .admin-page .mu_dis_con_single_img:nth-child(3){
    margin-left: -11px;
  }
  .admin-page .mu_dis_con_single_img:nth-child(4){
    margin-left: -11px;
  }
  .admin-page .mu_dis_con_single_img:nth-child(5){
    margin-left: -11px;
  }
  .admin-page .mu_dis_con_single_img:nth-child(6){
    margin-left: -11px;
  }
  .admin-page .mu_dis_con_single_img button{
    font-size: 9px;
    color: #FFF;
    background: #707070;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
  }
  .admin-page .mu_dis_content_img_left {
    width: 190px;
    position: relative;
  }
  .admin-page .mu_dis_con_img_img1 img{
    width: 15px;
    height: auto;
  }
  .admin-page .mu_dis_content_img_posi.mu_dis_content_img_posi2 {
    margin-bottom: 0px;
  }
  .admin-page .mu_dis_con_pad.mu_dis_con_pad2 h4 {
    margin-bottom: 13px;
  }
 .admin-page .mu_dis_con_pad.mu_dis_con_pad2 p {
    font-size: 14px !important;
    line-height: 1.5;
  }

 .admin-page .mu_dis_time_left p{
    font-size: 11px;
    color: #9EA0A5;
    margin-bottom: 30px;
  }
  .admin-page .mu_dis_time_left a {
    color: #fff;
    background: #145B94;
    padding: 2px 8px;
    border-radius: 50%;
    font-size: 12px;
  }
  /*End Full Discussion Area*/

  /*Start Groups Full Area*/
  .admin-page .mu_dis_content_img_posi.mu_dis_content_img_posi3 {
    margin-bottom: -11px;
  }
 .admin-page .mu_dis_time_left.mu_dis_time_left_p2 p:last-child {
    margin-bottom: 0;
  }
  /*End Groups Full Area*/

  /*Start Chart Area*/
  .admin-page .mu_dis_con_img_img1.mu_dis_con_img_img1reply2 {
    margin-bottom: -30px;
  }
  .admin-page .mu_dis_con_single_img.mu_dis_con_single_img_new img {
    width: 40px;
    height: auto;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-left: -20px;
    margin-top: -5px;
  }
  /*Start Chart Area*/
  /*================================ End Left Site discussion Page ============================*/

  /*================================ Start Right Site discussion Page ============================*/
  .admin-page .mu_main_right_discussion {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 67%;
    flex: 0 0 67%;
  }
  /*Overflow Scroll*/
  .admin-page .mu_message_scroll_right {
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*Start Chat Box*/
  .admin-page .mu_messages_area.mu_messages_area2 {
    width: 270px;
    padding: 23px 52px 18px 62px;
    margin-left: -100px;
  }
  .admin-page .mu_chart_area.mu_chart_area2 {
    padding: 27px 10px 15px 0px;
  }
  .admin-page .mu_messages.mu_messages2 {
    padding-bottom: 6px;
  }
  /*End Chat Box*/

  /*Start right site header*/
 .admin-page .mu_dis_right_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 30px;
    border-left: 1px solid #EAEDF3;
    background: #FFF;
  }
 .admin-page .mu_dis_right_header_left p{
    font-size: 18px;
    color: #3E3F42;
  }
 .admin-page .mu_dis_right_header_rtr{
    margin-right: 15px;
  }
 .admin-page .mu_dis_right_header_rtr ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
 .admin-page .mu_dis_right_header_rtr img{
    width: 28px;
    height: auto;
  }

  .admin-page .mu_dis_right_header_right{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_right_header_rtl2 img{
    width: 28px;
    height: auto;
    margin-right: 30px;
  }
  .admin-page .mu_dis_right_header_rtl3 img {
    width: 20px ;
    height: auto;
  }
  .admin-page .mu_dis_con_single_img_reply button {
    padding: 7px;
  }
  .admin-page .mu_dis_right_header_rtr a {
    margin-right: 5px;
  }
  /*End right site header*/

  /*Start Right profile Content Area*/
 .admin-page .mu_right_profile_area {
    background: #FFF;
    padding: 25px 30px 10px 30px;
    border: 1px solid #EAEDF3;
  }
 .admin-page .mu_dis_profile_img{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_profile_img_img img{
    width: 40px;
    height: auto;
    margin-right: 10px;
  }
 .admin-page .mu_dis_profile_img_title_flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .mu_dis_profile_img_title_flex h5{
    font-size: 16px;
    color: #3E3F42;
    margin-right: 10px;
  }
 .admin-page .mu_dis_profile_img_title_flex p{
    font-size: 9px;
    color: #FFF;
    background: #3F4254;
    padding: 2px 9px;
    border-radius: 3px;
    font-weight: 700;
  }
 .admin-page .mu_dis_profile_img_title a {
    font-size: 12px;
    color: #145B94;
    border-bottom: 1px solid #145B94;
    display: inline-block;
    line-height: 10px;
  }
 .admin-page .mu_dis_profile_content_rt{
    margin-bottom: 10px;
  }
 .admin-page .mu_dis_profile_content_rt img{
    width: 14px;
    height: auto;
    margin-right: 7px;
  }
 .admin-page .mu_dis_profile_content_rt p{
    font-size: 12px;
    color: #3E3F42;
  }
 .admin-page .mu_dis_profile_content_rt{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_profile_content_rb p{
    font-size: 12px;
    color: #3F4254;
  }
 .admin-page .mu_profile_img_content_flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .admin-page .mu_dis_profile_cont p{
    font-size: 14px;
    color: #3E3F42;
  }
  /*End Right profile Content Area*/

  /*Start PDF Area*/
  .admin-page .mu_dis_pdf_flex_box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 16px;
    background: #F3F6F9;
    margin-bottom: 5px;
  }
 .admin-page .mu_dis_pdf_right {
    margin-bottom: 5px;
  }
 .admin-page .mu_dis_pdf_flex_left img{
    width: 20px;
    height: 20px;
    height: auto;
    margin-right: 10px;
  }
  .admin-page .mu_dis_pdf_flex_left p{
    font-size: 14px;
    color: #0078BF;
  }
  .admin-page .mu_dis_pdf_flex_right a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_pdf_flex_right a img{
    width: 20px;
    height: auto;
  }
  .admin-page .mu_dis_pdf_flex_right a p{
    margin-right: 10px;
    font-size: 14px;
    color: #0078BF;
  }
 .admin-page .mu_dis_pdf_flex_left{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
  }
  .admin-page .mu_dis_pdf_radio{
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 50%;
    text-align: right;
  }
  .admin-page .mu_dis_pdf_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .admin-page .mu_dis_pdf_right a {
    font-size: 12px;
    color: #145B94;
    border-bottom: 1px solid #145B94;
    display: inline-block;
    line-height: 10px;
  }
  .admin-page .mu_disc_pdf_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 15px;
  }
  /*End PDF Area*/

  /*Start message area 1*/
  .admin-page .mu_dis_message_area1{
    padding: 19px 23px 8px 18px;
  }
  .admin-page .mu_message_sin_content {
    background: #E4E6EF;
    padding: 10px 12px 20px 17px;
    border-radius: 0 8px 8px 8px;
    max-width: 500px;
    width: 100%;
  }
  .admin-page .mu_dis_Single_message_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .admin-page .mu_message_sin_img img{
    width: 28px;
    height: auto;
    margin-right: 15px;
  }
  .admin-page .mu_message_conte_flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .admin-page .mu_message_conte_flex p{
    font-size: 12px;
    color: #05233B;
  }
  .admin-page .mu_message_sin_con_par p{
    font-size: 14px;
    color: #05233B;
  }
 .admin-page .mu_dis_Single_message_box.mu_dis_message_area1_box2 {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .admin-page .mu_message_sin_content.mu_message_sin_content2 {
    border-radius: 8px 0 8px 8px;
  }
  .admin-page .mu_message_sin_content2{
    background: #C1D3E2;
  }
  .admin-page .mu_new_messag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
  }
  .admin-page .mu_new_messag a{
    font-size: 14px;
    color: #3E3F42;
    margin-right: 10px;
  }
  .admin-page .mu_new_messag p{
    font-size: 12px;
    color: #9EA0A5;
  }
  /*End message area 1*/

  /*Start message area 2*/
 .admin-page .mu_dis_message_area2 {
    background: #E5F1F3;
    padding: 28px 0 5px 31px;
  }
  .admin-page .mu_message_sin_con_par2 p:nth-child(1){
    margin-bottom: 20px;
  }

  .admin-page .mu_message_typein{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_message_typein_prof{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_message_typein_prof img{
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  .admin-page .mu_message_typein_prof p{
    font-size: 14px;
    color: #05233B;
    margin-right: 10px;
  }
  .admin-page .mu_message_typein_type p{
    font-size: 12px;
    color: #7E8299;
  }
  .admin-page .mu_dis_message_option {
    text-align: center;
    margin-top: 50px;
  }

 .admin-page .mu_dis_Single_message_box_option{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .mu_dis_message_option a img{
    width: 20px;
    height: auto;
  }
  /*Start message area 2*/

  /*Start Type a message*/
  .admin-page .mu_type_a_message{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #FFF;
    padding: 25px 30px;
  }
  .admin-page .mu_type_a_message_text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
  }
  .admin-page .mu_type_a_message_text input{
    font-size: 14px;
    color: #3F4254;
    border: none;
    outline: none;
    background: #FFF;
    width: 100%;
  }
 .admin-page .mu_type_a_message_img{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }
  .admin-page .mu_type_a_message_img a img{
    width: 20px;
    height: 18px;
    margin-left: 15px;
    cursor: pointer;
  }

  /*Upload File Style*/
  .admin-page .fileUpload {
    position: relative;
    overflow: hidden;
  }
  .admin-page .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  /*End Type a message*/
  /*================================ End Right Site discussion Page ============================*/
    /*================================================================================================
  ====                           End Discussions Page (13)(Munna)                     ====
  ================================================================================================*/


  /*================================================================================================
  ====                                    LEARNING PAGE CSS START                                  ====
  ================================================================================================*/
  .admin-page .ra_science_area {
    background-color: #F3F6F9;
    padding: 0 0 24px 0;
  }
  .admin-page .new-project-learning-content-area {
    display: none;
  }
 .admin-page .new-project-learning-content-area.update {
    display: block;
  }
  .admin-page .new-project-learning-content-area.update-two {
    display: none !important;
  }
  .admin-page .ra_science_main_area {
    padding-top: 20px;
  }
  .admin-page .ra_science{
    background: #fff;
    border-radius: 3px;
    padding: 31px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #F3F6F9;
  }
  /*tor area scroll start */
  .admin-page .ra_science_top_calendar {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 .admin-page .ra_science_top_calendar ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .ra_science_top_calendar ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
    
  }
  .admin-page .ra_science_top_calendar ul li a span {
    font-size: 14px;
    font-weight: 300;
    margin-left: 20px;
    color: #0078BF;
    -webkit-text-decoration: underline #0078BF;
    text-decoration: underline #0078BF;
  }
  .admin-page .ra_science_top_calendar ul li a img {
    width: 10px;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0px;
    margin-left: 6px;
  }
  /*tor area scroll end */
  
  .admin-page .ra_jackson_top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .ra_jackson_top .dropdown-menu.adjust {
    top: 13px !important;
    left: 5px !important;
    width: 100%;
  }
  .admin-page .ra_jackson_top .dropdown-item.width-two {
    width: 100%;
  }
  .admin-page .ra_jackson_top .header-profile-dropdown::before {
    width: 0;
    height: 0;
  }
 .admin-page .ra_jackson_top .header-profile-dropdown::after {
    width: 0;
    height: 0;
  }
  .admin-page .ra_jackson_top .profile-text h5 {
    font-size: 20px;
    line-height: 1.8;
    font-weight: 300;
  }
  .admin-page .ra_jackson_top .profile-text p {
    font-size: 14px;
  }
  .admin-page .ra_jackson_top .profile-image img {
    width: 60px;
    height: 60px;
    border-radius: 0;
    margin-right: 10px;
  }
  .admin-page .ra_jackson_top .down-icon-two img {
    right: -39px;
    top: 18px;
  }
  .admin-page .ra_jackson_top  .header-profile-dropdown {
    padding: 0 0px 0 0px;
    position: relative;
  }
  
  
  /*Btn area start*/
  .admin-page .ra_jackson_top .contact-button a {
    font-size: 14px;
    color: #0078BF;
    border: 1px solid #0078BF;
    padding: 7px 25px;
    border-radius: 26px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
 .admin-page .ra_jackson_top .contact-button a:hover{
    color: #fff;
    background-color:#0078BF; 
  }
  .admin-page .ra_jackson_btn_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .admin-page .ra_jackson_top .contact-button2 a {
    color: #fff;
    background-color: #0078BF;
    margin-left: 20px;
  }
  .admin-page .ra_jackson_top .contact-button2 a:hover{
    background-color: #fff;
    color: #0078BF;
  }

  
  
  /*  Since Progress area start   */
  .admin-page .ra_science_progress_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .admin-page .ra_science_progress_area .slider-course-progress-left p {
    font-size: 14px;
    color: #3F4254;
    font-weight: 300;
  }
  .admin-page .ra_science_content_area p {
    padding-top: 15px;
    color: #3F4254;
    font-weight: 300;
    font-family: Avenir;
    font-size: 14px;
  }
  .admin-page .ra_science_content_area p a{
    -webkit-text-decoration: underline #0078BF;
    text-decoration: underline #0078BF;
    color: #0078BF;
  }
 .admin-page .ra_science_progress_area .myclassroom-single-left-cross-progress {
    width: 247px;
    height: 7px;
    background: #E4E6EF;
    border-radius: 7px;
    margin: 8px 0;
  }
  .admin-page .myclassroom-single-left-cross-progress.myclassroom-single-left-cross-progress_extra {
    background: #05233B;
  }
  .admin-page .ra_science_progress_area .barra-nivel {
    height: 7px;
    background: #00A2A7;
  }
  .admin-page .ra_science_progress_area .course-progress-right h5,.admin-page .course-progress-right span {
    font-weight: 300;
    font-size: 12px;
    color: #181C32;
    text-transform: inherit;
    line-height: 9px;
  }
 .admin-page .ra_science_progress_area .slider-course-progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
 .admin-page .ra_science_progress_area .course-progress-right {
    margin-left: 12px;
    margin-top:0px;
  }
 .admin-page .ra_science_progress_area .course-progress-right_ex_ex {
    margin-top: 22px !important;
  }
  .admin-page .ra_science_progress_area .course-progress-left p {
    font-size: 14px;
    font-weight: 300;
  }
  .admin-page .ra_repetable_area .single-top-progress-heading-text p {
    font-weight: 300;
  }
  .admin-page .ra_science_progress_area .ra_bottom_area_science {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
 .admin-page .ra_bottom_area_science img {
    width: 12px;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 7px;
  }
  .admin-page .ra_bottom_area_science_ex{
    margin-right: 10px;
  }
  
  
  /* =================        Repetable area start      =================   */
  .admin-page .ra_repetable_area{
    background-color: #F3F6F9;
  }
 .admin-page .ra_single_repetable_area{

  }
 .admin-page .ra_repetable_area .single-top-progress-area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 24%;
    flex: 0 0 24%;
  }
  .admin-page .ra_repetable_area .top-progress-area {
    padding-bottom: 17px;
  }
  .admin-page .ra_repetable_area .single-top-progress-area{

  }
  .admin-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(1){
    background:url('/media/learning/bg-progress1.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
  .admin-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(2){
    background:url('/media/learning/bg-progress2.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
  .admin-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(3){
    background:url('/media/learning/bg-progress3.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
 .admin-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(4){
    background:url('/media/learning/bg-progress4.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
    color: #181C32;
  }
  .ra_repetable_area .single-top-progress-area:hover {

  }
  .admin-page .single-top-progress-area_ex .single-top-progress-heading-text p{
    color:#0B0000 !important; 
  }
  .admin-page .single-top-progress-area_ex .single-top-progress-count-text h5 {
    font-size: 26px;
    color: #0B0000;
    padding: 5px 5px;
    border-radius: 5px;
    margin: 0;
  }
  .admin-page .single-top-progress-area_ex .single-top-progress-count-text h5 span{
    font-size: 14px !important;
    color: #0B0000;
    font-weight: normal;
  }
  .admin-page .single-top-progress-count-text_ex h5{
    display: inline-block;
    background-color: #fff;
    font-size: 12px !important;
  }
  .admin-page .single-top-progress-area.single-top-progress-area_ex {
    padding: 12px 1px 23px 16px !important;
  }
  
  
  /* =================        Repetable area End        =================   */
  
  
  
  
  
  
  /* =================      Slider Resources css area start       ===================== */
  .admin-page .ra_resources_area {
    background-color: #F3F6F9;
    padding-bottom: 37px;
  }
  .admin-page .ra_resources_area .myclassroom-area{
    padding: 20px 20px;
  }
  
  
  
  /* Resources Slide area start */
  .admin-page .ra_resources_slider_area_ex{
    padding-left: 30px;
  }
  .admin-page .ra_resources_slider_area{
    position: relative;
  }
  .admin-page .ra_resources_slider_area::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 28px;
    background: url('/media/learning/affff.png') no-repeat scroll 0 0 / cover;
    z-index: 1;
  }
  .admin-page .ra_resources_slider_area_single_main {
    border: 1px solid #E4E6EF;
    border-radius: 4px;
    position: relative;
  }
  .admin-page .ra_resources_slider_area_top_single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .admin-page .ra_resources_slider_area_top {
    padding: 10px 15px;
  }
  .admin-page .ra_resources_area .myclassroom-header{
    padding-bottom: 20px;
  }
  .admin-page .ra_resources_slider_area_butttom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #B2D8EF;
    padding: 7px 16px;
  }
  .admin-page .ra_resources_slider_area_butttom_man {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .ra_resources_slider_area_top_single img{
    width: 45px !important;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .admin-page .ra_resources_slider_area_butttom_man img {
    width: 25px !important;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 10px;
  }
  .admin-page .ra_resources_area .myclassroom-area {
    background-color: #fff;
  }
  .admin-page .ra_resources_content .single-top-progress-area:hover {
    border: none;
  }
  .admin-page .ra_resources_slider_pdf {
    text-align: right;
  }
  .admin-page .ra_resources_slider_area_top_single ul li {
    font-size: 9px;
  }
  .admin-page .ra_resources_slider_area_middle h2 {
    font-size: 15px;
    font-weight: 300;
    padding: 20px 0 10px 0;
    color: #3E3F42;
  }
  .admin-page .ra_resources_slider_area_middle p {
    font-size: 12px;
    font-weight: 300;
    color: #3E3F42;
    padding-right: 20px;
  }
  .admin-page .ra_resources_slider_area_middle {
    height: 100px;
  }
  .admin-page .ra_resources_slider_pdf a {
    color: #3F4254;
    font-weight: normal;
    display: inline-block;
    text-transform: uppercase;
    font-size: 9px;
    padding-top: 10px;
  }
 .admin-page .ra_single_man h6{
    font-size: 11px;
    color: #05233B;
    font-weight: normal;
  }
  .admin-page .ra_single_man p{
    font-size: 9px;
    color: #05233B;
    font-weight: normal;
  }
  
 .admin-page .ra_resources_area_bottom_text{
    text-align: right;
    padding-top: 60px;
  }
 .admin-page .ra_resources_area_bottom_text a {
    color: #0078BF;
    font-size: 14px;
    font-weight: 300;
  }
  
  
  /*Text right area start */
  .admin-page .ra_resources_right_area{
    background-color: #fff;
    padding: 25px 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .admin-page .ra_resources_right_area_single_item{
    padding-bottom: 30px;
    position: relative;
  }
  .admin-page .ra_resources_right_area_single_item::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: -45px;
    width: 128%;
    height: 1px;
    background-color: #EAEDF3;
  }
  .admin-page .ra_resources_right_area_single_item:last-child:after { 
    content: ""; 
    position: absolute;
    background-color: transparent;
  }
  
  .admin-page .ra_resources_right_area_single_item_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .ra_resources_top h4 {
    font-size: 16px;
    color: #3F4254;
    font-weight: 300;
  }
  .admin-page .ra_resources_top p {
    font-size: 12px;
    padding-top: 15px;
    font-weight: 300;
  }
 .admin-page .ra_resources_right_area_single_item_img img {
    width: 34px;
    margin-right: 15px;
  }
  .admin-page .ra_resources_right_area_single_item_img p{
    font-size: 14px;
    font-weight: 300;
  }
  .admin-page .ra_resources_right_area_single_item_img p:nth-child(2) {
    font-size: 12px;
    padding-top: 7px;
  }
  .admin-page .ra_resources_bottom_area{
    text-align: center;
  }
  .admin-page .ra_resources_bottom_area a {
    font-size: 14px;
    font-weight: 300;
    color: #0078BF;
  }
  /* =================       Resources css area end       ===================== */
  
  
  /* =================       Resources css area end       ===================== */
  
  
  /*================== Start Dropdown Menu Area ==================*/
  .admin-page .dropdown_search_flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
 .admin-page .mu_dropdown_menu_content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
  }
 .admin-page .mu_search_bar{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%
  }
  .admin-page .mu_search_bar.new-class {
    flex: 0 0 89%;
  }
  .admin-page .sh-new-class-add-last {
    display: flex;
    justify-content: space-between;
  }
 .admin-page .mu_dropdown_menu_area {
    background: #F3F6F9;
    padding-top: 10px;
  }
  .admin-page .mu_dropdown_menu_content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .mu_dropdown_menu_content ul li{
    color: #3F4254;
    margin-right: 20px;
    font-size: 12px;
  }
  .admin-page .mu_dropdown_menu_content ul li a,.mu_dropdown_menu_content ul li button.menu-drop,.admin-page .sh-new-first-filter-option ul li a,.admin-page .sh-new-second-filter-option ul li a {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    margin: 18px 0;
    position: relative;
  }
  .admin-page .mu_dropdown_menu_content ul li button.menu-drop {
    padding-right: 30px;
  }
  .admin-page .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
    color: #0078BF;
    border-bottom: 3px solid #0078BF;
  }
  .admin-page .mu_dropdown_menu_content ul li a.active,.admin-page .mu_dropdown_menu_content ul li a:hover {
    color: #0078BF;
  }

  .admin-page .dropdown-menu.new-menu.show.ra_custom_checkbox_area_up{
    top: -14px !important;
    position: absolute !important;
  }

 .admin-page .mu_dropdown_menu_content ul li a img,.admin-page .mu_dropdown_menu_content ul li button.menu-drop img {
    height: 14px;
    width: auto;
    margin-top: -7px;
    margin-right: 2px;
  }
 .admin-page .mu_dropdown_menu_content ul li a span {
    background: #145B94;
    font-weight: 400;
    font-size: 10px;
    color: #FFF;
    padding: 1px 5px;
    border-radius: 15px;
    width: 10px;
    margin-left: 5px;
  }
  
  
  
  /* Start View icons*/
  .admin-page .mu_view_icons ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .mu_view_icons ul li{
    margin: 0;
    margin-right: 20px;
    font-size: 14px;
    color: #3F4254;
  }
 .admin-page .mu_view_icons ul li:last-child{
    margin-right: 0;
  }
  .admin-page .mu_view_icons ul li a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .view_img_1{
    width: 11px;
  }
  .admin-page .view_img_2{
    width: 17px;
  }
  /* End View icons*/
  
  
  /*   Radio btn area start   */
  /* custom checkbox */
 .admin-page .custom_checkbox_area label span {
    font-size: 14px;
    font-weight: 300;
  }
  .admin-page .recommendation_checkbox label sapn {
    cursor: pointer;
  }
 .admin-page .custom_checkbox_area {
    padding: 10px 17px;
  }
 .admin-page .custom_checkbox_area label{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /* custom checkbox */
  .admin-page .custom_checkbox_area input[type="radio"] {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    opacity: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #3f51b5;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  .admin-page .recommendation_checkbox input[type="checkbox"] {
    height: 16px;
    width: 16px;
    margin: 0;
    padding: 0;
    opacity: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #D8DCE6;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  .admin-page .custom_checkbox_area input[type="radio"]:checked,.admin-page .recommendation_checkbox input[type="checkbox"]:checked {
    border: 2px solid #3f51b5;
    background: #3f51b5;
  }
  
  .admin-page .custom_checkbox_area input[type="radio"]:checked::before,.custom_checkbox_area input[type="radio"]:checked::after,.recommendation_checkbox input[type="checkbox"]:checked::before,.recommendation_checkbox input[type="checkbox"]:checked::after  {
    content: "";
    position: absolute;
    height: 2px;
    background: #fff;
  }
  .admin-page .custom_checkbox_area input[type="radio"]:checked::before {
    width: 8px;
    top: 8px;
    left: 0px;
    -webkit-transform: rotate(44deg);
    -ms-transform: rotate(44deg);
    transform: rotate(44deg);
  }
  .admin-page .recommendation_checkbox input[type="checkbox"]:checked::before {
    width: 7px;
    top: 6px;
    left: 0px;
    -webkit-transform: rotate(44deg);
    -ms-transform: rotate(44deg);
    transform: rotate(44deg);
  }
  .admin-page .custom_checkbox_area input[type="radio"]:checked::after {
    width: 11px;
    top: 6px;
    left: 4px;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
  }
  .admin-page .recommendation_checkbox input[type="checkbox"]:checked::after {
    width: 10px;
    top: 5px;
    left: 3px;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
  }
  .admin-page .custom_checkbox_area input[type="radio"]:focus,.admin-page .recommendation_checkbox input[type="checkbox"]:focus {
    outline: none !important;
  }
  .admin-page .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow:none !important;
    box-shadow:none !important;
  }
  
  /*Resources css area start header */
  .admin-page .ra_courch_area{
    padding: 0px 0 45px 0;
  }
 .admin-page .ra_courch_area .barra-nivel {
    background: #00A2A7;
  }
  .admin-page .ra_courch_area_header h2 {
    font-size: 18px;
    color: #05233B;
    font-weight: 300;
  }
  .admin-page .ra_courch_area .mu_dropdown_menu_content ul li:last-child a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: inline-block;
    color: #0078BF;
    }m
   .admin-page .ra_courch_area .mu_dropdown_menu_content ul li:last-child img{
      width: 12px;
      height: auto;
      margin-right: 5px;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .admin-page .ra_courch_area .mu_dropdown_menu_content ul li a:hover::after, .admin-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      background: transparent;
    }


    /*  Ra_courch prpgress area start  */
    .admin-page .ra_courch_single_item_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background-color: #fff;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 14px 24px;
      border-radius: 5px;
      border: 1px solid #F7F7F7;
      margin-bottom: 12px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
   .admin-page .ra_courch_single_item_left{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 55%;
      flex: 0 0 55%;
    }
   .admin-page .ra_courch_single_item_middle{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
    }
   .admin-page .ra_courch_single_item_right{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
    }
    /*Top area start */
   .admin-page .ra_courch_single_item_left_sub_main{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_courch_single_item_left_sub_main img {
      width: 30px;
      height: auto;
      -o-object-fit: cover;
      object-fit: cover;
      margin-right: 20px;
    }
   .admin-page .ra_courch_single_item_left_content h5 {
      font-size: 18px;
      font-weight: 300;
    }
    .admin-page .ra_courch_single_item_left_content p {
      font-size: 14px;
      color: #3F4254;
    }

    /*middle area start */
   .admin-page .ra_courch_ra_progress_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    /*More area start */
    .admin-page .ra_courch_more_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_courch_more_area .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
      margin-right: 20px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
    .admin-page .ra_courch_more_area .contact-button a:hover{
      color: #fff;
      background-color: #0078BF;
    }
   .admin-page .ra_courch_more_area img {
      width: auto;
      height: 4px;
      cursor: pointer;
    }
    .admin-page .ra_courch_ra_progress_area h5 {
      font-weight: 300;
      font-size: 14px;
    }

    /* =================       Resources css area end       ===================== */

    /* =================       Calender css area Start       ===================== */

    .admin-page .ra_calender_area{

    }
   .admin-page .ra_calender_area .upcoming-heading-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
   .admin-page .ra_day_tody_area_sub .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
      margin: 0 10px;
      padding: 6px 23px;
      font-size: 14px;
      border-radius: 50px;
      transition: .3s;
    }
    .admin-page .contact-button button.change {
      background: none;
    }
   .admin-page .contact-button button.change img {
      width: 14px;
      height: auto;
      margin: 0 15px;
    }
   .admin-page .ra_day_tody_area_sub .contact-button a:hover{
      background-color:#0078BF;
      color: #fff; 
    }
   .admin-page .ra_calender_area .upcoming-heading-content {
      background: #F3F6F9;
      padding: 15px 0px;
    }
   .admin-page .ra_day_tody_area_sub {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: -5px;
    }
   .admin-page .ra_calender_area .btn-group.btn-group-toggle {
      background: #fff;
    }
   .admin-page .ra_calender_area .table-active, .table-active > td, .table-active > th {
      background-color: #E4E6EF;
    }
   .admin-page .ra_calender_area .table thead th {
      font-size: 14px;
      color: #3F4254;
      font-weight: normal;
      padding: 10px 20px 10px 20px;
    }
   .admin-page .ra_calender_area .upcoming-heading-content p {
      font-size: 15px;
    }
   .admin-page .upcoming-area.ra_calender_area.upcoming-area tbody td {
      height: 61px;
    }
   .admin-page .ra_calender_area .table td, .table th {
     border-bottom: 2px solid #dee2e6; 
     border-top: 0px solid transparent; 
   }
   .admin-page .ra_calender_area.upcoming-area tbody {
    border-top: 4px solid #EAEDF3;
  }
 .admin-page .ra_calender_area.upcoming-area tbody td{
    border-right: 15px solid #EAEDF3;
    height: 400px;
    padding: 0;
    border-bottom: 1px solid #EAEDF3;
    position: relative;
  }
 .admin-page .ra_calender_area.upcoming-area tbody td:last-child{
    border-right: 1px solid #EAEDF3;
  }
 .admin-page .ra_calender_area.upcoming-area {
    padding-bottom: 25px;
  }


  /*Checkbox area start */
  .admin-page .ra_calender_area .ra_custom_checkbox_area_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .ra_calender_area .ra_custom_checkbox_area_item ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .ra_custom_checkbox_area_item label {
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
  }
  .admin-page .ra_calender_area .ra_custom_checkbox_area_item ul li a{
    font-size: 25px;
    margin-left: 50px;
    color: #A6ADB1;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  .admin-page .ra_calender_area .ra_custom_checkbox_area_item ul li a:hover{
    color: #0078BF;
  }

  .admin-page .ra_calender_area.upcoming-area .monday-first-card {
    background-color: #b2d8ef;
    border-left: 2px solid #387ebe;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 7px;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9999999;
  }

  /* =================       Calender css area end       ===================== */

  /*================================================================================================
    ====                                    LEARNING PAGE CSS End                                  ====
    ================================================================================================*/


  /*================================================================================================
    ====                                    Schedule-W PAGE CSS START                                  ====
    ================================================================================================*/

    /*  RA-Event area start   */
   .admin-page .ra_event_area{
      padding-bottom: 10px;
    }
    .admin-page .upcoming-heading-content.ra_upcoming_heading {
      padding-left: 0;
    }
    /*Top btn area start */
    .admin-page .ra_event_right_btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding-top: 10px;
    }
   .admin-page .ra_event_right_btn .contact-button a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      border: 1px solid #0078BF;
      padding: 6px 25px;
      border-radius: 40px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
    .admin-page .ra_event_right_btn .contact-button a:hover{
      color: #fff;
      background-color: #0078BF;
    }
    /*Slider area start */
    .admin-page .ra_event_main_area h2 {
      padding-bottom: 27px;
      font-weight: 300;
      font-size: 20px;
    }
    .admin-page .ra_event_single_slider_area {
      background: #C8CBDE4F;
      padding: 24px 8px 24px 20px;
      border-left: 7px solid #C8CBDE;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .admin-page .ra_event_main_area_slider_items{
      position: relative;
    }
    .admin-page .ra_event_main_area_slider_items::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 20px;
      background: url('/media/learning/affff.png') no-repeat scroll 0 0 / cover;
      z-index: 1;
    }

    .admin-page .ra_event_single_slider_area_top h5 {
      font-size: 16px;
      font-weight: 500;
      color: #05233B;
      padding: 10px 0;
    }
    .admin-page .ra_event_single_slider_area_top h4 {
      font-weight: 300;
      font-size: 30px;
      padding-bottom: 10px;
    }

    .admin-page .ra_event_single_slider_area_bottom {
      margin-left: 20px;
    }
    .admin-page .ra_event_single_slider_area_bottom h1 {
      font-size: 14px;
      font-weight: 300;
    }
   .admin-page .ra_event_single_slider_area_bottom p {
      font-size: 12px;
      margin-top: 10px;
    }
    .admin-page .ra_event_single_slider_area1 {
      border-left: 7px solid #A4BBCC !important;
      background: #A4BBCC73 !important;
    }
    .admin-page .ra_event_single_slider_area2 {
      background: #C8CBDE4F !important;
      border-left: 7px solid #C8CBDE !important;
    }
   .admin-page .ra_event_single_slider_area3 {
      border-left: 7px solid #1A6A9A;
      background: #CAE2F1;
    }
    .admin-page .ra_event_single_slider_area4 {
      border-left: 7px solid #00A2A7;
      background: #00A2A757;
    }
    /* =================================    Day Area start  ==================================== */
    .ra_day_view_area{

    }
   .admin-page .ra_day_view_area .upcoming-heading-content {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .admin-page .upcoming-heading-content {
      background: #F3F6F9;
      padding: 5px 15px;
    }
   .admin-page .ra_day_view_area .contact-button a {
      margin: 0 6px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }

    .admin-page .ra_day_view_area .contact-button a:hover{
      color: #fff;
      background-color:#0078BF; 
    }
    .admin-page .ra_day_view_area .btn-group, .admin-page .btn-group-vertical {
      background: #fff;
      margin-left: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_day_view_area .upcoming-heading-content p {
      font-weight: 300;
      font-size: 14px;
      color: #05233B;
      margin-right: 10px;
    }
    .admin-page .ra_day_view_area .btn-group .btn {
      border: 1px solid #EAEDF3;
      background-color: transparent;
    }
    .admin-page .ra_day_view_area .upcoming-heading-content {
      padding: 36px 0;
    }
    /* ===========  Teacher list area start  ========== */
    .admin-page .ra_teacher_list_area_header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #E4E6EF;
      padding: 13px 0px 13px 30px;
      border: 1px solid #F7F7F7;
    }
   .admin-page .ra_teacher_list_area_header_single_item h2 {
      color: #3F4254;
      font-size: 14px;
      font-weight: 300;
    }
    /*studernt list */
    .admin-page .ra_teacher_list_area_body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-top: 5px solid #F3F6F9;
      background: #fff;
      padding: 22px 10px 22px 41px;
      border-radius: 7px;
    }
    .admin-page .re_body_list_item1,.admin-page .re_header_list_item1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
    }
    .admin-page .re_body_list_item2,.admin-page .re_header_list_item2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
    }
   .admin-page .re_body_list_item3,.admin-page .re_header_list_item3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
    }
    .admin-page .re_body_list_item4,.admin-page .re_header_list_item4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
      flex: 0 0 10%;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2 img {
      margin-right: 10px;
      height: 45px;
      width: auto;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 img {
      height: 45px;
      width: auto;
      -o-object-fit: cover;
      object-fit: cover;
      margin-right: 9px;
    }
   .admin-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 p {
      font-size: 18px;
      color: #181C32;
      margin-left: 11px;
      font-weight: 300;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body_single_man h6 {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body_single_man p {
      font-size: 12px;
      color: #3F4254;
      padding-top: 7px;
    }
    .admin-page .ra_teacher_list_area_header_single_item_body p {
      font-size: 14px;
      font-weight: 300;
      color: #3F4254;
    }

    .admin-page .ra_schedule_calender_area .ra_monday-first-card_ex_b {
      border-left: 4px solid #A4BBCC;
      background-color: #A4BBCC66;
    }
    .admin-page .ra_schedule_calender_area .ra_monday-first-card_ex_s {
      border-left: 4px solid #ADDEDC;
      background-color: #ADDEDC73;
    }
   .admin-page .ra_schedule_calender_area.upcoming-area tbody td {
      border-right: 15px solid #EAEDF3;
      height: 60px;
      padding: 0;
      border-bottom: 1px solid #EAEDF3;
      position: relative;
    }
    .admin-page .ra_schedule_calender_area.upcoming-area tbody td:last-child{
      border-right: 0px solid #EAEDF3;
    }
    .admin-page .ra_schedule_calender_area .upcoming-heading-content p,.ra_upcoming_heading p {
      font-weight: 300;
      font-size: 16px;
      color: #05233B;
    }
    .admin-page .ra_upcoming_heading p {
      padding-bottom: 10px;
    }
    .admin-page .ra_schedule_calender_area .table thead th {
      font-weight: 300;
      padding: 12px 40px;
      font-size: 14px;
    }
   .admin-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
   .admin-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li{
      margin-left: 30px;
    }
    .admin-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a{
      font-size: 20px;
      color: #A6ADB1;
      display: inline-block;
      -webkit-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
    }
    .admin-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a:hover{
      color: #0078BF;
    }
    .admin-page .ra_side_area_active {
      background-color: #0078BF;
      padding: 5px 12px;
      border-radius: 5px;
      font-size: 14px !important;
      color: #fff !important;
    }
    .admin-page .ra_schedule_calender_area.upcoming-area {
      background-color: #f3f6f9;
      padding-bottom: 64px;
      padding-top: 28px;
    }
  /*================================================================================================
    ====                                    Schedule-W PAGE CSS END                                  ====
    ================================================================================================*/


  /* ================================================================================================
    ====                               Schedule-month PAGE CSS START                            ====
    ================================================================================================ */
    /*  Windows area start   */
    .admin-page .ra_windows_main_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
    }
    .admin-page .ra_calender_main_area {
      padding: 0px 0 59px 0;
    }
    .admin-page .ra_calender_left_area_main_area_btn .contact-button a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      border: 1px solid #0078BF;
      padding: 5px 13px;
      border-radius: 30px;
      max-width: 230px;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 27px;
      left: 0;
      right: 0;
      margin: auto;
    }
   .admin-page .ra_calender_left_area_main_area {
      background-color: #fff;
      padding: 30px 15px 70px 15px;
      height: 100%;
    }
   .admin-page .ra_calender_left_area_sub_area {
      padding-left: 15px;
    }
   .admin-page .ra_windows_main_area .view_img_1 {
      width: 16px;
    }
    .admin-page .ra_main_left_color_select .custom-control-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 14px;
      color: #3E3F42;
      font-weight: 300;
      cursor: pointer;
    }
    .admin-page #dropdownMenuButton {
      font-size: 14px;
      font-weight: 300;
      color: #181C32;
    }
    .admin-page .ra_main_left_color_select .custom-control-label img {
      margin-right: 10px;
      margin-top: 2px;
      margin: 2px 10px 0 10px;
    }
    .admin-page .ra_calender_left_area_sub_area h3 {
      font-size: 14px;
      color: #3F4254;
      font-weight: 500;
      padding-bottom: 23px;
      padding-top: 3px;
    }
   .admin-page .custom-control-label.re_custom-control-label_single_man img {
      width: 24px;
    }
    .admin-page .ra_windows_main_area .ra_main_left_color_select {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_windows_main_area .custom-control.custom-checkbox.mb-3 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }


    /*Right site area start */
   .admin-page .ra_calender_main_area .upcoming-heading-content .contact-button a {
      background-color: transparent;
      color: #0078BF;
      margin: 0 10px;
      padding: 6px 23px;
      font-size: 14px;
      border-radius: 50px;
    }
    .admin-page .upcoming-heading-content .contact-button a:hover{
      color: #fff;
      border: 1px solid #0078BF;
      background-color: #0078BF;
    }
   .admin-page .ra_calender_left_area.upcoming-area {
      padding-bottom: 0px;
    }
    .admin-page .ra_calender_left_area.upcoming-area tbody td {
      height: 170px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px 0;
      border-bottom: 1px solid #EAEDF3;
      position: relative;
    }
    .admin-page .ra_calender_left_area .table-active, .table-active > td, .table-active > th {
      background-color:#fff;
    }
    .admin-page .ra_calender_left_area .table thead th {
      border-bottom: 1px solid #dee2e6;
    }
   .admin-page  .ra_calender_left_area .monday-first-card {
      background-color: #b2d8ef;
      border-left: 2px solid #387ebe;
      padding-left: 10px;
      padding-bottom: 5px;
      padding-top: 7px;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 9999999;
      top: 40%;
    }
   .admin-page .ra_calender_left_area.upcoming-area tbody td span {
      padding: 8px 17px;
      font-size: 12px;
      color: #3E3F42;
      font-weight: 300;
    }
   .admin-page .ra_calender_left_area.upcoming-area tr th{
      text-align: center;
    }
   .admin-page .ra_calender_left_area.upcoming-area tr td {
      text-align: left;
    }
   .admin-page .ra_calender_left_area.upcoming-area .upcoming-heading-content {
      background: #fff;
      padding: 20px 30px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border: 1px solid #EAEDF3;
    }
   .admin-page .ra_calender_left_area .table thead th {
      border-bottom: 1px solid #dee2e6;
      font-weight: 300;
      font-size: 12px;
      padding: 14px 41px;
    }
   .admin-page .ra_calender_left_area .table {
      margin-bottom: 0rem;
    }
   .admin-page .ra_calender_left_area.upcoming-area .upcoming-heading-content p {
      font-weight: 300;
      font-size: 18px;
      color: #05233B;
      margin-right: 10px;
    }
   .admin-page .ra_badami_color {
      background-color: green;
    }

    /*Color system area start */
    .admin-page .monday-first-card.ra_badami_color {
      background: #E6772B54;
      border-left: 3px solid #E6772B;
      line-height: 2;
    }
    .admin-page .monday-first-card.ra_badami_color h6 {
      padding-bottom: 17px;
      font-size: 14px;
      font-weight: 300;
    }
    .admin-page .monday-first-card.ra_badami_color2 {
      background: #E6772B54;
      border-left: 3px solid #E6772B05;
      color: #E6772B05;
      padding: 34px 0;
    }
    .admin-page .monday-first-card.ra_skyblue_color {
      background-color: #ADDEDC4F;
      border-left: 3px solid #ADDEDC;
    }
    .admin-page .monday-first-card.defarent_angle_table {
      background-color: #F3F6F9;
      top: 40%;
      border-left: 3px solid #F3F6F9;
      border-bottom: 1px solid #fff;
    }
   .admin-page .monday-first-card.ra_skyblue_color.ra_skyblue_color_ex {
      padding: 7px 1px 7px 3px;
      top: 60%;
    }
    .admin-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno {
      top: 71%;
      background: transparent;
      border-left: 3px solid transparent;
    }
    .admin-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a {
      color: #3F4254;
      font-size: 12px;
      font-weight: normal;
    }
    .admin-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a:hover{
      color: #000;
    } 
    .admin-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno.ex_up{
      top: 77%;
    }

   .admin-page .ra_calender_left_area.upcoming-area .upcoming-heading-content .contact-button a {
      background-color: #0078BF;
      color: #fff;
      padding: 6px 50px;
    }
    .ra_calender_left_area.upcoming-area .upcoming-heading-content .contact-button a:hover{
      background-color: #fff;
      color: #0078BF;
    }

  /* ================================================================================================
    ====                               Schedule-month PAGE CSS End                            ====
    ================================================================================================ */ 


    /*===================================================================================
  ====                      Start Progress list pages css                        ====
  =================================================================================== */
  /*list_resource_pages_main_area*/
  .admin-page .list_document_resource_main {
    margin-top: 5px;
    overflow: hidden;
  }
  .admin-page .list_check_sortable_main {
    background: #E4E6EF;
    padding: 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .admin-page .list_check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .admin-page .list_prog_heading {
    margin-top: 35px;
  }
  .admin-page .list_check .mu-form-group label::before {
    background-color: #FFF;
    border: 1px solid #7E8299;
    padding: 8px;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  .admin-page .list_check .mu-form-group input:checked + label::after {
    top: 8px;
    left: 6.5px;
    width: 6px;
    height: 12px;
    border: solid #707070;
    border-width: 0 2px 2px 0;
  }
  .admin-page .li_star_design a img {
    width: 15px;
    height: auto;
  }
  .admin-page .li_arrow_sort button img {
    max-width: 12px;
    width: 100%;
    height: auto;
  }
 .admin-page .li_arrow_sort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -5px;
    margin-left: 13px;
  }
  .admin-page .single_sortable_design {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .single_sortable_design h4{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .admin-page .list_top_check {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
  }
  .admin-page .li_sortable_source_area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
 .admin-page .li_sortable_source_middle_area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
 .admin-page .li_sortable_source_right_area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    padding-right: 50px;
  }
 .admin-page .li_sortable_source_right_area .single_sortable_design {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .admin-page .li_arrow_sort button{
    background: transparent;
  }
  .admin-page .li_arrow_sort button:nth-child(1) {
    display: block;
    margin-bottom: -2px;
	z-index: 999999;
  }
  .admin-page .li_arrow_sort button:nth-child(2) {
    display: block;
    margin-top: -2px; 
  }
  .admin-page .left_dc_img img {
    max-width: 46px;
    width: 100%;
    height: auto;
  }
  .admin-page .right_dc_img a img {
    max-width: 15px;
    width: 100%;
    height: auto;
    margin-right: 4px;
  }
  .admin-page .right_dc_img a{
    color: #080F1E;
    font-weight: 300;
    font-size: 14px;
  }
  .admin-page .right_dc_img a span{
    color: #0078BF;
    -webkit-text-decoration: underline #0078BF;
    text-decoration: underline #0078BF;
  }
  .admin-page .right_dc_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .admin-page .dc_single_items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #FFF;
    padding: 17px 16px 25px;
    border: 1px solid #F7F7F7;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 5px;
  }
  .admin-page .dc_middle_txt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }
  .admin-page .dc_middle_txt a {
    font-size: 9px;
    font-weight: 500;
    color: #FFF;
    background: #00A2A7;
    padding: 3px 9px;
    display: inline-block;
    position: absolute;
    left: -5px;
    top: -1px;
  }
  .admin-page .dc_middle_txt p {
    color: #3F4254;
    font-weight: 400;
    font-size: 12px;
    padding-left: 37px;
  }
  .admin-page .dc_dots_area .down-icon {
    position: relative;
  }
 .admin-page .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -35px;
    top: -7px;
    position: absolute;
  }
 .admin-page .right_dc_date_time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12%;
    flex: 0 0 12%;
  }
 .admin-page .right_dc_date_time.new-class-add {
    flex: 0 0 17%;
    display: flex;
    justify-content: flex-end;

  }
  .admin-page .dc_dots_area button {
    background: none;
  }
  .admin-page .right_dc_date_time > a {
    font-size: 15px;
    color: #3F4254;
    font-weight: 300;
    display: inline-block;
    margin-top: 2.5px;
  }
  .admin-page .left_dc_img {
    margin-right: 27px;
  }
  .admin-page .dc_check_star {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6%;
    flex: 0 0 6%;
  }
  .admin-page .dc_jackson_img_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .right_dc_img a:nth-child(2) span{
    font-weight: 400;
    font-size: 12px;
  }
 .admin-page .right_dc_img a:nth-child(1) {
    margin-bottom: 10px;
  }
 .admin-page .right_dc_img a:nth-child(2) {
    margin-top: 10px;
  }
 .admin-page .dc_dots_area {
    position: relative;
  }
 .admin-page .dc_dots_area .dropdown-menu {
    min-width: 190px;
    min-width: 206px;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #E4E6EF;
    padding: 5px 0 !important;
    left: -130px !important;
    top: 10px !important;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
 .admin-page .dc_dots_area .dropdown-menu a{
    font-size: 14px;
    font-weight: 400;
  }
  .admin-page .document_pagenations_area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }
 .admin-page .left_pg_area a {
    color: #3F4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
  }
  .admin-page .right_pg_area .page-item .page-link img {
    max-width: 10px;
    width: 100%;
    height: auto;
    display: block;
  }
  .admin-page .right_pg_area .pagination {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .admin-page .right_pg_area .page-link {
    padding: .70rem 1rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
 .admin-page .right_pg_area .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }
.admin-page .right_pg_area ul li{
    margin: 0 12px;
  }
 .admin-page .right_pg_area .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
 .admin-page .list_prog_heading .mu_dropdown_menu_content ul li:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .admin-page .list_prog_heading .mu_dropdown_menu_content ul li:last-child img {
    width: 12px;
    height: auto;
    margin-right: 5px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .admin-page .list_prog_heading .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
    display: none;
  }

/*===================================================================================
  ====                      End Progress list pages css                        ====
  =================================================================================== */

/* ================================================================================================
    ====                               Trecking PAGE CSS Start                            ====
    ================================================================================================ */  
   .admin-page .ra_sortable_area {
      padding-bottom: 40px;
    }
   .admin-page .ra_process_tracking_area {
      padding: 25px;
    }
    .admin-page .ra_process_tracking_area .pt_view_main_area{
      margin-bottom: 10px;
      padding-top: 30px;
    }
   .admin-page .ra_process_tracking_area_header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: end;
    }
   .admin-page .ra_jackson_btn_area a {
      color: #0078BF;
      background: #fff;
      border: 1px solid #0078BF;
      font-size: 14px;
      padding: 7px 27px;
      border-radius: 50px;
      margin-left: 15px;
    }
    .admin-page .ra_process_tracking_area_header_search_width {
      max-width: 585px;
      width: 100%;
    }

    .admin-page .ra_process_tracking_area_header_search {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
   .admin-page .ra_process_tracking_area h3 {
      color: #05233B;
      font-size: 20px;
      font-weight: 300;
    }
    .admin-page .ra_process_tracking_area h4 {
      color: #05233B;
      font-size: 18px;
      font-weight: 300;
    }
    .admin-page .ra_process_tracking_single h3 {
      padding-bottom: 14px;
      font-weight: 300;
      font-size: 18px;
    }

    .admin-page .ra_process_tracking_area_body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background-color: #fff;
      margin-top: 27px;
      padding: 12px 0 27px 0;
    }
    .admin-page .ra_process_tracking_single {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33%;
      flex: 0 0 33.33%;
      text-align: center;
      position: relative;
    }
    .admin-page .ra_process_tracking_single::after {
      content: "";
      position: absolute;
      background-color: #7070703d;
      width: 1px;
      height: 70px;
      right: 0;
      top: 0;
    }
    .admin-page .ra_process_tracking_single:last-child::after{
      background-color: transparent;
      position: absolute;
      content: "";
    }
    .admin-page .slider-course-progress {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .admin-page .ra_process_tracking_area_body .barra-nivel {
      background: #00A2A7;
    }
    /*seach btn area start*/
    .admin-page .ra_sortable_area_btn_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_process_tracking_area .myclassroom-single-right-right-content .dropdown button {
      padding: 6px 24px;
      border-radius: 25px;
      font-size: 14px;
      font-weight: 300;
      margin-left: 14px;
    }
    .admin-page .ra_process_tracking_area .ra_btn_btnarea a {
      color: #fff;
      background: #00A2A7;
      display: inline-block;
      padding: 1px 10px;
      border-radius: 4px;
    }
   .admin-page .ra_sortable_body_main_area .contact-button a {
      padding: 4px 18px;
    }

    /*seach btn area End*/


    /* ======================       Ra srot able area start         ======================= */

    .admin-page .ra_sortable_area .mu_view_icons ul li {
      font-weight: 300;
    }
    .admin-page .ra_sortable_area .mu_dropdown_menu_content ul li:last-child a{
      color: #0078BF;
    }
   .admin-page .ra_sortable_area .mu_dropdown_menu_content ul li a:hover::after,.admin-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      content: '';
      position: absolute;
      background: transparent;
      height: 0px;
      width: 0%;
      bottom: 0;
      left: 0;
    }
    /*  Sort able area start   */
    .admin-page .ra_sortable_header_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #E4E6EF;
      padding: 5px 0;
    }
   .admin-page .ra_sortable_area_menu_ex_area h5 {
      color: #05233B;
      font-size: 14px;
      font-weight: 300;
      padding-left: 30px;
    }
    .admin-page .ra_sortable_area_menu_ex_area {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 34%;
      flex: 0 0 34%;
    }

   .admin-page .ra_sortable_header_area_menu > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_sortable_header_area_menu > ul li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .ra_sortable_header_area_menu > ul li ul li{
      line-height: 1;
    }
    .admin-page .ata_je_ki_rokom{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .admin-page .ata_je_ki_rokom span {
      line-height: 1.2;
    }
   .admin-page .ra_sortable_header_area_menu > ul li ul li img {
      margin-left: 5px;
      cursor: pointer;
    }
    .admin-page .ra_sortable_header_area_menu ul li {
      color: #3F4254;
      font-size: 12px;
      font-family: Source Sans Pro;
      font-weight: 300;
      padding: 2px 8px;
      margin-left: 8px;
    }


    /*  Sort able area End   */

    /*Sort Able area start */

    .admin-page .class_area_sort_flex{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      background: #fff;
      padding: 18px 22px 22px;
      margin-bottom: 15px;
      border: 1px solid #F7F7F7;
      -webkit-box-shadow: 0px 3px 3px #8888880f;
      box-shadow: 0px 3px 3px #8888880f;
      border-radius: 4px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
    .admin-page .class_area_sort_flex:hover {
      background-color: #A6ADB147;
      cursor: pointer;
    }
    .admin-page .ra_sort_left_area_body {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 27%;
      flex: 0 0 27%;
    }
    .admin-page .ra_sort_left_area_body .barra-nivel {
      background: #00A2A7;
    }

    .admin-page .ra_sortable_certificate_main {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 27%;
      flex: 0 0 27%;
    }
    .admin-page .ra_sortable_certificate_main_sub_item_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-right: 50px;
    }
    .admin-page .ra_sortable_certificate_sub1 img {
      width: 13px;
      margin-right: 5px;
      height: auto;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .admin-page .ra_sortable_certificate_main2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
      flex: 0 0 24%;
    }
    .admin-page .ra_sortable_certificate_main2_sub_item_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .admin-page .ra_sortable_certificate_sub1 a span {
      font-size: 9px;
      font-weight: 300;
      background-color: #DBE6EE;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      color: #05233B;
      padding: 1px 6px;
      border-radius: 9px;
      display: inline-block;
      line-height: 1;
    }

    /*.ra_sortable_certificate_sub1 a span:hover,*/
   .admin-page .ra_sortable_certificate_sub1 a span.active{
      background-color: #05233B;
      color: #fff;
    } 
   .admin-page .ra_sortable_right_btn_area {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 22%;
      flex: 0 0 22%;
    }
    .admin-page .ra_sortable_right_btn_area_sub_item_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-left: 50px;
    }
   .admin-page .ra_sortable_right_btn_area_sub2 a img {
      height: 3px;
      width: auto;
      -o-object-fit: cover;
      object-fit: cover;
      margin-bottom: 8px;
    }
    .admin-page .ra_sortable_right_btn_area_sub1 .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
    }
   .admin-page .ra_sortable_right_btn_area_sub1 .contact-button a:hover{
      color: #FFF;
    }
    .admin-page .ra_sortable_certificate_main2 p {
      font-size: 14px;
      color: #3F4254;
      font-weight: 300;
    }
    .admin-page .ra_sortable_body_main_area .profile-right-section-content p {
      font-weight: 300;
    }
   .admin-page .ra_sortable_body_main_area .profile-right-section-content span {
      font-weight: 300;
    }
    /*Sort Able area End */

    /*Sort Able area start */
    .admin-page .ra_sortable_buttom_area {
      background: #fff;
      padding: 17px 20px;
    }
   .admin-page .ra_sortable_buttom_area h3 {
      font-size: 18px;
      font-weight: 300;
      color: #05233B;
    }
    .admin-page .ra_sortable_buttom_area h3 span {
      font-size: 10px;
      line-height: 1;
      background: #DBE6EE;
      color: #05233B;
      padding: 1px 8px;
      border-radius: 12px;
      margin-left: 12px;
    }
    .admin-page .ra_sortable_buttom_area_item_main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .admin-page .ra_sortable_buttom_single_item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
    }
   .admin-page .ra_sortable_buttom_single_item_sub {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: start;
      padding-top: 17px;
    }
  .admin-page .ra_sortable_buttom_single_item_sub img {
      height: auto;
      width: 26px;
      -o-object-fit: cover;
      object-fit: cover;
      margin-right: 10px;
    }
    .admin-page .ra_sortable_buttom_single_item_content h5 {
      font-size: 16px;
      color: #05233B;
      font-weight: 300;
    }
    .admin-page .ra_sortable_buttom_single_item_content p {
      color: #3F4254;
      font-size: 14px;
      font-weight: 300;
    }

    .admin-page .container_ex_area{
      width: 1140px;
      margin: auto;
      overflow-x: scroll;
    }
    /*start progress tracking pages css*/
    /*start progress tracking pages css*/
    .admin-page .ju_pro_heading_area{
      background: #E4E6EF;
      border: 1px solid #E4E6EF;
      -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    }
    .admin-page .ju_pro_heading_area ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 20px 35px;
    }
    .admin-page .ju_pro_heading_area ul li{
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
    }
    .admin-page .ju_single_table_content_design > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .admin-page .ju_single_table_content_design > ul > li {
      border: 1px solid #E4E6EF;
      padding: 32px 25px;
      border-right: none;
      border-top: none;
      font-weight: 300;
      color: #05233B;
      font-size: 10px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% / 6);
      flex: 0 0 calc(100% / 6);
      height: 60px;
      cursor: pointer;
    }
    .admin-page .ju_first {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 14px !important;
      color: #3F4254 !important;
    }
   .admin-page .ju_first i {
      font-size: 15px;
      color: #05233B;
      padding-right: 5px;
    }
    .admin-page .ju_single_table_content_design {
      position: relative;
    }
    .admin-page .ju_posional_progress .myclassroom-single-left-cross-progress {
      width: 100%;
      height: 15px;
      display: block;
      border-radius: 50px;
      background: #E4E6EF;
      max-width: 700px;
    }
    .admin-page .ju_posional_progress .barra-nivel {
      height: 15px;
      background: #145B94;
      border-radius: 50px;
    }
   .admin-page .ju_posional_progress {
      content: "";
      position: absolute;
      width: 100%;
      height: auto;
      left: 207px;
      top: 10px;
    }
    .admin-page .box_cmt {
      height: 18px;
      width: 18px;
      background: #C8CBDE;
      border-radius: 5px;
      margin-right: 8px;
    }
    .admin-page .box_tb_items1{
      background: #2692D1;
    }
    .admin-page .box_tb_items2{
      background: #DBE6EE;
    }
    .admin-page .box_tb_items3{
      background: #05233B;
    }
    .admin-page .box_tb_items4{
      background: #1A6A9A;
    }
   .admin-page .box_tb_items5{
      background: #00A2A7;
    }
    .admin-page .ui_single ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .admin-page .text_dft{
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
   .admin-page .ui_single ul li {
      border: 1px solid #E4E6EF;
      padding: 20px 20px;
      border-top: none;
      border-right: none;
      font-size: 10px;
      font-weight: 300;
      color: #05233B;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% / 6);
      flex: 0 0 calc(100% / 6);
      height: 60px;
    }
    .admin-page .ui_single .myclassroom-single-left-cross-progress {
      height: 15px;
      border-radius: 50px;
      width: 100%;
    }
    .admin-page .ui_single .barra-nivel {
      height: 15px;
      background: #145B94;
      border-radius: 50px;
    }
   .admin-page .hidden_basic {
      opacity: 0;
    }
    /*update css files */
   .admin-page .card-header {
      padding: 0;
      margin-bottom: 0;
      background-color: #FFF;
      border-bottom: none;
    }
   .admin-page .bg_header_area {
      background: #E4E6EF;
    }
   .admin-page .ju_progress_tracking_main {
      margin-bottom: 100px;
    }
    .admin-page .ui_single {
      background: #FFF;
    }
   .admin-page .tide_here {
      padding: 30px 12px !important; 
    }
   .admin-page .rjdesign.active .adil{
      opacity: 0;
    }
   .admin-page .rjdesign.active .ju_first i{
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
      margin-right: 5px;
    }



  /* ================================================================================================
    ====                               Trecking PAGE CSS End                            ====
    ================================================================================================ */


  /* ================================================================================================
    ====                               LearningF PAGE CSS Start                            ====
    ================================================================================================ */

    .admin-page .ra_learningf_header_diable_area .mu_alert_box, .admin-page .custom_alert_design {
      margin-top: 11px;
    }
    .admin-page .ra_learningf_header_diable_area_sub_area h1 {
      font-size: 20px;
      color: #05233B;
      font-weight: 300;
      padding: 23px 0 22px 0;
    }
    .admin-page .ra_calender_area_bottom_checkbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .admin-page .ra_calender_area_bottom_checkbox.change {
      display: inherit;
    }
    .admin-page .ra_sortable_learning_page_area .ra_sort_left_area_body {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 23%;
      flex: 0 0 23%;
    }
    .admin-page .ra_sortable_learning_page_area .ra_sortable_certificate_main {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 22%;
      flex: 0 0 22%;
    }
    .admin-page .ra_sortable_learning_page_area .ra_sortable_certificate_main2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
      flex: 0 0 10%;
    }
   .admin-page .ra_sortable_certificate_main_sub_item_area {
      margin-right: 20px;
    }
    .admin-page .ra_sortable_learning_page_area .ra_color_pikar_section {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16%;
      flex: 0 0 16%;
    }
    .admin-page .ra_sortable_learning_page_area .ra_sortable_right_btn_area {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
      flex: 0 0 24%;
    }
   .admin-page .ra_sortable_learning_page_area .ra_sortable_right_btn_area_sub_item_area {
      margin-left: 12px;
    }
   .admin-page .ra_sortable_date_sub_area p {
      font-size: 14px;
      color: #3F4254;
      font-weight: 300;
    }
   .admin-page .ra_sortable_learning_page_area .ra_sortable_certificate_sub_item13 {
      margin-left: 35px;
      line-height: 1;
    }

    /*.ra_sortable_learning_page_area*/
   .admin-page .ra_sortable_learning_page_area {
      padding-bottom: 80px;
    }
   .admin-page .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:last-child{
      color: #0078BF;
    }

    .admin-page .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      content: '';
      position: absolute;
      background: transparent;
      height: 0px;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    .admin-page .ra_sortable_learning_page_area_search_area_start {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      max-width: 494px;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding-bottom: 7px;
    }
   .admin-page .ra_sortable_learning_page_area_search_area_start .mu_dropdown {
      top: 42px;
    }
   .admin-page .ra_calender_area_bottom_checkbox ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
   .admin-page .ra_sortable_learning_page_area .ra_side_area_active {
      margin: 0 32px;
      border-radius: 4px;
    }
   .admin-page .ra_sortable_learning_page_area .ra_calender_area_bottom_checkbox ul li a {
      color: #A6ADB1;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
   .admin-page .ra_sortable_learning_page_area .ra_calender_area_bottom_checkbox ul li a:hover{
      color: #0078BF;
    }

   .admin-page .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon img {
      width: 20px;
      position: initial;
    }
   .admin-page .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
   .admin-page .ra_sortable_right_btn_area_sub2  .dc_dots_area {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 99;
    }
    /*messages pages*/
   .admin-page .mu_people_list_right .dc_dots_area .down-icon img {
      position: initial;
    }
  .admin-page .mu_people_list_right .dc_dots_area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .admin-page .mu_people_list_right .dc_dots_area .down-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  /* ================================================================================================
    ====                               Learning PAGE CSS End                            ====
    ================================================================================================ */

    /* Start Home Page Toggleing */
    .admin-page .tabcontent.homepage {
      display: block;
    }
    .admin-page #home {
      position: relative;
    }
    .admin-page .home-card-content {
      display: none;
    }
    .admin-page .home-page-content.hide-home {
     display: none;
   }
   .admin-page .home-card-content.show-home {
    display: block;
  }
  .admin-page #learning {
    position: relative;
  }
  .admin-page .learning-first-content.hide-learn {
    display: none;
  }
  .admin-page #schedule {
    position: relative;
  }
  .admin-page .schedule-week-content {
    display: none;
  }
  .admin-page .schedule-week-content.show-week {
    display: block;
  }
  .admin-page .schedule-month-content.hide-week {
    display: none;
  }
 .admin-page .progress-traking-first-content .ra_process_tracking_area {
    padding: 0;
  }
 .admin-page .home-text-content.new h4 {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .admin-page .home-text-area.new {
    margin-bottom: 20px;
  }
 .admin-page #traking {
    position: relative;
  }
  .admin-page .progress-traking-first-content {
    display: none;
  }
 .admin-page .progress-traking-first-content.hide-traking {
    display: block;
  }
  .admin-page .progress-traking-second-content.show-traking {
    display: none;
  }

  .admin-page .traking-content-details {
    display: none;
  }
  .admin-page .traking-content-details.show-traking-details {
    display: block;
  }
  .admin-page .progress-traking-first-content.hide-traking-details {
    display: none
  }
 .admin-page #resources {
    position: relative;
  }
 .admin-page .first-resources-content {
    display: none;
  }
 .admin-page .first-resources-content.hide-resources {
    display: block;
  }
 .admin-page .second-resources-content.show-resources {
    display: none;
  }
 .admin-page .show-all-view-details,.slider-content-details {
    display: none;
  }
  .admin-page .show-all-view-details.show-details,.slider-content-details.show-details {
    display: block;
  }
  .admin-page .learning-first-content.hide-details,.slider-content-details.hide-details {
    display: none;
  }
  .admin-page .learning-first-content.hide-learn-details,.slider-traking-first-content.hide-learn-details  {
    display: none;
  }
  .admin-page .list_document_items_area ul li {
    cursor: pointer;
  }
  .admin-page .home-card-content.show-home.hide-learn-details {
    display: none;
  }
  .admin-page .show-all-view-details.show-slider {
    display: block;
  }

  /* End Home Page Toggleing */



 /* ====================================================================================================================================================
    ===========================                          *** START NEW SECOND PROJECT PAGES CSS ***                     ================================
    ==================================================================================================================================================== */


    /* Start New Project Header Css */
    .admin-page .inbox-area-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
   .admin-page .inbox-right-content-text p {
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
      line-height: 33px;
      padding-right: 25px;
      padding-left: 8px;
    }
    .admin-page .inbox-left-content-image img {
      width: 16px;
      height: auto;
      margin-top: -8px;
    }
    /* End New Project Header Css */


    /* Start Learning Detail XL Css */\
    .admin-page .home-text-content h4 span {
      border-bottom: 1px solid #FFFFFF;
      line-height: 15px;
      display: inline-block;
    }
    .admin-page .jannle-marshall {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 170px;
      position: relative;
    }
   .admin-page .down-icon-four-update img {
      width: 12px !important;
      height: 8px !important;
      right: 17px;
      bottom: 12px;
      position: absolute;
    }
    .admin-page .jannle-marshall-text p {
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
      line-height: 33px;
	  font-size:18px;
    }
   .admin-page .jannle-marshall-image img {
      width: 22px;
      height: auto;
      margin-right: 10px;
    }
   .admin-page .home-text-content h4 span {
      border-bottom: 1px solid #FFFFFF;
      line-height: 15px;
      display: inline-block;
    }
   .admin-page .ra_resources_area_bottom_text {
      text-align: center;
    }
    /* End Learning Detail XL Css */


    /* Start Parent Page Css */
    .admin-page .right_infor_area a img {
      margin-right: 5px;
    }
    .admin-page .right_infor_area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 15px;
    }
    .admin-page .infor_heading_area {
      align-items: flex-start;
    }
   .admin-page .right_infor_area a {
      margin-top: 0;
    }
    .admin-page .main_student_information_area {
      padding-bottom: 25px;
    }
    .admin-page .student_pages_content_area {
      padding-bottom: 70px;
    }
    /* End Parent Page Css */

    /* Start Discussion Page Css */
    .admin-page .mu_dis_right_header_rtr {
      margin-right: 5px;
    }
    /* End Discussion Page Css */

    /* Start Course Progress Page Css */
    .admin-page .pt_view_main_area.button {
      margin-bottom: 0;
      margin-top: 20px !important;
    }
   .admin-page .mu_dropdown_menu_content.dropdown {
      flex: 0 0 75%;
    }
   .admin-page .action-button-arrow i {
      font-size: 15px;
      right: 8px;
      bottom: 9px;
      position: absolute;
    }
    .admin-page .ra_sortable_certificate_sub_item2 {
      text-align: center;
    }
    .admin-page .ra_sortable_certificate_sub_item2 p:nth-child(2) {
      font-size: 18px
    }
    .admin-page .ra_sortable_area_btn_area .myclassroom-single-right-right-content .dropdown button {
      padding: 6px 24px;
      border-radius: 25px;
      font-size: 14px;
      font-weight: 300;
      margin-left: 14px;
    }
    .admin-page .ra_process_tracking_area_header h3 {
      font-weight: 300;
      font-size: 20px;
    }
    .admin-page .ra_process_tracking_area.changed {
      padding-top: 0;
      padding-bottom: 0;
    }
    .admin-page .pt_left_view h3 {
      font-size: 14px;
    }
    .admin-page .ra_process_tracking_area_header_text .dropdown button {
      background: transparent;
    }
    .admin-page .dropdown-menu.new-menu.new-class.show{
      padding: 10px 0 !important;
    }
    /* End Course Progress Page Css */

    /* Start Sheduel Page Css */
    .admin-page .ra_event_single_slider_area_top h5 {
      font-weight: 300;
    }
    .admin-page .ra_event_single_slider_area_bottom p {
      font-weight: 300;
      padding-bottom: 5px;
    }
    .admin-page .ra_event_single_slider_area_bottom h1 {
      width: 135px;
    }
    .admin-page .ra_event_single_slider_area {
      padding: 5px 8px 5px 20px;
    }
    .admin-page .ra_event_single_slider_area.change_slider {
      align-items: center;
      height: 130px;
    }
    .admin-page .ra_event_main_area_slider.changed {
      padding: 0 35px 0 30px;
    }
    .admin-page .ra_event_main_area.head h2 {
      font-size: 14px;
      padding-bottom: 20px;
      padding-top: 8px;
    }
    .admin-page .ra_teacher_list_area_header.new-class {
      background: #DBF0F0;
    }
   .admin-page .ra_teacher_list_area_body.add-new-class {
      background: #E8F4FF;
    }
    .admin-page .student-contents {
      display: flex;
      align-items: center;
    }
    .admin-page .student-contents img {
      height: 38px;
      width: auto;
      margin-right: 10px;
    }
    .admin-page .ra_schedule_calender_area .ra_monday-first-card_ex_s,.upcoming-area .monday-first-card {
      background-color: #E8F4FF;
    }
   .admin-page .upcoming-area .monday-first-card.shedule-calendar {
      margin-top: -45px;
    }
    .admin-page .add-table-new-content {
      display: flex;
      align-items: center;
      padding-bottom: 5px;
    }
    .admin-page .add-table-new-content img {
      width: 25px;
      height: auto;
      margin-right: 5px;
    }
    .admin-page .ra_schedule_calender_area.new_update_class_schedule.upcoming-area tbody td {
      height: 95px;
    }
    .admin-page .new-border-class {
      border-left: 3px solid #145B94 !important;
    }
    .admin-page .view-students-new-text p {
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
      line-height: 33px;
      margin-left: 10px;
    }
    .admin-page .view-students-new-image img {
      width: 22px;
      height: auto;
    }
   .admin-page #newdropdownbtntwo {
      background: transparent;
    }
   .admin-page .ra_event_right_btn.new-class-update {
      justify-content: space-between;
      align-items: center;
    }
    .admin-page .view-students-new-content {
      display: flex;
      align-items: center;
      width: 214px;
      position: relative;
    }
    .admin-page .view-students-new-text p span {
      background: #145B94;
      font-weight: 400;
      font-size: 10px;
      color: #FFF;
      padding: 1px 7px;
      border-radius: 15px;
      width: 10px;
      margin-left: 5px;
    }
    .admin-page .custom_checkbox_area .single-checkbox-area {
      padding: 10px 15px;
      cursor: pointer;
    }
    .admin-page .custom_checkbox_area .single-checkbox-area:hover {
      background: #DBE6EE;
    }
    .admin-page .custom_checkbox_area.new-class-add {
      padding: 0;
      width: 240px;
      padding: 10px 0;
    }
    .admin-page .single-checkbox-area label span {
      cursor: pointer;
    }
    /* End Sheduel Page Css */

    /* Start Progress List Page Css */
    .admin-page .dc_new_button_add button {
      font-weight: 400;
      font-size: 11px;
      color: #3F4254;
      text-transform: capitalize;
      background: #E4E6EF;
      padding: 1px 8px;
      border-radius: 4px;
    }
    .admin-page .dc_new_button_add button:last-child{
      background: #DBD3F2;
    }
    .admin-page .dc_new_button_add.add-new button:last-child {
     background: #E4E6EF;
   }
   .admin-page .dc_new_button_add {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 14px;
  }
  .admin-page .dc_new_button_add button {
    font-weight: 400;
    font-size: 11px;
    color: #3F4254;
    text-transform: capitalize;
    background: #E4E6EF;
    padding: 1px 8px;
    border-radius: 4px;
    margin-right: 4px;
  }
  .admin-page .dc_middle_txt.new-class-add {
    margin-left: 5px;
  }
  .admin-page .dc_middle_txt a {
    position: inherit;
    border-radius: 4px;
    padding: 1px 9px;
  }
  .admin-page .dc_middle_txt p {
    padding-left: 0;
  }
  .admin-page .mu_dropdown_menu_content ul li a{
    margin: 0 !important;
  }
  .admin-page .resources-paragraf p {
    font-weight: 500;
    font-size: 14px;
    color: #05233B;
    position: relative;
    top: 30px;
  }
  /* End Progress List Page Css */

  /* Start Learning Path Content Css */
  .admin-page .new-project-porgress-traking {
    position: relative;
  }
  .admin-page .learning-path-popup-area {
    background: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: none;
  }
 .admin-page .ju_progress_tracking_main {
    padding-bottom: 100px;
    margin-bottom: 0;
  }
  .admin-page .learning-path-popup-area {
    background: #FFFFFF;
  }
 .admin-page .learning-close-button {
    padding-top: 20px;
    padding-bottom: 70px;
  }
 .admin-page .learning-path-popup-content {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
  }
  .admin-page .learning-path-popup-head {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #E8E6F2;
    padding: 8px 25px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .admin-page .learning-path-left-heading {
    flex: 0 0 50%;
  }
  .admin-page .learning-path-right-heading {
    flex: 0 0 50%;
  }
  .admin-page .learning-path-single-content {
    background: #F3F6F9;
    padding: 25px 20px;
  }
  .admin-page .learning-path-left-img {
    display: flex;
    align-items: center;
  }
 .admin-page .learning-path-single-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 .admin-page .learning-path-single-content {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
 .admin-page .learning-path-single-left {
    flex: 0 0 50%;
  }
  .admin-page .learning-path-single-right {
    flex: 0 0 50%;
  }
  .admin-page .learning-path-left-img img {
    width: 42px;
    height: auto;
    margin-right: 20px;
    border-radius: 4px;
  }
  .admin-page .learning-path-popup-head p {
    font-weight: 300;
    font-size: 12px;
    color: #3F4254;
    text-transform: capitalize;
  }
  .admin-page .learning-path-left-img p {
    font-weight: 300;
    font-size: 17px;
    color: #181C32;
    text-transform: capitalize;
  }
  .admin-page .learnging-path-single-right-left-content p {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .admin-page .learnging-path-single-right-right-content a {
    font-weight: 400;
    font-size: 12px;
    color: #0078BF;
    text-transform: capitalize;
    border: 1px solid #0078BF;
    padding: 4px 17px;
    border-radius: 20px;
    transition: .3s;
  }
 .admin-page .learnging-path-single-right-right-content a:hover {
    background: #0078BF;
    color: #FFFFFF;
  }
  /* End Learning Path Content Css */

  /* Start Students Information Area Css */
  .admin-page .students-request-area {
    padding-bottom: 90px;
  }
 .admin-page .student-information-heading {
    background: #D8E4EF;
    border-radius: 5px;
    padding: 15px 20px 60px 15px;
  }
  .admin-page .student-information-text {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
  .admin-page .student-information-heading-profile {
    position: relative;
    width: 60px;
    height: 60px;
  }
  .admin-page .student-information-heading-profile img {
    width: 60px;
    height: auto;
  }
  .admin-page .student-information-edit img {
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .admin-page .student-information-heading-right a {
    font-weight: 300;
    font-size: 12px;
    color: #0078BF;
    text-transform: inherit;
  }
 .admin-page .student-information-heading-right a img {
    width: 14px;
    height: auto;
    margin-right: 5px;
    margin-top: -10px;
  }
  .admin-page .student-information-left-heading-image {
    display: flex;
    justify-content: space-between;
  }
  .admin-page .student-information-heading {
    background: #D8E4EF;
    border-radius: 5px;
    padding: 15px 30px 60px 15px;
  }
  .admin-page .student-information-single-text-heading h2 {
    font-weight: 400;
    font-size: 18px;
    color: #3F4254;
    padding-bottom: 20px;
  }
  .admin-page .student-information-single-text-heading h3 {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    padding-bottom: 15px;
  }
  .admin-page .student-information-single-info p {
    font-weight: 400;
    font-size: 12px;
    color: #3F4254;
    text-transform: inherit;
    padding-bottom: 8px;
  }
  .admin-page .student-information-single-info p span a {
   font-weight: 400;
   font-size: 12px;
   color: #3F4254;
 }
 .admin-page .student-information-single-info p span {
  padding-left: 10px;
}
.admin-page .student-information-text .student-information-single-text:nth-child(2),.admin-page .student-information-text .student-information-single-text:nth-child(3) {
  padding-top: 30px;
}
.admin-page .students-information-dropdown {
  padding-bottom: 25px;
}
.admin-page .students-information-dropdown button {
  background: transparent;
}
.admin-page .students-request-heading p {
  font-weight: 400;
  font-size: 15px;
  color: #3F4254;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 20px;
}
.admin-page .single-students-request-content .action_st_btn_img a.btn_style_2 {
  margin-top: 14px;
  margin-bottom: 20px;
}
.admin-page .single-students-request-content.last-child {
  margin-top: 30px;
  height: 220px;
}
.admin-page .single-students-request-content {
 height: 100%;
 background: #FFFFFF;
}
.admin-page .btn_style_1.update {
  position: absolute;
  bottom: 20px;
}
.admin-page .single-students-request-content .st_single_action {
  margin-bottom: 0;
}
/* End Students Information Area Css */

/* Start Home Page Css */
.admin-page .homepage-alert .mu_alert_box, .custom_alert_design {
  background: #EFD1BD;
}
.admin-page .inbox-messages-area.new {
  background: #DBF0F0;
}
.admin-page .inbox-messages-area.new .inbox-message-left-header a span {
  background: #007D81;
}


.admin-page .home-student-information-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 15px 0 20px;
}
.admin-page .home-student-info-left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.admin-page .home-student-information {
  background: #145B94;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 94px;
}
.admin-page .home-student-information.kenneth {
  background: #00A2A7;
}
.admin-page .home-student-info-right-content a {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  text-transform: capitalize;
  display: inline-block;
  border-bottom: 1px solid #FFFFFF;
  line-height: 11px;
}
.admin-page .home-student-info-head-text {
  padding-left: 15px;
}
.admin-page .home-student-info-head-img img {
  width: 90px;
  height: auto;
}
.admin-page .home-student-info-head-text h2 {
  font-weight: 500;
  font-size: 17px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.admin-page .home-student-info-middle-text p {
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  text-transform: inherit;
}
.admin-page .home-student-info-middle-text {
  display: flex;
  padding-top: 5px;
  padding-bottom: 8px;
}
.admin-page .home-student-info-middle-text p:nth-child(2) {
  margin-left: 28px;
}
.admin-page .home-student-info-head-text h4 {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.admin-page .home-student-info-right-content {
  margin-top: -8px;
}
.admin-page .home-student-all-information {
  background: #E8F4FF;
  padding: 15px 15px 0 15px;
  border: 2px solid #C7E6FF;
  border-top: none;
  border-bottom: none;
}
.admin-page .home-student-all-information.kenneth {
  background: #DBF0F0;
  border: 2px solid #B9E3E6;
  border-top: none;
  border-bottom: none;
}
/* .active-progress {
  width: 100%;
} */
.admin-page .single-stuinfo-progressbar .myclassroom-single-left-cross-progress {
  width: 345px;
  background: #FFFFFF;
}
.admin-page .single-stuinfo-progressbar .barra-nivel {
  background: #145B94;
}
.admin-page .single-stuinfo-progressbar.kenneth .barra-nivel {
  background: #00A2A7;
}
.admin-page .single-home-student-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0;
  border-bottom: 1px solid #C7E6FF;
}
.admin-page .single-home-student-info-left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 85%;
}
.admin-page .single-stuinfo-left-content img {
  width: 45px;
  height: auto;
}
.admin-page .single-stuinfo-top-right h2 {
  font-weight: 400;
  font-size: 14px;
  color: #181C32;
  text-transform: capitalize;
}
.admin-page .single-stuinfo-right-content p {
  font-weight: 400;
  font-size: 11px;
  color: #3F4254;
  text-transform: inherit;
}
.admin-page .single-stuinfo-top-right {
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
}
.admin-page .single-stuinfo-left-content {
  flex: 0 0 15%;
}
.admin-page .single-stuinfo-right-content {
  flex: 0 0 85%;
}
.admin-page .single-home-student-info-right-content img {
  width: 13px;
  height: auto;
}
.admin-page .single-stuinfo-middle-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
}
.admin-page .single-stuinfo-middle-right p {
  font-weight: 500;
  font-size: 12px;
  color: #3F4254;
}
.admin-page .single-stuinfo-middle-right p:nth-child(2) {
  padding-left: 20px;
}
.admin-page .learning-progress-button {
  text-align: center;
  padding: 15px 0;
  background: #E8F4FF;
  border: 2px solid #C7E6FF;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.admin-page .learning-progress-button.kenneth {
  border: 2px solid #B9E3E6;
  background: #DBF0F0;
}
.admin-page .learning-progress-button a {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background: #0078BF;
  padding: 6px 40px;
  border-radius: 40px;
  text-align: center;
  border: 1px solid transparent;
  transition: .3s;
}
.admin-page .learning-progress-button a:hover {
  border: 1px solid #0078BF;
  color: #0078BF;
  background: transparent;
}
.admin-page .home-student-all-information .single-home-student-information:last-child {
  border-bottom: none;
}
.admin-page .last-accessed-paragraf p {
  font-weight: 500;
  font-size: 12px;
  color: #3F4254;
  text-align: center;
  padding-top: 12px;
}
.admin-page .single-home-progress-left-content-area {
  padding-bottom: 35px;
}
.admin-page .upcoming-top-button {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.admin-page .upcoming-top-button .upcomming-top-single-button:nth-child(2) button {
  margin-left: 15px;
}
.admin-page .upcomming-top-single-button button {
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  border: 1px solid #0078BF;
  padding: 5px 22px;
  border-radius: 50px;
  text-transform: capitalize;
  transition: .3s;
}
.admin-page .upcomming-top-single-button button:hover {
  background: #0078BF;
  color: #FFFFFF;
}
.admin-page .home-progress-area.newp {
  padding-bottom: 15px;
}
.admin-page .btn-group, .btn-group-vertical {
  align-items: center;
}
.admin-page .add-event-buttons a {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background: #0078BF;
  padding: 6px 40px;
  border-radius: 30px;
  border: 1px solid transparent;
  margin-left: 70px;
  transition: .3s;
}
.admin-page .add-event-buttons a:hover {
 background: transparent;
 border: 1px solid #0078BF;
 color: #0078BF;
}
.admin-page .upcoming-area.new-project {
  padding-bottom: 430px;
}
.admin-page .upcomming-view-classroom-content {
  display: flex;
  justify-content: flex-start;
}
.admin-page .upcomming-view-classroom-left-content {
  text-align: center;
  flex: 0 0 85%;
}
.admin-page .upcomming-view-classroom-right-content {
  flex: 0 0 15%;
}
.admin-page .upcomming-view-classroom-left-content a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-transform: inherit;
  padding-top: 10px;
}
.admin-page .upcoming-area .monday-second-card.add-new {
  width: 17%;
  bottom: inherit;
  top: 40px;
}
.admin-page .upcoming-area .tudesday-card.add-new {
  bottom: 165px;
}
.admin-page .friday-card.add-new {
  top: inherit;
  bottom: 0;
}
.admin-page .table.bg-white.text-center.tables-design.new-class {
  margin-bottom: 0;
}
.admin-page .upcomming-view-classroom-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.admin-page .upcomming-view-classroom-right-content a img {
  width: 8px;
  height: auto;
  margin: 0 20px;
}
/* End Home Page Css */
.admin-page .progress-traking-new-project-content {
  position: relative;
}
 /* ====================================================================================================================================================
    ===========================                           *** END NEW SECOND PROJECT PAGES CSS ***                      ================================
    ==================================================================================================================================================== */



 /* ====================================================================================================================================================
    ===========================                            *** START THIRD NEW PROJECT PAGES CSS ***                            ========================
    ==================================================================================================================================================== */

    /* ======= START THIRD PROJECT HOME PAGE CSS ======== */
   .admin-page .h_dot_single_area {
      display: flex;
      justify-content: flex-start;
      align-items: first baseline;
      position: relative;
      bottom: -20px;
      height: 21px;
    }
   .admin-page .single-top-progress-area:hover,.top-progress-area .single-top-progress-area:nth-child(2):hover {
      border: 3px solid #145B94;
    }
   .admin-page .h_dot_text p {
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      text-transform: inherit;
      line-height: 18px;
    }
    .admin-page .h_dot_img img {
      width: 12px;
      height: auto;
      margin-right: 10px;
    }
    .admin-page .new-home-change-progress .active-progress {
      width: 90%;
    }

    /* ======= END THIRD PROJECT HOME PAGE CSS ======== */



    /* ======= START THIRD PROJECT ATTENDANCE PAGE CSS ======== */
    .admin-page .attendance-header-area {
      position: relative;
    }
    .admin-page .attendance-student-select-area {
      display: none;
    }
   .admin-page .attendance-student-select-area.fixedHeader {
      background: #FFFFFF;
      padding: 30px 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, .1);
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
      display: block;
    }
    .admin-page .attendance-student-select-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .admin-page .attendance-header-top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
    }
    .admin-page .attendance-header-top-left-content .jannle-marshall-text p {
      font-size: 18px;
    }
    .admin-page .attendance-header-top-left-content .down-icon-four-update img {
      right: -30px;
    }
    .admin-page .attendance-header-top-left-content .students-information-dropdown {
      padding-bottom: 0;
    }
    .admin-page .take-attendance-button button {
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      text-transform: capitalize;
      background: #0078BF;
      padding: 5px 20px;
      border-radius: 35px;
      border: 1px solid transparent;
      transition: .3s;
    }
    .admin-page .take-attendance-button button:hover {
      background: transparent;
      border: 1px solid #0078BF;
      color: #0078BF;
    }
    .admin-page .attendance-header-present-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    .admin-page .single-attendance-header-present-content {
      background: #019FA3;
      padding: 15px;
      border-radius: 5px;
      flex: 0 0 23%;
    }
    .admin-page .attendance-header-present-content .single-attendance-header-present-content:nth-child(2) {
      background: #C55A11;
    }
    .admin-page .attendance-header-present-content .single-attendance-header-present-content:nth-child(3) {
      background: #790000;
    }
    .admin-page .attendance-header-present-content .single-attendance-header-present-content:last-child {
      background: #145B94;
    }
    .admin-page .single-attendance-header-middle-text {
      display: flex;
      justify-content: center;
      align-items: first baseline;
      padding: 15px 0;
    }
    .admin-page .single-attendance-header-top-text p {
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      text-transform: capitalize;
      line-height: 20px;
    }
    .admin-page .single-attendance-header-middle-text p {
      font-weight: 400;
      font-size: 15px;
      color: #FFFFFF;
      text-transform: lowercase;
      line-height: 20px;
      padding-left: 10px;
    }
    .admin-page .single-attendance-header-middle-text h4 {
      font-weight: 400;
      font-size: 25px;
      color: #FFFFFF;
      text-transform: inherit;
      line-height: 30px;
    }
    .admin-page .view-attendance-header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .admin-page .view-attendance-middle-left-content .form-groups {
      width: 180px;
      position: relative;
      margin-right: 25px;
    }
    .admin-page .view-attendance-middle-left-content .form-groups input {
      background: #FFFFFF;
      text-transform: uppercase;
      color: #3F4254;
      font-size: 14px;
    }
    .admin-page .view-attendance-left-content {
      flex: 0 0 18%;
    }
    .admin-page .view-attendance-middle-content {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
    }
    .admin-page .view-attendance-middle-right-content .mu_dropdown_menu_content ul li:last-child a {
      color: #0078BF;
    }
    .admin-page .view-attendance-middle-right-content .mu_dropdown_menu_content ul li button.menu-drop {
      margin: 0;
    }
    .admin-page .view-attendance-left-content h5 {
      font-weight: 300;
      font-size: 14px;
      color: #05233B;
      text-transform: inherit;
    }
    .admin-page .view-attendance-left-content h3 {
      font-weight: 300;
      font-size: 16px;
      color: #05233B;
      padding-top: 15px;
      padding-bottom: 6px;
    }
    .admin-page .view-attendance-left-content h3 span {
      font-size: 14px;
    }
   .admin-page .view-attendance-left-content p {
      font-weight: 300;
      font-size: 12px;
      color: #3F4254;
      text-transform: inherit;
      margin-bottom: 4px;
    }
    .admin-page .list_top_check.attendance-list-checkbox  {
      flex: 0 0 5%;
    }
    .admin-page .attendance-list-check-header .list_check_sortable_main {
      padding: 8px 16px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    .admin-page .attendance-student-single-shortable .li_arrow_sort button:nth-child(1) {
      margin-bottom: -8px;
    }
    .admin-page .attendance-student-single-shortable {
      flex: 0 0 50%;
    }
    .admin-page .attendance-student-single-shortable.new-class {
      flex: 0 0 10%;
    }
    .admin-page .single-attendance-student-list {
      background: #FFFFFF;
      padding: 22px 16px;
      box-shadow: 0 0 3px rgba(0,0,0,.1);
      border-radius: 4px;
      margin-bottom: 18px;
    }
    .admin-page .single-attendance-student-same-content {
      display: flex;
      align-items: center;
    }
   .admin-page .single-attendance-student-profile-content {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
    }
    .admin-page .single-attendance-student-profile-left-content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 0 0 50%;
    }
    .admin-page .single-attendance-student-profile-right-content {
      flex: 0 0 50%;
    }
   .admin-page .single-attendance-student-count-content {
      flex: 0 0 10%;
    }
   .admin-page .single-attendance-student-profile-left-content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .admin-page .single-attendance-student-pro-img {
      width: 40px;
      position: relative;
    }
    .admin-page .single-attendance-student-pro-img button {
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      background: #00A2A7;
      padding: 2px 7px;
      color: #FFFFFF;
      border-radius: 3px;
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      margin: auto;
    }
    .admin-page .single-atendance-studeint-pro-text p,.admin-page .single-attendance-student-profile-right-content p,.admin-page .single-attendance-student-count-content p {
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
      text-transform: inherit;
    }
    .admin-page .single-atendance-studeint-pro-text p {
      margin-left: 12px;
    }
    .admin-page .single-attendance-student-dot-content {
      flex: 0 0 5%;
      margin-left: 10px;
    }
    .admin-page .single-attendance-student-pro-img img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
   .admin-page .list_top_check.attendance-list-checkbox .list_check .mu-form-group input:checked + label::after {
      background: url('/media/attendance/checked.png');
      width: 19px;
      height: 19px;
      left: 0;
      top: 5px;
      border: navajowhite;
      transform: rotate(0);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .admin-page .single-attendance-student-two-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 43%;
    }
    .admin-page .single-attendance-student-two-button {
      display: flex;
      align-items: center;
    }
    .admin-page .single-attendance-student-two-button button {
      font-weight: 300;
      font-size: 12px;
      color: #0078BF;
      background: transparent;
      padding: 4px 5px;
      border: 1px solid #0078BF;
      border-radius: 50px;
      width: 80px;
      margin: 0 5px;
      transition: .3s;
    }
    .admin-page .single-attendance-student-two-button button:hover {
      background: #0078BF;
      color: #FFFFFF;
    }
    .admin-page .single-attendance-student-two-button button:nth-child(2) {
      border: 1px solid #C55A11;
      color: #C55A11;
    }
    .admin-page .single-attendance-student-two-button button:nth-child(2):hover {
      background: #C55A11;
      color: #FFFFFF;
    }
    .admin-page .single-attendance-student-two-button button:nth-child(3) {
      border: 1px solid #790000;
      color: #790000;
    }
    .admin-page .single-attendance-student-two-button button:nth-child(3):hover {
      background: #790000;
      color: #FFFFFF;
    }
    .admin-page .attendance-student-select-left-content p {
      font-weight: 300;
      font-size: 14px;
      color: #05233B;
      text-transform: capitalize;
    }
    .admin-page .attendance-student-select-left-content p span {
      background: #05233B;
      font-weight: 400;
      font-size: 10px;
      color: #FFF;
      padding: 1px 8px;
      border-radius: 15px;
      margin-left: 8px;
    }

    /* ======= END THIRD PROJECT ATTENDANCE PAGE CSS ======== */

    /* ======= START ALL THIRD PROJECT POPUP PAGE CSS ======== */
   .admin-page .form-control.select-course.new-class {
      max-width: 340px;
      width: 100%;
    }
    .admin-page .request-enrollment-area.new-class {
      display: block;
      position: relative;
    }
    .admin-page .single-same-button-design.new-class button {
      background: #0078BF;
      border: 1px solid #0078BF;
      color: #FFFFFF;
      margin-left: 15px;
      font-size: 13px;
      padding: 7px 5px;
      width: 120px;
      border-radius: 50px;
    }
    .admin-page .single-same-button-design.new-class button:hover {
      background: transparent;
      border: 1px solid #0078BF;
      color: #0078BF;
    }
    .admin-page .request-enrollment-textarea-content textarea {
      max-width: 350px;
      width: 100%;
      resize: none;
      border: 1px solid #3F4254;
      border-radius: 5px;
      padding: 10px 10px;
      height: 115px;
      background: transparent;
    }
    .admin-page .request-enrollment-textarea-content textarea:focus {
      outline: none;
    }
    .admin-page .request-enrollment-single-datepiker.new-class {
      margin-bottom: 20px;
    }
   .admin-page .theacher-popup-content {
      padding-bottom: 100px;
      background: #FFFFFF;
    }
/*     .theacher-popup-content-two,.theacher-popup-content-two,.theacher-popup-content-three,.theacher-enrolment-popup-content,.theacher-enrolment-popup-content-two,.assiment-setup-popup-content,.assiment-setup-popup-three-area {
      background: #FFFFFF;
    }
    .theacher-popup-content {
      padding-bottom: 100px;
    }
    .theacher-popup-content-two {
      padding-bottom: 340px;
    }
    .theacher-popup-content-three {
      padding-bottom: 108px;
    }
    .theacher-enrolment-popup-content {
      padding-bottom: 500px;
    }
    .theacher-enrolment-popup-content-two {
      padding-bottom: 100px;
    }
    .assiment-setup-popup-three-area {
      padding-bottom: 70px;
    }
    .assiment-setup-popup-content {
      padding-bottom: 250px;
    } */
    .admin-page .add-activity-text-left-content h3 {
      font-weight: 400;
      font-size: 18px;
      color: #05233B;
      text-transform: inherit;
      line-height: 28px;
    }
    .admin-page .add-activity-text-right-content button {
      font-weight: 300;
      font-size: 12px;
      color: #0078BF;
      text-transform: capitalize;
      background: transparent;
    }
    .admin-page .add-activity-text-right-content button img {
      width: 13px;
      height: auto;
      margin-top: -7px;
      margin-right: 10px;
    }
   .admin-page .add-activity-text-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
    }
    .admin-page .tab-content.new-class-add-last-website .single-same-button-design.new-class.change {
      margin-right: -80px;
    }
    .admin-page .tab-content.new-class-add-last-website #select-chose-course {
      max-width: 350px;
      width: 100%;
    }
    .admin-page .tab-content.new-class-add-last-website .request-enrollment-main-content {
      max-width: 350px;
      width: 100%;
    }
    .admin-page .tab-content.new-class-add-last-website .form-groups {
      max-width: 350px;
      width: 100%;
    }
    .admin-page .tab-content.new-class-add-last-website .form-groups .input-field {
      max-width: 350px;
      width: 100%;
    }
    .admin-page .tab-content.new-class-add-last-website .single-myinfo-form input {
      background: transparent;
    }

    .admin-page .tab-content.new-class-add-last-website .single-myinfo-form {
      padding-bottom: 15px;
      margin: 20px 0;
    }
    .admin-page .tab-content.new-class-add-last-website .dropdown-menu.select-course {
      max-width: 350px;
    }
    .admin-page .single-enrolment-file-images img {
      width: 40px;
      height: auto;
    }
   .admin-page .single-enrolment-file-text p, .single-enrolment-file-text p button {
      font-weight: 300;
      font-size: 15px;
      color: #05233B;
      text-transform: inherit;
      margin: 10px 0;
    }
    .admin-page .__lk-fileInput input {
      display: none;
    }
    .admin-page .__lk-fileInput span {
      color: #0078BF;
      border-bottom: 1px solid #0078BF;
      margin-left: 2px;
      background: transparent;
      line-height: 14px;
      cursor: pointer;
      display: inline-block;
    }
    .admin-page .single-enrolment-file-upload-area {
      border: 3px dashed #E9EAF1;
      border-radius: 0;
      text-align: center;
      padding: 20px 15px;
      cursor: pointer;
      margin-bottom: 35px;
      margin-top: 30px;
    }
    .admin-page .teacher-popup-checkbox {
      padding-bottom: 25px;
    }
    .admin-page .teacher-popup-checkbox-content .mu-form-group label span {
      font-weight: 300;
      font-size: 14px;
      color: #3E3F42;
      margin-left: 12px;
      position: absolute;
      top: 6px;
    }
    .admin-page .teacher-popup-checkbox-content .mu-form-group label {
      width: 100%;
    }
    .admin-page .teacher-popup-new-design .single-myinfo-form {
      margin: 28px 0 !important;
      padding-bottom: 0 !important;
    }
    .admin-page .teacher-popup-new-design .single-myinfo-form input {
      max-width: 150px;
      width: 100%;
    }
    .admin-page .teacher-new-paragraf p {
      font-weight: 300;
      font-size: 12px;
      color: #3F4254;
      margin-top: ;
      text-transform: inherit;
      margin-top: 8px;
    }
    .admin-page .teacher-popup-checkbox.new-class-add {
      padding-bottom: 10px;
    }
    .admin-page .teacher-add-more a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      text-transform: inherit;
      text-align: right;
      display: block;
      padding-top: 5px;
    }
    .admin-page .request-enrollment-single-datepiker.new-class.due {
      padding: 15px 0;
    }
    .admin-page .request-enrollment-textarea-content.add-class textarea::placeholder,.admin-page .request-enrollment-textarea-content.add-class textarea {
      font-weight: 300;
      font-size: 14px;
      color: #3F4254;
      text-transform: inherit;
      line-height: 1.2;
    }
    .admin-page .request-enrollment-textarea-content.add-class.prefilled {
      padding-top: 70px;
    }
    .admin-page .teacher-time-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 15px;
    }
    .admin-page .teacher-left-time-area {
      border: 1px solid #3F4254;
      width: 92px;
      border-radius: 6px;
    }
    .admin-page .teacher-left-time-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .admin-page .teacher-time-up-down {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
    .admin-page .teacher-time-up-down img {
      width: 10px;
      height: auto;
      cursor: pointer;
    }
    .admin-page .teacher-time-up-down img:first-child {
      margin-bottom: 8px;
    }
    .admin-page .teacher-time-text p {
      margin-bottom: 0;
      padding: 8px 10px;
      border-right: 1px solid #3F4254;
      padding-right: 13px;
    }
    .admin-page .teacher-time-text p span {
      display: inline-block;
      margin-top: 0;
      margin: 0 3px;
      margin-top: -8px;
    }
    .admin-page .teacher-right-time-area {
      width: 95px;
      display: flex;
      justify-content: space-around;
      border-radius: 5px;
      align-items: center;
      font-weight: 300;
      font-size: 14px;
      color: #3F4254;
      text-transform: uppercase;
      margin-left: 15px;
    }
    .admin-page .teacher-time-am p,.admin-page .teacher-time-pm p {
      padding: 8px 12px;
      flex: 0 0 50%;
      margin-bottom: 0 !important;
    }
    .admin-page .teacher-time-am p {
      background: #3F4254;
      color: #FFFFFF;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid #3F4254;
    }
    .admin-page .teacher-time-pm p {
      border: 1px solid #3F4254;
      border-left: transparent;
      border-radius: ;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
   .admin-page .add-activity-text-content.new-class {
      padding-bottom: 50px;
    }
    .admin-page .request-enrollment-single-select-option.new-class-add {
      margin-bottom: 35px;
    }
   .admin-page .all-same-button-design.nav.new-add-class {
      padding-top: 295px;
    }

    .admin-page .all-same-button-design.nav.new-add-class ul li .single-same-button-design button {
      padding: 6px 10px;
      width: 125px;
    }
    .admin-page .request-enrollment-main-content.setup-assiment {
      max-width: 550px;
    }
    .admin-page .assignment-environemental-area .mu_dis_pdf_flex_box {
      justify-content: space-between;
    }
    .admin-page .add-activity-text-content.new-class.add {
      padding-bottom: 20px;
    }
    .admin-page .assignment-environemental-area .mu_dis_pdf_flex_left {
      flex: 0 0 75%;
      margin-right: 0;
    }
    .admin-page .assignment-environemental-area  .mu_dis_pdf_flex_right {
      flex: 0 0 25%;
    }
   .admin-page .assignment-environemental-area .mu_dis_pdf_flex_right a {
      justify-content: space-between;
    }
    .admin-page .assignment-environemental-area .mu_dis_pdf_flex_left a,.admin-page .assignment-environemental-area .mu_dis_pdf_flex_right p {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
    }
    .admin-page .assignment-environemental-input {
      padding-top: 12px;
    }
   .admin-page .single-myinfo-form.points {
      width: 120px;
    }
   .admin-page .assignment-environemental-input .form-groups.assignment {
      max-width: 350px;
      width: 100%;
    }
   .admin-page .assignment-environemental-input .form-groups.assignment  input {
      max-width: 350px;
      width: 100%;
    }
   .admin-page .dropdown-item.enrollment img {
      width: 16px;
      margin-top: -4px;
      margin-right: 5px;
    }

   .admin-page .assiment-setup-popup-three-content {
      padding-top: 20px;
    }
    .admin-page .assiment-setup-popup-three-content {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .admin-page .assiment-setup-popup-three-right-top-content {
      padding-top: 35px;
      padding-bottom: 15px;
    }
   .admin-page .assiment-setup-popup-three-left-content {
      flex: 0 0 64%;
    }
   .admin-page .assiment-setup-popup-three-right-content {
      flex: 0 0 34%;
      margin-left: 20px;
    }
    .admin-page .assiment-setup-popup-three-left-image img {
      width: 100%;
      height: auto;
    }
    .admin-page .assiment-setup-popup-three-left-content p {
      font-weight: 400;
      font-size: 18px;
      color: #05233B;
      text-transform: inherit;
      line-height: 25px;
      padding-bottom: 10px;
    }
    .admin-page .assiment-setup-popup-three-left-content p span {
      padding-left: 25px;
    }
    .admin-page .assiment-new-input-field input {
      max-width: 280px;
      width: 100%;
    }
   .admin-page .assiment-new-input-field .single-myinfo-form {
      padding-bottom: 0;
    }
    .admin-page .assignment-setup-textarea textarea {
      max-width: inherit;
      font-weight: 300;
      font-size: 14px;
      color: #3F4254;
      height: 100px;
    }
    .admin-page .submit-button-content-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 35px;
    }
    .admin-page .single-setup-submit-button button {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      padding: 5px 15px;
      background: transparent;
      border: 1px solid #0078BF;
      border-radius: 35px;
      transition: .3s;
    }
    .admin-page .single-setup-submit-button button:hover {
      background: #0078BF;
      color: #FFFFFF;
    }
    .admin-page .assignment-showing-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .admin-page .assignment-shoing-left-content p {
      font-weight: 300;
      font-size: 12px;
      color: #3F4254;
      text-transform: inherit;
      margin-right: 15px;
    }
   .admin-page .assignment-shoing-right-content button {
      background: transparent;
      border: 1px solid #0078BF;
      padding: 2px 10px;
      border-radius: 5px;
      margin: 0 2px;
      text-align: center;
    }
    .admin-page .assignment-shoing-right-content button img {
      width: 8px;
      height: auto;
      margin-top: -7px;
    }
    .admin-page .request-enrollment-single-select-option.add-new-class {
      margin-bottom: 14px;
    }
    .admin-page .assignment-setup-textarea {
      padding-top: 6px;
    }
    /* ======= END ALL THIRD PROJECT POPUP PAGE CSS ======== */




    /* ======= START SCHEDULE THIRD PROJECT PAGE ======== */

    .admin-page .ra_event_main_area_slider_items.add-new-class .ra_event_single_slider_area {
      padding: 24px 8px 24px 20px;
    }
    .admin-page .ra_event_main_area_slider_items.add-new-class .ra_event_single_slider_area_bottom h1 {
      width: 154px;
    }
    .admin-page .ra_upcomming-left-contents p {
      padding-bottom: 0;
      padding-left: 10px;
    }
    .admin-page .ra_upcoming_heading.last-pages-class {
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      margin-top: 45px;
    }
    .admin-page .ra_upcoming-right-contents {
      display: flex;
      align-items: first baseline;
    }
    .admin-page .single-ra_upcoming-right-contents a img {
      width: auto;
      height: 20px;
      margin-left: 20px;
    }
    .admin-page .single-ra-upcoming-right-image-contents {
      display: flex;
    }

    .admin-page .ra-last-teacher-list-contents .re_body_list_item3,.admin-page .ra-last-teacher-list-contents .re_header_list_item3 {
      flex: 0 0 40%;
    }
    .admin-page .ra-last-teacher-list-contents  .re_body_list_item1,.admin-page .ra-last-teacher-list-contents  .re_header_list_item1 {
      flex: 0 0 40%;
    }
    .admin-page .monday-first-card.ra_monday-first-card_ex_s.one-change {
      background: #E5F4F4;
    }
    .admin-page .monday-first-card.ra_monday-first-card_ex_b.two-change {
      background: #DBE6EE;
    }
    .admin-page .full-classroom-schedule-content {
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 20px;
      border-radius: 3px;
      margin-top: -13px;
    }
    .admin-page .full-classroom-schedule-left-content a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      text-transform: inherit;
    }
   .admin-page .dropdown_search_flex.ar-gradebook-filter-wrapper {
      padding: 0px 20px;
      margin-top: 13px;
      border-left: 1px solid #E4E6EF;
      border-top: 1px solid #E4E6EF;
      border-right: 1px solid #E4E6EF;
    }
   .admin-page .mu_dropdown_menu_content.ar-gradebook-filter-dropdown {
      flex: 0 0 60%;
    }
   .admin-page .mu_dropdown_menu_area.add-new-class,.list_prog_heading.add-new-class {
      margin-top: 0;
    }
   .admin-page .ra_upcoming_heading.last-pages-class.month-page {
      margin-bottom: 5px;
    }

    /* ======= END SCHEDULE THIRD PROJECT PAGE ======== */


    /* ======= START STUDENT CURRENT COURSE PROGRESS DETAILS ======== */

    .admin-page .student-course-progress-top-content {
      padding-top: 20px;
      padding-bottom: 15px;
    }
    .admin-page .dropdown-menu.new-class-add .dropdown-item {
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
      text-transform: inherit;
      padding: 6px 20px;
    }
    .admin-page .student-course-progress-top-content .ra_process_tracking_area_header_search {
      justify-content: flex-end;
    }
    .admin-page .down-icon-four-update.new-class img {
      right: 50px;
    }
    .admin-page .student-course-progress-content .row .col-xl-3 {
      padding-left: 0;
    }
    .admin-page .student-course-progress-content .row .col-xl-9 {
      padding-right: 0;
    }
    .admin-page .change-password-left-content.course-details {
      padding-bottom: 55px;
    }
    .admin-page .student-information-heading-profile {
      display: flex;
      align-items: first baseline;
    }
    .admin-page .student-information-right-headings button {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      text-transform: capitalize;
      background: transparent;
      padding: 5px 10px;
      border: 1px solid #0078BF;
      border-radius: 30px;
      width: 155px;
      margin-left: 20px;
      transition: .3s;
	  overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
    }
    .admin-page .student-information-right-headings button:hover {
      background: #0078BF;
      color: #FFFFFF;
    }
    .admin-page .ra_process_tracking_area.add-new-class {
      padding: 25px 0;
    }
    .admin-page .ra_sortable_right_btn_area_sub_item_area.add-new-class {
      margin-left: 20px;
    }
    .admin-page .ra_sortable_certificate_main2.s-new-class {
      flex: 0 0 20%;
    }
    .admin-page .ra_sortable_right_btn_area_sub2.sh-new-class {
      padding-right: 8px;
    }
    .admin-page .down-icon.new-down-icon a {
      color: #3F4254;
    }
    .admin-page .down-icon.new-down-icon a:hover {
      color: #3F4254;
    }
    .admin-page .down-icon.new-down-icon img {
      width: 12px;
      height: auto;
      position: absolute;
      top: 7px;
      right: -15px;
    }
    .admin-page .ra_sortable_header_area_menu ul li.short-option {
      margin-left: 130px;
    }
   .admin-page .dropdown-item.drop_as.add-new-class {
      position: relative;
    }
    .admin-page .li_arrow_sort.add-new-class {
      position: absolute;
      right: 15px;
      top: 5px;
    }
    .admin-page .rt_progress_left_side.new-add-class {
      flex: 0 0 93%;
    }
    .admin-page .rt_progress_txt.add-new h3 {
      color: #FFFFFF;
      padding-top: 15px;
    }
    .admin-page .left_rt_progress_sys.course-pgrs {
      flex: 0 0 30%;
    }
   .admin-page .right_rt_progress_sys.course-pgrs {
      flex: 0 0 calc(70% - 15px);
    }
    .admin-page .mu_search_bar.new-class.again-add {
      flex: 0 0 85%;
    }
    .admin-page .view_img_1.new {
      width: 14px;
    }
    .admin-page .ju_posional_progress.adil.progress-change {
      top: 7px;
    }
    .admin-page .ju_posional_progress.progress-change .myclassroom-single-left-cross-progress {
      /*max-width: 590px;*/
    }
    .admin-page .ju_single_table_content_design.change-padding ul > li {
      padding: 32px 5px;
    }
    /* ======= END STUDENT CURRENT COURSE PROGRESS DETAILS ======== */

    /* ======= START STUDENT PAGES CSS ======== */
    .admin-page .mu_dropdown_menu_area.add-new-class.again-add {
      padding-top: 30px;
      padding-bottom: 8px;
    }
    .admin-page .mu_search_bar.new-class.student {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .admin-page .student-page-paragraf {
      flex: 0 0 60%;
      text-align: right;
    }
   .admin-page .student-page-paragraf p {
      font-weight: 300;
      font-size: 12px;
      color: #3F4254;
      text-transform: capitalize;
    }
    .admin-page .list_top_check.add-new-class {
      flex: 0 0 5%;
    }
    .admin-page .ar-single-search.search1.new-add {
      flex: 0 0 42%;
      margin-right: 20px;
    }
    .admin-page .ar-single-search.search3.new-add {
      flex: 0 0 20%;
    }
    .admin-page .ar-single-search.search4.new-add {
      flex: 0 0 38%;
    }
    .admin-page .ar-single-search.search5.new-add {
      flex: 0 0 22%;
    }

    .admin-page .ar-search_box_area.ar-input1.new-add input[type="search"] {
      max-width: 225px;
      width: 100%;
    }
    .admin-page .ar-search_box_area.ar-input3.new-add input[type="search"] {
      max-width: 80px;
      width: 100%;
    }
    .admin-page .ar-search_box_area.ar-input4.new-add input[type="search"] {
      max-width: 190px;
      max-width: 140px;
      width: 100%;
    }
    .admin-page .ar-search_box_area.ar-input5.new-add input {
      max-width: 90px;
      width: 100%;
    }
   .admin-page .ar-search_box_area.ar-input7.new-add input[type="search"] {
      width: 90px;
    }
    .admin-page .student-listing-single-content .profile-section {
      margin-left: 0;
    }
    .admin-page .student-listing-single-content {
      flex: 0 0 20%;
    }
    .admin-page .student-grade-lavel-single-content {
      flex: 0 0 18%;
      text-align: center;
    }
    .admin-page .student-gpa-contents {
      flex: 0 0 18%;
      text-align: center;
    }
    .admin-page .student-course-progress-contents {
      flex: 0 0 18%;
    }
   .admin-page .student-status-text-contents {
      flex: 0 0 9%;
      text-align: center;
    }
   .admin-page .student-three-dot-contents {
      flex: 0 0 7%;
      text-align: right;
    }
    .admin-page .dc_single_items.ads-new-class {
      margin-bottom: 12px;
    }

    /* ======= END STUDENT PAGES CSS ======== */

    /* ======= START LEARNING COURSE STUDENT PAGES CSS ======== */

    .admin-page .ra_science_area.add-new-class {
      padding-bottom: 0;
    }
    .admin-page .ra_science_top_calendar.add-new-class {
      padding-bottom: 0;
    }
    .admin-page .ra_science_main_area.add-new-class {
      padding-top: 0;
    }
    .admin-page .header-profile-dropdown.add-new-class {
      padding-left: 0;
    }
    .admin-page .header-profile-dropdown.add-new-class::before,.admin-page .header-profile-dropdown.add-new-class::after {
      display: none;
    }
    .admin-page .profile-text.add-new-class h5 {
      font-size: 19px;
    }

/*     .myclassroom-single-left-cross-progress {
      width: 200px;
    } */
    .admin-page .learning-enviroment-progress-text p {
      font-weight: 300;
      font-size: 12px;
      color: #3F4254;
      text-transform: capitalize;
    }
   .admin-page .learning-enviroment-learn-button .contact-button a {
      font-size: 14px;
      color: #0078BF;
      border: 1px solid #0078BF;
      padding: 5px 30px;
      border-radius: 26px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
    .admin-page .learning-enviroment-learn-button .contact-button a:hover {
      background: #0078BF;
      border-color: #0078BF;
      color: #FFF;
    }
   .admin-page .profile-text.add-new-class {
      margin-left: 0;
    }
    .admin-page .ra_science_main_area.add-new-class {
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      flex-wrap: wrap;
    }
    .admin-page .learning-top-enviroment-text {
      flex: 0 0 23%;
    }
   .admin-page .learning-enviroment-progress-content {
      flex: 0 0 23%;
    }
    .admin-page .single-top-progress-area.add-new-one-class {
      background: url('/media/learning/learning-one.png') no-repeat scroll 0 0 / cover !important;
    }
    .admin-page .single-top-progress-area.common-class p,.single-top-progress-area.common-class h1,.single-top-progress-area.common-class h5 {
      color: #05233B;
    }
    .admin-page .single-top-progress-area.add-new-one-class {
      background: url('/media/learning/learning-one.png') no-repeat scroll 0 0 / cover !important;
    }
    .admin-page .single-top-progress-area.add-new-two-class {
      background: url('/media/learning/learning-two.png') no-repeat scroll 0 0 / cover !important;
    }
    .admin-page .single-top-progress-area.add-new-three-class {
      background: url('/media/learning/learning-three.png') no-repeat scroll 0 0 / cover !important;
    }
    .admin-page .single-top-progress-area.add-new-four-class {
      background: url('/media/learning/learning-four.png') no-repeat scroll 0 0 / cover !important;
    }
    .admin-page .single-top-progress-area.common-class {
      padding-bottom: 40px;
    }
    .admin-page .view-greadback-content {
      background: #FFFFFF;
      padding: 15px 15px;
      position: relative;
      height: 105px;
    }
    .admin-page .ra_resources_content .col-xl-3 {
      padding-left: 5px;
    }
   .admin-page .view-greadback-content p {
      font-weight: 300;
      font-size: 14px;
      color: #3F4254;
      text-transform: inherit;
    }
    .admin-page .view-greadback-content a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      text-transform: inherit;
      margin-top: 15px;
      position: absolute;
      left: 15px;
      bottom: 15px;
      z-index: 1;
    }
   .admin-page .learning-course-progress-single-content {
      flex: 0 0 24%;
    }
    .admin-page .ra_resources_right_area_single_item.add-new-add-class.ra_resources_top {
     display: flex;
     justify-content: space-between;
     align-items: flex-start;
   }
   .admin-page .ra_resources_right_area_single_item.add-new-add-class.ra_resources_top p {
    padding-top: 5px;
  }
  .admin-page .ra_resources_right_text_contents img {
    width: 19px;
    height: auto;
  }
  .admin-page .ra_resources_left_text_contents.add-new p {
    padding-bottom: 40px;
  }
  .admin-page .ra_resources_right_area_single_item.learn-class-add::after {
    left: -15px;
    width: 115%;
  }
  .admin-page .ra_resources_slider_area_butttom.add-new-class {
    padding: 9px 16px;
    justify-content: flex-end;
  }
 .admin-page .ra_courch_single_item_right.add-class-new {
    flex: 0 0 18%;
  }
 .admin-page .ra_courch_single_item_left.add-course-class {
    flex: 0 0 33%;
  }
 .admin-page .contact-button p {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding-right: 38px;
  }
  .admin-page .ra_courch_single_item_left.add-course-class {
    flex: 0 0 70%;
    display: flex;
    align-items: center;
  }
  .admin-page .middle-course-text-class {
    padding-left: 100px;
  }
 .admin-page .ra_courch_single_item_left.learn-course {
    flex: 0 0 65%;
  }
  .admin-page .learn-courses-date p {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .admin-page .student-greadback-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
  }
  .admin-page .student-greadback-buttons button {
    background: transparent;
    font-weight: 300;
    font-size: 14px;
    color: #0078BF;
    text-transform: inherit;
    border: 1px solid #0078BF;
    padding: 5px 30px;
    border-radius: 32px;
    transition: .3s;
    margin-right: 25px;
  }
 .admin-page .student-greadback-buttons button:hover {
    background: #0078BF;
    color: #FFFFFF;
  }
  .admin-page .student-greadbak-right-content {
    display: flex;
    align-items: center;
  }
  .admin-page .dropdown-menu.adjust.adjust-two {
    left: 0 !important;
  }
 .admin-page .custom-control.custom-checkbox.mb-3.add-new {
    margin-top: 10px;
  }
  .admin-page .ra_calender_area_bottom_checkbox.new-class ul li a img {
    width: 8px;
    height: auto;
  }
  .admin-page .ra_calender_area_bottom_checkbox.new-class ul li a:last-child {
    margin-left: 18px;
  }
  .admin-page .ra_upcoming_heading.last-pages-class.last {
    margin-top: 0;
  }
 .admin-page .course-progress-today {
  padding-top: 10px;
}
.admin-page .course-progress-img-single-contents p {
  font-weight: 300;
  font-size: 12px;
  color: #05233B;
  text-transform: inherit;
}
.admin-page .course-progress-today .active-progress {
  width: 90%;
  background: #05233B;
  margin-top: 2px;
}
.admin-page .course-progress-today .barra-nivel {
  background: #FAF3DD;
}.admin-page .course-progress-img-single-contents {
  display: flex;
  align-items: center;
}
.admin-page .course-progress-img-single-contents img {
  width: 12px;
  height: auto;
  margin-right: 12px;
}
.admin-page .course-progress-img-text-contents {
  padding-top: 16px;
}
.admin-page .ra_resources_right_area_single_item.learn-class-add:last-child::after { 
background: #EAEDF3;
}
.admin-page .ra_resources_right_area.add-new-class {
  padding-bottom: 12px;
}
.admin-page .enviroment-sections-heading {
  background: #FFFFFF;
  padding: 10px 10px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.admin-page .enviroment-sections-middle-contents p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
}
.admin-page .enviroment-sections-left-contents {
  display: flex;
  align-items: center;
}
.admin-page .enviroment-sections-left-contents .dc_check_star {
  flex: 0 0 20%;
}
.admin-page .enviroment-sections-single-contents {
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px;
  margin-top: 5px;
}
.admin-page .view-all-learn-button a {
  color: #0078BF;
  font-size: 14px;
  font-weight: 300;
}
.admin-page .view-all-learn-button {
  padding: 10px 20px;
  background: #FFFFFF;
  margin-top: 5px;
}

  /* ======= END LEARNING COURSE STUDENT PAGES CSS ======== */












  /* ABDUR RAHIM*/

/*--============================================================================
====                          abdur rahim                         ====
/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/



.admin-page .ar-learn-search-bar form {
  justify-content: flex-start;
}
.admin-page .ar-learn-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-page .ar-filter-area{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 20px;
  margin-top: 30px;
}
.admin-page .ar-reset-funtion-area a{
  position: relative;
  color: #0078BF;
  font-size: 14px;
  font-weight: 300;
}
.admin-page .ar-reset-funtion-area img {
  position: absolute;
  left: -20px;
  top: 2px;
}
.admin-page .ar-button-filter {
  padding: 15px 0px !important;
  margin: 0px !important;
}
.admin-page .ar-button-filter img {
  right: -20px;
  top: 29px;
}
.admin-page .ar-filter-area{
  border: 1px solid #E4E6EF;
  border-radius: 5px;
} 
.admin-page .ar-drop-menu-cont ul {
  flex-wrap: wrap;
}
.admin-page .ar-filter-all-wrapper{
  display: flex;
  padding: 10px 20px 13px 20px;
  margin-top: 1px;
}
.admin-page .ar-single-filter-check{
  padding-right: 30px;
}
.admin-page .ar-srech-filt-menu ul li{
  padding: 2px 0px;
  margin-left: 0px; 
}
.admin-page .ar-filter-all-wrapper{
  background: #E4E6EF;
}
.admin-page .ar-search-wrapper{
  display: flex;
  align-items: center;
}

.admin-page .ar-single-search{
  margin-right: 10px;
}
.admin-page .ar-search_box_area input[type="search"]{
  background: #FFF;
  border: 1px solid #A6ADB1;
  padding: 7px 0px 7px 10px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
}

.admin-page .ar-input1 input[type="search"]{
  width: 185px;
}
.admin-page .ar-input2 input[type="search"]{
  width: 100px;
}

.admin-page .ar-input3 input[type="search"]{
  width: 150px;
}

.admin-page .ar-input4 input[type="search"]{
  width: 130px;
}

.admin-page .ar-input5 input[type="search"]{
  width: 60px;
}
.admin-page .ar-input6 input[type="search"]{
  width: 60px;
}
.admin-page .ar-input7 input[type="search"]{
  width: 100px;
}








.admin-page .ar-single-department-course{
  background: #fff;
}
.admin-page .ar-single-department-course{
  display: flex;
  align-items: center;
  padding: 15px 0px;
  box-shadow: 0px 0px 10px #E4E6EF;
  margin-bottom: 10px;
}

.admin-page .stu-dept{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.admin-page .student-sec{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-page .student-img {
  position: relative;
  padding-right: 20px;
  padding-left: 25px;
}
.admin-page .ar-learn-button {
  position: absolute;
  left: 88px;
  top: 50px;
}
.admin-page .student-img img{
  width: 40px;
  height: auto;
}
.admin-page .student-name h3{
  font-size: 17px;
  color: #181C32;
  font-weight: 300;
  font-family: 'Avenir';
}
.admin-page .student-name span{
  color: #3F4254;
  font-size: 12px;
  font-family: 'Avenir';
  font-weight: 300;
}
.admin-page .stu-dept{
  /*width: 400px;*/
  flex: 0 0 38%;
}
.admin-page .department-name {
  background: #E4E6EF;
  border-radius: 5px;
  margin-right: 30px;
}
.admin-page .department-name p{
  color: #3F4254;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 300;
}

.admin-page .cour-staf{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 300px;
  padding-left: 20px;
}
.admin-page .course-sec{
  background: #E4E6EF;
  border-radius: 5px;
}
.admin-page .course-sec p{
  color: #3F4254;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 300;
}
.admin-page .staff-sec {
  padding-top: 10px;
  padding-left: 30px;
  width:50%;
}
.admin-page .staff-sec-sec{
  background: #E4E6EF;
  border-radius: 5px;
  margin-bottom: 5px;
}
.admin-page .staff-sec-sec p{
  color: #3F4254;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 300;
  
}
.admin-page .staff-sec span{
  color: #3F4254;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 300;
  
}

.admin-page .wek-stu-stuta {
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap ;
  align-items: center;
  padding-left: 10px;
  padding-right: 25px;
}
.admin-page .weeks-sec {
  padding-left: 10px;
}
.admin-page .weeks-sec p,
.admin-page .wek-stu-sec{
  color: #3F4254;
  font-size: 14px;
  padding: 2px 10px;
  font-weight: 300;
}
.admin-page .wek-stu-sec{
  padding-left: 40px;
}
.admin-page .wek-stu-sec-status p{
  color: #3F4254;
  font-size: 14px;
  padding: 2px 20px;
  font-weight: 300;
  text-transform: capitalize;
}
.admin-page .ar-right-btn{
  margin-left: 0px;
}
.admin-page .wek-stu-sec-status {
  padding-left: 25px;
}
.admin-page .ar-btn-sub2{
  padding-left: 20px;
}
.admin-page .ar-launch a{
  text-transform: capitalize;
  padding: 4px 20px !important;
}


/*next-button*/
.admin-page .ar-next-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 0px;
}
.admin-page .ar-next-button a img{
  padding-bottom: 7px;
}
/*chat-option-area*/
.admin-page .chat-wrapper{
  display: flex;
  justify-content: flex-end;
  padding: 50px 0px 30px 0px;
}
.admin-page .chat-option-area{
  display: flex;
  justify-content: space-between;
  background: #0078BF;
  border-radius: 28px;
  width: 150px;
  padding: 10px 20px;
}
.admin-page .chat-img img{
  width: 20px;
}
.admin-page .chat p{
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}
.admin-page .chat-number {
  background: #fff;
  width: 30px;
  height: 18px;
  border-radius: 28px;
  text-align: center;
  /* position: absolute; */
  margin-top: 4px;
}
.admin-page .chat-number p{
  color: #0078BF;
  font-size: 16px;
  line-height: 1.2;
}


/*learnig2 style 2nd page*/


/*learning2 search*/

.admin-page .ar-input1-learn2 input[type="search"]{
  width: 250px;
}
.admin-page .ar-input2-learn2 input[type="search"]{
  width: 100px;
}
.admin-page .ar-input4-learn2 input[type="search"]{
  width: 100px;
}
.admin-page .ar-input5-learn2 input[type="search"]{
  width: 120px;
}
.admin-page .ar-input6-learn2 input[type="search"]{
  width: 120px;
}
.admin-page .ar-input7-learn2 input[type="search"]{
  width: 150px;
}

.admin-page .ar-single-search-learn2:nth-last-child(2){
  margin-right: 40px;
}
.admin-page .ar-single-search-learn2:nth-last-child(3){
  margin-right: 20px;
}

.admin-page .ar-single-search-learn2:nth-last-child(4){
  margin-right: 40px;
}
.admin-page .ar-single-search-learn2:nth-last-child(5){
  margin-right: 20px;
}


.admin-page .ar-menu-drop{
  margin: 0px !important;
  font-weight: 300 !important;
}


/*ar-student-cll-biodata-section-area*/
.admin-page .ar-single-student-bio{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  padding: 15px 0px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px #E4E6EF;
}

.admin-page .ar-student-quality{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 0px 20px;
}
.admin-page .ar-name-work{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-page .ar-dent-name p{
  color: #3F4254;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Avenir';
  padding-bottom: 5px;
  line-height: 14px;
}
.admin-page .ar-dent-name span{
  color: #3F4254;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Avenir';
  padding-top: 5px;
  display: block;
  word-break: break-all;
}
.admin-page .ar-quality-of-work p{
  color: #3F4254;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Avenir';
}

.admin-page .ar-grade-activity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding-left: 70px;
  padding-right: 40px;
}
.admin-page .ar-grade p,
.admin-page .ar-activity p{
  color: #3F4254;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Avenir';
}


.admin-page .ar-section-time-action-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 380px;
  justify-content: space-between;
}
.admin-page .ar-section-time-action-time p{
  color: #3F4254;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Avenir';
  margin-bottom: -10px;
}
.admin-page .ar-section-time {
  padding-left: 45px;
}
.admin-page .ar-activity {
  padding-right: 10px;
}
.admin-page .ar-am-dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
  width: 215px;
  padding-left: 25px;
}
.admin-page .ar-action-time p{
  color: #3F4254;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Avenir';
}
.admin-page .ar-profile{
  position: relative;
}
.admin-page .ar-profile-button {
  position: absolute;
  left: 58px;
  top: 53px;
}
.admin-page .ar-alert-box p{
  font-weight: 300;
}
.admin-page .mu_dropdown_menu_content.ar-drop-menu-cont {
  font-weight: 400;
}





/*tabs-menu-codepen*/


.admin-page .pagination-tabs ul{
  margin: 0; 
}
.admin-page .pagination-tabs ul li{
 display: inline-block;
}
.admin-page .pagination-tabs ul li a{
 display: block;
 text-decoration: none;
 color: #fff;
 padding: 8px 15px;
 border-radius: 5px 5px 0 0;
 -webkit-border-radius: 5px 5px 0 0;
 background: #2da4ac;
 font-weight: bold;
}
.admin-page .pagination-tabs ul li a:hover, .pagination-tabs ul li a.current-tab{
 background: #74d4f8; 
}
.admin-page .pagination-contents{
 padding: 20px;
 border: 2px solid #2da4ac;
 border-radius: 10px;
}
.admin-page .pagecont{
 font-weight: bold;
 display: none;
}
.admin-page .current{
 display: block;
}
ul{
 /*padding-left: 25px;*/
}
.aligncenter{
 text-align: center;
}
.blue{
 color: #006cc5;
}
.top{
 margin: 50px auto 25px;
}

.overflowhidden{
 overflow: hidden;
}
.admin-page .hover:hover{
 cursor: pointer;
 background: #74D4F8;
 color: #fff;
 transition: 1s ease;
 padding: 8px;
 font-size: 30px;
}
.admin-page .hover{
 display:inline-block;
}
.admin-page .animation{
 display:inline-block;
 animation: animate 10s infinite;
 -webkit-animation: animate 10s infinite;
 -moz-animation: animate 10s infinite;
 left: -100%;
 position: relative;
}
@keyframes animate{
 0%{
   left: 0%;
 }
 100%{
   left: 100%;
 }
}
.admin-page .effects{
 width: 180px;
 height: 180px;
 animation: bg 5s infinite;
 background: red;
 color: #fff;
 padding: 10px;
}
@keyframes bg{
 0%{
   background: green;
   color: orange;
 }
 30%{
   background: blue;
 }
 50%{
   background: skyblue;
 }
 80%{
   background: yellow;
   color: #000;
 }
 100%{
   background: orange;
 }
}






.admin-page .pagination-contents {
 padding: 0px; 
 border:none; 
 border-radius: 0px; 
}


.admin-page .pagination-tabs {
  text-align: right;
  margin: 30px 0px 50px 0px;
  display: flex;
  justify-content: flex-end;
}
.admin-page .pagination-tabs ul li a {
 font-weight: 400;
 font-size: 14px;
 background: transparent;
 color: #3E3F43;
 padding: 5px 12px;
 border-radius: 4px;
 transition: .1s;
 margin: 0 3px;
}
.admin-page .pagination-tabs ul li a{
  padding-right: none;
  margin-right: none;
}
/*.pagination-tabs ul li a:hover:last-child{
  background: none;
  }*/
  .admin-page .pagination-tabs ul li a:hover, .admin-page .pagination-tabs ul li a.tab-last-child.current-tab {
    background: transparent;
  }
  .admin-page .pagination-tabs ul li a:hover, .admin-page .pagination-tabs ul li a.current-tab {
   background: #0078BF;
   color: #FFF;
   padding: 5px 12px;
   border-radius: 4px;
 }
 .admin-page .pagination-tabs ul {
  display: flex;
  align-items: center;
  justify-content: end;
}
.admin-page .pagination-tabs ul li a img {
  width: 8px;
  padding-bottom: 4px;
}
.admin-page .tab-last-child:hover{
  background: transparent !important;
}










/* ====================================== abdur rahim GradeBook page Area start 2nd work =============================================== */

/* start gradebook popup css */

.admin-page .ar-gradebook-full-content {
  position: relative;
}
.admin-page #first-popup-content,.admin-page #second-popup-content,.admin-page #third-popup-content,.admin-page #fourth-popup-content,.admin-page #fifth-popup-content,.admin-page #six-popup-content,.admin-page #seven-popup-content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: #FFFFFF;
  display: none;
}





/* end gradebook popup css */








.admin-page .ra_gradebook_header{
  padding:0 0 10px 0;
}
.admin-page .ra_gradebook_header .home-text-area {
  margin-bottom: 25px;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search {
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search_width {
  width: 100% !important;
  max-width: 100%;
  display:flex;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search_width .down-icon-two img {
    width: 12px;
    height: 8px;
    right: -22px;
    top: 25px;
    position: absolute;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search_width .profile-text h5 {
    color: #05233b;
    text-transform: capitalize;
}
.admin-page .ra_gradebook_header .dropdown_search_flex {
  padding: 0px 20px;
  margin-top: 13px;
  border-left: 1px solid #E4E6EF;
  border-top: 1px solid #E4E6EF;
  border-right: 1px solid #E4E6EF;
}
.admin-page .ra_gradebook_header .mu_dropdown_menu_content ul li a, .admin-page .mu_dropdown_menu_content ul li button.menu-drop {
 margin: 0px 0; 
}
.admin-page .ra_gradebook_header .mu_view_icons ul li a{
  color: #0078BF;
}
.admin-page .ra_gradebook_header .mu_view_icons ul li a img {
  color: #0078BF;
  width: 11px;
  height: auto;
  object-fit: cover;
  margin-right: 5px;
}
/* Sort able area start header */
.ra_gradebook_shortable {

}
.admin-page .ra_gradebook_shortable_header {
  display: flex;
  flex-wrap: wrap;
}
/* Left short abale area start  */
.admin-page .ra_gradebook_shortable_header_left{
  flex: 0 0 30%;
}
.admin-page .ra_gradebook_shortable_header_left .ar-grade2-student-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 20px;
  background-color: #E4E6EF;
}
.admin-page .ra_gradebook_shortable_header_left .li_arrow_sort button:nth-child(2) {
  display: block;
  margin-top: -8px;
}
.admin-page .ra_gradebook_shortable_header_left .ar-grde-dive:first-child {
  display: flex;
  align-items: center;
}
.admin-page .ar-grade-header-unit-left-text h5 {
  color: #05233B;
  font-weight: 300;
  font-size: 20px;
  padding: 15px 0;
  font-size: 20px;
}
.admin-page .ar-grade2-student-unit{
  display: flex;
}
/* Left short abale area end  */
/* Header Right sections area start  */
.admin-page .ra_gradebook_shortable_header_right {
  display: flex;
}
.admin-page .ra_gradebook_shortable_header_right .single_sortable_design {
  display: flex;
  align-items: start;
}
.admin-page .ra_gradebook_shortable_header_right_single_item {
  background: #DBE6EE;
  padding: 13.7px 10px;
  border-left: 1px solid #fff;
  min-height:115px;
}

.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive {
  display: flex;
  align-items: center;
}
.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive {
  width: 13%;
}
.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive:last-child{
  width: 9%;
}
.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive p {
  text-align: center;
}
.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive p span {
  color: #3F4254;
  background-color: #B0D0E8;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 18px;
  border-radius: 4px;
  display: block;
  margin-top: 3px;
}
.admin-page .ra_gradebook_shortable_header_right_box span img {
  width: 20px;
  height: auto;
  object-fit: cover;
  float: right;
}
.admin-page .ra_gradebook_shortable_header_right .ar-grde-dive:last-child {
  background-color: #fff;
}
.admin-page .ra_gradebook_extra{
  background-color: #FAFBFC;
  height: 102px;
}
.admin-page .ra_gradebook_shortable_header_right .single_sortable_design h5 {
  font-size: 10px;
  font-weight: 400;
  color: #3F4254;
  padding-right: 14px;
}

.admin-page .ra_gradebook_shortable_header_right_single_item.ra_gradebook_extra .single_sortable_design{
  text-align: center;
}
.admin-page .ra_gradebook_shortable_header_right_single_item.ra_gradebook_extra .single_sortable_design img {
  width: 10px;
  margin: auto;
}
.admin-page .ra_gradebook_shortable_header_right_single_item.ra_gradebook_extra p{
  font-size: 12px;
  cursor: pointer;
}
.admin-page .ar-gradehed-stuovr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #E4E6EF;
  padding: 5px 20px;
}
.admin-page .ar-header-unitsection-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.admin-page .ar-grade-header-student{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.admin-page .ar-grade-section-unit{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 70%;
}
.ar-grade-single-unit1,
.ar-grade-single-unit2,
.ar-grade-single-unit3,
.ar-grade-single-unit4,
.ar-grade-single-unit5,
.ar-grade-single-unit6,
.ar-grade-single-unit7,
.ar-grade-single-unit8{
  text-align: center;
  width: 100px;
}
.admin-page .ar-grade-single-unit8{
  width: 77px;
}
.admin-page .ar-grade-unit-btn button{
  background: #B0D0E8;
  font-size: 11px;
  padding: 2px 18px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  color: #3F4254;
}
.admin-page .ar-grade-single-unit8{
  height: 111px !important;
}
.admin-page .ra_gradebook_shortable_header_right_single_item.ra_gradebook_extra.ar-short-unit36 {
  height: 132px;
}
.admin-page .dc_dots_area.ar-grade-dots36 {
  padding-top: 20px;
}
.admin-page .ar-grade-single-unit8.ar-grade2-unit8 {
  height: 132px !important;
}
    /*.single_sortable_design.ar-grade2-arrow {
      padding-top: 10px;
      }*/
     .admin-page .ar-grad-text.ar-grade2-text {
        padding-top: 13px;
      }

      .admin-page .ar-grade2-downfile{
        position: relative;
      }
      .admin-page .ar-gradebook2-arrow {
        position: absolute !important;
        top: 20px;
        right: 12px;
      }
      .admin-page .ar-gradebook-li-filter {
        font-weight: 300;
        color: #181C32 !important;
      }
      .admin-page .ar-gradebook-li-filterli{
        font-weight: 300;
        color: #181C32 !important;
        font-size: 14px !important;
      }
     .admin-page .ar-grade1-extra{
        height: 113px;
      }

      /* =========================    Body area start ==================== */
      .admin-page .ar-book-percent.parcent8.ar-grade2parcent8 {
        padding: 45px 0px !important;
      }
      .admin-page .ra_gradebook_shortable_body {
        display: flex;
        align-items: center;
      }
      .admin-page .ra_gradebook_shortable_body_single_item_area1 {
        flex: 0 0 30%;
      }
      .admin-page .ra_gradebook_shortable_body_single_item_area_sub1 ul {
        display: flex;
        align-items: center;
      }
     .admin-page .ra_gradebook_shortable_body_single_item_area2 {
        flex: 0 0 70%;
      }

      .admin-page .ar-book img{

        top: 5px !important;
      }
      .admin-page .ar-book-ARROW{
       right: -10px !important;
       top: 5px;
     }
     .admin-page .ar-grad-text {
      padding-top: 10px;
    }
   .admin-page .ar-grade1text{
     padding-top: 3px;
   }
   .admin-page .ar-grade-single-unit8 .dc_dots_area .down-icon img {
    right: -10px;
    top: -10px;
  }
 .admin-page .ra_gradebook_shortable_header_left_text h5 {
    padding-top: 37px;
    color: #05233B;
    font-weight: 400;
    font-size: 20px;
  }
  .admin-page .ar-grade-single-unit8 .dc_dots_area .ar-grade-more-arrow img {
    top: -15px;
  }

  /* =========================    gradebody book start ==================== */

  .admin-page .ar-gradebook-flex-body{
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
    background: #fff;
    box-shadow: 0px 0px 10px #E4E6EF;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  .admin-page .body-all-1st-child{
    margin-top: 1px;
  }
  .admin-page .ar-book-imagename-check{
    display: flex;
    align-items: center;
    width: 225px;
  }
  .admin-page .ar-book-img-name p{
    font-weight: 300;
  }
  .admin-page .ar-book-percent{
    width: 100px;
    font-size: 14px;
    color: #3F4254;
    font-weight: 300;
    text-align: center;
    border-right: 1px solid #E4E6EF;
    padding: 35px 0px;
  }
 .admin-page .parcent1 {
    background: #EAEDF3;
    width: 90px;
    text-align: center;
  }
  .admin-page .sameparcent3{
    background: #F3F6F9;
  }
 .admin-page .ar-book-percent.parcent4 {
    width: 100px;
  }
 .admin-page .ar-book-percent.parcent5 {
    width: 100px;
  }
 .admin-page .ar-book-percent.parcent6 {
    width: 100px;
  }
  .admin-page .ar-book-percent.parcent7{
    width: 100px;
  }
 .admin-page .ar-book-percent.parcent8{
    width: 98px;
    padding: 25px 0px;
  }
 .admin-page .ar-gradebook img{
    right: -44px !important;
  }
 .admin-page .back-color-def{
    background: #F4DBCB;
  }
 .admin-page .parcent5 p{
    z-index: 11111;
  }



  /*gradebook2*/

 .admin-page .ar-same5-color-unit{
    background: #D3D6E3;
  }
 .admin-page .ar-same2-color-unit{
    background: #D6BFCF;
  }
 .admin-page .ar-unit-seven-reading h5 {
    padding-right: 0px !important;
    text-align: left;
    margin-right: -20px !important;
  }
  .admin-page .ar-grade2-assignment {
    border-radius: 3px;
    display: flex;
    justify-content: center;
  }
 .admin-page .ar-grade2-assignment button {
    color: #fff !important;
    padding: 2px 15px;
    font-weight: 400 !important;
    background: #72778D;
    display: inline;
    border-radius: 3px;
    font-size: 11px;
  }
  .admin-page .ra_gradebook_shortable_header_right_single_item.ar-same5-color-unit.position-assign.ar-short-unit36 {
    height: 131px;
  }
  .admin-page .ar-grade-unit-date {
    padding-top: 5px;
  }
  .admin-page .ar-unit-seven-dots button img{

  }
 .admin-page .ra_gradebook_shortable_header_right_single_item p{
    color: #3F4254;
    font-size: 10px;
    font-weight: 300;
  }
 .admin-page .ar-gradebook-assignment{
   background: #D3D6E3 !important;
 }
 .admin-page .ar-gradebook-assignment-quiz{
  background: #D6BFCF !important;
}
.admin-page .ar-gradebook-assignment p span{
  padding: 3px 10px !important;
  background: #72778D !important;
  color: #FFFFFF !important;

}
.admin-page .grader-book-time{
  padding-top: 10px;
}
.admin-page .ar-arrow-grade{
  margin-left: 0px;
}
.admin-page .ar-arrow-grade button img {
  max-width: 15px;
}
.admin-page .ar-grade-header-samewidth {
  height: 139px !important;
}
.admin-page .ar-gradebook2-dots-same img{
  top: 20px !important;
}
.admin-page .dc_dots_area.ar-gradebook2-dots-same {
  padding-top: 10px;
}
.admin-page .down-icon.ar-book-ARROW.arrow-more-dots {
  padding-top: 12px;
}

.admin-page .ar-gradebook-filter-wrapper{
  flex-wrap: wrap;
}
.admin-page .ar-grade-filter-reset{
  display: block !important;
}
.parcent8{}
.parcent8 a img{
  width: 22px;
}
.admin-page .ar-gradebook-attachfile p {
  text-decoration: underline;
  color: #0078BF;
  padding-bottom: 10px;
  cursor: pointer;
  padding-top: 5px;
}
.admin-page .attachfile-menu-dropdown {
  background: #FFF;
  box-shadow: 0px 0px 10px #E4E6EF;
  width: 260px;
  padding: 20px;
  border: 1px solid #E4E6EF;
  text-align: left;
}
.admin-page .dc_dots_area .attacjfile-dropdownfile {
  left: -190px !important;
}
.admin-page .ar-att-file-down{
  padding: 10px 30px !important;
}

.parcent8 {
  position: relative;
  padding: 35px 0px !important;
}
.gradebook-parcent-attachifile {
  position: absolute;
  left: 0;
  right: 0;
  bottom:0px;
}
.attachfile-menu-dropdown {
  position: absolute;
  z-index: 9999;
  right: 0;
  top: -45px;
  background: #FFF;
  box-shadow: 0px 0px 10px #E4E6EF;
  width: 260px;
  padding: 20px;
  border: 1px solid #E4E6EF;
  text-align: left;
  display: none;
}



.ar-learn-dots-area{
  z-index: auto !important;
}

/*pagination-footer*/
.ar-pagenation-area-footer{
  padding-bottom: 30px;
}
.ar-pagenation-area-footer.new{
  padding-bottom: 270px;
}
.ar-pagination-footer-next{
  align-items: flex-start;
}
.ar-pagination-footer-next-left-icon{
  padding-right: 20px;
}
.ar-pagination-footer-next-right-icon{
  padding-left: 10px;
}

/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/
/*============================================================================ --*/


/*ar start */
.admin-page .ar-home-head-seaech-area {
  background: #fff;
  display: flex ;
  justify-content: space-between ;
  flex-wrap: wrap;
  align-items: center ;
  padding: 10px;
}
.admin-page .ar-search-arrow-header a {
  color:#181C32;
  font-size: 14px;
  font-weight: 300;
  margin: 0px 5px ;
}
.admin-page .ar-search-arrow-header a i {
  color: #9A9A9A;
  font-size: 16px;
  width: 35px;
  height: 35px;
  border: 1px solid #EAEDF3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-page .ar-header-left-section{
  padding-bottom: 15px;
}
.admin-page .single-myclass-right-content .ar-home-man-count{
  background: #DBE6EE;
  padding: 1px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #000;
  border-radius: 12px;
  margin-left: 6px;
}
.admin-page .ar-room-content{
  position: relative;
}
/*.myclassroom-single-right-left-content{
  position: absolute;
}*/
/*.ar-clsssroom-right-content{
  width: 100%;
}*/
.admin-page .ar-single-content-left{
  flex:0 0 80%;
}
.admin-page .ar-clsssroom-right-content {
  flex: 0 0 20%;
  padding-right: 50px;
}
.admin-page .myclassroom-single-left-content.ar-myclassroom-single-left {
  flex: 0 0 72%;
}.admin-page .ar-classroom-name-stu{
  width: 100%!important;
}
.admin-page .ar-single-myclass-main{
  flex-wrap: wrap !important;
}
.admin-page .ar-progress-myclass{
  justify-content: flex-start;
}

.admin-page .ar-myclass-count123 {
  margin-left: -175px;
  padding-top: 50px;
}
.admin-page .ar-home-man-count{
  padding: 0px 8px !important;
}
.ar-home-black{
   padding: 0px 8px !important;
}




/* table design area start */

.admin-page .ra_top_event_calender_area ul li {
  color: #181C32;
  padding: 9px 17px;
  font-size: 12px;
  font-weight: 300;
}
.admin-page .ra_top_event_calender_area ul li:nth-child(1){
  background-color: #E4E6EF;
  font-size: 14px;
}
.admin-page .ra_top_event_calender_area ul li:nth-child(2){
  background-color: #CAE2F1;
  border-left: 5px solid #1B6A9A;
}
.admin-page .ra_top_event_calender_area ul li:nth-child(3){
  background-color: #ADDEDC;
  border-left: 5px solid #1B6A9A;
}
.admin-page .ra_top_event_calender_area ul li:nth-child(4){
  background-color: #EBECF3;
  border-left: 5px solid #C8CBDE;
}
.admin-page .ra_bottom_event_calender_area {
  padding-top: 10px;
  background: #fff;
}
.admin-page .ra_bottom_event_calender_area > ul > li{
  padding: 7px 17px;
  color: #181C32;
  position: relative;
  font-size: 12px;
  font-weight: 300;
}
.ra_bottom_event_calender_area > ul > li:nth-child(1)::after,
.ra_bottom_event_calender_area > ul > li:nth-child(2)::after,
.ra_bottom_event_calender_area > ul > li:nth-child(5)::after,
.ra_bottom_event_calender_area > ul > li:nth-child(8)::after,
.ra_bottom_event_calender_area > ul > li:nth-child(9)::after,
.ra_bottom_event_calender_area > ul > li:nth-child(10)::after{
  position: absolute;
  content:"";
  width: 90%;
  height: 1.5px;
  height: 1px;
  background: #EAEDF3;
  right: 0;
  top: 50%;
}
.admin-page .ra_bottom_event_calender_area > ul > li a {
  color: #0078BF;
  font-size: 13px;
  display:block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.admin-page .ra_event_position_area{
  position: relative;
}
.admin-page .ra_event_position_area1{
  position: relative;
}
.admin-page .ra_event_position_area ul {
  position: absolute;
  width: 90%;
  height: ;
  right: 0;
  background: #DBE6EE;
  top: 0;
  padding: 3px 18px;
  border-left: 5px solid #A4BBCC;
}

.admin-page .ra_event_position_area1 ul{
  position: absolute;
  width: 90%;
  padding: 3px 18px;
  right: 0;
  top: 0;
  background: #EBECF3;
  border-left: 5px solid #C8CBDE;
}
/* table design area End */
/* Event calender style start */
.admin-page .ra_even_calender_main_area_start_sub {
  display: flex;
  align-items: center;
}
.admin-page .ra_event_btn_style ul li a {
  border-radius: 28px;
  border: 1px solid #0078BF;
  text-transform: capitalize;
  font-size: 14px;
  color: #0078BF;
  margin-left: 27px;
  font-size: 14px;
  transition: .3s;
  padding: 7px 36px;
  border-radius: 20px;

}
.admin-page .ra_event_btn_style ul li a:hover {
  background: #0078BF;
  color: #fff;
}

.admin-page .ar-ra-search-bar {
  max-width: 500px !important;
  width: 100% !important;
}
/* Event calender style start */

.admin-page .ar-ra-search-bar input[type="search"] {
  width: 405px !important;
}
.admin-page .myclassroom-content .ar-section21{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F3F6F9;
  padding-bottom: 20px;
}

.admin-page .myclassroom-header-left.myclassroom-content.ar-section21222 {
  box-shadow: none;
  margin-top: 0px;
}
.admin-page .today-week-button{
  display: flex;
  align-items: center;
}
.admin-page .today-week-button a {
  width: 80px;
  height: 35px;
  border-radius: 28px;
  border: 1px solid #0078BF;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  color: #0078BF;
  margin-right: 15px;
  padding-left: 17px;
  transition: .3s;
}
.admin-page .today-week-button a:hover{
  background:  #0078BF;
  color: #FFF;
}
.admin-page .ar-ra-area-section{
  margin: 20px 0px;
}
.admin-page .rakib-area-section{
  margin-bottom: 50px;
}
.admin-page .myclassroom-single-left-content.ar-myclassroom-single-left:hover{
  flex: 0 0 72%;
  background: transparent
}
.admin-page .ar-content-hover {
  background: transparent !important;
}
.admin-page .ar-button-border{
  border: 1px solid #ddd;
}
.admin-page .ar-mycladd-arrr{
   border: 1px solid #ddd;
}
.admin-page .myclassroom-single-left-new-button.add-new button {
  right: 10px;
  bottom: -4px;
}
.admin-page .top-progress-area .single-top-progress-area.add-new:nth-child(2) {
  padding-bottom: 12px;
}
.admin-page .course-progress-today.new .barra-nivel {
  background: #B2D8EF;
}
.admin-page .course-progress-img-single-contents.add-new p {
  color: #FFFFFF;
}
.admin-page .see-all-class-button.add-new a,.view-all-message.add-new a {
  text-align: left;
}
.admin-page .myclassroom-content.ar-single-myclass-main {
  box-shadow: 0 0 4px rgba(0,0,0,.1);
  margin-top: 4px;
}
.admin-page .mu_dropdown_menu_content ul li button.menu-drop.add-new {
  padding: 12px 30px 12px 10px;
}
.admin-page .dropdown-menu.new-menu.show.ra_custom_checkbox_area_up.new {
  top: -6px !important;
}
.admin-page .down-icon-three.s-new-add img {
  bottom: 19px;
}
.admin-page .inbox-messages-area.add-new {
  padding: 13px 20px;
}
.admin-page .rakib-area-section.add-new {
  margin-top: 25px;
}
.admin-page .myclassroom-single-left-cross-progress.add-new-class {
  width: 150px;
}
.admin-page .second-home-page-content-third,.first-sections-page-content-third,.first-schedule-page-content-third,.first-students-page-content-third,.first-gradebook-page-content-third,.first-attendance-page-content-third,.first-resources-page-content-third,.first-inbox-page-content-third {
  display: none;
} 
.admin-page .first-home-page-content-third.hide-home,.second-sections-page-content-third.hide-sections,.admin-page .second-schedule-page-content-third.hide-schedule,.second-students-page-content-third.hide-students,.second-gradebook-page-content-third.hide-gradebook,.second-attendance-page-content-third.hide-attendance,.second-resources-page-content-third.hide-resources,.second-inbox-page-content-third.hide-inbox {
  display: none;
}
.admin-page .second-home-page-content-third.show-home,.admin-page .first-sections-page-content-third.show-sections,.admin-page .first-schedule-page-content-third.show-schedule,.first-students-page-content-third.show-students,.first-gradebook-page-content-third.show-gradebook,.first-attendance-page-content-third.show-attendance,.first-resources-page-content-third.show-resources,.first-inbox-page-content-third.show-inbox  {
  display: block;
}

   /* ====================================================================================================================================================
    ===========================                             *** END THIRD NEW PROJECT PAGES CSS ***                             ========================
    ==================================================================================================================================================== */
.progress-couse-teacher{
	height:7px!important;
}
.admin-page .progress-couse-teacher .barra-nivel{
	border-radius:0;
}
.box_co1 {
   height: 12px;
    width: 12px;
    background: #b2d8ef;
    border-radius: 3px;
    margin-right: 8px;
}
.box_co5 {
    height: 12px;
    width: 12px;
    background: #ffb64d;
    border-radius: 3px;
    margin-right: 8px;
}
.box_co2 {
    height: 12px;
    width: 12px;
    background: #05233b;
    border-radius: 3px;
    margin-right: 8px;
}
.box_co3 {
    height: 12px;
    width: 12px;
    background: #00a2a7;
    border-radius: 3px;
    margin-right: 8px;
}
.box_co4 {
    height: 12px;
    width: 12px;
    background: #28a745;
    border-radius: 3px;
    margin-right: 8px;
}
.bg-color1 {
    background-color: #b2d8ef!important;
}
.bg-color2 {
    background-color: #05233b!important;
}
.bg-color3 {
    background-color: #00a2a7!important;
}
.bg-color4 {
    background-color: #28a745!important;
}
.bg-color5 {
    background-color: #ffb64d!important;
}
.scrollere-cos {
    min-height: 40px!important;
    min-height: 30px!important;
    height: inherit!important;
    max-height: 50px!important;
    max-height: 35px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	
}
.scrollere-cos::-webkit-scrollbar {
    width: 2px;
    background-color: #145b94;
    border-radius: 10px;
}
.scrollere-cos::-webkit-scrollbar-thumb {
    background-color: #145b94;
   /*background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));*/
    border-radius: 10px;
	
}
.scrollere-cos::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #145b94;
	margin: 0 30px;
}
.admin-page #home .course-progress-img-text-contents {
   /* padding-top: 7px;*/
   margin-top:7px;
}
.admin-page .mu_search_bar button img {
    margin: 7px 0 0 40px;
}
.admin-page #home .mu_search_bar_reply .mu_dropdown {
    bottom: -134px!important;
}
.scrollere-sec {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 554px!important;
    max-height: 440px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
    margin-top: 0px;
}
.scrollere-sec::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.scrollere-sec::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.scrollere-sec::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
.admin-page #home .mu_search_bar form {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
}
.ar-search-arrow-header .pagination{
	align-items: center;
}
.ar-search-arrow-header .pagination .previous a ,.ar-search-arrow-header .pagination .next a {
	border: 1px solid #ddd!important;
    background-color: unset!important;
    vertical-align: middle;
    text-align: center;
	padding:15px;
    border-radius: 0;
    background-size: 8px 13px;
    background-attachment: inherit;
    background-position: center;
}
.ar-search-arrow-header .break-me {
    display: none;
}
.ar-search-arrow-header .pagination>.active>a,.ar-search-arrow-header .pagination>.active>a:focus,.ar-search-arrow-header .pagination>.active>a:hover,.ar-search-arrow-header .pagination>.active>span,.ar-search-arrow-header .pagination>.active>span:focus,.ar-search-arrow-header .pagination>.active>span:hover {
    z-index: 3;
    cursor: default;
    background-color: #efe;
    border-color: #ffffff;
    background: #ffffff;
    color: #05233b!important;
    padding: 5px;
    border-radius: 4px;
}
.ar-search-arrow-header .previous,.ar-search-arrow-header .next {
    margin-right: 0px!important;
}
.admin-page .today-week-button a.btn_style_4 {
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078bf;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

.admin-page .today-week-button a.btn_style_4 {
    color: #fff;
    background: #0078bf;
}
.admin-page .today-week-button a.btn_style_4 {
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    background: #0078BF;
    transition: 0.3s ease-in-out;
}
.admin-page .today-week-button a {
    text-align: center!important;
    /* color: #FFF; */
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    /* background: #0078BF; */
    transition: 0.3s ease-in-out;
    color: #0078BF;
}
.mbsc-ios.mbsc-schedule-all-day-text {
    text-align: center!important;
}
#sections .header-profile-dropdown.add-new-class{
	display:flex;
}
.admin-page .course-progress-img-text-contents {
    padding-top: 0px; 
    margin-top: 16px;
}
#sections .myclassroom-single-right-right-content .dropdown #launchcourse{
	padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
#sections .di-rig{
	flex: 0 0 44%;
    display: flex;
    justify-content: flex-end;
}
.admin-page #sections .ar-gradebook-filter-dropdown #dropdownMenuButton {
    padding: 18px 0;
}
.admin-page #sections .down-icon-three img {
    width: 12px !important;
    height: 8px !important;
    right: 10px;
    bottom: 24px!important;
    position: absolute;
}
.admin-page #sections .ar-filter-area #dropdownMenuButton {
    padding: 18px 0;
}
#paging .pagination>.active>a, #paging .pagination>.active>a:focus, #paging .pagination>.active>a:hover, #paging .pagination>.active>span, #paging .pagination>.active>span:focus, #paging .pagination>.active>span:hover {
    z-index: 3;
    cursor: default;
    background-color: #efe;
    border-color: #addead;
    background: #0078bf;
    color: #fff!important;
    padding: 5px 12px;
    border-radius: 4px;
}
.admin-page .dc_dots_area.ar-learn-dots-area .dropdown .btn {
    padding: .380rem 0;
}
.div-act{
	text-align: right;
    padding: 15px 15px 0;
	display: flex;
    align-items: center;
    justify-content: flex-end;
}
.menu-school {
	min-width: 210px;
	min-width: 270px;
    width: auto;
}
.sec-st-img .sb-avatar__text{
	border-radius:5px;
}
.admin-page .stu-dept .student-sec {
    width: 65%;
}
.admin-page .course-sec{
	width: auto;
    display: inline-block;
}
.admin-page .department-name {
    background: #E4E6EF;
    border-radius: 5px;
    margin-right: 30px;
    margin-right: 0;
    width: auto;
    display: inline-block;
    margin-left: 13px;
	max-width: 126px;
}
.admin-page .student-sec {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
}
.admin-page .stu-dept {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
}
.admin-page .staff-sec {
    padding-top: 0px;
    padding-left: 13px;
    width: 50%;
}
.admin-page .ar-launch a.sec-lurch {
    text-transform: capitalize;
    padding: 4px 12px !important;
}
.ct-sli-re{
	min-height: 60px!important;
    height: inherit!important;
    max-height: 100px!important;
    max-height: 35px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.ct-sli-re::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.ct-sli-re::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.ct-sli-re::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
#sections .sv-av .sb-avatar {
    margin-right: 10px;
}
#sections .list_document_items_area .right_dc_date_time {
    -webkit-flex: 0 0 15%;
    flex: 0 0 15%;
	justify-content: flex-end;
}
#sections .list_document_items_area .dc_single_items .dc_describ_txt_area {
    width: 50%;
}
.max210{
	min-width:210px!important;
}
.max210{
	min-height: 100px!important;
    height: inherit!important;
    max-height: 200px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.max210::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.max210::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.max210::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
.student-sec{
	cursor:pointer;
}
.admin-page #grade-st .ra_courch_single_item_left.learn-course {
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin-page #student #dropdownMenuButton {
    padding: 18px 0;
}
.admin-page #student .down-icon-three img {
    width: 12px !important;
    height: 8px !important;
    right: 10px;
    bottom: 24px!important;
    position: absolute;
}
.admin-page .dc_dots_area .dropdown-menu.menu-vi-mo{
	left:-60px!important;
}
.admin-page #student .mu_search_bar a {
    font-size: 14px;
    position: relative;
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
.admin-page #student .select-status{
	background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 8px 0px 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width:90px;
}
.admin-page #sections .select-dep{
	background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 8px 0px 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width:130px;
}
.admin-page #student .myclassroom-single-left-content-course-progress {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
.admin-page #student .student-course-progress-contents {
    -webkit-flex: 0 0 18%;
    flex: 0 0 20%;
}
.admin-page #student .profile-section, .admin-page #rosters .profile-section{
	cursor:pointer;
}
.admin-page #student .myclassroom-single-right-right-content .dropdown button {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page .student-information-heading-profile .sb-avatar__text{
	border:2px solid #fff;
}
.admin-page .student-course-progress-area .ra_sortable_area_menu_ex_area{
	    flex: 0 0 55%;
	    flex: 0 0 43%;
}
.admin-page .student-course-progress-area .ra_sortable_header_area_menu ul li {
    color: #3F4254;
    font-size: 12px;
    font-family: Source Sans Pro;
    font-weight: 300;
    padding: 2px 40px 2px 8px;
    padding: 2px 8px;
    margin-left: 8px;
}
.admin-page .student-course-progress-area .dc_dots_area .dropdown-menu {
    min-width: 150px;
}
.admin-page .student-course-progress-area .down-icon-four-update {
    margin-right: 70px;
}
.admin-page .jannle-marshall{
	width:auto;
}
.admin-page .student-course-progress-area .jannle-marshall {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
}
.scho-op{
	display: block;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
    background: #F3F6F9;
    padding-bottom: 20px;
    min-width: 75px;

}
.admin-page  #schedule .sb-avatar__text{
	border-radius:5px;
}
.maxH475{
	max-height:475px!important;
}
.admin-page #resource .dc_single_items .dc_describ_txt_area {
    width: auto;
}
.admin-page #resource #dropdownMenuButton {
    padding: 18px 0;
}
.admin-page #resource .down-icon-three img {
    width: 12px!important;
    height: 8px!important;
    right: 10px;
    bottom: 24px!important;
    position: absolute;
}
.admin-page #gradebook #dropdownMenuButton {
    padding: 18px 0;
}
.admin-page #gradebook .dc_dots_area .dropdown-menu {
    min-width: 190px;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #E4E6EF;
    padding: 5px 0 !important;
    left: -100px !important;
    top: 10px !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
}
.admin-page #gradebook .dc_dots_area .down-icon img{
	top:5px;
}
.admin-page #gradebook .dc_dots_area.final .down-icon img{
	top:-10px;
}
.admin-page .help-contetn img {
    width: 80px;
    height: auto;
    margin-top: 0px;
}
 .admin-page .st_another_single_deffrent {
    display: block;
}
.admin-page .student-information-heading{
	min-height:auto;
}
.admin-page .left_infor_area .infor_profile img.ava{
	border: 2px solid #0078bf;
	width:60px;
	height:60px;
}
#sections .di-rig {
    -webkit-flex: 0 0 44%;
    flex: 0 0 44%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    flex: auto;
}
.admin-page .seach-student{
	display: flex;
    justify-content: flex-end;
}
.admin-page #student .student-listing-single-content{
	flex: 0 0 33%;
}
.admin-page #student .student-grade-lavel-single-content {
    flex: 0 0 10%;
    text-align: left;
	padding-left:15px;
}
.mu_header_next_btn .pagination li {
    margin-left: 2px!important;
    margin-right: 2px!important;
}
.admin-page .custom_checkbox_area label span {
    font-size: 14px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.admin-page .myclassroom-single-right-right-content button.start-class {
    font-weight: 400;
    font-size: 12px;
    color: #0078BF;
    border: 1px solid #0078BF;
    padding: 4px 24px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
	margin-top:5px;
}
.admin-page .btn-join-fire{
	float:right;
	position:absolute;
	right:100px;
	color:#fff!important;
	background: #c55a11;
    border-color: #c55a11;
}
.admin-page .btn-join-fire:hover{
	color:#c55a11!important;
}
.admin-page #sections .student-img {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
	padding-right: 5px;
    padding-left: 8px;
	
}
.admin-page #sections .ar-single-search {
    margin-right: 15px;
}
.admin-page #sections .search5 ,.admin-page #sections .search6 ,.admin-page #sections .search7 {
    margin-right: 10px;
}
.admin-page #sections .cour-staf{
	flex: 0 0 27%;
	width:auto;
	padding-left: 0;
}
.admin-page .wek-stu-stuta{
	padding-left: 0px;
    padding-right: 0px
}
.admin-page .wek-stu-sec {
    padding-left: 50px;
}
.admin-page .ar-btn-sub2 {
    padding-left: 10px;
}
.admin-page .btn-start-cla{
    display: block;
    margin-top: 5px;
    width: 104px;
    /* vertical-align: middle; */
    float: right;
}
.admin-page .btn-start-cla .start-class {
	color:#0078BF;
}
.admin-page .header-top-left-content .header-logo-dropdown::after{
	width:0px;
}
.admin-page #sections .header-profile-dropdown .dropdown-menu.adjust{
	/*min-width:331px;*/
	top: -13px !important;
}
.parent-page .scrollere#list-st {
    margin-top: 0;
    max-height: 300px!important;
}
.admin-page #sections .dropdown-item.width-two {
    width: auto;
}
.admin-page .dropdown-item.active {
    background-color: #DBE6EE;
}
.shoo-nam{
	font-size:12px;
}
.admin-page .dropdown-item h6{
	font-size:14px;
}
.admin-page .student_pages_content_area .request-edit-student-main-content{
	min-width:100%;
}
.admin-page .student_pages_content_area {
    padding-bottom: 400px;
}
.admin-page .request-edit-student-area {
    width: 100%;
    height: 96%;
    top: 4%;
}
.admin-page #form-edit-student .student-information-heading-profile .avartar_st {
    width: 100px!important;
    height: 100px!important;
    border-radius: 100%;
    border: 2px solid #fff;
}
.admin-page #form-edit-student .student-information-left-heading-image {
    display: block;
}
.admin-page #form-edit-student .student-information-heading-profile {
    position: relative;
    width: auto;
    height: auto;
}
.admin-page #form-edit-student .student-information-edit img {
    width: 20px;
    position: absolute;
    bottom: 20px;
    left: 80px;
}
.top-right-0-3-2 {
    bottom: 0;
    right: 0;
    text-align: right;
}
.admin-page .first-sections-page-content-third .ra_courch_ra_progress_area h5 {
    font-weight: 300;
    font-size: 14px;
    width: 37px;
    text-align: right;
}

.admin-page #in-sec-clas .re_body_list_item1, .admin-page #in-sec-clas .re_header_list_item1 {

    flex: 0 0 35%;
}
.admin-page #in-sec-clas .re_body_list_item2, .admin-page #in-sec-clas .re_header_list_item2 {
  
    flex: 0 0 30%!important;
}
.admin-page #in-sec-clas .re_body_list_item4, .admin-page #in-sec-clas .re_header_list_item4 {
 
    flex: 0 0 35%!important;
}
.admin-page #in-cla-sec .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a {
    font-size: 14px;
    color: #A6ADB1;
    display: inline-block;
    transition: .5s;
}
#schedule .ra_jackson_btn_area a.btn-start-cla{
	float: left;
    text-align: center;
    padding: 4px 15px;
    width: 110px;
}
#schedule .carousel-caption {
    position: initial;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    color: rgb(33, 37, 41);
    text-align: center;
}
#schedule .carousel-indicators{
	display:none;
}
#schedule .carousel-control-next,#schedule .carousel-control-prev{
	display:none;
}
 .admin-page #schedule .ra_event_single_slider_area_bottom p {
    font-weight: 300;
    padding-bottom: 5px;
    text-align: left;
}
.admin-page #schedule .ra_event_main_area_slider_items.add-new-class .ra_event_single_slider_area_bottom h1 {
    width: 200px;
    text-align: left;
}
.admin-page .ra_event_main_area_slider_items.add-new-class .ra_event_single_slider_area {
    padding: 24px 8px 24px 20px;
    height: 140px;
}
.admin-page .col-big .single-top-progress-area{
	padding:40px;
}
.admin-page .col-big .top-progress-area .single-top-progress-area.add-new:nth-child(2) {
    padding-bottom: 25px!important;
}
.admin-page #home .col-big .myclassroom-single-left-cross-progress {
    width: 210px;
}
.admin-page .col-big .ar-myclass-count123 {
    margin-left: -400px;
    padding-top: 50px;
}
.admin-page .col-big .ar-ra-search-bar input[type="search"] {
    width: 500px !important;
}
.admin-page #home .top-progress-area {
   display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.admin-page .no-attan .learning-course-progress-single-content {
    flex: 0 0 32%;
}
.admin-page #student .student-information-text{
	width: 97%;
    margin-left: 5%;
	justify-content: flex-start;
	
}
.admin-page #student .student-information-text .txt-ifosmaler{
	padding-bottom: 0px!important;
    font-size: 11px!important;
    color: #757c80!important;
	display:flex;

}
.admin-page #student .student-information-text .txt-ifosmaler a{
	color: #007bff;
	padding-left:10px;
}
.admin-page #sections .learning-enviroment-learn-button .contact-button a {
    font-size: 14px;
    color: #0078BF;
    border: 1px solid #0078BF;
    padding: 5px 14px;
	background: transparent;
}
.admin-page #sections .learning-enviroment-learn-button .contact-button a:hover {
    background: #0078BF;
    border-color: #0078BF;
    color: #FFF;
}
.admin-page #sections .learning-enviroment-learn-button .contact-button a button{
	color: #0078BF;
}
.admin-page .mu_people_list_margin {
    margin: 0 0px 0 12px;
}
#gradebook .down-icon-three img{
	width: 12px !important;
    height: 8px !important;
    right: 10px;
    bottom: 24px!important;
    position: absolute;
}
#gradebook .scrollere {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 250px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
    margin-top: 10px;
	z-index:9999999;
}
#gradebook .ar-grade-single-unit {
    text-align: center;
    width: 100px;
}
#gradebook .ct-grad{
	width:100%;
	display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex: 0 0 70%;
    flex: 0 0 71%;
}

.admin-page #activity-gradebook .ra_gradebook_shortable_header_left{
	flex: 0 0 22%;
}
#gradebook #activity-gradebook .ct-grad{
	flex: 0 0 79.3%;
	width:100%;
	width: auto;
    /*overflow: auto;*/
}
.admin-page #activity-gradebook .ar-grade-section-unit{
	flex: 0 0 78%;
	width: auto;
    /*overflow: auto;*/
}
.admin-page #activity-gradebook .dc_dots_area .dropdown-menu a {
    font-size: 13px;
    font-weight: 400;
    padding: 5px 12px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right_single_item{
	min-height:110px;min-width: 100px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right .single_sortable_design{
	display:block;
}
.admin-page #activity-gradebook .ar-grade-header-unit-left-text h5{
	font-size:14px;
	/*padding: 10px 0;*/
}
.admin-page #activity-gradebook .ar-grade-header-unit-left-text label{
	color: #05233B;
    font-weight: 300;
    margin-bottom: 0;
	font-family: 'Source Sans Pro', sans-serif;
    font-family: 'Avenir';
	font-size:13px;
}
.admin-page #activity-gradebook .body-scol{
	width:100%;
	display:flex;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right.ar-grade-section-unit .single_sortable_design {
  height: 83px;
  line-height: 83px;
  text-align: left;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right.ar-grade-section-unit h5 {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-weight: 500;
    font-size: 12px
}

.admin-page #activity-gradebook .ar-book-percent{
	min-width:100px;
	    /*max-width: 150px;*/
}
.admin-page #activity-gradebook .ar-grade-section-unit::-webkit-scrollbar {
    height: 5px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.admin-page #activity-gradebook .ar-grade-section-unit::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.cale-stool{	
    display: contents;
}

.admin-page #activity-gradebook .ar-grade-section-unit::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
#gradebook #activity-gradebook .ct-grad::-webkit-scrollbar {
    height: 5px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
#gradebook #activity-gradebook .ct-grad::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
#gradebook #activity-gradebook .ct-grad::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
#gradebook #activity-gradebook .div-tool{
	display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}
#gradebook #activity-gradebook .div-tool i{
	cursor:pointer;
}
#gradebook  a.txt-link-bac{
	text-decoration: underline;
    margin-bottom: 15px;
    color: blue;
}
#gradebook  a.txt-link-bac i{
	margin-right:1px;
}
.cale-stool>div{
	position: absolute;
    margin-top: 19px;
}
.admin-page .ar-grade-unit-btn .react-datepicker button{
	background: initial;
	padding: initial;
}
.log-i-role{
	padding-top: 0!important;
    padding-bottom: 0!important;
    color: red!important;
}
.admin-page .ra_gradebook_header .mu_dropdown_menu_content ul li a.item-sec {
    padding: 12px!important;
}
.admin-page .ra_gradebook_header .dropdown-menu.sec {
    top: -25px !important;
	    width: auto;
}
.admin-page .ra_gradebook_header .dropdown-item.width-two {
    width: auto;
}
.admin-page .ra_gradebook_header .dropdown-item.width-two h6 {
    font-size: 13px;
    font-weight: 300;
}
.admin-page .ar-grade-header-unit-left-text h5 {
   
    font-size: 18px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right.ar-grade-section-unit .single_sortable_design {
    height: 76px;
    line-height: 50px;
    text-align: left;
}
.admin-page #activity-gradebook .css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(210deg 33% 96%);
    border-color: #3F4254;
    color: #3F4254;
}
.admin-page .theacher-popup-content-two{
	padding-bottom:100px
}
.admin-page #activity-gradebook .tab-content.new-class-add-last-website .single-myinfo-form {
    padding-bottom: 0;
}
.admin-page #cur-enrol .mu_search_bar.new-class.again-add {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
}
.admin-page .student-course-progress-area .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #schedule #home .upcoming-area.ra_calender_area.upcoming-area tbody td {
    height: 48px;
}
.admin-page #home #in-section-class-new .jss9.jss11.jss16.jss21.jss12, .admin-page #home #in-section-class-new .jss17.jss9.jss11.jss12, .admin-page #home #in-section-class-new .makeStyles-flexRow-17.makeStyles-stickyElement-9.makeStyles-ordinaryLeftPanelBorder-12, .admin-page #home #in-section-class-new .makeStyles-stickyElement-9.makeStyles-ordinaryLeftPanelBorder-12 {
    display: none;
}
.lihi14{
	line-height:14px!important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 0;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}
.ar-grade-single-unit1, .ar-grade-single-unit2, .ar-grade-single-unit3, .ar-grade-single-unit4, .ar-grade-single-unit5, .ar-grade-single-unit6, .ar-grade-single-unit7, .ar-grade-single-unit8 {
    
   /* max-width: 150px;*/
}
.admin-page #activity-gradebook .ar-book-percent {
     /* max-width: 150px;*/
}
.admin-page #gradebook .dc_dots_area .dropdown-menu {
    min-width: 190px;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #e4e6ef;
    padding: 5px 0!important;
    left: -60%!important;
    top: 24px!important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
}
.admin-page #gradebook .ra_gradebook_shortable_header_right_box{
	    display: flex;
    justify-content: flex-end;
    margin-right: 25%;
}
.admin-page #gradebook .form-control {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    border: 1px solid #3F4254;
    max-width: 95px;
    padding: 5px 15px;
    border-radius: 5px;
    outline: none;
    margin: auto;
}
.admin-page .ra_upcoming_heading.last-pages-class.onlien-sec{
	margin-top: 0px;
	background: #f3f6f9;
}
.admin-page #sections .today-tile {
    width: 60%;
    width: 70%;
}
.admin-page #sections .owl-carousel .owl-item .ra_resources_slider_pdf img {
    display: inline-block;
    width: auto;
    margin-right: 4px;
}
.admin-page #sections .owl-carousel .owl-item .lean_detail img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}
.admin-page #schedule #view-togge{
	padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #order-grades #view-togge{
	padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #order-grades .mu_dropdown_menu_content ul li {
    color: #3f4254;
    margin-right: 20px;
    font-size: 12px;
	padding:18px 0;
}
.admin-page #order-grades .ra_event_right_btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    padding-top: 0px;
    margin-top: -44px;
	width: 70%;
    float: right;
}
.admin-page #schedule .ra_event_right_btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    padding-top: 0px;
    margin-top: -44px;
	width: 70%;
    float: right;
}
.admin-page #order-grades .ar-input7 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input6 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input5 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input4 select {
	padding: 8px 0 7px 3px;
    width: 90px;
	    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #order-grades .ar-input4 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input3 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input3 input[type="text"] {
    width: 90px;
	    border: 1px solid #a6adb1;
    outline: none;
    color: #505355;
    font-size: 14px;
}
.admin-page #order-grades .ar-input2 input[type="search"] {
    width: 90px;
}
.admin-page #order-grades .ar-input1 input[type="search"] {
    width: 270px;
}
.admin-page #order-grades .ar-single-search {
    margin-right: 30px;
}
.admin-page .student-course-progress-area #trackname .ra_process_tracking_area_header{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #order-grades .ar-single-department-course {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0px 0px 10px #e4e6ef;
    margin-bottom: 10px;
}
.admin-page #order-grades .student-name span {
    color: #3F4254;
    font-size: 12px;
    font-family: 'Avenir';
    font-weight: 300;
    display: flex;
}
.admin-page #order-grades .stu-dept {
    /* width: 400px; */
    -webkit-flex: 0 0 28%;
    -webkit-flex: 0 0 27%;
    flex: 0 0 28%;
    flex: 0 0 27.5%;
}
.admin-page #order-grades .cote-grade {
   flex: 0 0 11%;
   flex: 0 0 12%;
   flex: 0 0 11.5%;
   margin-left: 0px;
   padding-left:0px;
}
.admin-page #order-grades .course-sec {
    background: #ffffff;
}
#order-grades .react-datepicker__day-name,#order-grades .react-datepicker__day,#order-grades .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    font-weight: 100;
}
.admin-page #gradebook .ar-book-percent {
   
    height: 100px;
}
.admin-page #gradebook #btn-arow{
	    display: flex;
    justify-content: space-around;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right_single_item .fa-save{
	font-size:20px;
	cursor:pointer;
}
.admin-page  #activity-gradebook .ar-book-percent{
	padding:25px 0;
}
.admin-page #edit-grade .tab-content.new-class-add-last-website .request-enrollment-main-content {
    max-width: unset;
    width: 100%;
}

.admin-page #edit-grade {
    padding-bottom: 100px;
    background: #fff;
}
.admin-page  #edit-grade .request-enrollment-option p{
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    line-height: 2.4;
    margin-bottom: 0;
}
.admin-page  #edit-grade .input{
	    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding: 7px 10px;
    border: 1px solid #3F4254;
    border-radius: 5px;
    outline: none;
}
.admin-page  #edit-grade .request-enrollment-option .css-yk16xz-control{	   
        width: 100%;
    font-weight: 300;
    font-size: 13px;
    color: #3F4254;
    text-transform: inherit;
    padding: 0px 10px;
    border: 1px solid #3F4254;
    border-radius: 5px;
    outline: none;
}
.admin-page  #edit-grade input.form-control{
	    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding: 7px 10px;
    border: 1px solid #3F4254;
    border-radius: 5px;
    outline: none;
}
.pd0{
	padding:0;
}
.admin-page #edit-grade .all-same-button-design {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 35px;
}
.admin-page #edit-grade .single-same-button-design.new-class button {
    background: #0078BF;
    border: 1px solid #0078BF;
    color: #FFFFFF;
    margin-left: 0;
    font-size: 13px;
    padding: 7px 5px;
    width: 120px;
    border-radius: 50px;
}
.admin-page #edit-grade .add-activity-text-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: center;
    -webkit-align-items: center;
    align-items: flex-start;
    padding-bottom: 50px;
    padding-bottom: 5px;
}
.admin-page #activity-gradebook #btn-arow {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
	    height: 40px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right_single_item.ra_gradebook_extra .single_sortable_design {
    text-align: center;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right .single_sortable_design {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: start;
    align-items: start;
}
.admin-page #activity-gradebook .ar-grade1-extra {
    height: 128px;
}
.admin-page #activity-gradebook .ar-grade-single-unit8 .ra_gradebook_shortable_header_right_single_item {
    min-height: 110px;
    min-width: 96px;
}
.admin-page #activity-gradebook .ar-book-dots{
	display: flex;
    align-items: center;
}
.admin-page .jannle-marshall-image img{
	width:40px;
	height:40px!important
}
.admin-page #roster .ar-search-wrapper{
   width:100%;
}
.admin-page #sections #roster .ar-single-search.search1{
	flex:0 0 30%;
	margin:0px;
}
.admin-page #sections #roster .ar-single-search.search2{
	flex:0 0 14%;
	margin:0px;
}

.admin-page #sections #roster .ar-single-search.search4{
	flex:0 0 14%;
	margin:0px;
}
.admin-page #sections #roster .ar-single-search.search5{
	flex:0 0 14%;
	margin:0px;
}
.admin-page #sections #roster .ar-single-search.search6{
	flex:0 0 14%;
	margin:0px;
}
.admin-page #sections #roster .ar-single-search.search7{
	flex:0 0 14%;
	margin:0px;
}
.admin-page #sections #roster .ar-student-quality {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: unset;
    padding: 0px 0px 0 20px;
    flex: 0 0 30%;
}

.admin-page #sections #roster .ar-quality-of-work {    
    width: unset;
    flex: 0 0 14%;
	padding-left: 30px;
}
.admin-page #sections #roster .ar-quality-of-work p {
    color: #3f4254;
    font-size: 14px;
    font-weight: 300;
    font-family: "Avenir";
}
.admin-page #sections #roster .profile-section {
    cursor:pointer;
}
.admin-page #sections .ra_resources_right_area_single_item.add-new-add-class.ra_resources_top p {
    padding-top: 5px;
    padding-bottom: 5px;
}
.admin-page #sections .ra_resources_right_area_single_item::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: -15px;
    width: 114%;
    height: 1px;
    background-color: #EAEDF3;
}
.admin-page #sections .ra_resources_right_area_single_item:last-child:after {
    content: "";
    position: absolute;
    background-color: initial;
}
.admin-page #sections #fil-sec .ar-input1 input[type="search"] {
    width: 240px;
}
.admin-page #sections .weeks-sec {
    padding-left: 20px;
}
.admin-page #sections .wek-stu-sec {
    padding-left: 60px;
}
.admin-page #student #se-stue .ar-filter-all-wrapper {
    display: -webkit-flex;
    display: flex;
    padding: 10px 30px 13px 40px;
    margin-top: 1px;
}
.admin-page #student  #se-stue .dc_single_items.ads-new-class {
    margin-bottom: 12px;
    padding-left: 40px;
}
.admin-page #student  #se-stue .student-gpa-contents {
    -webkit-flex: 0 0 18%;
    flex: 0 0 20%;
    text-align: center;
}

.admin-page .today-week-button a.add-event{
	width:auto;
}
.admin-page .form-check-input[type=checkbox] {
    border-radius: .25em;
}
.admin-page .form-check .form-check-input {
    padding: 0;
    float: left;
    margin-left: 0.5em;
    width: 15px!important;
    height: 15px
}
.admin-page .form-check-input {
   /* width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;*/
}
.admin-page .request-edit-student-area .css-yk16xz-control {
    border-color: #3f4254;
    height: 36px;
    min-height: 34px;
	height:auto;
}
.linebo{
	    border-top: 1px dashed #ddd;
    padding: 10px 0;
    border-bottom: 1px dashed #ddd;
    margin-bottom: 10px;
}
.admin-page .request-edit-student-area .txt-si{	
    height: 32px;
    width: 84px;
    padding: 0px;
    display: inline;
    margin-left: 20px;

}
.admin-page .request-edit-student-area .txt-se{	
    height: 32px;
    padding: 0px;
	width:70%;
	 display: inline;

}
.admin-page #attendance-page .view-attendance-middle-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: 0 0 50%;
    flex: 0 0 65%;
}
.admin-page #attendance-page .view-attendance-left-content{
	    display: flex;
    width: 100%;
    justify-content: space-between;
}

.admin-page #attendance-page .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page .request-edit-student-area .form-check-input:checked[type=checkbox] {
    /*background-image: url('/media/enrollment/down-arrow-enroll.png');*/
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}
.admin-page .request-edit-student-area .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.admin-page #gradebook .mu_search_bar.mu_search_bar_reply{
	margin-right:30px;
}
.admin-page #gradebook .div-action{
	    display: flex;
}
.admin-page #gradebook button#add-unit {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    background: #0078bf;
    padding: 5px 20px;
    border-radius: 35px;
    border: 1px solid transparent;
    transition: .3s;
}
.admin-page #gradebook button#add-unit:hover{
	background: transparent;
	color: #0078bf;
	 border: 1px solid #0078bf;
}
.admin-page .mu_icon_header_icons ul li a{
	margin:0 15px;
}
.fa-paperclip{
	cursor:pointer;
	color:blue;
}
.admin-page a.add-event-sche {
    text-align: center!important;
    color: #0078bf;
	font-size: 10px;
    font-weight: 300;
    display: inline-block;
    padding: 4px 10px;    
    border-radius: 50px;
    transition: .3s ease-in-out;	
	border: 1px solid #0078bf;
	background: transparent;
}
.admin-page a.add-event-sche:hover{
	 background: #0078bf;
	color: #fff;
	border: 1px solid transparent; 
}	
.admin-page #schedule .request-edit-student-area {
    height: 98%!important;
    top: 2%!important;
}
.btn-cap{
	text-transform: capitalize!important;
}

.admin-page #is_online_section .ra_gradebook_shortable_header_left {
   
}
.admin-page #is_online_section .ar-book-imagename-check{
	width:28.8%;
}
.flex-end{
	width: 2%;
    display: flex;
    justify-content: flex-end!important;
}
.admin-page #gradebook .ra_gradebook_header .home-text-area {
    margin-bottom: 10px;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search_width .header-profile-dropdown .dropdown .dropdown-menu {
	transform: translate(0px, 45px)!important;
}
.admin-page #gradebook .ar-grade-header-unit-left-text{
	line-height: 2;
    padding: 18px;
}
.admin-page #gradebook .ar-grade-header-unit-left-text label {
    color: #05233B;
    font-weight: 300;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-family: 'Avenir';
    font-size: 13px;
}
.admin-page #sections .learning-course-details-area #list-st{
	transform: translate(0px, 33px);
}
.admin-page #sections #list-st .dropdown-item.width-two {
    width: auto;
    display: block;
    padding: 10px;
}
.admin-page .ra_gradebook_shortable_header_right .single_sortable_design h5 {
    font-size: 10px;
    font-weight: 400;
    color: #3F4254;
    padding-right: 14px;
    max-height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.admin-page #gradebook .li_arrow_sort button img {
    max-width: 12px;
    width: 9px;
    height: auto;
}
.admin-page #student .ar-single-search.search3.new-add {
    -webkit-flex: 0 0 20%;
    flex: 0 0 30%;
}
.admin-page #student .student-listing-single-content {
    -webkit-flex: 0 0 33%;
    flex: 0 0 33%;
}
.admin-page .super-admin-groups .student-listing-single-content.c1 {
    -webkit-flex: 0 0 46%;
    flex: 0 0 46%!important;
}
.admin-page #student .student-grade-lavel-single-content {
    -webkit-flex: 0 0 10%;
    flex: 0 0 30%;
    text-align: left;
    padding-left: 30px;
}
.admin-page #student .student-status-text-contents {
    -webkit-flex: 0 0 9%;
    flex: 0 0 14%;
    text-align: center;
}
.admin-page #student .student-status-text-contents.c4st {
    -webkit-flex: 0 0 9%;
    flex: 0 0 15%;
    text-align: center;
}

.admin-page #student .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width: 300px;
    width: 95%;
}
.admin-page #student .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 140px;
    width: 100%;
}
.admin-page #student .ar-search_box_area.ar-input4.new-add input[type="search"] {
    max-width: 140px;
    width: 100%;
}
.admin-page #student .ar-search_box_area.ar-input5.new-add input[type="search"] {
    max-width: 140px;
    width: 100%;
}
.admin-page #student .select-status{
	width:140px;
}
.admin-page .div-acts {
   text-align: right;
    padding: 15px 15px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: space-between;
}
.admin-page .change-password-area  #setting .change-password-content {
    max-width: 800px;
    max-width: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-left: 0;
    padding-top: 20px;
}
.admin-page #setting .table-notification thead th {
    font-weight: 600;
}
#attendance-page .scrollere {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 250px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
    margin-top: 10px;
}
.admin-page #student .ar-single-search.search1.new-add {
    flex: 0 0 35%;
    flex: 0 0 32%;
    margin-right: 0px;
}
.admin-page #student .ar-single-search.search2.new-add {
	flex: 0 0 28%;
    margin-right: 0px;
}
.admin-page #student .ar-single-search.search3.new-add {
	flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #student .ar-single-search.search4.new-add {
	flex: 0 0 19%;
    margin-right: 0px;
}
.admin-page #student .ar-single-search.search5.new-add {
	flex: 0 0 14%;
    margin-right: 0px;
}
.admin-page #student .student-listing-single-content {
    -webkit-flex: 0 0 33%;
    flex: 0 0 33%;
    flex: 0 0 35%;
}
.admin-page #student .student-grade-lavel-single-content.c2 {
    -webkit-flex: 0 0 28%;
    flex: 0 0 28%;
    flex: 0 0 26%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #student .student-grade-lavel-single-content.c3 {
        -webkit-flex: 0 0 15%;
    flex: 0 0 15%;
    text-align: left;
    padding-left: 25px;
}
.admin-page #student .student-grade-lavel-single-content.c4 {
    flex: 0 0 13%;
    text-align: left!important;
    padding-left: 25px;
}
.admin-page #student .student-grade-lavel-single-content.c5 {
    -webkit-flex: 0 0 10%;
    flex: 0 0 14%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #student .student-three-dot-contents {
    -webkit-flex: 0 0 4%;
    flex: 0 0 4%;
    text-align: right;
}
.admin-page #student .ar-input2 input[type="search"] {
    width: 80px;
    width: 90%;
}
.admin-page .single-top-progress-area.a1 {
    background: url(/media/home/background_image_a1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 5px;
    -webkit-flex: 0 0 24.75%;
    flex: 0 0 24.75%;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .1s;
    position: relative;
}

.admin-page .single-top-progress-area.a1 .single-top-progress-count-text h1{
	color: #012845;
}
.admin-page .single-top-progress-area.a1 .single-top-progress-heading-text p{
	color: #012845;
}
.admin-page .single-top-progress-area.a1 .single-top-progress-count-text h5{
	color: #012845;
}
.single-top-progress-area.a1:hover, .top-progress-area .single-top-progress-area.a1:nth-child(2):hover {
    border: 3px solid #67aeca;
}
.admin-page .single-top-progress-area.a2:hover, .top-progress-area .single-top-progress-area.a2:nth-child(2):hover {
    border: 3px solid #69a0cc;
}
.admin-page .single-top-progress-area.a2 .single-top-progress-heading-text p {    
    color: #012845;
}
.admin-page .single-top-progress-area.a2 .course-progress-img-single-contents.add-new p {
    color: #012845;
}
.admin-page .single-top-progress-area.a4 {
    background: url(/media/home/background_image_a4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 5px;
    -webkit-flex: 0 0 24.75%;
    flex: 0 0 24.75%;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .1s;
    position: relative;
}
.center-ct-hom{
	margin-top:30px;
}
.admin-page #home #requests .dc_dots_area {
    position: relative;
    margin-right: 10px;
	display:flex;
}
.admin-page #requests .single-inbox-date-button span.bck {
    background: #d4d8ec;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 9px;
    color: #3e3f43;
    border-radius: 10px;
    /* margin-left: 4px; */
    height: 17px;

}
.admin-page .single-inbox-date-button  span.bck {
    background: #d4d8ec;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 9px;
    color: #3e3f43;
    border-radius: 10px;
    /* margin-left: 4px; */
    height: 17px;
}




.admin-page #requests .dc_dots_area .down-icon img {
    width: 15px;
    height: auto;
}
.admin-page #requests .single-inbox-date-button a {
    background: #fff;
    padding: 3px 15px;
    color: #3F4254;
}
.admin-page #requests .dc_dots_area .dropdown-menu a {
    font-size: 13px;
    font-weight: 300;
}
.admin-page #requests .dc_dots_area .dropdown-menu {
    min-width: 105px;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #E4E6EF;
    padding: 5px 0 !important;
    left: -52px !important;
    top: 10px !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
}
.admin-page .single-top-progress-area.a4:hover {
    border: 3px solid #095d79;
}
.admin-page #sections .ar-single-search.search1 {
    flex: 0 0 25%;
    flex: 0 0 23%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search2 {
	flex: 0 0 10%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search3 {
    flex: 0 0 15%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search4 {
    flex: 0 0 18%;
    flex: 0 0 15%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search5 {
    flex: 0 0 10%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search6 {
    flex: 0 0 7%;
	margin: 0;
}
.admin-page #sections .ar-single-search.search7 {
    flex: 0 0 15%;
    flex: 0 0 9%;
	margin: 0;
}
.admin-page #sections .ar-filter-all-wrapper{
	display:block;
	display: flex;
    padding: 10px 20px 13px;
    padding: 10px 10px 13px;
    margin-top: 1px;
}
.admin-page #sections #fil-sec .ar-input1 input[type="search"] {
    width: 95%;
}
.admin-page #sections .ar-input2 input[type="search"] {
    width: 95%;
}
.admin-page #sections .select-dep{
	width: 95%;
}
.admin-page #sections #fil-sec .ar-input3 input[type="search"] {
    width: 95%;
}
.admin-page #sections #fil-sec .ar-input4 input[type="search"] {
    width: 95%;
}
.admin-page #sections #fil-sec .ar-input5 input[type="search"] {
    width: 95%;
}
.admin-page #sections .text-1{
	flex: 0 0 25%;
	flex: 0 0 23%;
	flex: 0 0 22%;
}
.admin-page #sections .text-2{
	flex: 0 0 10%;
}
.admin-page #sections .text-3{
	flex: 0 0 15%;
	flex: 0 0 14%;
}
.admin-page #sections .text-4{
	flex: 0 0 18%;
	flex: 0 0 14%;
}
.admin-page #sections .text-5{
	flex: 0 0 10%;
}
.admin-page #sections .text-6{
	flex: 0 0 7%;
}
.admin-page #sections .text-7{
	flex: 0 0 9%;
}
.admin-page #sections .text-8{
	flex: 0 0 5%;
}

.admin-page #sections .wek-stu-sec-status {
    padding-left: 0;
}
.admin-page #sections .wek-stu-sec-status p{
	padding:2px 5px;
}
.admin-page #sections .wek-stu-sec-status {
    padding-left: 0;
    width: 90px;
}
.admin-page #sections .department-name{
	max-width:95%;
}
.admin-page #sections .staff-sec{
	width:80%;
	width:auto;
}
.admin-page .div-view-page {
    margin-right: 10px;
}
.admin-page .view-page {
    color: #3f4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 5px;
}
.admin-page .select-page {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    width: 50px;
}
.admin-page #sections .stu-dept .student-sec {
    width: 97%;
}
.admin-page #sections .mu_dropdown_menu_area {
    margin-top: 15px;
}
.admin-page .div-action  .dropdown button {
    font-weight: 400;
    font-size: 12px;
    color: #0078bf;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
}
.admin-page .div-action .dropdown button {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active, .admin-page .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,.admin-page .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,.admin-page .dropdown-toggle.btn.btn-success:hover {
    color: #0078bf;
    background-color: unset;
    border-color: 1px solid #0078bf;
}
.admin-page #sections .div-action .dropdown-menu.adjust {
    top: 13px !important;
    left: -110px !important;
    left: -60px !important;
	transform: translate(14px, 25px)!important;
}
.mgi-10{
	margin:10px 0px;
}
.admin-page #sections .ra_repetable_area .top-progress-area .single-top-progress-area.a4 {
    background: url(/media/home/background_image_a4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 5px;    
    border: 3px solid transparent;
    cursor: pointer;
    transition: .1s;
    position: relative;
}
.admin-page #sections .single-top-progress-area.common-class p, .admin-page #sections .single-top-progress-area.common-class h1, .admin-page #sections .single-top-progress-area.common-class h5 {
    color: #fff;
}
.admin-page #sections .view-content{
	background:#fff;
	background: #FFFFFF;
    padding: 15px 15px;
    position: relative;
    height: 210px;
    height: 260px;
}
.admin-page #sections .inbox-message-left-header h1 {
    font-size: 15px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
}
.admin-page #sections .inbox-message-right-header img {
    width: 16px;
    height: auto;
	vertical-align: top;
}
.admin-page #sections .ar-profile {
    position: relative;
    width: 30%;
    text-align: center;
}
.admin-page #sections .c-avatar__status {
    width: 15px;
    height: 15px;
    background: #99CC00;
    background: #009650;
    border: 1px solid white;
    position: absolute;
    bottom: 0%;
    right: 2%;
    right: -7%;
    border-radius: 100%;
}
.admin-page #sections .profile-left-section-content img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #0aa15a;
}
.admin-page #sections .body-content{
	margin-top:8px;
	display:flex;
	flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-around;
}
.admin-page #sections .ar-profile p{
	font-size:13px;
}
.admin-page a.link-to {
    font-weight: 300;
    font-size: 14px;
    color: #0078BF;
    text-transform: inherit;
    margin-top: 15px;
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 1;
}
.admin-page .student-ava img{
	width:28px;
	height:28px;
	border-radius:100%;
	cursor:pointer;
}
.admin-page .student-ava{
	width:35px;margin-bottom: 10px;
}
.admin-page #sections .body-content.ct-new {
    margin-top: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
}
.admin-page #sections .myclassroom-header-left p {
    font-weight: 500;
    font-size: 15px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
}
.admin-page #sections .ra_upcomming-left-contents p{
	font-weight: 500;
    font-size: 15px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
}
.admin-page #sections .ra_upcoming-right-contents.right-ct {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: first baseline;
    align-items: first baseline;
    flex: 0 0 50%;
    justify-content: space-between;
}
.admin-page #sections .ra_upcoming_heading.last-pages-class {
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 25px 0px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-top: 15px;
    margin-top: 0px;
    justify-content: space-between;
}
.admin-page #sections .ra_resources_area {
    background-color: #F3F6F9;
    padding-bottom: 0px;
}
.admin-page #sections .contact-button a{
	font-size:11px;
}
.admin-page #sections .staff-sec{
	padding-left:3px;
}
.admin-page #sections .department-name{
	margin-left:8px;
	margin-left:0px;
}
.admin-page #sections .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,
 .admin-page #sections .dc_dots_area .dropdown-toggle.btn.btn-success:hover,
 .admin-page #sections .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, 
 .admin-page #sections .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,
 .admin-page #sections .dc_dots_area .dropdown-toggle.btn.btn-success:hover{
	color: transparent;
}
.admin-page #sections .dc_dots_area .dropdown-menu.menu-vi-mo {
    left: -125px!important;
}
.div-ct-check{
	display: flex;
    align-items: center;
}
.admin-page #addSection .single-change-password-right-input input.text{
	height:38px;
}
.admin-page #addSection .single-change-password-right-input .select{
	border: 1px solid #333;
	font-size: 14px;
    font-weight: 300;height:38px;
	
}
.admin-page #addSection .single-change-password-right-input label {
    font-weight: 500;
    font-size: 14px;
    color: #333;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.admin-page #sections{
	min-height:1100px;
}
.admin-page #addSection .btn-sec{
	width:120px;
	font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    background: #fff;
    border: 1px solid #0078BF;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
}
.admin-page #addSection .btn-sec:hover{
	background: #0078BF;color:#fff;
}
.admin-page #addSection .btn-sec.btn_style_1{
	background: #0078BF;color:#fff;
}
.admin-page #addSection .btn-sec.btn_style_1:hover{
	background: #fff;color:#0078BF;
}
.admin-page #sections .ar-filter-area .dropdown-menu.adjust{
	transform: translate(0px, 34px)!important;
}
.admin-page #student .mu_dropdown_menu_area {
    margin-top: 15px;
}
.admin-page #student .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #launchcourse1,.admin-page #launchcourse2{
    background: #0078BF!important;
    color: #FFF!important;
}
.admin-page #launchcourse1:hover,.admin-page #launchcourse2:hover{
    color: #0078BF!important;
	background:transparent!important;
}
.admin-page #student .div-action .dropdown-menu.adjust{
	    left: -60px!important;top:7px!important;min-width: 270px;
}
.admin-page #student #list-st .dropdown-item.width-two {
    width: auto;
    padding: 10px 15px!important;
    margin: 5px 0!important;
}
.admin-page #student .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,
 .admin-page #student .dc_dots_area .dropdown-toggle.btn.btn-success:hover,
 .admin-page #student .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, 
 .admin-page #student .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,
 .admin-page #student .dc_dots_area .dropdown-toggle.btn.btn-success:hover{
	color: transparent;
}
a.view-detail{
	margin-top: 25px;
    font-size: 13px;
}
.admin-page #cur-enrol .student-information-single-info p span {
    padding-left: 0px;
}
.admin-page #student .student-information-single-info p span {
    padding-left: 0px;
}
.admin-page #message .mu_message_scroll_right {
    height: 715px;
}
.admin-page #teacers .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page  #teacers .dc_check_star {
    -webkit-flex: 0 0 6%;
    flex: 0 0 3.5%;
}
.admin-page #teacers .ar-single-search.search1.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #teacers .ar-single-search.search2.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #teacers .ar-single-search.search3.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #teacers .ar-single-search.search4.new-add {
    flex: 0 0 7%;
    margin-right: 0px;
}
.admin-page #teacers .ar-single-search.search5.new-add {
    flex: 0 0 7%;
    margin-right: 0px;
}
.admin-page #teacers .ar-single-search.search7.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #teacers .select-school, .admin-page #teacers .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width: 125px;
	
}
.admin-page #teacers .select-school{
	width:95%;
}
.admin-page #teacers .ar-search_box_area.ar-input1.new-add input[type="search"] {
    width: 93%;
    width: 94%;
}
.admin-page #teacers .ar-input2 input[type="search"] {
    width: 93%;
}
.admin-page #teacers .ar-input3 input[type="search"] {
    width: 93%;max-width:inherit;
}
.admin-page #teacers .ar-input4 input[type="search"] {
    width: 93%;max-width:inherit;
}
.admin-page #teacers .ar-input5 input[type="search"] {
    width: 93%;max-width:inherit;
}
.admin-page #teacers .ar-input7 input[type="search"] {
    width: 93%;max-width:inherit;
}
.admin-page #teacers .student-listing-single-content {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    flex: 0 0 22%;
	cursor:pointer;
}
.admin-page #teacers .student-grade-lavel-single-content.c2 {
   flex: 0 0 19%;
    text-align: left;
}
.admin-page #teacers .student-grade-lavel-single-content.c3 {
   flex: 0 0 14%;text-align: left;
}
.admin-page #teacers .student-grade-lavel-single-content.c4 {
   flex: 0 0 7%;
}
.admin-page #teacers .student-grade-lavel-single-content.c5 {
   flex: 0 0 7%;
}
.admin-page #teacers .student-grade-lavel-single-content.c7 {
   flex: 0 0 9%;
}
.admin-page #teacers .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,
 .admin-page #teacers .dc_dots_area .dropdown-toggle.btn.btn-success:hover,
 .admin-page #teacers .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, 
 .admin-page #teacers .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,
 .admin-page #teacers .dc_dots_area .dropdown-toggle.btn.btn-success:hover{
	color: transparent;
}
.admin-page #teacers .mu_dropdown_menu_content ul li {
    color: #3F4254;
    margin-right: 20px;
    font-size: 12px;
    padding: 18px 0;
}
.admin-page #teacers .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #teacers .staff-sec-sec{
  background: #E4E6EF;
  border-radius: 5px;
  margin-bottom: 5px;
      width: auto;
    display: inline-block;
	    max-width: 140px;
    word-break: break-all;
    max-height: 50px;
    overflow: auto;
}
.admin-page #teacher-detail .main_student_information_area {
    margin-top: 0;
}
.admin-page #teacher-detail .student-course-progress-left-content .course-details .active{
	 background-color: #dbe6ee;
}
.admin-page #teacher-detail .change-password-left-content.course-details {
    padding-bottom: 5px;
}
.admin-page #home .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,
 .admin-page #home .dc_dots_area .dropdown-toggle.btn.btn-success:hover,
 .admin-page #home .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, 
 .admin-page #home .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,
 .admin-page #home .dc_dots_area .dropdown-toggle.btn.btn-success:hover{
	color: transparent;
}
.admin-page #teacher-detail .ra_calender_area.upcoming-area tbody {
    border-top: 1px solid #ffffff;
}
.admin-page #teacher-detail .today-week-button a{
	z-index:1111;
	font-size: 11px;
    padding: 4px;
    height: 26px;
    width: 65px;
}
.admin-page #teacher-detail .today-tile {
    width: 75%;
}
.admin-page .mu_dropdown_menu_content ul li{
	padding:18px 0;
}
.admin-page .department-name.none{
	background:#fff;
}
.admin-page #addSection{
	background:#fff;
}
.portlet.box.green-meadow {
    border: 1px solid #2ae0bb;
    border-top: 0;
}
.portlet.box.green-meadow > .portlet-title {
    background-color: #1BBC9B;
}
.portlet.box > .portlet-title {
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
}
.portlet.box.green-meadow > .portlet-title > .caption {
    color: #FFFFFF;
}
.portlet.box > .portlet-title > .caption {
    padding: 11px 0 9px 0;
}

.portlet > .portlet-title:before, .portlet > .portlet-title:after {
    content: " ";
    display: table;
}
.portlet.box > .portlet-body {
    background-color: #fff;
    padding: 10px 30px;

}
.div-flex-ct{
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	width: 80%;
	width: 100%;
}
#input-ad-se{
	    width: 70px;
    margin-left: 10px;
    margin-right: 10px;
}
.label-re-fe{
	padding-top:7px;text-transform: inherit!important;
}
.btn-ad-inf{
	width: 60px!important;
    padding: 4px!important;
    text-align: center;
}
.admin-page #addSection .css-yk16xz-control{
	border: 1px solid #3F4254;
}
.student-tip .tooltip-inner{
	max-width:inherit!important;
}
.admin-page #teacers .department-name{
	max-width:95%;
}
.admin-page #sections .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #sections .dc_check_star {
    flex: 0 0 3.5%;
	display:flex;
	flex-direction: row;
    justify-content: flex-end;
}
.admin-page #student .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #student .dc_check_star {
    flex: 0 0 3.5%;
}
.admin-page #sections .single-top-progress-area.common-class h1, .admin-page #sections .single-top-progress-area.common-class h5, .admin-page #sections .teacher-page .single-top-progress-area.common-class p {
    color: #05233b;
}
.admin-page #sections .single-top-progress-area.common-class p, .admin-page #sections .single-top-progress-area.common-class h1, .admin-page #sections .single-top-progress-area.common-class h5 {
    color: #05233b;
}
.admin-page #sections .single-top-progress-count-text h1,.admin-page #sections .single-top-progress-count-text span {
     color: #05233b;
}
.fw300{
	font-weight:300;font-size:13px;
}
.fsize13{
	font-size:13px!important;
}
.div-tea-name{
	display: flex;
    flex-direction: row;
    align-items: center;
	font-size:13px!important;
}
.admin-page #se-enroll .ar-single-search.search1.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.admin-page #se-enroll .ar-single-search.search2.new-add {
    flex: 0 0 11%;
    margin-right: 0px;
}
.admin-page #se-enroll .ar-single-search.search3.new-add {
    flex: 0 0 11%;
    margin-right: 0px;
}
.admin-page #se-enroll .ar-single-search.search4.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #se-enroll .ar-single-search.search5.new-add {
    flex: 0 0 28%;
    margin-right: 0px;
}
.admin-page #se-enroll .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #se-enroll .ar-search_box_area.ar-input2.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #se-enroll .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #se-enroll .ar-search_box_area.ar-input4.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #se-enroll .ar-search_box_area.ar-input5.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #se-enroll .ar-single-filter-check {
    padding-right: 10px;
	padding-top: 22px;
}
.admin-page #se-enroll .dc_check_star {
    -webkit-flex: 0 0 3.5%;
    flex: 0 0 3.5%;
}
.admin-page #se-enroll .student-listing-single-content.c1 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 28%;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c2 {
    flex: 0 0 11%;
    text-align: center;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c3 {
    flex: 0 0 11%;
    text-align: center;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c4 {
    flex: 0 0 19%;
    text-align: left;
    margin-left: 20px;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c5 {
    flex: 0 0 25%;
    text-align: left;
}
 .admin-page #se-enroll .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width:95%;
}
.admin-page #se-enroll .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
	margin-top:20px;
}
.admin-page #teacers .mu_dropdown_menu_area {
    background: #F3F6F9;
    margin-top: 15px;
}
.admin-page #home .body-porlet{
	height:730px;
	background:#fff;
	border-radius:5px;
	box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	display: flex;
    flex-direction: column;
   /* justify-content: space-between;*/
}
.admin-page .header-top-center-content span{
	font-size:12px;
}
.admin-page .header-bottom-content .dc_dots_area .btn {
    padding: 0;
}
.admin-page .header-bottom-content .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -35px;
    right: -25px;
    top: -5px;
    position: absolute;
}
.admin-page .header-bottom-content .dc_dots_area .img-icon-head{
	margin-top:-3px;
}
.admin-page #applications .addnew{
	padding:6px 16px;
}
.admin-page #applications .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #applications .mu_dropdown_menu_area {
    margin-top: 15px;
}
.admin-page #applications .ar-single-search.search1.new-add {
    flex: 0 0 8%;
}
.admin-page #applications .ar-single-search.search2.new-add {
    flex: 0 0 23%;
}
.admin-page #applications .ar-single-search.search3.new-add {
    flex: 0 0 23%;
}
.admin-page #applications .ar-single-search.search4.new-add {
    flex: 0 0 11%;
}
.admin-page #applications .ar-single-search.search5.new-add {
    flex: 0 0 11%;
}
.admin-page #applications .ar-single-search.search6.new-add {
    flex: 0 0 15%;
}
.admin-page #applications .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #applications .ar-search_box_area.ar-input2.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #applications .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #applications .ar-search_box_area.ar-input4.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page .ar-search_box_area.ar-input5.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #applications .ar-search_box_area.ar-input6.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #applications .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #applications .dc_check_star {
    flex: 0 0 3.5%;
}
.admin-page #applications .student-listing-single-content {
    flex: 0 0 8%;
}
.admin-page #applications .student-grade-lavel-single-content.c2 {
    flex: 0 0 23%;
	text-align:left;
	margin-left:20px;
}
.admin-page #applications .student-grade-lavel-single-content.c3 {
    flex: 0 0 23%;
	text-align:left;
	padding-left:20px;
}
.admin-page #applications .student-grade-lavel-single-content.c4 {
    flex: 0 0 11%;
	text-align:left;
	padding-left:20px;
}
.admin-page #applications .student-grade-lavel-single-content.c5 {
    flex: 0 0 11%;
	text-align:left;
	padding-left:20px;
}
.admin-page #applications .student-three-dot-contents {
    flex: 0 0 3%;
    text-align: right;
}
.admin-page #applications .student-status-text-contents {
    -webkit-flex: 0 0 9%;
    flex: 0 0 12%;
    text-align: center;
}
.admin-page #applications #list-st .dropdown-item.width-two {
    width: auto;
    padding: 10px!important;
    margin: 5px 0!important;
}
.admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active, .admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, .admin-page .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle, .admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:hover {
    color: #ffffff;
}
.admin-page #Rosters .header-profile-dropdown.add-new-class {
    display: -webkit-flex;
    display: flex;
}
#Rosters .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #Rosters #roster .ar-single-search.search1 {
    flex: 0 0 26%;
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.searchm {
    flex: 0 0 12%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.search2 {
    flex: 0 0 10%;
    flex: 0 0 8%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.search4 {
    flex: 0 0 10%;
    flex: 0 0 8%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.search5 {
    flex: 0 0 14%;
    flex: 0 0 12%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.search6 {
    flex: 0 0 18%;
    flex: 0 0 16%;
    margin: 0;
}
.admin-page #Rosters #roster .ar-single-search.search7 {
    flex: 0 0 14%;
    flex: 0 0 11%;
    margin: 0;
}
.admin-page #Rosters #roster .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #Rosters .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #Rosters .ar-input1-learn2 input[type="search"] {
    width:95%;
}
.admin-page #Rosters .ar-input2-learn2 input[type="search"] {
    width: 95%;
}
.admin-page #Rosters .ar-input4-learn2 input[type="search"] {
    width: 95%;
}
.admin-page #Rosters .ar-input5-learn2 input[type="search"] {
    width: 95%;
}
.admin-page #Rosters .ar-input6-learn2 input[type="search"] {
    width: 95%;
}
.admin-page #Rosters .ar-input7-learn2 input[type="search"] {
    width: 95%;
}
.admin-page #Rosters #roster .ar-student-quality {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    padding: 0 0 0 15px;
    flex: 0 0 29%;
    flex: 0 0 23%;
}
.admin-page #Rosters #roster .ar-quality-of-work.c2, .admin-page #Rosters #roster .ar-quality-of-work.c3 {
    width: unset;
    -webkit-flex: 0 0 11%;
    flex: 0 0 12%;
    padding-left: 20px;
	flex: 0 0 9%;
	flex: 0 0 7%;
    padding-left: 10px;
	
}
.admin-page #Rosters #roster .ar-quality-of-work.cm{
	flex: 0 0 12%;
    padding-left: 10px;
}
.admin-page #Rosters #roster .ar-quality-of-work.c4 {
    width: unset;
    -webkit-flex: 0 0 12%;
    flex: 0 0 13%;
    flex: 0 0 11%;
    padding-left: 10px;
}
.admin-page #Rosters #roster .ar-quality-of-work.c5 {
    flex: 0 0 15%;
    flex: 0 0 17%;
    padding-left: 0px;
}
.admin-page #Rosters #roster .ar-quality-of-work.c6 {
    flex: 0 0 14%;
    flex: 0 0 9%;
    padding-left: 0px;
}
.admin-page #Rosters #roster .profile-section{
	margin-left:0;
}
.admin-page #applications .profile-section span{
    border-radius: 100%;
    padding: 3px;
    font-size: 11px;
    width: 19px;
	color:#fff;
	margin-left:7px;
}
.admin-page #applications .profile-section .labe{
	font-size: 14px;
    font-weight: 300;
}
.box_co6 {
    height: 12px;
    width: 12px;
    background: #ffb64d;
    border-radius: 3px;
    margin-right: 8px;
}
.admin-page #attendance-page .single-attendance-header-present-content {
    -webkit-flex: 0 0 19.5%!important;
    flex: 0 0 19.5%!important;
}
.admin-page .tab-content.new-class-add-last-website #edit-student .request-enrollment-main-content {
    max-width: 100%;
    width: 100%;
}
.admin-page #poup-student .ra_gradebook_header {
    padding: 0 ;
}
.admin-page #poup-student .btn-back{
	background:#fff!important;
}
#process-container {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    font-weight: 700;
    overflow-y: hidden;
    padding: 4px 4px 4px 8px;
    max-width: 100%;
    position: relative;
    line-height: 21px;
    margin-bottom: 5px;
    margin-top: 5px;
	width:400px;
}
#process-container .filename {
    font-size: 13px;
    font-weight: 600;
    max-width: 220px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-right: 10px;
    color: #15c;
}
#process-container .remove {
    cursor: pointer;
    height: 16px;
    margin-top: 1px;
    opacity: .5;
    width: 16px;
    position: absolute;
    right: 0;
    top: 5px;
}
#div-footet-save{
	display: flex;
    justify-content: space-between;
}
.admin-page #requets .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
}
.admin-page #requets .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #requets .ar-single-search.search1.new-add {
    flex: 0 0 15%;
}
.admin-page #requets .ar-single-search.search2.new-add {
    flex: 0 0 10%;
}
.admin-page #requets .ar-single-search.search3.new-add {
    flex: 0 0 15%;
}
.admin-page #requets .ar-single-search.search4.new-add {
    flex: 0 0 15%;
}
.admin-page #requets .ar-single-search.search5.new-add {
    flex: 0 0 13%;
}
.admin-page #requets .ar-single-search.search6.new-add {
    flex: 0 0 11%;
}

.admin-page #requets .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input2.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input4.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input5.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input6.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .ar-search_box_area.ar-input7.new-add input[type="search"] {
    max-width:95%;
    width: 100%;
}
.admin-page #requets .dc_check_star {
    flex: 0 0 3.5%;
}
.admin-page #requets .student-listing-single-content {
    -webkit-flex: 0 0 15%;
    flex: 0 0 15%;
}
.admin-page #requets .student-grade-lavel-single-content.c2 {
    flex: 0 0 10%;
}
.admin-page #requets .student-grade-lavel-single-content.c3 {
    flex: 0 0 15%;
}
.admin-page #requets .student-grade-lavel-single-content.c4 {
    flex: 0 0 15%;
}
.admin-page #requets .student-grade-lavel-single-content.c5 {
    flex: 0 0 15%;
}
.admin-page #requets .student-grade-lavel-single-content.c6 {
    flex: 0 0 11%;
}
.admin-page #requets .student-three-dot-contents {
    flex: 0 0 3%;
    text-align: right;
}
.admin-page #order-grades .dc_dots_area .dropdown-menu{
	min-width:155px;
}
.admin-page #edit-grade .btn-sec {
    width: 120px;
    font-weight: 300;
    color: #0078bf;
    font-size: 12px;
    background: #fff;
    border: 1px solid #0078bf;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: .3s ease-in-out;
	
}
.admin-page #edit-grade .btn-sec.btn_style_1:hover {
    background: #fff;
    color: #0078bf;
}
.admin-page #edit-grade .btn-sec.btn_style_1 {
    background: #0078bf;
    color: #fff;
	margin-left:10px;
}
.admin-page #order-grades #edit-all .ar-single-search.search1 {
	width: 180px;
	width: 150px;
    margin-right: 20px;

}
.admin-page #order-grades #edit-all .ar-single-search.search2 {
	width: 170px;
	width: 145px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search3 {
	width: 170px;
	width: 145px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search4 {
	width: 120px;
	width: 85px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search5 {
	width: 120px;
	width: 77px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search6 {
	width: 110px;
}
.admin-page #order-grades #edit-all .ar-single-search.search7 {
	width: 110px;
	width: 80px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search8 {
	width: 110px;
	width: 80px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search9 {
	width: 80px;
	width: 70px;
    margin-right: 10px;
}
.admin-page #order-grades #edit-all .ar-single-search.search10 {
	width: 80px;
	width: 60px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search11 {
	width: 80px;
	width: 65px;
    margin-right: 20px;
}
.admin-page #order-grades #edit-all .ar-single-search.search12 {
	width: 80px;
	width: 56px;
    margin-right: 15px;
}
.admin-page #order-grades #edit-all .ar-single-search.search13 {
	width: 70px;
	width: 30px;
	padding-left:20px;
}
.admin-page #order-grades #edit-all .cursor{
	cursor:pointer;
}
.admin-page #order-grades #edit-all .ar-filter-all-wrapper {
    padding: 10px 28px 13px 20px;
}
.admin-page #table-period thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
}
.admin-page .btn-delete {
    margin: 0 10px;
    background: #e40613;
    border: 1px solid #e40613;
}
.admin-page #userDirectory .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #userDirectory .ar-single-search.search1 {
    flex: 0 0 33%;
    flex: 0 0 30%;
    flex: 0 0 28%;
    margin: 0;
}
.admin-page #userDirectory .ar-single-search.search2 {
    flex: 0 0 13%;
    margin: 0;
}.admin-page #userDirectory .ar-single-search.search2a {
    flex: 0 0 18%;
    margin: 0;
}

.admin-page #userDirectory .ar-single-search.search3 {
    flex: 0 0 12%;
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #userDirectory .ar-single-search.search4 {
    flex: 0 0 12%;
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #userDirectory .ar-single-search.search5 {
    flex: 0 0 12%;
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #userDirectory .ar-single-search.search6 {
    flex: 0 0 12%;
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #userDirectory .ar-input1-learn2 input[type=search], .admin-page #userDirectory .ar-input2-learn2 input[type=search], .admin-page #userDirectory .ar-input3-learn2 input[type=search], .admin-page #userDirectory .ar-input4-learn2 input[type=search], .admin-page #userDirectory .ar-input5-learn2 input[type=search], .admin-page #userDirectory .ar-input6-learn2 input[type=search] {
    width: 95%;
}
.admin-page #userDirectory .ar-student-quality {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    flex: 0 0 33%;
	flex: 0 0 31%;
}
.admin-page #userDirectory .ar-quality-of-work.c2{
	width: unset;
    flex: 0 0 12%;
    padding-left: 5px;
	    word-break: break-all;
}
.admin-page #userDirectory .ar-quality-of-work.c2a{
	width: unset;
    flex: 0 0 17%;
    padding-left: 5px;
	padding-right:5px;
}
.admin-page #userDirectory .profile-section{
	margin-left:0;
}
 .admin-page #userDirectory .ar-quality-of-work.c3,
.admin-page #userDirectory .ar-quality-of-work.c4, .admin-page #userDirectory .ar-quality-of-work.c5,
.admin-page #userDirectory .ar-quality-of-work.c6 {
    width: unset;
    flex: 0 0 12%;
    flex: 0 0 9%;
    padding-left: 5px;
    padding-left: 0px;
}
.admin-page #userDirectory .dc_dots_area .down-icon img {
  right:-10px;
}
.admin-page #userDirectory .dc_dots_area .dropdown-menu {
    -webkit-transform: translate(-70px,15px)!important;
    z-index: 200!important;
    min-width: 230px;
}
.admin-page #userDirectory .ar-quality-of-work.c2a p{
	max-height: 80px;
    overflow-y: auto;
	overflow-x: hidden;
    width: 100%;
    overflow-wrap: anywhere;

}
#userDirectory .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade1{
	width:200px;
	width:160px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade2{
	width:190px;
	width:160px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade3{
	width:190px;
	width:160px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade4{
	width:140px;
	width:96px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade5{
	width:140px;
	width:80px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade6{
	width:140px;margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade7{
	width:130px;
	width:90px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade8{
	width:130px;
	width:120px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade9{
	width:110px;
	width:55px;
	padding-left:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade10{
	width:100px;
	width:75px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade11{
	width:100px;
	width:75px;
	margin-right:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade12{
	width:100px;
	width:75px;
	margin-right:10px;
	/*margin-right:28px;*/
}
.admin-page #order-grades #edit-all .input-sec.cote-grade13{
	width:100px;
	width:119px;
	width:80px;
	width:60px;
	padding-left:20px;
	padding-left:10px;
}
.admin-page #order-grades #edit-all .input-sec.cote-grade13 .fa-trash-alt{
	cursor:pointer;
	color:red;
}
.admin-page .input-sec p {
    color: #3F4254;
    font-size: 12px;    
    font-weight: 300;
}
.css-1wa3eu0-placeholder {
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
	font-size:12px;
	font-weight:300;
}
.css-yk16xz-control{
	font-size:12px;
	font-weight:300;
}
.admin-page #edit-all .single-change-password-right-input .text{
	border: 1px solid hsl(0, 0%, 80%);
	margin: 0;
    height: 37px;
}
.admin-page #edit-all .select.form-control{
	font-size:12px;
	font-weight:300;
	 height: 37px;
}
.admin-page #edit-all .single-change-password-right-input .input-date{
	font-size:12px;
	font-weight:300;
	border: 1px solid hsl(0, 0%, 80%);
	height: 37px;
	margin:0;
}
.admin-page .ic-menu{
	    height: 14px;
    width: auto;
    margin-top: -7px;
    margin-right: 2px;
}
.admin-page .drop-menu-it .down-icon-two img {
    width: 12px;
    height: 8px;
    right: -18px;
    top: 17px;
    position: absolute;
}
.admin-page .header-bottom-content ul li a.actives #dropdownMenuButton, .admin-page .header-bottom-content ul li a:hover #dropdownMenuButton{
    color: #0078BF!important;
}
.admin-page .header-bottom-content ul li a #dropdownMenuButton, .admin-page .header-bottom-content ul li a #dropdownMenuButton{
	font-weight: 400;
    font-size: 14px;
    color: #05233B;
}
.admin-page .header-bottom-content .dropdown-item.width-two {
    width: auto;
}

.admin-page #gradebook .mu_search_bar.mu_search_bar_reply{
	margin-right:30px;
}
.admin-page #gradebook .div-action{
	    display: flex;
}
.admin-page #gradebook button#add-unit {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    background: #0078bf;
    padding: 5px 20px;
    border-radius: 35px;
    border: 1px solid transparent;
    transition: .3s;
}
.admin-page #gradebook button#add-unit:hover{
	background: transparent;
	color: #0078bf;
	 border: 1px solid #0078bf;
}
.admin-page .mu_icon_header_icons ul li a{
	margin:0 15px;
}
.admin-page #is_online_section .ra_gradebook_shortable_header_left {
   
}
.admin-page #is_online_section .ar-book-imagename-check{
	width:28.8%;
}
.flex-end{
	width: 2%;
    display: flex;
    justify-content: flex-end!important;
}
.admin-page #gradebook .ra_gradebook_header .home-text-area {
    margin-bottom: 10px;
}
.admin-page .ra_gradebook_header .ra_process_tracking_area_header_search_width .header-profile-dropdown .dropdown .dropdown-menu {
	transform: translate(0px, 45px)!important;
}
.admin-page #gradebook .ar-grade-header-unit-left-text{
	line-height: 2;
    padding: 18px;
    padding: 3px 18px;
}
.admin-page #gradebook .ar-grade-header-unit-left-text label {
    color: #05233B;
    font-weight: 300;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-family: 'Avenir';
    font-size: 13px;
}
.admin-page .ra_gradebook_shortable_header_right .single_sortable_design h5 {
    font-size: 10px;
    font-weight: 400;
    color: #3F4254;
    padding-right: 14px;
    max-height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.admin-page #gradebook .li_arrow_sort button img {
    max-width: 12px;
    width: 9px;
    height: auto;
}
.admin-page #gradebook .ra_gradebook_shortable .left-ct{
	flex:0 0 30%;
}
.admin-page #gradebook .ra_gradebook_shortable .right-ct{
	/*flex:0 0 70%;*/
	width: 70%;
    overflow: auto;
}
.admin-page #gradebook .cote{
	display:flex;
}
.admin-page .left-ct .ar-book-imagename-check {
    width: 100%!important;
}
.admin-page #gradebook .right-ct .ct-grad{
	flex: 0 0 100%;
}
.admin-page #gradebook .right-ct .ar-gradebook-flex-body{
	padding:0;
}
.admin-page #gradebook .left-ct .ar-gradebook-flex-body{
	height:100px;
}
.admin-page #gradebook .left-ct .ra_gradebook_shortable_header_left {
    height: 115px;
}
.admin-page #gradebook .right-ct .ct-grads {
   display:flex;
   box-shadow: 0px 0px 10px #e4e6ef;
   
}
.admin-page #gradebook .right-ct  .ar-gradebook-flex-body{
	box-shadow:none;
}
.admin-page .ar-book-percent {
    background: #fff;
}
.admin-page .ar-book-percent.sameparcent3 {
    background: #F3F6F9;
}
.admin-page #is_of_line .left-ct .ar-book-percent {
    height: 100px;width:85px;background: #eaedf3;
}
.admin-page #is_of_line .left-ct .ar-book-imagename-check{
	width: 225px!important;
}

.admin-page #is_of_line .left-ct .ar-gradebook-flex-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0px 0px 0px 20px;
    background: #fff;
    box-shadow: 0px 0px 10px #e4e6ef;
    margin-bottom: 15px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}
.admin-page #activity-gradebook .left-ct .ra_gradebook_shortable_header_left {
    height: 126px;
}
.avar-gra{
	border-radius:100%;
	width:40px;
	height:40px;
}
.admin-page .select-page {
    background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 8px 0px 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    width: 50px;
}
.admin-page .view-page{
	color: #3F4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 5px;
}
.admin-page .div-view-page{
	margin-right: 10px;
}
.admin-page #gradebook .search-student-in-grade{
	width:130%;
}
.fil-obj{
	    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    color: #3e3f42;
    font-weight: 300;
    cursor: pointer;
}
.admin-page #gradebook button#btn-finalize:hover {
    background: transparent;
    color: #0078bf;
    color: #c55a11;
    border: 1px solid #0078bf;
    border: 1px solid #c55a11;
}

.admin-page #gradebook button#btn-finalize {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    background: #0078bf;
    background: #c55a11;
    padding: 5px 20px;
    border-radius: 35px;
    border: 1px solid #ebc4a9;
    transition: .3s;
	margin-left: 14px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right.ar-grade-section-unit h5 {
    display: block;
    vertical-align: middle;
    line-height: normal;
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    margin-bottom: 10px;
	max-height: 39px;
    overflow: inherit;
    text-overflow: ellipsis;
    white-space: break-spaces;
	min-height:39px;
}
.admin-page #activity-gradebook .ra_gradebook_shortable_header_right.ar-grade-section-unit .single_sortable_design {
    height: 76px;
    line-height: 80px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
#wrapper1{
	height: 20px; overflow-x: scroll!important;
}
#wrapper1 #div1{
	font-size:2px;
}
#wrapper11{
	height: 20px; overflow-x: scroll!important;
}
#wrapper11 #div11{
	font-size:2px;
}
.admin-page #sections .body-content.ct-new {
    margin-top: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
}
.admin-page #sections .student-ava {
    width: 120px;
    width: 130px;
    margin-bottom: 10px;
	display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-page #sections .student-ava img {
    width: 50px;
    height: 50px;
	text-align:center;
	border-radius:50%;
}
.admin-page #sections .student-ava p{
	font-size:13px;
}
.admin-page #gradebook .ra_gradebook_shortable {
    margin-top: -20px;
}
.admin-page #gradebook .right-ct .ar-grade-single-unit1{
	min-width:120px;
}
.admin-page #gradebook .right-ct .ar-book-percent{
	min-width:120px;
}
.admin-page .cursor{
	cursor:pointer;
}
.admin-page #gradebook .mu_dropdown_menu_content ul li{
	padding:0px;
}
.admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active, .admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, .admin-page .dc_dots_area .show>.dropdown-toggle.btn.btn-success.dropdown-toggle, .admin-page .dc_dots_area .dropdown-toggle.btn.btn-success:hover {
    color: transparent;
}
.admin-page #course .dropdown-menu.adjust {
    top: 5px !important;
    left: -30px !important;
}
.admin-page #course .mu_dropdown_menu_area {
    margin-top: 15px;
}
.admin-page #course .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #student .mu_dropdown_menu_content ul li {
    padding: 0;
}
.admin-page #course .dropdown-item.width-two {
    width: auto;
    padding: 10px 15px!important;
    margin: 5px 0!important;
}
.admin-page #course .ar-single-search.search1 {
    flex: 0 0 25%;
    margin: 0;
}
.admin-page #course .ar-single-search.search2 {
    flex: 0 0 10%;
    margin: 0;
}
.admin-page #course .ar-single-search.search3 {
    flex: 0 0 10%;
    flex: 0 0 7%;
    margin: 0;
}
.admin-page #course .ar-single-search.search4 {
    flex: 0 0 10%;
    flex: 0 0 7%;
    margin: 0;
}
.admin-page #course .ar-single-search.search5 {
    flex: 0 0 10%;
    margin: 0;
}
.admin-page #course .ar-single-search.search6 {
    flex: 0 0 10%;
    margin: 0;
}
.admin-page #course .ar-single-search.search7 {
    flex: 0 0 11%;
    flex: 0 0 14%;
    margin: 0;
}
.admin-page #course .ar-single-search.search8 {
    flex: 0 0 11%;
    flex: 0 0 14%;
    margin: 0;
}
.admin-page #course .select-dep, .admin-page #course .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #course  .student-img {
    position: relative;
    padding-right: 8px;
    padding-left: 15px;
}

.admin-page #course .ar-input1 input[type=search], .admin-page #course .ar-input3 input[type=search],
.admin-page #course .ar-input4 input[type=search], .admin-page #course .ar-input5 input[type=search],
.admin-page #course .ar-input6 input[type=search], .admin-page #course .ar-input7 input[type=search],
.admin-page #course .ar-input8 input[type=search],
.admin-page #course .ar-input2 input[type=search], .admin-page #course .select-dep {
    width: 95%;
}
.admin-page #course .dc_check_star {
    -webkit-flex: 0 0 3.5%;
    flex: 0 0 3.5%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
.admin-page #course .text-1 {
    flex: 0 0 24%;
}
.admin-page #course .stu-dept .student-sec {
    width: 100%;
}
.admin-page #course .text-2 {
    flex: 0 0 10%;
}
.admin-page #course .text-3 {
    flex: 0 0 10%;
    flex: 0 0 7%;
}
.admin-page #course .text-4 {
    flex: 0 0 9%;
    flex: 0 0 7%;
}
.admin-page #course .text-5 {
    flex: 0 0 9%;
}
.admin-page #course .text-6 {
    flex: 0 0 10%;
}
.admin-page #course .text-7 {
    flex: 0 0 10%;
    flex: 0 0 13%;
}
.admin-page #course .text-8 {
    flex: 0 0 10%;
    flex: 0 0 13%;
}
.admin-page #course .department-name {
    background: #fff;
    width: 100%;
    display: inline-block;
    margin-left: 13px;
    max-width: 100%;
    padding: 0;
    margin: 0;
}
.admin-page #course .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -15px;
    top: -7px;
    position: absolute;
}
.admin-page #userDirectory .dc_dots_area .drop_as{
  padding: 10px 18px;
}
.admin-page #user-tags .tab-content.new-class-add-last-website .request-enrollment-main-content {
  max-width: 80%;
  width: 100%;
}
.bg-wh{
    margin-bottom: 30px;
}
.admin-page #user-tags .tab-content.new-class-add-last-website .request-enrollment-main-content .ct-tag{
     height: 40px;
    padding: 7px 7px;
    background-color: #fff;
    box-shadow: 0 0 10px #8d93a0;
    border-radius: 2px;
    padding-right: 5px;
}
#user-tags .lbl-ct-tag{
  font-size: 13px;
  padding: 0 6px;
  
}
#user-tags .ct-tag input[type=checkbox]{
	width: 15px;
    height: 15px;
    display: initial;
    vertical-align: sub;
}
.admin-page #user-tags .tab-content.new-class-add-last-website .request-enrollment-main-content .ct-tag img{
  float: right;padding-top: 3px;
}
.admin-page #user-tags .btn-sec {
  width: 120px;
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  background: transparent;
  border: 1px solid #0078bf;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: .3s ease-in-out;
}
.admin-page #user-tags .btn-sec.btn_style_1, .admin-page #user-tags .btn-sec:hover {
  background: #0078bf;
  color: #fff;
}
.admin-page #user-tags .ar-single-filter-check {
  padding-right: 10px;
  padding-top: 22px;
}
.admin-page #user-tags .ar-single-search.search1.new-add {
  flex: 0 0 35%;
  margin-right: 0;
}
.admin-page #user-tags .ar-single-search.search2.new-add {
  flex: 0 0 15%;
  margin-right: 0;
}
.admin-page #user-tags .ar-single-search.search3.new-add {
  flex: 0 0 15%;
  margin-right: 0;
}
.admin-page #user-tags .ar-single-search.search4.new-add {
  flex: 0 0 15%;
  margin-right: 0;
}
.admin-page #user-tags .ar-single-search.search5.new-add {
  flex: 0 0 15%;
  margin-right: 0;
}
.admin-page #user-tags .ar-search_box_area.ar-input1.new-add input[type=search] {
  max-width: 95%;
  width: 95%;
}
.admin-page #user-tags .ar-search_box_area.ar-input2.new-add input[type=search] {
  max-width: 95%;
  width: 95%;
}
.admin-page #user-tags .ar-search_box_area.ar-input3.new-add input[type=search] {
  max-width: 95%;
  width: 95%;
}
.admin-page #user-tags .ar-search_box_area.ar-input4.new-add input[type=search] {
  max-width: 95%;
  width: 95%;
}
.admin-page #user-tags .ar-search_box_area.ar-input5.new-add input[type=search] {
  max-width: 95%;
  width: 95%;
}
.admin-page #user-tags .dc_check_star {
  flex: 0 0 3.5%;
}
.admin-page #user-tags .student-listing-single-content {
  flex: 0 0 35%;
}
.admin-page #user-tags .ar-quality-of-work.c2{
  width: unset;
  flex: 0 0 15%;
  padding-left: 15px;
}
.admin-page #user-tags .ar-quality-of-work.c3{
  width: unset;
  flex: 0 0 14%;
  padding-left: 15px;
}
.admin-page #user-tags .ar-quality-of-work.c4{
  width: unset;
  flex: 0 0 14%;
  padding-left: 15px;

}
.admin-page #user-tags .ar-quality-of-work.c5{
  width: unset;
  flex: 0 0 15%;
  padding-left: 15px;
}
.admin-page #user-tags .dc_dots_area .down-icon img {
  width: 20px;
  height: auto;
  right: 0px;
  top: -7px;
  position: absolute;
}
.admin-page #sections .student-ava.studentif {
  width: 120px;
  width: 35px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-page #user-tags .c-avatar img{
  width: 100px;
  height: 100px;
  border: 1px solid #addedc;
  border-radius: 100%;
}
.admin-page #user-tags .av-us{
  width: 100%;
  text-align: center;
}
.admin-page #user-tags .c-avatar p{
  margin: 7px 0;
  font-size: 14px;
}
.admin-page #user-tags .av-us .tool a{
  margin: 0 40px;
  font-size: 14px;
  font-weight: 500;
  color: #006cc5;
}
.admin-page .btn_style_4.add-school {
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  padding: 5px 17px;
  border: 1px solid #0078BF;
  border-radius: 50px;
  background: #0078BF;
  transition: 0.3s ease-in-out;
}
.admin-page .drop-menu-it .down-icon-two img {
  width: 12px;
  height: 8px;
  right: -10px;
  top: 30px;
  top: 32px;
  position: absolute;
}
.admin-page .dropdown-menu.adjust {
  top: 13px !important;
  left: -60px !important;
  display: inline-table;
  width: auto;
}
.admin-page #teacher-detail .btn-add-new{
  background: #0078BF;
  color: #FFF;
}
.admin-page #teacher-detail .btn-add-new:hover{
  background: #FFF;
  color: #0078BF;
}
.admin-page #communications-teacher .ar-single-filter-check {
  padding-right: 10px;
  padding-top: 22px;
}
.admin-page #communications-teacher .ar-single-search.search1 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search2 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search3 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search4 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search5 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search6 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-single-search.search7 {
  flex: 0 0 12%;
  margin: 0;
}
.admin-page #communications-teacher .ar-input1-learn2 input[type=search], .admin-page #communications-teacher .ar-input2-learn2 input[type=search],
 .admin-page #communications-teacher .ar-input3-learn2 input[type=search], .admin-page #communications-teacher .ar-input4-learn2 input[type=search],
  .admin-page #communications-teacher .ar-input5-learn2 input[type=search], .admin-page #communications-teacher .ar-input6-learn2 input[type=search],
  .admin-page #communications-teacher .ar-input7-learn2 input[type=search]  {
  width: 95%;
}
.admin-page #communications-teacher .ar-student-quality {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: unset;
  padding: 0 0 0 20px;
  flex: 0 0 16%;
}
.admin-page #communications-teacher .ar-quality-of-work.c2, .admin-page #communications-teacher .ar-quality-of-work.c3,
 .admin-page #communications-teacher .ar-quality-of-work.c4, .admin-page #communications-teacher .ar-quality-of-work.c5,
  .admin-page #communications-teacher .ar-quality-of-work.c6 {
  width: unset;
  flex: 0 0 11.3%;
  padding-left: 5px;
}
.admin-page #communications-teacher .ar-quality-of-work.c7 {
  width: unset;
  flex: 0 0 22%;
  padding-left: 5px;
}
.admin-page #communications-teacher .ar-quality-of-work a {
  color: #0056b3;
  text-decoration: underline;
  font-size: 14px;
}
.admin-page #comment-document-teacher .ar-single-filter-check {
  padding-right: 10px;
  padding-top: 22px;
}
.admin-page #comment-document-teacher .ar-single-search.search1 {
  flex: 0 0 45%;
  margin: 0;
}
.admin-page #comment-document-teacher .ar-single-search.search2 {
  flex: 0 0 45%;
  margin: 0;
}
.admin-page #comment-document-teacher .ar-input1-learn2 input[type=search], .admin-page #comment-document-teacher .ar-input2-learn2 input[type=search]
  {
  width: 65%;
}
.admin-page #comment-document-teacher .ar-student-quality {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: unset;
  padding: 0 0 0 20px;
  flex: 0 0 46%;
}
.admin-page #comment-document-teacher .ar-quality-of-work.c2 {
  flex: 0 0 46%;
  margin: 0;width: unset;
}
.admin-page #edit-log #view-togge{
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #edit-log .ra_event_right_btn{
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    margin-top: -44px;
    width: 70%;
    float: right;
}
.admin-page #edit-log .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #edit-log .ar-single-search.search1 {
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #edit-log .ar-single-search.search2 {
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #edit-log .ar-single-search.search3 {
    flex: 0 0 30%;
    margin: 0;
}
.admin-page #edit-log .ar-single-search.search4 {
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #edit-log .ar-single-search.search5 {
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #edit-log .ar-input1 input[type=search],
.admin-page #edit-log .ar-input2 input[type=search],
.admin-page #edit-log .ar-input3 input[type=search],
.admin-page #edit-log .ar-input4 input[type=search],
.admin-page #edit-log .ar-input5 input[type=search]{
    width: 95%;
}
.admin-page #edit-log .dc_check_star {
    flex: 0 0 4.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.admin-page #edit-log .ar-filter-all-wrapper {
    display: flex;
    padding: 10px 10px 13px 10px;
    margin-top: 1px;
}
.admin-page #edit-log .student-name p{
    font-size: 13px;
    color: #181c32;
    font-weight: 300;
    font-family: "Avenir";
}
.admin-page #edit-log .txt1 {
    flex: 0 0 14%;
}
.admin-page #edit-log .txt2 {
    flex: 0 0 19%;
}
.admin-page #edit-log .txt3 {
    flex: 0 0 28%;
}
.admin-page #edit-log .txt4 {
    flex: 0 0 18.5%;
}
.admin-page #edit-log .txt5 {
    flex: 0 0 13%;
}
.admin-page #edit-log  .con-sec p {
    color: #3F4254;
    font-size: 12px;
    padding: 2px 10px;
    font-weight: 300;
}
.admin-page #edit-log .ar-btn-sub2 {
    padding-left: 4px;
}
.header-tran{
	display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.header-tran h5{
	font-size:16px;
}
.admin-page #student-aplication .student-information-single-text-heading p{
	padding-bottom:8px;
}
.admin-page #student-aplication .student-information-single-text-heading h3 {
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    padding-bottom: 15px;
}
#div22 .container1 {
  display: flex;
      width: 100%;
  /*flex-wrap: wrap;
  width: 100%;*/
}

.item {
  display: flex;
    padding: 16px;
    position: relative;
    max-width: 25%;
    flex-basis: 100%;
    border-radius: 2px;
    flex-direction: column;
}

.inner-item {
    /*height: 212px;*/
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
    outline: none;
    
    /*width: 400px;*/
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}
.SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
   /* height: 60px;
    padding: 0 20px;*/
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}
.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}
.grabbing {
  pointer-events: auto !important;
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}
.dragging-container{
pointer-events: auto !important;
cursor:move;
}
.icon-6dot{
	/*margin-bottom:-35px;
	margin-left:-85px;*/
	margin-top:10px;
	float: left;
	cursor:pointer;
	display:none;
}
.ar-grade-single-unit1:hover + .icon-6dot {
  /*display: initial!important;*/
}
.ar-grade-single-unit1:hover .icon-6dot {
  display: initial!important;
}
.div-acti .icon-6dot {
  display: initial!important;
}
.admin-page #transcriptRequest .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #transcriptRequest .ar-single-search.search1 {
    flex: 0 0 30%;
    margin: 0;
}
.admin-page #transcriptRequest .ar-single-search.search2 {
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #transcriptRequest .ar-single-search.search3, .admin-page #transcriptRequest .ar-single-search.search4, .admin-page #transcriptRequest .ar-single-search.search5, .admin-page #transcriptRequest .ar-single-search.search6 {
    flex: 0 0 12%;
    margin: 0;
}
.admin-page #transcriptRequest .ar-student-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    flex: 0 0 33%;
}
.admin-page #transcriptRequest .ar-input1-learn2 input[type=search], .admin-page #transcriptRequest .ar-input2-learn2 input[type=search], .admin-page #transcriptRequest .ar-input3-learn2 input[type=search], .admin-page #transcriptRequest .ar-input4-learn2 input[type=search], .admin-page #transcriptRequest .ar-input5-learn2 input[type=search], .admin-page #userDirectory .ar-input6-learn2 input[type=search] {
    width: 95%;
}
.admin-page #transcriptRequest .ar-quality-of-work.c2 {
    width: unset;
    flex: 0 0 14%;
    padding-left: 5px;
}
.admin-page #transcriptRequest .ar-quality-of-work.c3, .admin-page #transcriptRequest .ar-quality-of-work.c4, .admin-page #transcriptRequest .ar-quality-of-work.c5, .admin-page #transcriptRequest .ar-quality-of-work.c6 {
    width: unset;
    flex: 0 0 11.5%;
    padding-left: 5px;
}
.admin-page #transcriptRequest .ar-quality-of-work.c3 p, .admin-page #transcriptRequest .ar-quality-of-work.c4 p, .admin-page #transcriptRequest .ar-quality-of-work.c5 p, .admin-page #transcriptRequest .ar-quality-of-work.c6 p {
	font-size:13px;
}
#transcriptRequest .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #resource .dropdown-toggle.btn.btn-success:hover,
.admin-page #resource .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active,
.admin-page #resource .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active,
.admin-page #resource .show>.dropdown-toggle.btn.btn-success.dropdown-toggle {
    /*color: transparent;
    background-color: unset;
    border-color: 1px solid #0078bf;*/
}
#applications .head-ne{
	display: flex;
    justify-content: space-between;
}
#applications .head-ne h5 {
    font-size: 16px;
}
.admin-page #gradebook .profile-section{
	margin-left:0;
}
 .student-information-heading .far {
    font-size: 15px;
    color: rgba(37,132,215,.82);
	padding:3px;
}
.admin-page #sections .c-avatar__status {
    width: 10px;
    height: 10px;
    background: #99CC00;
    background: #009650;
    border: 1px solid white;
    position: absolute;
    bottom: 0%;
    right: 2%;
    right: -7%;
    border-radius: 100%;
}
.admin-page #sections .ra_upcoming_heading.last-pages-class {
    background: transparent;
    display: flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 25px 0px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-top: 15px;
    margin-top: 0;
    justify-content: flex-start;
}


.admin-page .link-detail {
    display: initial;
    text-decoration: underline;
}
.admin-page a.link-detail {
	color: blue!important;
}

.admin-page #sections .request-edit-student-area{
	z-index:11111!important;
}
form .form-control{
	border-color: #333;
	height:34px;
}
.admin-page #se-enroll .ra_process_tracking_area_header {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.admin-page  #se-enroll .mu_dropdown_menu_area {
    background: #F3F6F9;
    margin-top: 10px;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c2 {
    flex: 0 0 10%;
    text-align: left!important;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c3 {
    flex: 0 0 10%;
    text-align: left;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c4 {
    flex: 0 0 12%;
    text-align: left;
    margin-left: 0;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c5 {
    flex: 0 0 22%;
    text-align: left;
    margin-left: 0;
}
.modal-body #se-enroll .ar-single-search.search1.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.modal-body #se-enroll .ar-single-search.search2.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.modal-body #se-enroll .ar-single-search.search3.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.modal-body #se-enroll .ar-single-search.search4.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.modal-body #se-enroll .ar-single-search.search5.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.modal-body #se-enroll .ar-single-search.search6.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.modal-body #se-enroll .student-status-text-contents.c6 {
    flex: 0 0 12%;
    text-align: center;
}
.modal-body #se-enroll .student-status-text-contents.c7 {
    flex: 0 0 5%;
    text-align: center;
}
.modal-body #se-enroll-de label {
    font-size: 14px;
    font-weight: 400;
}
p.autobreaak{
	    word-wrap: break-word;
    line-height: initial;
}
.admin-page #teacher-detail .dropdown-menu.new-menu.new-class.dropdown-menu.show{	
    transform: translate(0px, 35px)!important;
}
.admin-page #edit-log-teacher .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #edit-log-teacher .ar-single-search.search1, .admin-page #edit-log-teacher .ar-single-search.search2, .admin-page #edit-log-teacher .ar-single-search.search3 {
    flex: 0 0 25%;
    margin: 0;
}
.admin-page #edit-log-teacher .ar-single-search.search4{
	flex: 0 0 12%;
    margin: 0;
}
.admin-page #edit-log-teacher .ar-single-search.search5{
	flex: 0 0 13%;
    margin: 0;
}
.admin-page #edit-log-teacher .ar-input1-learn2 input[type=search],
.admin-page #edit-log-teacher .ar-input2-learn2 input[type=search],
.admin-page #edit-log-teacher .ar-input3-learn3 input[type=search],
.admin-page #edit-log-teacher .ar-input4-learn4 input[type=search],
.admin-page #edit-log-teacher .ar-input5-learn5 input[type=search] {
    width: 90%;
}
.admin-page #edit-log-teacher .ar-quality-of-work.c1 {
    flex: 0 0 23%;
    text-align: left;
}
.admin-page #edit-log-teacher .ar-quality-of-work.c2 {
    flex: 0 0 24%;
    text-align: left;
}
.admin-page #edit-log-teacher .ar-quality-of-work.c3 {
    flex: 0 0 24%;
    text-align: left;
}
.admin-page #edit-log-teacher .ar-quality-of-work.c4 {
    flex: 0 0 12%;
    text-align: left;
}
.admin-page #edit-log-teacher .ar-quality-of-work.c5 {
    flex: 0 0 13%;
    flex: 0 0 15%;
    text-align: left;
	padding-left:5px;
}
.admin-page #complianceLog .select-dep {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width: 95%;
}
.admin-page #complianceLog .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #complianceLog .ar-single-search.search1 {
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search2 {
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search3 {
    flex: 0 0 9%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search4 {
    flex: 0 0 7%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search5 {
    flex: 0 0 12%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search6 {
    flex: 0 0 22%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search7 {
    flex: 0 0 17%;
    margin: 0;
}
.admin-page #complianceLog .ar-single-search.search8 {
    flex: 0 0 9%;
    margin: 0;
}

.admin-page #complianceLog .ar-input1-learn2 input[type=search],
.admin-page #complianceLog .ar-input2-learn2 input[type=search],
.admin-page #complianceLog .ar-input3-learn2 input[type=search], 
.admin-page #complianceLog .ar-input4-learn2 input[type=search], 
.admin-page #complianceLog .ar-input5-learn2 input[type=search], 
.admin-page #complianceLog .ar-input6-learn2 input[type=search], 
.admin-page #complianceLog .ar-input7-learn2 input[type=search], 
.admin-page #complianceLog .ar-input8-learn2 input[type=search] {
    width: 95%;
}
.admin-page #complianceLog .ar-student-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    flex: 0 0 13.5%;
    flex: 0 0 11%;
}
.admin-page #complianceLog .ar-student-quality .profile-section{
	margin:0;
}
.admin-page #complianceLog .ar-quality-of-work.c2 {
    width: unset;
    flex: 0 0 14%;
    padding-right: 10px;
}
.admin-page #complianceLog .ar-quality-of-work.c3 {
    width: unset;
    flex: 0 0 8%;
    padding-left: 5px;
}
.admin-page #complianceLog .ar-quality-of-work.c4 {
    width: unset;
    flex: 0 0 7%;
    padding-left: 5px;
}
.admin-page #complianceLog .ar-quality-of-work.c5 {
    width: unset;
    flex: 0 0 11%;
    padding-left: 5px;
}
.admin-page #complianceLog .ar-quality-of-work.c6 {
    width: unset;
    flex: 0 0 21%;
    flex: 0 0 23%;
    padding-left: 10px;
}
.admin-page #complianceLog .ar-quality-of-work.c7 {
    width: unset;
    flex: 0 0 16%;
    padding-left: 0px;
}
.admin-page #complianceLog .ar-quality-of-work.c8 a{
	font-size:13px;
	font-weight:300;
}
.admin-page #autoPromote .myclassroom-single-right-right-content .dropdown .btn-add-new {
    background: #0078bf;
    color: #fff!important;
	position: relative;
    transition: .3s;
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #autoPromote .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #autoPromote .ar-single-search.search1 {
    flex: 0 0 45%;
    margin: 0;
}
.admin-page #autoPromote .ar-single-search.search2 {
    flex: 0 0 25%;
    margin: 0;
}
.admin-page #autoPromote .ar-single-search.search3 {
    flex: 0 0 25%;
    margin: 0;
}
.admin-page #autoPromote .ar-input1-learn2 input[type=search], 
.admin-page #autoPromote .ar-input2-learn2 input[type=search], 
.admin-page #autoPromote .ar-input3-learn2 input[type=search]{
    width: 95%;
}
.admin-page #autoPromote .ar-student-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    flex: 0 0 47%;
}
.admin-page #autoPromote .ar-quality-of-work.c2 {
    width: unset;
    flex: 0 0 25%;
    padding-left: 15px;
}
.admin-page #autoPromote .ar-quality-of-work.c3 {
    width: unset;
    flex: 0 0 25%;
    padding-left: 15px;
}
.admin-page #attendance-page .single-attendance-header-present-content {
    flex: 0 0 16.5%!important;
}
.admin-page #poup-parent .request-edit-student-main-content {
    background: transparent;
}
.admin-page #poup-parent .tab-content.new-class-add-last-website #edit-student .request-enrollment-main-content {
    max-width: 90%;
    width: 100%;
}
.admin-page #poup-parent .single-change-password-right-input label {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    text-align:right;
    text-transform: capitalize;
    padding-top: 5px;
}
#edit-grade form #levelist {
    border-color: #333;
    height: calc(1.8em + 0.75rem + 2px);
	    color: hsl(0, 0%, 20%);
		font-size:13px;font-weight:300;
}
#edit-grade form #creditsList {
    border-color: #333;
    height: calc(1.8em + 0.75rem + 2px);
	    color: hsl(0, 0%, 20%);
		font-size:13px;font-weight:300;
}
#edit-grade form #grade_level {
    border-color: #333;
    height: calc(1.8em + 0.75rem + 2px);
	    color: hsl(0, 0%, 20%);
		font-size:13px;font-weight:300;
}
#edit-grade form #staus_list {
    border-color: #333;
    height: calc(1.8em + 0.75rem + 2px);
	    color: hsl(0, 0%, 20%);
		font-size:13px;font-weight:300;
}
.admin-page #edit-grade .request-enrollment-main-content{
	padding-top:0px;
}
.admin-page #home .body-porlet {
    height: 910px;
    height: 400px!important;
}
.admin-page #home .scoll-home{
	min-height: 50px!important;
    height: inherit!important;
    overflow-y: auto!important;
}
.admin-page #parents .addnew {
    padding: 6px 16px;
}
.admin-page #parents .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #parents .ar-single-search.search1 {
    flex: 0 0 25%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search2 {
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search3 {
    flex: 0 0 16%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search4 {
    flex: 0 0 15%;
    flex: 0 0 12%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search5 {
    flex: 0 0 6%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search6 {
    flex: 0 0 6%;
    margin: 0;
}
#parents form select.form-control {
    border-color: #a6adb1;
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 0;
    padding-left: 5px;
    padding-right: 0;
}
.admin-page #parents .ar-single-search.search8 {
    flex: 0 0 10%;
    margin: 0;
}
.admin-page #parents .ar-single-search.search7 {
    flex: 0 0 8%;
    margin: 0;
}
.admin-page #parents .ar-input1-learn2 input[type=search], .admin-page #parents .ar-input2-learn2 input[type=search], .admin-page #parents .ar-input3-learn2 input[type=search], .admin-page #parents .ar-input4-learn2 input[type=search], .admin-page #parents .ar-input5-learn2 input[type=search], .admin-page #parents .ar-input6-learn2 input[type=search] {
    width: 95%;
}
.admin-page #parents .ar-student-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: unset;
    padding: 0 0 0 20px;
    flex: 0 0 28%;
}

.admin-page #parents .ar-quality-of-work.c2 {
    width: unset;
    flex: 0 0 14%;
    padding-left: 5px;
}
.admin-page #parents .ar-quality-of-work.c3 {
    width: unset;
    flex: 0 0 14%;
    flex: 0 0 15%;
    padding-left: 5px;
}
.admin-page #parents .ar-quality-of-work.c4 {
    width: unset;
    flex: 0 0 14%;
    flex: 0 0 11%;
    padding-left: 5px;
}
.admin-page #parents .ar-quality-of-work.c5 {
    width: unset;
    flex: 0 0 12%;
    flex: 0 0 5%;
    padding-left: 5px;
}
.admin-page #parents .ar-quality-of-work.c6 {
    width: unset;
    flex: 0 0 12%;
    flex: 0 0 5%;
    padding-left: 5px;
}
.admin-page #parents .ar-quality-of-work.c8 {
    flex: 0 0 10%;
    padding-left: 10px;
}
.admin-page #parents .ar-quality-of-work.c7 {
    width: unset;
    flex: 0 0 8%;
    padding-left: 0;
}
.admin-page .ar-quality-of-work.c7 p, .admin-page .ar-quality-of-work.c5 p, .admin-page .ar-quality-of-work.c6 p, .admin-page .ar-quality-of-work.c3 p, .admin-page .ar-quality-of-work.c4 p{
	font-size:12px;
	font-weight:400;
}
.admin-page #order-edit-all-grades .ar-single-department-course {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 8px;
}
.admin-page #order-edit-all-grades .single-change-password-right-input {
    padding-bottom: 0;
}
.admin-page #order-edit-all-grades .single-change-password-right-input input {
	font-size:12px;
}
.admin-page #parents .ar-student-quality .mu_icon_header_checkbox {
    margin-right: 1px;
}
.admin-page #parents .dc_dots_area .down-icon img{
	right:-8px;
}
.admin-page #parents .profile-right-section-content span{
	text-transform: initial;
}
.add-school #div-footet-save {
    display: flex;
    justify-content: flex-end;
}
.background-ct{
	background: #fff;
    padding: 15px;
    box-shadow: 0 0 10px #e4e6ef;
    border-radius: 5px;
}
.title-us{
	color: #578ebe !important;
    font-size: 16px;
}
.line-us{
	border-top:1px solid #ddd;
	margin:10px 0;
}
.txt-us{
	font-size:14px;
	margin-bottom: 10px;
}
.txt-us-bd{
	font-size:14px;
	font-weight:600;
	margin-bottom: 10px;
}
.div-head-us{
	    display: flex;
    justify-content: space-between;
}
.mgt8{
	margin-top:8px;
}
#student-enroll-to-sec .css-yk16xz-control{
	border-color:#3F4254;
}
.admin-page #student-enroll-to-sec .react-datepicker__input-container input{
	line-height:25px;
}
.admin-page #student-enroll-to-sec .request-enrollment-main-content {
    max-width: 100%!important;
    width: 100%;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search1.new-add {
    flex: 0 0 10%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search2.new-add {
    flex: 0 0 20%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search3.new-add {
    flex: 0 0 20%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search4.new-add {
    flex: 0 0 13%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search5.new-add {
    flex: 0 0 13%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search6.new-add {
    flex: 0 0 13%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .ar-single-search.search7.new-add {
    flex: 0 0 11%;
    margin-right: 0;
}
.admin-page #student-enroll-to-sec #student-enroll .student-listing-single-content.c1 {
    flex: 0 0 10%;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c2 {
    flex: 0 0 20%;text-align:left;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c3 {
    flex: 0 0 20%;text-align:left;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c4 {
    flex: 0 0 13%;text-align:left;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c5 {
    flex: 0 0 13%;text-align:left;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c6 {
    flex: 0 0 13%;text-align:left;
}
.admin-page #student-enroll-to-sec #student-enroll .student-grade-lavel-single-content.c7 {
    flex: 0 0 11%;
}
.activeheader{
	font-weight:600;
	    color: #0078bf;
	    padding: 0 4px;
    border-radius: 5px;
}
#report-center .mu_search_bar a {
    font-size: 14px;
    position: relative;
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
.admin-page #report-center .ra_process_tracking_area_header {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}
.admin-page  #report-center .mu_dropdown_menu_area {
    background: #F3F6F9;
    margin-top: 20px;
}
.admin-page  #report-center .rpt-ct{
	padding:10px;
	background:#fff;
	border-radius:2px;
	display: flex;
	margin: 5px 0;
}
.admin-page  #report-center .right-rprt{
	margin-left:10px;
}
.admin-page  #report-center .right-rprt p{
	font-size:13px;
}
.admin-page  #report-center .right-rprt a{
	text-decoration: underline;
}
.admin-page #report-center .caption-head{
	font-size: 18px;
    margin-bottom: 10px;
}
.admin-page #disfle{
	display: flex;
    justify-content: space-between;
}
.admin-page #div-action .dropdown button {
    margin-left: 8px;
	display:flex;
}
.admin-page #student-invoice .ar-single-search.search1 {
    flex:0 0 10%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search2 {
    flex:0 0 10%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search3 {
    flex:0 0 12%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search4 {
    flex:0 0 18%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search5 {
    flex:0 0 8%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search6 {
    flex:0 0 8%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search7 {
    flex:0 0 10%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search8 {
    flex:0 0 10%;margin:0;
}
.admin-page #student-invoice .ar-single-search.search9 {
    flex:0 0 10%;margin:0;
}

.admin-page #student-invoice .ar-input1 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input2 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input3 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input4 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input5 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input6 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input7 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input8 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-input9 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-invoice .ar-single-department-course {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 10px;
}

.admin-page #student-invoice .stu-dept {
    flex: 0 0 10%;
}
.admin-page #student-invoice .cote-grade.txt2 {
    flex: 0 0 10%;
}
.admin-page #student-invoice .cote-grade.txt3 {
    flex: 0 0 12%;
}
.admin-page #student-invoice .cote-grade.txt4 {
    flex: 0 0 18%;
}
.admin-page #student-invoice .cote-grade.txt5 {
    flex: 0 0 8%;
}
.admin-page #student-invoice .cote-grade.txt6 {
    flex: 0 0 8%;
}
.admin-page #student-invoice .cote-grade.txt7 {
    flex: 0 0 10%;
}
.admin-page #student-invoice .cote-grade.txt8 {
    flex: 0 0 10%;
}
.admin-page #student-invoice .cote-grade.txt9 {
    flex: 0 0 10%;
}

.admin-page #student-invoice .cote-grade p {
    font-size:12px;
	font-weight:300;
}
.admin-page #student-invoice .ra_event_right_btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    margin-top: -44px;
    width: 70%;
    float: right;
}
.admin-page #student-invoice #view-togge {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #student-payment-his .ar-single-search.search1 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-his .ar-single-search.search2 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-his .ar-single-search.search3 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-his .ar-single-search.search4 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-his .ar-single-search.search5 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-his .ar-input1 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-his .ar-input2 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-his .ar-input3 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-his .ar-input4 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-his .ar-input5 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-his .ar-search-wrapper{
	width:100%;
}
.admin-page #student-payment-his .ar-single-department-course {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 10px;
}

.admin-page #student-payment-his .stu-dept {
    flex: 0 0 20%;
}
.admin-page #student-payment-his .cote-grade.txt2 {
    flex: 0 0 20%;
}
.admin-page #student-payment-his .cote-grade.txt3 {
    flex: 0 0 20%;
}
.admin-page #student-payment-his .cote-grade.txt4 {
    flex: 0 0 20%;
}
.admin-page #student-payment-his .cote-grade.txt5 {
    flex: 0 0 20%;
}
.admin-page #student-payment-his .cote-grade p, .admin-page #student-payment-his .stu-dept p{
    font-size:14px;
	font-weight:300;
}
.admin-page #student-payment-his .ra_event_right_btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    margin-top: -44px;
    width: 70%;
    float: right;
}
.scoll-requset{
	/*min-height: 500px!important;
	min-height: 910px!important;
    height: inherit!important;
    max-height: 910px!important;
    max-height: 919px!important;*/
	min-height: 50px!important;
    height: inherit!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
/*.scoll-requset::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.scoll-requset::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
}
.scoll-requset::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
    margin: 0 30px;
}*/
.txalr{
	text-align:right;
}
.admin-page #student-payment-app .form-control{
	border:1px solid #3F4254;
}
.admin-page #student-payment-app select.form-control{
	height:34px;
}
.admin-page #student-payment-app .single-change-password-right-input .lbl{
	padding-top:8px;
}
.admin-page #student-payment-app #div-footet-save {
    display: flex;
    justify-content: flex-start;
}
.admin-page #student-payment-app .ar-filter-all-wrapper {
    display: flex;
    padding: 5px 20px 5px 20px;
    margin-top: 1px;
}
.admin-page #student-payment-app .ar-single-search.search1 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-app .ar-single-search.search2 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-app .ar-single-search.search3 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-app .ar-single-search.search4 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-app .ar-single-search.search5 {
    flex:0 0 20%;margin:0;
}
.admin-page #student-payment-app .ar-input1 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-app .ar-input2 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-app .ar-input3 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-app .ar-input4 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-app .ar-input5 input {
    width:95%;
	max-width:95%;
}
.admin-page #student-payment-app .ar-search-wrapper{
	width:100%;
}
.admin-page #student-payment-app .ar-single-department-course {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 5px;
}

.admin-page #student-payment-app .stu-dept {
    flex: 0 0 18%;
}
.admin-page #student-payment-app .cote-grade.txt2 {
    flex: 0 0 19%;
}
.admin-page #student-payment-app .cote-grade.txt3 {
    flex: 0 0 19%;
}
.admin-page #student-payment-app .cote-grade.txt4 {
    flex: 0 0 19%;
}
.admin-page #student-payment-app .cote-grade.txt5 {
    flex: 0 0 20%;
}
.admin-page #student-payment-app .cote-grade p, .admin-page #student-payment-app .stu-dept p{
    font-size:14px;
	font-weight:300;
}
.admin-page #student-payment-app .ra_event_right_btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    margin-top: -44px;
    width: 70%;
    float: right;
}
.admin-page #student-contracts .ra_event_right_btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    margin-top: -44px;
    width: 70%;
    float: right;
}
.admin-page #student-contracts #view-togge {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #student-contracts .ar-single-search.search1{
    flex: 0 0 16%;
    margin: 0;
}
.admin-page #student-contracts .ar-single-search.search2{
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #student-contracts .ar-single-search.search3{
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #student-contracts .ar-single-search.search4{
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #student-contracts .ar-single-search.search5{
    flex: 0 0 15%;
    margin: 0;
}
.admin-page #student-contracts .ar-single-search.search6{
    flex: 0 0 15%;
    margin: 0;
}

.admin-page #student-contracts .ar-input1 input, .admin-page #student-contracts .ar-input2 input, .admin-page #student-contracts .ar-input3 input, .admin-page #student-contracts .ar-input4 input, .admin-page #student-contracts .ar-input5 input, .admin-page #student-contracts .ar-input6 input {
    width: 95%;
    max-width: 95%;
}
.admin-page #student-contracts .ar-filter-all-wrapper,.admin-page #student-contracts .ar-search-wrapper{
	width:100%;
}
.admin-page #student-contracts .ar-single-department-course {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 10px;
}
.admin-page #student-contracts .stu-dept{
    flex: 0 0 16%;margin:0;
}
.admin-page #student-contracts .cote-grade.txt2 {
    flex: 0 0 20%;margin:0;padding-left:5px;
}
.admin-page #student-contracts .cote-grade.txt3 {
    flex: 0 0 15%;margin:0;padding-left:5px;
}
.admin-page #student-contracts .cote-grade.txt4 {
    flex: 0 0 15%;margin:0;padding-left:5px;
}
.admin-page #student-contracts .cote-grade.txt5 {
    flex: 0 0 15%;margin:0;padding-left:5px;
}
.admin-page #student-contracts .cote-grade.txt6 {
    flex: 0 0 15%;margin:0;padding-left:5px;
}
.admin-page #student-contracts .student-name span, .admin-page #student-contracts .cote-grade p {
    color: #3F4254;
    font-size: 13px;
    font-family: 'Avenir';
    font-weight: 300;
}
.admin-page #edit-contract .request-enrollment-main-content{
	padding-top:0;
}
.admin-page .tit-rep{
	font-size:30px;
	text-align:center;
	margin-bottom:20px;
}
.btn-printf {
    font-weight: 400;
    font-size: 12px!important;
    color: #0078bf;
    border: 1px solid #0078bf;
    padding: 4px 15px!important;
    border-radius: 25px;
    position: relative;
    transition: .3s;
}
.di-ct-rep-progress{
	 border: 1px solid;
	 padding:0px 15px 24px 15px;
	 margin-bottom:40px;
	 min-height:720px;
	 min-height:690px;
	 min-height:670px;

}
.ct2-rep-progress{
	border-top: 1px solid; border-bottom: 1px solid; margin: 4px 1px 14px 1px;
}
.ct-ref-pro-grad{
	border: solid 1px #c3c3c3; padding: 15px 0px 23px 20px;
}
textarea.cment-rep {
    height: auto;
    min-height: 160px;
	border: solid 1px #c3c3c3;
	border-radius:0;
}
#report-progress{
	display: block;
    background: #ffffff;
    min-height: 900px;
	    padding-bottom: 10px;
}
.ic-title{
	font-weight: 600;
    padding: 0 10px;
    width: 1.25em;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
}
.txt-la-fil{
	font-weight:600!important;
	padding-top:7px;
}
#report-progress .css-yk16xz-control, .select-rep{
	border-color: #181c32!important;
}
.admin-page .btn-download-rpt {
	    font-weight: 400;
    font-size: 12px;
    color: #0078bf;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
    background-color: #61b943!important;
    border-color: #ddd!important;
    color: #fff;
}
.admin-page .btn-download-rpt:hover{
    color: #61b943!important;
    background: transparent!important;
	 border: 1px solid #61b943!important;
}
#report-application{
	display: block;
    background: #ffffff;
    min-height: 900px;
	    padding-bottom: 10px;
}
.admin-page #report-application .btn-print {
    font-weight: 400;
    font-size: 12px;
    color: #0078bf!important;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
	background: transparent!important;
}
.admin-page #report-application .btn-print:hover{
    color: #fff!important;
    background: #0078bf!important;
	 border: 1px solid #61b943!important;
}
#report-application .css-yk16xz-control{
	border-color: #181c32!important;
}
#report-application .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
	border: 1px solid #ddd!important;
}
#table-simulation>tbody>tr>td, #table-simulation>tbody>tr>th, #table-simulation>tfoot>tr>td, #table-simulation>tfoot>tr>th, #table-simulation>thead>tr>td, #table-simulation>thead>tr>th {
   /* border: 0!important;
    color: #2a77a9;    */
    font-weight: 400;
    font-size: 13px;
}
.row-si {
    background: #f6f6f6;
}
.row-thead {
   background: #dadada;
}

#table-simulation>thead>tr>th.f400{
	font-weight:700;
}
#report-teacher .txt-hi,#report-teacher .input-date{
	height:37px;
}
#report-teacher .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
	border: 1px solid #ddd!important;
}
#report-teacher{
	display: block;
    background: #ffffff;
    min-height: 900px;
	    padding-bottom: 10px;
}
.admin-page #report-teacher .btn-print {
    font-weight: 400;
    font-size: 12px;
    color: #0078bf!important;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
	background: transparent!important;
}
.admin-page #report-teacher .btn-print:hover{
    color: #fff!important;
    background: #0078bf!important;
	 border: 1px solid #61b943!important;
}
#report-teacher .css-yk16xz-control{
	border-color: #181c32!important;
}
#report-teacher .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
	border: 1px solid #ddd!important;
}
.admin-page #message .seach-dis .dropdown-menu.dropdown-menu {
    transform: translate(0px, 28px)!important;
    width: 280px;
}
.admin-page #message .seach-dis .dropdown-menu.dropdown-menu .custom_checkbox_area label span{
	width:110px!important;
}
.admin-page #message .text-editor {
  width: 100%;
  margin: 0 auto;
}
.admin-page #message .ql-editor {
  min-height: 300px;
}
.admin-page #message .label-inline.mr-2{
	color:blue;
	font-size:13px;
}
.admin-page .inbox-message-left-header h1>a {
    font-weight: 300;
    font-size: 18px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
}
.admin-page .single-inbox-messages-content a.color {
    font-weight: 500;
    font-size: 12px;
    color: #3e3f42;
    text-transform: inherit;
}
.admin-page .single-inbox-messages-content a.color:hover{
	/*text-decoration:underline;*/
	color:#546cb2;
}
.inbox-messages-content .single-inbox-messages-content.unread a.color{
	font-weight:600;
}
.admin-page #home .single-top-progress-area{
	flex: 0 0 32%!important;
}
.admin-page .dc_dots_area .dropdown-menu a.it-dot{
	padding:10px 15px;
}
.cursorter{
	cursor:pointer;
}
.cursorter:hover{
	background: #c2dbff42;
	background: #b2d8ef26;
}

#report-parent.txt-hi,#report-parent .input-date{
	height:37px;
}
#report-parent .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
	border: 1px solid #ddd!important;
}
#report-parent{
	display: block;
    background: #ffffff;
    min-height: 900px;
	    padding-bottom: 10px;
}
.admin-page #report-parent .btn-print {
    font-weight: 400;
    font-size: 12px;
    color: #0078bf!important;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
	background: transparent!important;
}
.admin-page #report-parent .btn-print:hover{
    color: #fff!important;
    background: #0078bf!important;
	 border: 1px solid #61b943!important;
}
#report-parent .css-yk16xz-control{
	border-color: #181c32!important;
}
#report-parent .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
	border: 1px solid #ddd!important;
}

.admin-page .mu_alert_box_left p.aler{
    font-size: 14px;
    color: #0078bfc7;
}
.admin-page #course .ar-filter-all-wrapper {
    display: flex;
    padding: 10px 20px 13px 10px;
    margin-top: 1px;
}
.dropdown-scroll {
    height: 600px;
    overflow: auto;
    display: block!important;
	overflow-x: hidden;
}
.admin-page .learning-top-enviroment-text .dropdown-item.width-two,
.admin-page .attendance-header-content .dropdown-item.width-two, {
    width: auto;
}








/*-------- hide trial chart-------*/
svg > g[class^="raphael-group-"] > text{
    display: none;
}
g[class$='creditgroup'] {
         display:none !important;
    }
 g[class^='raphael-group-'][class$='-creditgroup'] {
         display:none !important;
    }
	[pointer-events="bounding-box"] {
    display: none
}
div[id^='fc-'] > span > svg > defs + g > g:nth-child(26) { display:none !important; }

   /*=======PRINT======*/
@media print {
	#divcontents{
			display: block!important;
			visibility: visible;
			width:100%;
			overflow: visible
		}
	.di-ct-rep-progress {		
		min-height: 640px;
	}
	.table {
		/*border: 1px solid #ddd!important;:first-child*/
	}
	.page-breek{
		/*page-break-after: always
		clear: both;;*/
		page-break-before: always!important;
		
	}
	.page-breek1{
		/*page-break-after: always;
		clear: both;*/
		page-break-after: auto!important;
		
	}
	
	.page-break-prin{
		clear: both;
		page-break-after: always!important;
	}
	
	.table-responsive{
		overflow-x:visible!important;visibility: visible;overflow: visible;font-size:7px!important; 
	}
	#report-parent #table-simulation>tbody>tr>td, 
	#report-parent #table-simulation>tbody>tr>th,
	#report-parent #table-simulation>tfoot>tr>td,
	#report-parent #table-simulation>tfoot>tr>th,
	#report-parent #table-simulation>thead>tr>td,
	#report-parent #table-simulation>thead>tr>th {
		font-weight: 400;
		font-size: 9px!important;
	}
}
.admin-page #course-detail .left_infor_area .infor_profile img{
	border-radius:5px!important;
}
.admin-page #course-detail .student-information-single-info p.f6{
	font-weight:600;
}
.admin-page #course-detail .student-information-text .student-information-single-text:nth-child(2) {
    padding-top: 0;
}
.admin-page #course-detail .head-sec{
	margin: 15px 0;
    display: flex;
    justify-content: space-between;
}
.admin-page .learning-path-templte .brd-radius .bg-success.backg-com{
	/*border-radius:10px;*/
}
.admin-page .learning-path-templte .ju_pro_heading_area ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;
    padding: 20px;
    padding-right: 50px;
}
.admin-page .learning-path-templte .total-credit{
	font-size:13px;
}
.admin-page .learning-path-templte .ju_first.text_dft{
	display:block;
	text-align:center;
}
.admin-page .learning-path-templte .ju_first.text-center.text_dft{
	display:block;
	font-weight:600;
}
.admin-page .teacher-detail-schedule .mu_search_bar form {
    display: flex;
    justify-content: flex-start;
}
.admin-page .teacher-detail-schedule .ar-ra-search-bar {
    max-width: 70% !important;
    width: 100% !important;
}
.header-up-cal{
	display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.admin-page .teacher-detail-schedule .ra_schedule_calender_area.upcoming-area {
    background-color: #f3f6f9;
    padding-bottom: 0;
    padding-top: 28px;
}
.admin-page #course-detail .dropdown-item.width-two {
    width: 223px;
    width: auto;
    display: block;
    padding: 10px;
}
.ct-alert{
	/*border-top:1px solid #ddd;*/
	padding: 15px;
	margin-right:40px;
	margin-top:20px;
	background: aliceblue;
}
.btn-anoder{
	font-size: 14px;
    color: #0078BF;
    border: 1px solid #dbe6ee;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;
    /* color: #333333; */
    background-color: #E5E5E5;
}
.admin-page .ct-alert .single-change-password-right-input {
    padding-bottom: 0;
}
.admin-page #prerequisite .ar-single-search.search1.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.admin-page #prerequisite .ar-single-search.search2.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.admin-page #prerequisite .ar-single-search.search3.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.admin-page #prerequisite .ar-search_box_area.ar-input1.new-add input[type=search] {
    max-width: 95%;
    width: 100%;
}
.admin-page #prerequisite .ar-search_box_area.ar-input2.new-add input[type=search] {
    max-width: 95%;
    width: 100%;
}
.admin-page #prerequisite .ar-search_box_area.ar-input3.new-add input[type=search] {
    max-width: 95%;
    width: 100%;
}
.admin-page #prerequisite .student-listing-single-content {
    flex: 0 0 30%;text-align:left;padding-left:15px;
}

.admin-page #prerequisite .student-grade-lavel-single-content.c2 {
    flex: 0 0 30%;text-align:left;padding-left:15px;
}
.admin-page #prerequisite .student-grade-lavel-single-content.c3 {
    flex: 0 0 30%;
	text-align:left;
	padding-left:15px;
}
span.bage {
    background: #05233B;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 9px;
    color: #FFF;
    border-radius: 12px;
    margin-left: 8px;
}
a.uncheck{
	margin-left: 30px;
    color: #0056b3!important;
}
.admin-page .prfile-tea img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
	margin-right:10px;
}
.admin-page .prfile-tea{
	margin-left:15px;
	padding:0px;
}
.admin-page .prfile-tea .avatar-title.sb-avatar.sb-avatar--text{
	margin-right:10px;
}
.se-tit-tea{
	padding: 15px 0px;
    display: flex;
    justify-content: space-between;
}
.admin-page #course-detail .student-information-single-info a{
	font-size:12px;
	font-weight:600;
	display: block;
}
.admin-page #course-detail #table-simulation>tbody>tr>td,.admin-page #course-detail #table-simulation>tfoot>tr>td, #table-simulation>tfoot>tr>th{   
    border: 0.5px solid #ddd;
}
.admin-page #course-detail #table-simulation .txt-id-stu{
	height: 28px;
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}
.admin-page #course-detail .btn-print {
    font-weight: 400;
    font-size: 12px;
    color: #0078bf!important;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
    background: transparent!important;
}
.admin-page #course-detail .div-foot{
	display: flex;
    justify-content: space-between;
}
.admin-page #course-detail .btn-printf{
	margin-left:10px;width:70px;
}
.admin-page #course-detail .btn-print:hover{
	background: #ddd!important;
}
.admin-page #parents .select-dep {
    width: 95%;
	background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #applications .select-dep {
    width: 95%;
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #resource .select-deptt {
    width: 100%;
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}

.admin-page .list_document_resource_main .dc_single_items{
	padding:17px 10px 25px;
}
.ct-rp-tran{
	width:100%; border-top: 1px solid; padding:0px 15px 24px 15px; margin-bottom:40px;
}
.logo-in-rp-tran{
	max-height: 105px;padding-top: 5px; padding-bottom: 5px;padding-right: 10px;
}
.row-ct-rp-tran{
	border-top: 0px solid; border-bottom: 0px solid; margin: 4px 1px 14px 1px;
}
.img-use-ov{
	display: block;
    max-width: 100%;
    height: auto;
}
.inv-rpo-di{
	width:100%; border: 1px solid; padding:0px 15px 24px 15px;  margin-bottom:40px;min-height:720px;
}
.inv-rpo-di #logo-default{
	max-height: 105px;padding-top: 5px; padding-bottom: 5px;padding-right: 10px;
}
.ro-in-rein{
	border-top: 1px solid; border-bottom: 1px solid; margin: 4px 1px 14px 1px; padding: 5px;
}
.text-red{
	color:red;
}
.text-success {
    color: #45B6AF;
}
.admin-page .custom_alert_ero{
	background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}
.admin-page .custom_alert_ero p.aler {
    font-size: 14px;
    color: #a94442;
}
.admin-page #sections-enrol .table-responsive .mu_alert_box_left{
	display:block;
}
.admin-page .btn-uploads {
    font-weight: 400;
    font-size: 14px;
    color: #0078bf!important;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
    background: transparent!important;
}
.admin-page .btn-uploads img {
    vertical-align: sub;
    border-style: none;
}
.btn-do-uplad {
    font-weight: 400;
    font-size: 14px;
    color: #0078bf;
    border: 1px solid #0078bf;
    padding: 4px 15px;
    border-radius: 25px;
    position: relative;
    transition: .3s;
	margin-left:15px;
}
.admin-page span.txt-spa {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-bottom: 5px;
	margin-left:5px;
}
.admin-page a.txt-spa {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-bottom: 5px;
}
 .ra_upcoming_heading h4 {
    font-weight: 300;
    color: #05233b;
}
.admin-page .table-up>tbody>tr>td {
    border: 0.5px solid #ddd;
}
.icon-remove{
	color:red;
	cursor:pointer;
	margin-right:4px;
}
.table-up>tbody>tr.danger:hover>td, .table-up>tbody>tr.danger:hover>th, .table-up>tbody>tr:hover>.danger, .table-up>tbody>tr>td.danger:hover, .table-up>tbody>tr>th.danger:hover {
    background-color: #ebcccc;
}
.table-up > tbody > tr > td.danger:hover, .table-up > tbody > tr > th.danger:hover, .table-up > tbody > tr.danger:hover > td, .table-up > tbody > tr.danger:hover > th {
    background: #ebcccc;
}
.table-up > thead > tr > td.danger, .table-up > thead > tr > th.danger, .table-up > thead > tr.danger > td, .table-up > thead > tr.danger > th, .table-up > tbody > tr > td.danger, .table-up > tbody > tr > th.danger, .table-up > tbody > tr.danger > td, .table-up > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table-up > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table-up > tfoot > tr.danger > th{
	background: #f2dede;
    color: #a94442;
}
.table-up> tbody > tr.success > td{
	background: #dff0d8;
    color: #3c763d;
}
.table-up> tbody > tr.success:hover > td{
    background: #d0e9c6;
}
.admin-page #add-prerequisite .single-change-password-right-input label{
	padding-top:5px;
}
.txt-se-co{
	    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
}
.css-yk16xz-control{
	border-color: #333!important;
}
.di-foo-but{
	display: flex;
    justify-content: space-between;
}
#parents .profile-section{
	cursor:pointer;
}
.btn-back{
	color: #3f4254!important;
    background: #efefef!important;
    border-color: #e7e4e4!important;
    padding: 4px 15px;
    border-radius: 15px;
    border: 1px solid #ddd;
}
.admin-page .inbox-message-left-header a:hover{
	text-decoration: underline;
}
.admin-page #parents .profile-section a:hover {
    color: #0056b3!important;
    text-decoration: underline;
}
.admin-page #parents .profile-section a {
    color: #007bff!important;
}
.admin-page .logo-sch .text-truncate {
    margin-right: 50px!important;
    margin-right: 40px!important;
    max-width: 355px;
    max-width: 275px;
}
.header-top-left-content .text-truncate{
	margin: 1rem 0.55rem!important;
}
.admin-page #teacers .mu-form-group label::before, .admin-page #student .mu-form-group label::before {
    content: '';
    -webkit-appearance: none;
    background-color: #F6F7F9;
    border: 1px solid #6c757d;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 3px;
}
.admin-page  #student .ar-filter-all-wrapper {
    display: flex;
    padding: 10px 15px 13px 10px;
    margin-top: 1px;
}
.admin-page #student .ar-single-search.search0 {
    margin-right: 10px;
    flex: 0 0 4%;
}
.admin-page #form-add-teacher .student-information-heading-profile {
    position: relative;
    width: auto;
    height: auto;
}
.admin-page #form-add-teacher .student-information-left-heading-image {
    display: block;
}
.admin-page #form-add-teacher .student-information-heading-profile .avartar_st {
    width: 100px!important;
    height: 100px!important;
    border-radius: 100%;
    border: 2px solid #fff;
}
#userDirectory form .form-control {
    border-color: #a6adb1;
    height: 34px;
}
.admin-page #student-in-teacher .dc_dots_area .dropdown-menu.menu-vi-mo {
    left: -100px!important;
}
.admin-page #course-detail .dc_dots_area .dropdown-menu.menu-vi-mo {
    left: -100px!important;
}

.admin-page #student .head-sec{
	margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.admin-page #course-detail .profile-section a:hover {
    color: #0056b3!important;
    text-decoration: underline;
}
.admin-page #course-detail .profile-section a {
    color: #007bff!important;
}
.react-datepicker__month-container button.btn-month {
    padding: 1px 6px;
    border: 1px solid rgb(118, 118, 118);
    outline: inherit;
	border-radius: 3px;
}
.admin-page .btn-saves {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    order: ;
    border: 1px solid #0078BF;
    transition: .3s;
}
.admin-page #activity-gradebook .all-same-button-design {
    padding-top: 10px;
}
.mgt7{
	margin-top:7px;
}
.txt-bold{
	font-size: 17px;
    font-weight: 700;
    margin: 10px 0;
}
#transfer-bulk select{
	font-size:13px;
}
.admin-page #poup-parent .single-change-password-left-input label {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    text-align: left;
    text-transform: capitalize;
    padding-top: 5px;
}
.admin-page  #form-edit-enroll .single-change-password-right-input label {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    text-align: left;
    text-transform: capitalize;
    padding-top: 5px;
}
.link-trial{
	color:#fff;
}
.admin-page #student-info .ra_sort_left_area_body {
    flex: 0 0 22%;
}
.admin-page #student-info .ra_sortable_certificate_main {
    flex: 0 0 18%;
}
.admin-page #student-info .profile-section{
	margin-left:0px;
}
.admin-page #student-info .ra_sortable_certificate_main2.s-new-class {
    flex: 0 0 53%;
}
.admin-page #student-info .ra_sortable_right_btn_area {
    flex: 0 0 5%;
}
#student-info  .ra_sortable_certificate_sub_item1 {
    flex: 0 0 16%;
}
#student-info .ra_sortable_certificate_sub_item2 {
    flex: 0 0 20%;
}
#student-info .ra_sortable_certificate_sub_item3 {
    flex: 0 0 16%;
}
#student-info .ra_sortable_certificate_sub_item4 {
    flex: 0 0 16%;
    text-align: center;
}
#student-info .ra_sortable_certificate_sub_item5 {
    flex: 0 0 20%;
}
#student-info .ra_sortable_certificate_sub_item6 {
    flex: 0 0 14%;
}
.admin-page #recipient-transript .single-change-password-right-input input{
	border: 1px solid #ced4da;
	padding: 7px 15px;
}
.admin-page .ra_process_tracking_area .pt_view_main_area.ihgdd{
	display: flex!important;
    justify-content: space-between!important;
	
}
.admin-page #student-merge .ar-single-search.search1.new-add {
    flex: 0 0 5%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search2.new-add {
    flex: 0 0 10%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search3.new-add {
    flex: 0 0 12%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search4.new-add {
    flex: 0 0 12%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search5.new-add {
    flex: 0 0 10%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search6.new-add {
    flex: 0 0 20%;
    flex: 0 0 18%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search7.new-add {
    flex: 0 0 11%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search8.new-add {
    flex: 0 0 15%;
    margin-right: 0;
}
.admin-page #student-merge .ar-single-search.search9.new-add {
    flex: 0 0 10%;
    margin-right: 0;
}
.txt-app-detail{
    font-weight: 300;
    font-size: 16px;
	margin-top:4px;
}
.admin-page #teacher-detail .select-dep {
    width: 95%;
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #teacher-detail  .select-status {
    background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 8px 0px 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    width: 90px;
}
.admin-page #teacher-detail #student .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search1{
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search1 input[type="search"] {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search2{
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search2 select {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search3{
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search3 select {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search4{
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search4 select {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search5{
    flex: 0 0 17%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search5 input[type="search"] {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search6{
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search6 input[type="search"] {
    max-width: 95%;
    width: 95%;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search7{
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #teacher-detail #student .ar-single-search-learn2.search7 select {
    max-width: 95%;
    width: 95%;
}

.admin-page #teacher-detail #student .ar-student-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    padding: 0px 0 0 10px;
	 flex: 0 0 23%;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c2 {
	flex: 0 0 14%;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c3 {
	flex: 0 0 10%;padding-left:15px;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c4 {
	flex: 0 0 9%;padding-left:15px;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c5 {
	flex: 0 0 17%;
	    overflow: hidden;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c5 p {
	overflow: hidden;
    text-overflow: ellipsis;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c6 {
	flex: 0 0 9%;
}
.admin-page #teacher-detail #student .ar-quality-of-work.c7 {
	flex: 0 0 10%;
}

.admin-page #teacher-detail #student .profile-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
}
.admin-page #teacher-detail #student .head-sec {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    margin-bottom: -10px;
}
.admin-page #teacher-detail #student .div-action .dropdown-menu.adjust {
    left: -20px!important;
    top: 7px!important;
    min-width: 190px;
}
.admin-page #teacher-detail #student .form-control{
	font-size:14px;
}
.admin-page #student-merge .mu_icon_header_checkbox {
    margin-right: 0;
    flex: 0 0 4.5%;
}
.admin-page #student-merge .student-listing-single-content.c1 {
    flex: 0 0 10%;
}
.admin-page #student-merge .student-grade-lavel-single-content.c2 {
    flex: 0 0 12.5%;padding-right:8px;text-align:left;
}
.admin-page #student-merge .student-grade-lavel-single-content.c3 {
    flex: 0 0 12%;text-align:left;
}
.admin-page #student-merge .student-grade-lavel-single-content.c4 {
    flex: 0 0 10%;text-align:left;
}
.admin-page #student-merge .student-grade-lavel-single-content.c5 {    
    flex: 0 0 20%;
    flex: 0 0 18%;
	text-align:left;padding-right:8px;
}
.admin-page #student-merge .student-status-text-contents.c6 {
    flex: 0 0 11%;text-align:left;
}
.admin-page #student-merge .student-status-text-contents.c7 {
    flex: 0 0 11%;text-align:left;padding-left:5px;
}
.admin-page #student-merge .student-status-text-contents.c8 {
    flex: 0 0 10%;text-align:left;
}
.admin-page #student-merge .student-status-text-contents.c6 p{
	word-break: break-all;
}
.admin-page #student-merge .ar-quality-of-work p{
	font-size:12px;
}
.admin-page #student-merge li{
	font-weight:500;
}
.admin-page #Rosters #roster .profile-right-section-content{
	cursor:pointer;
}
.admin-page #Rosters #roster .ar-dent-name a {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Avenir';
    padding-bottom: 5px;
}
.react-datepicker__month-container span.btn-month {
    padding: 1px 6px;
    border: 1px solid #767676;
    outline: inherit;
    border-radius: 3px;
	cursor:pointer;
}
#edit-student .form-control:disabled, #edit-student .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}
#userDirectory form select.form-control {
    border-color: #a6adb1;
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 0px;
    padding-left: 5px;
    padding-right: 0;
}
.admin-page #student-enroll-to-sec .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width: 95%;
    width: 95%;
}
.admin-page #student-enroll-to-sec .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width: 100%;
}
.reset-filter{
	margin-right: 10px;
    position: relative;
    color: #0078bf;
    font-size: 14px;
    font-weight: 300;
}
.reset-filter img{
	position: absolute;
    left: -20px;
    top: 2px;
}
.myclassroom-single-left-cross-progress .barra-nivel-weekly {
    background: #00A2A7;
}
.admin-page .div-action .dropdown button.state_ob {
    padding: 6px 18px;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 300;
    margin-left: 5px;
}
.admin-page #rosters .ra_process_tracking_area_header {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}
.admin-page #rosters .mu_dropdown_menu_area {
    margin-top: 15px;
}
.admin-page #rosters .select-status {
    background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 8px 0px 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    width: 95%;
}
.admin-page #rosters .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 22px;
}
.admin-page #rosters .ar-single-search.search1.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search2.new-add {
    flex: 0 0 14%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search3.new-add {
    flex: 0 0 13%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search4.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search5.new-add {
    flex: 0 0 5%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search6.new-add {
    flex: 0 0 17%;
    margin-right: 0px;
}
.admin-page #rosters .ar-single-search.search7.new-add {
    flex: 0 0 17%;
    margin-right: 0px;
}
.admin-page #rosters .ar-search_box_area.ar-input1.new-add input[type=search] {
    max-width: 95%;
    width: 95%;
}
.admin-page #rosters .ar-input2 input[type="search"] {
    width: 95%;max-width: 95%;
}
.admin-page #rosters .ar-input3 input[type="search"] {
    width: 95%;max-width: 95%;
}
.admin-page #rosters .student-listing-single-content {
    flex: 0 0 19%;    
}
.admin-page #rosters .dc_check_star {
    flex: 0 0 3%;
}
.admin-page #rosters .ar-filter-all-wrapper{
	padding:10px 10px 13px 10px;
}
.admin-page #rosters .student-grade-lavel-single-content.c2 {
    flex: 0 0 14%;
    text-align: left;
    padding-left: 10px;padding-right:5px;   
}
.admin-page #rosters .student-grade-lavel-single-content.c3 {
    flex: 0 0 13%;
    text-align: left;
    padding-left: 2px;padding-right:10px;    
}
.admin-page #rosters .student-status-text-contents.c4 {
    flex: 0 0 10%;
    text-align: left;
    padding-left: 0px;
}
.admin-page #rosters .student-status-text-contents.c5 {
    flex: 0 0 4.5%;
    text-align: left;
    padding-left: 0px;
}
.admin-page #rosters .student-status-text-contents.c6 {
    flex: 0 0 17.5%;
    text-align: left;
    padding-left: 0px;padding-right:12px;
}
.admin-page #rosters .student-status-text-contents.c7 {
    flex: 0 0 16.5%;
    text-align: left;
    padding-left: 0px;
}
.admin-page #rosters .ar-quality-of-work a.end {
    font-size: 12px;
    font-weight: 300;
    font-family: 'Avenir';
}
.admin-page #rosters .ar-quality-of-work p.end {
    font-size: 10px;
    font-weight: 300;
    font-family: 'Avenir';
}
.admin-page #rosters .student-three-dot-contents {
    flex: 0 0 2%;
    text-align: right;
}
.admin-page #rosters .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -7px;
    top: -7px;
    position: absolute;
}
.admin-page #rosters .myclassroom-single-left-cross-progress {
    width: 125px;
    height: 7px;
    background: #E4E6EF;
    border-radius: 5px;
}
.admin-page #rosters .course-progress-right {
    margin-left: 3px;
    margin-top: 14px;
}
.admin-page #rosters .myclassroom-single-left-content-course-progress p{
	font-size:9px;
	font-weight:600;
}
.admin-page #rosters .dc_dots_area .dropdown-menu.menu-vi-mo {
    left: -85px!important;min-width: 226px;
}
.admin-page .ar-quality-of-work .staff-sec-sec p {
    color: #3F4254;
    font-size: 12px;
    font-weight: 300;
}
.hre-sec{
	font-size: 12px;
    color: blue!important;
}
.comment-tips .tooltip-inner {
    max-width: 320px;
}
.admin-page span.text-id {
    color: #3F4254;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Avenir';
}
.admin-page .ar-dent-name a{
	font-size:14px;
	font-weight:300;
}
.admin-page .header-bottom-content ul li a, .header-bottom-content ul li button.menu-drop, #mu_accordion li .link a {
    padding: 18px 10px;   
	font-size: 14px;
}
.admin-page .header-bottom-content ul li:first-child a{
	padding-left:0;
}
.admin-page .header-bottom-content ul li:last-child a{
	padding-right:0;
}
.admin-page .header-bottom-content ul li a span{
    background: #145B94;
    font-weight: 300;
    font-size: 9px;
    color: #FFF;
    padding: 1px 5px;
    border-radius: 15px;
    width: 10px; 
    margin-left: 2px;
}
.admin-page #transfer-sec .ar-single-search.search1.new-add {
    flex: 0 0 5%;
    margin-right: 0;
}
.admin-page #transfer-sec .ar-single-search.search2.new-add {
    flex: 0 0 25%;
    margin-right: 0;
}
.admin-page #transfer-sec .ar-single-search.search3.new-add {
    flex: 0 0 15%;
    margin-right: 0;
}
.admin-page #transfer-sec .ar-single-search.search4.new-add {
    flex: 0 0 15%;
    margin-right: 0;
}
.admin-page #transfer-sec .ar-single-search.search5.new-add {
    flex: 0 0 10%;
    margin-right: 0;
}
.admin-page #transfer-sec .ar-single-search.search6.new-add {
    flex: 0 0 15%;
    margin-right: 0;
}

.admin-page #transfer-sec .ar-input1 input[type=search]{
    width: 95%;max-width:95%;
}
.admin-page #transfer-sec .ar-input2 input[type=search]{
    width: 95%;max-width:95%;
}
.admin-page #transfer-sec .ar-input3 input[type=search]{
    width: 95%;max-width:95%;
}
.admin-page #transfer-sec .ar-input4 input[type=search]{
    width: 95%;max-width:95%;
}
.admin-page #transfer-sec .select-dep {
    width: 95%;
}
.admin-page #transfer-sec .select-dep, .admin-page #transfer-sec .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
}
.admin-page #transfer-sec .mu_icon_header_checkbox {
    margin-right: 0;
    flex: 0 0 4.5%;
}
.admin-page #transfer-sec .text-1 {
    flex: 0 0 25%;
}
.admin-page #transfer-sec .stu-dept .student-sec {
    width: 97%;
}
.admin-page #transfer-sec .student-img {
    position: relative;
    padding-right: 5px;
    padding-left: 8px;
}
.admin-page #transfer-sec .student-grade-lavel-single-content.c2 {
    flex: 0 0 15%;
}
.admin-page #transfer-sec .student-grade-lavel-single-content.c3 {
    flex: 0 0 15%;
}
.admin-page #transfer-sec .text-4 {
    flex: 0 0 15%;
}
.admin-page #transfer-sec .student-grade-lavel-single-content.c5 {
    flex: 0 0 10%;padding-left:10px;
}
.admin-page #transfer-sec .student-status-text-contents.c6 {
    flex: 0 0 15%;padding-left:30px;
}

.admin-page #transfer-sec .ar-quality-of-work p {
   color: #3f4254;
    font-size: 12px;
    font-weight: 300;
	text-align:left;
}
.admin-page #transfer-sec .staff-sec {
    padding-top: 0px;
    padding-left: 0px;
    width: 96%;
}
.form-control.period{
	border: 1px solid #333;
	line-height: 2.5;
	font-size:14px;
}
#ros-add-coment .ticket-header {
    font-size: 16px!important;
}
.admin-page .custom_checkbox_area input[type="checkbox"] {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    opacity: 1;
    border: 2px solid #3f51b5;
    border-radius: 3px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
}
#content-access .di-of-tbl-si {
    background: #fff;
    border: 1px solid #f1eeee;
    border-radius: 4px!important;
    padding: 10px 20px 0;
    border: 1px solid #ddd!important;
}
.admin-page .content-access-pla .btn-cancel{
	font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    background: #fff;
    border: 1px solid #0078BF;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
	margin-left:15px;
}
.admin-page .content-access-pla .btn-cancel:hover{
	background: #0078BF;color:#fff;
}
.admin-page #feature-access-architecture .ar-single-search {
    margin-right: 0px;
	padding-right:2px;
}
 #feature-access-architecture .fa-square {
    font-size: 16px;
    color: rgba(37,132,215,.82);
}
 #feature-access-architecture .select-dep{
	background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 8px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    max-width: 100%;
    width: 100%;
}
#feature-access-architecture p.group-school{
	max-height: 80px;
    overflow: auto;
}
.admin-page #addSection .single-change-password-right-input .corsepre {
    font-size: 14px;
    
}
.admin-page #feature-access-architecture .single-change-password-right-input input{
	border: 1px solid #ced4da;
}
.admin-page #edit-role-tags .ct-tag {
    height: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px #8d93a0;
    border-radius: 2px;
    padding: 7px 5px 7px 5px;
}
.admin-page #edit-role-tags .lbl-ct-tag {
    font-size: 13px;
    padding: 0 10px;
	font-size: 12px;
    padding: 0 6px;
	
}
.admin-page #edit-role-tags .ct-tag input[type="checkbox"] {
    vertical-align: sub;
}
.admin-page #edit-role-tags .btn-sec {
    width: 120px;
    font-weight: 300;
    color: #0078bf;
    font-size: 12px;
    background: transparent;
    border: 1px solid #0078bf;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: .3s ease-in-out;
}
.admin-page #edit-role-tags .btn-sec.btn_style_1, .admin-page #edit-role-tags .btn-sec:hover {
    background: #0078bf;
    color: #fff;
}
.di-hire{
	float: right;
    padding-top: 6px;
}
.admin-page #edit-grade .add-activity-text-content.left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 50px;
    padding-bottom: 5px;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f2f2f2;
    opacity: 1;
}
.admin-page #Rosters .myclassroom-single-left-content-course-progress {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
 .admin-page .additional-field label {
    font-weight: 300;
    font-size: 14px;
    color: #3f4254;
    margin-bottom: 5px;
    text-transform: capitalize;
}
 .admin-page .additional-field label.lbl{	
    margin-left:10px;
 }
#edit-student #form-edit-enroll .form-control:disabled, #edit-student #form-edit-enroll .form-control[readonly] {
    background-color: inherit;
    opacity: 1;
}
.header-profile-dropdown.add-new-class .dropdown a{
	white-space: nowrap;
	    padding: 18px 0;
}
.header-profile-dropdown.add-new-class .w-filter{
	width: 90%!important;
    min-width: 90%;
    max-width: 100%!important;
}
.admin-page .header-profile-dropdown.add-new-class .dropdown-item {
    font-weight: 400;
    font-size: 14px;
    font-size: 13px;
    color: #3f4254;
    text-transform: inherit;
    padding: 12px 20px;
}
.admin-page .header-profile-dropdown.header-logo-dropdown .dropdown-item.width-two {
    width: auto;
}
.admin-page .header-profile-dropdown.header-logo-dropdown .dropdown-menu.adjust{
	left:0!important;
}
.triangle-top-left-charge {
    width: 0;
    height: 0;
    border-top: 40px solid red;
    border-right: 40px solid transparent;
    cursor: pointer;
    margin-bottom: -33px;
    display: table-cell;
    position: absolute;
}
.triangle-text-charge {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    position: relative;
    right: -5px;
    top: -25px;
    white-space: nowrap;
    transform: rotate(-45deg);
}
.admin-page .dc_dots_area1 .down-icon img {
    width: 20px;
    height: auto;
    right: 0;
    top: 12px;
    position: absolute;
}
.admin-page .dc_dots_area1 .drop_as{
	padding:7px 18px 7px 18px;
}
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
    font-weight: bold;
    color: crimson;
}
.admin-page #trackname #student-info .class_area_sort_flex{
	padding: 18px 8px 22px;
}
.admin-page #trackname #student-info .dc_check_star {
    flex: 0 0 2.5%;
}
.admin-page #trackname #student-info .dc_dots_area .down-icon img{
	right: -9px;
}
.admin-page #trackname #student-info .ra_sortable_right_btn_area_sub_item_area.add-new-class {
    margin-left: 6px;
}
.admin-page #trackname .mu_icon_header_checkbox_n {
    margin-right: 10px;
    margin-left: 10px;
}
.admin-page .th_name {
    display: flex;
    align-items: center;
	height:30px;
}
.btn-acade{
    font-weight: 400;
    font-size: 14px!important;
    color: #0078bf;
    border: 1px solid #0078bf;
    padding: 4px 15px!important;
    border-radius: 25px;
    position: relative;
    transition: .3s;
    float: right;
	width: auto!important;
}
.errors{
	color:red;
}
.select-op{
    font-size: 14px;
    border: 1px solid;
}
#launchcourse2{
	color:#fff;
}
.row-thead-n {
    background: #dadada40;
    border: 1px solid #ddd;
}
.admin-page .table-temp tbody td{
    padding: 7px 10px!important;
    vertical-align: inherit!important;
}
.admin-page #recipient-transript .single-change-password-right-input input.gu-tran {
    border: 1px solid #333;
    padding: 7px 15px;
}
.fulname:hover{
	text-decoration: underline;
	cursor:pointer;
}
.admin-page #user-tags #gradebook  .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -35px;
    top: 5px;
    position: absolute;
}
#divaa{
	color: transparent!important;
}
.admin-page .mu_main_bottom_next_change.message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.load-spiner.message {
    display: inline-block;
    position: fixed;
    z-index: 3;
    left: 50%;
    margin-bottom: 50%;
}
.admin-page .superadmin .dropdown-menu.adjust{
	top: 0px !important;left:0!important;
}
.admin-page .super-admin .div-action .dropdown-menu.adjust {
    min-width: 186px!important;
}
.admin-page .super-admin-groups .ar-single-search.search1.new-add {
    flex: 0 0 50%!important;
    margin-right: 20px!important;
}
.admin-page .super-admin-groups .ar-single-search.search2.new-add {
    flex: 0 0 45%!important;
    margin-right: 0px;
}
.admin-page .super-admin-groups .ar-search_box_area.ar-input1.new-add input[type="search"] {
    max-width: 95%!important;
    width: 95%!important;
}
.admin-page .super-admin-groups .student-listing-single-content {
    flex: 0 0 45%!important;
}
.admin-page .super-admin-groups .student-grade-lavel-single-content.c2 {
    flex: 0 0 45%!important;
}
.admin-page #Rosters .mu_icon_header_checkbox {
    margin-right: 5px;
}
.admin-page .super-admin .student-grade-lavel-single-content.c3 {
    padding-left: 2px!important;
}
.admin-page .super-admin .student-listing-single-content {
    flex: 0 0 31%!important;
}
.admin-page .super-admin .student-status-text-contents.c4st {
    text-align: left!important;
    padding-left: 10px;
}
.row-no{
	display: block;
    margin: 0;
}
.admin-page #addSchool .btn-sec {
    width: 120px;
    font-weight: 300;
    color: #0078bf;
    font-size: 12px;
    background: #fff;
    border: 1px solid #0078bf;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: .3s ease-in-out;
}
.admin-page #addSchool .btn-sec.btn_style_1, .admin-page #addSchool .btn-sec:hover {
    background: #0078bf;
    color: #fff;
}
.admin-page #addSchool #btn-run:hover {
    background: #fff;
    color: #0078bf;
}
#form-edit-student .lbl-for-check-date .form-check-label {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 300;
	text-transform: lowercase;
}

.admin-page .lbl-for-check-date.form-check .form-check-input{
	margin-left:2px;
}
.input-d-gu{
	padding-right:0;
}
.input-d-gu .form-check-inline{
	margin-right: 0.55rem;
}
.di-of-tbl-si .hideprint li{
	cursor:pointer;
}
a.sendE:not([href]):not([tabindex]),a.sendE {
    color: #0078bf!important;
   cursor:pointer!important;
}
a.sendE:not([href]):not([tabindex]):focus, a.sendE:not([href]):not([tabindex]):hover{
	 text-decoration: underline!important;
}
.admin-page #order-grades .ar-single-filter-check {
    padding-right: 0;
	padding-top: 22px;
}
.admin-page #order-grades .dc_check_star {
    flex: 0 0 3%;
}
.admin-page .student-information-edit img.edit {
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 38px;
}
.admin-page .student-information-heading-profile.name {
    position: relative;
    width: 70%;
    height: 60px;
}
.admin-page .inbox-message-left-header h1.title-port {
    font-size: 15px;
    color: #05233b;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
}
.learning-course-progress-single-content.white{
	background:#f3f6f9;
	border-radius:10px;
}
.admin-page .learning-course-progress-single-content.white .view-content {
    padding: 15px;
    position: relative;
    min-height: 180px;
    min-height: 196px;
}
.admin-page .learning-course-progress-single-content.white .inbox-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
.admin-page .student-information-single-info .info p{
	padding-bottom:0;
}
.admin-page .student-information-single-info .info span.txt-f600{
	font-weight:600;
}
.scrol-detail{
	max-height: 198px;
    overflow: auto;

}
.scrol-detail::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.scrol-detail::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 10px;
}
a.text-detail{
	background: #145b94;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 12px;
    color: #fff!important;
    border-radius: 12px;
}
.admin-page #teacher-detail #student .student-listing-single-content {
    flex: 0 0 30%;
}
.admin-page #teacher-detail #student .student-grade-lavel-single-content.c3{
	 flex: 0 0 16%;
	 padding-left: 30px;
}
.admin-page #teacher-detail #student .student-status-text-contents {
    flex: 0 0 16%;
    text-align: left;
	padding-left:10px;
}
.admin-page #teacher-detail #student .student-grade-lavel-single-content.c2{
	padding-left:15px;
}
.admin-page #teacher-detail #student  .select-status{
	width: 160px;
}
.tippy-content .event-tooltip-content{
	text-align:left;
}
.admin-page #advisor-link #student-merge .mu_icon_header_checkbox{
	margin-right: 10px;
}
.admin-page #teacher-detail #advisor-link #student .ar-single-search-learn2.search1 {
    flex: 0 0 33%;
    margin-right: 0px;
}
.admin-page #teacher-detail #advisor-link #student .ar-single-search-learn2.search2 {
    flex: 0 0 25%;
    margin-right: 0px;
}
.admin-page #teacher-detail #advisor-link #student .ar-student-quality{
	flex: 0 0 36%;
}
.admin-page #teacher-detail #advisor-link #student .ar-quality-of-work.c2 {
    flex: 0 0 23%;
}
.admin-page #teacher-detail #advisor-link #student .ar-quality-of-work.c7 {
    flex: 0 0 15%;
    flex: 0 0 17%;
    padding-left: 15px;
}
.admin-page #teacher-detail #advisor-link #student .form-control {
    font-size: 14px;width:80%;
}
.navi-status-scholl{
	padding-left: 5px;
    font-size: 9px;
}
.shoo-status-mes{
	font-size: 10px;
    color: red;
}
.admin-page .mu_people_list_left {
    flex: 0 0 84%;
}
.admin-page .mu_people_left_left{
	flex: 0 0 33%;
}
.admin-page .mu_people_left_right{
	flex: 0 0 67%;
}
#send-message{
	display: block;
    font-size: 14px;
    border: 1px solid #0078BF;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;
	margin: 0px 12px 0px 0px;
	color:#fff;
}
#send-message:hover{
	color: #0069d9;
    background-color: #fff;
    border-color: #0062cc;
}
.portlet.box.green-meadow.red-meadow {
    border: 1px solid #ff0000;
    border-top: 0;
}
.reder input, .reder select{
	border-color:red!important;
}
.fw500{
	font-weight:500!important;
}
#edit-role-tags .nav-tabs .nav-item.show .nav-link, #edit-role-tags .nav-tabs .nav-link.active {
    font-weight: 600;
    color: #495057;
    background-color: #dbe6ee;
    border-color: #dee2e6 #dee2e6 #fff;
}
.admin-page #ContentIntegration .ra_process_tracking_area_header {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}
.admin-page #ContentIntegration .lb-ct-cfix{
	text-align: right;
    line-height: 34px;
    margin-bottom: 0;
}
.admin-page #parents .ar-quality-of-work.c4 p{
	overflow: hidden;
    text-overflow: ellipsis;
}
.admin-page #parents .ar-quality-of-work.c4 {
	overflow: hidden;
}
.admin-page #ContentIntegration .btn_style_2{
	vertical-align: middle;
}
.admin-page #feature-access-architecture .single-change-password-right-input input.error {
    border: 1px solid #f10a2a;
}
label.redtex{
	font-size: 13px;
    color: red;
    color: blue;
    font-weight: 100;
}
.txt-date{
	height: 28px;
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid #e5e5e5!important;
    border-radius: 4px;
}
.hyperlink{
	color:blue!important;
	cursor:pointer;
}
.la-text-date .form-check-label{
    margin-bottom: 0!important;
    font-size: 10px!important;
    font-weight: 300!important;
    text-transform: lowercase!important;
}
.la-text-date.form-check-inline{
	margin-right: 0.25rem;
}
.label-inf-bulk{
	padding-bottom: 0!important;
    line-height: 36px;
}
#bulk-info-update .form-control{
	font-size: 14px;
    font-weight: 300;
}
.admin-page #bulk-info-update .single-change-password-right-input input{
	    border: 1px solid #ced4da;
}
#bulk-info-update .css-yk16xz-control{
	 border: 1px solid #ced4da!important;
	 min-height: calc(1.5em + 0.75rem + 2px);
}
.pdt7{
	padding-top:7px;
}
.fnt-si-small{
	font-size:11px!important;
	margin-bottom:10px;
}
.fnt-si-small input{
	font-size:11px!important;padding: 0.375rem 3px;
}
.fnt-si-small select{
	font-size:11px!important;
	padding: 0.375rem 3px;
}
.bdle1px{
	border-left: 1px solid #ced4da;
}
.bdred1px{
	border: 1px solid red!important;
}
.admin-page .home-text-content h4.noborder span{
	border:0!important;padding:0 3px;
}
.info-student span{
	font-size:11px;
	font-weight:300;
}
.admin-page .info-student h1.title-port {
    font-size: 13px;
    color: #05233b;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 10px;
}
.admin-page #EventQueue1 .ar-single-search.search1.new-add {
    flex: 0 0 10%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-single-search.search2.new-add {
    flex: 0 0 18%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-single-search.search3.new-add {
    flex: 0 0 25%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-single-search.search4.new-add {
    flex: 0 0 12%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-single-search.search5.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-single-search.search6.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #EventQueue1 .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 100%;
    width: 95%;
}
.admin-page #student  #EventQueue1 .ar-search_box_area.ar-input5.new-add input[type="search"] {
    max-width: 100%!important;
    width: 95%!important;
}
.admin-page #EventQueue2 .student-grade-lavel-single-content.c1 {
    flex: 0 0 9%;
    text-align: left;
	padding-left:0;
}
.admin-page #EventQueue2 .student-grade-lavel-single-content.c2 {
    flex: 0 0 17%;
    text-align: left;
	padding-left:5px;
}
.admin-page #EventQueue2 .student-grade-lavel-single-content.c3 {
    flex: 0 0 24%;
    text-align: left;
	padding-left:5px;
}
.admin-page #EventQueue2 .student-grade-lavel-single-content.c4 {
    flex: 0 0 12%;
    text-align: left;
	padding-left:5px;
}
.admin-page #student #EventQueue2 .student-grade-lavel-single-content.c5 {
    flex: 0 0 19%;
    text-align: left;
	padding-left:5px;
}
.admin-page #student #EventQueue2 .student-status-text-contents.c6 {
    flex: 0 0 12%;
    text-align: left;
	padding-left:5px;
}
.admin-page #student #EventQueue2 .student-three-dot-contents {
    flex: 0 0 1%;
    text-align: right;
}
.hr-use{
	color: blue!important;
    font-size: 12px;
    font-weight: 300;
	margin-right:15px;
}
.hr-use:hover{
	text-decoration: underline!important;
}
.di-avail{
	font-size: 13px;
    font-weight: 300;
}
.admin-page .field-icon-new {
    float: right;
    position: relative;
    z-index: 2;
    top: -30px;
    right: 10px;
    cursor: pointer;
}
.admin-page .help-contetn .lang-select img{
	margin-top:-5px;
}
.admin-page #rosters .profile-right-section-content {
    margin-top: 0px;
    margin-left: 2px;
}
.admin-page #order-grades .ar-filter-all-wrapper {
    padding: 10px 40px 13px 20px;
}
.admin-page .upload-file.btn {
    padding: 4px 15px;
}
.txt-hrf{
	font-size: 13px;
    text-decoration: underline;
}
.admin-page #EventQueueNew .ar-single-search.search1.new-add {
    flex: 0 0 30%;
    margin-right: 0px;
}
.admin-page #EventQueueNew .ar-single-search.search2.new-add {
    flex: 0 0 50%;
    margin-right: 0px;
}
.admin-page #EventQueueNew .ar-single-search.search3.new-add {
    flex: 0 0 20%;
    margin-right: 0px;
}

.admin-page #EventQueueNew .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 100%;
    width: 95%;
}
.admin-page #EventQueue2n .student-grade-lavel-single-content.c1 {
    flex: 0 0 28%;
    text-align: left;
    padding-left: 0;
}
.admin-page #EventQueue2n .student-grade-lavel-single-content.c2 {
    flex: 0 0 48%;
    text-align: left;
    padding-left: 0;
}
.admin-page #EventQueue2n .student-grade-lavel-single-content.c3 {
    flex: 0 0 20%;
    text-align: left;
    padding-left: 20px;
}
.admin-page .superadmin .header-bottom-content ul li a {
    padding: 18px 7px;
    font-size: 14px;
}/*---- Swich-----------*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 2px 15px 0;
  margin: 7px 15px 0 7px;
  width: 45px;
  height: 25px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
   height: 19px;
  width: 19px;
}

input:checked + .slider {
  background-color: #2196F3;
  background-color: #0078bf;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*----end Swich-----------*/
  .text-overflow {
     /* width:250px;*/
      height:120px;
      height:100px;
      /*display:block; */
      overflow:hidden;
      word-break: break-word;
      word-wrap: break-word;
	  font-size:13px!important;
  }

  .btn-overflow {
    display: none;
    text-decoration: none; 
	font-size:13px!important;
  }
	.btn-overflow-less {
    display: none;
    text-decoration: none; 
	font-size:13px!important;
  }
span.cap-name{
	font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    display: block;
}
.admin-page #edit-notificationquee .single-change-password-right-input .react-datepicker-wrapper input{
	height:35px;
}
/*========arabic languaes========*/
.admin-page .header-top-content.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .arabic .help-contetn{
	padding-left:0;
	padding-right:10px;
}
.admin-page .arabic .header-top-left-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .header-logo-dropdown.arabic{
	flex-direction: row-reverse;
}
.admin-page .arabic #dropdownMenuButton{
	    display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .down-icon img{
	left:0px; 
}
.admin-page .arabic  .logo-sch .text-truncate {
       margin-right: 16px!important;
    margin-left: 34px!important;

}
.header-logo-dropdown .dropdown.arabic {
    display: flex;
    justify-content: flex-end;
}
.admin-page .dropdown-item.width-two.arabic {
    text-align: right;
}
.admin-page .header-bottom-content ul.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .header-bottom-content ul.arabic li a {
    display: flex;
    flex-direction: row-reverse;
	padding-right: 10px;
}
.admin-page .header-bottom-content ul.arabic li a img{
	margin-top: 0;
    margin-right: 0;
    margin-left: 2px;
}
.admin-page .header-bottom-content ul.arabic .drop-menu-it .down-icon-two img.arabic {
    width: 12px;
    height: 8px;
    right: -10px;
    top: 26px;
    left: -6px;
    position: absolute;
}
.admin-page .arabic .myclassroom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .header-bottom-content ul.arabic .dc_dots_area img{
	left:-20px;
}
.admin-page .arabic .mu_dropdown_menu_content ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.ar-flex{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .ar-filter-area.arabic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 20px;
    margin-top: 30px;
    flex-direction: row-reverse;
}
.arabic .ar-reset-funtion-area{
	margin-left:20px;
}
.admin-page .document_pagenations_area.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 30px;
    flex-direction: row-reverse;
}
.admin-page .arabic .div-view-page {
    margin-right: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 10px;
}
.admin-page .arabic .view-page {
    color: #3F4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 5px;
}
.admin-page .arabic .ra_sortable_header_area_menu > ul {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row-reverse;
}
.admin-page .arabic .ar-srech-filt-menu ul li {
    display: flex;
    padding: 2px 0px;
    margin-left: 0px;
    flex-direction: row-reverse;
    padding-right: 10px;
}
.admin-page .dc_dots_area.arabic .dropdown-menu a {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    padding-right: 18px;
    padding-left: 18px;
}
.admin-page .arabic .ar-search_box_area input[type=search] {
    text-align: right;
}
.admin-page .home-text-content h4.noborder span{
	border:0!important;padding:0 3px;
}
.admin-page .home-text-content.arabic  h4.noborder span{
	padding-left:3px;
	padding-right:3px;
}
.arabictxt{
	display:flex;
	flex-direction: row-reverse;
}
.arabictxt .d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
    flex-direction: row-reverse;
}
.admin-page .arabic .ra_upcoming_heading.last-pages-class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.admin-page .ra_upcomming-left-contents p.arabic  {
    padding-right: 10px;
    padding-left: 0px;
}
.admin-page .ra_upcoming_heading.arabic{
	display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.admin-page .mu_view_icons ul.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page #schedule .mu_view_icons ul.arabic li {
    margin-left: 0;
    margin-right: 20px;
}
.admin-page .ra_upcoming_heading p.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .tab-pane.arabic .row.single-change-password-right-input {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row-reverse;
}
.admin-page .tab-pane.arabic .row.single-change-password-right-input .aretextri{
	text-align:right;
}
.admin-page .request-enrollment-main-content-heading p.aretextri{
	text-align:right;
}
.admin-page .tab-pane.arabic .row.single-change-password-right-input label {
	display: flex;
    flex-direction: row-reverse;
}	
.admin-page .same-close-button-area.arabic {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.admin-page .mu_dropdown_menu_content ul.arabic {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .mu_dropdown_menu_content ul.arabic li {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page .div-action.arabic .dropdown-item {
    text-align: right;
}
.admin-page .b {
    display: none;
    max-width: 350px;
    box-shadow: 0 6px 10px rgb(0 0 0 / 10%);
    border: 1px solid rgba(0,0,0,.15);
    position: absolute;
    width: 105px;
    width: 120px;
    z-index: 99999;
}
.admin-page .arabic .header-top-right-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .btn-select {
    font-weight: 400;
    font-size: 14px;
    color: #375f7f;
    text-transform: capitalize;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #fff;
    border: none;
    width: 105px;
    width: auto;
    position: relative;
}
.admin-page .btn-select:after {
    content: "";
    position: absolute;
    background: url(/media/home/down-arrow.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    right: 0;
    top: 14px;
    width: 12px;
    height: 8px;
    right: -5px;
}
.dis-flex-rever{
	display: flex!important;
    flex-direction: row-reverse!important;
}
.admin-page .inbox-message-header.arabic {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.arabic a.flex{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}
.arabic a.flex span{
	margin-left:0;
	margin-right:4px;
}
.arabic .texfleleft{
	justify-content: flex-end!important;
}
.admin-page .request-edit-student-area .dis-flex-rever .txt-si{
	margin-left: 0px;
	margin-right: 20px;
}
.admin-page .arabic .dropdown-item{
	text-align:right;padding-right:18px;
}
.admin-page .home-text-content.arabic h4 {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page #sections .arabic .single-top-progress-area.common-class p{
	text-align:right;
}
.admin-page .arabic .single-top-pera-count {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .arabic .inbox-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.admin-page #sections .arabic .body-content .row{
	display: flex;
    flex-direction: row-reverse;
	
}
.admin-page #sections .arabic .body-content .row .col-md-6,.admin-page #sections .arabic .body-content .row .col-md-5{
	text-align:right;
	
}
.admin-page #sections .arabic .body-content .row .col-md-6.fw300,.admin-page #sections .arabic .body-content .row .col-md-5.fw300{
	display: flex;
    flex-direction: row-reverse;
	
}
.admin-page #addSection .arabic .single-change-password-right-input label{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page .arabic .profile-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page .arabic .profile-text {
    text-align: right;
    margin-left: 0;
    margin-right: 10px;
}
.admin-page .arabic .header-profile-dropdown {
    padding: 0 42px 0 30px;
    padding: 0 10px 0 10px;
    position: relative;
}
.down-icon-two.arb{
	padding-left:0px!important;
	padding-right:15px;
}
.header-logo-dropdown .dropdown.arb {
    display: flex;
    flex-direction: row-reverse;
}
.txalr-arb{
	text-align:right!important;
}
.dis-flex-rever .btn-do-uplad{
	margin-left:0;
	margin-right:15px;
}
.arabic span.bage{
	margin-left: 0px;
	margin-right: 8px;
}
.arabic .student-information-single-info .row{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .ra_sortable_header_area_menu > ul.ar-le {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
#ros-add-coment .arabic .ticket-header {
    display: flex;
    flex-direction: row-reverse;
}
.request-enrollment-main-content-form.arabic .row{
	display: flex;
    flex-direction: row-reverse;
}
.request-enrollment-main-content-form.arabic .row label, .txt-rig{
	text-align:right;
	    display: flex;
    flex-direction: row-reverse;
}
.admin-page .dc_dots_area .arabic .dropdown-menu a{
	padding-right: 18px;
    padding-left: 0;
}
.arb-enr{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

}
.arabic .student-information-single-info a {
    display: flex!important;
    flex-direction: row-reverse;
}

/*========end arabic languaes========*/
.table-tranfer-user .form-control{
	padding:0.3rem 3px;
}

.table-tranfer-user .lihei-32,.lihei-32{
	line-height: 32px;
}
.table-tranfer-user .txt-date{
	height: 34px;
    margin: 0;
}
.table-tranfer-user .txt-date.mgr5{
	margin-right:5px;
}
.table-tranfer-user .react-datepicker__input-container{
	width: 95%;
}
.admin-page #home .body-porlet.communiction {
    height: 910px;
    height: 336px!important;
}
#table-tranfer{
	overflow-x: inherit!important;
}
.di-reset{
	display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
}
.myclassroom-single-right-right-content .dropdown-menu.show {
    z-index: 9999999;
}
.admin-page .arabic .add-activity-text-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.admin-page .request-enrollment-main-content.arabic .request-enrollment-option p{
	text-align:right;
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .request-enrollment-main-content.arabic .request-enrollment-main-content-heading p{
	text-align:right;
}
.admin-page .request-enrollment-main-content.arabic .row {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page .request-enrollment-main-content.arabic label{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .request-enrollment-main-content.arabic label span {
    margin-right: 5px;
}
.admin-page #gradebook .arabic .row .rightt {
    text-align: right;
}
.admin-page .arabic#edit-student .single-change-password-right-input label{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic#edit-student .request-enrollment-main-content-heading p{
	text-align: right;
}
.admin-page .row.arabic, .admin-page .row.arabic label{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page  #student .arabic .ra_sortable_header_area_menu.ar-srech-filt-menu > ul{
	    margin-right: 10%;
}
.admin-page  #student .arabic .search4 .ra_sortable_header_area_menu.ar-srech-filt-menu > ul{
	    margin-right: 25%;
}
.admin-page  #user-tags .arabic .request-enrollment-main-content-form .row, .admin-page  #user-tags .arabic .request-enrollment-main-content-form .row .single-change-password-right-input label{
	display:flex;
	flex-direction: row-reverse;
}
.admin-page  #user-tags .arabic .request-enrollment-main-content-form .row .is-checkbox{
	text-align:right;
}
.admin-page  #student-enroll-to-sec .arabic .row, .admin-page  #student-enroll-to-sec .arabic .row .ar-lg{
	display:flex;
	flex-direction: row-reverse;
}
.admin-page #student-enroll-to-sec  .arabic#edit-student .single-change-password-right-input label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page #student-enroll-to-sec .arabic .ra_sortable_header_area_menu > ul {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}
.admin-page .arabic #recipient-transript .div-ct-check {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .la-ar{
    display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .la-ar-rx-ri{
	text-align:right;
}
 .admin-page .arabic .la-ar #table-simulation>thead>tr>th {
	text-align:right; 
 }
 .admin-page .arabic .la-ar-le{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page .arabic .la-ar-ri {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}
.admin-page .arabic .txt-app-detail{
	text-align:right;
}
.admin-page .arabic .left_pg_area a{
	margin-right: 0px;
	margin-left: 20px;
}
.admin-page #student .arabic .student-information-single-info p span {
    padding-left: 5px!important;
    text-align: right;
}
.error-title{
	color:red;
}
.admin-page .superadmin #student .ra_process_tracking_area_header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: end;
    align-items: end;
	justify-content: space-between;
}
.empty-tool #toolbar{
	display:none!important;
}
.admin-page .content-tags{
	display:flex;margin:0;	
	padding-left: 0!important;
    padding-right: 0!important;
}
.admin-page .content-tags .tags-name{
	margin-bottom: 10px;
	display:flex;
}
.admin-page .content-tags .tags-name:last-child{
	margin-bottom: 0;
}
.admin-page .content-tags .tags-name:nth-last-child(1){
	margin-bottom: 0;
}
.admin-page .content-tags .tags-name:nth-last-child(2){
	margin-bottom: 0;
}
.admin-page .content-tags .tags-name:nth-last-child(3){
	margin-bottom: 0;
}
.mgbt0{
	margin-bottom:0px!important;
}
.admin-page #user-tags .tab-content.new-class-add-last-website .request-enrollment-main-content .ct-tag.ct-tag-new{
	height:auto!important;
	padding:15px 0;
}
.admin-page #user-tags .tab-content.new-class-add-last-website .request-enrollment-main-content .ct-tag.ct-tag-new .lbl-ct-tag{
	margin-bottom:0;
}
.admin-page #edit-role-tags #user-tags .ct-tag.ct-tag-new{
	height:auto!important;
	padding:15px 0;
}
.admin-page #edit-role-tags #user-tags .ct-tag.ct-tag-new .lbl-ct-tag{
	margin-bottom:0;
}
.admin-page .arabic .ra_sortable_header_area_menu > ul.arabic {
    display: flex;
    align-items: center;
    align-content: center;
	flex-direction: inherit;
}
.admin-page .arabic#cur-enrol .ra_sortable_header_area_menu > ul.arabic li{
	margin-right:8px;
}
.admin-page .arabic .mrg0-ar{
	margin-right:0;
}
.add-more-other{
	display: flex;
    justify-content: space-between;
}
.admin-page #edit_custom_role_tag .content-tags .tags-name {
    margin-bottom: 10px;
    display: inline-block;
}
.admin-page #edit_custom_role_tag .content-tags {
    display: block;
}
#view-consolidated-tag a.role_tags {
    background: #ff7f27;
    border-radius: 50%;
    width: 13px;
    height: 13px;
	margin-right:2px;
}
#view-consolidated-tag a.user_school {
    background: #22b14c;
    border-radius: 50%;
    width: 13px;
    height: 13px;
	margin-right:2px;
}
#view-consolidated-tag a.user {
    background: #00a2e8;
    border-radius: 50%;
    width: 13px;
    height: 13px;
	margin-right:2px;
}
#view-consolidated-tag a.schools {
    background: #05233b;
    border-radius: 50%;
    width: 13px;
    height: 13px;
	margin-right:2px;
}
.admin-page .change-password-submit-button input.red {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #e70b0b;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    order: ;
    border: 1px solid #e70b0b;
    transition: .3s;
}
 .admin-page .change-password-submit-button input.red :hover {
    background: transparent;
    color: #e70b0b;
  }
  .admin-page #edit-role-tags #user-tags .ct-tag.ct-tag-new {
    height: auto!important;
    padding: 15px 0;
}


.admin-page #security-tag-dicionary .ar-single-search.search1.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary .ar-single-search.search2.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary  .ar-single-search.search3.new-add {
    flex: 0 0 19%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary .ar-single-search.search4.new-add {
    flex: 0 0 7.5%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary .ar-single-search.search6.new-add {
    flex: 0 0 14%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary .ar-single-search.search5.new-add {
    flex: 0 0 15%;
    margin-right: 0px;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c1 {
    flex: 0 0 14%;
    text-align: left;
    padding-left: 0;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c2 {
    flex: 0 0 14%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c3 {
    flex: 0 0 19%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c4 {
    flex: 0 0 19%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c5 {
    flex: 0 0 12%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #security-tag-dicionary-detail .student-grade-lavel-single-content.c6 {
    flex: 0 0 14%;
    text-align: left;
    padding-left: 5px;
}
.admin-page #student #security-tag-dicionary .ar-search_box_area.ar-input3.new-add input[type="search"] {
    max-width: 95%;
    width: 100%;
}
.admin-page #edit-grade #add-other-grades .arabic .request-enrollment-option p {
    
    display: flex;
    flex-direction: row-reverse;
}
.arabic .rei-ar{
	text-align:right;
}
#add-contract .arabic .row{
	display: flex;
	flex-direction: row-reverse;
}
.admin-page #teacher-detail .arabic .infor_heading_area {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.admin-page #teacher-detail .arabic .infor_heading_area {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.ar-detai{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.ar-detai span{
	padding-left:3px;
}
.admin-page .dc_dots_area1 .arabic .down-icon img {
    left: auto;
}
.admin-page #attendance-page .arabic .single-attendance-header-present-content p, .admin-page #attendance-page .arabic .single-attendance-header-present-content .single-attendance-header-middle-text{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .single-attendance-header-middle-text p{
	padding-left: 0;
    padding-right: 10px;
}
.role-cus{
	font-size: 11px;
    font-weight: 400;
    color: darkgray;
}
.admin-page .superadmin.myclassroom-single-right-right-content .dropdown button {
    padding: 6px 24px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 14px;
}
.admin-page #student-aplication .student-information-single-info p span:first-child {
    padding-left: 0px;
}
.fs13 .form-check-label{
	font-size:13px!important;
}
.fs13{
	padding-left:20px;
}
.mg0{
	margin:0px;
}
#transfer-bulk .la-text-date .form-check-label{
	text-transform: capitalize!important;
}
.admin-page #student .div-action .dropdown-menu.adjust.mw260 {
    min-width: 160px;
}
.admin-page .cote-grade4 .fa-trash-alt {
    cursor: pointer;
    color: red!important;
}
.admin-page #edit-all .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	width: 100%;
}
.dis-flex-rever-le {
    display: flex!important;
    flex-direction: row-reverse!important;
    justify-content: flex-end;
}
.admin-page .mu_main_bottom_next_change.message.arabic {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
span.none{
	color: inherit!important;
}
.flex-left-ar{
	 display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page .mgl0 .form-check .form-check-input{
	margin-left:0;
}
.admin-page .arabic .change-password-left-content button {
    text-align: right;
}
.admin-page .arabic .change-password-right-heading p {
    text-align: right;
}
.repo-title-ar{
	display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}
.admin-page #report-parent .arabic .div-ct-check {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page #report-parent .arabic .row {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-parent .arabic .row .single-change-password-right-input label{
	text-align:right;
	display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-parent .arabic .row .single-change-password-right-input.dis-flex-rever label{
	margin: 0 8px;
    padding: 0;
}
.mgr_015{
	margin:0 15px;
}
.admin-page #report-teacher .arabic .div-ct-check {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page #report-teacher .arabic .row {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-teacher .arabic .row .single-change-password-right-input label{
	text-align:right;
	display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-teacher .arabic .row .single-change-password-right-input.dis-flex-rever label{
	margin: 0 8px;
    padding: 0;
}
.admin-page #report-application .arabic .div-ct-check {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.admin-page #report-application .arabic .row {
    display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-application .arabic .row .single-change-password-right-input label{
	text-align:right;
	display: flex;
    flex-direction: row-reverse;
}
.admin-page #report-application .arabic .row .single-change-password-right-input.dis-flex-rever label{
	margin: 0 8px;
    padding: 0;
}
.admin-page #report-application .th_name, .admin-page #report-teachern .th_name, .admin-page #report-parent .th_name{
	    height: auto;
		min-height: 37px;
}
#table-simulation>thead>tr>th.f400 {
    vertical-align: middle;
	padding-top:8px;
	padding-bottom:8px;
}
.admin-page .super-admin-groups .dep-listing-single-content.c1 {
    flex: 0 0 50%!important;
}
.admin-page .super-admin-groups .dep-listing-single-content.c2 {
    flex: 0 0 40%!important;
}
.mgr8{
	margin-right:8px;
}
.admin-page .student-information-single-info .student-img {
    position: relative;
    padding-right: 5px;
	padding-left:0;
}
.admin-page .student-information-single-info .student-img img {
    width: 30px;
    height: auto;
}
.admin-page .student-information-single-info .student-name h3 {
    font-size: 12px;
    font-weight: 600;
}
.admin-page .student-information-single-info .student-sec{
	margin-bottom: 5px;
}
.admin-page .student-information-single-info .info span.txt-f400 {
    font-weight: 400;
}
.scrol-detail-110 {
    max-height: 150px;
    overflow: auto;
}

.admin-page .request-enrollment-main-content.pd0, .admin-page .ra_gradebook_header.pd0{
	padding:0!important;
}
.admin-page #teacher-detail .parent-page .student-information-single-info p span {
    padding-left: 0;
} 
.arabic .student-information-single-info a.img{
	    display: initial!important;
}
.admin-page .arabic .single-change-password-right-input label{
	display: flex;
    flex-direction: row-reverse;
}
.ar-de-w100{
	display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
.arabic #body-add .row{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic #body-add .single-change-password-right-input label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page .arabic #body-add .div-ct-check {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dis-flex-revert{
	display: flex;
	justify-content: flex-start!important;
}
.admin-page .arabic .la-ar-ris{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.admin-page #poup-parent #add-addess .single-change-password-right-input label {
    text-align: left;
}
.admin-page #poup-parent #add-phone .single-change-password-right-input label {
    text-align: left;
}
.admin-page .arabic #body-add .text-f-r .single-change-password-right-input label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}
.admin-page #poup-parent .arabic .single-change-password-right-input label{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic #body-add .div-ct-check.check-ri {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.admin-page #poup-parent .student-information-edit img{
	width: 20px;
    position: absolute;
    bottom: 20px;
    left: 80px;
}
.answered{
	border: 1px solid;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
}
.qustion{
	border: 1px solid;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
}
.answeredhis{
	border: 1px solid;
    padding: 15px;
    background: #addedc40;
    border-radius: 5px;
}
.txalrig {
    text-align: right;
    font-size: 12px;
    font-weight: 200;
}
.admin-page #ai-course .ar-single-search.search1 {
    flex: 0 0 40%;
    margin: 0;
}
.admin-page #ai-course .ar-single-search.search2 {
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #ai-course .ar-single-search.search3 {
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #ai-course .ar-single-search.search4 {
    flex: 0 0 20%;
    margin: 0;
}
.admin-page #ai-course .text-1 {
    flex: 0 0 40%;
}
.admin-page #ai-course .text-2 {
    flex: 0 0 20%;
}
.admin-page #ai-course .text-3 {
    flex: 0 0 19%;
}
.admin-page #ai-course .text-4 {
    flex: 0 0 17%;
}
.admin-page #ai-course .department-name {
    margin-left: 0;
    max-width: 100%;
}

.admin-page #ai-course .myclassroom-single-left-cross-progress {
    width: 125px;
    height: 7px;
    background: #e4e6ef;
    border-radius: 5px;
}
.admin-page #ai-course .department-name p{
	padding:0 5px;
}
.admin-page #ai-course .ar-input1 input[type="search"] {
    width: 90%;
}
.admin-page #ai-course .ar-input2 input[type="search"] {
    width: 90%;
}
.admin-page #ai-course .ar-input3 input[type="search"] {
    width: 90%;
}
.admin-page  #ai-course .mu_dropdown_menu_area{
	margin:0;
	padding:0;
}
.admin-page #ai-course .ra_gradebook_header {
    padding: 0;
}
.bacg-silde{
	border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    height: 300px;
    height: 380px;
}
.admin-page #ai-course .ra_event_single_slider_area {
    background: #fff;
    padding: 24px 8px 24px 20px;
    padding: 10px 20px;
    border-left: 0;
    display: flex;
    align-items: flex-start;
	justify-content: center;
}
.mgbt15{
	margin-bottom:15px;
}
.fledot{
	flex: 0 0 2%;
    text-align: right;
}
p.unit-descri{
	font-size: 13px;
    padding: 0 30px;
	max-height:37px;
}
.bacg-silde .none{
	padding: 0 30px;
}
.ar-lesson-name{
	width: 150px;
	width: 140px;
	width: 220px;
    text-align: center;
}
.admin-page #ai-course .ar-lesson-name p {
    color: #3f4254;
    font-size: 14px;
    font-weight: 600;
    font-family: "Avenir";
    padding-bottom: 5px;
    line-height: 14px;
	margin: 12px 32px;
	margin: 12px;
}
.medallion {
    box-sizing: border-box;
    border-radius: 50%;
    height: 72px;
    width: 72px;
	
    margin: 12px 32px;
    z-index: 1;
    flex-shrink: 0;
    text-align: center;
    background-color: #128297;
    transition: margin .5s cubic-bezier(.35,0,.25,1);
    box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
	
}
.lesson-profile{
	width: 216px;
    height: 360px;
    margin: 0 8px;
    box-sizing: border-box;
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px 0;
    border-radius: 4px;
	background: #fff;
    color: rgba(0,0,0,0.87);
	    border: 1px solid #8dcbef;
}
.lesson-profile .header {
    height: 140px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    border-bottom: 1px solid #e0edf5;
}
.lesson-profile .medallion  {
	margin: 12px 80px;
}
.lesson-profile .ar-lesson-name{
	width: 235px;
	width: 215px;
    text-align: center;
}
.admin-page #ai-course .lesson-profile .ar-lesson-name p  {
	margin: 12px 80px;
	margin: 12px 28px;
}
.lesson-profile .detail {
	height: 132px;
    position: relative;
    color: rgba(0,0,0,.54);
	    display: block;
    margin-bottom: 8px;
	padding:16px;	
    text-align: left;
}
.lesson-profile .detail .subtitle {
    line-height: 24px;
    font-weight: 500;
    max-height: 48px;
    max-height: 98px;
    overflow: hidden;
	font-size:14px;
	font-weight:500;
}
.lesson-profile .detail .midle{
	font-size:14px;
	font-weight:200;
}
.mat-card-actions {
   
    margin-right: 8px;
    padding: 8px 0;
	display: flex;
    justify-content: space-between;

}
.admin-page .mat-card-actions button {
    font-weight: 300;
    font-size: 13px;
    color: #0078bf;
    text-transform: capitalize;
    background: transparent;
    padding: 5px 10px;
    padding: 2px 10px;
    border: 1px solid #0078bf;
    border-radius: 30px;
    margin-left: 20px;
    transition: .3s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	margin-bottom:8px;
}
.admin-page .mat-card-actions button:hover, .admin-page .mat-card-actions button:active{
	background: #0078bf;
    color: #fff;
}
.admin-page .mat-card-actions .dropdown button{
	background: transparent;
	border:0;
}
.none-profile{
	cursor:pointer;
}
.admin-page #ai-course .profile-image img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
	margin-right:10px;
}
.admin-page #ai-course .nav-link {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
	background: #fff;
	margin-bottom: 3px;
	/*justify-content: space-between;*/
	
}
.admin-page #ai-course .nav-pills .nav-link.active, .admin-page #ai-course .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}
.admin-page #ai-course  .btn_style_1{
	padding: 6px 24px;
}
#page-content .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    align-items: center;
    align-content: center;
}
h4.title-les{
	margin: 32px 0;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    box-sizing: border-box;
}
h4.title-lesson{
	margin: 10px 0;
    padding-bottom: 8px;
    box-sizing: border-box;
}
 .bord-lesson{
	margin: 32px 0;
    padding-bottom: 8px;
    border-top: 1px solid rgba(0,0,0,.12);
    
    box-sizing: border-box;
}
.bord-lesson-bottom{
	margin: 10px 0;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    box-sizing: border-box;
    margin-bottom: 15px;
}
.border-less{
	margin: 0;
    border-bottom: 1px solid rgba(0,0,0,.12);
    box-sizing: border-box;
	padding-bottom: 32px!important;
	margin-bottom:15px;
}
.answered.bord{
	border: 1px solid rgba(0,0,0,.12);
    box-sizing: border-box;
}
.form-control.bord{
	border: 1px solid rgba(0,0,0,.12);
    box-sizing: border-box;
}

/*========*/
#ai-course .box {
  
  position: relative;
}
#ai-course .ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
#ai-course .ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
#ai-course .ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
#ai-course .ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.text-break{
	word-break: break-all;
}
.zoom{
	cursor:pointer;
}
.zoom:hover {
  -ms-transform: scale(3.5); /* IE 9 */
  -webkit-transform: scale(3.5); /* Safari 3-8 */
  transform: scale(2.0); 
  z-index:111111;
  position: relative;
}
/*========*/
.btn-cancel-less{
	padding: 5px 20px;
    font-size: 12px;
    vertical-align: middle;
}
.admin-page #poup-parent .arabic .single-change-password-left-input label.txtR{
	text-align:right!important;
}
.admin-page #poup-parent .arabic .txtR, .admin-page .arabic .txtR{
	text-align:right!important;
}
.admin-page #edit-grade .arabic .request-enrollment-option p{
	    display: flex;
    flex-direction: row-reverse;
}
.div-flex-revert {
    display: flex;
    justify-content: flex-start!important;
    flex-direction: row-reverse;
}
.arabic .lng{
	display: flex;
    flex-direction: row-reverse;
}
.admin-page .arabic .single-change-password-right-input label.flex-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.arabic .fle-left{
	display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.arabic b.flex-ar{
	display: flex;
    flex-direction: row-reverse;
}
.label-option{
	font-size: 13px;
    font-weight: 500;
}
.ar-flex-end{
	
    display: flex;
    justify-content: flex-end;
}
.admin-page #profile-page .student-information-single-info p span {
    padding-left: 1px;
}
.admin-page #profile-page{
	margin-top:15px;
}
.tippy-content .event-tooltip-content {
    text-align: left;
    background-color: aliceblue;
    font-size: 12px;
    padding: 5px;
	border-radius: 5px;
    border: 1px solid #fff;
    border: 1px solid #b4bcea;
}
#school-overview.arabic .txt-us{
	display: flex;
    flex-direction: row-reverse;
}
#school-overview.arabic .txt-us-bd{
	text-align:right;
}
.admin-page #course-detail .btn-printf {
    margin-left: 10px;
    width: auto;
}
.title-flex{
	display: flex;
    flex-direction: row-reverse;
}
#payment-aplication .arabic .row, #payment-aplication .arabic .row label{
	display: flex;
    flex-direction: row-reverse;
}
.parent-page #payment-aplication .mu_dropdown_menu_content ul.arabic,#payment-aplication .mu_dropdown_menu_content ul.arabic {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
}	
.arabic #tran-com .row{
	display: flex;
    flex-direction: row-reverse;
}
#wrapper11 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
#div22.table-up, #table-simulation.table-up{
	margin-top:1rem;
}
.admin-page #student .ar-search_box_area.ar-input3.num-enrol input[type="search"] {
    max-width: 140px;
    width: 90%;
}
#enroll-one #div22>tbody>tr>td, #enroll-one #div22>tbody>tr>th, #enroll-one #div22>tfoot>tr>td, #enroll-one #div22>tfoot>tr>th, #enroll-one #div22>thead>tr>td, #enroll-one #div22>thead>tr>th {
    font-weight: 400;
    font-size: 13px;
}

#div22>thead>tr>th.f400 {
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
}
.row-thead-n {
    background: #dadada;
}

.admin-page #template-noti th .list_check .mu-form-group input:checked + label::after {
    top: 2px;
    left: 6.5px;
    width: 6px;
    height: 12px;
    border: solid #707070;
    border-width: 0 2px 2px 0;
}
.header-bottom-content .dropdown-menu.show{
	z-index: 99999!important;
}
.di-foo-but-ri{
	display: flex;
    justify-content: flex-end;
}
.h2-auto {
    height: auto!important;
    max-height: 260px;
    min-height: 260px;
    overflow-y: auto;
}
.admin-page .no-attan .learning-course-progress-single-content {
    box-shadow: 0px 0px 10px #e4e6ef;
}
.admin-page #learning-path-template .dropdown-item.width-two {
    width: auto;
}
#notification-rule-template .custom-table-row td:first-child, #notification-rule-template .custom-table-head th:first-child {
    padding-left: 8px;
    vertical-align: baseline;
    padding-top: 20px;
}
.icon-color{
	margin-left:5px;
	color:blue;
	cursor:pointer;
}
.admin-page #rosters .filt-search #dropdownMenuButton {
    font-weight: 500;
    color: #505355;
}
.admin-page #rosters .filt-search #dropdownMenuButton .down-icon-three img {
    width: 12px !important;
    height: 8px !important;
    right: 2px;
    bottom: 24px;
    position: absolute;
    top: 10px;
}
.admin-page #rosters .filt-search #filter-grade {
	background: #fff;
    border: 1px solid #a6adb1;
    padding: 3px 0 5px 4px;
    margin-top: 4px;
    margin-top: 0px;
    border-radius: 3px;
}
.admin-page #rosters .ar-search_box_area input[type="search"] {
  padding: 7px 0px 7px 4px;
}
.massage-status{
  background: red;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  min-width:13px;
}
.width_90{
	width:90%!important;
}
.pdbt6{
	padding-bottom:6px!important;
}
.admin-page  #userDirectory .dropdown-menu.adjust {
   
    right: -6px!important;
}
.admin-page  #userDirectory .myclassroom-single-right-right-content.mbcss .dropdown-item{
	padding: 12px 18px;
}
.admin-page  #userDirectory .ra_process_tracking_area_header {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    margin-top: 15px;
}
.admin-page  #userDirectory .ar-filter-area, .admin-page  #parents .ar-filter-area{
	margin-top: 15px;
}
.admin-page #addSection .single-change-password-right-input input.input-date{
	height:37px;
}
.admin-page #addSection .single-change-password-right-input label.redtex {
  font-size: 13px;
  color: red;
  color: #00f;
  font-weight: 100;
}
.icon-copy{
	margin-left: 7px;
  color: blue;
  cursor:pointer;

}
.dis-role-tag-check{
	display: flex;
    justify-content: space-between;
}
.font500{
	font-weight:500;
}
.admin-page #request-com .btn-sec {
  width: 120px;
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  background: transparent;
  border: 1px solid #0078bf;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: .3s ease-in-out;
}
.admin-page #request-com .btn-sec.btn_style_1, .admin-page #request-com .btn-sec:hover {
  background: #0078bf;
  color: #fff;
}
.pdt20{
	padding-top: 20px!important;
}
.pdt40{
	padding-top: 40px!important;
}
.lh24px{
	line-height:24px;
}
 .login-google button{
  background-color: rgb(255, 255, 255)!important;
  display: inline-flex!important;
  align-items: center!important;
  color: rgba(0, 0, 0, 0.54)!important;
  box-shadow: rgba(0, 0, 0, 0.41) 0px 2px 2px 0px, rgba(0, 0, 0, 0.41) 0px 0px 1px 0px!important;
  padding: 0px!important;
  border-radius: 5px!important;
  border: 1px solid #3F4254!important;
}
.buttongoogle{
	background-color: rgb(255, 255, 255); display: inline-flex; align-items: center; color: rgba(0, 0, 0, 0.54); box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; padding: 0px; border-radius: 2px; border: 1px solid transparent; font-size: 14px; font-weight: 500; font-family: Roboto, sans-serif;
}
span.spgoog{
	padding: 10px 10px 10px 0px; font-weight: 500;
}
div.digoog{
	margin-right: 1px; background: rgb(255, 255, 255); padding: 10px; border-radius: 2px;
}
#message .mu_profile_img_area_content p img{
	/*width:100%; !important;*/
}
#tblidstudenr .sb-avatar__text {
    border: 2px solid #fff;
}
.text-blue{
	color:blue;
}
#edit-request-template #div-from-url .select-multi{
	width:95%!important;
}
#edit-request-template .div-ct-retem{
	display: flex;
    align-items: center;
    justify-content: space-between;

}
#request-rule .select-status
{
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 8px 0 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width:80%;
}
#request-com .select-role
{
  background: #fff;
  border: 1px solid;
  padding: 10px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 100%;
  height:40px;
}
.pdfViewer .page canvas
{
  margin: 20px!important;
  display: block;
}
.admin-page #EventQueue1 .ar-single-search.new-add.div-flex32{
	flex: 0 0 32%!important;	
}
.admin-page #EventQueue2 .student-grade-lavel-single-content.div-flex31{
	flex: 0 0 31%!important;	
}
.admin-page .ar-srech-filt-menu ul li {
    font-weight: 600;
}
.admin-page #rosters .ar-search-wrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
}
@media (min-width: 768px){
	.fix-col-3 {
		max-width: 21%;
	}
	.fix-13 {
		max-width: 13.5%;
	}
	
	.pde0{
		padding:0;text-align:right;
	}
	.pdt20{
		padding-top: 10px!important;
	}
	.pdt40{
		padding-top: 15px!important;
	}
}
.admin-page #user-tags #user-edit-assginment .ar-single-search.search21.new-add,
.admin-page #user-tags #user-edit-assginment .ar-single-search.search2.new-add,
.admin-page #user-tags #user-edit-assginment .ar-single-search.search3.new-add,
.admin-page #user-tags #user-edit-assginment .ar-single-search.search4.new-add,
.admin-page #user-tags #user-edit-assginment .ar-single-search.search5.new-add{
  flex: 0 0 12%!important;
  margin-right: 0!important;
}
.admin-page #user-tags #user-edit-assginment .ar-quality-of-work.c5,
.admin-page #user-tags #user-edit-assginment .ar-quality-of-work.c4,
.admin-page #user-tags #user-edit-assginment .ar-quality-of-work.c3,
.admin-page #user-tags #user-edit-assginment .ar-quality-of-work.c2,
.admin-page #user-tags #user-edit-assginment .ar-quality-of-work.c21{
  width: unset;
  flex: 0 0 11.5%;
  padding-left: 7px;
}
.admin-page #user-tags #user-edit-assginment .student-listing-single-content {
  flex: 0 0 34%;
}
#rosters .react-datepicker-wrapper {
  width: 66% !important;
}
#rosters .react-datepicker-wrapper input{
  width: 100%;
  line-height: 29px;
  font-size: 13px;
}
.admin-page .student-course-progress-area .ra_sortable_header_area_menu ul li {
	font-weight:600;
}
.admin-page .ra_teacher_list_area_header_single_item h2 {
	font-weight:600;
}
.admin-page #se-enroll .ar-single-search.search1.new-add {
  flex: 0 0 25%;
}
.admin-page #se-enroll .ar-single-search.search4.new-add {
  flex: 0 0 15%;
}
.admin-page #se-enroll .ar-single-search.search5.new-add {
  flex: 0 0 20%;
}
.admin-page #se-enroll .ar-single-search.search6.new-add {
  flex: 0 0 17%;
  margin-right: 0px;
}
.admin-page #se-enroll .student-listing-single-content.c1 {
  flex: 0 0 24%;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c2 {
  flex: 0 0 10%;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c4 {
  flex: 0 0 13%;
  text-align: left;
  margin-left: 10px;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c5 {
  flex: 0 0 19%;
   margin-left: 10px;
}
.admin-page #se-enroll .student-grade-lavel-single-content.c6 {
  flex: 0 0 16%;
   margin-left: 10px;
}
.parent-page #response {
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  background: #30bf39;
  border: 1px solid #baf0bd;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
}
.mgl0 input{
	margin-left:0px!important;
}
.icon-check-blue{
	font-size: 12px;
    color: blue;
    line-height: 20px;
}
.icon-check-gray {
    font-size: 12px;
    color: #e9e3e3;
    line-height: 20px;
}
.admin-page .ico-bage {
    height: 47px;
    width: auto;
   
}
#div-footet-recoline {
    display: flex;
    justify-content: flex-end;
}
.lh32 label{
	line-height: 32px;
}
.admin-page .single-change-password-right-input input[type="checkbox"],.admin-page .single-change-password-right-input input[type="radio"]
{
	width: auto;
}
.admin-page #studentoftecher .dc_dots_area .dropdown-menu{
	min-width: 220px;
}
.admin-page #sections .h2-auto.two-row {
  height: auto !important;
  max-height: 310;
  min-height: 310px;
  overflow-y: auto;
}
.admin-page #sections .view-content.two-row {
  height: 210px;
  height: 310px;
}
 #order-grades .btn-card-view {
    font-size: 10px;
    border-radius: 15px;
    padding: 3px 12px;
    background: #00c1e6;
    color: #fdfffe;
    font-weight: 600;
}
.admin-page #learning-two #order-grades .ar-single-search {
  margin-right: 0;
}
.admin-page  #learning-two #order-grades .course-sec {
  background: unset;
}
.admin-page #student .div-action .dropdown-menu.adjust.actmn {
  left: -10px !important;
  top: 7px !important;
  min-width: 180px;
}
.admin-page #addSection .btn-refresh {
  width: auto;
}
.admin-page .link-trial:hover {
  color: blue;
}
.audit-log-section .myclassroom-single-right-right-content .dropdown #launchcourse {
  padding: 6px 24px;
  border-radius: 25px;
  font-weight: 300;
  margin-left: 14px;
}
.admin-page #addSection .student-information-edit img {  
  width: 20px;
  position: absolute;
  bottom: 20px;
  left: 80px;
}
.admin-page  #addSection .student-information-heading-profile img#imgshow {
  width: 60px;
  height: auto;
  width: 100px !important;
  height: 100px !important;
  border-radius: 100%;
  border: 2px solid #fff;
}
.hideDiv {
  display: none;
}
    
.myDIV:hover + .hideDiv {
  display: block;
  color: red;
}
.myDIV{
	cursor:pointer;
}

.tooltip-n {
  position: relative;
  display: inline-block;
  cursor:pointer;
 /* border-bottom: 1px dotted black;*/
}
.tooltip-n .tooltiptext-n {
  visibility: hidden;
  min-width: 300px;
  background-color: #145b94;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 15px;
  border:1px solid #ffffff;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-n:hover .tooltiptext-n {
  visibility: visible;
}
.admin-page a.ar-reset-filter {
  position: relative;
  color: #0078bf!important;
  font-size: 14px;
  font-weight: 300;
}
.admin-page a.ar-reset-filter img {
  position: absolute;
  left: -20px;
  top: 2px;
}