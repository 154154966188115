body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f3f6f9 !important;
  font-family: "Source Sans Pro", sans-serif;
  font-family: "Avenir";
  overflow: hidden;
}
html {
  overflow-x: scroll;
}
.parent-page h1,
.parent-page h2,
.parent-page h3,
.parent-page h4,
.parent-page h5,
.parent-page h6,
.parent-page p {
  margin: 0;
}
.parent-page a:hover {
  text-decoration: none;
}
.parent-page a {
  display: inline-block;
}
.parent-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.parent-page button {
  padding: 0;
  border: none;
  outline: none !important;
}

/*Start Humber Icon Design Css*/
.parent-page .mobile-menu-icon {
  display: none;
  position: relative;
}
.parent-page .mobile-menu-icon::after {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 2px;
  height: 54px;
  right: -6px;
  top: -17px;
}
.parent-page .mobile-search-icon {
  width: 18px;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9999;
  display: none;
}
.parent-page .mobile-search-icon::after {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 2px;
  height: 54px;
  left: -20px;
  top: -12px;
}
.parent-page .mobile-search-icon img {
  width: 18px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 4px;
}
.parent-page .all-p-humber {
  width: 30px;
  height: 18px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 99;
}
.parent-page .all-p-humber span {
  display: block;
  position: absolute;
  height: 3px;
  width: 25px;
  background: #a6adb1;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.parent-page .all-p-humber span:nth-child(1) {
  top: 6px;
}
.parent-page .all-p-humber span:nth-child(2),
.parent-page .all-p-humber span:nth-child(3) {
  top: 8px;
}
.parent-page .all-p-humber span:nth-child(4) {
  top: 16px;
}
.parent-page .all-p-humber.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.parent-page .all-p-humber.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.parent-page .all-p-humber.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.parent-page .all-p-humber.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
/*End Humber Icon Design Css*/

/*================================================================================================
  ====                                    START HOME PAGE CSS                                 ====
  ================================================================================================*/

/*=============================================================================
  ====                        Start Header Area Css                        ====
  =============================================================================*/
.parent-page .header-top-area {
  background: #ffffff;
  padding-bottom: 5px;
}
.parent-page .header-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .header-top-left-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.parent-page .header-top-right-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.parent-page .header-logo-dropdown::after {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 1px;
  width: 0px;
  height: 65px;
  right: 0;
  top: 0;
}
.parent-page .header-logo-dropdown img {
  width: 250px;
  height: auto;
}
.parent-page .header-top-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .header-logo-dropdown {
  max-width: 290px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.parent-page .dropdown-toggle::after {
  display: none;
}
.parent-page .down-icon img {
  width: 12px;
  height: 8px;
  right: 22px;
  right: inherit;
  top: 25px;
  position: absolute;
}
.parent-page .dropdown-item.width {
  width: 275px;
  margin: 8px 0;
}
.parent-page .dropdown-item.width:hover {
  background: #dbe6ee;
}
.parent-page .dropdown-item:hover {
  background-color: #dbe6ee;
}
.parent-page .dropdown-menu {
  padding: 0 !important;
  margin: 2.125rem 0 0;
  display: block;
}
.parent-page .dropdown-item {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  padding: 12px 20px;
}
.parent-page .dropdown-menu.new-menu a {
  padding: 12px 20px;
}
.parent-page .header-dropdown::after {
  content: "";
  position: absolute;
  /* background: url('./images/home/down-arrow.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 15px;
  top: 26px;
  width: 12px;
  height: 8px;
  pointer-events: none;
}
.parent-page .header-dropdown select {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 275px;
  height: 52px;
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .header-dropdown select option {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  cursor: pointer;
}
.parent-page .header-flag-dropdown {
  padding-left: 15px;
  width: 80%;
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  line-height: 33px;
}
.parent-page .header-flag-dropdown img {
  width: 14px;
  height: 10px;
  margin-right: 12px;
  margin-top: -5px;
}
/* Start Custom Flug Select Option Css */
.parent-page .vodiapicker {
  display: none;
}
.parent-page #a {
  padding-left: 0px;
}
.parent-page #a img,
.parent-page .btn-select img {
  width: 14px;
  height: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -5px;
}
.parent-page #a li {
  font-weight: 400;
  font-size: 13px;
  color: #375f7f;
  text-transform: capitalize;
  padding: 7px 10px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.parent-page #a li:hover {
  background-color: #f4f3f3;
}
.parent-page #a li span,
.parent-page .btn-select li span {
  margin-left: 15px;
  margin-left: 0px;
  cursor: pointer;
  line-height: 20px;
}
/* item list */
.parent-page .b {
  display: none;
  max-width: 350px;
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 105px;
  width: 126px;
  z-index: 2;
}
.parent-page .open {
  display: show !important;
}
.parent-page .btn-select {
  font-weight: 400;
  font-size: 14px;
  color: #375f7f;
  text-transform: capitalize;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  width: 105px;
  position: relative;
      width: auto;
}
.parent-page .btn-select::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/home/down-arrow.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 0;
  top: 14px;
  width: 12px;
  height: 8px;
  right: -9px;
    top: 18px;
}
.parent-page .btn-select li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
}
.parent-page .btn-select:hover li {
  margin-left: 0px;
}
.parent-page .btn-select:focus {
  outline: none;
}
/* End Custom Flug Select Option Css */

/* Start heading code*/
.parent-page .dropdown-item.width-two:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #dbe6ee;
}
.parent-page .dropdown-item.width-two {
  width: 223px;
  margin: 8px 0;
}
.parent-page .dropdown-item.width-two span {
  background: #05233b;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #fff;
  border-radius: 12px;
  margin-left: 8px;
}
/*End Heading code*/
.parent-page .profile-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .profile-text {
  text-align: left;
  margin-left: 10px;
}
.parent-page .profile-image img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .profile-text h5 {
  font-weight: 400;
  font-size: 15px;
  color: #05233b;
  text-transform: capitalize;
}
.parent-page .profile-text p {
  font-weight: 400;
  font-size: 12px;
  color: #707070;
  text-transform: capitalize;
}
.parent-page #dropdownMenuButton {
  background: transparent;
}
.parent-page .header-top-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .header-profile-dropdown {
  padding: 0 42px 0 30px;
  position: relative;
}
.parent-page .header-profile-dropdown::before {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 1px;
  height: 65px;
  left: 0;
  top: -12px;
}
.parent-page .header-profile-dropdown::after {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 1px;
  height: 65px;
  right: 0;
  top: -12px;
}
.parent-page .help-contetn a span {
  font-weight: 300;
  font-size: 12px;
  color: #05233b;
  text-transform: capitalize;
}
.parent-page .help-contetn img {
  width: 12px;
  height: auto;
  margin-top: -5px;
}
.parent-page .help-contetn {
  padding-left: 30px;
}
.parent-page .dropdown-item.width-two {
  width: 223px;
}
.parent-page .dropdown-menu.adjust {
  top: 0px !important;
  left: -50px !important;
}
.parent-page .down-icon-two img {
  width: 12px;
  height: 8px;
  right: -18px;
  top: 32px;
  position: absolute;
}
/* Style the tab content */
.parent-page .tabcontent {
  display: none;
}
/* Style the tab content */
/* Start Header Bottom Area Css */
.parent-page .header-bottom-area {
  background: #fafbfc;
}
.parent-page .header-bottom-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .header-bottom-content ul li a,
.parent-page .header-bottom-content ul li button.menu-drop,
.parent-page #mu_accordion li .link a {
  font-weight: 400;
  font-size: 14px;
  color: #05233b;
  text-transform: capitalize;
  padding: 18px 15px;
  position: relative;
}
.parent-page #mu_accordion li .link a {
  padding: 0;
  display: block;
}
.parent-page .tablinks i {
  top: 5px !important;
}
.parent-page .header-bottom-content ul li button.menu-drop {
  padding-right: 30px;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
}
.parent-page .header-bottom-content ul li button.menu-drop:hover {
  color: #0078bf;
  border-bottom: 3px solid #0078bf;
}
.parent-page .header-bottom-content ul li a.actives,
.parent-page .header-bottom-content ul li a:hover {
  color: #0078bf;
}
.parent-page .header-bottom-content ul li a:hover::after,
.parent-page .header-bottom-content ul li button.menu-drop:hover::after {
  content: "";
  position: absolute;
  background: #0078bf;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parent-page .header-bottom-content ul li a:hover::after,{
  content: "";
  position: absolute;
  background: #0078bf;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parent-page .header-bottom-content .dropdown-item.width-two.active{
	    background-color: #dbe6ee;
}
.parent-page .header-bottom-content ul li a.active :hover::after
{
  content: "";
  position: absolute;
  background: #0078bf;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parent-page .header-bottom-content ul li a.actives::after {
  content: "";
  position: absolute;
  background: #0078bf;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parent-page .header-bottom-content ul li a img,
.parent-page .header-bottom-content ul li button.menu-drop img,
.parent-page #mu_accordion li .link img {
  height: 14px;
  width: auto;
  margin-top: -7px;
  margin-right: 8px;
}
.parent-page .header-bottom-content ul li a span {
  background: #145b94;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 1px 5px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}
.parent-page .down-icon-three img {
  width: 12px !important;
  height: 8px !important;
  right: 10px;
  bottom: 24px;
  position: absolute;
}

/* End Header Bottom Area Css */

/* Start Home Text Area Css */
.parent-page .home-text-area {
  /* background: url('../assets/images/home/home-text.png'); */
  padding: 18px 0;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #00a2a7;
}
.parent-page .home-text-content h4 {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  line-height: 1.2;
}
/* End Home Text Area Css */

/*=============================================================================
  ====                         End Header Area Css                         ====
  =============================================================================*/

/*=============================================================================
  ====                     Start Home Progress Area Css                    ====
  =============================================================================*/
.parent-page .home-progress-area {
  background: #f3f6f9;
  padding-bottom: 25px;
}
.parent-page .home-progress-content {
  padding-top: 30px;
}
.parent-page .top-progress-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-top-progress-area {
  /* background: url('../assets/images/home/background_image.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  padding-bottom: 30px;
  border-radius: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  border: 3px solid transparent;
  cursor: pointer;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  position: relative;
}
.parent-page .single-top-progress-area:hover {
  border: 3px solid #006365;
}
.parent-page .top-progress-area .single-top-progress-area:nth-child(2) {
  /* background: url('../assets/images/home/background-image-two.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.parent-page .single-top-progress-heading-text p {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .single-top-progress-count-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 15px;
}
.parent-page .single-top-pera-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-top-progress-count-text h1,
.parent-page .single-top-progress-count-text span {
  font-weight: 900;
  font-size: 28px;
  color: #ffffff;
  text-transform: inherit;
  line-height: 1;
}
.parent-page .single-top-progress-count-text h5 {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-transform: lowercase;
  line-height: 1.2;
  margin-left: 8px;
}
.parent-page .single-top-progress-last-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-top-progress-last-text .single-top-progress-count-text:nth-child(2) {
  margin-left: 15px;
}
/* Start All Progressbar Css */
.parent-page .active-progress {
  width: 215px;
  height: 7px;
  background: #e4e6ef;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 8px;
}
.parent-page .myclassroom-single-left-cross-progress {
  width: 150px;
  height: 7px;
  background: #e4e6ef;
  border-radius: 5px;
}
.parent-page .barra-nivel {
  height: 7px;
  background: #709bbe;
  width: 1px;
  -webkit-transition: width 2s ease;
  -o-transition: width 2s ease;
  transition: width 2s ease;
  border-radius: 5px;
}
/* End All Progressbar Css */

/* Start MyClassroom Area Css */
.parent-page .myclassroom-area {
  padding-top: 20px;
}
.parent-page .myclassroom-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-header-left p {
  font-weight: 300;
  font-size: 18px;
  color: #05233b;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .myclassroom-header-left span {
  font-weight: 400;
  font-size: 13px;
  color: #05233b;
  text-transform: inherit;
  background: #dbe6ee;
  padding: 0 10px;
  border-radius: 10px;
  margin-left: 15px;
}
.parent-page .myclassroom-header-single p {
  font-weight: 400;
  font-size: 14px;
  color: #05233b;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .myclassroom-header-single img {
  height: 14px;
  width: auto;
  margin-left: 25px;
  margin-top: -6px;
}
.parent-page .myclassroom-content {
  background: #ffffff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-single-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}
.parent-page .myclassroom-single-left-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 62%;
  flex: 0 0 62%;
  cursor: pointer;
  padding: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .myclassroom-single-right-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  padding: 20px;
}
.parent-page .myclassroom-single-left-content:hover {
  background: #e4e6e7;
}
.parent-page .myclassroom-single-left-content-image {
  position: relative;
  width: 70px;
}
.parent-page .myclassroom-single-left-content-image img {
  width: 50px;
  height: auto;
  border-radius: 3px;
}
.parent-page .myclassroom-single-left-new-button button {
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
  background: #00a2a7;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 3px;
  position: absolute;
  right: 18px;
  bottom: 0px;
}
.myclassroom-single-right-content-text {
  width: 185px;
}
.parent-page .myclassroom-single-left-content-top-text h1 {
  font-weight: 300;
  font-size: 18px;
  color: #181c32;
  text-transform: inherit;
}
.parent-page .myclassroome-single-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* Start Course Progressbar Css */
.parent-page .meter {
  background: #e4e6ef;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  display: block;
  height: 7px;
  position: relative;
  width: 150px;
}
.parent-page .meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3) inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3) inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  -webkit-transition: width 2s ease-out;
  -o-transition: width 2s ease-out;
  transition: width 2s ease-out;
  background: #00a2a7;
}

.parent-page .red > span {
  background-color: #f0a3a3;
  background-image: -o-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f0a3a3),
    to(#f42323)
  );
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
}
.parent-page .cadetblue > span {
  background-color: #5f9ea0;
  background-image: -o-linear-gradient(top, #0ff, #1e90ff);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0ff),
    to(#1e90ff)
  );
  background-image: linear-gradient(to bottom, #0ff, #1e90ff);
}
/* End Course Progressbar Css */

.parent-page .myclassroom-single-left-content-top-text p {
  font-weight: 500;
  font-size: 11px;
  color: #3e3f42;
  text-transform: capitalize;
}
.parent-page .myclassroom-single-left-content-course-progress p {
  font-weight: 400;
  font-size: 10px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.parent-page .course-progress-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-single-left-content-course-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.parent-page .course-progress-right h5,
.parent-page .course-progress-right span {
  font-weight: 400;
  font-size: 10px;
  color: #3f4254;
  text-transform: inherit;
  line-height: 9px;
}
.parent-page .course-progress-left {
  padding-top: 5px;
}
.parent-page .profile-left-section-content img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .profile-right-section-content p {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  line-height: 10px;
}
.parent-page .profile-right-section-content span {
  font-weight: 400;
  font-size: 12px;
  color: #3f4254;
  text-transform: capitalize;
  line-height: normal;
}
.parent-page .profile-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}
.parent-page .profile-right-section-content {
  margin-top: 8px;
  margin-left: 10px;
}
.parent-page .single-myclass-right-content span {
  background: #05233b;
  padding: 1px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #fff;
  border-radius: 12px;
  margin-left: 6px;
}
.parent-page .single-myclass-right-content.myclassroom-slider span {
  padding: 0 8px;
}
.parent-page .single-myclass-right-content img {
  width: 16px !important;
  height: auto;
}
.parent-page .myclassroom-single-right-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-myclass-right-content {
  width: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
}
.parent-page .single-myclass-right-content.myclassroom-slider {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .myclassroom-single-right-right-content .dropdown button {
  font-weight: 400;
  font-size: 12px;
  color: #0078bf;
  border: 1px solid #0078bf;
  padding: 4px 15px;
  border-radius: 25px;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .myclassroom-single-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .dropdown-menu.course {
  right: 0 !important;
  left: inherit !important;
}
/* End MyClassroom Area Css */
.parent-page .see-all-class-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  text-align: center;
  display: block;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 5px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
/* Start Inbox Messages Area Css */
.parent-page .inbox-messages-area {
  background: #b2d8ef;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.parent-page .inbox-message-left-header h1 {
  font-weight: 300;
  font-size: 18px;
  color: #05233b;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .home-progress-right-content {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.parent-page .single-inbox-messages-content {
  background: #ffffff;
  padding: 10px 20px;
  border-bottom: 1px solid #eaedf3;
}
.parent-page .inbox-message-left-header a {
  font-weight: 500;
  font-size: 12px;
  color: #145b94;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .inbox-message-left-header a span {
  background: #145b94;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #fff;
  border-radius: 12px;
  margin-left: 4px;
}
.parent-page .inbox-message-right-header img {
  width: 18px;
  height: auto;
}
.parent-page .inbox-message-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.parent-page .single-inbox-messages-content p {
  font-weight: 500;
  font-size: 12px;
  color: #3e3f42;
  text-transform: inherit;
}
.parent-page .single-inbox-messages-content p span {
  color: #983e00;
}
.parent-page .single-inbox-date-button p {
  font-weight: 400;
  font-size: 10px;
  color: #3e3f43;
  text-transform: inherit;
  line-height: normal;
}
.parent-page .single-inbox-date-button.announcement a,
.parent-page .single-inbox-date-button a {
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
  background: #c55a11;
  padding: 3px 10px;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .single-inbox-date-button a {
  background: #e4e6ef;
  padding: 3px 15px;
  color: #3f4254;
}
.parent-page .single-inbox-date-button a:hover {
  background: #c55a11;
  color: #ffffff;
}
.parent-page .single-inbox-date-button.announcement a:hover {
  background: #e4e6ef;
  color: #3f4254;
}
.parent-page .single-inbox-date-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 5px;
}
.parent-page .inbox-messages-content .single-inbox-messages-content:nth-child(1) {
  position: relative;
}
.parent-page .inbox-messages-content .single-inbox-messages-content:nth-child(1)::after {
  content: "";
  position: absolute;
  background: #c55a11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.parent-page .inbox-messages-content .single-inbox-messages-content:nth-child(3) {
  position: relative;
}
.parent-page .inbox-messages-content .single-inbox-messages-content:nth-child(3)::after {
  content: "";
  position: absolute;
  background: #c55a11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.parent-page .view-all-message a {
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  text-align: center;
  display: block;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 5px;
}
/* End Inbox Messages Area Css */

/*=============================================================================
  ====                      End Home Progress Area Css                     ====
  =============================================================================*/
.parent-page .upcoming-area {
  background-color: #f3f6f9;
  padding-bottom: 90px;
}
.parent-page .upcoming-area tbody td {
  border-right: 1px solid #eaedf3;
  height: 400px;
  padding: 0;
  border-bottom: 1px solid #eaedf3;
}
.parent-page .upcoming-area .monday {
  background-color: #b2d8ef !important;
  color: #ffffff !important;
}
.parent-page .upcoming-area .monday-first-card {
  background-color: #b2d8ef;
  border-left: 2px solid #387ebe;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 6px;
}
.parent-page .upcoming-area p {
  font-size: 12px;
  text-align: left;
  padding: 2px;
}
.parent-page .upcoming-area tr {
  position: relative;
}
.parent-page .shasha#dropdownMenuButton {
  padding: 0;
  padding-right: 20px;
}
.parent-page .upcoming-area .monday-second-card {
  position: absolute;
  background-color: #ebecf3;
  border-left: 2px solid #c8cbde;
  bottom: 50px;
  width: 14%;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 6px;
}
.parent-page .down-icon-last img {
  width: 11px;
  height: auto;
  position: absolute;
  right: 0;
  top: 10px;
}
.parent-page .upcoming-area .tudesday-card {
  position: absolute;
  background-color: #dbe6ee;
  border-left: 2px solid #b2d8ef;
  bottom: 111px;
  width: 13.8%;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 6px;
}
.parent-page .upcoming-area .friday-card {
  position: absolute;
  background-color: #e5f4f4;
  border-left: 2px solid #b2d8ef;
  top: 172px;
  width: 12%;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 6px;
}
.parent-page .upcoming-area .events p {
  font-weight: 900;
  font-size: 18px;
  color: #05233b;
}
.parent-page .upcoming-area .btn-group .btn {
  border: 1px solid #e8e8e8;
  background-color: transparent;
}
.parent-page .upcoming-area .btn-group .first-btn {
  padding-top: 14px;
}
.parent-page .btn.first-btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.parent-page #dropdownMenuButton {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.parent-page .btn.first-btn {
  cursor: pointer;
}
.parent-page .btn.first-btn img {
  width: 8px;
  height: auto;
  margin-top: -9px;
}
.parent-page .upcoming-heading-content.homepage {
  background: #ffffff;
}
.parent-page .upcoming-heading-content p {
  font-weight: 300;
  font-size: 18px;
  color: #05233b;
}
.parent-page .table thead th {
  font-weight: 300;
}
.parent-page .upcoming-heading-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  padding: 5px 15px;
}
.parent-page .dropdown-toggle.shfocus:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.parent-page .btn {
  font-size: 14px;
}
.parent-page .btn.items.shasha {
  padding-right: 25px;
}
/*=============================================================================
  ====                      Start Footer Area Area Css                     ====
  =============================================================================*/
.parent-page .footer-area {
  background: #080f1e;
  padding-top: 48px;
  padding-bottom: 28px;
}
.parent-page .footer-area .container {
  position: relative;
}
.parent-page .footer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 80px;
}
.parent-page .single-footer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 9;
}
.parent-page .footer-links ul li a {
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
  line-height: normal;
  padding-bottom: 10px;
}
.parent-page .footer-logo img {
  width: 140px;
  height: auto;
  margin-right: 20px;
}
.parent-page .need-help-content p {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: inherit;
  padding-bottom: 5px;
}
.parent-page .contact-button a {
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  padding: 4px 25px;
  border-radius: 15px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background: transparent;
}
.parent-page .contact-button a:hover {
  background: #0078bf;
  border-color: #0078bf;
  color: #fff;
}
.parent-page .copyright-text {
  padding-top: 10px;
  border-top: 1px solid #7e8299;
}
.parent-page .copyright-text p,
.parent-page .copyright-text p a {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: inherit;
  text-align: center;
}

/* Start Chating Area Css */
.parent-page .chating-area {
  max-width: 1140px;
  width: 100%;
}
.parent-page .chating-content {
  max-width: 265px;
  width: 100%;
  border: 1px solid #addedc;
  border-top-right-radius: 25px;
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 1;
  border-top-left-radius: 25px;
  z-index: 99999;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.parent-page .chating-content.show {
  bottom: -100%;
}
.parent-page .chating-heading,
.parent-page .chating-heading-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #0078bf;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 7px 20px;
  cursor: pointer;
}
.parent-page .chating-heading-area {
  position: fixed;
  right: 15px;
  bottom: 15px;
  max-width: 265px;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
  z-index: 9999;
}
.parent-page .chating-heading-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.parent-page .chating-heading-left p {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 1.2;
  text-transform: capitalize;
}
.parent-page .chating-heading-left img {
  width: 18px;
  height: auto;
  margin-top: -5px;
  margin-right: 10px;
}
.parent-page .chating-heading-left p span {
  background: #ffffff;
  padding: 1px 10px;
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  border-radius: 8px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.parent-page .chating-heading-right button {
  background: none;
}
.parent-page .chating-heading-right button img {
  width: 16px;
  height: auto;
  margin-left: 10px;
  margin-top: -6px;
}
.parent-page .single-chating-left-image img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.parent-page .single-chating-content {
  background: #addedc;
  padding: 10px;
  border-bottom: 1px solid #eaedf3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.parent-page .single-chating-content:hover {
  background: #ffffff;
}
.parent-page .single-chating-left-content p {
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  text-transform: inherit;
  line-height: 1.5;
}
.parent-page .single-chating-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-chating-right-content p {
  font-weight: 400;
  font-size: 10px;
  color: #3e3f43;
  text-transform: inherit;
  line-height: 1.5;
}
.parent-page .single-chating-left-content p:nth-child(2) {
  color: #686868;
}
.parent-page .view-all-button a {
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  text-transform: inherit;
  text-align: center;
  display: block;
  padding: 8px 10px;
}
/* End Chating Area Css */
/*=============================================================================
  ====                       End Footer Area Area Css                      ====
  =============================================================================*/

/*================================================================================================
  ====                                     END HOME PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                                 START HOME CARD PAGE CSS                               ====
  ================================================================================================*/

/*=============================================================================
  ====                    Start Myclassroom Slider Area Css                ====
  =============================================================================*/
.parent-page .myclassroom-slider-area {
  padding-left: 35px;
}
.parent-page .single-myclassroom-slider-content {
  background: #ffffff;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .single-myclassroom-slider-content:hover {
  border: 2px solid #0078bf;
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  margin-left: 5px;
}
.parent-page .single-myclassroom-slider {
  padding: 7px;
}
.parent-page .single-myclassroom-slider-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-myclassroom-slider-middle-heading h1 {
  font-weight: 500;
  font-size: 15px;
  color: #3e3f42;
  text-transform: capitalize;
  line-height: 1.2;
}
.parent-page .single-myclassroom-slider-middle-heading p {
  font-weight: 500;
  font-size: 11px;
  color: #3e3f42;
  text-transform: capitalize;
}
.parent-page .single-myclassroom-slider-middle-heading {
  padding-top: 12px;
  padding-bottom: 2px;
}
.parent-page .slider-course-progress-left p {
  font-weight: 400;
  font-size: 9px;
  color: #707070;
  text-transform: inherit;
}
.parent-page .slider-course-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.parent-page .course-progress-right {
  margin-left: 12px;
  margin-top: 14px;
}
.parent-page .single-slider-profile-left img {
  width: 25px;
  height: 25px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.parent-page .single-slider-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 12px;
}
.parent-page .single-slider-profile-right {
  margin-left: 8px;
}
.parent-page .single-slider-profile-right h2 {
  font-weight: 400;
  font-size: 11px;
  color: #181c32;
  text-transform: capitalize;
  line-height: normal;
}
.parent-page .single-slider-profile-right p {
  font-weight: 400;
  font-size: 9px;
  color: #3f4254;
  text-transform: capitalize;
  line-height: normal;
}
.parent-page .launch-course-button button {
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
  background: #0078bf;
  border: none;
  width: 180px;
  padding: 5px 10px;
  border-radius: 25px;
  text-align: center;
  text-transform: inherit;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 auto;
  display: block;
}
.parent-page .launch-course-button button:hover {
  background: transparent;
  color: #0078bf;
}
.parent-page .view-course-button button {
  font-weight: 500;
  font-size: 11px;
  color: #0078bf;
  background: transparent;
  border: none;
  width: 180px;
  padding: 5px 10px;
  border-radius: 25px;
  text-align: center;
  text-transform: inherit;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 auto;
  margin-top: 3px;
  display: block;
}
.parent-page .view-course-button button:hover {
  background: #0078bf;
  color: #ffffff;
}
.parent-page .slider-course-progress-left p {
  font-weight: 400;
  font-size: 9px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.parent-page .single-myclassroom-slider-heading a,
.parent-page .single-myclassroom-slider-heading p {
  font-weight: 500;
  font-size: 9px;
  color: #3f4254;
  border-radius: 3px;
  text-transform: capitalize;
}
.parent-page .single-myclassroom-slider-heading a {
  width: 45px;
  text-align: center;
}
.parent-page .single-myclassroom-slider-heading a {
  background: #dbe6ee;
  padding: 2px 6px;
}
.parent-page .single-myclassroom-slider-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-myclassroom-slider-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #b2d8ef;
  padding: 7px;
}
.parent-page .single-myclassroom-slider-bottom-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 135px;
}
.parent-page .single-myclassroom-slider-bottom-right img {
  width: 20px !important;
  height: auto;
}
.parent-page .see-all-class-slider-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  text-align: right;
  display: block;
}
/* Start Owl Carousel Customize Css */
.parent-page .myclassroom-slider-area::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/home-card/myclassroom-slider-after.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: -12px;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 1;
}
.parent-page .owl-theme .owl-nav {
  margin-top: 0;
  height: 0;
}
.parent-page .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
}
.parent-page .owl-theme .owl-nav [class*="owl-"]:hover {
  color: transparent;
}
.parent-page .owl-theme .owl-nav [class*="owl-"] {
  margin: 0;
}
.parent-page .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: 0px;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.parent-page .owl-theme .owl-nav .owl-prev img:hover {
  opacity: 0.8;
}
.parent-page .owl-theme .owl-dots .owl-dot.active span,
.parent-page .owl-theme .owl-dots .owl-dot:hover span {
  width: 12px;
  height: 12px;
  background: #3f4254;
  border: 2px solid #3f4254;
}
.parent-page .owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: transparent;
  border: 2px solid #3f4254;
}

/*Rakib start*/
.parent-page .ra_resources_area .owl-theme .owl-dots {
  bottom: -29px;
}
.parent-page .ra_resources_content .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.parent-page .ra_resources_content .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999;
}
.parent-page .ra_event_area .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.parent-page .ra_event_area .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999;
}
.parent-page .ra_event_main_area_slider {
  padding: 0 35px 0 45px;
}
.parent-page .ra_event_main_area_slider .owl-theme .owl-nav .owl-next img {
  right: -33px;
}
.parent-page .btn.first-btn.extra_btn_btnclass {
  padding: 7.5px 13px;
}
.parent-page .btn {
  padding: 0.38rem 0.75rem;
}
/*Rakib end*/

/* End Owl Carousel Customize Css */

/*=============================================================================
  ====                     End Myclassroom Slider Area Css                 ====
  =============================================================================*/

/*================================================================================================
  ====                                  END HOME CARD PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                                   START LOGIN PAGE CSS                                 ====
  ================================================================================================*/
.parent-page .login-header {
  background: #ffffff;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.parent-page .login-logo img {
  width: 250px;
  height: auto;
}
.parent-page .login-content {
  max-width: 485px;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  padding: 25px 68px;
}
.parent-page .login-heading h1 {
  font-weight: 500;
  font-size: 35px;
  color: #05233b;
  text-transform: inherit;
  line-height: 55px;
  padding-bottom: 15px;
  text-align: center;
}
.parent-page .single-login-form {
  margin-bottom: 15px;
}
.parent-page .single-login-form label {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  display: block;
  text-align: left;
  margin-bottom: 5px !important;
}
.parent-page .single-login-form input {
  width: 100%;
  border: 1px solid #3f4254;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  outline: none;
}
.parent-page .single-login-form input:focus {
  border: 2px solid #0078bf;
  border-radius: 5px;
}
.parent-page .single-login-form input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-login-form input::-moz-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-login-form input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-login-form input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-login-form input::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-login-submit-button input {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #0078bf;
  border: none;
  width: 100%;
  padding: 8px 15px;
  border-radius: 30px;
  margin-top: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .single-login-submit-button input:hover {
  background: #3f4254;
}
.parent-page .login-form-text a {
  font-weight: 500;
  font-size: 14px;
  color: #0078bf;
  text-transform: inherit;
  line-height: normal;
}
.parent-page .login-form-text a:nth-child(1) {
  padding: 20px 0;
}
.parent-page .login-form-text a span {
  color: #05233b;
}
.parent-page .login-form-logo {
  text-align: center;
  margin-top: 15px;
  position: relative;
}
.parent-page .login-form-logo::before {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 78px;
  height: 2px;
  left: 0;
  top: 22px;
}
.parent-page .login-form-logo::after {
  content: "";
  position: absolute;
  background: #e4e6ef;
  width: 78px;
  height: 2px;
  right: 0;
  top: 22px;
}
.parent-page .login-form-logo img {
  width: 160px;
  height: auto;
}
.parent-page .single-login-checkbox-form input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.parent-page .single-login-checkbox-form label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  display: block;
  text-align: left;
  margin-bottom: 5px !important;
}
.parent-page .single-login-checkbox-form label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #05233b;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
  top: -5px;
}
.parent-page .single-login-checkbox-form input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #05233b;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.parent-page .login-footer-area {
  background: #080f1e;
  padding-top: 55px;
  padding-bottom: 30px;
}
.parent-page .login-footer-content {
  max-width: 485px;
  width: 100%;
  margin: 0 auto;
}
.parent-page .login-footer-top-left-content img {
  width: 145px;
  height: auto;
}
.parent-page .login-footer-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #7e8299;
}
.parent-page .login-footer-top-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-login-footer-left a {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: inherit;
  margin-right: 15px;
}
.parent-page .single-login-footer-right a {
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  padding: 4px 25px;
  border-radius: 15px;
}
.parent-page .login-footer-bottom-content p {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: inherit;
  text-align: center;
  padding-top: 12px;
}
.parent-page .login-footer-bottom-content p a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  line-height: 10px;
}
.parent-page .login-footer-bottom-content p span {
  color: #7e8299;
}
/*================================================================================================
  ====                                    END LOGIN PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                           Start Resource Cards Page (10)(Munna)                        ====
  ================================================================================================*/
/*Start Alert Area*/
.parent-page .mu_resource_cards {
  background: #f3f6f9;
}
.parent-page .mu_alert_box,
.parent-page .custom_alert_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #addedc;
  padding: 14px;
  margin-top: 24px;
  border-radius: 4px;
}
.parent-page .mu_alert_box_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_alert_box_left img {
  width: 22px;
  height: 22px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 17px;
}
.parent-page .mu_alert_box_left p {
  font-size: 14px;
  color: #3f4254;
}
.parent-page .mu_alert_box_right a,
.parent-page .custom_alert_right a {
  font-size: 12px;
  font-weight: 300;
  color: #3f4254;
  display: inherit;
  margin-right: 10px;
}
/*End Alert Area*/

/*================== Start Dropdown Menu & Search Area ==================*/
/*Start Dropdown*/
.parent-page .dropdown_search_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .mu_dropdown_menu_content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
}
.parent-page .mu_search_bar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
}
.parent-page .mu_dropdown_menu_area {
  background: #f3f6f9;
  margin-top: 39px;
}
.parent-page .mu_dropdown_menu_content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dropdown_menu_content ul li {
  color: #3f4254;
  margin-right: 20px;
  font-size: 12px;
}
.parent-page .mu_dropdown_menu_content ul li a,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  padding: 18px 0;
  position: relative;
  background: transparent;
}
.parent-page .mu_dropdown_menu_content ul li button.menu-drop {
  padding-right: 30px;
}
.parent-page .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
  color: #0078bf;
  border-bottom: 3px solid #0078bf;
}
.parent-page .mu_dropdown_menu_content ul li a.active,
.parent-page .mu_dropdown_menu_content ul li a:hover {
  color: #0078bf;
}

.parent-page .mu_dropdown_menu_content ul li a img,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop img {
  height: 14px;
  width: auto;
  margin-top: -7px;
  margin-right: 2px;
}
.parent-page .mu_dropdown_menu_content ul li a span {
  background: #145b94;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 1px 5px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}
/*End Dropdown*/

/*Start Mobile Menu Icon*/
.parent-page #nav-icon3 {
  display: none;
}
.parent-page #nav-icon1,
.parent-page #nav-icon2,
.parent-page #nav-icon3,
.parent-page #nav-icon4 {
  width: 25px;
  height: 20px;
  position: relative;
  /*margin: 0px auto;*/
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.parent-page #nav-icon1 span,
.parent-page #nav-icon3 span,
.parent-page #nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #a6adb1;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
/* Icon 3 */
.parent-page #nav-icon3 span:nth-child(1) {
  top: 0px;
}
.parent-page #nav-icon3 span:nth-child(2),
.parent-page #nav-icon3 span:nth-child(3) {
  top: 8px;
}
.parent-page #nav-icon3 span:nth-child(4) {
  top: 16px;
}
.parent-page #nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.parent-page #nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.parent-page #nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.parent-page #nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
/*End Mobile Menu Icon*/

/* Start Mobile Search Box Css */
.parent-page #search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; */
  z-index: 2;
}
.parent-page #search input[type="search"] {
  position: absolute;
  top: 50%;
  max-width: 450px;
  width: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  outline: none;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-bottom: 1px solid #fff;
  padding: 5px 15px;
}
.parent-page #search input[type="search"]::-webkit-input-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]::-moz-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]:-ms-input-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]::-ms-input-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]::-webkit-input-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]::-moz-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]:-ms-input-placeholder {
  color: #ffffff;
}
.parent-page #search input[type="search"]::placeholder {
  color: #ffffff;
}
.parent-page #search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #0078bf;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 30px;
  text-transform: uppercase;
  margin-top: 20px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 8px;
  border: none;
}
.parent-page .volver-button a {
  font-weight: 400;
  font-size: 17px;
  color: #231f20;
  background: #00f1a4;
  padding: 8px 45px;
  border-radius: 10px;
  margin-top: 60px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page #search .btn:hover {
  background: #addedc;
  color: #3e3f42;
}
.parent-page #search .btn.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.parent-page #search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  background-color: #0078bf;
  opacity: 1;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; */
  font-size: 15px;
  outline: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page #search .close:hover {
  background: #addedc;
  color: #3e3f42;
}
.parent-page #search button.close::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/ico/cross.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  left: 12px;
  top: 12px;
  -webkit-transform: rotate(133deg);
  -ms-transform: rotate(133deg);
  transform: rotate(133deg);
}
.parent-page #search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; */
}
/* End Mobile Search Box Css */

/*Start Mobile Menu Responsive*/
.parent-page .mu_full_menu_area {
  position: relative;
}
.parent-page .mu_accordion {
  display: none;
  position: absolute;
  width: 100%;
  background: #fafbfc;
  top: 55px;
  left: 0;
  z-index: 9991;
}
.mu_accordion.hide {
  display: none !important;
}
.parent-page .mu_accordion .link {
  cursor: pointer;
  display: block;
  padding: 22px 0px 20px 36px;
  color: #05233b;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.parent-page .mu_accordion li:last-child .link {
  border-bottom: 0;
}
.parent-page .mu_accordion li i {
  position: absolute;
  top: 20px;
  left: 43px;
  font-size: 23px;
  color: #a6adb1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.parent-page .mu_accordion li i.fa-chevron-down {
  right: 35px;
  left: auto;
  font-size: 16px;
  top: 2px;
  color: #000000;
}
.parent-page .mu_accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
/*submenu*/
.parent-page .mu_submenu {
  display: none;
  background: #fff;
  font-size: 14px;
}
.parent-page .mu_submenu li {
  border-bottom: 1px solid #e4e6ef;
}
.parent-page .mu_submenu a {
  display: block;
  text-decoration: none;
  color: #05233b;
  padding: 20px;
  padding-left: 36px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.parent-page .mu_submenu a:hover {
  background: #fafbfc;
  color: #05233b;
}
/*End Mobile Menu Responsive*/

/*Start Search Bar*/
.parent-page .mu_search_bar button {
  font-size: 14px;
  color: #ffffff;
  position: relative;
}
.parent-page .mu_search_bar input[type="search"] {
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 7px 0px 7px 40px;
  outline: none;
  width: 260px;
  color: #505355;
  font-size: 14px;
  border-radius: 3px 0 0 3px;
}
.parent-page .mu_search_bar input[type="search"]:focus {
  -webkit-box-shadow: 0px 0px 10px #bbb;
  box-shadow: 0px 0px 10px #bbb;
}
.parent-page .mu_search_bar button {
  border: none;
  background: #05233b;
  color: #fff;
  outline: none;
  padding: 8px 15px;
  margin-left: -5px;
  border-radius: 0 3px 3px 0;
}
.parent-page .mu_search_bar button img {
  margin: -2px 0 0 40px;
}
.parent-page .search_box_area,
.parent-page .mu_search_bar {
  position: relative;
}
.parent-page .mu_search_bar form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.parent-page .search_icon {
  position: absolute;
  top: 3px;
  padding: 0 10px;
}
.parent-page .search_icon img {
  width: 19px;
  height: auto;
  cursor: pointer;
}
.parent-page .mu_dropdown {
  background: #fff;
  width: 170px;
  border: 1px solid #ddd;
  position: absolute;
  right: 0;
  bottom: -126px;
  border-radius: 4px;
  z-index: 999;
}
.parent-page .mu_dropdown ul li a {
  color: #3f4254;
  font-size: 14px;
  display: block;
  padding: 8px 20px;
  border-bottom: 3px solid transparent;
}
.parent-page .mu_dropdown ul li a:hover {
  background-color: #e4e6efa3;
}
/* End Search Bar */

/* Start View icons*/
.parent-page .mu_view_icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_view_icons ul li {
  margin-right: 20px;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .mu_view_icons ul li:last-child {
  margin-right: 0;
}
.parent-page .mu_view_icons ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .view_img_1 {
  width: 11px;
}
.parent-page .view_img_2 {
  width: 17px;
}
/* End View icons*/
/*=================== End Dropdown Menu Area =================*/

/*========================== Start Grid Box Area ====================*/
.parent-page .mu_grid_box_area {
  background: #f3f6f9;
}
.parent-page .mu_single_all_grid_boxs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .mu_single_grid_boxs {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18.5%;
  flex: 0 0 18.5%;
  margin-bottom: 30px;
}
.parent-page .mu_single_grid_box {
  background: #fff;
  padding: 9px;
  border: 1px solid #e4e6ef;
  border-radius: 3px;
}
.parent-page .mu_date_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}
.parent-page .mu_date_img ul li {
  font-size: 9px;
}
.parent-page .mu_date_img img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}
.parent-page .mu_single_grid_box h6 {
  font-size: 15px;
  font-weight: 400;
  color: #3e3f42;
  margin-bottom: 7px;
}
.parent-page .mu_single_grid_box p {
  font-size: 12px;
  font-weight: 400;
  color: #3e3f42;
  line-height: 15px;
}
.parent-page .mu_grid_box_pdf_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_grid_box_pdf_left img {
  width: 13px;
  height: 13px;
  margin-right: 7px;
}
.parent-page .mu_grid_box_pdf_left a {
  font-size: 9px;
  color: #145b94;
  text-decoration: underline;
}
.parent-page .mu_grid_box_pdf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .mu_grid_box_pdf_right {
  margin: 3px 0;
}
.parent-page .mu_grid_box_pdf_right a {
  color: #3f4254;
  font-size: 9px;
}
/*PDF Client*/
.parent-page .mu_pdf_client {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #b2d8ef;
  padding: 6px 9px;
}
.parent-page .mu_pdf_client_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_pdf_client_left img {
  margin-right: 5px;
  width: 23px;
  height: 23px;
}
.parent-page .mu_pdf_client_content h6 {
  font-size: 12px;
  color: #181c32;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.parent-page .mu_pdf_client_content p {
  font-size: 10px;
  color: #3f4254;
}
.parent-page .mu_pdf_client_right a img {
  width: 20px;
  height: 20px;
}

/*Footer Button (10)(Munna)*/
.parent-page .mu_footer_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}
.parent-page .mu_footer_btn a img {
  width: 120px;
  border: 1px solid #fff;
  margin-right: 10px;
}
/*===============================================================================================
  ====                           End Resource Cards Page (10)(Munna)                         ====
  ===============================================================================================*/

/*================================================================================================
  ====                                     START STUDENT PAGE CSS                             ====
  ================================================================================================*/

/*=============================================================================
  ====                      Start student_pages_content_area Css           ====
  =============================================================================*/

/*student_action_alert_area*/
.parent-page .student_action_alert_area .custom_alert_design {
  background: rgba(230, 119, 43, 0.29);
  margin-top: 0;
  margin-bottom: 5px;
}
/*End student_action_alert_area*/

/*student_pages_content_area*/
.parent-page .student_pages_content_area {
  padding-bottom: 462px;
  position: relative;
}
.parent-page .infor_heading_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .main_student_information_area {
  background: #fff;
  margin-top: 14px;
}
.parent-page .left_infor_area .infor_profile img {
  max-width: 60px;
  width: 100%;
  height: auto;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 7px;
  margin-left: 11px;
}
.parent-page .infor_profile {
  position: relative;
}
.parent-page .infor_profile a {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
}
.parent-page .right_infor_area a img {
  max-width: 13px;
  width: 100%;
  height: auto;
}
.parent-page .right_infor_area a span {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  margin-left: 2px;
}
.parent-page .right_infor_area a {
  margin-top: 15px;
  margin-right: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .left_infor_area .infor_profile a img {
  max-width: 21px;
  width: 100%;
  height: auto;
}
.parent-page .information_biodata h4 {
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  color: #3f4254;
  margin-top: -15px;
}
.parent-page .infor_bio_top {
  max-width: 197px;
  width: 100%;
  margin: auto;
  margin-top: 19px;
}
.parent-page .infor_bio_top > ul li a,
.parent-page .infor_comment_area > ul li a,
.parent-page .infor_address_area > ul li a,
.parent-page .infor_parent_area > ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  color: #3f4254;
  font-size: 11px;
}
.parent-page .infor_bio_top ul li:not(:last-child) {
  margin-bottom: 6px;
}
.parent-page .infor_bio_top ul li a span {
  margin-left: 20px;
  display: inline-block;
}
.parent-page .infor_bio_top > ul li:nth-child(4) a span,
.parent-page .infor_comment_area > ul li:nth-child(4) a span {
  margin-left: 35px;
}
.parent-page .infor_comment_area > p {
  margin-top: 45px;
  margin-bottom: 39px;
}
.parent-page .infor_comment_area > ul li:nth-child(3) a span {
  margin-left: 35px;
}
.parent-page .infor_address_area h5 {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  margin-top: 46px;
  margin-bottom: 20px;
}
.parent-page .infor_address_area ul li:nth-child(1) a span {
  margin-left: 0;
}
.parent-page .infor_address_area > ul li:nth-child(4) a span {
  margin-left: 30px;
}
.parent-page .infor_address_area > ul li:nth-child(5) a span {
  margin-left: 10px;
}
.parent-page .infor_address_area > ul > li > a,
.parent-page .infor_parent_area > ul > li > a {
  position: relative;
}
.parent-page .infor_address_area > ul > li > a::after,
.parent-page .infor_parent_area > ul > li > a::after {
  content: "";
  position: absolute;
  right: -30px;
  top: 2px;
  width: 1px;
  height: 12px;
  background: #c7c8ca;
}
.parent-page .infor_parent_area {
  margin-right: -20px;
  padding-bottom: 24px;
}
.parent-page .infor_parent_area h5 {
  margin-top: 48px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 14px;
}
.parent-page .infor_address_area ul li:not(:last-child),
.parent-page .infor_parent_area ul li:not(:last-child) {
  margin-bottom: 7px;
}
.parent-page .infor_parent_area > ul > li > a span {
  margin-left: 15px;
}
.parent-page .infor_parent_area > ul > li:nth-child(3) a span {
  margin-left: 10px;
}
.parent-page .infor_parent_area > ul > li:nth-child(4) a span {
  margin-left: 10px;
}
.parent-page .infor_parent_area > ul > li > a::after {
  right: -11px;
}
/*student_action_help_area css*/
.parent-page .student_action_help_area {
  margin-top: 14px;
}
.parent-page .student_action_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.parent-page .student_action_heading h5 {
  font-size: 16px;
  font-weight: 300;
  color: #05233b;
}
.parent-page .student_action_heading a {
  color: #0078bf;
  font-weight: 300;
}
.parent-page .action_st_btn_img img {
  max-width: 58px;
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
  margin-top: 14px;
  margin-bottom: 26px;
}
.parent-page .Student_action_single_items,
.parent-page .st_another_single_deffrent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .st_single_action {
  background: #fff;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 6px);
  flex: 0 0 calc(50% - 6px);
  margin-bottom: 10px;
}
.parent-page .st_single_action h5 {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  padding-top: 15px;
  padding-left: 20px;
}
.parent-page .action_st_btn_img {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parent-page .btn_style_1 {
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  background: #0078bf;
  border: 1px solid #0078bf;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.parent-page .btn_style_1:hover {
  background: #fff;
  color: #0078bf;
}
.parent-page .btn_style_2 {
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  background: #fff;
  border: 1px solid #0078bf;
  padding: 4px 17px;
  display: inline-block;
  border-radius: 50px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.parent-page #launchcourse {
  background: transparent;
}
.parent-page .btn_style_2:hover,
.parent-page #launchcourse:hover {
  background: #0078bf;
  color: #fff;
}
.parent-page .Student_action_single_items .action_st_btn_img a.btn_style_2 {
  margin-top: 14px;
  margin-bottom: 20px;
}
.parent-page .Student_action_single_items .st_single_action:nth-child(2) a.btn_style_1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.parent-page .Student_action_single_items
  .st_single_action:nth-child(2)
  .action_st_btn_img
  img {
  max-width: 53px;
  margin-top: 35px;
}
.parent-page .st_another_single_deffrent
  .st_single_action:nth-child(1)
  .action_st_btn_img
  img {
  max-width: 53px;
  margin-top: 13px;
  margin-bottom: 0;
}
.parent-page .st_another_single_deffrent
  .st_single_action:nth-child(2)
  .action_st_btn_img
  img {
  max-width: 56px;
  margin-top: 13px;
  margin-bottom: 0;
}
.parent-page .st_another_single_deffrent
  .st_single_action:nth-child(1)
  .action_st_btn_img
  a.btn_style_1 {
  margin-top: 18px;
  margin-bottom: 24px;
}
.parent-page .st_another_single_deffrent
  .st_single_action:nth-child(2)
  .action_st_btn_img
  a.btn_style_1 {
  margin-top: 19px;
  margin-bottom: 24px;
}
/*=============================================================================
  ====                       End student_pages_content_area Css            ====
  =============================================================================*/

/*================================================================================================
  ====                                      END STUDENT PAGE CSS                              ====
  ================================================================================================*/

/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/
.parent-page .resources-area {
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}
.parent-page .same-close-button-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
}
.parent-page .same-close-button-left button {
  font-weight: 300;
  font-size: 18px;
  color: #3f4254;
  text-transform: capitalize;
  line-height: 1.2;
  background: none;
}
.parent-page .same-close-button-left button img {
  width: 14px;
  height: auto;
  margin-top: -6px;
  margin-right: 15px;
}
.parent-page .same-close-right-link-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .single-same-close-right-link-button a {
  font-weight: 300;
  font-size: 14px;
  color: #0078bf;
  text-transform: inherit;
  border: 1px solid #0078bf;
  padding: 5px 18px;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .single-same-close-right-link-button a:hover {
  background: #0078bf;
  color: #ffffff;
}
.parent-page .same-close-right-link-button
  .single-same-close-right-link-button:nth-child(2)
  a {
  margin-left: 15px;
}
.parent-page .resources-main-image-area {
  text-align: right;
  padding-top: 25px;
}
.parent-page .resources-main-image-area img {
  max-width: 880px;
  width: 100%;
  height: auto;
}
/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/

/*================================================================================================
  ====                          START REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                 ====
  ================================================================================================*/
.parent-page .request-mobile-area {
  background: #ffffff;
}
.parent-page .request-transcript-main-mobile-design {
  display: none;
}
.parent-page .request-mobile-content-area {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
}
.parent-page .request-mobile-content-heading-text p {
  padding-bottom: 20px;
}
.parent-page .request-mobile-content-area p,
.parent-page .single-request-transcript-heading-content p {
  font-weight: 300;
  font-size: 15px;
  color: #05233b;
  text-transform: inherit;
  line-height: 1.2;
}
.parent-page .single-request-mobile-middle-text p:nth-child(2) {
  font-size: 12px;
  padding-top: 5px;
  width: 88%;
}
.parent-page .single-request-mobile-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f3f6f9;
  padding: 25px 20px;
  margin-bottom: 20px;
}
.parent-page .single-request-mobile-middle-text {
  margin-left: 25px;
  max-width: 385px;
  width: 100%;
}
.parent-page .single-request-mobile-middle-text a,
.parent-page .single-request-transcript-bottom-head a {
  font-weight: 300;
  font-size: 12px;
  color: #0b7dc1;
  text-transform: inherit;
  border-bottom: 1px solid #0b7dc1;
  line-height: 10px;
}
.parent-page .single-request-mobile-left-image img {
  width: 50px;
  height: auto;
}
.parent-page .single-request-mobile-right-text a {
  background: transparent;
}
/*================================================================================================
  ====                          END REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST TRANSCRIPT LIST  PAGE CSS                     ====
  ================================================================================================*/
.parent-page .request-transcript-area {
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}
.parent-page .request-transcript-main-content {
  padding-top: 55px;
}
.parent-page .request-transcript-main-content {
  width: 95%;
  margin: 0 auto;
}
.parent-page .single-request-transcript-content {
  background: #f3f6f9;
  padding: 15px 20px 60px 20px;
  height: 100%;
  position: relative;
}
.parent-page .single-request-transcript-heading-content img {
  height: 55px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 15px;
}
.parent-page .single-request-transcript-bottom-content p {
  font-weight: 300;
  font-size: 12px;
  color: #05233b;
  line-height: 1.2;
}
.parent-page .single-request-transcript-bottom-button a {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 185px;
}
.parent-page .single-request-transcript-heading-content p {
  text-align: left;
}
.parent-page .single-request-transcript-bottom-button a:hover {
  background: transparent;
}
/*================================================================================================
  ====                             END REQUEST TRANSCRIPT LIST  PAGE CSS                      ====
  ================================================================================================*/

/*================================================================================================
  ====                              START REQUEST ENROLLMENT PAGE CSS                         ====
  ================================================================================================*/
.parent-page .request-enrollment-area {
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  display: none;
}
.parent-page .need-help-link a {
  font-weight: 300;
  font-size: 12px;
  color: #0b7dc1;
  text-transform: inherit;
  border-bottom: 1px solid #0b7dc1;
  line-height: 11px;
}
.parent-page .request-enrollment-main-content {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.parent-page .request-enrollment-main-content-heading p {
  font-weight: 300;
  font-size: 15px;
  color: #05233b;
  text-transform: inherit;
  line-height: 1.4;
  padding-bottom: 25px;
}
.parent-page .request-enrollment-option {
  margin-bottom: 10px;
}
.parent-page .request-enrollment-single-select-option {
  margin-bottom: 20px;
}
.parent-page .request-enrollment-option p,
.parent-page .single-request-enrollment-option label,
.parent-page .request-enrollment-single-select-option p,
.parent-page .request-enrollment-single-datepiker p {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  line-height: 1.2;
  margin-bottom: 8px;
}
.parent-page .request-enrollment-option p span,
.parent-page .request-enrollment-single-select-option p span,
.parent-page .request-enrollment-single-datepiker p span {
  color: #ff0000;
  vertical-align: middle;
}

/* Start Custom Radio Checkbox Css */
.parent-page .single-request-enrollment-option input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.parent-page .single-request-enrollment-option label {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 30px;
}
.parent-page .single-request-enrollment-option label::before {
  content: "";
  /* background: url('../assets/images/enrollment/check-before.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
.parent-page .single-request-enrollment-option input:checked + label::after {
  content: "";
  /* background: url('../assets/images/enrollment/check-after.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
/* End Custom Radio Checkbox Css */

/* Start Dropdown Select Option Filter Css */
.parent-page #select-chose-course {
  border: 1px solid #3f4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
}
.parent-page #select-chose-course::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/enrollment/down-arrow-enroll.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 6px;
  right: 12px;
  top: 13px;
}
.parent-page .hierarchy-select.dropdown .hs-searchbox {
  padding: 4px 5px 4px 5px;
  position: relative;
}
.parent-page .hs-searchbox img {
  position: absolute;
  left: 12px;
  top: 15px;
  width: 15px;
  height: auto;
  cursor: pointer;
}
.parent-page .dropdown-menu.select-course {
  border: 1px solid #e4e6ef;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  max-width: 275px;
  width: 100%;
}
.parent-page .form-control.select-course::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #a6adb1;
  text-transform: inherit;
  line-height: 1.7;
}
.parent-page .form-control.select-course::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #a6adb1;
  text-transform: inherit;
  line-height: 1.7;
}
.parent-page .form-control.select-course:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #a6adb1;
  text-transform: inherit;
  line-height: 1.7;
}
.parent-page .form-control.select-course::-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #a6adb1;
  text-transform: inherit;
  line-height: 1.7;
}
.parent-page .form-control.select-course::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #a6adb1;
  text-transform: inherit;
  line-height: 1.7;
}
.parent-page .form-control.select-course {
  border: 1px solid #a6adb1;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 5px 10px 5px 30px;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  border-radius: 5px;
  outline: none;
}
.parent-page .dropdown-item.enrollment {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .dropdown-item.enrollment:hover {
  color: inherit;
  background-color: #e4e6ef;
}
.parent-page .dropdown-item.enrollment.active,
.parent-page .dropdown-item.enrollment:active {
  color: #3f4254;
  text-decoration: none;
  background-color: #dbe6ee;
  font-weight: 300;
}
.parent-page .form-control.select-course:focus {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  outline: none !important;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border: 1px solid #a6adb1;
}

/* Start Dropdown Select Option Filter Css */

/* Start Custom Datepiker Css */
.parent-page .form-groups {
  max-width: 280px;
  width: 100%;
  position: relative;
}
.parent-page .form-groups::after,
.parent-page .info-form-group::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/enrollment/datepiker.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 18px;
  top: 8px;
  right: 9px;
  pointer-events: none;
}
.parent-page .form-groups::before,
.parent-page .info-form-group::before {
  content: "";
  position: absolute;
  background: #3f4254;
  width: 1px;
  height: 34px;
  top: 0;
  right: 32px;
}
.parent-page .form-groups .input-field {
  border: 1px solid #3f4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.parent-page .form-groups input::-webkit-input-placeholder {
  font-size: 14px;
}
.parent-page .form-groups input::-moz-placeholder {
  font-size: 14px;
}
.parent-page .form-groups input:-ms-input-placeholder {
  font-size: 14px;
}
.parent-page .form-groups input::-ms-input-placeholder {
  font-size: 14px;
}
.parent-page .form-groups input::placeholder {
  font-size: 14px;
}
.parent-page .form-groups .input-field:focus {
  outline: none;
}
.parent-page .ui-datepicker {
  display: none;
  background-color: #fff;
  -webkit-box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.parent-page .ui-datepicker-calendar table {
  border-collapse: collapse;
  border-spacing: 0;
}
.parent-page .ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: #353535;
}
.parent-page .ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.parent-page .ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 50%;
  line-height: 2rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  color: #353535;
  font-size: 0.875rem;
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.parent-page .ui-datepicker-calendar tbody td a.ui-state-active {
  background: #d33a47;
  color: #ffffff;
}
.parent-page .ui-datepicker-calendar tbody td a.ui-state-highlight {
  color: #0078bf;
  border: 1px solid #0078bf;
}
.parent-page .ui-datepicker-calendar tbody td a:hover {
  background: #0078bf;
  color: #ffffff;
}
.parent-page .ui-datepicker-header a span {
  display: none;
}
.parent-page .ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 8px 20px;
}
.parent-page .ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
}
.parent-page .ui-datepicker-header a.ui-datepicker-prev::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/enrollment/down-arrow-enroll.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 12px;
}
.parent-page .ui-datepicker-header a.ui-datepicker-next {
  right: 0;
}
.parent-page .ui-datepicker-header a.ui-datepicker-next::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/enrollment/down-arrow-enroll.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 12px;
}
.parent-page .ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.parent-page .ui-datepicker-week-col {
  color: #353535;
  font-weight: 400;
  font-size: 0.75rem;
}
/* End Custom Datepiker Css */

.parent-page .all-same-button-design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 35px;
}
.parent-page .single-same-button-design button {
  font-weight: 300;
  font-size: 12px;
  color: #0078bf;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #0078bf;
  width: 104px;
  text-align: center;
  padding: 3px 5px;
  border-radius: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .single-same-button-design button:hover {
  background: #0078bf;
  border: 1px solid #0078bf;
  color: #ffffff;
}
.parent-page .all-same-button-design .single-same-button-design:nth-child(2) button {
  background: #0078bf;
  border: 1px solid #0078bf;
  color: #ffffff;
  margin-left: 15px;
}
.parent-page .all-same-button-design .single-same-button-design:nth-child(2) button:hover {
  background: transparent;
  border: 1px solid #0078bf;
  color: #0078bf;
}
.parent-page .reason-paragraf-text p {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  text-transform: inherit;
  line-height: 1.2;
  padding-top: 25px;
  padding-bottom: 45px;
}
.parent-page .single-same-button-design.change {
  margin-left: 15px;
}
/*================================================================================================
  ====                               END REQUEST ENROLLMENT PAGE CSS                          ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST RECOMMENDATION PAGE CSS                       ====
  ================================================================================================*/

.parent-page .request-recommendation-area {
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}
.parent-page .request-recommendation-opition-area {
  max-width: 685px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
}
.parent-page .request-recommendation-heading p {
  font-weight: 300;
  font-size: 15px;
  color: #05233b;
  text-transform: inherit;
  padding-bottom: 10px;
}
.parent-page .request-recommendation-option-content:nth-child(2)
  .single-request-recommendation-option-area {
  padding-bottom: 20px;
}
.parent-page .new-request-recommendation-option-content {
  margin-bottom: 25px;
}
.parent-page .request-recommendation-option-head p {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  text-transform: capitalize;
  padding-bottom: 15px;
}
.parent-page .single-request-recommendation-option-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 95%;
  cursor: pointer;
  position: relative;
  background: #f3f6f9;
  padding: 20px 30px;
  border: 2px solid transparent;
}
.parent-page .single-request-recommendation-option-content.show {
  border: 2px solid #0078bf;
}
.parent-page .single-request-recommendation-left img {
  width: 45px;
  height: auto;
}
.parent-page .single-request-recommendation-right {
  margin-left: 30px;
}
.parent-page .single-request-recommendation-right h5 {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  text-transform: inherit;
}
.parent-page .single-request-recommendation-right p {
  font-weight: 300;
  font-size: 12px;
  color: #05233b;
  text-transform: inherit;
  max-width: 360px;
  width: 100%;
  padding-top: 8px;
  line-height: 1.2;
}
.parent-page .single-request-recommendation-right p:nth-child(1) {
  padding-top: 0;
}
.parent-page .cross-button-image button {
  background: transparent;
}
.parent-page .cross-button-image img {
  width: 14px;
  height: auto;
  position: absolute;
  right: 28px;
  top: 0;
  bottom: 0;
  margin: auto;
}
/*================================================================================================
  ====                             END REQUEST RECOMMENDATION PAGE CSS                        ====
  ================================================================================================*/

/*================================================================================================
  ====                           START REQUEST RECOMMENDATION INFO PAGE CSS                   ====
  ================================================================================================*/
.parent-page .recommendation-myinfo-area {
  background: #ffffff;
}
.parent-page .recommendation-myinfo-content {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
}
.parent-page .recommendation-save-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.parent-page .recommendation-save-text img {
  width: 13px;
  height: auto;
  margin-top: -5px;
  margin-right: 2px;
}
.parent-page .recommendation-save-text span {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .recommendation-tab-button img {
  max-width: 530px;
  width: 100%;
  height: auto;
  margin-left: 15px;
  padding: 8px 0;
}
.parent-page .recommendation-tab-top-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 540px;
  width: 100%;
  margin-left: 12px;
}
.parent-page .recommendation-tab-top-button button {
  font-weight: 300;
  font-size: 12px;
  color: #7e8299;
  background: none;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .recommendation-tab-top-button button:hover {
  color: #0b7dc1;
}
.parent-page .recommendation-tab-top-button button.actives {
  color: #0078bf;
}
.parent-page .recommendation-myinfo-form-heading-text p {
  font-weight: 300;
  font-size: 15px;
  color: #05233b;
  text-transform: inherit;
  padding-top: 25px;
  padding-bottom: 20px;
  margin-left: 10px;
}
.parent-page .recommendation-myinfo-form-heading-text.second p {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 22px;
  margin-left: 5px;
}
.parent-page .recommendation-myinfo-form-heading-text.salf-evaluation p {
  margin-left: 0;
}
.parent-page .recommendation-myinfo-first-form form {
  max-width: 340px;
  width: 100%;
}
.parent-page .info-form-group {
  position: relative;
  cursor: pointer;
}
.parent-page .info-form-group input {
  cursor: pointer;
}
.parent-page .recommendation-myinfo-first-form {
  margin-left: 10px;
}
.parent-page .single-recommendation-myinfo-second {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 15px;
}
.parent-page .single-recommendation-info-second-half {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
}
.parent-page .single-myinfo-second-right,
.parent-page .single-myinfo-second-half {
  max-width: 340px;
  width: 100%;
}
.parent-page .single-myinfo-second-left p,
.parent-page .single-myinfo-second-left-pera p {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  margin-right: 20px;
  text-transform: inherit;
}
.parent-page .single-myinfo-second-left p {
  margin-top: 34px;
}
.parent-page .single-myinfo-second-left-pera p {
  margin-top: 8px;
}
.parent-page .single-myinfo-form label,
.parent-page .single-recommendation-myinfo-second label,
.parent-page .single-recommendation-myinfo-second-bottom label {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  display: block;
  margin-bottom: 6px;
}
.parent-page .single-myinfo-form {
  padding-bottom: 15px;
}
.parent-page .single-myinfo-form input,
.parent-page .single-recommendation-myinfo-second input,
.parent-page .single-recommendation-myinfo-second-bottom input {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  padding: 6px 10px;
  border: 1px solid #3f4254;
  border-radius: 5px;
  outline: none;
}
.parent-page .single-recommendation-myinfo-second input,
.parent-page .single-recommendation-myinfo-second-bottom input {
  margin-bottom: 15px;
}
.parent-page .single-myinfo-second-half {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .single-myinfo-second-half input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48%;
  flex: 0 0 48%;
}
.parent-page .single-myinfo-form input::-webkit-input-placeholder,
.parent-page .single-recommendation-myinfo-second input::-webkit-input-placeholder,
.parent-page .single-recommendation-myinfo-second-bottom input::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-myinfo-form input::-moz-placeholder,
.parent-page .single-recommendation-myinfo-second input::-moz-placeholder,
.parent-page .single-recommendation-myinfo-second-bottom input::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-myinfo-form input:-ms-input-placeholder,
.parent-page .single-recommendation-myinfo-second input:-ms-input-placeholder,
.parent-page .single-recommendation-myinfo-second-bottom input:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-myinfo-form input::-ms-input-placeholder,
.parent-page .single-recommendation-myinfo-second input::-ms-input-placeholder,
.parent-page .single-recommendation-myinfo-second-bottom input::-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-myinfo-form input::placeholder,
.parent-page .single-recommendation-myinfo-second input::placeholder,
.parent-page .single-recommendation-myinfo-second-bottom input::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .myinfo-teacher-name {
  position: relative;
}
.parent-page .myinfo-teacher-name::after {
  content: "";
  position: absolute;
  /* background: url('../assets/images/recommendation/down-arrow.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10px;
  height: 7px;
  right: 10px;
  bottom: 15px;
  pointer-events: none;
}
.parent-page .myinfo-teacher-name select {
  width: 100%;
  border: 1px solid #3f4254;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
}
.parent-page .myinfo-teacher-name option {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .recommendation-myinfo-second-form-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 15px;
}
.parent-page .recommendation-myinfo-second-form-heading p {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  padding-left: 10px;
}
.parent-page .recommendation-myinfo-second-form-heading img {
  width: 12px;
  height: auto;
  margin-left: 10px;
}
.parent-page .custom_checkbox_area.recommendation_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  padding-bottom: 25px;
  padding-left: 10px;
}
.parent-page .custom_checkbox_area.recommendation_checkbox.middle {
  padding-top: 138px;
}
.parent-page .recommendation-myinfo-bottom-design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.parent-page .recommendation-myinfo-second-form-right {
  padding-top: 52px;
  padding-left: 20px;
}
.parent-page .recommendation_checkbox .single-recommendation-checkbox:nth-child(1) {
  margin-right: 20px;
}
.parent-page .single-recommendation-checkbox label span {
  cursor: pointer;
}
.parent-page .add-anoter-button a img {
  width: 18px;
  height: auto;
  margin-right: 5px;
  margin-top: -6px;
}
.parent-page .add-anoter-button a {
  font-weight: 300;
  font-size: 12px;
  color: #0078bf;
  text-transform: inherit;
}
.parent-page .recommendation-myinfo-bottom-peragraf p {
  font-weight: 300;
  font-size: 11px;
  color: #3f4254;
  max-width: 515px;
  width: 100%;
  line-height: 1.3;
  padding-top: 10px;
}
.parent-page .all-same-button-design-with-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
}
/*================================================================================================
  ====                            END REQUEST RECOMMENDATION INFO PAGE CSS                    ====
  ================================================================================================*/

/*================================================================================================
  ====                     START REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS              ====
  ================================================================================================*/
.parent-page .recommendation-salf-evaluation,
.parent-page .recommendation-reflection {
  background: #ffffff;
}
.parent-page .recommendation-myinfo-form-heading-text p:nth-child(2) {
  padding-top: 0;
  font-weight: 300;
  font-size: 11px;
  color: #3f4254;
  text-transform: inherit;
  max-width: 500px;
  width: 100%;
}
.parent-page .recommendation-salf-evaluation-form form {
  max-width: 510px;
  width: 100%;
}
.parent-page .adjetive-input-textarea-content {
  padding-bottom: 25px;
}
.parent-page .recommendation-salf-evaluation-form-textarea {
  padding-bottom: 35px;
}
.parent-page .recommendation-salf-evaluation-form-textarea label,
.parent-page .recommendation-salf-evaluation-form-peragraf p,
.parent-page .single-recommendation-slef-input-textarea-right label {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  display: block;
  text-transform: inherit;
  line-height: 1.3;
}
.parent-page .recommendation-salf-evaluation-form-peragraf p:nth-child(1) {
  padding-bottom: 15px;
}
.parent-page .recommendation-salf-evaluation-form-textarea label {
  margin-bottom: 5px;
}
.parent-page .single-recommendation-self-input-textarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: first baseline;
  -ms-flex-align: first baseline;
  align-items: first baseline;
  padding-top: 25px;
}
.parent-page .single-recommendation-slef-input-textarea-right {
  max-width: 490px;
  width: 100%;
}
.parent-page .recommendation-salf-evaluation-form-textarea textarea,
.parent-page .single-recommendation-slef-input-textarea-right textarea {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  padding: 5px 10px;
  border: 1px solid #3f4254;
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: none;
  height: 90px;
}
.parent-page .single-recommendation-slef-input-textarea-right input {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  padding: 5px 10px;
  border: 1px solid #3f4254;
  max-width: 185px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
}
.parent-page .single-recommendation-slef-input-textarea-right
  input::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-recommendation-slef-input-textarea-right input::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-recommendation-slef-input-textarea-right input:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-recommendation-slef-input-textarea-right input::-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-recommendation-slef-input-textarea-right input::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-recommendation-slef-input-textarea-right textarea {
  height: 60px;
}
.parent-page .recommendation-salf-evaluation-form-textarea p {
  font-weight: 300;
  font-size: 11px;
  color: #3f4254;
  padding-top: 8px;
}
.parent-page .single-recommendation-slef-input-textarea-left p {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
  padding-right: 12px;
}
/*================================================================================================
  ====                     END REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS                ====
  ================================================================================================*/

/*================================================================================================
  ====                      START REQUEST RECOMMENDATION REFLECTION PAGE CSS                  ====
  ================================================================================================*/

.parent-page .recommendation-salf-evaluation-form-textarea.reflection label {
  margin-bottom: 10px;
}
.parent-page .recommendation-salf-evaluation-form-textarea.reflection {
  padding-bottom: 45px;
}
/*================================================================================================
  ====                       END REQUEST RECOMMENDATION REFLECTION PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                                  START SETTINGS PAGE CSS                               ====
  ================================================================================================*/
.parent-page .change-password-area {
  padding-bottom: 900px;
}
.parent-page .change-password-content {
  max-width: 700px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 60px;
  padding-top: 20px;
}
.parent-page .change-password-left-content {
  background: #ffffff;
  padding: 5px 0;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 270px;
  width: 100%;
  border-radius: 4px;
}
.parent-page .change-password-left-content button {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  background: #ffffff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .change-password-left-content button:hover {
  background: #dbe6ee;
}
.parent-page .change-password-right-content {
  background: #ffffff;
  padding: 30px 40px;
  max-width: 415px;
  width: 100%;
  border-radius: 4px;
}
.parent-page .change-password-right-heading p {
  font-weight: 300;
  font-size: 20px;
  color: #05233b;
  text-transform: capitalize;
  line-height: 1.2;
  padding-bottom: 20px;
}
.parent-page .single-change-password-right-input {
  padding-bottom: 15px;
}
.parent-page .single-change-password-right-input label {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  display: block;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.parent-page .single-change-password-right-input input {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
  display: block;
  margin-bottom: 5px;
  text-transform: capitalize;
  border: 1px solid #3f4254;
  width: 100%;
  padding: 5px 15px;
  border-radius: 5px;
  outline: none;
}
.parent-page .single-change-password-right-input input::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .single-change-password-right-input input::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .single-change-password-right-input input:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .single-change-password-right-input input::-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .single-change-password-right-input input::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .single-change-password-right-input input:focus {
  border: 2px solid #0078bf;
}
.parent-page .change-password-submit-button {
  text-align: center;
}
.parent-page .change-password-submit-button input {
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  background: #0078bf;
  border: none;
  padding: 5px 25px;
  border-radius: 30px;
  text-align: center;
  border: 1px solid #0078bf;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .change-password-submit-button input:hover {
  background: transparent;
  color: #0078bf;
}
.parent-page .field-icon {
  float: right;
  position: relative;
  z-index: 2;
  top: -30px;
  right: 10px;
  cursor: pointer;
}
.parent-page .field-icon::before {
  content: "";
  position: absolute;
  /* background: url('../assets/images/enrollment/eyes.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 13px;
  right: 0;
  top: 2px;
}
/*================================================================================================
  ====                                   END SETTINGS PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                               Start Progress tracking pages css                        ====
  ================================================================================================ */
/* start progress_tracking_main_area css*/
.parent-page .pro_college_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
}
.parent-page .left_pro_college_area h3 {
  font-weight: 300;
  color: #05233b;
  font-size: 20px;
  margin-top: 12px;
}
.parent-page .right_pro_college_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .pro_download_area {
  position: relative;
}
.parent-page .pro_search_clg {
  margin-right: 30px;
}
.parent-page .pro_download_area .down-icon {
  position: absolute;
  right: -95px;
  top: -16px;
}
.parent-page .pro_download_area .dropdown-menu {
  min-width: 170px !important;
  border: 1px solid #e4e6ef;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 6px 0 !important;
  position: absolute !important;
  left: -75px !important;
  top: 5px !important;
}
.parent-page .drop_as {
  font-family: "Avenir";
  font-weight: 300;
  color: #3f4254;
  padding: 10px 0;
  padding-left: 18px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .drop_as:hover {
  background: #dbe6ee;
}
/*progress tracking pages progress*/
.parent-page .rt_progress_funel {
  /* background: url('../assets/images/progress/rt_progress.png') no-repeat scroll 0 0 / cover; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  padding-bottom: 45px;
  cursor: pointer;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  position: relative;
}
.parent-page .rt_progress_main_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 20px 0;
}
.parent-page .left_rt_progress_sys {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.parent-page .rt_progress_txt h3 {
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.parent-page .right_rt_progress_sys {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(75% - 15px);
  flex: 0 0 calc(75% - 15px);
  margin-left: 15px;
  background: #fff;
  padding: 13px 15px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.parent-page .rt_progress_bar_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .rt_progress_left_side h5 {
  font-size: 18px;
  font-weight: 300;
  color: #05233b;
  margin-bottom: 13px;
}
.parent-page .rt_progress_left_side p {
  font-size: 12px;
  color: #05233b;
  font-weight: 300;
  margin-top: 12px;
}
.parent-page .rt_progress_left_side {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 95%;
  flex: 0 0 95%;
}
.parent-page .rt_progress_right_side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(5% - 10px);
  flex: 0 0 calc(5% - 10px);
}
.parent-page .rt_progress_left_side .myclassroom-single-left-cross-progress {
  width: 100%;
  height: 16px;
  border-radius: 50px;
}
.parent-page .rt_progress_left_side .barra-nivel {
  height: 15px;
  background: #00a2a7;
  border-radius: 50px;
}
.rt_progress_right_side h5,
.rt_progress_right_side span {
  color: #05233b;
  font-weight: 300;
  font-size: 12px;
}
/* startpt_progress_main_area css*/
.parent-page .pt_view_main_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 45px;
}
.parent-page .pt_left_view h3 {
  font-weight: 400;
  color: #3f4254;
  font-size: 14px;
}
.parent-page .pt_right_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .pt_right_view a {
  margin-left: 16px;
}
.parent-page .btn_style_3 {
  color: #0078bf;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  padding: 5px 17px;
  border: 1px solid #0078bf;
  border-radius: 50px;
  background: transparent;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.parent-page .btn_style_3:hover {
  color: #fff;
  background: #0078bf;
}
.parent-page .btn_style_4 {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  padding: 5px 17px;
  border: 1px solid #0078bf;
  border-radius: 50px;
  background: #0078bf;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.parent-page .btn_style_4:hover {
  color: #0078bf;
  background: transparent;
}
.parent-page .pt_right_view .btn_style_3.change {
  background: #05233b;
  color: #ffffff;
}
.parent-page .pt_right_view .btn_style_3.change:hover {
  background: transparent;
  color: #05233b;
  border: 1px solid #05233b;
}
/*================================================================================================
  ====                                End Progress tracking pages css                         ====
  ================================================================================================ */

/*================================================================================================
  ====                           Start Messages Page (11)(Munna)                                ====
  ================================================================================================*/
.parent-page .mu_messages_page {
  padding: 17px 0px 0px 0px;
}
.parent-page .mu_messages_all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .mu_discussion_header_top_text p {
  font-size: 16px;
  color: #05233b;
  margin-left: 20px;
  font-weight: 300;
}
/*===================== Start Right Site Messages page =================*/
.parent-page .mu_messages_page_left_site {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%; /*
            padding-bottom: 100px;*/
  background: #fff;
}

/* Start Message Text*/
.parent-page .mu_messages_area {
  background: #fff;
  padding: 30px 45px 20px 30px;
}
.parent-page .mu_messages_left img {
  width: 25px;
  margin-right: 10px;
}
.parent-page .mu_messages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 3px solid #145b94;
  padding-bottom: 10px;
}
.parent-page .mu_messages_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_messages_left h4 {
  font-size: 20px;
  color: #145b94;
}
.parent-page .mu_messages_right {
  background: #00a2a7;
  padding: 4px 10px;
  border-radius: 5px;
}
.parent-page .mu_messages_right p {
  font-size: 12px;
  color: #fff;
}
/* End Message Text*/

/* Start Accordion Messages box*/
.parent-page .mu_accordion2 {
  width: 100%;
  max-width: 360px;
  background: #fff;
}
.parent-page .mu_accordion2 .link {
  cursor: pointer;
  display: block;
  padding: 10px 30px;
  color: #3e3f42;
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.parent-page .mu_accordion2 .link:hover {
  background: #dbe6ee;
}
.parent-page .mu_accordion2 li i {
  position: absolute;
  top: 14px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.parent-page .mu_accordion2 li i.fa-chevron-down {
  right: 25px;
  left: auto;
  font-size: 12px;
  color: #a6adb1;
}
.parent-page .mu_accordion2 li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
/*.accordion li.open .link { background: #DBE6EE; }*/

/*.accordion li.open i { color: #3E3F42; }*/
.parent-page .mu_submenu2 {
  display: none;
  background: #444359;
  font-size: 14px;
}
.parent-page .mu_submenu2 li {
  background: #fff;
}
.parent-page .mu_submenu2 a {
  display: block;
  text-decoration: none;
  color: #3e3f42;
  padding: 12px;
  padding-left: 50px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.parent-page .mu_submenu2 a:hover {
  background: #dbe6ee;
  color: #3e3f42;
}
/* End Accordion Messages box*/

/*Start Recent Client */
.parent-page .mu_recent_client li {
  background: #fff;
}
.parent-page .mu_recent_client li img {
  margin-right: 17px;
}
.parent-page .mu_li_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_recent_client li img {
  width: 28px;
  height: auto;
}
.parent-page .mu_accrodion_li_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 30px;
}
.parent-page .mu_accrodion_li_flex p {
  color: #3f4254;
  font-size: 14px;
  line-height: 1.6;
}
/*End Recent Client */

/*Start Button*/
.parent-page .mu_message_button {
  background: #fff;
  padding: 25px 20px 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .mu_message_button a {
  text-align: center;
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  border-radius: 20px;
  padding: 6px 0;
  margin-bottom: 16px;
  font-weight: 400;
  max-width: 240px;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.parent-page .mu_message_button a:hover {
  background: #0078bf;
  color: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/*End Button*/
/*===================== End LEFT Site Messages page =================*/

/*===================== Start Right Site Messages page =================*/
.parent-page .mu_right_site_border {
  border: 1px solid #eaedf3;
  border-radius: 6px 5px 0 0;
}
.parent-page .mu_messages_page_right_site {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
}
/* Start Chart Text*/
.parent-page .mu_chart_area {
  width: 270px;
  background: #e4e6ef;
  padding: 20px 0 28px 0;
  border: 4px solid #fff;
}
.parent-page .mu_chart_left img {
  width: 25px;
  margin-right: 10px;
}
.parent-page .mu_chart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parent-page .mu_chart_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_chart_left h4 {
  font-size: 20px;
  color: #707070;
}
.parent-page .mu_chart_right {
  background: #707070;
  padding: 4px 10px;
  border-radius: 5px;
  margin-left: 12px;
}
.parent-page .mu_chart_right p {
  font-size: 12px;
  color: #fff;
}
/* End Chart Text*/

/*=======Start search Header*/
.parent-page .mu_chart_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  padding: 23px 15px 15px 27px;
  border-radius: 6px 5px 0 0;
}
.parent-page .mu_chart_header.mu_chart_header2 {
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  justify-content: normal;
}
/*Start Header button*/
.parent-page .mu_header_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .mu_header_btn.mu_header_btn2 {
  margin-right: 40px;
}
.parent-page .mu_header_btn a {
  display: block;
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  border-radius: 20px;
  padding: 6px 20px;
  font-weight: 400;
  margin-right: 16px;
}
.parent-page .mu_header_btn a:hover {
  background: #0078bf;
  color: #fff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .mu_header_btn.mu_header_btn12 a {
  margin: 0px;
}
.parent-page .mu_header_btn.mu_header_btn12f a {
  margin: 0px 12px 0px 0px;
}
.parent-page .mu_header_btn.mu_header_btn12f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_header_btn.mu_header_btn12f img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
/*End Header button*/

/*Start search Bar*/
.parent-page .mu_search_bar.mu_search_bar_reply {
  -webkit-box-flex: inherit;
  -ms-flex: inherit;
  flex: inherit;
  max-width: 352px;
  width: 100%;
}
/*End search Bar*/

/*Start Next button*/
.parent-page .mu_header_next_btn img {
  width: 8px;
  height: auto;
}
.parent-page .pagination_left_icon {
  padding-right: 33px;
}
.parent-page .pagination_right_icon {
  padding-left: 33px;
}
.parent-page .mu_header_next_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_pagination_count_button a {
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  color: #3e3f43;
  padding: 5px 12px;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 3px;
}
.parent-page .mu_pagination_count_button a.active {
  background: #0078bf;
  color: #fff;
  padding: 5px 12px;
  border-radius: 4px;
}
.parent-page .mu_pagination_count_button a:hover {
  background: #0078bf;
  color: #fff;
  border-radius: 4px;
}
/*End Next button*/
/*======End search Header*/

/*======Start icon Header*/
.parent-page .mu_icon_header {
  background: #fff;
  padding: 9px 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #eaedf3;
}
.parent-page .mu_icon_header_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_icon_header_left_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*Start check box*/
.parent-page .mu_icon_header_checkbox {
  margin-right: 10px;
}
.parent-page .mu-form-group {
  display: block;
}

.parent-page .mu-form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.parent-page .mu-form-group label {
  position: relative;
  cursor: pointer;
}

.parent-page .mu-form-group label::before {
  content: "";
  -webkit-appearance: none;
  background-color: #f6f7f9;
  border: 1px solid #d8dce6;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
}

.parent-page .mu-form-group input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 5px;
  width: 7px;
  height: 12px;
  border: solid #707070;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*End check box*/

/*Start icons*/
.parent-page .mu_icon_header_icons {
  margin-right: 15px;
}
.parent-page .mu_icon_header_icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_icon_header_icons ul li a {
  margin: 0 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_icon_header_icons ul li span {
  color: #d8dce6;
}
.parent-page .mu_icon_header_icons ul li a img {
  width: 14px;
  height: auto;
}
/*End icons*/
.parent-page .mu_filter_announcement p {
  font-size: 14px;
  color: #3e3f43;
  font-weight: 300;
}
.parent-page .mu_icon_header_right p {
  font-size: 14px;
  color: #3e3f43;
}
/*======End icon Header*/

/*========Start People List*/
.parent-page .mu_people_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  padding: 16px 27px;
  border-top: 1px solid #eaedf3;
}
/*Start People Left Site*/
.parent-page .mu_people_list_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_peo_left_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 15px 0 6px;
}
.parent-page .mu_peo_left_img a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .mu_peo_left_img img {
  width: 16px;
  height: auto;
}
.parent-page .mu_people_left_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 220px;
}
.parent-page .mu_people_profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mu_people_profile img {
  width: 28px;
  height: auto;
  margin-right: 10px;
}
.parent-page .mu_people_profile p {
  font-size: 14px;
  color: #3e3f43;
}

.parent-page .mu_people_left_right p {
  font-size: 14px;
  color: #3e3f43;
}
/*Start People Left Site*/

/*Start People Right Site*/
.parent-page .mu_people_list_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_people_list_right p {
  font-size: 14px;
  color: #3e3f43;
}
.parent-page .mu_people_list_margin {
  margin: 0 20px 0 12px;
}
.parent-page .mu_people_list_right a img {
  width: 20px;
  height: auto;
}
.parent-page .mu_people_list_right a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_people_right_img {
  width: 16px;
  height: auto;
  margin-right: 15px;
  cursor: pointer;
}
/*End People Right Site*/
/*======End People List*/

/*===================== End Right Site Messages page =================*/

/*Start Bottom Next Page*/
.parent-page .mu_main_bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}
.parent-page .mu_main_bottom_left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.parent-page .mu_main_bottom_right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
}
.parent-page .parent-page .mu_main_bottom_next_change {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/*End Bottom Next Page*/

/*===============================================================================================
  ====                           End Messages Page (11)(Munna)                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                           Start Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/
.parent-page .mu_single_letter {
  background: #fff;
  padding: 0 0 80px 27px;
  border-top: 1px solid #eaedf3;
}
/*Start Letter Top Button*/
.parent-page .mu_letter_top_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 17px 27px 17px 0;
}
/*End Letter Top Button*/

/*Start Letter Content Area*/
.parent-page .mu_profile_img_area_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 37px;
  padding-right: 27px;
}
.parent-page .mu_profile_img_area_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_profile_img_area_left img {
  width: 52px;
  height: auto;
  margin-right: 19px;
}
.parent-page .mu_profile_img_area_left p {
  font-size: 16px;
  color: #3e3f43;
}
.parent-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2 a {
  margin-left: 15px;
}

.parent-page .mu_profile_img_area_content h6 {
  font-size: 16px;
  color: #3e3f43;
  font-weight: 300;
}
.parent-page .mu_profile_img_area_content p {
  font-size: 14px;
  color: #3e3f43;
  margin-top: 19px;
}
/*End Letter Content Area*/

/*Start Letter bottom Button*/
.parent-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 a {
  margin: 0 15px 0 0;
}
.parent-page .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 {
  margin-top: 100px;
}
/*End Letter bottom Button*/
/*================================================================================================
  ====                           End Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/

/*================================================================================================
  ====                           Start Discussions Page (13)(Munna)                     ====
  ================================================================================================*/
.parent-page .mu_main_discussion_page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*================================ Start Left Site discussion Page ============================*/
.parent-page .mu_main_left_discussion {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  background: #fff;
}
/*Overflow Scroll*/
.parent-page .mu_message_scroll_left {
  height: 715px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*Start left site header*/
.parent-page .mu_dis_left_header {
  padding: 22px 30px 0px 30px;
  border-bottom: 1px solid #eaedf3;
}
.parent-page .mu_dis_left_header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .mu_dis_left_header ul li a {
  font-size: 14px;
  color: #3f4254;
  border-bottom: 3px solid transparent;
  padding-bottom: 21px;
}
.parent-page .mu_dis_left_header ul li a:hover {
  border-bottom: 3px solid #145b94;
  color: #3e3f42;
}
.parent-page .mu_dis_left_li_flex a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_left_li_flex a img {
  width: 15px;
  height: auto;
  margin-right: 5px;
}
/*End left site header*/

/*Start My Teacher*/
.parent-page .mu_my_teacher_dis {
  border-bottom: 1px solid #eaedf3;
}
.parent-page .mu_my_teacher_dis p {
  font-size: 12px;
  color: #181c32;
  padding: 13px 30px;
  font-weight: 300;
}
.parent-page .mu_dis_teacher_img {
  border-bottom: 1px solid #eaedf3;
  padding: 8px 20px 15px 20px;
}
.parent-page .mu_dis_teacher_img ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .mu_dis_teacher_img ul li a img {
  width: 40px;
  height: auto;
}
/*End My Teacher*/

/*Start Dropdown Menu*/
.parent-page .mu_dis_dropdown_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 21px 15px 18px 30px;
  border-bottom: 1px solid #eaedf3;
}
.parent-page .mu_header_btn.mu_dis_le_dropdown_btn a {
  margin: 0;
  padding: 6px 36px;
}
.parent-page .mu_dis_le_dropdown ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_le_dropdown_li_btn {
  font-size: 14px;
  color: #3f4254;
  margin-right: 50px;
}
.parent-page .mu_dis_li_img img {
  width: 12px !important;
  height: 8px !important;
  right: 30px;
  top: 12px;
  position: absolute;
}
/*End Dropdown Menu*/

/*Start Discussion Full Area*/
.parent-page .mu_dis_content_left {
  border-bottom: 1px solid #eaedf3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 7px;
  background: #ffffff;
  cursor: pointer;
}
.parent-page .mu_dis_content_left:hover {
  background: #f6f9fd;
}
.parent-page .mu_dis_content_description_left p:first-child {
  font-size: 11px;
  color: #9ea0a5;
}
.parent-page .mu_dis_con_pad h4 {
  font-size: 16px;
  color: #3e3f42;
  font-weight: 700;
  margin-bottom: 3px;
}
.parent-page .mu_dis_con_pad p {
  font-size: 12px !important;
  margin-bottom: 7px;
  color: #6b6c6f;
}
.parent-page .mu_dis_content_description_left p {
  font-size: 11px;
  color: #6b6c6f;
}
.parent-page .mu_dis_img_con_flex_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .mu_dis_con_img_img1 {
  margin-bottom: -21px;
}
.parent-page .mu_dis_content_img_posi img {
  width: 40px;
  height: auto;
}
.parent-page .mu_dis_con_p_pos a {
  font-size: 12px;
  color: #fff;
  background: #009651;
  padding: 3px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
}
.parent-page .mu_dis_content_img_posi {
  position: relative;
  text-align: center;
  margin-bottom: 15px;
}
.parent-page .mu_dis_con_p_pos {
  position: absolute;
  bottom: -13px;
  right: 32px;
}

/*Start Class Discussion Area*/
.parent-page .mu_dis_con_single_img img {
  width: 25px;
  height: auto;
  border: 2px solid #fff;
  border-radius: 50%;
}
.parent-page .mu_dis_con_img_to_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parent-page .mu_dis_con_img_to_btn2 {
  position: absolute;
  bottom: 18px;
  left: 15px;
}
.parent-page .mu_dis_con_img_to_btn3 {
  position: absolute;
  bottom: 20px;
  left: 27px;
}
.parent-page .mu_dis_con_single_img:nth-child(2) {
  margin-left: -11px;
}
.parent-page .mu_dis_con_single_img:nth-child(3) {
  margin-left: -11px;
}
.parent-page .mu_dis_con_single_img:nth-child(4) {
  margin-left: -11px;
}
.parent-page .mu_dis_con_single_img:nth-child(5) {
  margin-left: -11px;
}
.parent-page .mu_dis_con_single_img:nth-child(6) {
  margin-left: -11px;
}
.parent-page .mu_dis_con_single_img button {
  font-size: 9px;
  color: #fff;
  background: #707070;
  padding: 4px;
  border-radius: 50%;
  text-align: center;
}
.parent-page .mu_dis_content_img_left {
  width: 190px;
  position: relative;
}
.parent-page .mu_dis_con_img_img1 img {
  width: 15px;
  height: auto;
}
.parent-page .mu_dis_content_img_posi.mu_dis_content_img_posi2 {
  margin-bottom: 0px;
}
.parent-page .mu_dis_con_pad.mu_dis_con_pad2 h4 {
  margin-bottom: 13px;
}
.parent-page .mu_dis_con_pad.mu_dis_con_pad2 p {
  font-size: 14px !important;
  line-height: 1.5;
}

.parent-page .mu_dis_time_left p {
  font-size: 11px;
  color: #9ea0a5;
  margin-bottom: 30px;
}
.parent-page .mu_dis_time_left a {
  color: #fff;
  background: #145b94;
  padding: 2px 8px;
  border-radius: 50%;
  font-size: 12px;
}
/*End Full Discussion Area*/

/*Start Groups Full Area*/
.parent-page .mu_dis_content_img_posi.mu_dis_content_img_posi3 {
  margin-bottom: -11px;
}
.parent-page .mu_dis_time_left.mu_dis_time_left_p2 p:last-child {
  margin-bottom: 0;
}
/*End Groups Full Area*/

/*Start Chart Area*/
.parent-page .mu_dis_con_img_img1.mu_dis_con_img_img1reply2 {
  margin-bottom: -30px;
}
.parent-page .mu_dis_con_single_img.mu_dis_con_single_img_new img {
  width: 40px;
  height: auto;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: -20px;
  margin-top: -5px;
}
/*Start Chart Area*/
/*================================ End Left Site discussion Page ============================*/

/*================================ Start Right Site discussion Page ============================*/
.parent-page .mu_main_right_discussion {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 67%;
  flex: 0 0 67%;
}
/*Overflow Scroll*/
.parent-page .mu_message_scroll_right {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*Start Chat Box*/
.parent-page .mu_messages_area.mu_messages_area2 {
  width: 270px;
  padding: 23px 52px 18px 62px;
  margin-left: -100px;
}
.parent-page .mu_chart_area.mu_chart_area2 {
  padding: 27px 10px 15px 0px;
}
.parent-page .mu_messages.mu_messages2 {
  padding-bottom: 6px;
}
/*End Chat Box*/

/*Start right site header*/
.parent-page .mu_dis_right_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 30px;
  border-left: 1px solid #eaedf3;
  background: #fff;
}
.parent-page .mu_dis_right_header_left p {
  font-size: 18px;
  color: #3e3f42;
}
.parent-page .mu_dis_right_header_rtr {
  margin-right: 15px;
}
.parent-page .mu_dis_right_header_rtr ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .mu_dis_right_header_rtr ul li a img {
  width: 28px;
  height: auto;
}

.parent-page .mu_dis_right_header_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_right_header_rtl2 img {
  width: 28px;
  height: auto;
  margin-right: 30px;
}
.parent-page .mu_dis_right_header_rtl3 img {
  width: 20px;
  height: auto;
}
.parent-page .mu_dis_con_single_img_reply button {
  padding: 7px;
}
.parent-page .mu_dis_right_header_rtr ul li a {
  margin-right: 5px;
}
/*End right site header*/

/*Start Right profile Content Area*/
.parent-page .mu_right_profile_area {
  background: #fff;
  padding: 25px 30px 10px 30px;
  border: 1px solid #eaedf3;
}
.parent-page .mu_dis_profile_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_profile_img_img img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}
.parent-page .mu_dis_profile_img_title_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_profile_img_title_flex h5 {
  font-size: 16px;
  color: #3e3f42;
  margin-right: 10px;
}
.parent-page .mu_dis_profile_img_title_flex p {
  font-size: 9px;
  color: #fff;
  background: #3f4254;
  padding: 2px 9px;
  border-radius: 3px;
  font-weight: 700;
}
.parent-page .mu_dis_profile_img_title a {
  font-size: 12px;
  color: #145b94;
  border-bottom: 1px solid #145b94;
  display: inline-block;
  line-height: 10px;
}
.parent-page .mu_dis_profile_content_rt {
  margin-bottom: 10px;
}
.parent-page .mu_dis_profile_content_rt img {
  width: 14px;
  height: auto;
  margin-right: 7px;
}
.parent-page .mu_dis_profile_content_rt p {
  font-size: 12px;
  color: #3e3f42;
}
.parent-page .mu_dis_profile_content_rt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_profile_content_rb p {
  font-size: 12px;
  color: #3f4254;
}
.parent-page .mu_profile_img_content_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.parent-page .mu_dis_profile_cont p {
  font-size: 14px;
  color: #3e3f42;
}
/*End Right profile Content Area*/

/*Start PDF Area*/
.parent-page .mu_dis_pdf_flex_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 16px;
  background: #f3f6f9;
  margin-bottom: 5px;
}
.parent-page .mu_dis_pdf_right {
  margin-bottom: 5px;
}
.parent-page .mu_dis_pdf_flex_left img {
  width: 20px;
  height: 20px;
  height: auto;
  margin-right: 10px;
}
.parent-page .mu_dis_pdf_flex_left p {
  font-size: 14px;
  color: #0078bf;
}
.parent-page .mu_dis_pdf_flex_right a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_pdf_flex_right a img {
  width: 20px;
  height: auto;
}
.parent-page .mu_dis_pdf_flex_right a p {
  margin-right: 10px;
  font-size: 14px;
  color: #0078bf;
}
.parent-page .mu_dis_pdf_flex_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}
.parent-page .mu_dis_pdf_radio {
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid #707070;
  border-radius: 50%;
  text-align: right;
}
.parent-page .mu_dis_pdf_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.parent-page .mu_dis_pdf_right a {
  font-size: 12px;
  color: #145b94;
  border-bottom: 1px solid #145b94;
  display: inline-block;
  line-height: 10px;
}
.parent-page .mu_disc_pdf_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
}
/*End PDF Area*/

/*Start message area 1*/
.parent-page .mu_dis_message_area1 {
  padding: 19px 23px 8px 18px;
}
.parent-page .mu_message_sin_content {
  background: #e4e6ef;
  padding: 10px 12px 20px 17px;
  border-radius: 0 8px 8px 8px;
  max-width: 500px;
  width: 100%;
}
.parent-page .mu_dis_Single_message_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  margin-right: 10px;
}
.parent-page .mu_message_sin_img img {
  width: 28px;
  height: auto;
  margin-right: 15px;
}
.parent-page .mu_message_conte_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.parent-page .mu_message_conte_flex p {
  font-size: 12px;
  color: #05233b;
}
.parent-page .mu_message_sin_con_par p {
  font-size: 14px;
  color: #05233b;
}
.parent-page .mu_dis_Single_message_box.mu_dis_message_area1_box2 {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.parent-page .mu_message_sin_content.mu_message_sin_content2 {
  border-radius: 8px 0 8px 8px;
}
.parent-page .mu_message_sin_content2 {
  background: #c1d3e2;
}
.parent-page .mu_new_messag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
}
.parent-page .mu_new_messag a {
  font-size: 14px;
  color: #3e3f42;
  margin-right: 10px;
}
.parent-page .mu_new_messag p {
  font-size: 12px;
  color: #9ea0a5;
}
/*End message area 1*/

/*Start message area 2*/
.parent-page .mu_dis_message_area2 {
  background: #e5f1f3;
  padding: 28px 0 5px 31px;
}
.parent-page .mu_message_sin_con_par2 p:nth-child(1) {
  margin-bottom: 20px;
}

.parent-page .mu_message_typein {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_message_typein_prof {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_message_typein_prof img {
  width: 28px;
  height: 28px;
  margin-right: 8px;
}
.parent-page .mu_message_typein_prof p {
  font-size: 14px;
  color: #05233b;
  margin-right: 10px;
}
.parent-page .mu_message_typein_type p {
  font-size: 12px;
  color: #7e8299;
}
.parent-page .mu_dis_message_option {
  text-align: center;
  margin-top: 50px;
}

.parent-page .mu_dis_Single_message_box_option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dis_message_option a img {
  width: 20px;
  height: auto;
}
/*Start message area 2*/

/*Start Type a message*/
.parent-page .mu_type_a_message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  padding: 25px 30px;
}
.parent-page .mu_type_a_message_text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
}
.parent-page .mu_type_a_message_text input {
  font-size: 14px;
  color: #3f4254;
  border: none;
  outline: none;
  background: #fff;
  width: 100%;
}
.parent-page .mu_type_a_message_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.parent-page .mu_type_a_message_img a img {
  width: 20px;
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
}

/*Upload File Style*/
.parent-page .fileUpload {
  position: relative;
  overflow: hidden;
}
.parent-page .fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
/*End Type a message*/
/*================================ End Right Site discussion Page ============================*/
/*================================================================================================
  ====                           End Discussions Page (13)(Munna)                     ====
  ================================================================================================*/

/*================================================================================================
  ====                                    LEARNING PAGE CSS START                                  ====
  ================================================================================================*/
.parent-page .ra_science_area {
  background-color: #f3f6f9;
  padding: 0 0 24px 0;
}
.parent-page .ra_science_main_area {
  padding-top: 20px;
}
.parent-page .ra_science {
  background: #fff;
  border-radius: 3px;
  padding: 31px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #f3f6f9;
}
/*tor area scroll start */
.parent-page .ra_science_top_calendar {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-page .ra_science_top_calendar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_science_top_calendar ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_science_top_calendar ul li a span {
  font-size: 14px;
  font-weight: 300;
  margin-left: 20px;
  color: #0078bf;
  -webkit-text-decoration: underline #0078bf;
  text-decoration: underline #0078bf;
}
.parent-page .ra_science_top_calendar ul li a img {
  width: 10px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0px;
  margin-left: 6px;
}
/*tor area scroll end */

.parent-page .ra_jackson_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_jackson_top .dropdown-menu.adjust {
  top: 13px !important;
  left: 5px !important;
  width: 100%;
}
.parent-page .ra_jackson_top .dropdown-item.width-two {
  width: 100%;
}
.parent-page .ra_jackson_top .header-profile-dropdown::before {
  width: 0;
  height: 0;
}
.parent-page .ra_jackson_top .header-profile-dropdown::after {
  width: 0;
  height: 0;
}
.parent-page .ra_jackson_top .profile-text h5 {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 300;
}
.parent-page .ra_jackson_top .profile-text p {
  font-size: 14px;
}
.parent-page .ra_jackson_top .profile-image img {
  width: 60px;
  height: 60px;
  border-radius: 0;
  margin-right: 10px;
}
.parent-page .ra_jackson_top .down-icon-two img {
  right: -39px;
  top: 18px;
}
.parent-page .ra_jackson_top .header-profile-dropdown {
  padding: 0 0px 0 0px;
  position: relative;
}

/*Btn area start*/
.parent-page .ra_jackson_top .contact-button a {
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  padding: 7px 25px;
  border-radius: 26px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .ra_jackson_top .contact-button a:hover {
  color: #fff;
  background-color: #0078bf;
}
.parent-page .ra_jackson_btn_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.parent-page .ra_jackson_top .contact-button2 a {
  color: #fff;
  background-color: #0078bf;
  margin-left: 20px;
}
.parent-page .ra_jackson_top .contact-button2 a:hover {
  background-color: #fff;
  color: #0078bf;
}

/*  Since Progress area start   */
.parent-page .ra_science_progress_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 20px;
}
.parent-page .ra_science_progress_area .slider-course-progress-left p {
  font-size: 14px;
  color: #3f4254;
  font-weight: 300;
}
.parent-page .ra_science_content_area p {
  padding-top: 15px;
  color: #3f4254;
  font-weight: 300;
  font-family: Avenir;
  font-size: 14px;
}
.parent-page .ra_science_content_area p a {
  -webkit-text-decoration: underline #0078bf;
  text-decoration: underline #0078bf;
  color: #0078bf;
}
.parent-page .ra_science_progress_area .myclassroom-single-left-cross-progress {
  width: 247px;
  height: 7px;
  background: #e4e6ef;
  border-radius: 7px;
  margin: 8px 0;
}
.parent-page .myclassroom-single-left-cross-progress.myclassroom-single-left-cross-progress_extra {
  background: #05233b;
}
.parent-page .ra_science_progress_area .barra-nivel {
  height: 7px;
  background: #00a2a7;
}
.parent-page .ra_science_progress_area .course-progress-right h5,
.parent-page .course-progress-right span {
  font-weight: 300;
  font-size: 12px;
  color: #181c32;
  text-transform: inherit;
  line-height: 9px;
}
.parent-page .ra_science_progress_area .slider-course-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .ra_science_progress_area .course-progress-right {
  margin-left: 12px;
  margin-top: 0px;
}
.parent-page .ra_science_progress_area .course-progress-right_ex_ex {
  margin-top: 22px !important;
}
.parent-page .ra_science_progress_area .course-progress-left p {
  font-size: 14px;
  font-weight: 300;
}
.parent-page .ra_repetable_area .single-top-progress-heading-text p {
  font-weight: 300;
}
.parent-page .ra_science_progress_area .ra_bottom_area_science {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .ra_bottom_area_science img {
  width: 12px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 7px;
}
.parent-page .ra_bottom_area_science_ex {
  margin-right: 10px;
}

/* =================        Repetable area start      =================   */
.parent-page .ra_repetable_area {
  background-color: #f3f6f9;
}

.parent-page .ra_repetable_area .single-top-progress-area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24%;
  flex: 0 0 24%;
}
.parent-page .ra_repetable_area .top-progress-area {
  padding-bottom: 17px;
}

.parent-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(1) {
  /* background:url('../assets/images/learning/bg-progress1.png') no-repeat scroll 0 0 / cover; */
  border: none !important;
}
.parent-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(2) {
  /* background:url('../assets/images/learning/bg-progress2.png') no-repeat scroll 0 0 / cover; */
  border: none !important;
}
.parent-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(3) {
  /* background:url('../assets/images/learning/bg-progress3.png') no-repeat scroll 0 0 / cover; */
  border: none !important;
}
.parent-page .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(4) {
  /* background:url('../assets/images/learning/bg-progress4.png') no-repeat scroll 0 0 / cover; */
  border: none !important;
  color: #181c32;
}
.parent-page .single-top-progress-area_ex .single-top-progress-heading-text p {
  color: #0b0000 !important;
}
.parent-page .single-top-progress-area_ex .single-top-progress-count-text h5 {
  font-size: 26px;
  color: #0b0000;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 0;
}
.parent-page .single-top-progress-area_ex .single-top-progress-count-text h5 span {
  font-size: 14px !important;
  color: #0b0000;
  font-weight: normal;
}
.parent-page .single-top-progress-count-text_ex h5 {
  display: inline-block;
  background-color: #fff;
  font-size: 12px !important;
}
.parent-page .single-top-progress-area.single-top-progress-area_ex {
  padding: 12px 1px 23px 16px !important;
}

/* =================        Repetable area End        =================   */

/* =================      Slider Resources css area start       ===================== */
.parent-page .ra_resources_area {
  background-color: #f3f6f9;
  padding-bottom: 37px;
}
.parent-page .ra_resources_area .myclassroom-area {
  padding: 20px 20px;
}

/* Resources Slide area start */
.parent-page .ra_resources_slider_area_ex {
  padding-left: 30px;
}
.parent-page .ra_resources_slider_area {
  position: relative;
}
.parent-page .ra_resources_slider_area::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 28px;
  /* background: url('../assets/images/learning/affff.png') no-repeat scroll 0 0 / cover; */
  z-index: 1;
}
.parent-page .ra_resources_slider_area_single_main {
  border: 1px solid #e4e6ef;
  border-radius: 4px;
  position: relative;
}
.parent-page .ra_resources_slider_area_top_single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .ra_resources_slider_area_top {
  padding: 10px 15px;
}
.parent-page .ra_resources_area .myclassroom-header {
  padding-bottom: 20px;
}
.parent-page .ra_resources_slider_area_butttom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #b2d8ef;
  padding: 7px 16px;
}
.parent-page .ra_resources_slider_area_butttom_man {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_resources_slider_area_top_single img {
  width: 45px !important;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .ra_resources_slider_area_butttom_man img {
  width: 25px !important;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.parent-page .ra_resources_area .myclassroom-area {
  background-color: #fff;
}
.parent-page .ra_resources_content .single-top-progress-area:hover {
  border: none;
}
.parent-page .ra_resources_slider_pdf {
  text-align: right;
}
.parent-page .ra_resources_slider_area_top_single ul li {
  font-size: 9px;
}
.parent-page .ra_resources_slider_area_middle h2 {
  font-size: 15px;
  font-weight: 300;
  padding: 20px 0 10px 0;
  color: #3e3f42;
}
.parent-page .ra_resources_slider_area_middle p {
  font-size: 12px;
  font-weight: 300;
  color: #3e3f42;
  padding-right: 20px;
}
.parent-page .ra_resources_slider_area_middle {
  height: 100px;
}
.parent-page .ra_resources_slider_pdf a {
  color: #3f4254;
  font-weight: normal;
  display: inline-block;
  text-transform: uppercase;
  font-size: 9px;
  padding-top: 10px;
}
.parent-page .ra_single_man h6 {
  font-size: 11px;
  color: #05233b;
  font-weight: normal;
}
.parent-page .ra_single_man p {
  font-size: 9px;
  color: #05233b;
  font-weight: normal;
}

.parent-page .ra_resources_area_bottom_text {
  text-align: right;
  padding-top: 60px;
}
.parent-page .ra_resources_area_bottom_text a {
  color: #0078bf;
  font-size: 14px;
  font-weight: 300;
}

/*Text right area start */
.parent-page .ra_resources_right_area {
  background-color: #fff;
  padding: 25px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.parent-page .ra_resources_right_area_single_item {
  padding-bottom: 30px;
  position: relative;
}
.parent-page .ra_resources_right_area_single_item::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: -45px;
  width: 128%;
  height: 1px;
  background-color: #eaedf3;
}
.parent-page .ra_resources_right_area_single_item:last-child:after {
  content: "";
  position: absolute;
  background-color: transparent;
}

.parent-page .ra_resources_right_area_single_item_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_resources_top h4 {
  font-size: 16px;
  color: #3f4254;
  font-weight: 300;
}
.parent-page .ra_resources_top p {
  font-size: 12px;
  padding-top: 15px;
  font-weight: 300;
}
.parent-page .ra_resources_right_area_single_item_img img {
  width: 34px;
  margin-right: 15px;
}
.parent-page .ra_resources_right_area_single_item_img p {
  font-size: 14px;
  font-weight: 300;
}
.parent-page .ra_resources_right_area_single_item_img p:nth-child(2) {
  font-size: 12px;
  padding-top: 7px;
}
.parent-page .ra_resources_bottom_area {
  text-align: center;
}
.parent-page .ra_resources_bottom_area a {
  font-size: 14px;
  font-weight: 300;
  color: #0078bf;
}
/* =================       Resources css area end       ===================== */

/* =================       Resources css area end       ===================== */

/*================== Start Dropdown Menu Area ==================*/
.parent-page .dropdown_search_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .mu_dropdown_menu_content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
}
.parent-page .mu_search_bar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
}

.parent-page .mu_dropdown_menu_area {
  background: #f3f6f9;
  padding-top: 10px;
}
.parent-page .mu_dropdown_menu_content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_dropdown_menu_content ul li {
  color: #3f4254;
  margin-right: 20px;
  font-size: 12px;
}
.parent-page .mu_dropdown_menu_content ul li a,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: capitalize;
  margin: 18px 0;
  position: relative;
}
.parent-page .mu_dropdown_menu_content ul li button.menu-drop {
  padding-right: 30px;
}
.parent-page .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
  color: #0078bf;
  border-bottom: 3px solid #0078bf;
}
.parent-page .mu_dropdown_menu_content ul li a.active,
.parent-page .mu_dropdown_menu_content ul li a:hover {
  color: #0078bf;
}

.parent-page .dropdown-menu.new-menu.show.ra_custom_checkbox_area_up {
  top: -14px !important;
  position: absolute !important;
}

.parent-page .mu_dropdown_menu_content ul li a img,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop img {
  height: 14px;
  width: auto;
  margin-top: -7px;
  margin-right: 2px;
}
.parent-page .mu_dropdown_menu_content ul li a span {
  background: #145b94;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 1px 5px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}

/* Start View icons*/
.parent-page .mu_view_icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_view_icons ul li {
  margin: 0;
  margin-right: 20px;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .mu_view_icons ul li:last-child {
  margin-right: 0;
}
.parent-page .mu_view_icons ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .view_img_1 {
  width: 11px;
}
.parent-page .view_img_2 {
  width: 17px;
}
/* End View icons*/

/*   Radio btn area start   */
/* custom checkbox */
.parent-page .custom_checkbox_area label span {
  font-size: 14px;
  font-weight: 300;
}
.parent-page .recommendation_checkbox label sapn {
  cursor: pointer;
}
.parent-page .custom_checkbox_area {
  padding: 10px 17px;
}
.parent-page .custom_checkbox_area label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* custom checkbox */
.parent-page .custom_checkbox_area input[type="radio"] {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #3f51b5;
  border-radius: 3px;
  background: #fff;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.parent-page .recommendation_checkbox input[type="checkbox"] {
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d8dce6;
  border-radius: 3px;
  background: #fff;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.parent-page .custom_checkbox_area input[type="radio"]:checked,
.parent-page .recommendation_checkbox input[type="checkbox"]:checked {
  border: 2px solid #3f51b5;
  background: #3f51b5;
}

.parent-page .custom_checkbox_area input[type="radio"]:checked::before,
.parent-page .custom_checkbox_area input[type="radio"]:checked::after,
.parent-page .recommendation_checkbox input[type="checkbox"]:checked::before,
.parent-page .recommendation_checkbox input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  height: 2px;
  background: #fff;
}
.parent-page .custom_checkbox_area input[type="radio"]:checked::before {
  width: 8px;
  top: 8px;
  left: 0px;
  -webkit-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  transform: rotate(44deg);
}
.parent-page .recommendation_checkbox input[type="checkbox"]:checked::before {
  width: 7px;
  top: 6px;
  left: 0px;
  -webkit-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  transform: rotate(44deg);
}
.parent-page .custom_checkbox_area input[type="radio"]:checked::after {
  width: 11px;
  top: 6px;
  left: 4px;
  -webkit-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.parent-page .recommendation_checkbox input[type="checkbox"]:checked::after {
  width: 10px;
  top: 5px;
  left: 3px;
  -webkit-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.parent-page .custom_checkbox_area input[type="radio"]:focus,
.parent-page .recommendation_checkbox input[type="checkbox"]:focus {
  outline: none !important;
}
.parent-page .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*Resources css area start header */
.parent-page .ra_courch_area {
  padding: 0px 0 45px 0;
}
.parent-page .ra_courch_area .barra-nivel {
  background: #00a2a7;
}
.parent-page .ra_courch_area_header h2 {
  font-size: 18px;
  color: #05233b;
  font-weight: 300;
}
.parent-page .ra_courch_area .mu_dropdown_menu_content ul li:last-child a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: inline-block;
  color: #0078bf;
}
.parent-page  .ra_courch_area .mu_dropdown_menu_content ul li:last-child img {
  width: 12px;
  height: auto;
  margin-right: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .ra_courch_area .mu_dropdown_menu_content ul li a:hover::after,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
  background: transparent;
}

/*  Ra_courch prpgress area start  */
.parent-page .ra_courch_single_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 14px 24px;
  border-radius: 5px;
  border: 1px solid #f7f7f7;
  margin-bottom: 12px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .ra_courch_single_item_left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
}
.parent-page .ra_courch_single_item_middle {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.parent-page .ra_courch_single_item_right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
/*Top area start */
.parent-page .ra_courch_single_item_left_sub_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_courch_single_item_left_sub_main img {
  width: 30px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 20px;
}
.parent-page .ra_courch_single_item_left_content h5 {
  font-size: 18px;
  font-weight: 300;
}
.parent-page .ra_courch_single_item_left_content p {
  font-size: 14px;
  color: #3f4254;
}

/*middle area start */
.parent-page .ra_courch_ra_progress_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/*More area start */
.parent-page .ra_courch_more_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_courch_more_area .contact-button a {
  color: #0078bf;
  border: 1px solid #0078bf;
  margin-right: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .ra_courch_more_area .contact-button a:hover {
  color: #fff;
  background-color: #0078bf;
}
.parent-page .ra_courch_more_area img {
  width: auto;
  height: 4px;
  cursor: pointer;
}
.parent-page .ra_courch_ra_progress_area h5 {
  font-weight: 300;
  font-size: 14px;
}

/* =================       Resources css area end       ===================== */

/* =================       Calender css area Start       ===================== */
.parent-page .ra_calender_area .upcoming-heading-content {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .ra_day_tody_area_sub .contact-button a {
  color: #0078bf;
  border: 1px solid #0078bf;
  margin: 0 10px;
  padding: 6px 23px;
  font-size: 14px;
  border-radius: 50px;
  transition: 0.3s;
}
.parent-page .contact-button button.change {
  background: none;
}
.parent-page .contact-button button.change img {
  width: 14px;
  height: auto;
  margin: 0 15px;
}
.parent-page .ra_day_tody_area_sub .contact-button a:hover {
  background-color: #0078bf;
  color: #fff;
}
.parent-page .ra_calender_area .upcoming-heading-content {
  background: #f3f6f9;
  padding: 15px 0px;
}
.parent-page .ra_day_tody_area_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -5px;
}
.parent-page .ra_calender_area .btn-group.btn-group-toggle {
  background: #fff;
}
.parent-page .ra_calender_area .table-active,
.parent-page .table-active > td,
.parent-page .table-active > th {
  background-color: #e4e6ef;
}
.parent-page .ra_calender_area .table thead th {
  font-size: 14px;
  color: #3f4254;
  font-weight: normal;
  padding: 10px 20px 10px 20px;
}
.parent-page .ra_calender_area .upcoming-heading-content p {
  font-size: 15px;
}
.parent-page .upcoming-area.ra_calender_area.upcoming-area tbody td {
  height: 61px;
}
.parent-page .ra_calender_area .table td,
.parent-page .table th {
  border-bottom: 2px solid #dee2e6;
  border-top: 0px solid transparent;
}
.parent-page .ra_calender_area.upcoming-area tbody {
  border-top: 4px solid #eaedf3;
}
.parent-page .ra_calender_area.upcoming-area tbody td {
  border-right: 15px solid #eaedf3;
  height: 400px;
  padding: 0;
  border-bottom: 1px solid #eaedf3;
  position: relative;
}
.parent-page .ra_calender_area.upcoming-area tbody td:last-child {
  border-right: 1px solid #eaedf3;
}
.parent-page .ra_calender_area.upcoming-area {
  padding-bottom: 25px;
}

/*Checkbox area start */
.parent-page .ra_calender_area .ra_custom_checkbox_area_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_calender_area .ra_custom_checkbox_area_item ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_custom_checkbox_area_item label {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}
.parent-page .parent-page .ra_calender_area .ra_custom_checkbox_area_item ul li a {
  font-size: 25px;
  margin-left: 50px;
  color: #a6adb1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .ra_calender_area .ra_custom_checkbox_area_item ul li a:hover {
  color: #0078bf;
}

.parent-page .ra_calender_area.upcoming-area .monday-first-card {
  background-color: #b2d8ef;
  border-left: 2px solid #387ebe;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 7px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9999999;
}

/* =================       Calender css area end       ===================== */

/*================================================================================================
    ====                                    LEARNING PAGE CSS End                                  ====
    ================================================================================================*/

/*================================================================================================
    ====                                    Schedule-W PAGE CSS START                                  ====
    ================================================================================================*/

/*  RA-Event area start   */
.parent-page .ra_event_area {
  padding-bottom: 10px;
}
.parent-page .upcoming-heading-content.ra_upcoming_heading {
  padding-left: 0;
}
/*Top btn area start */
.parent-page .ra_event_right_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 10px;
}
.parent-page .ra_event_right_btn .contact-button a {
  font-weight: 300;
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  padding: 6px 25px;
  border-radius: 40px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .ra_event_right_btn .contact-button a:hover {
  color: #fff;
  background-color: #0078bf;
}
/*Slider area start */
.parent-page .ra_event_main_area h2 {
  padding-bottom: 27px;
  font-weight: 300;
  font-size: 20px;
}
.parent-page .ra_event_single_slider_area {
  background: #c8cbde4f;
  padding: 24px 8px 24px 20px;
  border-left: 7px solid #c8cbde;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.parent-page .ra_event_main_area_slider_items {
  position: relative;
}
.parent-page .ra_event_main_area_slider_items::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  /* background: url('../assets/images/learning/affff.png') no-repeat scroll 0 0 / cover; */
  z-index: 1;
}

.parent-page .ra_event_single_slider_area_top h5 {
  font-size: 16px;
  font-weight: 500;
  color: #05233b;
  padding: 10px 0;
}
.parent-page .ra_event_single_slider_area_top h4 {
  font-weight: 300;
  font-size: 30px;
  padding-bottom: 10px;
}

.parent-page .ra_event_single_slider_area_bottom {
  margin-left: 20px;
}
.parent-page .ra_event_single_slider_area_bottom h1 {
  font-size: 14px;
  font-weight: 300;
}
.parent-page .ra_event_single_slider_area_bottom p {
  font-size: 12px;
  margin-top: 10px;
}
.parent-page .ra_event_single_slider_area1 {
  border-left: 7px solid #a4bbcc !important;
  background: #a4bbcc73 !important;
}
.parent-page .ra_event_single_slider_area2 {
  background: #c8cbde4f !important;
  border-left: 7px solid #c8cbde !important;
}
.parent-page .ra_event_single_slider_area3 {
  border-left: 7px solid #1a6a9a;
  background: #cae2f1;
}
.parent-page .ra_event_single_slider_area4 {
  border-left: 7px solid #00a2a7;
  background: #00a2a757;
}
/* =================================    Day Area start  ==================================== */

.parent-page .ra_day_view_area .upcoming-heading-content {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.parent-page .upcoming-heading-content {
  background: #f3f6f9;
  padding: 5px 15px;
}
.parent-page .ra_day_view_area .contact-button a {
  margin: 0 6px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.parent-page .ra_day_view_area .contact-button a:hover {
  color: #fff;
  background-color: #0078bf;
}
.parent-page .ra_day_view_area .btn-group,
.parent-page .btn-group-vertical {
  background: #fff;
  margin-left: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_day_view_area .upcoming-heading-content p {
  font-weight: 300;
  font-size: 14px;
  color: #05233b;
  margin-right: 10px;
}
.parent-page .ra_day_view_area .btn-group .btn {
  border: 1px solid #eaedf3;
  background-color: transparent;
}
.parent-page .ra_day_view_area .upcoming-heading-content {
  padding: 36px 0;
}
/* ===========  Teacher list area start  ========== */
.parent-page .ra_teacher_list_area_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e4e6ef;
  padding: 13px 0px 13px 30px;
  border: 1px solid #f7f7f7;
}
.parent-page .ra_teacher_list_area_header_single_item h2 {
  color: #3f4254;
  font-size: 14px;
  font-weight: 300;
}
/*studernt list */
.parent-page .ra_teacher_list_area_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 5px solid #f3f6f9;
  background: #fff;
  padding: 22px 10px 22px 41px;
  border-radius: 7px;
}
.parent-page .re_body_list_item1,
.parent-page .re_header_list_item1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
}
.parent-page .re_body_list_item2,
.parent-page .re_header_list_item2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.parent-page .re_body_list_item3,
.parent-page .re_header_list_item3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.parent-page .re_body_list_item4,
.parent-page .re_header_list_item4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2 img {
  margin-right: 10px;
  height: 45px;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 img {
  height: 45px;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 9px;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1 p {
  font-size: 18px;
  color: #181c32;
  margin-left: 11px;
  font-weight: 300;
}
.parent-page .ra_teacher_list_area_header_single_item_body_single_man h6 {
  font-size: 14px;
  font-weight: 300;
}
.parent-page .ra_teacher_list_area_header_single_item_body_single_man p {
  font-size: 12px;
  color: #3f4254;
  padding-top: 7px;
}
.parent-page .ra_teacher_list_area_header_single_item_body p {
  font-size: 14px;
  font-weight: 300;
  color: #3f4254;
}

.parent-page .ra_schedule_calender_area .ra_monday-first-card_ex_b {
  border-left: 4px solid #a4bbcc;
  background-color: #a4bbcc66;
}
.parent-page .ra_schedule_calender_area .ra_monday-first-card_ex_s {
  border-left: 4px solid #addedc;
  background-color: #addedc73;
}
.parent-page .ra_schedule_calender_area.upcoming-area tbody td {
  border-right: 15px solid #eaedf3;
  height: 60px;
  padding: 0;
  border-bottom: 1px solid #eaedf3;
  position: relative;
}
.parent-page .ra_schedule_calender_area.upcoming-area tbody td:last-child {
  border-right: 0px solid #eaedf3;
}
.parent-page .ra_schedule_calender_area .upcoming-heading-content p,
.parent-page .ra_upcoming_heading p {
  font-weight: 300;
  font-size: 16px;
  color: #05233b;
}
.parent-page .ra_upcoming_heading p {
  padding-bottom: 10px;
}
.parent-page .ra_schedule_calender_area .table thead th {
  font-weight: 300;
  padding: 12px 40px;
  font-size: 14px;
}
.parent-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li {
  margin-left: 30px;
}
.parent-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a {
  font-size: 20px;
  color: #a6adb1;
  display: inline-block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.parent-page .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a:hover {
  color: #0078bf;
}
.parent-page .ra_side_area_active {
  background-color: #0078bf;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 14px !important;
  color: #fff !important;
}
.parent-page .ra_schedule_calender_area.upcoming-area {
  background-color: #f3f6f9;
  padding-bottom: 64px;
  padding-top: 28px;
}
/*================================================================================================
    ====                                    Schedule-W PAGE CSS END                                  ====
    ================================================================================================*/

/* ================================================================================================
    ====                               Schedule-month PAGE CSS START                            ====
    ================================================================================================ */
/*  Windows area start   */
.parent-page .ra_windows_main_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.parent-page .ra_calender_main_area {
  padding: 0px 0 59px 0;
}
.parent-page .ra_calender_left_area_main_area_btn .contact-button a {
  font-weight: 300;
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  padding: 5px 13px;
  border-radius: 30px;
  max-width: 230px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 27px;
  left: 0;
  right: 0;
  margin: auto;
}
.parent-page .ra_calender_left_area_main_area {
  background-color: #fff;
  padding: 30px 15px 70px 15px;
  height: 100%;
}
.parent-page .ra_calender_left_area_sub_area {
  padding-left: 15px;
}
.parent-page .ra_windows_main_area .view_img_1 {
  width: 16px;
}
.parent-page .ra_main_left_color_select .custom-control-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #3e3f42;
  font-weight: 300;
  cursor: pointer;
}
.parent-page #dropdownMenuButton {
  font-size: 14px;
  font-weight: 300;
  color: #181c32;
}
.parent-page .ra_main_left_color_select .custom-control-label img {
  margin-right: 10px;
  margin-top: 2px;
  margin: 2px 10px 0 10px;
}
.parent-page .ra_calender_left_area_sub_area h3 {
  font-size: 14px;
  color: #3f4254;
  font-weight: 500;
  padding-bottom: 23px;
  padding-top: 3px;
}
.parent-page .custom-control-label.re_custom-control-label_single_man img {
  width: 24px;
}
.parent-page .ra_windows_main_area .ra_main_left_color_select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_windows_main_area .custom-control.custom-checkbox.mb-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*Right site area start */
.parent-page .ra_calender_main_area .upcoming-heading-content .contact-button a {
  background-color: transparent;
  color: #0078bf;
  margin: 0 10px;
  padding: 6px 23px;
  font-size: 14px;
  border-radius: 50px;
}
.parent-page .upcoming-heading-content .contact-button a:hover {
  color: #fff;
  border: 1px solid #0078bf;
  background-color: #0078bf;
}
.parent-page .ra_calender_left_area.upcoming-area {
  padding-bottom: 0px;
}
.parent-page .ra_calender_left_area.upcoming-area tbody td {
  height: 170px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px solid #eaedf3;
  position: relative;
}
.parent-page .ra_calender_left_area .table-active,
.parent-page .table-active > td,
.parent-page .table-active > th {
  background-color: #fff;
}
.parent-page .ra_calender_left_area .table thead th {
  border-bottom: 1px solid #dee2e6;
}
.parent-page .ra_calender_left_area .monday-first-card {
  background-color: #b2d8ef;
  border-left: 2px solid #387ebe;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 7px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9999999;
  top: 40%;
}
.parent-page .ra_calender_left_area.upcoming-area tbody td span {
  padding: 8px 17px;
  font-size: 12px;
  color: #3e3f42;
  font-weight: 300;
}
.parent-page .ra_calender_left_area.upcoming-area tr th {
  text-align: center;
}
.parent-page .ra_calender_left_area.upcoming-area tr td {
  text-align: left;
}
.parent-page .ra_calender_left_area.upcoming-area .upcoming-heading-content {
  background: #fff;
  padding: 20px 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eaedf3;
}
.parent-page .ra_calender_left_area .table thead th {
  border-bottom: 1px solid #dee2e6;
  font-weight: 300;
  font-size: 12px;
  padding: 14px 41px;
}
.parent-page .ra_calender_left_area .table {
  margin-bottom: 0rem;
}
.parent-page .ra_calender_left_area.upcoming-area .upcoming-heading-content p {
  font-weight: 300;
  font-size: 18px;
  color: #05233b;
  margin-right: 10px;
}
.parent-page .ra_badami_color {
  background-color: green;
}

/*Color system area start */
.parent-page .monday-first-card.ra_badami_color {
  background: #e6772b54;
  border-left: 3px solid #e6772b;
  line-height: 2;
}
.parent-page .monday-first-card.ra_badami_color h6 {
  padding-bottom: 17px;
  font-size: 14px;
  font-weight: 300;
}
.parent-page .monday-first-card.ra_badami_color2 {
  background: #e6772b54;
  border-left: 3px solid #e6772b05;
  color: #e6772b05;
  padding: 34px 0;
}
.parent-page .monday-first-card.ra_skyblue_color {
  background-color: #addedc4f;
  border-left: 3px solid #addedc;
}
.parent-page .monday-first-card.defarent_angle_table {
  background-color: #f3f6f9;
  top: 40%;
  border-left: 3px solid #f3f6f9;
  border-bottom: 1px solid #fff;
}
.parent-page .monday-first-card.ra_skyblue_color.ra_skyblue_color_ex {
  padding: 7px 1px 7px 3px;
  top: 60%;
}
.parent-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno {
  top: 71%;
  background: transparent;
  border-left: 3px solid transparent;
}
.parent-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a {
  color: #3f4254;
  font-size: 12px;
  font-weight: normal;
}
.parent-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a:hover {
  color: #000;
}
.parent-page .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno.ex_up {
  top: 77%;
}

.parent-page .ra_calender_left_area.upcoming-area
  .upcoming-heading-content
  .contact-button
  a {
  background-color: #0078bf;
  color: #fff;
  padding: 6px 50px;
}
.parent-page .ra_calender_left_area.upcoming-area
  .upcoming-heading-content
  .contact-button
  a:hover {
  background-color: #fff;
  color: #0078bf;
}

/* ================================================================================================
    ====                               Schedule-month PAGE CSS End                            ====
    ================================================================================================ */

/*===================================================================================
  ====                      Start Progress list pages css                        ====
  =================================================================================== */
/*list_resource_pages_main_area*/
 .list_document_resource_main {
  margin-top: 5px;
  overflow: hidden;
}
 .list_check_sortable_main {
  background: #e4e6ef;
  padding: 12px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
 .list_check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.list_prog_heading {
  margin-top: 35px;
}
 .list_check .mu-form-group label::before {
  background-color: #fff;
  border: 1px solid #7e8299;
  padding: 8px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.list_check .mu-form-group input:checked + label::after {
  top: 8px;
  left: 6.5px;
  width: 6px;
  height: 12px;
  border: solid #707070;
  border-width: 0 2px 2px 0;
}
.li_star_design a img {
  width: 15px;
  height: auto;
}
 .li_arrow_sort button img {
  max-width: 12px;
  width: 100%;
  height: auto;
}
 .li_arrow_sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: -5px;
  margin-left: 13px;
}
.single_sortable_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single_sortable_design h4 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #3f4254;
}
 .list_top_check {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}
.li_sortable_source_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
}
 .li_sortable_source_middle_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
}
 .li_sortable_source_right_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  padding-right: 50px;
}
.li_sortable_source_right_area .single_sortable_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.li_arrow_sort button {
  background: transparent;
}
 .li_arrow_sort button:nth-child(1) {
  display: block;
  margin-bottom: -2px;
}
 .li_arrow_sort button:nth-child(2) {
  display: block;
  margin-top: -2px;
}
.left_dc_img img {
  max-width: 46px;
  width: 100%;
  height: auto;
}
.right_dc_img a img {
  max-width: 15px;
  width: 100%;
  height: auto;
  margin-right: 4px;
}
.parent-page .right_dc_img a {
  color: #080f1e;
  font-weight: 300;
  font-size: 14px;
}
.parent-page .right_dc_img a span {
  color: #0078bf;
  -webkit-text-decoration: underline #0078bf;
  text-decoration: underline #0078bf;
}
.parent-page .right_dc_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.parent-page .dc_single_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fff;
  padding: 17px 16px 25px;
  border: 1px solid #f7f7f7;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}
.parent-page .dc_middle_txt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.parent-page .dc_middle_txt a {
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  background: #00a2a7;
  padding: 3px 9px;
  display: inline-block;
  position: absolute;
  left: -5px;
  top: -1px;
}
.parent-page .dc_middle_txt p {
  color: #3f4254;
  font-weight: 400;
  font-size: 12px;
  padding-left: 37px;
}
.parent-page .dc_dots_area .down-icon {
  position: relative;
}
.parent-page .dc_dots_area .down-icon img {
  width: 20px;
  height: auto;
  right: -35px;
  top: -7px;
  position: absolute;
}
.parent-page .right_dc_date_time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
}
.parent-page .dc_dots_area button {
  background: none;
}
.parent-page .right_dc_date_time > a {
  font-size: 15px;
  color: #3f4254;
  font-weight: 300;
  display: inline-block;
  margin-top: 2.5px;
}
.parent-page .left_dc_img {
  margin-right: 27px;
}
.parent-page .dc_check_star {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 6%;
  flex: 0 0 6%;
}
.parent-page .dc_jackson_img_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 34%;
  flex: 0 0 34%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .right_dc_img a:nth-child(2) span {
  font-weight: 400;
  font-size: 12px;
}
.parent-page .right_dc_img a:nth-child(1) {
  margin-bottom: 10px;
}
.parent-page .right_dc_img a:nth-child(2) {
  margin-top: 10px;
}
.parent-page .dc_dots_area {
  position: relative;
}
.parent-page .dc_dots_area .dropdown-menu {
  min-width: 190px;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid #e4e6ef;
  padding: 5px 0 !important;
  left: -130px !important;
  top: 10px !important;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.parent-page .dc_dots_area .dropdown-menu a {
  font-size: 14px;
  font-weight: 400;
}
.parent-page .document_pagenations_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 30px;
}
.parent-page .left_pg_area a {
  color: #3f4254;
  font-size: 12px;
  font-weight: 300;
  margin-right: 20px;
}
.parent-page .right_pg_area .page-item .page-link img {
  max-width: 10px;
  width: 100%;
  height: auto;
  display: block;
}
.parent-page .right_pg_area .pagination {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .right_pg_area .page-link {
  padding: 0.7rem 1rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}
.parent-page .right_pg_area .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.parent-page .right_pg_area ul li {
  margin: 0 12px;
}
.parent-page .right_pg_area .page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.parent-page .list_prog_heading .mu_dropdown_menu_content ul li:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .list_prog_heading .mu_dropdown_menu_content ul li:last-child img {
  width: 12px;
  height: auto;
  margin-right: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}
.parent-page .list_prog_heading .mu_dropdown_menu_content ul li a:hover::after,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
  display: none;
}

/*===================================================================================
  ====                      End Progress list pages css                        ====
  =================================================================================== */

/* ================================================================================================
    ====                               Trecking PAGE CSS Start                            ====
    ================================================================================================ */
.parent-page .ra_sortable_area {
  padding-bottom: 40px;
}
.parent-page .ra_process_tracking_area {
  padding: 25px;
}
.parent-page .ra_process_tracking_area .pt_view_main_area {
  margin-bottom: 10px;
  padding-top: 30px;
}
.parent-page .ra_process_tracking_area_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: flex-end;
}
.parent-page .ra_jackson_btn_area a {
  color: #0078bf;
  background: #fff;
  border: 1px solid #0078bf;
  font-size: 14px;
  padding: 7px 27px;
  border-radius: 50px;
  margin-left: 15px;
}
.parent-page .ra_process_tracking_area_header_search_width {
  max-width: 585px;
  width: 100%;
}

.parent-page .ra_process_tracking_area_header_search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_process_tracking_area h3 {
  color: #05233b;
  font-size: 20px;
  font-weight: 300;
}
.parent-page .ra_process_tracking_area h4 {
  color: #05233b;
  font-size: 18px;
  font-weight: 300;
}
.parent-page .ra_process_tracking_single h3 {
  padding-bottom: 14px;
  font-weight: 300;
  font-size: 18px;
}

.parent-page .ra_process_tracking_area_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 27px;
  padding: 12px 0 27px 0;
}
.parent-page .ra_process_tracking_single {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  text-align: center;
  position: relative;
}
.parent-page .ra_process_tracking_single::after {
  content: "";
  position: absolute;
  background-color: #7070703d;
  width: 1px;
  height: 70px;
  right: 0;
  top: 0;
}
.parent-page .ra_process_tracking_single:last-child::after {
  background-color: transparent;
  position: absolute;
  content: "";
}
.parent-page .slider-course-progress {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parent-page .ra_process_tracking_area_body .barra-nivel {
  background: #00a2a7;
}
/*seach btn area start*/
.parent-page .ra_sortable_area_btn_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_process_tracking_area
  .myclassroom-single-right-right-content
  .dropdown
  button {
  padding: 6px 24px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 300;
  margin-left: 14px;
}
.parent-page .ra_process_tracking_area .ra_btn_btnarea a {
  color: #fff;
  background: #00a2a7;
  display: inline-block;
  padding: 1px 10px;
  border-radius: 4px;
}
.parent-page .ra_sortable_body_main_area .contact-button a {
  padding: 4px 18px;
}

/*seach btn area End*/

/* ======================       Ra srot able area start         ======================= */

.parent-page .ra_sortable_area .mu_view_icons ul li {
  font-weight: 300;
}
.ra_sortable_area .mu_dropdown_menu_content ul li:last-child a {
  color: #0078bf;
}
.parent-page .ra_sortable_area .mu_dropdown_menu_content ul li a:hover::after,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
  content: "";
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0%;
  bottom: 0;
  left: 0;
}
/*  Sort able area start   */
.parent-page .ra_sortable_header_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e4e6ef;
  padding: 5px 0;
}
.parent-page .ra_sortable_area_menu_ex_area h5 {
  color: #05233b;
  font-size: 14px;
  font-weight: 300;
  padding-left: 30px;
}
.parent-page .ra_sortable_area_menu_ex_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 34%;
  flex: 0 0 34%;
}

.parent-page .ra_sortable_header_area_menu > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_sortable_header_area_menu > ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_sortable_header_area_menu > ul li ul li {
  line-height: 1;
}
.parent-page .ata_je_ki_rokom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.parent-page .ata_je_ki_rokom span {
  line-height: 1.2;
}
.parent-page .ra_sortable_header_area_menu > ul li ul li img {
  margin-left: 5px;
  cursor: pointer;
}
.parent-page .ra_sortable_header_area_menu ul li {
  color: #3f4254;
  font-size: 12px;
  font-family: Source Sans Pro;
  font-weight: 300;
  padding: 2px 8px;
  margin-left: 8px;
}

/*  Sort able area End   */

/*Sort Able area start */

.parent-page .class_area_sort_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  padding: 18px 22px 22px;
  margin-bottom: 15px;
  border: 1px solid #f7f7f7;
  -webkit-box-shadow: 0px 3px 3px #8888880f;
  box-shadow: 0px 3px 3px #8888880f;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .class_area_sort_flex:hover {
  background-color: #a6adb147;
  cursor: pointer;
}
.parent-page .ra_sort_left_area_body {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27%;
  flex: 0 0 27%;
}
.parent-page .ra_sort_left_area_body .barra-nivel {
  background: #00a2a7;
}

.parent-page .ra_sortable_certificate_main {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27%;
  flex: 0 0 27%;
}
.parent-page .ra_sortable_certificate_main_sub_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 50px;
}
.parent-page .ra_sortable_certificate_sub1 img {
  width: 13px;
  margin-right: 5px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.parent-page .ra_sortable_certificate_main2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24%;
  flex: 0 0 24%;
}
.parent-page .ra_sortable_certificate_main2_sub_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.parent-page .ra_sortable_certificate_sub1 a span {
  font-size: 9px;
  font-weight: 300;
  background-color: #dbe6ee;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: #05233b;
  padding: 1px 6px;
  border-radius: 9px;
  display: inline-block;
  line-height: 1;
}

/*.ra_sortable_certificate_sub1 a span:hover,*/
.parent-page .ra_sortable_certificate_sub1 a span.active {
  background-color: #05233b;
  color: #fff;
}
.parent-page .ra_sortable_right_btn_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 22%;
  flex: 0 0 22%;
}
.parent-page .ra_sortable_right_btn_area_sub_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 50px;
}
.parent-page .ra_sortable_right_btn_area_sub2 a img {
  height: 3px;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 8px;
}
.parent-page .ra_sortable_right_btn_area_sub1 .contact-button a {
  color: #0078bf;
  border: 1px solid #0078bf;
}
.parent-page .ra_sortable_right_btn_area_sub1 .contact-button a:hover {
  color: #fff;
}
.parent-page .ra_sortable_certificate_main2 p {
  font-size: 14px;
  color: #3f4254;
  font-weight: 300;
}
.parent-page .ra_sortable_body_main_area .profile-right-section-content p {
  font-weight: 300;
}
.parent-page .ra_sortable_body_main_area .profile-right-section-content span {
  font-weight: 300;
}
/*Sort Able area End */

/*Sort Able area start */
.parent-page .ra_sortable_buttom_area {
  background: #fff;
  padding: 17px 20px;
}
.parent-page .ra_sortable_buttom_area h3 {
  font-size: 18px;
  font-weight: 300;
  color: #05233b;
}
.parent-page .ra_sortable_buttom_area h3 span {
  font-size: 10px;
  line-height: 1;
  background: #dbe6ee;
  color: #05233b;
  padding: 1px 8px;
  border-radius: 12px;
  margin-left: 12px;
}
.parent-page .ra_sortable_buttom_area_item_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .ra_sortable_buttom_single_item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.parent-page .ra_sortable_buttom_single_item_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  padding-top: 17px;
}
.parent-page .ra_sortable_buttom_single_item_sub img {
  height: auto;
  width: 26px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.parent-page .ra_sortable_buttom_single_item_content h5 {
  font-size: 16px;
  color: #05233b;
  font-weight: 300;
}
.parent-page .ra_sortable_buttom_single_item_content p {
  color: #3f4254;
  font-size: 14px;
  font-weight: 300;
}

.parent-page .container_ex_area {
  width: 1140px;
  margin: auto;
  overflow-x: scroll;
}
/*start progress tracking pages css*/
/*start progress tracking pages css*/
.parent-page .ju_pro_heading_area {
  background: #e4e6ef;
  border: 1px solid #e4e6ef;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.parent-page .ju_pro_heading_area ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 35px;
}
.parent-page .ju_pro_heading_area ul li {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
}
.parent-page .ju_single_table_content_design > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .ju_single_table_content_design > ul > li {
  border: 1px solid #e4e6ef;
  padding: 32px 25px;
  border-right: none;
  border-top: none;
  font-weight: 300;
  color: #05233b;
  font-size: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 6);
  flex: 0 0 calc(100% / 6);
  height: 60px;
  cursor: pointer;
}
.parent-page .ju_first {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px !important;
  color: #3f4254 !important;
}
.parent-page .ju_first i {
  font-size: 15px;
  color: #05233b;
  padding-right: 5px;
}
.parent-page .ju_single_table_content_design {
  position: relative;
}
.parent-page .ju_posional_progress .myclassroom-single-left-cross-progress {
  width: 100%;
  height: 15px;
  display: block;
  border-radius: 50px;
  background: #e4e6ef;
  max-width: 700px;
}
.parent-page .ju_posional_progress .barra-nivel {
  height: 15px;
  background: #145b94;
  border-radius: 50px;
}
.parent-page .ju_posional_progress {
  content: "";
  position: absolute;
  width: 100%;
  height: auto;
  left: 207px;
  top: 10px;
}
.parent-page .box_cmt {
  height: 18px;
  width: 18px;
  background: #c8cbde;
  border-radius: 5px;
  margin-right: 8px;
}
.parent-page .box_tb_items1 {
  background: #2692d1;
}
.parent-page .box_tb_items2 {
  background: #dbe6ee;
}
.parent-page .box_tb_items3 {
  background: #05233b;
}
.parent-page .box_tb_items4 {
  background: #1a6a9a;
}
.parent-page .box_tb_items5 {
  background: #00a2a7;
}
.parent-page .ui_single ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.parent-page .text_dft {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.parent-page .ui_single ul li {
  border: 1px solid #e4e6ef;
  padding: 20px 20px;
  border-top: none;
  border-right: none;
  font-size: 10px;
  font-weight: 300;
  color: #05233b;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 6);
  flex: 0 0 calc(100% / 6);
  height: 60px;
}
.parent-page .ui_single .myclassroom-single-left-cross-progress {
  height: 15px;
  border-radius: 50px;
  width: 100%;
}
.parent-page .ui_single .barra-nivel {
  height: 15px;
  background: #145b94;
  border-radius: 50px;
}
.parent-page .hidden_basic {
  opacity: 0;
}
/*update css files */
.parent-page .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: none;
}
.parent-page .bg_header_area {
  background: #e4e6ef;
}
.parent-page .ju_progress_tracking_main {
  margin-bottom: 100px;
}
.parent-page .ui_single {
  background: #fff;
}
.parent-page .tide_here {
  padding: 30px 12px !important;
}
 .rjdesign.active .adil {
  opacity: 0;
}
 .rjdesign.active .ju_first i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin-right: 5px;
}

/* ================================================================================================
    ====                               Trecking PAGE CSS End                            ====
    ================================================================================================ */

/* ================================================================================================
    ====                               LearningF PAGE CSS Start                            ====
    ================================================================================================ */

.parent-page .ra_learningf_header_diable_area .mu_alert_box,
.parent-page .custom_alert_design {
  margin-top: 11px;
}
.parent-page .ra_learningf_header_diable_area_sub_area h1 {
  font-size: 20px;
  color: #05233b;
  font-weight: 300;
  padding: 23px 0 22px 0;
}
.parent-page .ra_calender_area_bottom_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.parent-page .ra_calender_area_bottom_checkbox.change {
  display: inherit;
}
.parent-page .ra_sortable_learning_page_area .ra_sort_left_area_body {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 23%;
  flex: 0 0 23%;
}
.parent-page .ra_sortable_learning_page_area .ra_sortable_certificate_main {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 22%;
  flex: 0 0 22%;
}
.parent-page .ra_sortable_learning_page_area .ra_sortable_certificate_main2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}
.parent-page .ra_sortable_certificate_main_sub_item_area {
  margin-right: 20px;
}
.parent-page .ra_sortable_learning_page_area .ra_color_pikar_section {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
}
.parent-page .ra_sortable_learning_page_area .ra_sortable_right_btn_area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24%;
  flex: 0 0 24%;
}
.parent-page .ra_sortable_learning_page_area .ra_sortable_right_btn_area_sub_item_area {
  margin-left: 12px;
}
.parent-page .ra_sortable_date_sub_area p {
  font-size: 14px;
  color: #3f4254;
  font-weight: 300;
}
.parent-page .ra_sortable_learning_page_area .ra_sortable_certificate_sub_item13 {
  margin-left: 35px;
  line-height: 1;
}

/*.ra_sortable_learning_page_area*/
.parent-page .ra_sortable_learning_page_area {
  padding-bottom: 80px;
}
.parent-page .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:last-child {
  color: #0078bf;
}

.parent-page .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:hover::after,
.parent-page .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
  content: "";
  position: absolute;
  background: transparent;
  height: 0px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parent-page .ra_sortable_learning_page_area_search_area_start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 494px;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 7px;
}
.parent-page .ra_sortable_learning_page_area_search_area_start .mu_dropdown {
  top: 42px;
}
.parent-page .ra_calender_area_bottom_checkbox ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .ra_sortable_learning_page_area .ra_side_area_active {
  margin: 0 32px;
  border-radius: 4px;
}
.parent-page .ra_sortable_learning_page_area .ra_calender_area_bottom_checkbox ul li a {
  color: #a6adb1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.parent-page .ra_sortable_learning_page_area
  .ra_calender_area_bottom_checkbox
  ul
  li
  a:hover {
  color: #0078bf;
}

.parent-page .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon img {
  width: 20px;
  position: initial;
}
.parent-page .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.parent-page .ra_sortable_right_btn_area_sub2 .dc_dots_area {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 99;
}
/*messages pages*/
.parent-page .mu_people_list_right .dc_dots_area .down-icon img {
  position: initial;
}
.parent-page .mu_people_list_right .dc_dots_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.parent-page .mu_people_list_right .dc_dots_area .down-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* ================================================================================================
    ====                               Learning PAGE CSS End                            ====
    ================================================================================================ */

/* Start Home Page Toggleing */
.parent-page .tabcontent.homepage {
  display: block;
}
.parent-page #home {
  position: relative;
}
.parent-page .home-card-content {
  display: none;
}
.parent-page .home-page-content.hide-home {
  display: none;
}
.parent-page .home-card-content.show-home {
  display: block;
}
.parent-page #learning {
  position: relative;
}
.parent-page .learning-first-content.hide-learn {
  display: none;
}
.parent-page #schedule {
  position: relative;
}
.parent-page .schedule-week-content {
  display: none;
}
.parent-page .schedule-week-content.show-week {
  display: block;
}
.parent-page .schedule-month-content.hide-week {
  display: none;
}
.parent-page .progress-traking-first-content .ra_process_tracking_area {
  padding: 0;
}
.parent-page .home-text-content.new h4 {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.parent-page .home-text-area.new {
  margin-bottom: 20px;
}
.parent-page #traking {
  position: relative;
}
.parent-page .progress-traking-first-content {
  display: none;
}
.parent-page .progress-traking-first-content.hide-traking {
  display: block;
}
.parent-page .progress-traking-second-content.show-traking {
  display: none;
}

.parent-page .traking-content-details {
  display: none;
}
.parent-page .traking-content-details.show-traking-details {
  display: block;
}
.parent-page .progress-traking-first-content.hide-traking-details {
  display: none;
}
.parent-page #resources {
  position: relative;
}
.parent-page .first-resources-content {
  display: none;
}
.parent-page .first-resources-content.hide-resources {
  display: block;
}
.parent-page .second-resources-content.show-resources {
  display: none;
}
.parent-page .show-all-view-details,
.parent-page .slider-content-details {
  display: none;
}
.parent-page .show-all-view-details.show-details,
.parent-page .slider-content-details.show-details {
  display: block;
}
.parent-page .learning-first-content.hide-details,
.parent-page .slider-content-details.hide-details {
  display: none;
}
.parent-page .learning-first-content.hide-learn-details,
.parent-page .slider-traking-first-content.hide-learn-details {
  display: none;
}
.parent-page .list_document_items_area ul li {
  cursor: pointer;
}
.parent-page .home-card-content.show-home.hide-learn-details {
  display: none;
}
.parent-page .show-all-view-details.show-slider {
  display: block;
}

/* End Home Page Toggleing */

/* ====================================================================================================================================================
    ===========================                            *** START NEW PROJECT PAGES CSS ***                          ================================
    ==================================================================================================================================================== */

/* Start New Project Header Css */
.parent-page .inbox-area-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.parent-page .inbox-right-content-text p {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  line-height: 33px;
  padding-right: 25px;
  padding-left: 8px;
}
.parent-page .inbox-left-content-image img {
  width: 16px;
  height: auto;
  margin-top: -8px;
}
/* End New Project Header Css */

/* Start Learning Detail XL Css */
.parent-page .home-text-content h4 span {
  border-bottom: 1px solid #ffffff;
  line-height: 15px;
  display: inline-block;
}
.parent-page .jannle-marshall {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 170px;
  position: relative;
}
.parent-page .down-icon-four-update img {
  width: 12px !important;
  height: 8px !important;
  right: 17px;
  right: -4px;
  bottom: 12px;
  position: absolute;
}
.parent-page .jannle-marshall-text p {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  line-height: 33px;
}
.parent-page .jannle-marshall-image img {
  width: 22px;
  height: auto;
  margin-right: 10px;
}
.parent-page .home-text-content h4 span {
  border-bottom: 1px solid #ffffff;
  line-height: 15px;
  display: inline-block;
}
.parent-page .ra_resources_area_bottom_text {
  text-align: center;
}
/* End Learning Detail XL Css */

/* Start Parent Page Css */
.parent-page .right_infor_area a img {
  margin-right: 5px;
}
.parent-page .right_infor_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
}
.parent-page .infor_heading_area {
  align-items: flex-start;
}
.parent-page .right_infor_area a {
  margin-top: 0;
}
.parent-page .main_student_information_area {
  padding-bottom: 25px;
}
.parent-page .student_pages_content_area {
 /* padding-bottom: 70px;*/
}
/* End Parent Page Css */

/* Start Discussion Page Css */
.parent-page .mu_dis_right_header_rtr {
  margin-right: 5px;
}
/* End Discussion Page Css */

/* Start Course Progress Page Css */
.parent-page .pt_view_main_area.button {
  margin-bottom: 0;
}
.parent-page .mu_dropdown_menu_content.dropdown {
  flex: 0 0 75%;
}
.parent-page .action-button-arrow i {
  font-size: 15px;
  right: 8px;
  bottom: 9px;
  position: absolute;
}
.parent-page .ra_sortable_certificate_sub_item2 {
  text-align: center;
}
.parent-page .ra_sortable_certificate_sub_item2 p:nth-child(2) {
  font-size: 18px;
}
.parent-page .ra_sortable_area_btn_area
  .myclassroom-single-right-right-content
  .dropdown
  button {
  padding: 6px 24px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 300;
  margin-left: 14px;
}
.parent-page .ra_process_tracking_area_header h3 {
  font-weight: 300;
  font-size: 20px;
}
.parent-page .ra_process_tracking_area.changed {
  padding-top: 0;
  padding-bottom: 0;
}
.parent-page .pt_left_view h3 {
  font-size: 14px;
}
.parent-page .ra_process_tracking_area_header_text .dropdown button {
  background: transparent;
}
.parent-page .dropdown-menu.new-menu.new-class.show {
  padding: 10px 0 !important;
}
/* End Course Progress Page Css */

/* Start Sheduel Page Css */
.parent-page .ra_event_single_slider_area_top h5 {
  font-weight: 300;
}
.parent-page .ra_event_single_slider_area_bottom p {
  font-weight: 300;
  padding-bottom: 5px;
}
.parent-page .ra_event_single_slider_area_bottom h1 {
  width: 135px;
}
.parent-page .ra_event_single_slider_area {
  padding: 5px 8px 5px 20px;
}
.parent-page .ra_event_single_slider_area.change_slider {
  align-items: center;
  height: 130px;
}
.parent-page .ra_event_main_area_slider.changed {
  padding: 0 35px 0 30px;
}
.parent-page .ra_event_main_area.head h2 {
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 8px;
}
.parent-page .ra_teacher_list_area_header.new-class {
  background: #dbf0f0;
}
.parent-page .ra_teacher_list_area_body.add-new-class {
  background: #e8f4ff;
}
.parent-page .student-contents {
  display: flex;
  align-items: center;
}
.parent-page .student-contents img {
  height: 38px;
  width: auto;
  margin-right: 10px;
}
.parent-page .ra_schedule_calender_area .ra_monday-first-card_ex_s,
.parent-page .upcoming-area .monday-first-card {
  background-color: #e8f4ff;
}
.parent-page .upcoming-area .monday-first-card.shedule-calendar {
  margin-top: -45px;
}
.parent-page .add-table-new-content {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.parent-page .add-table-new-content img {
  width: 25px;
  height: auto;
  margin-right: 5px;
}
.parent-page .ra_schedule_calender_area.new_update_class_schedule.upcoming-area tbody td {
  height: 95px;
}
.parent-page .new-border-class {
  border-left: 3px solid #145b94 !important;
}
.parent-page .view-students-new-text p {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  line-height: 33px;
  margin-left: 10px;
}
.view-students-new-image img {
  width: 22px;
  height: auto;
}
.parent-page #newdropdownbtntwo {
  background: transparent;
}
.parent-page .ra_event_right_btn.new-class-update {
  justify-content: space-between;
  align-items: center;
}
.parent-page .view-students-new-content {
  display: flex;
  align-items: center;
  width: 214px;
  position: relative;
}
.parent-page .view-students-new-text p span {
  background: #145b94;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 1px 7px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}
.parent-page .custom_checkbox_area .single-checkbox-area {
  padding: 10px 15px;
  cursor: pointer;
}
.parent-page .custom_checkbox_area .single-checkbox-area:hover {
  background: #dbe6ee;
}
.parent-page .custom_checkbox_area.new-class-add {
  padding: 0;
  width: 240px;
  padding: 10px 0;
}
.parent-page .single-checkbox-area label span {
  cursor: pointer;
}
/* End Sheduel Page Css */

/* Start Progress List Page Css */
.parent-page .dc_new_button_add button {
  font-weight: 400;
  font-size: 11px;
  color: #3f4254;
  text-transform: capitalize;
  background: #e4e6ef;
  padding: 1px 8px;
  border-radius: 4px;
}
.parent-page .dc_new_button_add button:last-child {
  background: #dbd3f2;
}
.parent-page .dc_new_button_add {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 14px;
}
.parent-page .dc_new_button_add button {
  font-weight: 400;
  font-size: 11px;
  color: #3f4254;
  text-transform: capitalize;
  background: #e4e6ef;
  padding: 1px 8px;
  border-radius: 4px;
  margin-right: 4px;
}
.parent-page .dc_middle_txt.new-class-add {
  margin-left: 5px;
}
.parent-page .dc_middle_txt a {
  position: inherit;
  border-radius: 4px;
  padding: 1px 9px;
}
.parent-page .dc_middle_txt p {
  padding-left: 0;
}
.parent-page .mu_dropdown_menu_content ul li a {
  margin: 0 !important;
}
.parent-page .resources-paragraf p {
  font-weight: 500;
  font-size: 14px;
  color: #05233b;
  position: relative;
  top: 30px;
}
/* End Progress List Page Css */

/* Start Learning Path Content Css */
.parent-page .new-project-porgress-traking {
  position: relative;
}
.parent-page .new-project-porgress-traking .dropdown-menu {
  margin: 0.125rem 0 0;
}
.parent-page .new-project-porgress-traking .dropdown-menu.course {
  margin: 2.125rem 0 0;
}
.parent-page .learning-path-popup-area {
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: none;
}
.parent-page .ju_progress_tracking_main {
  padding-bottom: 100px;
  margin-bottom: 0;
}
.parent-page .learning-path-popup-area {
  background: #ffffff;
}
.parent-page .learning-close-button {
  padding-top: 20px;
  padding-bottom: 70px;
}
.parent-page .learning-path-popup-content {
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
}
.parent-page .learning-path-popup-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #e8e6f2;
  padding: 8px 25px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.parent-page .learning-path-left-heading {
  flex: 0 0 50%;
}
.parent-page .learning-path-right-heading {
  flex: 0 0 50%;
}
.parent-page .learning-path-single-content {
  background: #f3f6f9;
  padding: 25px 20px;
}
.parent-page .learning-path-left-img {
  display: flex;
  align-items: center;
}
.parent-page .learning-path-single-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent-page .learning-path-single-content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.parent-page .learning-path-single-left {
  flex: 0 0 50%;
}
.parent-page .learning-path-single-right {
  flex: 0 0 50%;
}
.parent-page .learning-path-left-img img {
  width: 42px;
  height: auto;
  margin-right: 20px;
  border-radius: 4px;
}
.parent-page .learning-path-popup-head p {
  font-weight: 300;
  font-size: 12px;
  color: #3f4254;
  text-transform: capitalize;
}
.parent-page .learning-path-left-img p {
  font-weight: 300;
  font-size: 17px;
  color: #181c32;
  text-transform: capitalize;
}
.parent-page .learnging-path-single-right-left-content p {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .learnging-path-single-right-right-content a {
  font-weight: 400;
  font-size: 12px;
  color: #0078bf;
  text-transform: capitalize;
  border: 1px solid #0078bf;
  padding: 4px 17px;
  border-radius: 20px;
  transition: 0.3s;
}
.parent-page .learnging-path-single-right-right-content a:hover {
  background: #0078bf;
  color: #ffffff;
}
/* End Learning Path Content Css */

/* Start Students Information Area Css */
.parent-page .students-request-area {
  padding-bottom: 90px;
}
.parent-page .student-information-heading {
  background: #d8e4ef;
  border-radius: 5px;
  padding: 15px 20px 60px 15px;
}
.parent-page .student-information-text {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
.parent-page .student-information-heading-profile {
  position: relative;
  width: 60px;
  height: 60px;
}
.parent-page .student-information-heading-profile img {
  width: 60px;
  height: auto;
}
.parent-page .student-information-edit img {
  width: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.parent-page .student-information-heading-right a {
  font-weight: 300;
  font-size: 12px;
  color: #0078bf;
  text-transform: inherit;
}
.parent-page .student-information-heading-right a img {
  width: 14px;
  height: auto;
  margin-right: 5px;
  margin-top: -10px;
}
.parent-page .student-information-left-heading-image {
  display: flex;
  justify-content: space-between;
}
.parent-page .student-information-heading {
  background: #d8e4ef;
  border-radius: 5px;
  padding: 15px 30px 60px 15px;
}
.parent-page .student-information-single-text-heading h2 {
  font-weight: 400;
  font-size: 18px;
  color: #3f4254;
  padding-bottom: 20px;
}
.parent-page .student-information-single-text-heading h3 {
  font-weight: 400;
  font-size: 14px;
  color: #3f4254;
  padding-bottom: 15px;
}
.parent-page .student-information-single-info p {
  font-weight: 400;
  font-size: 12px;
  color: #3f4254;
  text-transform: inherit;
  padding-bottom: 8px;
}
.parent-page .student-information-single-info p span a {
  font-weight: 400;
  font-size: 12px;
  color: #3f4254;
}
.parent-page .student-information-single-info p span {
  padding-left: 10px;
}
.parent-page .student-information-text .student-information-single-text:nth-child(2),
.parent-page .student-information-text .student-information-single-text:nth-child(3) {
  padding-top: 30px;
}
.parent-page .students-information-dropdown {
  padding: 25px 0;
}
.parent-page .students-information-dropdown button {
  background: transparent;
}
.parent-page .students-request-heading p {
  font-weight: 400;
  font-size: 15px;
  color: #3f4254;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 20px;
}
.parent-page .single-students-request-content .action_st_btn_img a.btn_style_2 {
  margin-top: 14px;
  margin-bottom: 20px;
}
.parent-page .single-students-request-content.last-child {
  margin-top: 30px;
  height: 220px;
}
.parent-page .single-students-request-content {
  height: 290px;
  background: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
}
.parent-page .parent-page .single-students-request-content{
  width: calc(50% - 15px);
}
.parent-page .single-students-request-content img {
  margin: 0;
}
.parent-page .btn_style_1.update {
  position: absolute;
  bottom: 20px;
}
.parent-page .single-students-request-content .st_single_action {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.parent-page .single-students-request-content h5 {
  padding: 0;
  text-align: center;
}
/* End Students Information Area Css */

/* Start Home Page Css */
.parent-page .homepage-alert .mu_alert_box,
.parent-page .custom_alert_design {
  background: #efd1bd;
}
.parent-page .inbox-messages-area.new {
  background: #dbf0f0;
}
.parent-page .inbox-messages-area.new .inbox-message-left-header a span {
  background: #007d81;
}

.parent-page .home-student-information-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 15px 0 20px;
}
.parent-page .home-student-info-left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.parent-page .home-student-information {
  background: #145b94;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 94px;
}
.parent-page .home-student-information.kenneth {
  background: #00a2a7;
}
.parent-page .home-student-info-right-content a {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;
  display: inline-block;
  border-bottom: 1px solid #ffffff;
  line-height: 11px;
}
.parent-page .home-student-info-head-text {
  padding-left: 15px;
}
.parent-page .home-student-info-head-img img {
  width: 90px;
  height: auto;
}
.parent-page .home-student-info-head-text h2 {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  text-transform: capitalize;
}
.parent-page .home-student-info-middle-text p {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  text-transform: inherit;
}
.parent-page .home-student-info-middle-text {
  display: flex;
  padding-top: 5px;
  padding-bottom: 8px;
}
.parent-page .home-student-info-middle-text p:nth-child(2) {
  margin-left: 28px;
}
.parent-page .home-student-info-head-text h4 {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;
}
.parent-page .home-student-info-right-content {
  margin-top: -8px;
}
.parent-page .home-student-all-information {
  background: #e8f4ff;
  padding: 15px 15px 0 15px;
  border: 2px solid #c7e6ff;
  border-top: none;
  border-bottom: none;
}
.parent-page .home-student-all-information.kenneth {
  background: #dbf0f0;
  border: 2px solid #b9e3e6;
  border-top: none;
  border-bottom: none;
}
.parent-page .active-progress {
  width: 100%;
}
.parent-page .single-stuinfo-progressbar .myclassroom-single-left-cross-progress {
  width: 345px;
  background: #ffffff;
}
.parent-page .single-stuinfo-progressbar .barra-nivel {
  background: #145b94;
}
.parent-page .single-stuinfo-progressbar.kenneth .barra-nivel {
  background: #00a2a7;
}
.parent-page .single-home-student-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0;
  border-bottom: 1px solid #c7e6ff;
}
.parent-page .single-home-student-info-left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 85%;
}
.parent-page .single-stuinfo-left-content img {
  width: 45px;
  height: auto;
}
.parent-page .single-stuinfo-top-right h2 {
  font-weight: 400;
  font-size: 14px;
  color: #181c32;
  text-transform: capitalize;
}
.parent-page .single-stuinfo-right-content p {
  font-weight: 400;
  font-size: 11px;
  color: #3f4254;
  text-transform: inherit;
}
.parent-page .single-stuinfo-top-right {
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
}
.parent-page .single-stuinfo-left-content {
  flex: 0 0 15%;
}
.parent-page .single-stuinfo-right-content {
  flex: 0 0 85%;
}
.parent-page .single-home-student-info-right-content img {
  width: 13px;
  height: auto;
}
.parent-page .single-stuinfo-middle-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
}
.parent-page .single-stuinfo-middle-right p {
  font-weight: 500;
  font-size: 12px;
  color: #3f4254;
}
.parent-page .single-stuinfo-middle-right p:nth-child(2) {
  padding-left: 20px;
}
.parent-page .learning-progress-button {
  text-align: center;
  padding: 15px 0;
  background: #e8f4ff;
  border: 2px solid #c7e6ff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.parent-page .learning-progress-button.kenneth {
  border: 2px solid #b9e3e6;
  background: #dbf0f0;
}
.parent-page .learning-progress-button a {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background: #0078bf;
  padding: 6px 40px;
  border-radius: 40px;
  text-align: center;
  border: 1px solid transparent;
  transition: 0.3s;
}
.parent-page .learning-progress-button a:hover {
  border: 1px solid #0078bf;
  color: #0078bf;
  background: transparent;
}
.parent-page .home-student-all-information .single-home-student-information:last-child {
  border-bottom: none;
}
.parent-page .last-accessed-paragraf p {
  font-weight: 500;
  font-size: 12px;
  color: #3f4254;
  text-align: center;
  padding-top: 12px;
}
.parent-page .single-home-progress-left-content-area {
  padding-bottom: 35px;
}
.parent-page .upcoming-top-button {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.parent-page .upcoming-top-button .upcomming-top-single-button:nth-child(2) button {
  margin-left: 15px;
}
.parent-page .upcomming-top-single-button button {
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  border: 1px solid #0078bf;
  padding: 5px 22px;
  border-radius: 50px;
  text-transform: capitalize;
  transition: 0.3s;
}
.parent-page .upcomming-top-single-button button:hover {
  background: #0078bf;
  color: #ffffff;
}
.parent-page .home-progress-area.newp {
  padding-bottom: 15px;
}
.parent-page .btn-group,
.parent-page .btn-group-vertical {
  align-items: center;
}
.parent-page .add-event-buttons a {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background: #0078bf;
  padding: 6px 40px;
  border-radius: 30px;
  border: 1px solid transparent;
  margin-left: 70px;
  transition: 0.3s;
}
.parent-page .add-event-buttons a:hover {
  background: transparent;
  border: 1px solid #0078bf;
  color: #0078bf;
}
.parent-page .upcoming-area.new-project {
  padding-bottom: 430px;
}
.parent-page .upcomming-view-classroom-content {
  display: flex;
  justify-content: flex-start;
}
.parent-page .upcomming-view-classroom-left-content {
  text-align: center;
  flex: 0 0 85%;
}
.parent-page .upcomming-view-classroom-right-content {
  flex: 0 0 15%;
}
.parent-page .upcomming-view-classroom-left-content a {
  font-weight: 400;
  font-size: 14px;
  color: #0078bf;
  text-transform: inherit;
  padding-top: 10px;
}

.parent-page .upcoming-area .monday-second-card.add-new {
  width: 17%;
  bottom: inherit;
  top: 40px;
}
.parent-page .upcoming-area .tudesday-card.add-new {
  bottom: 165px;
}
.parent-page .friday-card.add-new {
  top: inherit;
  bottom: 0;
}
.parent-page .table.bg-white.text-center.tables-design.new-class {
  margin-bottom: 0;
}
.parent-page .upcomming-view-classroom-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.parent-page .upcomming-view-classroom-right-content a img {
  width: 8px;
  height: auto;
  margin: 0 20px;
}
/* End Home Page Css */
.parent-page .filterCard h2{
  font-size: 18px;
  color: #05233B;
  font-weight: 300;
}
.login-logo img {
    height: 60px;
    width: auto;
}
.parent-page .header-profile-dropdown.header-logo-dropdown .dropdown-menu.adjust{
	    transform: translate(0px, 30px)!important;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1rem 0.75rem!important;
}
.parent-page .pd0 {
	padding:0px!important;
}
.pro-dropdow .new-class{
	transform: translate(0px, 33px)!important;
	width: auto;
    display: grid;
}
.parent-page #prgoress .mu_search_bar_reply .mu_dropdown {
    bottom: -125px!important;
}
.parent-page .mu_search_bar button img {
    margin: 7px 0 0 40px;
}
.collapse:not(.show) {
    display: none;
}
.parent-page .rt_progress_txt h3 {
    font-weight: 500;
    color: #fff;
    text-align: center;
	font-size: 1.75rem;
}
.parent-page .home-student-info-head-img img {
    width: 90px;
    height: 90px;
    /* height: auto; */
    border: 2px solid #f3f6f9;
}
.parent-page .single-stuinfo-left-content img {
    width: 45px;
    height: 45px;
    border-radius: 5px;
}
.single-stuinfo-left-content .sb-avatar__text{
	border-radius:5px;
}
.home-student-info-head-img .sb-avatar__text{
	border: 2px solid #f3f6f9;
}
.csro-pr{
	max-height: 434px!important;
	margin-top: 0px!important;
}
.parent-page .header-logo-dropdown .dropdown-menu{
	transform: translate(0px, 60px)!important;
}
.home-student-info-head-img{
	z-index:1;
}
.custom_checkbox_area input[type="checkbox"]:checked, .recommendation_checkbox input[type="checkbox"]:checked {
    border: 2px solid #3f51b5;
    background: #3f51b5;
}
.custom_checkbox_area input[type="checkbox"] {
    height: 18px;
    width: 18px;
    
}
.parent-page .ra_event_main_area_slider .owl-theme .owl-nav .owl-next img {
    display: block;
}
.ra_teacher_list_area_header_single_item_body .sb-avatar{
	margin-right:10px;
}
 #calendar .re_body_list_item4 {
    -webkit-flex: 0 0 10%;
    flex: 0 0 20%;
}
.ju_single_table_content_design>ul>li, .ui_single ul li {
    height: 90px!important;
}
.ra_process_tracking_area .jannle-marshall{
	width:auto;
}
.ra_process_tracking_area .down-icon-four-update{
	margin-right:40px;
}
.parent-page .ra_process_tracking_area_header_search {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
}
.jannle-marshall-image .sb-avatar{
	margin-right:10px;
}
.ra_teacher_list_area_header_single_item_body.re_body_list_item1{
	cursor:pointer;
}
.parent-page #resources .list_prog_heading {
    margin-top: 25px; 
}
.parent-page .dropdown-item.width-two {
    width: 223px;
    margin: 4px 0;
	
}
.single-chating-left-image .sb-avatar{
	margin-right:10px;
}
.stundents-information-area .down-icon-four-update{
	margin-right:40px;
}
.parent-page .stundents-information-area .jannle-marshall{
	width:auto;
}
.parent-page .stundents-information-area .new-class.dropdown-menu {
	transform: translate(0px, 37px);
	width:230px;
}
.parent-page .stundents-information-area .students-information-dropdown {
    padding-bottom: 25px;
	padding-top:0;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.parent-page .stundents-information-area .single-students-request-content img {
    margin-bottom: 26px;
}
.parent-page .students-request-area .single-students-request-content {
  height: 100%!important;
  background: #ffffff;
  padding: 0!important;
  margin-bottom: 0!important;
}
.parent-page .students-request-area .st_single_action h5 {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    padding-top: 15px;
    padding-left: 20px;
	text-align:left;
}
.parent-page .students-request-area .action_st_btn_img img {
    max-width: 58px;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    margin-top: 14px;
    margin-bottom: 26px;
}
.parent-page .students-request-area .single-students-request-content .st_single_action {
	display:block;
}
.parent-page .students-request-area .single-students-request-content.last-child {
    margin-top: 30px;
    height: 220px!important;
}
.parent-page .stundents-information-area  .student_info{
	width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 2px solid #755db4;
}
.parent-page .stundents-information-area .student-information-heading-profile .sb-avatar__text{
	border: 2px solid #755db4;
}
.student-info .request-transcript-area,.parent-page .resources-area {
    width: 100%;
    height: 96%;
    top: 4%;
}
.student-info .students-request-content .btn_style_1.update {
    position: inherit;
}
.student-info .students-request-area .action_st_btn_img img{
	height:58px;
}


.parent-info .request-transcript-area {
    width: 100%;
    height: 94%;
    top: 6%;
}
.parent-page .request-suport-ticket-area {
    width: 100%;
    height: 95%;
    top: 5%;
}
.parent-page .request-suport-ticke-main-content {
    max-width: 515px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
}
.parent-page #resources .right_dc_img a:nth-child(1) {
    margin-bottom: 0px;
}
.parent-page .request-edit-student-main-content {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
	background: #eae8f3;
    border-radius: 5px;
	padding: 20px;
}
#form-edit-student .student-information-heading-profile .sb-avatar__text{
	border:2px solid #fff;
}
.parent-page .student-information-heading-profile .avartar_st{
	width: 100px!important;
    height: 100px!important;
    border-radius: 100%;
    border: 2px solid #fff;
}
.parent-page #form-edit-student .student-information-heading-profile {
    position: relative;
    width: auto;
    height: auto;
}
.parent-page #form-edit-student .student-information-left-heading-image{
	display:block;
}
.parent-page #form-edit-student .student-information-edit img {
    width: 20px;
    position: absolute;
    bottom: 37px;
    left: 80px;
}
.parent-page .request-edit-student-area {
    width: 100%;
    height: 95%;
    top: 5%;
}
.parent-page .change-password-content{
	max-width:100%;
	    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-left: 0;
    padding-top: 20px;

}
.parent-page #prgoress .mu_search_bar_reply .mu_dropdown {
    bottom: -162px!important;
}
.parent-page .dropdown-menu.adjust{
	left:0px!important;
}
.parent-page .home-student-info-middle-text p:nth-child(3) {
    margin-left: 28px;
}
.parent-page .home-student-info-head-text h4{
	width: 260px;
}
.parent-page .help-contetn img {
    width: 80px;
    height: auto;
    margin-top: 0px;
}
.parent-page .home-student-info-right-content a{
	border:0px;
	color: #80eef9;
}
.progess_muti {
    width: 64px!important;
}
.parent-page .mu_dis_con_single_img span {
    font-size: 9px;
    color: #fff;
    background: #707070;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
}
.parent-page .scrollere#list-st{
	margin-top:0px;
	max-height:300px!important;
}
.source-de{
	overflow: auto!important;
    height: 80px!important;
}
.source-de::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.source-de::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.source-de::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
.txt12{
	font-size:12px;
	font-size:11px;
}
.txt11{
	font-size:11px;
}
#calendar .in-class-sec .re_body_list_item2, #calendar .in-class-sec .re_header_list_item2 {
    -webkit-flex: 0 0 20%!important;
    flex: 0 0 20%;
	padding-right:10px;
}
#calendar  .in-class-sec .re_body_list_item3,#calendar  .in-class-sec .re_header_list_item3 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%!important;
	padding-right:10px;
}
#calendar  .in-class-sec .re_body_list_item4,#calendar  .in-class-sec .re_header_list_item4 {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%!important;
}
#calendar  .ra_windows_main_area {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
	margin:10px 0;
}
.left_infor_area .infor_profile img {   
    width: 60px;
    height: 60px;
}
.parent-page .left_infor_area .infor_profile img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}
.parent-page .student_pages_content_area {
    padding-bottom: 900px;
    position: relative;
}
.parent-page .request-edit-student-area {
    width: 100%;
    height: 96%;
    top: 4%;
}
.parent-page .student-info .request-edit-student-area {
    width: 100%;
    height: 97%;
    top: 3%;
}
.parent-page .request-suport-ticket-area {
    width: 100%;
    height: 97%;
    top: 3%;
}
.student-info .request-transcript-area, .parent-page .resources-area {
    width: 100%;
    height: 97%;
    top: 3%;
}
#resources .right_dc_img a span {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Avenir';
}
.parent-page .div-cm-teext {
    font-weight: 500;
    font-size: 13px;
    color: #3f4254;
}
.ct-cme{
	padding:5px 15px;
}
.navi-text {
    color: #3F4254;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.parent-page .mu_people_list_margin {
    margin: 0 0px 0 12px;
}
.log-i-role{
	padding-top: 0!important;
    padding-bottom: 0!important;
    color: red!important;
}
.parent-page #calendar .ra_teacher_list_area_header_single_item_body.re_body_list_item2 img{
	width:40px!important;
	height:40px!important;
}
.parent-page .dropdown_search_flex.ar-gradebook-filter-wrapper {
       padding: 0 20px;
    border-left: 1px solid #e4e6ef;
    border-top: 1px solid #e4e6ef;
    border-right: 1px solid #e4e6ef;
}
.parent-page  .ar-reset-funtion-area a {
    position: relative;
    color: #0078bf!important;
    font-size: 14px;
    font-weight: 300;
	font-size: 13px;
}
.parent-page  .ar-reset-funtion-area a img{
	margin-right:5px;
}
/* ====================================================================================================================================================
    ===========================                             *** END NEW PROJECT PAGES CSS ***                           ================================
    ==================================================================================================================================================== */
.parent-page  .ar-filter-all-wrapper {
    background: #e4e6ef;
}
.parent-page  .ar-filter-all-wrapper {
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    margin-top: 1px;
}
.parent-page .ar-single-filter-check {
    padding-right: 10px;
}
.parent-page .ar-search-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.parent-page .ar-single-search.search1.new-add {
    -webkit-flex: 0 0 56%;
    flex: 0 0 56%;
    flex: 0 0 73%;
    margin-right: 10px;
}
.parent-page .ar-single-search.search3.new-add {
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
	 margin-right: 10px;
	 margin-right: 20px;
}
.parent-page .ar-search_box_area input[type=search] {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 7px 0 7px 10px;
    outline: none;
    color: #505355;
    font-size: 12px;
    border-radius: 3px;
}
.parent-page .ar-search_box_area.ar-input1.new-add input[type=search] {
	max-width:300px;
    width: 100%;
}
.parent-page .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
	    width: 100px;
	    width: 120px;
		font-size: 12px;
}
.parent-page #student-content .dc_single_items{
	padding:17px 10px;
}
.parent-page #student-content .profile-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    margin-left: 0;
}
.parent-page #student-content  .student-listing-single-content {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
}
.parent-page #student-content  .ar-search_box_area.ar-input3.new-add input[type=search] {
    max-width:200px;
    width: 100%;
}
.parent-page #student-content  .ra_sortable_header_area_menu > ul li ul li img {
    margin-left: 5px;
    cursor: pointer;
    width: 9px;
}
.parent-page #student-content .profile-right-section-content span {
    font-weight: 400;
    font-size: 11px;
    color: #3f4254;
    text-transform: capitalize;
    line-height: normal;
	    display: block;
}
.parent-page #student-content .student-grade-lavel-single-content {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    flex: 0 0 23%;
    text-align: left;
    padding-left: 15px;
}
.parent-page #student-content .ar-quality-of-work p {
    color: #3f4254;
    font-size: 12px;
    font-weight: 300;
    font-family: "Avenir";
	text-align:center;
}
.parent-page #student-content .ra_sortable_header_area_menu ul li {
    color: #3f4254;
    font-size: 12px;
    font-family: Source Sans Pro;
    font-weight: 300;
    padding: 2px;
    margin-left: 8px;
}
.parent-page #student-content .student-status-text-contents {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    flex: 0 0 18%;
    text-align: center;
}
.parent-page #student-content .pagination>.active>a,.parent-page #student-content .pagination>.active>a:focus,.parent-page #student-content .pagination>.active>a:hover, .parent-page #student-content .pagination>.active>span, .parent-page #student-content .pagination>.active>span:focus, .parent-page #student-content .pagination>.active>span:hover {
    z-index: 3;
    cursor: default;
    background-color: #efe;
    border-color: #addead;
    background: #0078bf;
    color: #fff!important;
    padding: 5px 12px;
    border-radius: 4px;
}
.parent-page #student-content .right_pg_area ul li {
    margin: 0 12px;
}
.parent-page #student-content .mu_header_next_btn .pagination li {
    margin-left: 2px!important;
    margin-right: 2px!important;
}
.parent-page #student-content  .srolle_student {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 250px!important;
    max-height: 425px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.parent-page #student-content .mu_icon_header_checkbox {
  margin-right: 15px;
}


.parent-page #student-content>li>a, .parent-page #student-content>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 12px;
}
.parent-page #student-content .ar-search-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.parent-page #student-content .pagination>li>a, .parent-page #student-content .pagination>li>span{
	font-size:12px!important;
}
.curpoi{
	cursor:pointer;
}
.parent-page .student_pages_content_area .dropdown-menu.new-menu.new-class.scrollere.dropdown-menu{
	    transform: translate(0px, 41px)!important;
}
.parent-page #student-content .dc_dots_area .dropdown-menu{
	display:table;
}
.parent-page #student-content2 .ar-single-search.search1.new-add {
    -webkit-flex: 0 0 56%;
    flex: 0 0 56%;
    -webkit-flex: 0 0 73%;
    flex: 0 0 45%;
    margin-right: 10px;
}
.parent-page #student-content2 #student-content .mu_icon_header_checkbox {
    margin-right: 30px;
}
.parent-page #student-content2 #student-content .student-listing-single-content {
    -webkit-flex: 0 0 50%;
    flex: 0 0 40%;
}
.parent-page  #student-content2 #student-content .student-status-text-contents {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    -webkit-flex: 0 0 18%;
    flex: 0 0 22%;
    text-align: center;
}
.parent-page #student-content2 .select-status {
    background: #fff;
    border: 1px solid #a6adb1;
    padding: 8px 0 7px 3px;
    outline: none;
    color: #505355;
    font-size: 14px;
    border-radius: 3px;
    width: 100px;
    width: 140px;
    font-size: 12px;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 0;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}
.parent-page #student-content .btn-card-view{
	font-size: 10px;
    border-radius: 15px;
    padding: 3px 12px;
    background: #00c1e6;
    color: #fdfffe;
    font-weight: 600;
}
.parent-page #student-content2 #student-content .student-grade-lavel-single-content {
   
    flex: 0 0 30%;
    text-align: left;
    padding-left: 15px;
}
.parent-page #student-content2 #student-content .ar-quality-of-work{
	display: flex;
    justify-content: space-evenly;
}
.parent-page #student-content2 #student-content .profile-section {
	margin-left:14px;
}
.parent-page .mu_icon_header_icons ul li a{
	margin:0 15px;
}
.header-logo-dropdown .dropdown-item.widths{
	width: auto;
    margin: 8px;
	padding-left:5px;
	display:block;
}
.header-logo-dropdown .dropdown-item.widths div{
	display: flex;
    align-items: center;
}
.dropdown-item.widths .navi-text-school{
	font-size:13px;
}
.header-logo-dropdown .dropdown-menu.show{
	padding:0px 10px!important;
}
.header-profile-dropdown.header-logo-dropdown .dropdown-menu.show {
     padding: 0px!important; 
}
.parent-page #prgoress .ra_sortable_certificate_main2 {
    flex: 0 0 30%;
}
.parent-page #prgoress .ra_sortable_right_btn_area{
	flex: 0 0 16%;
}
#prgoress .ra_sortable_right_btn_area_sub_item_area {
    margin-left: 40px;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item1{
	flex:0 0 25%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item2{
	flex:0 0 40%;
}
.parent-page #calendar .ra_event_single_slider_area_bottom h1 {
    width: auto;
}
.parent-page #prgoress .but_left_view{
	display: flex;
    flex-direction: row;
    align-items: center;
}
.parent-page .pt_view_main_area.option {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.parent-page .today-week-button a, .parent-page .today-week-button a.btn_style_4 {
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078bf;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

.parent-page .today-week-button a.btn_style_4 {
    color: #fff;
    background: #0078bf;
}
.parent-page .today-week-button a {
    text-align: center!important;
    color: #0078bf;
}
.parent-page .today-week-button a {
    width: 80px;
    height: 35px;
    border-radius: 28px;
    display: block;
    align-items: center;
    text-transform: capitalize;
    margin-right: 15px;
    padding-left: 17px;
    transition: .3s;
}
.parent-page .today-week-button {
    display: flex;
    align-items: center;
}
.parent-page .link-detail{
	display: initial;text-decoration: underline;
}
.parent-page a.link-detail{
	color: blue!important;
}

.parent-page .single-home-progress-left-content-area:last-child{
	padding-bottom:0;
}
.parent-page #message .seach-dis .dropdown-menu.dropdown-menu {
    transform: translate(0px, 28px)!important;
    width: 280px;
}
.parent-page #message .seach-dis .dropdown-menu.dropdown-menu .custom_checkbox_area label span{
	width:110px!important;
}
.parent-page #message .text-editor {
  width: 100%;
  margin: 0 auto;
}
.parent-page #message .ql-editor {
  min-height: 300px;
}
.parent-page #message .label-inline.mr-2{
	color:blue;
	font-size:13px;
}
.parent-page .home-progress-content .single-stuinfo-middle-right p {
    font-weight: 500;
    font-size: 10px;
    color: #3f4254;
}
.parent-page .home-progress-content .course-progress-left {
    padding-top: 0px;
}
.parent-page #prgoress .ra_sort_left_area_body{
	flex: 0 0 22%;
}
.parent-page #prgoress .ra_sortable_certificate_main{
	flex: 0 0 18%;
}
.parent-page #prgoress .ra_sortable_certificate_main2 {
    flex: 0 0 46%;
}
.parent-page #prgoress .ra_sortable_body_main_area .contact-button a {
    padding: 4px 10px;
    font-size: 11px;
}
.parent-page #prgoress .ra_sortable_right_btn_area1{
	flex: 0 0 8%;
	margin-left:5px;
}
.parent-page #prgoress .class_area_sort_flex{
	padding: 18px 8px 22px;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item1{
	flex: 0 0 15%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item2{
	flex: 0 0 18%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item3{
	flex: 0 0 15%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item4{
	flex: 0 0 20%;
    text-align: center;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item5{
	flex: 0 0 20%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item6{
	flex: 0 0 14%;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item5 p{
	font-size:13px;
}
.parent-page #prgoress .ra_sortable_certificate_sub_item6 p{
	font-size:13px;
}
.parent-page #prgoress .ra_sortable_certificate_main2_sub_item_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
.parent-page #prgoress .ra_sortable_header_area_menu ul li{
	margin-left:3px;
}
.fosi{
	font-size:12px;
	margin-right:15px!important;
}
.dsflex{
	display:flex;
}
.parent-page .dropdown-item.widths.active {
    background-color: #DBE6EE;
}
.parent-page .header-top-content .dropdown-item.width-two {
    width: 223px;
    width: auto;
	display:block;
}
.parent-page .single-stuinfo-progressbar .barra-nivel-weekly {
    background: #00a2a7; margin-top:5px;
}
.parent-page #prgoress .myclassroom-single-left-content-image {
    position: relative;
    width: 65px;
}
.parent-page #prgoress .myclassroom-single-right-content-text {
    width: 190px;
}
.parent-page #prgoress .myclassroom-single-left-content-course-progress p {
    font-weight: 400;
    font-size: 9px;
    color: #707070;
    text-transform: inherit;
    margin-bottom: 2px;
}
.parent-page .ra_process_tracking_area_header_search_width.tdps {
    max-width: 161px;
    width: 100%;
}
.custom_checkbox_area input[type="checkbox"] {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    opacity: 1;
    border: 2px solid #3f51b5;
    border-radius: 3px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
}
.header-bottom-content ul.arabic {
    display: flex !important;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.header-top-right-content.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.header-top-left-content.arabic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.header-top-content.arabic{
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
}
.header-top-content .dropdown-item.width-two.arabic {
    width: 223px;
    width: auto;
    display: block;
    text-align: right;
}
.parent-page .arabic .help-contetn {
    padding-left: 0px;
    padding-right: 30px;
}
.parent-page .home-text-content.arabic{
	text-align:right;
}
.parent-page .row.arabic {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: row-reverse;
}
.parent-page .inbox-message-header.arabic  {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.parent-page .field-icon-new {
    float: right;
    position: relative;
    z-index: 2;
    top: -30px;
    right: 10px;
    cursor: pointer;
}
.parent-page .inbox-message-left-header.arabic{
	text-align:right;
}
.parent-page .single-inbox-messages-content p.arabic{
	text-align:right;
}
.parent-page .single-inbox-date-button.arabic{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}
.parent-page .home-student-information-heading.arabic {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 15px 0 20px;
    flex-direction: row-reverse;
}
.parent-page .home-student-info-left-content.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.parent-page .home-student-info-head-text.arabic {
    padding-left: 0;
    text-align: right;
    padding-right: 15px;
}
.parent-page .home-student-info-middle-text.arabic {
    display: flex;
    padding-top: 5px;
    padding-bottom: 8px;
    flex-direction: row-reverse;
}
.parent-page .home-student-info-middle-text.arabic p:nth-child(2) {
    margin-left: 0;
    margin-right: 28px;
}
.parent-page .home-student-info-middle-text.arabic p:nth-child(3) {
    margin-left: 0;
    margin-right: 28px;
	display: flex;
    flex-direction: row-reverse;
}
.parent-page .single-home-student-information.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 0;
    border-bottom: 1px solid #c7e6ff;
    flex-direction: row-reverse;
}
.parent-page .single-home-student-info-right-content img.arabic {
    width: 13px;
    height: auto;
    transform: rotate(180deg);
}
.parent-page .single-home-student-info-left-content.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 0 85%;
    flex-direction: row-reverse;
}
.parent-page .single-stuinfo-left-content.arabic {
    flex: 0 0 15%;
    display: flex;
    justify-content: flex-end;
}
.parent-page .single-stuinfo-top-right.arabic {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    flex-direction: row-reverse;
}
.parent-page .single-stuinfo-middle-right.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2px;
    flex-direction: row-reverse;
}
.parent-page .myclassroom-single-left-content-course-progress.arabic {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row-reverse;
}
.parent-page .single-stuinfo-middle-right.arabic p:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
}
.parent-page .dropdown_search_flex.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.parent-page .mu_dropdown_menu_content ul.arabic {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.parent-page .ar-filter-all-wrapper.arabic {
    display: flex;
    padding: 10px;
    margin-top: 1px;
    flex-direction: row-reverse;
}
.parent-page #student-content .ar-search-wrapper.arabic {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.parent-page .arabic .ar-single-search.search1.new-add {
    flex: 0 0 56%;
    flex: 0 0 73%;
    margin-right: 0px;
    margin-left: 10px;
}
.parent-page .arabic .ar-single-search.search3.new-add {
    flex: 0 0 25%;
    margin-right: 1;
    margin-left: 20px;
    margin-right: 0;
}
.parent-page .ra_sortable_header_area_menu > ul.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page #student-content .ra_sortable_header_area_menu ul.arabic li {
    color: #3f4254;
    font-size: 12px;
    font-family: Source Sans Pro;
    font-weight: 300;
    padding: 2px;
    margin-left: 0;
    margin-right: 8px;
    display: flex;
    flex-direction: row-reverse;
}
.parent-page .arabic .ar-search_box_area.new-add{
	display: flex;
    flex-direction: row-reverse;
}
.parent-page .arabic .ar-search_box_area.new-add input[type=search]{
	text-align: right;
}
.parent-page .dc_single_items.arabic {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    padding: 17px 16px 25px;
    border: 1px solid #f7f7f7;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    flex-direction: row-reverse;
}
.parent-page #student-content2 #student-content .profile-section.arabic {
    margin-left: 0;
    margin-right: 14px;
}

.parent-page #student-content .profile-section.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    flex-direction: row-reverse;
}
.parent-page .arabic .profile-right-section-content {
    margin-top: 8px;
    margin-left: 0;
    margin-right: 10px;
	text-align: right;
}
.parent-page #student-content2 #student-content .ar-quality-of-work.arabic {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row-reverse;
}
.parent-page .arabic .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -35px;
    top: -7px;
    position: absolute;
    left: -35px;
}
.parent-page .document_pagenations_area.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 30px;
    flex-direction: row-reverse;
}
.parent-page .arabic .left_pg_area a {
    color: #3f4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
    margin-left: 20px;
    margin-right: 0;
}
.parent-page .view-students-new-content.arabic {
    display: flex;
    align-items: center;
    width: 214px;
    position: relative;
    flex-direction: row-reverse;
}
.parent-page .ra_event_right_btn.arabic {
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
    flex-direction: row-reverse;
}
.parent-page .down-icon-four-update img.arabic {
    width: 12px !important;
    height: 8px !important;
    right: 17px;
    left: -4px;
    bottom: 12px;
    position: absolute;
}
.parent-page .arabic .view-students-new-text p {
    font-weight: 400;
    font-size: 14px;
    color: #3f4254;
    line-height: 33px;
    margin-left: 0px;
    margin-right: 10px;
}
.parent-page .arabic .view-students-new-text p span{
	margin-left:0;
	margin-right:5px;
}
.ra_upcoming_heading.arabic{
	text-align:right;
}
.parent-page .ra_teacher_list_area_header.arabic {
    display: flex;
    align-items: center;
    background: #e4e6ef;
    padding: 13px 30px 13px 0px;
    border: 1px solid #f7f7f7;
    flex-direction: row-reverse;
}
 .parent-page .arabic .re_header_list_item1 {
    flex: 0 0 35%;
    display: flex;
    justify-content: flex-end;
}
 .parent-page .arabic .re_header_list_item2 {
    flex: 0 0 20%;
    display: flex;
    justify-content: flex-end;
}
.parent-page .arabic .re_header_list_item3 {
    display: flex;
    justify-content: flex-end;
}
.parent-page .ra_teacher_list_area_body.arabic {
    display: flex;
    align-items: center;
    border-top: 5px solid #f3f6f9;
    padding: 22px 41px 22px 10px;
    border-radius: 7px;
    flex-direction: row-reverse;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1.arabic img {
    
    margin-right: 0;
    margin-left: 9px;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item1.arabic p{
	margin-right: 11px;
    margin-left: 0;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .ra_teacher_list_area_header_single_item_body.re_body_list_item2.arabic img{
	margin-right: 0;
    margin-left: 10px;
}
.parent-page .re_body_list_item3.arabic{
    flex: 0 0 25%;
    display: flex;
    justify-content: flex-end;
}
.parent-page .student-contents.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.ra_teacher_list_area_header_single_item_body .arabic .sb-avatar {
    margin-right: 0px;
    margin-left: 10px;
}
.ra_teacher_list_area_header_single_item_body .arabic .imtea {
    margin-right: 0px;
    margin-left: 10px;
}
.ra_upcoming_heading.in.arabic{
	display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.parent-page .mu_view_icons ul.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .mu_view_icons ul.arabic li:nth-child(1){
	    display: flex;
    flex-direction: row-reverse;
}
.parent-page .mu_view_icons ul.arabic li {
    margin-right: 0;
    margin-left: 20px;
}
.parent-page #in-sec-clas .ra_teacher_list_area_header.arabic {
    display: flex;
    align-items: center;
    padding: 13px 30px 13px 0px;
    border: 1px solid #f7f7f7;
    flex-direction: row-reverse;
}
.parent-page .ra_teacher_list_area_body.arabic {
    display: flex;
    align-items: center;
    border-top: 5px solid #f3f6f9;    
    padding: 22px 41px 22px 10px;
    border-radius: 7px;
    flex-direction: row-reverse;
}
.parent-page .ra_jackson_btn_area.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .re_header_list_item4.arabic {
    flex: 0 0 10%;
    display: flex;
    justify-content: flex-end;
}
.ra_teacher_list_area_header_single_item_body.arabic .sb-avatar {
    margin-right:0px;
    margin-left: 10px;
}
.parent-page #in-sec-clas .ra_teacher_list_area_header_single_item_body.re_body_list_item1.arabic p {
    margin-right: 11px;
    margin-left: 0;
    text-align: right;
}
#calendar .in-class-sec .re_body_list_item2.arabic{
	padding-right: 0px;
    padding-left: 10px;
}
.parent-page .single-top-progress-heading-text p.arabic{
	text-align:right;
}
.parent-page .rt_progress_bar_design.arabic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .arabic .rt_progress_left_side h5{
	text-align:right;
}
.parent-page .arabic .rt_progress_right_side{
	margin-left:0;
	margin-right:10px;
}
.parent-page .arabic .rt_progress_left_side p{
	text-align:right;
}
.parent-page .pt_view_main_area.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 45px;
    flex-direction: row-reverse;
}
.parent-page .arabic .pt_right_view {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .arabic .pt_right_view a {
    margin-left: 0;
    margin-right: 16px;
}
.parent-page .dropdown_search_flex.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.parent-page .arabic .mu_dropdown_menu_content ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .arabic .mu_dropdown_menu_content ul li{
	margin-left:20px;
	margin-right:0;
}
.parent-page .mu_messages.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #145b94;
    padding-bottom: 10px;
    flex-direction: row-reverse;
}
.parent-page .mu_li_flex.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .mu_accordion2 li .arabic i.fa-chevron-down{
	left: 25px;
    right: auto;
}
.parent-page .mu_accordion2 .link.arabic{
	text-align:right;
}
.parent-page .mu_chart.arabic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}
.parent-page .mu_messages_left.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .mu_messages_left.arabic img{
	margin-left:10px;
	margin-right:0;
}
.parent-page .arabic .mu_chart_right{
	margin-left:0;
	margin-right:12px;
}
.parent-page .arabic .mu_chart_left {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .arabic .mu_chart_left img{
	margin-right: 0px;
	margin-left: 10px;
}
h4.no_un_line span{
	border: 0!important;
}
.arabic .no_un_line{
	display: flex;
    flex-direction: row-reverse;
}
.parent-page .ra_courch_area_header h2.arabic{
	display: flex;
    flex-direction: row-reverse;
}
#printto h4.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.parent-page .single-top-progress-area.single-top-progress-area_ex.arabic {
    padding: 12px 16px 23px 1px !important;
}
.single-top-progress-area_ex.arabic .single-top-progress-heading-text p{
	text-align:right;
}
.parent-page .arabic .single-top-progress-last-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .ra_repetable_area .single-top-progress-heading-text p.arabic{
	text-align:right;
}
.parent-page .single-top-progress-last-text.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .single-top-progress-count-text.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 15px;
    flex-direction: row-reverse;
}
.parent-page .single-top-progress-count-text.arabic h5{
	margin-left:0;
	margin-right:8px;
}
.parent-page .myclassroom-header.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .myclassroom-header-left.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.parent-page .myclassroom-header-left.arabic span{
	margin-left:0;
	margin-right:15px;
}
.parent-page .ra_science_progress_area .slider-course-progress-left p.arabic{
	text-align:right;
}
.fosi.arabic{
	display: flex;
}
.parent-page .st_single_action h5.arabic{
	padding-left: 0px;
	padding-right: 20px;
	text-align:right;
}
.parent-page .arabic .student-information-single-info p {
    font-weight: 400;
    font-size: 12px;
    color: #3f4254;
    text-transform: inherit;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row-reverse;
}
.parent-page .student-information-single-text-heading h3.arabic{
	text-align:right;
}
.row-arabic{
	    display: flex;
    flex-direction: row-reverse;
}
.parent-page .infor_heading_area.arabic {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.parent-page .student-information-text.arabic {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.information_biodata h5.arabic{
	text-align:right;
}
.parent-page .student-information-single-info.arabic p {
    display: flex;
    flex-direction: row-reverse;
}
.parent-page .student-information-single-text-heading h3.arabic{
	text-align:right;
}
.parent-page .change-password-content.arabic {
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
    padding-top: 20px;
    flex-direction: row-reverse;
}
.change-password-area .arabic .change-password-right-content {
    margin-left: 0;
    margin-right: 15px;
}
.parent-page .arabic .change-password-left-content button{
	text-align:right;
}
.parent-page .arabic .change-password-right-heading p{
	text-align:right;
}
.parent-page .arabic .single-change-password-right-input label{
	text-align:right;
}
.arabic .rt_progress_left_side .barra-nivel {
    text-align: right;
    float: right;
}
.parent-page .single-stuinfo-middle-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px;
}
.ri-ct-pr p{
	text-align:right;
}
p.pdl{
	padding-left:0!important;
}
.parent-page .single-stuinfo-middle-right.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px;
    flex-direction: row-reverse;
}
.arabic .ri-ct-pr p {
    text-align: left!important;
    padding: 0!important;;
}
.header-logo-dropdown .dropdown.arabic {
    display: flex;
    justify-content: flex-end;
}
.header-logo-dropdown .arabic #dropdownMenuButton {
    display: inline-flex;
    flex-direction: row-reverse;
}
.arabic .down-icon img{
	left:0px; 
}
.arabic  .logo-sch .text-truncate {
       margin-right: 16px!important;
    margin-left: 34px!important;

}
#comunication-log .form-control2{
	padding-left:5px;
	padding-right:5px;
}
a.sendE:not([href]):not([tabindex]) {
    color: #0078bf;
   cursor:pointer;
}
a.sendE:not([href]):not([tabindex]):focus, a.sendE:not([href]):not([tabindex]):hover{
	 text-decoration: underline!important;
}
 #user-tags .btn-sec,#user-tags .btn-sec.btn_style_1:hover {
    width: 120px;
    font-weight: 300;
    color: #0078bf;
    font-size: 12px;
    background: transparent;
    border: 1px solid #0078bf;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: .3s ease-in-out;
	cursor:pointer;
}
 #user-tags .btn-sec.btn_style_1, #user-tags .btn-sec:hover {
    background: #0078bf;
    color: #fff;
}
.navi-status-scholl{
	padding-left: 5px;
    font-size: 9px;
}
.shoo-status-mes{
	font-size: 10px;
    color: red;
}
.mu_people_list_left {
    flex: 0 0 84%;
}
 .mu_people_left_left{
	flex: 0 0 33%;
}
 .mu_people_left_right{
	flex: 0 0 67%;
}
#send-message{
	display: block;
    font-size: 14px;
    border: 1px solid #0078BF;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;
	margin: 0px 12px 0px 0px;
	color:#fff;
}
#send-message:hover{
	color: #0069d9;
    background-color: #fff;
    border-color: #0062cc;
}
.parent-page .header-logo-dropdown .down-icon-two img{
	top:7px;
}
#studentInfo .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-weight: 300;
    margin-left: 14px;
}
#studentInfo .action-button-arrow i {
    font-size: 15px;
    right: 8px;
    bottom: 9px;
    position: absolute;
}
#dv-actionq{
	display: flex;
    justify-content: flex-end;
}
#studentInfo .student-information-heading-profile.name {
    position: relative;
    width: 70%;
    height: 60px;
	display: flex;
    align-items: first baseline;
}
#studentInfo .student-information-edit img.edit {
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 38px;
}
#studentInfo .learning-course-progress-single-content.white .view-content {
    padding: 15px;
    position: relative;
    min-height: 180px;
}
#studentInfo .learning-course-progress-single-content.white .inbox-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
#studentInfo .inbox-message-left-header h1.title-port {
    font-size: 15px;
    color: #05233b;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
}
#studentInfo .student-information-single-info p {
    font-weight: 400;
    font-size: 12px;
    color: #3f4254;
    text-transform: inherit;
    padding-bottom: 8px;
}
#studentInfo .student-information-single-info p span {
    padding-left: 0;
}
#studentInfo .student-information-single-info .info span.txt-f600 {
    font-weight: 600;
}
#studentInfo  .learning-course-progress-single-content {
    flex: 0 0 24%;
}
#studentInfo .student-information-heading-right a {
    font-weight: 300;
    font-size: 12px;
    color: #0078bf;
    text-transform: inherit;
}
.parent-page #studentInfo .myclassroom-single-right-right-content .dropdown-menu.show {
    transform: translate(14px, 32px)!important;
	    right: 0;
    left: auto!important;
}
.parent-page #studentInfo .stundents-information-area .students-information-dropdown {
    padding-bottom: 5px;
}
.phones-info{
	display: inline-grid;
    padding-left: 15px;
}
.parent-page  .myclassroom-single-right-right-content .arabic .dropdown-menu.show {
    display: block;
}
.parent-page #studentInfo .arabic .student-information-single-info p span,.parent-page #studentInfo .student-information-single-info p.ar-txtp span {
    padding-left: 10px!important;text-align:right;
}
.txt-arc-ri{
	text-align: right;
    display: block;
}
.parent-page .arabic .la-ar{
    display: flex;
    flex-direction: row-reverse;
}
.parent-page .arabic .pdl.la-ar{
	padding-right:0!important;
}
.change-password-submit-button input.red {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #e70b0b;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    order: ;
    border: 1px solid #e70b0b;
    transition: .3s;
}
.change-password-submit-button input.red :hover {
    background: transparent;
    color: #e70b0b;
  }

.parent-page #parent-profile .student-information-left-heading-image {
    display: flex;
    justify-content: space-between;
}
.parent-page #parent-profile .student-information-heading-profile.name {
    position: relative;
    width: 70%;
    height: 60px;
}
.parent-page #parent-profile .student-information-heading-profile {
    display: flex;
    align-items: first baseline;
}
.parent-page #parent-profile .student-information-edit img.edit {
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 38px;
}
.parent-page #parent-profile .learning-course-progress-single-content.white .view-content {
    padding: 15px;
    position: relative;
    min-height: 180px;
    min-height: 196px;
}
.parent-page #parent-profile .learning-course-progress-single-content.white .inbox-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
.parent-page #parent-profile .inbox-message-left-header h1.title-port {
    font-size: 15px;
    color: #05233b;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
}
.parent-page #parent-profile .student-information-single-info p {
    font-weight: 400;
    font-size: 12px;
    color: #3f4254;
    text-transform: inherit;
    padding-bottom: 0;
}
.parent-page #parent-profile .student-information-single-info .info span.txt-f600 {
    font-weight: 600;
}
.parent-page #parent-profile .student-information-single-info p span {
    padding-left: 0;
}
.parent-page #edit-parent-profile.request-edit-student-area {
    width: 100%;
    height: 96.5%;
    top: 3.5%;
}
.parent-page .arabic #parent-profile .student-information-single-info p {
    display: flex;
    flex-direction: row-reverse;
}
.parent-page  #edit-parent-profile .arabic .single-change-password-right-input label {
    display: flex;
    flex-direction: row-reverse;
}
.tippy-content .event-tooltip-content {
    text-align: left;
    background-color: aliceblue;
    font-size: 12px;
    padding: 5px;
	border-radius: 5px;
    border: 1px solid #fff;
    border: 1px solid #b4bcea;
}
.parent-page #form-edit-student .student-information-left-heading-image.ar-flex-end{
	
    display: flex;
    justify-content: flex-end;
}
.parent-page .arabic .request-enrollment-main-content-heading p.ticket-header{
	display: flex;
    justify-content: flex-end;
}
.parent-page .arabic .request-mobile-content-heading-text p{
	    display: flex;
    flex-direction: row-reverse;
}
#home .single-myclassroom-slider-content {
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 15px;
    transition: .3s;
    width: 100%;
    display: inline-table;
}
.parent-page .home-text-content h4.noborder span {
  border-bottom: 0;
}
.parent-page .add-activity-text-left-content h3 {
  font-weight: 400;
  font-size: 18px;
  color: #05233b;
  text-transform: inherit;
  line-height: 28px;
}
.parent-page .add-activity-text-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.massage-status{
  background: red;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  min-width:13px;
}
.icon-copy{
	margin-left: 7px;
  color: blue;
  cursor:pointer;

}
.dis-role-tag-check{
	display: flex;
    justify-content: space-between;
}
.font500{
	font-weight:500;
}
.parent-page #request-com .btn-sec {
  width: 120px;
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  background: transparent;
  border: 1px solid #0078bf;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: .3s ease-in-out;
}
.parent-page #request-com .btn-sec.btn_style_1, .parent-page #request-com .btn-sec:hover {
  background: #0078bf;
  color: #fff;
}
.pdt20{
	padding-top: 20px!important;
}
.pdt40{
	padding-top: 40px!important;
}
.lh24px{
	line-height:24px;
}
 .login-google button{
  background-color: rgb(255, 255, 255)!important;
  display: inline-flex!important;
  align-items: center!important;
  color: rgba(0, 0, 0, 0.54)!important;
  box-shadow: rgba(0, 0, 0, 0.41) 0px 2px 2px 0px, rgba(0, 0, 0, 0.41) 0px 0px 1px 0px!important;
  padding: 0px!important;
  border-radius: 5px!important;
  border: 1px solid #3F4254!important;
}
.buttongoogle{
	background-color: rgb(255, 255, 255); display: inline-flex; align-items: center; color: rgba(0, 0, 0, 0.54); box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; padding: 0px; border-radius: 2px; border: 1px solid transparent; font-size: 14px; font-weight: 500; font-family: Roboto, sans-serif;
}
span.spgoog{
	padding: 10px 10px 10px 0px; font-weight: 500;
}
div.digoog{
	margin-right: 1px; background: rgb(255, 255, 255); padding: 10px; border-radius: 2px;
}
#message .mu_profile_img_area_content p img{
	/*width:100%; !important;*/
}
#tblidstudenr .sb-avatar__text {
    border: 2px solid #fff;
}
.text-blue{
	color:blue;
}
#edit-request-template #div-from-url .select-multi{
	width:95%!important;
}
#edit-request-template .div-ct-retem{
	display: flex;
    align-items: center;
    justify-content: space-between;

}
#request-rule .select-status
{
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 8px 0 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width:80%;
}
#request-com .select-role
{
  background: #fff;
  border: 1px solid;
  padding: 10px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 100%;
  height:40px;
}
.pdfViewer .page canvas
{
  margin: 20px!important;
  display: block;
}
span.letter-grade{
  font-size: 15px!important;
  font-weight: 600!important;
  margin-right:1px;
}
.lear-foot a{
	font-size: 13px;
    color: #242323;
}
.ico-bage {
    height: 47px;
    width: auto;
   
}
 #btn-run-edit:not(:disabled) {
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  color: #FFFFFF;
  background: #0078BF;
  border: none;
  padding: 5px 25px;
  border-radius: 30px;
  text-align: center;
  border-radius: ;
  order: ;
  border: 1px solid #0078BF;
  transition: .3s;
}
 #btn-run-edit:hover {
	background: transparent;
  color: #0078BF;
 }
 .parent-page .div-view-page {
  margin-right: 10px;
}
.parent-page .mu_main_bottom_next_change.message {
  display: flex;
  align-items: center;
  justify-content: flex-end!important;
}
#message .mu_main_bottom_next_change.message {
  display: flex;
  align-items: center;
  justify-content: flex-end!important;
}
#message .mu_main_bottom_next_change.message .select-page {
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 8px 0 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 50px;
}
.parent-page .view-page {
  color: #3F4254;
  font-size: 12px;
  font-weight: 300;
  margin-right: 5px;
}
.parent-page .select-page {
  background: #FFF;
  border: 1px solid #A6ADB1;
  padding: 8px 0px 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 50px;
}