
.modal-content{
	border-radius: 10px;
}
.modal-detail-order-title{
	text-transform: uppercase;
}

.modal .modal-dialog .modal-content{
	max-width:300px;
	margin:auto;
}
.txt-al-r{
	text-align:right;
}
button.close {    
    color: #333!important;
    opacity: 1!important;
}

.mg5{
	margin-top:5px;
}
.rowct .form-group{
	    margin: 0 15px;
}
.pdr0{
	padding-right:0;
}
.rowct .input-icon-ofCus {
    border-left: 1px solid #ccc!important;
}

.div0{
	padding:0;
}
.bt-closerd{
	color: #fff!important;
    background-color: rgb(184, 25, 0)!important;
	border-color: #826c68!important;
}
.modal{ display:block!important;
 }
.modal-title{
	text-transform: uppercase;
}
#modal-sale .modal-dialog .modal-content {
     max-width: inherit; 
}

.modal-expense .checkbox input[type=checkbox], .modal-expense .checkbox-inline input[type=checkbox], .modal-expense .radio input[type=radio], .modal-expense .radio-inline input[type=radio] {
    position: absolute;
    margin-top: 4px\9;
    margin-left: -18px;
    margin-top: 3px;
}
.fade:not(.show) {
    opacity: 1!important;
}
.modal-backdrop {
    background-color: #3f425478;
    opacity: 0.1;
}
.modal{
	/*top:20%;*/
}

.modal-header {
	display:block;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}
.modal.fade .modal-dialog{
	-webkit-transform: inherit;
    transform: inherit;
}
.title-mss{
	height: 33px;
    font-size: 24px;
    line-height: 33px;
    color: #0185b7!important;
    font-family: 'Avenir';
    font-weight: 600;
    text-transform: none;
}
#btn-start {
    width: 65px;
    /* height: 49px; */
    border-radius: 4px;
    background-color: #1e5f8d;
    border-color: #ddd;
    font-size: 14px;
    /* line-height: 27px; */
    color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-right: 7px;
}

.btn-primary, .btn-primary.active, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:hover, .open>.dropdown-toggle.btn-primary, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: #1e5f8d;
    border-color: #61b943;
}
.btn-default {
   color: #333;
    background-color: #fff;
    border-color: #ccc;
    border: 1px solid #ccc;
    font-size: 14px;
	font-family: 'Avenir';
    font-weight: 400;
    text-align: center;
}
.btn-default:hover,.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4!important;
    border-color: #8c8c8c;
}
.tile-red{
	color: rgb(184, 25, 0)!important;
}
.modal-confirm .modal-dialog .modal-content {
    max-width: 475px!important;
    margin: auto;
}
.modal-eror .modal-dialog .modal-content {
    max-width: 480px;
    margin: auto;
}
.input-grade p {
    font-weight: 300;
    font-size: 14px;
    color: #3f4254;
    text-transform: inherit;
    line-height: 1.2;
    margin-bottom: 8px;
}
.input-grade p span {
    color: red;
    vertical-align: middle;
}
 .input-grade input[type="text"] {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #3f4254;
    text-transform: inherit;
    padding: 6px 10px;
    border: 1px solid #3f4254;
    border-radius: 5px;
    outline: none;
}
.btn-sa{
	margin: 0px 5px;
    border-radius: 10px;
    width: 80px;
}
#modal-detail-ev .modal-dialog {
    width: 700px;
    margin: 30px auto;
	width:80%;
}
#modal-detail-ev .modal-dialog .modal-content {
    max-width: 600px;
    margin: auto;
	max-width:100%;
}
#modal-detail-ev{
	top: 5%;z-index: 99999!important;
}
#modal-detail-ev .form-group{
	margin-bottom:5px;
}
#modal-detail-ev label{
	font-size:13px;
}
.confirm-ev{
	/*z-index: 1151!important;*/
	z-index: 9999999!important;
}
#modal-detail-ev .modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: space-between;
}
.btn-access{
	margin: 0px 5px;
    background: #81dde0;
    color: #707070;
    border: 1px solid #54ccd0;
}
.modal-confirm-enroll .modal-dialog .modal-content {
    margin: auto;
    max-width: 100%!important;
    max-width: 85%!important;
	widows:100%;
}
.modal.modal-confirm-enroll .modal-dialog {
    -webkit-transform: inherit;
    transform: inherit;
    max-width: 70%;
    width: 100%;
}
#modal-enroll{
	top: 2%;
}
#modal-enroll .admin-page .dc_single_items.ads-new-class {
    margin-bottom: 5px;
	padding:8px 16px;
}
#modal-enroll .admin-page .dc_single_items.ads-new-class p{
	font-size:0.75rem!important;
}
#modal-enroll .admin-page #se-enroll .ar-single-filter-check {
    padding-right: 10px;
    padding-top: 0px;
}
#modal-enroll .scrollere-sec {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 275px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
    margin-top: 0px
}
#modal-enroll .modal-header{
	padding:7px 15px;
}
#modal-enroll .admin-page .ar-filter-all-wrapper{
	padding:7px 20px;
}
#modal-enroll .modal-body{
	padding:0.5rem;
}
#modal-enroll .modal-footer{
	padding:0.5rem 1rem;
}
 .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.modal-body .form-group {
    margin-bottom: 0.1rem;
}
.modal-body .input-grade p{
    padding-top:10px
}
.cappital-txt{
	text-transform: capitalize;
}
.modal-body .input-grade input[type="text"] {
	border: 1px solid #ced4da;
}
#personate .modal-detail-order-title {
    text-transform: capitalize;
}
.modal-body #se-enroll .student-status-text-contents.c6 {
    flex: 0 0 12%;
    text-align: left!important;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c5 {
    flex: 0 0 21%!important;
    text-align: left;
    margin-left: 0;
}
.modal-body .row.arabic{
	display: flex;
	flex-direction: row-reverse;
}
.modal-body .arabic .input-grade p{
	display: flex;
    flex-direction: row-reverse;
}
.modal-body .arabic .input-overide{
	display: flex;
    flex-direction: row-reverse;
}
.modal-body .arabic .txt-ri{
	text-align:right;
}
.modal-confirm.modal-confirm-merge .modal-dialog .modal-content {
    max-width: 680px!important;
    margin: auto;
}
#modal-detail-ev .arabic .form-group {
    display: flex;
    flex-direction: row-reverse;
}
#modal-detail-ev .arabic .form-group label{
	text-align:right;
}
.arabic.modal-body .row, .arabic.modal-body .row p{
	display: flex;
    flex-direction: row-reverse;
}
.nocolor span{
	color:inherit!important;
	margin-right:2px;
}
.arabic.modal-body .nocolor span{
	margin-right:0;
	margin-left:2px;
}
.btn-sa.btn-en {
    min-width: 80px;
	width:auto;
}
.mssage-status.modal .modal-dialog .modal-content {
  max-width: 600px;
  margin: auto;
}
.modal-body #se-enroll .student-grade-lavel-single-content.c5.flex19{
	flex: 0 0 19% !important;
}
#modal-enroll .scrollere-sec.newcss {
  min-height: 350px !important;
  height: inherit !important;
  max-height: 375px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  margin-top: 0px;
}
@media (min-width: 768px){
	#modal-team .modal-dialog {
		width: 400px;
		margin: 30px auto;
	}
	#modal-team .modal-dialog .modal-content {
		max-width: 400px;
		margin: auto;
	}
   .modal-build .modal-content {
		max-width: 500px!important;
		margin: auto;
	}
	.modal-expense .modal-content {
		max-width: 500px!important;
		margin: auto;
	}
	#modal-upload .modal-dialog {
		width: 500px;
		margin: 30px auto;
	}
	#modal-upload .modal-dialog .modal-content {
		max-width: 500px;
		margin: auto;
	}
	.modal-personate .modal-dialog {
		max-width: 390px;
		margin: 1.75rem auto;
	}
	.modal-print .modal-dialog .modal-content {
		max-width: 35%;
	}
}