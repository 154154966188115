body{
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #F3F6F9;
  font-family: 'Source Sans Pro', sans-serif;
  font-family: 'Avenir';
  overflow: hidden;
}
html {
  overflow-x: scroll;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
a:hover{
  text-decoration: none;
}
a {
  display: inline-block;
}
ul{
  margin:0;
  padding:0;
  list-style: none;
}
button {
  padding: 0;
  border: none;
  outline: none !important;
}

/*Start Humber Icon Design Css*/
.mobile-menu-icon {
  display: none;
  position: relative;
}
.mobile-menu-icon::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 2px;
  height: 54px;
  right: -6px;
  top: -17px;
}
.mobile-search-icon {
  width: 18px;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9999;
  display: none;
}
.mobile-search-icon::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 2px;
  height: 54px;
  left: -20px;
  top: -12px;
}
.mobile-search-icon img {
  width: 18px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 4px;
}
.all-p-humber {
  width: 30px;
  height: 18px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 99;
}
.all-p-humber span {
  display: block;
  position: absolute;
  height: 3px;
  width: 25px;
  background: #A6ADB1;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
/ Icon 3 /
.all-p-humber span:nth-child(1) {
  top: 6px;
}
.all-p-humber span:nth-child(2), .all-p-humber span:nth-child(3) {
  top: 8px;
}
.all-p-humber span:nth-child(4) {
  top: 16px;
}
.all-p-humber.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.all-p-humber.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.all-p-humber.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.all-p-humber.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
/*End Humber Icon Design Css*/

/*================================================================================================
  ====                                    START HOME PAGE CSS                                 ====
  ================================================================================================*/

/*=============================================================================
  ====                        Start Header Area Css                        ====
  =============================================================================*/
  .header-top-area {
    background: #FFFFFF;
    padding-bottom: 5px;
  }
  .header-top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-top-left-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .header-top-right-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .header-logo-dropdown::after {
    content: "";
    position: absolute;
    background: #E4E6EF;
    width: 1px;
    width: 0px;
    height: 65px;
    right: 0;
    top: 0;
  }
  .header-logo-dropdown img {
    width: 250px;
    height: auto;
  }
  .header-top-left-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-logo-dropdown {
    max-width: 290px;
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .down-icon img {
    width: 12px;
    height: 8px;
    right: 22px;
    right: inherit;
    top: 25px;
    position: absolute;
  }
  .dropdown-item.width {
    width: 275px;
    margin: 8px 0;
  }
  .dropdown-item.width:hover {
    background: #DBE6EE;
  }
  .dropdown-item:hover {
    background-color: #DBE6EE;
  }
  .dropdown-menu {
    padding: 0 !important;
  }
  .dropdown-item {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding: 12px 20px;
  }
  .dropdown-menu.new-menu a {
    padding: 12px 20px;
  }
  .header-dropdown::after {
    content: '';
    position: absolute;
    background: url('/media/home/down-arrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    right: 15px;
    top: 26px;
    width: 12px;
    height: 8px;
    pointer-events: none;
  }
  .header-dropdown select {
    -webkit-appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 275px;
    height: 52px;
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .header-dropdown select option {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    cursor: pointer;
  }
  .header-flag-dropdown {
    /*padding-left: 15px;*/
    padding-right: 6px;
  }
  /* Start Custom Flug Select Option Css */
  .vodiapicker{
    display: none; 
  }
  #a{
    padding-left: 0px;
  }
  #a img, .btn-select img {
    width: 14px;
    height: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -5px;
  }
  #a li {
    font-weight: 400;
    font-size: 13px;
    color: #375F7F;
    text-transform: capitalize;
    padding: 7px 10px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
  #a li:hover{
   background-color: #F4F3F3;
 }
 #a li span, .btn-select li span {
  margin-left: 15px;
  cursor: pointer;
  line-height: 20px;
}
/* item list */
.b {
  display: none;
  max-width: 350px;
  -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.1);
  box-shadow: 0 6px 10px rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,.15);
  position: absolute;
  width: 105px;
  z-index: 2;
  width: auto;
}
.open{
  display: show !important;
}
.btn-select {
  font-weight: 400;
  font-size: 14px;
  color: #375F7F;
  text-transform: capitalize;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: none;
  width: 105px;
  position: relative;
}
.btn-select::after {
  content: "";
  position: absolute;
  background: url('/media/home/down-arrow.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 0;
  top: 14px;
  width: 12px;
  height: 8px;
}
.btn-select li{
  list-style: none;
  float: left;
  padding-bottom: 0px;
}
.btn-select:hover li{
  margin-left: 0px;
}
.btn-select:focus{
 outline:none;
}
/* End Custom Flug Select Option Css */

/* Start heading code*/
.dropdown-item.width-two:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #DBE6EE;
}
.dropdown-item.width-two {
  width: 223px;
  margin: 8px 0;
}
.dropdown-item.width-two span {
  background: #05233B;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 8px;
}
/*End Heading code*/
.profile-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.profile-text {
  text-align: left;
  margin-left: 10px;
}
.profile-image img, .profile-image .avatar-title {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.profile-text h5 {
  font-weight: 400;
  font-size: 15px;
  color: #05233B;
  text-transform: capitalize;
}
.profile-text p {
  font-weight: 400;
  font-size: 12px;
  color: #707070;
  text-transform: capitalize;
}
#dropdownMenuButton {
  background: transparent;
}
.header-top-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-profile-dropdown {
  padding: 0 42px 0 30px;
  position: relative;
}
.header-profile-dropdown::before {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 1px;
  height: 65px;
  left: 0;
  top: -12px;
}
.header-profile-dropdown::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 1px;
  height: 65px;
  right: 0;
  top: -12px;
}
.help-contetn a span {
  font-weight: 300;
  font-size: 12px;
  color: #05233B;
  text-transform: capitalize;
  margin-left:4px;
}
.help-contetn img {
  width: 12px;
  height: auto;
  margin-top: -5px;
}
.help-contetn {
  padding-left: 30px;
}
.dropdown-item.width-two {
  width: 223px;
}
.dropdown-menu.adjust {
  top: 13px !important;
  left: -30px !important;
}
.down-icon-two img {
  width: 12px;
  height: 8px;
  right: -22px;
  top: 5px;
  position: absolute;
}
/* Style the tab content */
.tabcontent {
  display: none;
} 
/* Style the tab content */
/* Start Header Bottom Area Css */
.header-bottom-area {
  background: #FAFBFC;
}
.header-bottom-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-bottom-content ul li a,.header-bottom-content ul li button.menu-drop,#mu_accordion li .link a {
  font-weight: 400;
  font-size: 14px;
  color: #05233B;
  text-transform: capitalize;
  padding: 18px 15px;
  position: relative;
}
#mu_accordion li .link a {
 padding: 0;
 display: block;
}
.tablinks i {
  top: 5px !important;
}
.header-bottom-content ul li button.menu-drop {
  padding-right: 30px;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
}
.header-bottom-content ul li button.menu-drop:hover {
  color: #0078BF;
  border-bottom: 3px solid #0078BF;
}
.header-bottom-content ul li a.actives,.header-bottom-content ul li a:hover {
  color: #0078BF;
}
.header-bottom-content ul li a:hover::after,.header-bottom-content ul li button.menu-drop:hover::after {
  content: '';
  position: absolute;
  background: #0078BF;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.header-bottom-content ul li a.actives::after{
  content: '';
  position: absolute;
  background: #0078BF;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.header-bottom-content ul li a img,.header-bottom-content ul li button.menu-drop img,#mu_accordion li .link img {
  height: 14px;
  width: auto;
  margin-top: -7px;
  margin-right: 2px;
  margin-right: 5px;
}
.header-bottom-content ul li a span {
  background: #145B94;
  font-weight: 400;
  font-size: 10px;
  color: #FFF;
  padding: 1px 5px;
  border-radius: 15px;
  width: 10px;
  margin-left: 5px;
}
.down-icon-three img {
  width: 12px !important;
  height: 8px !important;
  right: 10px;
  bottom: 24px;
  position: absolute;
}

/* End Header Bottom Area Css */

/* Start Home Text Area Css */
.home-text-area {
  background: #1B84C3;
  padding: 18px 0;
  margin-top: 2px;
}
.home-text-content h4 {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 1.2;
}
/* End Home Text Area Css */

/*=============================================================================
  ====                         End Header Area Css                         ====
  =============================================================================*/

/*=============================================================================
  ====                     Start Home Progress Area Css                    ====
  =============================================================================*/
  .home-progress-area {
    background: #F3F6F9;
    padding-bottom: 25px;
  }
  .home-progress-content {
    padding-top: 30px;
  }
  .top-progress-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .single-top-progress-area {
    background: url('/media/home/background_image.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    border: 3px solid transparent;
    cursor: pointer;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    position: relative;
  }
  .single-top-progress-area:hover {
   border: 3px solid #006365;
 }
 .top-progress-area .single-top-progress-area:nth-child(2) {
   background: url('/media/home/background-image-two.png');
   background-repeat: no-repeat;
   background-size: 100% 100%;
 }
 .single-top-progress-heading-text p {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 1.2;
}
.single-top-progress-count-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 15px;
}
.single-top-pera-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-top-progress-count-text h1,.single-top-progress-count-text span {
  font-weight: 900;
  font-size: 28px;
  color: #FFFFFF;
  text-transform: inherit;
  line-height: 1;
}
.single-top-progress-count-text h5 {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: lowercase;
  line-height: 1.2;
  margin-left: 8px;
}
.single-top-progress-last-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-top-progress-last-text .single-top-progress-count-text:nth-child(2) {
  margin-left: 15px;
}
/* Start All Progressbar Css */
.active-progress {
  width: 215px;
  height: 7px;
  background: #E4E6EF;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 8px;
}
.myclassroom-single-left-cross-progress {
  width: 150px;
  height: 7px;
  background: #E4E6EF;
  border-radius: 5px;
}
.barra-nivel {
  height: 7px;
  background: #709BBE;
  width: 1px;
  transition: width 2s ease;
  border-radius: 5px;
}
/* End All Progressbar Css */

/* Start MyClassroom Area Css */
.myclassroom-area {
  padding-top: 20px;
}
.myclassroom-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.myclassroom-header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.myclassroom-header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.myclassroom-header-left p {
  font-weight: 300;
  font-size: 18px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.myclassroom-header-left span {
  font-weight: 400;
  font-size: 13px;
  color: #05233B;
  text-transform: inherit;
  background: #DBE6EE;
  padding: 0 10px;
  border-radius: 10px;
  margin-left: 15px;
}
.myclassroom-header-single p {
  font-weight: 400;
  font-size: 14px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.myclassroom-header-single img {
  height: 14px;
  width: auto;
  margin-left: 25px;
  margin-top: -6px;
}
.myclassroom-content {
  background: #FFFFFF;
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.2);
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  border-radius: 6px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.myclassroom-single-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}
.myclassroom-single-left-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 62%;
  flex: 0 0 62%;
  cursor: pointer;
  padding: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.myclassroom-single-right-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  padding: 20px;
}
.myclassroom-single-left-content:hover {
  background: #E4E6E7;
}
.myclassroom-single-left-content-image {
  position: relative;
  width: 70px;
}
.myclassroom-single-left-content-image img {
  width: 50px;
  height: auto;
  border-radius: 3px;
}
.myclassroom-single-left-new-button button {
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
  background: #00A2A7;
  padding: 2px 7px;
  color: #FFFFFF;
  border-radius: 3px;
  position: absolute;
  right: 18px;
  bottom: 0px;
}
.myclassroom-single-right-content-text {
  width: 185px;
}
.myclassroom-single-left-content-top-text h1 {
  font-weight: 300;
  font-size: 18px;
  color: #181C32;
  text-transform: inherit;
}
.myclassroome-single-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* Start Course Progressbar Css */
.meter {
  background: #E4E6EF;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  display: block;
  height: 7px;
  position: relative;
  width: 150px;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: inset 0 2px 9px rgba(255,255,255,0.3) inset 0 -2px 6px rgba(0,0,0,0.4);
  box-shadow: inset 0 2px 9px rgba(255,255,255,0.3) inset 0 -2px 6px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
  -webkit-transition: width 2s ease-out;
  -o-transition: width 2s ease-out;
  transition: width 2s ease-out;
  background: #00A2A7;
}

.red > span {
  background-color: #f0a3a3;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0a3a3), to(#f42323));
  background-image: -o-linear-gradient(top, #f0a3a3, #f42323);
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
}
.cadetblue >span {
  background-color: #5f9ea0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0ff), to(#1e90ff));
  background-image: -o-linear-gradient(top, #0ff, #1e90ff);
  background-image: linear-gradient(to bottom, #0ff, #1e90ff);
}
/* End Course Progressbar Css */

.myclassroom-single-left-content-top-text p {
  font-weight: 500;
  font-size: 11px;
  color: #3E3F42;
  text-transform: capitalize;
}
.myclassroom-single-left-content-course-progress p {
  font-weight: 400;
  font-size: 10px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.course-progress-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.myclassroom-single-left-content-course-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.course-progress-right h5, .course-progress-right span {
  font-weight: 400;
  font-size: 10px;
  color: #3F4254;
  text-transform: inherit;
  line-height: 9px;
}
.course-progress-left {
  padding-top: 5px;
}
.profile-left-section-content img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.profile-right-section-content p {
  font-weight: 500;
  font-size: 14px;
  color: #3F4254;
  text-transform: capitalize;
  line-height: 10px;
}
.profile-right-section-content span {
  font-weight: 400;
  font-size: 12px;
  color: #3F4254;
  text-transform: capitalize;
  line-height: normal;
}
.profile-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}
.profile-right-section-content {
  margin-top: 8px;
  margin-left: 10px;
}
.single-myclass-right-content span {
  background: #05233B;
  padding: 1px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 6px;
}
.single-myclass-right-content.myclassroom-slider span {
  padding: 0 8px;
}
.single-myclass-right-content img {
  width: 16px !important;
  height: auto;
}
.myclassroom-single-right-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-myclass-right-content {
  width: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: first baseline;
}
.single-myclass-right-content.myclassroom-slider {
  align-items: center;
}
.myclassroom-single-right-right-content .dropdown button {
  font-weight: 400;
  font-size: 12px;
  color: #0078BF;
  border: 1px solid #0078BF;
  padding: 4px 15px;
  border-radius: 25px;
  position: relative;
  transition: .3s;
}
.myclassroom-single-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dropdown-menu.course {
  right: 0 !important;
  left: inherit !important;
}
/* End MyClassroom Area Css */
.see-all-class-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: center;
  display: block;
  background: #FFFFFF;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 5px;
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.2);
  box-shadow: 0 0 4px rgba(0,0,0,.2);
}
/* Start Inbox Messages Area Css */
.inbox-messages-area {
  background: #B2D8EF;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.inbox-message-left-header h1 {
  font-weight: 300;
  font-size: 18px;
  color: #05233B;
  text-transform: capitalize;
  line-height: 1.2;
}
.home-progress-right-content {
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.2);
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  border-radius: 5px;
}
.single-inbox-messages-content {
  background: #FFFFFF;
  padding: 10px 20px;
  border-bottom: 1px solid #EAEDF3;
}
.inbox-message-left-header a {
  font-weight: 500;
  font-size: 12px;
  color: #145B94;
  text-transform: capitalize;
  line-height: 1.2;
}
.inbox-message-left-header a span {
  background: #145B94;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 9px;
  color: #FFF;
  border-radius: 12px;
  margin-left: 4px;
}
.inbox-message-right-header img {
  width: 18px;
  height: auto;
}
.inbox-message-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.single-inbox-messages-content p {
  font-weight: 500;
  font-size: 12px;
  color: #3E3F42;
  text-transform: inherit;
}
.single-inbox-messages-content p span {
  color: #983E00;
}
.single-inbox-date-button p {
  font-weight: 400;
  font-size: 10px;
  color: #3E3F43;
  text-transform: inherit;
  line-height: normal;
}
.single-inbox-date-button.announcement a,.single-inbox-date-button a {
  font-weight: 400;
  font-size: 11px;
  color: #FFFFFF;
  background: #C55A11;
  padding: 3px 10px;
  border-radius: 5px;
  transition: .3s;
}
.single-inbox-date-button a {
  background: #E4E6EF;
  padding: 3px 15px;
  color: #3F4254;
}
.single-inbox-date-button a:hover {
  background: #C55A11;
  color: #FFFFFF;
}
.single-inbox-date-button.announcement a:hover {
  background: #E4E6EF;
  color: #3F4254;
}
.single-inbox-date-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 5px;
}
.inbox-messages-content .single-inbox-messages-content:nth-child(1) {
  position: relative;
}
.inbox-messages-content .single-inbox-messages-content11:nth-child(1)::after {
  content: "";
  position: absolute;
  background: #C55A11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.inbox-messages-content .single-inbox-messages-content:nth-child(3) {
  position: relative;
}
.inbox-messages-content .single-inbox-messages-content11:nth-child(3)::after {
  content: "";
  position: absolute;
  background: #C55A11;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.view-all-message a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: center;
  display: block;
  background: #FFFFFF;
  padding: 15px 20px;
  border-radius: 5px;
}
/* End Inbox Messages Area Css */

/*=============================================================================
  ====                      End Home Progress Area Css                     ====
  =============================================================================*/
  .upcoming-area {
    background-color: #f3f6f9;
    padding-bottom: 90px;
  }
  .upcoming-area tbody td {
    border-right: 1px solid #EAEDF3;
    height: 400px;
    padding: 0;
    border-bottom: 1px solid #EAEDF3;
  }
  .upcoming-area .monday {
    background-color: #b2d8ef !important;
    color: #FFFFFF !important;
  }
  .upcoming-area .monday-first-card {
    background-color: #b2d8ef;
    border-left: 2px solid #387ebe;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .upcoming-area p {
    font-size: 12px;
    text-align: left;
    padding: 2px;
  }
  .upcoming-area tr {
    position: relative;
  }
  .shasha#dropdownMenuButton {
    padding: 0;
    padding-right: 20px;
  }
  .upcoming-area .monday-second-card {
    position: absolute;
    background-color: #ebecf3;
    border-left: 2px solid #c8cbde;
    bottom: 50px;
    width: 14%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .down-icon-last img {
    width: 11px;
    height: auto;
    position: absolute;
    right: 0;
    top: 10px;
  }
  .upcoming-area .tudesday-card {
    position: absolute;
    background-color: #dbe6ee;
    border-left: 2px solid #b2d8ef;
    bottom: 111px;
    width: 13.8%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .upcoming-area .friday-card {
    position: absolute;
    background-color: #e5f4f4;
    border-left: 2px solid #b2d8ef;
    top: 172px;
    width: 12%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 6px;
  }
  .upcoming-area .events p {
    font-weight: 900;
    font-size: 18px;
    color: #05233B;
  }
  .upcoming-area .btn-group .btn {
    border: 1px solid #e8e8e8;
    background-color: transparent;
  }
  .upcoming-area .btn-group .first-btn {
    padding-top: 14px;
  }
  .btn.first-btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  #dropdownMenuButton {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .btn.first-btn {
    cursor: pointer;
  }
  .btn.first-btn img {
    width: 8px;
    height: auto;
    margin-top: -9px;
  }
  .upcoming-heading-content.homepage {
    background: #FFFFFF;
  }
  .upcoming-heading-content p {
    font-weight: 300;
    font-size: 18px;
    color: #05233B;
  }
  .table thead th {
    font-weight: 300;
  }
  .upcoming-heading-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    padding: 5px 15px;
  }
  .dropdown-toggle.shfocus:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .btn {
    font-size: 14px;
  }
  .btn.items.shasha {
    padding-right: 25px;
  }
/*=============================================================================
  ====                      Start Footer Area Area Css                     ====
  =============================================================================*/
  .footer-area {
    background: #080F1E;
    padding-top: 48px;
    padding-bottom: 28px;
  }
  .footer-area .container {
    position: relative;
  }
  .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 80px;
  }
  .single-footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    z-index: 9;
  }
  .footer-links ul li a {
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: capitalize;
    line-height: normal;
    padding-bottom: 10px;
  }
  .footer-logo img {
    width: 140px;
    height: auto;
    margin-right: 20px;
  }
  .need-help-content p {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: inherit;
    padding-bottom: 5px;
  }
  .contact-button a {
    font-weight: 300;
    font-size: 12px;
    color: #FFF;
    border: 1px solid #FFF;
    padding: 4px 25px;
    border-radius: 15px;
    transition: 0.3s ease-in-out;
    background: transparent;
  }
  .contact-button a:hover{
    background: #0078BF;
    border-color: #0078BF;
    color: #FFF;
  }
  .copyright-text {
    padding-top: 10px;
    border-top: 1px solid #7E8299;
  }
  .copyright-text p,.copyright-text p a {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: inherit;
    text-align: center;
  }

  /* Start Chating Area Css */
  .chating-area {
   max-width: 1140px;
   width: 100%;
 }
 .chating-content {
  max-width: 265px;
  width: 100%;
  border: 1px solid #ADDEDC;
  border-top-right-radius: 25px;
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 1;
  border-top-left-radius: 25px;
  z-index: 99;
  transition: 1s;
}
.chating-content.show {
  bottom: -100%;
}
.chating-heading,.chating-heading-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #0078BF;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 7px 20px;
}
.chating-heading-area {
  position: fixed;
  right: 15px;
  bottom: 15px;
  max-width: 265px;
  width: 100%;
  border-radius: 25px;
  z-index: 99;
}
.chating-heading-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.chating-heading-left p {
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 1.2;
  text-transform: capitalize;
}
.chating-heading-left img {
  width: 18px;
  height: auto;
  margin-top: -5px;
  margin-right: 10px;
}
.chating-heading-left p span {
  background: #FFFFFF;
  padding: 1px 10px;
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  border-radius: 8px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.chating-heading-right button {
  background: none;
}
.chating-heading-right button img {
  width: 16px;
  height: auto;
  margin-left: 10px;
  margin-top: -6px;
}
.single-chating-left-image img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.single-chating-content {
  background: #ADDEDC;
  padding: 10px;
  border-bottom: 1px solid #EAEDF3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.single-chating-content:hover {
  background: #FFFFFF;
}
.single-chating-left-content p {
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  text-transform: inherit;
  line-height: 1.5;
}
.single-chating-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-chating-right-content p {
  font-weight: 400;
  font-size: 10px;
  color: #3E3F43;
  text-transform: inherit;
  line-height: 1.5;
}
.single-chating-left-content p:nth-child(2) {
  color: #686868;
}
.view-all-button a {
  background: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-transform: inherit;
  text-align: center;
  display: block;
  padding: 8px 10px;
}
/* End Chating Area Css */
/*=============================================================================
  ====                       End Footer Area Area Css                      ====
  =============================================================================*/

/*================================================================================================
  ====                                     END HOME PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                                 START HOME CARD PAGE CSS                               ====
  ================================================================================================*/

/*=============================================================================
  ====                    Start Myclassroom Slider Area Css                ====
  =============================================================================*/
  .myclassroom-slider-area {
    padding-left: 35px;
  }
  .single-myclassroom-slider-content {
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 15px;
    transition: .3s;
  }
  .single-myclassroom-slider-content:hover {
    border: 2px solid #0078BF;
    transform: scale(1.03);
    margin-left: 5px;
  }
  .single-myclassroom-slider {
    padding: 7px;
  }
  .single-myclassroom-slider-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .single-myclassroom-slider-middle-heading h1 {
    font-weight: 500;
    font-size: 15px;
    color: #3E3F42;
    text-transform: capitalize;
    line-height: 1.2;
  }
  .single-myclassroom-slider-middle-heading p {
    font-weight: 500;
    font-size: 11px;
    color: #3E3F42;
    text-transform: capitalize;
  }
  .single-myclassroom-slider-middle-heading {
    padding-top: 12px;
    padding-bottom: 2px;
  }
  .slider-course-progress-left p {
    font-weight: 400;
    font-size: 9px;
    color: #707070;
    text-transform: inherit;
  }
  .slider-course-progress {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .course-progress-right {
    margin-left: 12px;
    margin-top: 14px;
  }
  .single-slider-profile-left img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
  }
  .single-slider-profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 12px;
  }
  .single-slider-profile-right {
    margin-left: 8px;
  }
  .single-slider-profile-right h2 {
    font-weight: 400;
    font-size: 11px;
    color: #181C32;
    text-transform: capitalize;
    line-height: normal;
  }
  .single-slider-profile-right p {
    font-weight: 400;
    font-size: 9px;
    color: #3F4254;
    text-transform: capitalize;
    line-height: normal;
  }
  .launch-course-button button {
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    width: 180px;
    padding: 5px 10px;
    border-radius: 25px;
    text-align: center;
    text-transform: inherit;
    transition: .3s;
    margin: 0 auto;
    display: block;
  }
  .launch-course-button button:hover {
   background: transparent;
   color: #0078BF;
 }
 .view-course-button button {
  font-weight: 500;
  font-size: 11px;
  color: #0078BF;
  background: transparent;
  border: none;
  width: 180px;
  padding: 5px 10px;
  border-radius: 25px;
  text-align: center;
  text-transform: inherit;
  transition: .3s;
  margin: 0 auto;
  margin-top: 3px;
  display: block;
}
.view-course-button button:hover {
 background: #0078BF;
 color: #FFFFFF;
}
.slider-course-progress-left p {
  font-weight: 400;
  font-size: 9px;
  color: #707070;
  text-transform: inherit;
  margin-bottom: 2px;
}
.single-myclassroom-slider-heading a,.single-myclassroom-slider-heading p {
  font-weight: 500;
  font-size: 9px;
  color: #3F4254;
  border-radius: ;
  border-radius: 3px;
  text-transform: capitalize;
}
.single-myclassroom-slider-heading a {
  width: 45px;
  text-align: center;
}
.single-myclassroom-slider-heading a {
 background: #DBE6EE;
 padding: 2px 6px;
}
.single-myclassroom-slider-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-myclassroom-slider-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #B2D8EF;
  padding: 7px;
}
.single-myclassroom-slider-bottom-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 135px;
}
.single-myclassroom-slider-bottom-right img {
  width: 20px !important;
  height: auto;
}
.see-all-class-slider-button a {
  font-weight: 400;
  font-size: 14px;
  color: #0078BF;
  text-align: right;
  display: block;
}
/* Start Owl Carousel Customize Css */
.myclassroom-slider-area::after {
  content: "";
  position: absolute;
  background: url('/media/home-card/myclassroom-slider-after.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: -12px;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 1;
}
.owl-theme .owl-nav {
  margin-top: 0;
  height: 0;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  color: transparent;
}
.owl-theme .owl-nav [class*="owl-"] {
  margin: 0;
}
.owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: 0px;
  top: 45%;
  transform: translateY(-50%);
}
.owl-theme .owl-nav .owl-prev img:hover {
  opacity: .8;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  width: 12px;
  height: 12px;
  background: #3F4254;
  border: 2px solid #3F4254;
}
.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: transparent;
  border: 2px solid #3F4254;
}

/*Rakib start*/
.ra_resources_area .owl-theme .owl-dots {
  bottom: -29px;
}
.ra_resources_content .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
.ra_resources_content .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999;
}
.ra_event_area .owl-theme .owl-nav .owl-prev img {
  width: 24px;
  height: auto;
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
.ra_event_area .owl-theme .owl-nav .owl-next img {
  width: 24px;
  height: auto;
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999;
}
.ra_event_main_area_slider{
  padding: 0 35px 0 45px;
}
.ra_event_main_area_slider .owl-theme .owl-nav .owl-next img {
  right: -33px;
}
.btn.first-btn.extra_btn_btnclass {
  padding: 7.5px 13px;
}
.btn {
  padding: .380rem .75rem;
}
/*Rakib end*/


/* End Owl Carousel Customize Css */

/*=============================================================================
  ====                     End Myclassroom Slider Area Css                 ====
  =============================================================================*/

/*================================================================================================
  ====                                  END HOME CARD PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                                   START LOGIN PAGE CSS                                 ====
  ================================================================================================*/
  .login-header {
    background: #FFFFFF;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
  }
  .login-logo img {
    width: 250px;
    height: auto;
  }
  .login-content {
    max-width: 485px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0,0,0,.1);
    margin: 10px auto;
    padding: 25px 68px;
  }
  .login-heading h1 {
    font-weight: 500;
    font-size: 35px;
    color: #05233B;
    text-transform: inherit;
    line-height: 55px;
    padding-bottom: 15px;
    text-align: center;
  }
  .single-login-form {
    margin-bottom: 15px;
  }
  .single-login-form label {
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    display: block;
    text-align: left;
    margin-bottom: 5px !important;
  }
  .single-login-form input {
    width: 100%;
    border: 1px solid #3F4254;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    outline: none;
  }
  .single-login-form input:focus {
    border: 2px solid #0078BF;
    border-radius: 5px;
  }
  .single-login-form input::placeholder {
   font-weight: 500;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .single-login-submit-button input {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  background: #0078BF;
  border: none;
  width: 100%;
  padding: 8px 15px;
  border-radius: 30px;
  margin-top: 10px;
  transition: .3s;
}
.single-login-submit-button input:hover {
  background: #3F4254;
}
.login-form-text a {
  font-weight: 500;
  font-size: 14px;
  color: #0078BF;
  text-transform: inherit;
  line-height: normal;
}
.login-form-text a:nth-child(1) {
  padding: 20px 0;
}
.login-form-text a span {
  color: #05233B;
}
.login-form-logo {
  text-align: center;
  margin-top: 15px;
  position: relative;
}
.login-form-logo::before {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 78px;
  height: 2px;
  left: 0;
  top: 22px;
}
.login-form-logo::after {
  content: "";
  position: absolute;
  background: #E4E6EF;
  width: 78px;
  height: 2px;
  right: 0;
  top: 22px;
}
.login-form-logo img {
  width: 160px;
  height: auto;
}
.single-login-checkbox-form input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.single-login-checkbox-form label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  display: block;
  text-align: left;
  margin-bottom: 5px !important;
}
.single-login-checkbox-form label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #05233B;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
  top: -5px;
}
.single-login-checkbox-form input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #05233B;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.login-footer-area {
  background: #080F1E;
  padding-top: 55px;
  padding-bottom: 30px;
}
.login-footer-content {
  max-width: 485px;
  width: 100%;
  margin: 0 auto;
}
.login-footer-top-left-content img {
  width: 145px;
  height: auto;
}
.login-footer-top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #7E8299;
}
.login-footer-top-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.single-login-footer-left a {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: inherit;
  margin-right: 15px;
}
.single-login-footer-right a {
  font-weight: 300;
  font-size: 12px;
  color: #FFF;
  border: 1px solid #FFF;
  padding: 4px 25px;
  border-radius: 15px;
}
.login-footer-bottom-content p {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: inherit;
  text-align: center;
  padding-top: 12px;
}
.login-footer-bottom-content p a {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  line-height: 10px;
}
.login-footer-bottom-content p span {
  color: #7E8299;
}
/*================================================================================================
  ====                                    END LOGIN PAGE CSS                                  ====
  ================================================================================================*/

/*================================================================================================
  ====                           Start Resource Cards Page (10)(Munna)                        ====
  ================================================================================================*/
  /*Start Alert Area*/
  .mu_resource_cards{
    background: #F3F6F9;
  }
  .mu_alert_box, .custom_alert_design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ADDEDC;
    padding: 14px;
    margin-top: 24px;
    border-radius: 4px;
  }
  .mu_alert_box_left {
    display: flex;
    align-items: center;
  }
  .mu_alert_box_left img{
    width: 22px;
    height: 22px;
    object-fit: cover;
    margin-right: 17px;
  }
  .mu_alert_box_left p{
    font-size: 14px;
    color: #3F4254;
  }
  .mu_alert_box_right a,.custom_alert_right a {
    font-size: 12px;
    font-weight: 300;
    color: #3F4254;
    display: inherit;
    margin-right: 10px;
  }
  /*End Alert Area*/

  /*================== Start Dropdown Menu & Search Area ==================*/
  /*Start Dropdown*/
  .dropdown_search_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mu_dropdown_menu_content {
    flex: 0 0 55%;
  }
  .mu_search_bar{
    flex: 0 0 33%
  }
  .mu_dropdown_menu_area {
    background: #F3F6F9;
    margin-top: 39px;
  }
  .mu_dropdown_menu_content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mu_dropdown_menu_content ul li{
    color: #3F4254;
    margin-right: 20px;
    font-size: 12px;
  }
  .mu_dropdown_menu_content ul li a, .mu_dropdown_menu_content ul li button.menu-drop {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    padding: 18px 0;
    position: relative;
    background: transparent;
  }
  .mu_dropdown_menu_content ul li button.menu-drop {
    padding-right: 30px;
  }
  .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
    color: #0078BF;
    border-bottom: 3px solid #0078BF;
  }
  .mu_dropdown_menu_content ul li a.active,.mu_dropdown_menu_content ul li a:hover {
    color: #0078BF;
  }

  .mu_dropdown_menu_content ul li a img,.mu_dropdown_menu_content ul li button.menu-drop img {
    height: 14px;
    width: auto;
    margin-top: -7px;
    margin-right: 2px;
  }
  .mu_dropdown_menu_content ul li a span {
    background: #145B94;
    font-weight: 400;
    font-size: 10px;
    color: #FFF;
    padding: 1px 5px;
    border-radius: 15px;
    width: 10px;
    margin-left: 5px;
  }
  /*End Dropdown*/

  /*Start Mobile Menu Icon*/
  #nav-icon3{ 
    display: none;
  }
  #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
    width: 25px;
    height: 20px;
    position: relative;
    /*margin: 0px auto;*/
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  #nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #A6ADB1;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  /* Icon 3 */
  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }
  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 8px;
  }
  #nav-icon3 span:nth-child(4) {
    top: 16px;
  }
  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  /*End Mobile Menu Icon*/

  /* Start Mobile Search Box Css */
  #search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: 2;
  }
  #search input[type="search"] {
    position: absolute;
    top: 50%;
    max-width: 450px;
    width: 100%;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    outline: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-bottom: 1px solid #FFF;
    padding: 5px 15px;
  }
  #search input[type="search"]::-webkit-input-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]::-moz-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]:-ms-input-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]::-ms-input-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]::-webkit-input-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]::-moz-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]:-ms-input-placeholder {
    color: #FFFFFF;
  }
  #search input[type="search"]::placeholder {
    color: #FFFFFF;
  }
  #search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #0078BF;
    -webkit-transition: .3s;
    transition: .3s;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 8px 30px;
    text-transform: uppercase;
    margin-top: 20px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    border: none;
  }
  .volver-button a {
    font-weight: 400;
    font-size: 17px;
    color: #231F20;
    background: #00F1A4;
    padding: 8px 45px;
    border-radius: 10px;
    margin-top: 60px;
    text-transform: uppercase;
    -webkit-transition: .3s;
    transition: .3s;
  }
  #search .btn:hover {
    background: #ADDEDC;
    color: #3E3F42;
  }
  #search .btn.btn-primary:focus {
    box-shadow: none;
  }
  #search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: #0078BF;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    font-size: 15px;
    outline: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: .3s;
  }
  #search .close:hover {
    background: #ADDEDC;
    color: #3E3F42;
  }
  #search button.close::after {
    content: "";
    position: absolute;
    background: url(/media/ico/cross.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    left: 12px;
    top: 12px;
    -webkit-transform: rotate(133deg);
    transform: rotate(133deg);
  }
  #search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  /* End Mobile Search Box Css */

  /*Start Mobile Menu Responsive*/
  .mu_full_menu_area{
    position: relative;
  }
  .mu_accordion {
    display: none;
    position: absolute;
    width: 100%;
    background: #FAFBFC;
    top: 55px;
    left: 0;
    z-index: 9991;
  }
  .mu_accordion.hide {
    display: none !important;
  }
  .mu_accordion .link {
    cursor: pointer;
    display: block;
    padding: 22px 0px 20px 36px;
    color: #05233B;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #CCC;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .mu_accordion li:last-child .link { 
    border-bottom: 0; 
  }
  .mu_accordion li i {
    position: absolute;
    top: 20px;
    left: 43px;
    font-size: 23px;
    color: #A6ADB1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .mu_accordion li i.fa-chevron-down {
    right: 35px;
    left: auto;
    font-size: 16px;
    top: 2px;
    color: #000000;
  }
  .mu_accordion li.open i.fa-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  /*submenu*/
  .mu_submenu {
    display: none;
    background: #FFF;
    font-size: 14px;
  }
  .mu_submenu li {
   border-bottom: 1px solid #E4E6EF; 
 }
 .mu_submenu a {
  display: block;
  text-decoration: none;
  color: #05233B;
  padding: 20px;
  padding-left: 36px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.mu_submenu a:hover {
  background: #FAFBFC;
  color: #05233B;
}
/*End Mobile Menu Responsive*/

/*Start Search Bar*/
.mu_search_bar button{
  font-size: 14px;
  color: #FFFFFF;
  position: relative;
}
.mu_search_bar input[type="search"] {
  background: #FFF;
  border: 1px solid #A6ADB1;
  padding: 7px 0px 7px 40px;
  outline: none;
  width: 260px;
  color: #505355;
  font-size: 14px;
  border-radius: 3px 0 0 3px;
}
.mu_search_bar input[type="search"]:focus{
  box-shadow: 0px 0px 10px #bbb;
}
.mu_search_bar button {
  border: none;
  background: #05233B;
  color: #fff;
  outline: none;
  padding: 8px 15px;
  margin-left: -5px;
  border-radius: 0 3px 3px 0;
}
.mu_search_bar button img {
  margin: -2px 0 0 40px;
}
.search_box_area,.mu_search_bar {
  position: relative;
}
.mu_search_bar form {
  display: flex;
  justify-content: flex-end;
}
.search_icon {
  position: absolute;
  top: 3px;
  padding: 0 10px;
}
.search_icon img{
  width: 19px;
  height: auto;
  cursor: pointer;
}
.mu_dropdown {
  background: #fff;
  width: 170px;
  border: 1px solid #ddd;
  position: absolute;
  right: 0;
  bottom: -135px;
  border-radius: 4px;
  z-index: 999;
  display: none;
}
.mu_dropdown ul li a{
  color: #3F4254;
  font-size: 14px;
  display: block;
  padding: 8px 20px;
  border-bottom: 3px solid transparent;
}
.mu_dropdown ul li a:hover {
  background-color: #E4E6EFA3;
}
/* End Search Bar */

/* Start View icons*/
.mu_view_icons ul{
  display: flex;
  align-items: center;
}
.mu_view_icons ul li{
  margin-right: 20px;
  font-size: 14px;
  color: #3F4254;
}
.mu_view_icons ul li:last-child{
  margin-right: 0;
}
.mu_view_icons ul li a{
  display: flex;
  align-items: center;
}
.view_img_1{
  width: 11px;
}
.view_img_2{
  width: 17px;
}
/* End View icons*/
/*=================== End Dropdown Menu Area =================*/

/*========================== Start Grid Box Area ====================*/
.mu_grid_box_area {
  background: #F3F6F9;
}
.mu_single_all_grid_boxs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.mu_single_grid_boxs {
  flex: 0 0 18.5%;
  margin-bottom: 30px;
}
.mu_single_grid_box {
  background: #fff;
  padding: 9px;
  border: 1px solid #E4E6EF;
  border-radius: 3px;
}
.mu_date_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.mu_date_img ul li{
  font-size: 9px;
}
.mu_date_img img{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}
.mu_single_grid_box h6{
  font-size: 15px;
  font-weight: 400;
  color: #3E3F42;
  margin-bottom: 7px;
}
.mu_single_grid_box p {
  font-size: 12px;
  font-weight: 400;
  color: #3E3F42;
  line-height: 15px;
}
.mu_grid_box_pdf_left{
  display: flex;
  align-items: center;
}
.mu_grid_box_pdf_left img{
  width: 13px;
  height: 13px;
  margin-right: 7px;
}
.mu_grid_box_pdf_left a{
  font-size: 9px;
  color: #145B94;
  text-decoration: underline;
}
.mu_grid_box_pdf{
  display: flex;
  justify-content: space-between;
}
.mu_grid_box_pdf_right{
  margin: 3px 0;
}
.mu_grid_box_pdf_right a{
  color: #3F4254;
  font-size: 9px;
}
/*PDF Client*/
.mu_pdf_client{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #B2D8EF;
  padding: 6px 9px;
}
.mu_pdf_client_left{
  display: flex;
  align-items: center;
}
.mu_pdf_client_left img{
  margin-right: 5px;
  width: 23px;
  height: 23px;
}
.mu_pdf_client_content h6 {
  font-size: 12px;
  color: #181C32;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.mu_pdf_client_content p {
  font-size: 10px;
  color: #3F4254;
}
.mu_pdf_client_right a img{
  width: 20px;
  height: 20px;
}

/*Footer Button (10)(Munna)*/
.mu_footer_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.mu_footer_btn a img{
  width: 120px;
  border: 1px solid #FFF;
  margin-right: 10px;
}
/*===============================================================================================
  ====                           End Resource Cards Page (10)(Munna)                         ====
  ===============================================================================================*/

/*================================================================================================
  ====                                     START STUDENT PAGE CSS                             ====
  ================================================================================================*/

/*=============================================================================
  ====                      Start student_pages_content_area Css           ====
  =============================================================================*/

  /*student_action_alert_area*/
  .student_action_alert_area .custom_alert_design {
    background: rgba(230,119,43,0.29);
    margin-top: 0;
    margin-bottom: 5px;
  }
  /*End student_action_alert_area*/

  /*student_pages_content_area*/
  .student_pages_content_area {
    /* padding-bottom: 1080px; */
    position: relative;
	/*overflow-y: auto!important;*/
  }
  .infor_heading_area{
    display: flex;
    justify-content: space-between;

  }
  .main_student_information_area {
    background: #FFF;
    margin-top: 14px;
  }
  .left_infor_area .infor_profile img {
    max-width: 60px;
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    margin-top: 7px;
    margin-left: 11px;
  }
  .infor_profile{
    position: relative;
  }
  .infor_profile a {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
  }
  .right_infor_area a img {
    max-width: 13px;
    width: 100%;
    height: auto;
  }
  .right_infor_area a span {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    margin-left: 2px;
  }
  .right_infor_area a {
    margin-top: 15px;
    margin-right: 22px;
    display: flex;
    align-items: center;
  }
  .left_infor_area .infor_profile a img {
    max-width: 21px;
    width: 100%;
    height: auto;
  }
  .information_biodata h4 {
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    color: #3F4254;
    margin-top: -15px;
  }
  .infor_bio_top {
    max-width: 197px;
    width: 100%;
    margin: auto;
    margin-top: 19px;
  }
  .infor_bio_top > ul li a, .infor_comment_area > ul li a, 
  .infor_address_area > ul li a, .infor_parent_area > ul li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    color: #3F4254;
    font-size: 11px;
  }
  .infor_bio_top ul li:not(:last-child) {
    margin-bottom: 6px;
  }
  .infor_bio_top ul li a span{
    margin-left: 20px;
    display: inline-block;
  }
  .infor_bio_top > ul li:nth-child(4) a span, 
  .infor_comment_area > ul li:nth-child(4) a span {
    margin-left: 35px;
  }
  .infor_comment_area > p{
    margin-top: 45px;
    margin-bottom: 39px;
  }
  .infor_comment_area > ul li:nth-child(3) a span {
    margin-left: 35px;
  }
  .infor_address_area h5 {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-top: 46px;
    margin-bottom: 20px;
  }
  .infor_address_area ul li:nth-child(1) a span{
    margin-left: 0;
  }
  .infor_address_area > ul li:nth-child(4) a span {
    margin-left: 30px;
  }
  .infor_address_area > ul li:nth-child(5) a span {
    margin-left: 10px;
  }
  .infor_address_area > ul > li > a, .infor_parent_area > ul > li > a{
    position: relative;
  }
  .infor_address_area > ul > li > a::after, .infor_parent_area > ul > li > a::after {
    content: "";
    position: absolute;
    right: -30px;
    top: 2px;
    width: 1px;
    height: 12px;
    background: #C7C8CA;
  }
  .infor_parent_area {
    margin-right: -20px;
    padding-bottom: 24px;
  }
  .infor_parent_area h5{
    margin-top: 48px;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 14px;
  }
  .infor_address_area ul li:not(:last-child), .infor_parent_area ul li:not(:last-child) {
    margin-bottom: 7px;
  }
  .infor_parent_area > ul > li > a span{
    margin-left: 15px;
  }
  .infor_parent_area > ul > li:nth-child(3) a span{
    margin-left: 10px;
  }
  .infor_parent_area > ul > li:nth-child(4) a span{
    margin-left: 10px;
  }
  .infor_parent_area > ul > li > a::after {
    right: -11px;
  }
  /*student_action_help_area css*/
  .student_action_help_area {
    margin-top: 14px;
  }
  .student_action_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .student_action_heading h5{
    font-size: 16px;
    font-weight: 300;
    color: #05233B;
  }
  .student_action_heading a{
    color: #0078BF;
    font-weight: 300;
  }
  .action_st_btn_img img {
    max-width: 58px;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    margin-top: 14px;
    margin-bottom: 26px;
  }
  .Student_action_single_items, .st_another_single_deffrent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .st_single_action {
    background: #FFF;
    flex: 0 0 calc(50% - 6px);
    margin-bottom: 10px;
  }
  .st_single_action h5 {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    padding-top: 15px;
    padding-left: 20px;
  }
  .action_st_btn_img {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btn_style_1 {
    font-weight: 300;
    color: #FFF;
    font-size: 12px;
    background: #0078BF;
    border: 1px solid #0078BF;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
  }
  .btn_style_1:hover{
    background: #FFF;
    color: #0078BF;
  }
  .btn_style_2 {
    font-weight: 300;
    color: #0078BF;
    font-size: 12px;
    background: #FFF;
    border: 1px solid #0078BF;
    padding: 4px 17px;
    display: inline-block;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
  }
  #launchcourse {
    background: transparent;
  }
  .btn_style_2:hover,#launchcourse:hover{
    background:  #0078BF;
    color: #FFF;
  }
  .Student_action_single_items .action_st_btn_img a.btn_style_2 {
    margin-top: 14px;
    margin-bottom: 20px;
  }
  .Student_action_single_items .st_single_action:nth-child(2) a.btn_style_1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Student_action_single_items .st_single_action:nth-child(2) .action_st_btn_img img {
    max-width: 53px;
    margin-top: 35px;
  }
  .st_another_single_deffrent .st_single_action:nth-child(1) .action_st_btn_img img{
    max-width: 53px;
    margin-top: 13px;
    margin-bottom: 0;
  }
  .st_another_single_deffrent .st_single_action:nth-child(2) .action_st_btn_img img{
    max-width: 56px;
    margin-top: 13px;
    margin-bottom: 0;
  }
  .st_another_single_deffrent .st_single_action:nth-child(1) .action_st_btn_img a.btn_style_1{
    margin-top: 18px;
    margin-bottom: 24px;
  }
  .st_another_single_deffrent .st_single_action:nth-child(2) .action_st_btn_img a.btn_style_1{
    margin-top: 19px;
    margin-bottom: 24px;
  }
/*=============================================================================
  ====                       End student_pages_content_area Css            ====
  =============================================================================*/

/*================================================================================================
  ====                                      END STUDENT PAGE CSS                              ====
  ================================================================================================*/

/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/
  .resources-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
  .same-close-button-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  .same-close-button-left button {
    font-weight: 300;
    font-size: 18px;
    color: #3F4254;
    text-transform: capitalize;
    line-height: 1.2;
    background: none;
  }
  .same-close-button-left button img {
    width: 14px;
    height: auto;
    margin-top: -6px;
    margin-right: 15px;
  }
  .same-close-right-link-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .single-same-close-right-link-button a {
    font-weight: 300;
    font-size: 14px;
    color: #0078BF;
    text-transform: inherit;
    border: 1px solid #0078BF;
    padding: 5px 18px;
    border-radius: 30px;
    transition: .3s;
  }
  .single-same-close-right-link-button a:hover {
    background: #0078BF;
    color: #FFFFFF;
  }
  .same-close-right-link-button .single-same-close-right-link-button:nth-child(2) a {
    margin-left: 15px;
  }
  .resources-main-image-area {
    text-align: right;
    padding-top: 25px;
  }
  .resources-main-image-area img {
    max-width: 880px;
    width: 100%;
    height: auto;
  }
/*================================================================================================
  ====                                    START RESOURCE PAGE CSS                             ====
  ================================================================================================*/

/*================================================================================================
  ====                          START REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                 ====
  ================================================================================================*/
  .request-mobile-area {
    background: #FFFFFF;
  }
  .request-transcript-main-mobile-design {
    display: none;
  }
  .request-mobile-content-area {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .request-mobile-content-heading-text p {
    padding-bottom: 20px;
  }
  .request-mobile-content-area p,.single-request-transcript-heading-content p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    line-height: 1.2;
  }
  .single-request-mobile-middle-text p:nth-child(2) {
    font-size: 12px;
    padding-top: 5px;
    width: 88%;
  }
  .single-request-mobile-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F3F6F9;
    padding: 25px 20px;
    margin-bottom: 20px;
  }
  .single-request-mobile-middle-text {
    margin-left: 25px;
    max-width: 385px;
    width: 100%;
  }
  .single-request-mobile-middle-text a,.single-request-transcript-bottom-head a {
    font-weight: 300;
    font-size: 12px;
    color: #0B7DC1;
    text-transform: inherit;
    border-bottom: 1px solid #0B7DC1;
    line-height: 10px;
  }
  .single-request-mobile-left-image img {
    width: 50px;
    height: auto;
  }
  .single-request-mobile-right-text a {
    background: transparent;
  }
/*================================================================================================
  ====                          END REQUEST TRANSCRIPT LIST MOBILE PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST TRANSCRIPT LIST  PAGE CSS                     ====
  ================================================================================================*/
  .request-transcript-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
  .request-transcript-main-content {
    padding-top: 55px;
  }
  .request-transcript-main-content {
    width: 95%;
    margin: 0 auto;
  }
  .single-request-transcript-content {
    background: #F3F6F9;
    padding: 15px 20px 60px 20px;
    height: 100%;
    position: relative;
  }
  .single-request-transcript-heading-content img {
    height: 55px;
    width: auto;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .single-request-transcript-bottom-content p {
   font-weight: 300;
   font-size: 12px;
   color: #05233B;
   line-height: 1.2;
 }
 .single-request-transcript-bottom-button a {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 185px;
}
.single-request-transcript-heading-content p {
  text-align: left;
}
.single-request-transcript-bottom-button a:hover {
  background: transparent;
}
/*================================================================================================
  ====                             END REQUEST TRANSCRIPT LIST  PAGE CSS                      ====
  ================================================================================================*/

/*================================================================================================
  ====                              START REQUEST ENROLLMENT PAGE CSS                         ====
  ================================================================================================*/
  .request-enrollment-area {
   background: #FFFFFF;
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   z-index: 999;
   display: none;
 }
 .need-help-link a {
  font-weight: 300;
  font-size: 12px;
  color: #0B7DC1;
  text-transform: inherit;
  border-bottom: 1px solid #0B7DC1;
  line-height: 11px;
}
.request-enrollment-main-content {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.request-enrollment-main-content-heading p {
  font-weight: 300;
  font-size: 15px;
  color: #05233B;
  text-transform: inherit;
  line-height: 1.4;
  padding-bottom: 25px;
}
.request-enrollment-option {
  margin-bottom: 10px;
}
.request-enrollment-single-select-option {
  margin-bottom: 20px;
}
.request-enrollment-option p, .single-request-enrollment-option label, .request-enrollment-single-select-option p,.request-enrollment-single-datepiker p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  line-height: 1.2;
  margin-bottom: 8px;
}
.request-enrollment-option p span, .request-enrollment-single-select-option p span,.request-enrollment-single-datepiker p span {
  color: #FF0000;
  vertical-align: middle;
}

/* Start Custom Radio Checkbox Css */
.single-request-enrollment-option input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.single-request-enrollment-option label {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 30px;
}
.single-request-enrollment-option label::before {
  content: '';
  background: url('/media/enrollment/check-before.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
.single-request-enrollment-option input:checked + label::after {
  content: '';
  background: url('/media/enrollment/check-after.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: -2px;
}
/* End Custom Radio Checkbox Css */

/* Start Dropdown Select Option Filter Css */
#select-chose-course {
  border: 1px solid #3F4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
}
#select-chose-course::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 6px;
  right: 12px;
  top: 13px;
}
.hierarchy-select.dropdown .hs-searchbox {
  padding: 4px 5px 4px 5px;
  position: relative;
}
.hs-searchbox img {
  position: absolute;
  left: 12px;
  top: 15px;
  width: 15px;
  height: auto;
  cursor: pointer;
}
.dropdown-menu.select-course {
  border: 1px solid #E4E6EF;
  box-shadow: 0 0 2px rgba(0,0,0,.1);
  max-width: 275px;
  width: 100%;
}
.form-control.select-course::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #A6ADB1;
  text-transform: inherit;
  line-height: 1.7;
}
.form-control.select-course {
  border: 1px solid #A6ADB1;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 5px 10px 5px 30px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  outline: none;
}
.dropdown-item.enrollment {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
} 
.dropdown-item.enrollment:hover {
  color: inherit;
  background-color: #E4E6EF;
}
.dropdown-item.enrollment.active, .dropdown-item.enrollment:active {
  color: #3F4254;
  text-decoration: none;
  background-color: #DBE6EE;
  font-weight: 300;
}
.form-control.select-course:focus {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  outline: none !important;
  box-shadow: inherit;
  border: 1px solid #A6ADB1;
}

/* Start Dropdown Select Option Filter Css */

/* Start Custom Datepiker Css */
.form-groups {
  max-width: 280px;
  width: 100%;
  position: relative;
}
.form-groups::after,.info-form-group::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/datepiker.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 18px;
  top: 8px;
  right: 9px;
  pointer-events: none;
}
.form-groups::before,.info-form-group::before {
  content: "";
  position: absolute;
  background: #3F4254;
  width: 1px;
  height: 34px;
  top: 0;
  right: 32px;
}
.form-groups .input-field {
  border: 1px solid #3F4254;
  background: transparent;
  max-width: 280px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.form-groups input::placeholder {
  font-size: 14px;
}
.form-groups .input-field:focus {
  outline: none;
}
.ui-datepicker {
  display: none;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.ui-datepicker-calendar table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: #353535;
}
.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 50%;
  line-height: 2rem;
  transition: 0.3s all;
  color: #353535;
  font-size: 0.875rem;
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background: #d33a47;
  color: #ffffff;
}
.ui-datepicker-calendar tbody td a.ui-state-highlight {
  color: #0078BF;
  border: 1px solid #0078BF;
}
.ui-datepicker-calendar tbody td a:hover {
  background: #0078BF;
  color: #FFFFFF;
}
.ui-datepicker-header a span {
  display: none;
}
.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 8px 20px;
}
.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
}
.ui-datepicker-header a.ui-datepicker-prev::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  transform: rotate(90deg);
  top: 12px;
}
.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
}
.ui-datepicker-header a.ui-datepicker-next::after {
  content: "";
  position: absolute;
  background: url('/media/enrollment/down-arrow-enroll.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  transform: rotate(-90deg);
  top: 12px;
}
.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.ui-datepicker-week-col {
  color: #353535;
  font-weight: 400;
  font-size: 0.75rem;
}
/* End Custom Datepiker Css */

.all-same-button-design {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 35px;
}
.single-same-button-design button {
  font-weight: 300;
  font-size: 12px;
  color: #0078BF;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #0078BF;
  width: 104px;
  text-align: center;
  padding: 3px 5px;
  border-radius: 20px;
  transition: .3s;
}
.single-same-button-design button:hover {
 background: #0078BF;
 border: 1px solid #0078BF;
 color: #FFFFFF;
}
.all-same-button-design .single-same-button-design:nth-child(2) button {
  background: #0078BF;
  border: 1px solid #0078BF;
  color: #FFFFFF;
  margin-left: 15px;
}
.all-same-button-design .single-same-button-design:nth-child(2) button:hover {
 background: transparent;
 border: 1px solid #0078BF;
 color: #0078BF;
}
.reason-paragraf-text p {
  font-weight: 300;
  font-size: 14px;
  color: #05233B;
  text-transform: inherit;
  line-height: 1.2;
  padding-top: 25px;
  padding-bottom: 45px;
}
.single-same-button-design.change {
  margin-left: 15px;
}
/*================================================================================================
  ====                               END REQUEST ENROLLMENT PAGE CSS                          ====
  ================================================================================================*/

/*================================================================================================
  ====                            START REQUEST RECOMMENDATION PAGE CSS                       ====
  ================================================================================================*/

  .request-recommendation-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
  .request-recommendation-opition-area {
    max-width: 685px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .request-recommendation-heading p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    padding-bottom: 10px;
  }
  .request-recommendation-option-content:nth-child(2) .single-request-recommendation-option-area {
    padding-bottom: 20px;
  }
  .new-request-recommendation-option-content {
    margin-bottom: 25px;
  }
  .request-recommendation-option-head p {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    text-transform: capitalize;
    padding-bottom: 15px;
  }
  .single-request-recommendation-option-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    cursor: pointer;
    position: relative;
    background: #F3F6F9;
    padding: 20px 30px;
    border: 2px solid transparent;
  }
  .single-request-recommendation-option-content.show {
    border: 2px solid #0078BF;
  }
  .single-request-recommendation-left img {
    width: 45px;
    height: auto;
  }
  .single-request-recommendation-right {
    margin-left: 30px;
  }
  .single-request-recommendation-right h5 {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    text-transform: inherit;
  }
  .single-request-recommendation-right p {
    font-weight: 300;
    font-size: 12px;
    color: #05233B;
    text-transform: inherit;
    max-width: 360px;
    width: 100%;
    padding-top: 8px;
    line-height: 1.2;
  }
  .single-request-recommendation-right p:nth-child(1) {
    padding-top: 0;
  } 
  .cross-button-image button {
    background: transparent;
  }
  .cross-button-image img {
    width: 14px;
    height: auto;
    position: absolute;
    right: 28px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
/*================================================================================================
  ====                             END REQUEST RECOMMENDATION PAGE CSS                        ====
  ================================================================================================*/

/*================================================================================================
  ====                           START REQUEST RECOMMENDATION INFO PAGE CSS                   ====
  ================================================================================================*/
  .recommendation-myinfo-area {
    background: #FFFFFF;
  } 
  .recommendation-myinfo-content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .recommendation-save-text {
    display: flex;
    justify-content: flex-start;
  }
  .recommendation-save-text img {
    width: 13px;
    height: auto;
    margin-top: -5px;
    margin-right: 2px;
  }
  .recommendation-save-text span {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
  }
  .recommendation-tab-button img {
    max-width: 530px;
    width: 100%;
    height: auto;
    margin-left: 15px;
    padding: 8px 0;
  }
  .recommendation-tab-top-button {
    display: flex;
    justify-content: space-between;
    max-width: 540px;
    width: 100%;
    margin-left: 12px;
  }
  .recommendation-tab-top-button button {
    font-weight: 300;
    font-size: 12px;
    color: #7E8299;
    background: none;
    text-transform: capitalize;
    transition: .3s;
  }
  .recommendation-tab-top-button button:hover {
    color: #0B7DC1;
  }
  .recommendation-tab-top-button button.actives {
    color: #0078BF;
  }
  .recommendation-myinfo-form-heading-text p {
    font-weight: 300;
    font-size: 15px;
    color: #05233B;
    text-transform: inherit;
    padding-top: 25px;
    padding-bottom: 20px;
    margin-left: 10px;
  }
  .recommendation-myinfo-form-heading-text.second p {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 22px;
    margin-left: 5px;
  }
  .recommendation-myinfo-form-heading-text.salf-evaluation p {
    margin-left: 0;
  }
  .recommendation-myinfo-first-form form {
    max-width: 340px;
    width: 100%;
  }
  .info-form-group {
    position: relative;
    cursor: pointer;
  }
  .info-form-group input {
    cursor: pointer;
  }
  .recommendation-myinfo-first-form {
    margin-left: 10px;
  }
  .single-recommendation-myinfo-second {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
  }
  .single-recommendation-info-second-half {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .single-myinfo-second-right,.single-myinfo-second-half {
    max-width: 340px;
    width: 100%;
  }
  .single-myinfo-second-left p,.single-myinfo-second-left-pera p {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    margin-right: 20px;
    text-transform: inherit;
  }
  .single-myinfo-second-left p {
    margin-top: 34px;
  }
  .single-myinfo-second-left-pera p {
    margin-top: 8px;
  }
  .single-myinfo-form label,.single-recommendation-myinfo-second label,.single-recommendation-myinfo-second-bottom label {
    font-weight: 300;
    font-size: 14px;
    color: #05233B;
    display: block;
    margin-bottom: 6px;
  }
  .single-myinfo-form {
    padding-bottom: 15px;
  }
  .single-myinfo-form input,.single-recommendation-myinfo-second input,.single-recommendation-myinfo-second-bottom input {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    padding: 6px 10px;
    border: 1px solid #3F4254;
    border-radius: 5px;
    outline: none;
  }
  .single-recommendation-myinfo-second input,.single-recommendation-myinfo-second-bottom input {
    margin-bottom: 15px;
  }
  .single-myinfo-second-half {
    display: flex;
    justify-content: space-between;
  }
  .single-myinfo-second-half input {
    flex: 0 0 48%;
  }
  .single-myinfo-form input::placeholder,.single-recommendation-myinfo-second input::placeholder,.single-recommendation-myinfo-second-bottom input::placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #3F4254;
   text-transform: inherit;
 }
 .myinfo-teacher-name {
  position: relative;
}
.myinfo-teacher-name::after {
  content: "";
  position: absolute;
  background: url('/media/recommendation/down-arrow.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10px;
  height: 7px;
  right: 10px;
  bottom: 15px;
  pointer-events: none;
}
.myinfo-teacher-name select {
  width: 100%;
  border: 1px solid #3F4254;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
}
.myinfo-teacher-name option {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
}
.recommendation-myinfo-second-form-heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 15px;
}
.recommendation-myinfo-second-form-heading p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: capitalize;
  padding-left: 10px;
}
.recommendation-myinfo-second-form-heading img {
 width: 12px;
 height: auto;
 margin-left: 10px;
}
.custom_checkbox_area.recommendation_checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  padding-bottom: 25px;
  padding-left: 10px;
}
.custom_checkbox_area.recommendation_checkbox.middle {
  padding-top: 138px;
}
.recommendation-myinfo-bottom-design {
  display: flex;
  justify-content: flex-start;
}
.recommendation-myinfo-second-form-right {
  padding-top: 52px;
  padding-left: 20px;
}
.recommendation_checkbox .single-recommendation-checkbox:nth-child(1) {
  margin-right: 20px;
}
.single-recommendation-checkbox label span {
  cursor: pointer;
}
.add-anoter-button a img {
  width: 18px;
  height: auto;
  margin-right: 5px;
  margin-top: -6px;
}
.add-anoter-button a {
  font-weight: 300;
  font-size: 12px;
  color: #0078BF;
  text-transform: inherit;
}
.recommendation-myinfo-bottom-peragraf p {
  font-weight: 300;
  font-size: 11px;
  color: #3F4254;
  max-width: 515px;
  width: 100%;
  line-height: 1.3;
  padding-top: 10px;
}
.all-same-button-design-with-back {
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
}
/*================================================================================================
  ====                            END REQUEST RECOMMENDATION INFO PAGE CSS                    ====
  ================================================================================================*/


/*================================================================================================
  ====                     START REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS              ====
  ================================================================================================*/
  .recommendation-salf-evaluation,.recommendation-reflection {
    background: #FFFFFF;
  }
  .recommendation-myinfo-form-heading-text p:nth-child(2) {
    padding-top: 0;
    font-weight: 300;
    font-size: 11px;
    color: #3F4254;
    text-transform: inherit;
    max-width: 500px;
    width: 100%;
  }
  .recommendation-salf-evaluation-form form {
   max-width: 510px;
   width: 100%;
 }
 .adjetive-input-textarea-content {
  padding-bottom: 25px;
}
.recommendation-salf-evaluation-form-textarea {
  padding-bottom: 35px;
}
.recommendation-salf-evaluation-form-textarea label, .recommendation-salf-evaluation-form-peragraf p, .single-recommendation-slef-input-textarea-right label {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  display: block;
  text-transform: inherit;
  line-height: 1.3;
}
.recommendation-salf-evaluation-form-peragraf p:nth-child(1) {
  padding-bottom: 15px;
}
.recommendation-salf-evaluation-form-textarea label {
 margin-bottom: 5px;
}
.single-recommendation-self-input-textarea {
  display: flex;
  justify-content: flex-start;
  align-items: first baseline;
  padding-top: 25px;
}
.single-recommendation-slef-input-textarea-right {
  max-width: 490px;
  width: 100%;
}
.recommendation-salf-evaluation-form-textarea textarea,.single-recommendation-slef-input-textarea-right textarea {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  padding: 5px 10px;
  border: 1px solid #3F4254;
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: none;
  height: 90px;
}
.single-recommendation-slef-input-textarea-right input {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 padding: 5px 10px;
 border: 1px solid #3F4254;
 max-width: 185px;
 width: 100%;
 border-radius: 5px;
 margin-bottom: 15px;
}
.single-recommendation-slef-input-textarea-right input::placeholder {
 font-weight: 300;
 font-size: 14px;
 color: #3F4254;
 text-transform: inherit;
}
.single-recommendation-slef-input-textarea-right textarea {
  height: 60px;
}
.recommendation-salf-evaluation-form-textarea p {
  font-weight: 300;
  font-size: 11px;
  color: #3F4254;
  padding-top: 8px;
}
.single-recommendation-slef-input-textarea-left p {
  font-weight: 300;
  font-size: 14px;
  color: #3F4254;
  text-transform: inherit;
  padding-right: 12px;
}
/*================================================================================================
  ====                     END REQUEST RECOMMENDATION SELF EVALUATION PAGE CSS                ====
  ================================================================================================*/

/*================================================================================================
  ====                      START REQUEST RECOMMENDATION REFLECTION PAGE CSS                  ====
  ================================================================================================*/

  .recommendation-salf-evaluation-form-textarea.reflection label {
    margin-bottom: 10px;
  }
  .recommendation-salf-evaluation-form-textarea.reflection {
    padding-bottom: 45px;
  }
/*================================================================================================
  ====                       END REQUEST RECOMMENDATION REFLECTION PAGE CSS                   ====
  ================================================================================================*/

/*================================================================================================
  ====                                  START SETTINGS PAGE CSS                               ====
  ================================================================================================*/
  .change-password-area {
    padding-bottom: 900px;
  }
  .change-password-content {
    max-width: 700px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 60px;
    padding-top: 20px;
  }
  .change-password-left-content {
    background: #FFFFFF;
    padding: 5px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    max-width: 270px;
    width: 100%;
    border-radius: 4px;
  }
  .change-password-left-content button {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    width: 100%;
    text-align: left;
    padding: 12px 20px;
    background: #FFFFFF;
    transition: .3s;
  }
  .change-password-left-content button:hover {
    background: #DBE6EE;
  }
  .change-password-right-content {
    background: #FFFFFF;
    padding: 30px 40px;
    max-width: 415px;
    width: 100%;
    border-radius: 4px;
  }
  .change-password-right-heading p {
    font-weight: 300;
    font-size: 20px;
    color: #05233B;
    text-transform: capitalize;
    line-height: 1.2;
    padding-bottom: 20px;
  }
  .single-change-password-right-input {
    padding-bottom: 15px;
  }
  .single-change-password-right-input label {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .single-change-password-right-input input {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
    text-transform: inherit;
    border: 1px solid #3F4254;
    width: 100%;
    padding: 5px 15px;
    border-radius: 5px;
    outline: none;
  }
  .single-change-password-right-input input::placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .single-change-password-right-input input:focus {
    border: 2px solid #0078BF;
  }
  .change-password-submit-button {
    text-align: center;
  }
  .change-password-submit-button input {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    order: ;
    border: 1px solid #0078BF;
    transition: .3s;
  }
  .change-password-submit-button input:hover {
    background: transparent;
    color: #0078BF;
  }
  .field-icon {
    float: right;
    position: relative;
    z-index: 2;
    top: -30px;
    right: 10px;
    cursor: pointer;
  }
  .field-icon::before {
    content: "";
    position: absolute;
    background: url('/media/enrollment/eyes.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 13px;
    right: 0;
    top: 2px;
  }
/*================================================================================================
  ====                                   END SETTINGS PAGE CSS                                ====
  ================================================================================================*/

/*================================================================================================
  ====                               Start Progress tracking pages css                        ====
  ================================================================================================ */
  /* start progress_tracking_main_area css*/
  .pro_college_heading{
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
  }
  .left_pro_college_area h3 {
    font-weight: 300;
    color: #05233B;
    font-size: 20px;
    margin-top: 12px;
  }
  .right_pro_college_area {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pro_download_area {
    position: relative;
  }
  .pro_search_clg {
    margin-right: 30px;
  }
  .pro_download_area .down-icon {
    position: absolute;
    right: -95px;
    top: -16px;
  }
  .pro_download_area .dropdown-menu {
    min-width: 170px !important;
    border: 1px solid #E4E6EF;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 6px 0 !important;
    position: absolute !important;
    left: -75px !important;
    top: 5px !important;
  }
  .drop_as{
    font-family: 'Avenir';
    font-weight: 300;
    color: #3F4254;
    padding: 10px 0;
    padding-left: 18px;
    transition: 0.3s;
  }
  .drop_as:hover{
    background: #DBE6EE;
  }
  /*progress tracking pages progress*/
  .rt_progress_funel {
    background: url('/media/progress/rt_progress.png') no-repeat scroll 0 0 / cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    padding-bottom: 45px;
    cursor: pointer;
    transition: .1s;
    position: relative;
  }
  .rt_progress_main_area {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  .left_rt_progress_sys {
    flex: 0 0 25%;
  }
  .rt_progress_txt h3{
    font-weight: 500;
    color: #FFF;
    text-align: center;
  }
  .right_rt_progress_sys {
    flex: 0 0 calc(75% - 15px);
    margin-left: 15px;
    background: #FFF;
    padding: 13px 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-radius: 5px;
  }
  .rt_progress_bar_design {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .rt_progress_left_side h5 {
    font-size: 18px;
    font-weight: 300;
    color: #05233B;
    margin-bottom: 13px;
  }
  .rt_progress_left_side p {
    font-size: 12px;
    color: #05233B;
    font-weight: 300;
    margin-top: 12px;
  }
  .rt_progress_left_side {
    flex: 0 0 95%;
  }
  #prgoress-two .rt_progress_left_side {
    flex: 0 0 93%;
  }
  .rt_progress_right_side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
    flex: 0 0 calc(5% - 10px);
  }
  .rt_progress_left_side .myclassroom-single-left-cross-progress {
    width: 100%;
    height: 16px;
    border-radius: 50px;
  }
  .rt_progress_left_side .barra-nivel {
    height: 15px;
    background: #00A2A7;
    border-radius: 50px;
  }
  .rt_progress_right_side h5, .rt_progress_right_side span {
    color: #05233B;
    font-weight: 300;
    font-size: 12px;
  }
  /* startpt_progress_main_area css*/
  .pt_view_main_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 45px;
  }
  .pt_left_view h3{
    font-weight: 400;
    color: #3F4254;
    font-size: 14px;
  }
  .pt_right_view {
    display: flex;
    align-items: center;
  }
  .pt_right_view a{
    margin-left: 16px;
  }
  .btn_style_3 {
    color: #0078BF;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    background: transparent;
    transition: 0.3s ease-in-out;
  }
  .btn_style_3:hover{
    color: #FFF;
    background: #0078BF;
  }
  .btn_style_4{
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078BF;
    border-radius: 50px;
    background: #0078BF;
    transition: 0.3s ease-in-out;
  }
  .btn_style_4:hover{
    color: #0078BF;
    background: transparent;
  }
/*================================================================================================
  ====                                End Progress tracking pages css                         ====
  ================================================================================================ */


 /*================================================================================================
  ====                           Start Messages Page (11)(Munna)                                ====
  ================================================================================================*/
  .mu_messages_page {
    padding: 17px 0px 0px 0px;
  }
  .mu_messages_all{
    display: flex;
  }
  .mu_discussion_header_top_text p{
    font-size: 16px;
    color: #05233B;
    margin-left: 20px;
    font-weight: 300;
  }
  /*===================== Start Right Site Messages page =================*/
  .mu_messages_page_left_site {
    flex: 0 0 25%;/*
    padding-bottom: 100px;*/
    background: #FFF;
  }

  /* Start Message Text*/
  .mu_messages_area{
    background: #FFF;
    padding: 30px 45px 20px 30px;
  }
  .mu_messages_left img {
    width: 25px;
    margin-right: 10px;
  }
  .mu_messages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #145B94;
    padding-bottom: 10px;
  }
  .mu_messages_left{
    display: flex;
    align-items: center;
  }
  .mu_messages_left h4{
    font-size: 20px;
    color: #145B94;
  }
  .mu_messages_right {
    background: #00A2A7;
    padding: 4px 10px;
    border-radius: 5px;
  }
  .mu_messages_right p{
    font-size: 12px;
    color: #fff;
	text-transform: uppercase;
  }
  /* End Message Text*/

  /* Start Accordion Messages box*/
  .mu_accordion2 {
    width: 100%;
    max-width: 360px;
    background: #FFF;
  }
  .mu_accordion2 .link {
    cursor: pointer;
    display: block;
    padding: 10px 30px;
    color: #3E3F42;
    font-size: 14px;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .mu_accordion2 .link:hover{
    background: #DBE6EE;
  }
  .mu_accordion2 li i {
    position: absolute;
    top: 14px;
    left: 12px;
    font-size: 18px;
    color: #595959;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .mu_accordion2 li i.fa-chevron-down {
    right: 25px;
    left: auto;
    font-size: 12px;
    color: #A6ADB1;
  }
  .mu_accordion2 li.open i.fa-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  /*.accordion li.open .link { background: #DBE6EE; }*/

  /*.accordion li.open i { color: #3E3F42; }*/
  .mu_submenu2 {
    display: none;
    background: #444359;
    font-size: 14px;
  }
  .mu_submenu2 li { 
    background: #FFF; 
  }
  .mu_submenu2 a {
    display: block;
    text-decoration: none;
    color: #3E3F42;
    padding: 12px;
    padding-left: 50px;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .mu_submenu2 a:hover {
    background: #DBE6EE;
    color: #3E3F42;
  }
  /* End Accordion Messages box*/

  /*Start Recent Client */
  .mu_recent_client li { 
    background: #FFF; 
  }
  .mu_recent_client li img {
    margin-right: 17px;
  }
  .mu_li_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mu_recent_client li img{
    width: 28px;
    height: auto;
  }
  .mu_accrodion_li_flex {
    display: flex;
    padding: 12px 30px;
  }
  .mu_accrodion_li_flex p {
    color: #3F4254;
    font-size: 14px;
    line-height: 1.6;
  }
  /*End Recent Client */

  /*Start Button*/
  .mu_message_button {
    background: #FFF;
    padding: 25px 20px 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .mu_message_button a {
    text-align: center;
    font-size: 14px;
    color: #0078BF;
    border: 1px solid #0078BF;
    border-radius: 20px;
    padding: 6px 0;
    margin-bottom: 16px;
    font-weight: 400;
    max-width: 240px;
    width: 100%;
    flex: 0 0 100%;
  }
  .mu_message_button a:hover {
    background: #0078BF;
    color: #FFF;
    transition: .3s;
  }
  /*End Button*/
  /*===================== End LEFT Site Messages page =================*/

  /*===================== Start Right Site Messages page =================*/
  .mu_right_site_border{
    border: 1px solid #EAEDF3;
    border-radius: 6px 5px 0 0;
  }
  .mu_messages_page_right_site {
    flex: 0 0 75%;
  }
  /* Start Chart Text*/
  .mu_chart_area {
    width: 270px;
    background: #E4E6EF;
    padding: 20px 0 28px 0;
    border: 4px solid #FFF;
  }
  .mu_chart_left img {
    width: 25px;
    margin-right: 10px;
  }
  .mu_chart {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mu_chart_left{
    display: flex;
    align-items: center;
  }
  .mu_chart_left h4{
    font-size: 20px;
    color: #707070;
  }
  .mu_chart_right {
    background: #707070;
    padding: 4px 10px;
    border-radius: 5px;
    margin-left: 12px;
  }
  .mu_chart_right p{
    font-size: 12px;
    color: #fff;
	text-transform: uppercase;
  }
  /* End Chart Text*/

  /*=======Start search Header*/
  .mu_chart_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    padding: 23px 15px 15px 27px;
    border-radius: 6px 5px 0 0;
  }
  .mu_chart_header.mu_chart_header2 {
    justify-content: normal;
  }
  /*Start Header button*/
  .mu_header_btn {
    display: flex;
  }
  .mu_header_btn.mu_header_btn2 {
    margin-right: 40px;
  }
  .mu_header_btn a {
    display: block;
    font-size: 14px;
    color: #0078BF;
    border: 1px solid #0078BF;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;
    margin-right: 16px;
  }
  .mu_header_btn a:hover {
    background: #0078BF;
    color: #FFF;
    transition: .3s;
  }
  .mu_header_btn.mu_header_btn12 a {
    margin: 0px;
  }
  .mu_header_btn.mu_header_btn12f a {
    margin: 0px 12px 0px 0px;
  }
  .mu_header_btn.mu_header_btn12f{
    display: flex;
    align-items: center;
  }
  .mu_header_btn.mu_header_btn12f img {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  /*End Header button*/

  /*Start search Bar*/
  .mu_search_bar.mu_search_bar_reply {
    flex: inherit;
    max-width: 352px;
    width: 100%;
  }
  /*End search Bar*/

  /*Start Next button*/
  .mu_header_next_btn img{
    width: 8px;
    height: auto;
  }
  .pagination_left_icon {
    padding-right: 33px;
  }
  .pagination_right_icon {
    padding-left: 33px;
  }
  .mu_header_next_btn{
    display: flex;
    align-items: center;
  }
  .mu_pagination_count_button a {
    font-weight: 400;
    font-size: 14px;
    background: transparent;
    color: #3E3F43;
    padding: 5px 12px;
    border-radius: 4px;
    transition: .3s;
    margin: 0 3px;
  }
  .mu_pagination_count_button a.active {
    background: #0078BF;
    color: #FFF;
    padding: 5px 12px;
    border-radius: 4px;
  }
  .mu_pagination_count_button a:hover {
    background: #0078BF;
    color: #FFF;
    border-radius: 4px;
  }
  /*End Next button*/
  /*======End search Header*/

  /*======Start icon Header*/
  .mu_icon_header {
    background: #FFF;
    padding: 9px 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #EAEDF3;
  }
  .mu_icon_header_left {
    display: flex;
    align-items: center;
  }
  .mu_icon_header_left_flex{
    display: flex;
    align-items: center;
  }
  /*Start check box*/
  .mu_icon_header_checkbox{
    margin-right: 10px;
  }
  .mu-form-group {
    display: block;
  }

  .mu-form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .mu-form-group label {
    position: relative;
    cursor: pointer;
  }

  .mu-form-group label::before {
    content: '';
    -webkit-appearance: none;
    background-color: #F6F7F9;
    border: 1px solid #D8DCE6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
  }

  .mu-form-group input:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 7px;
    height: 12px;
    border: solid #707070;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  /*End check box*/

  /*Start icons*/
  .mu_icon_header_icons{
    margin-right: 15px;
  }
  .mu_icon_header_icons ul {
    display: flex;
    align-items: center;
  }
  .mu_icon_header_icons ul li a{
    margin: 0 13px;
    display: flex;
    align-items: center;
  }
  .mu_icon_header_icons ul li span{
    color: #D8DCE6;
  }
  .mu_icon_header_icons ul li a img{
    width: 14px;
    height: auto;
  }
  /*End icons*/
  .mu_filter_announcement p{
    font-size: 14px;
    color: #3E3F43;
    font-weight: 300;
  }
  .mu_icon_header_right p{
    font-size: 14px;
    color: #3E3F43;
  }
  /*======End icon Header*/

  /*========Start People List*/
  .mu_people_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    padding: 16px 27px;
    border-top: 1px solid #EAEDF3;
  }
  /*Start People Left Site*/
  .mu_people_list_left {
    display: flex;
    align-items: center;
  }
  .mu_peo_left_img {
    display: flex;
    align-items: center;
    margin: 0 15px 0 6px;
  }
  .mu_peo_left_img a {
    display: flex;
  }
  .mu_peo_left_img img{
    width: 16px;
    height: auto;
  }
  .mu_people_left_left {
    display: flex;
    align-items: center;
    width: 220px;
  }
  .mu_people_profile{
    display: flex;
    align-items: center;
  }
  .mu_people_profile img{
    width: 28px;
    height: auto;
    margin-right: 10px;
  }
  .mu_people_profile p{
    font-size: 14px;
    color: #3E3F43;
  }

  .mu_people_left_right p{
    font-size: 14px;
    color: #3E3F43;
  }
  /*Start People Left Site*/

  /*Start People Right Site*/
  .mu_people_list_right{
    display: flex;
    align-items: center;
  }
  .mu_people_list_right p{
    font-size: 14px;
    color: #3E3F43;
  }
  .mu_people_list_margin {
    margin: 0 20px 0 12px;
  }
  .mu_people_list_right a img{
    width: 20px;
    height: auto;
  }
  .mu_people_list_right a{
    display: flex;
    align-items: center;
  }
  .mu_people_right_img {
    width: 16px;
    height: auto;
    margin-right: 15px;
    cursor: pointer;
  }
  /*End People Right Site*/
  /*======End People List*/
  
  /*===================== End Right Site Messages page =================*/

  /*Start Bottom Next Page*/
  .mu_main_bottom {
    display: flex;
    padding: 10px 0;
  }
  .mu_main_bottom_left{
    flex: 0 0 25%;
  }
  .mu_main_bottom_right{
    flex: 0 0 75%;
  }
  .mu_main_bottom_next_change{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /*End Bottom Next Page*/

   /*===============================================================================================
  ====                           End Messages Page (11)(Munna)                                  ====
  ================================================================================================*/

  /*================================================================================================
  ====                           Start Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/
  .mu_single_letter {
    background: #FFF;
    padding: 0 0 80px 27px;
    border-top: 1px solid #EAEDF3;
  }
  /*Start Letter Top Button*/
  .mu_letter_top_button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 27px 17px 0;
  }
  /*End Letter Top Button*/

  /*Start Letter Content Area*/
  .mu_profile_img_area_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 37px;
    padding-right: 27px;
  }
  .mu_profile_img_area_left{
    display: flex;
    align-items: center;
  }
  .mu_profile_img_area_left img{
    width: 52px;
    height: auto;
    margin-right: 19px;
  }
  .mu_profile_img_area_left p{
    font-size: 16px;
    color: #3E3F43;
  }
  .mu_header_btn.mu_header_btn12.mu_header_btn12_2 a {
    margin-left: 15px;
  }

  .mu_profile_img_area_content h6{
    font-size: 16px;
    color: #3E3F43;
    font-weight: 300;
  }
  .mu_profile_img_area_content p{
    font-size: 14px;
    color: #3E3F43;
    margin-top: 19px;
  }
  /*End Letter Content Area*/

  /*Start Letter bottom Button*/
  .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 a {
    margin: 0 15px 0 0 ;
  }
  .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 {
    margin-top: 100px;
  }
  /*End Letter bottom Button*/
  /*================================================================================================
  ====                           End Messages Individual Page (12)(Munna)                     ====
  ================================================================================================*/

    /*================================================================================================
  ====                           Start Discussions Page (13)(Munna)                     ====
  ================================================================================================*/
  .mu_main_discussion_page{
    display: flex;
  }

  /*================================ Start Left Site discussion Page ============================*/
  .mu_main_left_discussion {
    flex: 0 0 33%;
    background: #FFF;
  }
  /*Overflow Scroll*/
  .mu_message_scroll_left{
    height: 715px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*Start left site header*/
  .mu_dis_left_header{
    padding: 22px 30px 0px 30px;
    border-bottom: 1px solid #EAEDF3;
  }
  .mu_dis_left_header ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mu_dis_left_header ul li a{
    font-size: 14px;
    color: #3F4254;
    border-bottom: 3px solid transparent;
    padding-bottom: 21px;
  }
  .mu_dis_left_header ul li a:hover{
    border-bottom: 3px solid #145B94;
    color: #3E3F42;
  }
  .mu_dis_left_li_flex a{
    display: flex;
    align-items: center;
  }
  .mu_dis_left_li_flex a img{
    width: 15px;
    height: auto;
    margin-right: 5px;
  }
  /*End left site header*/

  /*Start My Teacher*/
  .mu_my_teacher_dis{
    border-bottom: 1px solid #EAEDF3;
  }
  .mu_my_teacher_dis p{
    font-size: 12px;
    color: #181C32;
    padding: 13px 30px;
    font-weight: 300;
  }
  .mu_dis_teacher_img {
    border-bottom: 1px solid #EAEDF3;
    padding: 8px 20px 15px 20px;
  }
  .mu_dis_teacher_img ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mu_dis_teacher_img ul li a img{
    width: 40px;
    height: auto;
  }
  /*End My Teacher*/

  /*Start Dropdown Menu*/
  .mu_dis_dropdown_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 15px 18px 30px;
    border-bottom: 1px solid #EAEDF3;
  }
  .mu_header_btn.mu_dis_le_dropdown_btn a {
    margin: 0;
    padding: 6px 36px;
  }
  .mu_dis_le_dropdown ul{
    display: flex;
    align-items: center;
  }
  .mu_dis_le_dropdown_li_btn{
    font-size: 14px;
    color: #3F4254;
    margin-right: 50px;
  }
  .mu_dis_li_img img {
    width: 12px !important;
    height: 8px !important;
    right: 30px;
    top: 12px;
    position: absolute;
  }
  /*End Dropdown Menu*/

  /*Start Discussion Full Area*/
  .mu_dis_content_left {
    border-bottom: 1px solid #EAEDF3;
    display: flex;
    justify-content: space-between;
    padding: 15px 7px;
    background: #FFFFFF;
    cursor: pointer;
  }
  .mu_dis_content_left:hover {
    background: #F6F9FD;
  }
  .mu_dis_content_description_left p:first-child{
    font-size: 11px;
    color: #9EA0A5;
  }
  .mu_dis_con_pad h4{
    font-size: 16px;
    color: #3E3F42;
    font-weight: 700;
    margin-bottom: 3px;
  }
  .mu_dis_con_pad p{
    font-size: 12px !important;
    margin-bottom: 7px;
    color: #6B6C6F;
  }
  .mu_dis_content_description_left p{
    font-size: 11px;
    color: #6B6C6F;
  }
  .mu_dis_img_con_flex_left{
    display: flex;
  }
  .mu_dis_con_img_img1 {
    margin-bottom: -21px;
  }
  .mu_dis_content_img_posi img {
    width: 40px;
    height: auto;
  }
  .mu_dis_con_p_pos a {
    font-size: 12px;
    color: #FFF;
    background: #009651;
    padding: 3px 10px;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
  }
  .mu_dis_content_img_posi{
    position: relative;
    text-align: center;
    margin-bottom: 15px;
  }
  .mu_dis_con_p_pos {
    position: absolute;
    bottom: -13px;
    right: 32px;
  }

  /*Start Class Discussion Area*/
  .mu_dis_con_single_img img{
    width: 25px;
    height: auto;
    border: 2px solid #FFF;
    border-radius: 50%;
  }
  .mu_dis_con_img_to_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mu_dis_con_img_to_btn2{
    position: absolute;
    bottom: 18px;
    left: 15px;
  }
  .mu_dis_con_img_to_btn3{
    position: absolute;
    bottom: 20px;
    left: 27px;
  }
  .mu_dis_con_single_img:nth-child(2){
    margin-left: -11px;
  }
  .mu_dis_con_single_img:nth-child(3){
    margin-left: -11px;
  }
  .mu_dis_con_single_img:nth-child(4){
    margin-left: -11px;
  }
  .mu_dis_con_single_img:nth-child(5){
    margin-left: -11px;
  }
  .mu_dis_con_single_img:nth-child(6){
    margin-left: -11px;
  }
  .mu_dis_con_single_img button{
    font-size: 9px;
    color: #FFF;
    background: #707070;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
  }
  .mu_dis_content_img_left {
    width: 190px;
    position: relative;
  }
  .mu_dis_con_img_img1 img{
    width: 15px;
    height: auto;
  }
  .mu_dis_content_img_posi.mu_dis_content_img_posi2 {
    margin-bottom: 0px;
  }
  .mu_dis_con_pad.mu_dis_con_pad2 h4 {
    margin-bottom: 13px;
  }
  .mu_dis_con_pad.mu_dis_con_pad2 p {
    font-size: 14px !important;
    line-height: 1.5;
  }

  .mu_dis_time_left p{
    font-size: 11px;
    color: #9EA0A5;
    margin-bottom: 30px;
  }
  .mu_dis_time_left a {
    color: #fff;
    background: #145B94;
    padding: 2px 8px;
    border-radius: 50%;
    font-size: 12px;
  }
  /*End Full Discussion Area*/

  /*Start Groups Full Area*/
  .mu_dis_content_img_posi.mu_dis_content_img_posi3 {
    margin-bottom: -11px;
  }
  .mu_dis_time_left.mu_dis_time_left_p2 p:last-child {
    margin-bottom: 0;
  }
  /*End Groups Full Area*/

  /*Start Chart Area*/
  .mu_dis_con_img_img1.mu_dis_con_img_img1reply2 {
    margin-bottom: -30px;
  }
  .mu_dis_con_single_img.mu_dis_con_single_img_new img {
    width: 40px;
    height: auto;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-left: -20px;
    margin-top: -5px;
  }
  /*Start Chart Area*/
  /*================================ End Left Site discussion Page ============================*/

  /*================================ Start Right Site discussion Page ============================*/
  .mu_main_right_discussion {
    flex: 0 0 67%;
  }
  /*Overflow Scroll*/
  .mu_message_scroll_right {
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*Start Chat Box*/
  .mu_messages_area.mu_messages_area2 {
    width: 270px;
    padding: 23px 52px 18px 62px;
    margin-left: -100px;
  }
  .mu_chart_area.mu_chart_area2 {
    padding: 27px 10px 15px 0px;
  }
  .mu_messages.mu_messages2 {
    padding-bottom: 6px;
  }
  /*End Chat Box*/

  /*Start right site header*/
  .mu_dis_right_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-left: 1px solid #EAEDF3;
    background: #FFF;
  }
  .mu_dis_right_header_left p{
    font-size: 18px;
    color: #3E3F42;
  }
  .mu_dis_right_header_rtr{
    margin-right: 15px;
  }
  .mu_dis_right_header_rtr ul{
    display: flex;
  }
  .mu_dis_right_header_rtr img{
    width: 28px;
    height: auto;
  }

  .mu_dis_right_header_right{
    display: flex;
    align-items: center;
  }
  .mu_dis_right_header_rtl2 img{
    width: 28px;
    height: auto;
    margin-right: 30px;
  }
  .mu_dis_right_header_rtl3 img {
    width: 20px ;
    height: auto;
  }
  .mu_dis_con_single_img_reply button {
    padding: 7px;
  }
  .mu_dis_right_header_rtr a {
    margin-right: 5px;
  }
  /*End right site header*/

  /*Start Right profile Content Area*/
  .mu_right_profile_area {
    background: #FFF;
    padding: 25px 30px 10px 30px;
    border: 1px solid #EAEDF3;
  }
  .mu_dis_profile_img{
    display: flex;
    align-items: center;
  }
  .mu_dis_profile_img_img img{
    width: 40px;
    height: auto;
    margin-right: 10px;
  }
  .mu_dis_profile_img_title_flex{
    display: flex;
    align-items: center;
  }
  .mu_dis_profile_img_title_flex h5{
    font-size: 16px;
    color: #3E3F42;
    margin-right: 10px;
  }
  .mu_dis_profile_img_title_flex p{
    font-size: 9px;
    color: #FFF;
    background: #3F4254;
    padding: 2px 9px;
    border-radius: 3px;
    font-weight: 700;
  }
  .mu_dis_profile_img_title a {
    font-size: 12px;
    color: #145B94;
    border-bottom: 1px solid #145B94;
    display: inline-block;
    line-height: 10px;
  }
  .mu_dis_profile_content_rt{
    margin-bottom: 10px;
  }
  .mu_dis_profile_content_rt img{
    width: 14px;
    height: auto;
    margin-right: 7px;
  }
  .mu_dis_profile_content_rt p{
    font-size: 12px;
    color: #3E3F42;
  }
  .mu_dis_profile_content_rt{
    display: flex;
    align-items: center;
  }
  .mu_dis_profile_content_rb p{
    font-size: 12px;
    color: #3F4254;
  }
  .mu_profile_img_content_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .mu_dis_profile_cont p{
    font-size: 14px;
    color: #3E3F42;
  }
  /*End Right profile Content Area*/

  /*Start PDF Area*/
  .mu_dis_pdf_flex_box{
    display: flex;
    align-items: center;
    padding: 15px 16px;
    background: #F3F6F9;
    margin-bottom: 5px;
  }
  .mu_dis_pdf_right {
    margin-bottom: 5px;
  }
  .mu_dis_pdf_flex_left img{
    width: 20px;
    height: 20px;
    height: auto;
    margin-right: 10px;
  }
  .mu_dis_pdf_flex_left p{
    font-size: 14px;
    color: #0078BF;
  }
  .mu_dis_pdf_flex_right a{
    display: flex;
    align-items: center;
  }
  .mu_dis_pdf_flex_right a img{
    width: 20px;
    height: auto;
  }
  .mu_dis_pdf_flex_right a p{
    margin-right: 10px;
    font-size: 14px;
    color: #0078BF;
  }
  .mu_dis_pdf_flex_left{
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .mu_dis_pdf_radio{
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 50%;
    text-align: right;
  }
  .mu_dis_pdf_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mu_dis_pdf_right a {
    font-size: 12px;
    color: #145B94;
    border-bottom: 1px solid #145B94;
    display: inline-block;
    line-height: 10px;
  }
  .mu_disc_pdf_area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 15px;
  }
  /*End PDF Area*/

  /*Start message area 1*/
  .mu_dis_message_area1{
    padding: 19px 23px 8px 18px;
  }
  .mu_message_sin_content {
    background: #E4E6EF;
    padding: 10px 12px 20px 17px;
    border-radius: 0 8px 8px 8px;
    max-width: 500px;
    width: 100%;
  }
  .mu_dis_Single_message_box {
    display: flex;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .mu_message_sin_img img{
    width: 28px;
    height: auto;
    margin-right: 15px;
  }
  .mu_message_conte_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .mu_message_conte_flex p{
    font-size: 12px;
    color: #05233B;
  }
  .mu_message_sin_con_par p{
    font-size: 14px;
    color: #05233B;
  }
  .mu_dis_Single_message_box.mu_dis_message_area1_box2 {
    justify-content: flex-end;
  }
  .mu_message_sin_content.mu_message_sin_content2 {
    border-radius: 8px 0 8px 8px;
  }
  .mu_message_sin_content2{
    background: #C1D3E2;
  }
  .mu_new_messag {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .mu_new_messag a{
    font-size: 14px;
    color: #3E3F42;
    margin-right: 10px;
  }
  .mu_new_messag p{
    font-size: 12px;
    color: #9EA0A5;
  }
  /*End message area 1*/

  /*Start message area 2*/
  .mu_dis_message_area2 {
    background: #E5F1F3;
    padding: 28px 0 5px 31px;
  }
  .mu_message_sin_con_par2 p:nth-child(1){
    margin-bottom: 20px;
  }

  .mu_message_typein{
    display: flex;
    align-items: center;
  }
  .mu_message_typein_prof{
    display: flex;
    align-items: center;
  }
  .mu_message_typein_prof img{
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  .mu_message_typein_prof p{
    font-size: 14px;
    color: #05233B;
    margin-right: 10px;
  }
  .mu_message_typein_type p{
    font-size: 12px;
    color: #7E8299;
  }
  .mu_dis_message_option {
    text-align: center;
    margin-top: 50px;
  }

  .mu_dis_Single_message_box_option{
    display: flex;
    align-items: center;
  }
  .mu_dis_message_option a img{
    width: 20px;
    height: auto;
  }
  /*Start message area 2*/

  /*Start Type a message*/
  .mu_type_a_message{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    padding: 25px 30px;
  }
  .mu_type_a_message_text {
    flex: 0 0 80%;
  }
  .mu_type_a_message_text input{
    font-size: 14px;
    color: #3F4254;
    border: none;
    outline: none;
    background: #FFF;
    width: 100%;
  }
  .mu_type_a_message_img{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 20%;
  }
  .mu_type_a_message_img a img{
    width: 20px;
    height: 18px;
    margin-left: 15px;
    cursor: pointer;
  }

  /*Upload File Style*/
  .fileUpload {
    position: relative;
    overflow: hidden;
  }
  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  /*End Type a message*/
  /*================================ End Right Site discussion Page ============================*/
    /*================================================================================================
  ====                           End Discussions Page (13)(Munna)                     ====
  ================================================================================================*/


  /*================================================================================================
  ====                                    LEARNING PAGE CSS START                                  ====
  ================================================================================================*/
  .ra_science_area {
    background-color: #F3F6F9;
    padding: 0 0 24px 0;
  }
  .ra_science_main_area {
    padding-top: 20px;
  }
  .ra_science{
    background: #fff;
    border-radius: 3px;
    padding: 31px;
    box-sizing: border-box;
    border: 1px solid #F3F6F9;
  }
  /*tor area scroll start */
  .ra_science_top_calendar {
    padding-bottom: 24px;
  }
  .ra_science_top_calendar ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .ra_science_top_calendar ul li a {
    display: flex;
    align-items: center
    
  }
  .ra_science_top_calendar ul li a span {
    font-size: 14px;
    font-weight: 300;
    margin-left: 20px;
    color: #0078BF;
    text-decoration: underline #0078BF;
  }
  .ra_science_top_calendar ul li a img {
    width: 10px;
    height: auto;
    object-fit: cover;
    top: 0px;
    margin-left: 6px;
  }
  /*tor area scroll end */
  
  .ra_jackson_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ra_jackson_top .dropdown-menu.adjust {
    top: 13px !important;
    left: 5px !important;
    width: 100%;
	min-width:329px;
  }
  .ra_jackson_top .dropdown-item.width-two {
    width: 100%;
  }
  .ra_jackson_top .header-profile-dropdown::before {
    width: 0;
    height: 0;
  }
  .ra_jackson_top .header-profile-dropdown::after {
    width: 0;
    height: 0;
  }
  .ra_jackson_top .profile-text h5 {
    font-size: 20px;
    line-height: 1.8;
    font-weight: 300;
  }
  .ra_jackson_top .profile-text p {
    font-size: 14px;
  }
  .ra_jackson_top .profile-image img {
    width: 60px;
    height: 60px;
    border-radius: 0;
    margin-right: 10px;
  }
  .ra_jackson_top .down-icon-two img {
    right: -39px;
    top: 18px;
  }
  .ra_jackson_top  .header-profile-dropdown {
    padding: 0 0px 0 0px;
    position: relative;
  }
  
  
  /*Btn area start*/
  .ra_jackson_top .contact-button a {
    font-size: 14px;
    color: #0078BF;
    border: 1px solid #0078BF;
    padding: 7px 25px;
    border-radius: 26px;
    transition: .3s;
  }
  .ra_jackson_top .contact-button a:hover{
    color: #fff;
    background-color:#0078BF; 
  }
  .ra_jackson_btn_area {
    display: flex;
    align-items: center;
  }
  
  .ra_jackson_top .contact-button2 a {
    color: #fff;
    background-color: #0078BF;
    margin-left: 20px;
  }
  .ra_jackson_top .contact-button2 a:hover{
    background-color: #fff;
    color: #0078BF;
  }

  
  
  /*  Since Progress area start   */
  .ra_science_progress_area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .ra_science_progress_area .slider-course-progress-left p {
    font-size: 14px;
    color: #3F4254;
    font-weight: 300;
  }
  .ra_science_content_area p {
    padding-top: 15px;
    color: #3F4254;
    font-weight: 300;
    font-family: Avenir;
    font-size: 14px;
  }
  .ra_science_content_area p a{
    text-decoration: underline #0078BF;
    color: #0078BF;
  }
  .ra_science_progress_area .myclassroom-single-left-cross-progress {
    width: 247px;
    height: 7px;
    background: #E4E6EF;
    border-radius: 7px;
    margin: 8px 0;
  }
  .myclassroom-single-left-cross-progress.myclassroom-single-left-cross-progress_extra {
    background: #05233B;
  }
  .ra_science_progress_area .barra-nivel {
    height: 7px;
    background: #00A2A7;
  }
  .ra_science_progress_area .course-progress-right h5, .course-progress-right span {
    font-weight: 300;
    font-size: 12px;
    color: #181C32;
    text-transform: inherit;
    line-height: 9px;
  }
  .ra_science_progress_area .slider-course-progress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .ra_science_progress_area .course-progress-right {
    margin-left: 12px;
    margin-top:0px;
  }
  .ra_science_progress_area .course-progress-right_ex_ex {
    margin-top: 22px !important;
  }
  .ra_science_progress_area .course-progress-left p {
    font-size: 14px;
    font-weight: 300;
  }
  .ra_repetable_area .single-top-progress-heading-text p {
    font-weight: 300;
  }
  .ra_science_progress_area .ra_bottom_area_science {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .ra_bottom_area_science img {
    width: 12px;
    height: auto;
    object-fit: cover;
    margin-right: 7px;
  }
  .ra_bottom_area_science_ex{
    margin-right: 10px;
  }
  
  
  /* =================        Repetable area start      =================   */
  .ra_repetable_area{
    background-color: #F3F6F9;
  }
  .ra_single_repetable_area{

  }
  .ra_repetable_area .single-top-progress-area {
    flex: 0 0 24%;
  }
  .ra_repetable_area .top-progress-area {
    padding-bottom: 17px;
  }
  .ra_repetable_area .single-top-progress-area{

  }
  .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(1){
    background:url('/media/learning/bg-progress1.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
  .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(2){
    background:url('/media/learning/bg-progress2.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
  .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(3){
    background:url('/media/learning/bg-progress3.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
  }
  .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(4){
    background:url('/media/learning/bg-progress4.png') no-repeat scroll 0 0 / cover;
    border: none !important; 
    color: #181C32;
  }
  .ra_repetable_area .single-top-progress-area:hover {

  }
  .single-top-progress-area_ex .single-top-progress-heading-text p{
    color:#0B0000 !important; 
  }
  .single-top-progress-area_ex .single-top-progress-count-text h5 {
    font-size: 26px;
    color: #0B0000;
    padding: 5px 5px;
    border-radius: 5px;
    margin: 0;
  }
  .single-top-progress-area_ex .single-top-progress-count-text h5 span{
    font-size: 14px !important;
    color: #0B0000;
    font-weight: normal;
  }
  .single-top-progress-count-text_ex h5{
    display: inline-block;
    background-color: #fff;
    font-size: 12px !important;
  }
  .single-top-progress-area.single-top-progress-area_ex {
    padding: 12px 1px 23px 16px !important;
  }
  
  
  /* =================        Repetable area End        =================   */
  
  
  
  
  
  
  /* =================      Slider Resources css area start       ===================== */
  .ra_resources_area {
    background-color: #F3F6F9;
    padding-bottom: 37px;
  }
  .ra_resources_area .myclassroom-area{
    padding: 20px 20px;
  }
  
  
  
  /* Resources Slide area start */
  .ra_resources_slider_area_ex{
    padding-left: 30px;
  }
  .ra_resources_slider_area{
    position: relative;
  }
  .ra_resources_slider_area::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 28px;
    background: url('/media/learning/affff.png') no-repeat scroll 0 0 / cover;
    z-index: 1;
  }
  .ra_resources_slider_area_single_main {
    border: 1px solid #E4E6EF;
    border-radius: 4px;
    position: relative;
  }
  .ra_resources_slider_area_top_single {
    display: flex;
    justify-content: space-between;
  }
  .ra_resources_slider_area_top {
    padding: 10px 15px;
  }
  .ra_resources_area .myclassroom-header{
    padding-bottom: 20px;
  }
  .ra_resources_slider_area_butttom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #B2D8EF;
    padding: 7px 16px;
  }
  .ra_resources_slider_area_butttom_man {
    display: flex;
    align-items: center;
  }
  .ra_resources_slider_area_top_single img{
    width: 45px !important;
    height: auto;
    object-fit: cover;
  }
  .ra_resources_slider_area_butttom_man img {
    width: 25px !important;
    height: auto;
    object-fit: cover;
    margin-right: 10px;
  }
  .ra_resources_area .myclassroom-area {
    background-color: #fff;
  }
  .ra_resources_content .single-top-progress-area:hover {
    border: none;
  }
  .ra_resources_slider_pdf {
    text-align: right;
  }
  .ra_resources_slider_area_top_single ul li {
    font-size: 9px;
  }
  .ra_resources_slider_area_middle h2 {
    font-size: 15px;
    font-weight: 300;
    padding: 20px 0 10px 0;
    color: #3E3F42;
  }
  .ra_resources_slider_area_middle p {
    font-size: 12px;
    font-weight: 300;
    color: #3E3F42;
    padding-right: 20px;
  }
  .ra_resources_slider_area_middle {
    height: 100px;
  }
  .ra_resources_slider_pdf a {
    color: #3F4254;
    font-weight: normal;
    display: inline-block;
    text-transform: uppercase;
    text-transform: inherit;
    font-size: 9px;
    padding-top: 10px;
  }
  .ra_single_man h6{
    font-size: 11px;
    color: #05233B;
    font-weight: normal;
  }
  .ra_single_man p{
    font-size: 9px;
    color: #05233B;
    font-weight: normal;
  }
  
  .ra_resources_area_bottom_text{
    text-align: right;
    padding-top: 60px;
  }
  .ra_resources_area_bottom_text a {
    color: #0078BF;
    font-size: 14px;
    font-weight: 300;
  }
  
  
  /*Text right area start */
  .ra_resources_right_area{
    background-color: #fff;
    padding: 25px 16px;
    box-sizing: border-box;
  }
  .ra_resources_right_area_single_item{
    padding-bottom: 30px;
    position: relative;
  }
  .ra_resources_right_area_single_item::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: -45px;
    width: 128%;
    height: 1px;
    background-color: #EAEDF3;
  }
  .ra_resources_right_area_single_item:last-child:after { 
    content: ""; 
    position: absolute;
    background-color: transparent;
  }
  
  .ra_resources_right_area_single_item_img {
    display: flex;
    align-items: center;
  }
  .ra_resources_top h4 {
    font-size: 16px;
    color: #3F4254;
    font-weight: 300;
  }
  .ra_resources_top p {
    font-size: 12px;
    padding-top: 15px;
    font-weight: 300;
  }
  .ra_resources_right_area_single_item_img img {
    width: 34px;
    margin-right: 15px;
  }
  .ra_resources_right_area_single_item_img p{
    font-size: 14px;
    font-weight: 300;
  }
  .ra_resources_right_area_single_item_img p:nth-child(2) {
    font-size: 12px;
    padding-top: 7px;
  }
  .ra_resources_bottom_area{
    text-align: center;
  }
  .ra_resources_bottom_area a {
    font-size: 14px;
    font-weight: 300;
    color: #0078BF;
  }
  /* =================       Resources css area end       ===================== */
  
  
  /* =================       Resources css area end       ===================== */
  
  
  /*================== Start Dropdown Menu Area ==================*/
  .dropdown_search_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mu_dropdown_menu_content {
    flex: 0 0 55%;
  }
  .mu_search_bar{
    flex: 0 0 33%
  }
  
  .mu_dropdown_menu_area {
    background: #F3F6F9;
    padding-top: 10px;
  }
  .mu_dropdown_menu_content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mu_dropdown_menu_content ul li{
    color: #3F4254;
    margin-right: 20px;
    font-size: 12px;
  }
  .mu_dropdown_menu_content ul li a,.mu_dropdown_menu_content ul li button.menu-drop {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    text-transform: capitalize;
    margin: 18px 0;
    position: relative;
  }
  .mu_dropdown_menu_content ul li button.menu-drop {
    padding-right: 30px;
  }
  .mu_dropdown_menu_contentt ul li button.menu-drop:hover {
    color: #0078BF;
    border-bottom: 3px solid #0078BF;
  }
  .mu_dropdown_menu_content ul li a.active,.mu_dropdown_menu_content ul li a:hover {
    color: #0078BF;
  }

  .dropdown-menu.new-menu.show.ra_custom_checkbox_area_up{
    top: -14px !important;
    position: absolute !important;
  }

  .mu_dropdown_menu_content ul li a img,.mu_dropdown_menu_content ul li button.menu-drop img {
    height: 14px;
    width: auto;
    margin-top: -7px;
    margin-right: 2px;
  }
  .mu_dropdown_menu_content ul li a span {
    background: #145B94;
    font-weight: 400;
    font-size: 10px;
    color: #FFF;
    padding: 1px 5px;
    border-radius: 15px;
    width: 10px;
    margin-left: 5px;
  }
  
  
  
  /* Start View icons*/
  .mu_view_icons ul{
    display: flex;
    align-items: center;
  }
  .mu_view_icons ul li{
    margin: 0;
    margin-right: 20px;
    font-size: 14px;
    color: #3F4254;
  }
  .mu_view_icons ul li:last-child{
    margin-right: 0;
  }
  .mu_view_icons ul li a{
    display: flex;
    align-items: center;
  }
  .view_img_1{
    width: 11px;
  }
  .view_img_2{
    width: 17px;
  }
  /* End View icons*/
  
  
  /*   Radio btn area start   */
  /* custom checkbox */
  .custom_checkbox_area label span {
    font-size: 14px;
    font-weight: 300;
  }
  .recommendation_checkbox label sapn {
    cursor: pointer;
  }
  .custom_checkbox_area {
    padding: 10px 17px;
  }
  .custom_checkbox_area label{
    display: flex;
    align-items: center;
  }
  /* custom checkbox */
  .custom_checkbox_area input[type="radio"] {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 2px solid #3f51b5;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  .recommendation_checkbox input[type="checkbox"] {
    height: 16px;
    width: 16px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 1px solid #D8DCE6;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  .custom_checkbox_area input[type="radio"]:checked,.recommendation_checkbox input[type="checkbox"]:checked {
    border: 2px solid #3f51b5;
    background: #3f51b5;
  }
  
  .custom_checkbox_area input[type="radio"]:checked::before,.custom_checkbox_area input[type="radio"]:checked::after,.recommendation_checkbox input[type="checkbox"]:checked::before,.recommendation_checkbox input[type="checkbox"]:checked::after  {
    content: "";
    position: absolute;
    height: 2px;
    background: #fff;
  }
  .custom_checkbox_area input[type="radio"]:checked::before {
    width: 8px;
    top: 8px;
    left: 0px;
    transform: rotate(44deg);
  }
  .recommendation_checkbox input[type="checkbox"]:checked::before {
    width: 7px;
    top: 6px;
    left: 0px;
    transform: rotate(44deg);
  }
  .custom_checkbox_area input[type="radio"]:checked::after {
    width: 11px;
    top: 6px;
    left: 4px;
    transform: rotate(-50deg);
  }
  .recommendation_checkbox input[type="checkbox"]:checked::after {
    width: 10px;
    top: 5px;
    left: 3px;
    transform: rotate(-50deg);
  }
  .custom_checkbox_area input[type="radio"]:focus,.recommendation_checkbox input[type="checkbox"]:focus {
    outline: none !important;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow:none !important;
  }
  
  /*Resources css area start header */
  .ra_courch_area{
    padding: 0px 0 45px 0;
  }
  .ra_courch_area .barra-nivel {
    background: #00A2A7;
  }
  .ra_courch_area_header h2 {
    font-size: 18px;
    color: #05233B;
    font-weight: 300;
  }
  .ra_courch_area .mu_dropdown_menu_content ul li:last-child a{
    display: flex;
    align-items: center;
    display: inline-block;
    color: #0078BF;
    }m
    .ra_courch_area .mu_dropdown_menu_content ul li:last-child img{
      width: 12px;
      height: auto;
      margin-right: 5px;
      object-fit: cover;
    }
    .ra_courch_area .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      background: transparent;
    }


    /*  Ra_courch prpgress area start  */
    .ra_courch_single_item_area {
      display: flex;
      background-color: #fff;
      align-items: center;
      box-sizing: border-box;
      padding: 14px 24px;
      border-radius: 5px;
      border: 1px solid #F7F7F7;
      margin-bottom: 12px;
      justify-content: space-between;
    }
    .ra_courch_single_item_left{
      flex: 0 0 55%;
    }
    .ra_courch_single_item_middle{
      flex: 0 0 20%;
    }
    .ra_courch_single_item_right{
      flex: 0 0 25%;
    }
    /*Top area start */
    .ra_courch_single_item_left_sub_main{
      display: flex;
      align-items: center;
    }
    .ra_courch_single_item_left_sub_main img {
      width: 30px;
      height: auto;
      object-fit: cover;
      margin-right: 20px;
    }
    .ra_courch_single_item_left_content h5 {
      font-size: 18px;
      font-weight: 300;
    }
    .ra_courch_single_item_left_content p {
      font-size: 14px;
      color: #3F4254;
    }

    /*middle area start */
    .ra_courch_ra_progress_area {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /*More area start */
    .ra_courch_more_area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .ra_courch_more_area .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
      margin-right: 20px;
      transition: .3s;
    }
    .ra_courch_more_area .contact-button a:hover{
      color: #fff;
      background-color: #0078BF;
    }
    .ra_courch_more_area img {
      width: auto;
      height: 4px;
      cursor: pointer;
    }
    .ra_courch_ra_progress_area h5 {
      font-weight: 300;
      font-size: 14px;
    }

    /* =================       Resources css area end       ===================== */

    /* =================       Calender css area Start       ===================== */

    .ra_calender_area{

    }
    .ra_calender_area .upcoming-heading-content {
      flex-wrap: wrap;
    }
/*     .ra_day_tody_area_sub .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
      margin: 0 10px;
      padding: 6px 23px;
      font-size: 14px;
      border-radius: 50px;
      transition: .3s;
      } */
      .contact-button button.change {
        background: none;
      }
      .contact-button button.change img {
        width: 14px;
        height: auto;
        margin: 0 15px;
      }
      .ra_day_tody_area_sub .contact-button a:hover{
        background-color:#0078BF;
        color: #fff; 
      }
      .ra_calender_area .upcoming-heading-content {
        background: #F3F6F9;
        padding: 15px 0px;
      }
      .ra_day_tody_area_sub {
        display: flex;
        align-items: center;
        margin-top: -5px;
      }
      .ra_calender_area .btn-group.btn-group-toggle {
        background: #fff;
      }
      .ra_calender_area .table-active, .table-active > td, .table-active > th {
        background-color: #E4E6EF;
      }
      .ra_calender_area .table thead th {
        font-size: 14px;
        color: #3F4254;
        font-weight: normal;
        padding: 10px 20px 10px 20px;
      }
      .ra_calender_area .upcoming-heading-content p {
        font-size: 15px;
      }
      .upcoming-area.ra_calender_area.upcoming-area tbody td {
        height: 61px;
      }
      .ra_calender_area .table td, .table th {
       border-bottom: 2px solid #dee2e6; 
       border-top: 0px solid transparent; 
     }
     .ra_calender_area.upcoming-area tbody {
      border-top: 4px solid #EAEDF3;
    }
    .ra_calender_area.upcoming-area tbody td{
      border-right: 15px solid #EAEDF3;
      height: 400px;
      padding: 0;
      border-bottom: 1px solid #EAEDF3;
      position: relative;
    }
    .ra_calender_area.upcoming-area tbody td:last-child{
      border-right: 1px solid #EAEDF3;
    }
    .ra_calender_area.upcoming-area {
      padding-bottom: 25px;
    }
    
    
    /*Checkbox area start */
    .ra_calender_area .ra_custom_checkbox_area_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ra_calender_area .ra_custom_checkbox_area_item ul{
      display: flex;
      align-items: center;
    }
    .ra_custom_checkbox_area_item label {
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
    }
    .ra_calender_area .ra_custom_checkbox_area_item ul li a{
      font-size: 25px;
      margin-left: 50px;
      color: #A6ADB1;
      transition: .3s;
    }
    .ra_calender_area .ra_custom_checkbox_area_item ul li a:hover{
      color: #0078BF;
    }
    
    .ra_calender_area.upcoming-area .monday-first-card {
      background-color: #b2d8ef;
      border-left: 2px solid #387ebe;
      padding-left: 10px;
      padding-bottom: 5px;
      padding-top: 7px;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 9999999;
    }
    
    /* =================       Calender css area end       ===================== */
    
  /*================================================================================================
    ====                                    LEARNING PAGE CSS End                                  ====
    ================================================================================================*/


  /*================================================================================================
    ====                                    Schedule-W PAGE CSS START                                  ====
    ================================================================================================*/

    /*  RA-Event area start   */
    .ra_event_area{
      padding-bottom: 10px;
    }
    .upcoming-heading-content.ra_upcoming_heading {
      padding-left: 0;
    }
    /*Top btn area start */
    .ra_event_right_btn {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
    }
    .ra_event_right_btn .contact-button a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      border: 1px solid #0078BF;
      padding: 6px 25px;
      border-radius: 40px;
      transition: .3s;
    }
    .ra_event_right_btn .contact-button a:hover{
      color: #fff;
      background-color: #0078BF;
    }
    /*Slider area start */
    .ra_event_main_area h2 {
      padding-bottom: 27px;
      font-weight: 300;
      font-size: 20px;
    }
    .ra_event_single_slider_area {
      background: #C8CBDE4F;
      padding: 24px 8px 24px 20px;
      border-left: 7px solid #C8CBDE;
      display: flex;
      align-items: flex-start;
    }
    .ra_event_main_area_slider_items{
      position: relative;
    }
    .ra_event_main_area_slider_items::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 20px;
      background: url('/media/learning/affff.png') no-repeat scroll 0 0 / cover;
      z-index: 1;
    }

    .ra_event_single_slider_area_top h5 {
      font-size: 16px;
      font-weight: 500;
      color: #05233B;
      padding: 10px 0;
    }
    .ra_event_single_slider_area_top h4 {
      font-weight: 300;
      font-size: 30px;
      padding-bottom: 10px;
    }

    .ra_event_single_slider_area_bottom {
      margin-left: 20px;
    }
    .ra_event_single_slider_area_bottom h1 {
      font-size: 14px;
      font-weight: 300;
    }
    .ra_event_single_slider_area_bottom p {
      font-size: 12px;
      margin-top: 10px;
    }
    .ra_event_single_slider_area1 {
      border-left: 7px solid #A4BBCC !important;
      background: #A4BBCC73 !important;
    }
    .ra_event_single_slider_area2 {
      background: #C8CBDE4F !important;
      border-left: 7px solid #C8CBDE !important;
    }
    .ra_event_single_slider_area3 {
      border-left: 7px solid #1A6A9A;
      background: #CAE2F1;
    }
    .ra_event_single_slider_area4 {
      border-left: 7px solid #00A2A7;
      background: #00A2A757;
    }
    /* =================================    Day Area start  ==================================== */
    .ra_day_view_area{

    }
    .ra_day_view_area .upcoming-heading-content {
      justify-content: flex-start;
    }
    .upcoming-heading-content {
      background: #F3F6F9;
      padding: 5px 15px;
    }
    .ra_day_view_area .contact-button a {
      margin: 0 6px;
      transition: .3s;
    }

    .ra_day_view_area .contact-button a:hover{
      color: #fff;
      background-color:#0078BF; 
    }
    .ra_day_view_area .btn-group, .btn-group-vertical {
      background: #fff;
      margin-left: 50px;
      display: flex;
      align-items: center;
    }
    .ra_day_view_area .upcoming-heading-content p {
      font-weight: 300;
      font-size: 14px;
      color: #05233B;
      margin-right: 10px;
    }
    .ra_day_view_area .btn-group .btn {
      border: 1px solid #EAEDF3;
      background-color: transparent;
    }
    .ra_day_view_area .upcoming-heading-content {
      padding: 36px 0;
    }
    /* ===========  Teacher list area start  ========== */
    .ra_teacher_list_area_header {
      display: flex;
      align-items: center;
      background: #E4E6EF;
      padding: 13px 0px 13px 30px;
      border: 1px solid #F7F7F7;
    }
    .ra_teacher_list_area_header_single_item h2 {
      color: #3F4254;
      font-size: 14px;
      font-weight: 300;
    }
    /*studernt list */
    .ra_teacher_list_area_body {
      display: flex;
      align-items: center;
      border-top: 5px solid #F3F6F9;
      background: #fff;
      padding: 22px 10px 22px 41px;
      border-radius: 7px;
    }
    .re_body_list_item1,.re_header_list_item1 {
      flex: 0 0 35%;
    }
    .re_body_list_item2,.re_header_list_item2 {
      flex: 0 0 20%;
    }
    .re_body_list_item3,.re_header_list_item3 {
      flex: 0 0 25%;
    }
    .re_body_list_item4,.re_header_list_item4 {
      flex: 0 0 10%;
    }
    .ra_teacher_list_area_header_single_item_body.re_body_list_item1 {
      display: flex;
      align-items: center;
    }
    .ra_teacher_list_area_header_single_item_body.re_body_list_item2 {
      display: flex;
      align-items: center;
    }
    .ra_teacher_list_area_header_single_item_body.re_body_list_item2 img {
      margin-right: 10px;
      height: 45px;
      width: auto;
      object-fit: cover;
    }
    .ra_teacher_list_area_header_single_item_body.re_body_list_item1 img {
      height: 45px;
      width: auto;
      object-fit: cover;
      margin-right: 9px;
    }
    .ra_teacher_list_area_header_single_item_body.re_body_list_item1 p {
      font-size: 18px;
      color: #181C32;
      margin-left: 11px;
      font-weight: 300;
    }
    .ra_teacher_list_area_header_single_item_body_single_man h6 {
      font-size: 14px;
      font-weight: 300;
    }
    .ra_teacher_list_area_header_single_item_body_single_man p {
      font-size: 12px;
      color: #3F4254;
      padding-top: 7px;
    }
    .ra_teacher_list_area_header_single_item_body p {
      font-size: 14px;
      font-weight: 300;
      color: #3F4254;
    }

    .ra_schedule_calender_area .ra_monday-first-card_ex_b {
      border-left: 4px solid #A4BBCC;
      background-color: #A4BBCC66;
    }
    .ra_schedule_calender_area .ra_monday-first-card_ex_s {
      border-left: 4px solid #ADDEDC;
      background-color: #ADDEDC73;
    }
    .ra_schedule_calender_area.upcoming-area tbody td {
      border-right: 15px solid #EAEDF3;
      height: 60px;
      padding: 0;
      border-bottom: 1px solid #EAEDF3;
      position: relative;
    }
    .ra_schedule_calender_area.upcoming-area tbody td:last-child{
      border-right: 0px solid #EAEDF3;
    }
    .ra_schedule_calender_area .upcoming-heading-content p,.ra_upcoming_heading p {
      font-weight: 300;
      font-size: 16px;
      color: #05233B;
    }
    .ra_upcoming_heading p {
      padding-bottom: 10px;
    }
    .ra_schedule_calender_area .table thead th {
      font-weight: 300;
      padding: 12px 40px;
      font-size: 14px;
    }
    .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li{
      margin-left: 30px;
    }
    .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a{
      font-size: 20px;
      color: #A6ADB1;
      display: inline-block;
      transition: .5s;
    }
    .ra_schedule_calender_area .ra_calender_area_bottom_checkbox ul li a:hover{
      color: #0078BF;
    }
    .ra_side_area_active {
      background-color: #0078BF;
      padding: 5px 12px;
      border-radius: 5px;
      font-size: 14px !important;
      color: #fff !important;
    }
    .ra_schedule_calender_area.upcoming-area {
      background-color: #f3f6f9;
      padding-bottom: 64px;
      padding-top: 28px;
    }
  /*================================================================================================
    ====                                    Schedule-W PAGE CSS END                                  ====
    ================================================================================================*/


  /* ================================================================================================
    ====                               Schedule-month PAGE CSS START                            ====
    ================================================================================================ */
    /*  Windows area start   */
    .ra_windows_main_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    .ra_calender_main_area {
      padding: 0px 0 59px 0;
    }
    .ra_calender_left_area_main_area_btn .contact-button a {
      font-weight: 300;
      font-size: 14px;
      color: #0078BF;
      border: 1px solid #0078BF;
      padding: 5px 13px;
      border-radius: 30px;
      max-width: 230px;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 27px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .ra_calender_left_area_main_area {
      background-color: #fff;
      padding: 30px 15px 70px 15px;
      height: 100%;
    }
    .ra_calender_left_area_sub_area {
      padding-left: 15px;
    }
    .ra_windows_main_area .view_img_1 {
      width: 16px;
    }
    .ra_main_left_color_select .custom-control-label {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #3E3F42;
      font-weight: 300;
      cursor: pointer;
    }
    #dropdownMenuButton {
      font-size: 14px;
      font-weight: 300;
      color: #181C32;
    }
    .ra_main_left_color_select .custom-control-label img {
      margin-right: 10px;
      margin-top: 2px;
      margin: 2px 10px 0 10px;
    }
    .ra_calender_left_area_sub_area h3 {
      font-size: 14px;
      color: #3F4254;
      font-weight: 500;
      padding-bottom: 23px;
      padding-top: 3px;
    }
    .custom-control-label.re_custom-control-label_single_man img {
      width: 24px;
    }
    .ra_windows_main_area .ra_main_left_color_select {
      display: flex;
      align-items: center;
    }
    .ra_windows_main_area .custom-control.custom-checkbox.mb-3 {
      display: flex;
      align-items: center;
    }


    /*Right site area start */
    .ra_calender_main_area .upcoming-heading-content .contact-button a {
      background-color: transparent;
      color: #0078BF;
      margin: 0 10px;
      padding: 6px 23px;
      font-size: 14px;
      border-radius: 50px;
    }
    .upcoming-heading-content .contact-button a:hover{
      color: #fff;
      border: 1px solid #0078BF;
      background-color: #0078BF;
    }
    .ra_calender_left_area.upcoming-area {
      padding-bottom: 0px;
    }
    .ra_calender_left_area.upcoming-area tbody td {
      height: 170px;
      box-sizing: border-box;
      padding: 20px 0;
      border-bottom: 1px solid #EAEDF3;
      position: relative;
    }
    .ra_calender_left_area .table-active, .table-active > td, .table-active > th {
      background-color:#fff;
    }
    .ra_calender_left_area .table thead th {
      border-bottom: 1px solid #dee2e6;
    }
    .ra_calender_left_area .monday-first-card {
      background-color: #b2d8ef;
      border-left: 2px solid #387ebe;
      padding-left: 10px;
      padding-bottom: 5px;
      padding-top: 7px;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 9999999;
      top: 40%;
    }
    .ra_calender_left_area.upcoming-area tbody td span {
      padding: 8px 17px;
      font-size: 12px;
      color: #3E3F42;
      font-weight: 300;
    }
    .ra_calender_left_area.upcoming-area tr th{
      text-align: center;
    }
    .ra_calender_left_area.upcoming-area tr td {
      text-align: left;
    }
    .ra_calender_left_area.upcoming-area .upcoming-heading-content {
      background: #fff;
      padding: 20px 30px;
      justify-content: space-between;
      border: 1px solid #EAEDF3;
    }
    .ra_calender_left_area .table thead th {
      border-bottom: 1px solid #dee2e6;
      font-weight: 300;
      font-size: 12px;
      padding: 14px 41px;
    }
    .ra_calender_left_area .table {
      margin-bottom: 0rem;
    }
    .ra_calender_left_area.upcoming-area .upcoming-heading-content p {
      font-weight: 300;
      font-size: 18px;
      color: #05233B;
      margin-right: 10px;
    }
    .ra_badami_color {
      background-color: green;
    }

    /*Color system area start */
    .monday-first-card.ra_badami_color {
      background: #E6772B54;
      border-left: 3px solid #E6772B;
      line-height: 2;
    }
    .monday-first-card.ra_badami_color h6 {
      padding-bottom: 17px;
      font-size: 14px;
      font-weight: 300;
    }
    .monday-first-card.ra_badami_color2 {
      background: #E6772B54;
      border-left: 3px solid #E6772B05;
      color: #E6772B05;
      padding: 34px 0;
    }
    .monday-first-card.ra_skyblue_color {
      background-color: #ADDEDC4F;
      border-left: 3px solid #ADDEDC;
    }
    .monday-first-card.defarent_angle_table {
      background-color: #F3F6F9;
      top: 40%;
      border-left: 3px solid #F3F6F9;
      border-bottom: 1px solid #fff;
    }
    .monday-first-card.ra_skyblue_color.ra_skyblue_color_ex {
      padding: 7px 1px 7px 3px;
      top: 60%;
    }
    .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno {
      top: 71%;
      background: transparent;
      border-left: 3px solid transparent;
    }
    .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a {
      color: #3F4254;
      font-size: 12px;
      font-weight: normal;
    }
    .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno a:hover{
      color: #000;
    } 
    .monday-first-card.ra_skyblue_color_extra_nibo_tar_jonno.ex_up{
      top: 77%;
    }

    .ra_calender_left_area.upcoming-area .upcoming-heading-content .contact-button a {
      background-color: #0078BF;
      color: #fff;
      padding: 6px 50px;
    }
    .ra_calender_left_area.upcoming-area .upcoming-heading-content .contact-button a:hover{
      background-color: #fff;
      color: #0078BF;
    }

  /* ================================================================================================
    ====                               Schedule-month PAGE CSS End                            ====
    ================================================================================================ */ 


    /*===================================================================================
  ====                      Start Progress list pages css                        ====
  =================================================================================== */
  /*list_resource_pages_main_area*/
  .list_document_resource_main {
    margin-top: 5px;
    overflow: hidden;
  }
  .list_check_sortable_main {
    background: #E4E6EF;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .list_check {
    display: flex;
  }
  .list_prog_heading {
    margin-top: 35px;
  }
  .list_check .mu-form-group label::before {
    background-color: #FFF;
    border: 1px solid #7E8299;
    padding: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  .list_check .mu-form-group input:checked + label::after {
    top: 8px;
    left: 6.5px;
    width: 6px;
    height: 12px;
    border: solid #707070;
    border-width: 0 2px 2px 0;
  }
  .li_star_design a img {
    width: 15px;
    height: auto;
  }
  .li_arrow_sort button img {
    max-width: 12px;
    width: 100%;
    height: auto;
  }
  .li_arrow_sort {
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    margin-left: 13px;
  }
  .single_sortable_design {
    display: flex;
    align-items: center;
  }
  .single_sortable_design h4{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #3F4254;
  }
  .list_top_check {
    flex: 0 0 10%;
  }
  .li_sortable_source_area {
    flex: 0 0 30%;
  }
  .li_sortable_source_middle_area {
    flex: 0 0 30%;
  }
  .li_sortable_source_right_area {
    flex: 0 0 30%;
    padding-right: 50px;
  }
  .li_sortable_source_right_area .single_sortable_design {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .li_arrow_sort button{
    background: transparent;
  }
  .li_arrow_sort button:nth-child(1) {
    display: block;
    margin-bottom: -2px;
  }
  .li_arrow_sort button:nth-child(2) {
    display: block;
    margin-top: -2px;
  }
  .left_dc_img img {
    max-width: 46px;
    width: 100%;
    height: auto;
  }
  .right_dc_img a img {
    max-width: 15px;
    width: 100%;
    height: auto;
    margin-right: 4px;
  }
  .right_dc_img a{
    color: #080F1E;
    font-weight: 300;
    font-size: 14px;
  }
  .right_dc_img a span{
    color: #0078BF;
    text-decoration: underline #0078BF;
  }
  .right_dc_img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .dc_single_items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #FFF;
    padding: 17px 16px 25px;
    border: 1px solid #F7F7F7;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 5px;
  }
  .dc_middle_txt {
    display: flex;
    align-items: center;
    position: relative;
  }
  .dc_middle_txt a {
    font-size: 9px;
    font-weight: 500;
    color: #FFF;
    background: #00A2A7;
    padding: 3px 9px;
    display: inline-block;
    position: absolute;
    left: -5px;
    top: -1px;
  }
  .dc_middle_txt p {
    color: #3F4254;
    font-weight: 400;
    font-size: 12px;
    padding-left: 37px;
  }
  .dc_dots_area .down-icon {
    position: relative;
  }
  .dc_dots_area .down-icon img {
    width: 20px;
    height: auto;
    right: -35px;
    top: -7px;
    position: absolute;
  }
  .right_dc_date_time {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 0 12%;
  }
  .dc_dots_area button {
    background: none;
  }
  .right_dc_date_time > a {
    font-size: 15px;
    color: #3F4254;
    font-weight: 300;
    display: inline-block;
    margin-top: 2.5px;
  }
  .left_dc_img {
    margin-right: 27px;
  }
  .dc_check_star {
    flex: 0 0 6%;
  }
  .dc_jackson_img_area {
    display: flex;
    flex: 0 0 34%;
    align-items: center;
  }
  .right_dc_img a:nth-child(2) span{
    font-weight: 400;
    font-size: 12px;
  }
  .right_dc_img a:nth-child(1) {
    margin-bottom: 10px;
  }
  .right_dc_img a:nth-child(2) {
    margin-top: 10px;
  }
  .dc_dots_area {
    position: relative;
  }
  .dc_dots_area .dropdown-menu {
    min-width: 190px;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #E4E6EF;
    padding: 5px 0 !important;
    left: -130px !important;
    top: 10px !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  .dc_dots_area .dropdown-menu a{
    font-size: 14px;
    font-weight: 400;
  }
  .document_pagenations_area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .left_pg_area a {
    color: #3F4254;
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
  }
  .right_pg_area .page-item .page-link img {
    max-width: 10px;
    width: 100%;
    height: auto;
    display: block;
  }
  .right_pg_area .pagination {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }
  .right_pg_area .page-link {
    padding: .70rem 1rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  .right_pg_area .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }
  .right_pg_area ul li{
    margin: 0 12px;
  }
  .right_pg_area .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
  }
  .list_prog_heading .mu_dropdown_menu_content ul li:last-child {
    display: flex;
    align-items: center;
  }
  .list_prog_heading .mu_dropdown_menu_content ul li:last-child img {
    width: 12px;
    height: auto;
    margin-right: 5px;
    object-fit: cover;
  }
  .list_prog_heading .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
    display: none;
  }

/*===================================================================================
  ====                      End Progress list pages css                        ====
  =================================================================================== */


/* ================================================================================================
    ====                               Trecking PAGE CSS Start                            ====
    ================================================================================================ */  
    .ra_sortable_area {
      padding-bottom: 40px;
    }
    .ra_sortable_area .mu_dropdown_menu_content ul li:nth-child(1){
      display: none;
    }
    .ra_sortable_area .mu_dropdown_menu_content ul li:nth-child(2){
      display: none;
    }
    .ra_process_tracking_area {
      padding: 25px;
    }
    .ra_process_tracking_area .pt_view_main_area{
      margin-bottom: 10px;
      padding-top: 30px;
    }
    .ra_process_tracking_area_header {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .ra_jackson_btn_area a {
      color: #0078BF;
      background: #fff;
      border: 1px solid #0078BF;
      font-size: 14px;
      padding: 7px 27px;
      border-radius: 50px;
      margin-left: 15px;
    }
    .ra_process_tracking_area_header_search_width {
      max-width: 585px;
      width: 100%;
    }

    .ra_process_tracking_area_header_search {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ra_process_tracking_area h3 {
      color: #05233B;
      font-size: 20px;
      font-weight: 300;
    }
    .ra_process_tracking_area h4 {
      color: #05233B;
      font-size: 18px;
      font-weight: 300;
    }
    .ra_process_tracking_single h3 {
      padding-bottom: 14px;
      font-weight: 300;
      font-size: 18px;
    }

    .ra_process_tracking_area_body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      margin-top: 27px;
      padding: 12px 0 27px 0;
    }
    .ra_process_tracking_single {
      flex: 0 0 33.33%;
      text-align: center;
      position: relative;
    }
    .ra_process_tracking_single::after {
      content: "";
      position: absolute;
      background-color: #7070703d;
      width: 1px;
      height: 70px;
      right: 0;
      top: 0;
    }
    .ra_process_tracking_single:last-child::after{
      background-color: transparent;
      position: absolute;
      content: "";
    }
    .slider-course-progress {
      justify-content: center;
    }
    .ra_process_tracking_area_body .barra-nivel {
      background: #00A2A7;
    }
    /*seach btn area start*/
    .ra_sortable_area_btn_area {
      display: flex;
      align-items: center;
    }
    .ra_process_tracking_area .myclassroom-single-right-right-content .dropdown button {
      padding: 6px 24px;
      border-radius: 25px;
      font-size: 14px;
      font-weight: 300;
      margin-left: 14px;
    }
    .ra_process_tracking_area .ra_btn_btnarea a {
      color: #fff;
      background: #00A2A7;
      display: inline-block;
      padding: 1px 10px;
      border-radius: 4px;
    }
    .ra_sortable_body_main_area .contact-button a {
      padding: 4px 18px;
    }

    /*seach btn area End*/


    /* ======================       Ra srot able area start         ======================= */

    .ra_sortable_area .mu_view_icons ul li {
      font-weight: 300;
    }
    .ra_sortable_area .mu_dropdown_menu_content ul li:last-child a{
      color: #0078BF;
    }
    .ra_sortable_area .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      content: '';
      position: absolute;
      background: transparent;
      height: 0px;
      width: 0%;
      bottom: 0;
      left: 0;
    }
    /*  Sort able area start   */
    .ra_sortable_header_area {
      display: flex;
      align-items: center;
      background: #E4E6EF;
      padding: 5px 0;
    }
    .ra_sortable_area_menu_ex_area h5 {
      color: #05233B;
      font-size: 14px;
      font-weight: 300;
      padding-left: 30px;
    }
    .ra_sortable_area_menu_ex_area {
      flex: 0 0 34%;
    }

    .ra_sortable_header_area_menu > ul {
      display: flex;
      align-items: center;
    }
    .ra_sortable_header_area_menu > ul li {
      display: flex;
      align-items: center;
    }
    .ra_sortable_header_area_menu > ul li ul li{
      line-height: 1;
    }
    .ata_je_ki_rokom{
      display: flex;
      flex-direction: column;
    }
    .ata_je_ki_rokom span {
      line-height: 1.2;
    }
    .ra_sortable_header_area_menu > ul li ul li img {
      margin-left: 5px;
      cursor: pointer;
    }
    .ra_sortable_header_area_menu ul li {
      color: #3F4254;
      font-size: 12px;
      font-family: Source Sans Pro;
      font-weight: 300;
      padding: 2px 8px;
      margin-left: 8px;
    }


    /*  Sort able area End   */

    /*Sort Able area start */

    .class_area_sort_flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: #fff;
      padding: 18px 22px 22px;
      margin-bottom: 15px;
      border: 1px solid #F7F7F7;
      box-shadow: 0px 3px 3px #8888880f;
      border-radius: 4px;
      transition: .3s;
    }
    .class_area_sort_flex:hover {
      background-color: #A6ADB147;
      cursor: pointer;
    }
    .ra_sort_left_area_body {
      flex: 0 0 27%;
    }
    .ra_sort_left_area_body .barra-nivel {
      background: #00A2A7;
    }

    .ra_sortable_certificate_main {
      flex: 0 0 27%;
    }
    .ra_sortable_certificate_main_sub_item_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-right: 50px;
    }
    .ra_sortable_certificate_sub1 img {
      width: 13px;
      margin-right: 5px;
      height: auto;
      object-fit: cover;
    }

    .ra_sortable_certificate_main2 {
      flex: 0 0 24%;
    }
    .ra_sortable_certificate_main2_sub_item_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
    }
    .ra_sortable_certificate_sub1 a span {
      font-size: 9px;
      font-weight: 300;
      background-color: #DBE6EE;
      transition: .4s;
      color: #05233B;
      padding: 1px 6px;
      border-radius: 9px;
      display: inline-block;
      line-height: 1;
    }

    /*.ra_sortable_certificate_sub1 a span:hover,*/
    .ra_sortable_certificate_sub1 a span.active{
      background-color: #05233B;
      color: #fff;
    } 
    .ra_sortable_right_btn_area {
      flex: 0 0 22%;
    }
    .ra_sortable_right_btn_area_sub_item_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-left: 50px;
    }
    .ra_sortable_right_btn_area_sub2 a img {
      height: 3px;
      width: auto;
      object-fit: cover;
      margin-bottom: 8px;
    }
    .ra_sortable_right_btn_area_sub1 .contact-button a {
      color: #0078BF;
      border: 1px solid #0078BF;
    }
    .ra_sortable_right_btn_area_sub1 .contact-button a:hover{
      color: #FFF;
    }
    .ra_sortable_certificate_main2 p {
      font-size: 14px;
      color: #3F4254;
      font-weight: 300;
    }
    .ra_sortable_body_main_area .profile-right-section-content p {
      font-weight: 300;
    }
    .ra_sortable_body_main_area .profile-right-section-content span {
      font-weight: 300;
    }
    /*Sort Able area End */

    /*Sort Able area start */
    .ra_sortable_buttom_area {
      background: #fff;
      padding: 17px 20px;
    }
    .ra_sortable_buttom_area h3 {
      font-size: 18px;
      font-weight: 300;
      color: #05233B;
    }
    .ra_sortable_buttom_area h3 span {
      font-size: 10px;
      line-height: 1;
      background: #DBE6EE;
      color: #05233B;
      padding: 1px 8px;
      border-radius: 12px;
      margin-left: 12px;
    }
    .ra_sortable_buttom_area_item_main {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .ra_sortable_buttom_single_item {
      flex: 0 0 25%;
    }
    .ra_sortable_buttom_single_item_sub {
      display: flex;
      align-items: start;
      padding-top: 17px;
    }
    .ra_sortable_buttom_single_item_sub img {
      height: auto;
      width: 26px;
      object-fit: cover;
      margin-right: 10px;
    }
    .ra_sortable_buttom_single_item_content h5 {
      font-size: 16px;
      color: #05233B;
      font-weight: 300;
    }
    .ra_sortable_buttom_single_item_content p {
      color: #3F4254;
      font-size: 14px;
      font-weight: 300;
    }

    .container_ex_area{
      width: 1140px;
      margin: auto;
      overflow-x: scroll;
    }
    /*start progress tracking pages css*/
    /*start progress tracking pages css*/
    .ju_pro_heading_area{
      background: #E4E6EF;
      border: 1px solid #E4E6EF;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    }
    .ju_pro_heading_area ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 35px;
    }
    .ju_pro_heading_area ul li{
      font-weight: 400;
      font-size: 14px;
      color: #3F4254;
    }
    .ju_single_table_content_design > ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .ju_single_table_content_design > ul > li {
      border: 1px solid #E4E6EF;
      padding: 32px 25px;
      border-right: none;
      border-top: none;
      font-weight: 300;
      color: #05233B;
      font-size: 10px;
      flex: 0 0 calc(100% / 6);
      height: 60px;
      cursor: pointer;
    }
    .ju_first {
      display: flex;
      align-items: center;
      font-size: 14px !important;
      color: #3F4254 !important;
    }
    .ju_first i {
      font-size: 15px;
      color: #05233B;
      padding-right: 5px;
    }
    .ju_single_table_content_design {
      position: relative;
    }
    .ju_posional_progress .myclassroom-single-left-cross-progress {
      width: 100%;
      height: 15px;
      display: block;
      border-radius: 50px;
      background: #E4E6EF;
      max-width: 700px;
    }
    .ju_posional_progress .barra-nivel {
      height: 15px;
      background: #145B94;
      border-radius: 50px;
    }
    .ju_posional_progress {
      content: "";
      position: absolute;
      width: 100%;
      height: auto;
      left: 207px;
      top: 10px;
    }
    .box_cmt {
      height: 18px;
      width: 18px;
      background: #C8CBDE;
      border-radius: 5px;
      margin-right: 8px;
    }
    .box_tb_items1{
      background: #2692D1;
    }
    .box_tb_items2{
      background: #DBE6EE;
    }
    .box_tb_items3{
      background: #05233B;
    }
    .box_tb_items4{
      background: #1A6A9A;
    }
    .box_tb_items5{
      background: #00A2A7;
    }
    .ui_single ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .text_dft{
      justify-content: flex-end;
    }
    .ui_single ul li {
      border: 1px solid #E4E6EF;
      padding: 20px 20px;
      border-top: none;
      border-right: none;
      font-size: 10px;
      font-weight: 300;
      color: #05233B;
      flex: 0 0 calc(100% / 6);
      height: 60px;
    }
    .ui_single .myclassroom-single-left-cross-progress {
      height: 15px;
      border-radius: 50px;
      width: 100%;
    }
    .ui_single .barra-nivel {
      height: 15px;
      background: #145B94;
      border-radius: 50px;
    }
    .hidden_basic {
      opacity: 0;
    }
    /*update css files */
    .card-header {
      padding: 0;
      margin-bottom: 0;
      background-color: #FFF;
      border-bottom: none;
    }
    .bg_header_area {
      background: #E4E6EF;
    }
    .ju_progress_tracking_main {
      margin-bottom: 100px;
    }
    .ui_single {
      background: #FFF;
    }
    .tide_here {
      padding: 30px 12px !important; 
    }
    .rjdesign.active .adil{
      opacity: 0;
    }
    .rjdesign.active .ju_first i{
      transform: rotate(-180deg);
      margin-right: 5px;
    }



  /* ================================================================================================
    ====                               Trecking PAGE CSS End                            ====
    ================================================================================================ */


  /* ================================================================================================
    ====                               LearningF PAGE CSS Start                            ====
    ================================================================================================ */

    .ra_learningf_header_diable_area .mu_alert_box, .custom_alert_design {
      margin-top: 11px;
    }
    .ra_learningf_header_diable_area_sub_area h1 {
      font-size: 20px;
      color: #05233B;
      font-weight: 300;
      padding: 23px 0 22px 0;
    }
    .ra_calender_area_bottom_checkbox {
      display: flex;
      justify-content: flex-end;
    }
    .ra_calender_area_bottom_checkbox.change {
      display: inherit;
    }
    .ra_sortable_learning_page_area .ra_sort_left_area_body {
      flex: 0 0 23%;
    }
    .ra_sortable_learning_page_area .ra_sortable_certificate_main {
      flex: 0 0 22%;
    }
    .ra_sortable_learning_page_area .ra_sortable_certificate_main2 {
      flex: 0 0 10%;
    }
    .ra_sortable_certificate_main_sub_item_area {
      margin-right: 20px;
    }
    .ra_sortable_learning_page_area .ra_color_pikar_section {
      flex: 0 0 16%;
    }
    .ra_sortable_learning_page_area .ra_sortable_right_btn_area {
      flex: 0 0 24%;
    }
    .ra_sortable_learning_page_area .ra_sortable_right_btn_area_sub_item_area {
      margin-left: 12px;
    }
    .ra_sortable_date_sub_area p {
      font-size: 14px;
      color: #3F4254;
      font-weight: 300;
    }
    .ra_sortable_learning_page_area .ra_sortable_certificate_sub_item13 {
      margin-left: 35px;
      line-height: 1;
    }

    /*.ra_sortable_learning_page_area*/
    .ra_sortable_learning_page_area {
      padding-bottom: 80px;
    }
    .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:last-child{
      color: #0078BF;
    }

    .ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:hover::after, .mu_dropdown_menu_content ul li button.menu-drop:hover::after {
      content: '';
      position: absolute;
      background: transparent;
      height: 0px;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    .ra_sortable_learning_page_area_search_area_start {
      display: flex;
      align-items: center;
      max-width: 494px;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 7px;
    }
    .ra_sortable_learning_page_area_search_area_start .mu_dropdown {
      top: 42px;
    }
    .ra_calender_area_bottom_checkbox ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .ra_sortable_learning_page_area .ra_side_area_active {
      margin: 0 32px;
      border-radius: 4px;
    }
    .ra_sortable_learning_page_area .ra_calender_area_bottom_checkbox ul li a {
      color: #A6ADB1;
      transition: .3s;
    }
    .ra_sortable_learning_page_area .ra_calender_area_bottom_checkbox ul li a:hover{
      color: #0078BF;
    }

    .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon img {
      width: 20px;
      position: initial;
    }
    .ra_sortable_right_btn_area_sub2 .dc_dots_area .down-icon {
      display: flex;
    }
    .ra_sortable_right_btn_area_sub2  .dc_dots_area {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 99;
    }
    /*messages pages*/
    .mu_people_list_right .dc_dots_area .down-icon img {
      position: initial;
    }
    .mu_people_list_right .dc_dots_area {
      display: flex;
      align-items: center;
    }
    .mu_people_list_right .dc_dots_area .down-icon {
      display: flex;
    }
  /* ================================================================================================
    ====                               Learning PAGE CSS End                            ====
    ================================================================================================ */
.txt-error{
	color: #dc3545!important;
}
.single-login-checkbox-form .form-check{
	padding:0px;
}
#dropdownMenuButton{
	border:0;padding: 0;
}
.myclassroom-single-right-right-content .dropdown button {
    color: #0078BF!important;
    border: 1px solid #0078BF;
    
}
.myclassroom-single-left-content-image .sb-avatar__text{
	border-radius:5px;
	background: rgb(20 91 148)!important;
}
.btn_style_2:hover, #launchcourse:hover,#launchcourse:active {
    background: #0078BF!important;
    color: #FFF!important;
}
.scrollere {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 250px!important;
    max-height: 450px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	margin-top:10px;
	
	
}
.scrollere_large {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 250px!important;
    max-height: 580px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	
	
}
.scrollere::-webkit-scrollbar,.scrollere_large::-webkit-scrollbar {
    /*width: 2px;*/
    width: 4px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.scrollere::-webkit-scrollbar-thumb, .scrollere_large::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.scrollere::-webkit-scrollbar-track,.scrollere_large::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}

.see-all-class-button span {
    font-weight: 400;
    font-size: 14px;
    color: #0078BF;
    text-align: center;
    display: block;
    background: #FFFFFF;
    padding: 15px 20px;
    border-radius: 5px;
    margin-top: 5px;
    -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
	cursor:pointer;
}
.load-spiner{
	display: block;
    position: absolute;
    z-index: 3;
    left: 50%;
	/*top: 30%;
	top: 5%;*/
}
/*.load-spiner {
    display: block;
    position: fixed;
    z-index: 1;
    left: 31%;
    top: 30%;
}*/
.inbox-messages-content .single-inbox-messages-content.unread {
    
    border-left: 2px solid #C55A11;
}
.inbox-messages-content .single-inbox-messages-content:nth-child(1)::after,.inbox-messages-content .single-inbox-messages-content:nth-child(3)::after {
    
    background: inherit!important;
}
.unread p{
	font-weight:600;
}
.owl-carousel, .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{
	display: block!important;
}
.owl-theme .owl-nav .owl-next img {
   width: 24px;
    height: auto;
    position: absolute;
    right: -28px;
    left: inherit!important;
    top: 45%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
	display:none
}
.owl-theme .owl-nav .owl-prev img {
    width: 24px;
    height: auto;
    position: absolute;
    left: -30px;
    top: 45%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.see-all-class-slider-button span {
    font-weight: 400;
    font-size: 14px;
    color: #0078BF;
    text-align: right;
    display: block;
	cursor:pointer;
}
.myclassroom-header-single a{
	cursor:pointer;
}
.upcoming-schedule {
    background-color: #f3f6f9;
    padding-bottom: 90px;
	min-height:640px;
}
.myclassroom-single-right-right-content .course.dropdown-menu.show{
	transform: translate(0px, 31px)!important;
}
.mu_dropdown_menu_content ul li button.menu-drop {
    padding-right: 30px!important;
}
.down-icon-three img {
    bottom: 7px!important;
}
.dropdown .ra_custom_checkbox_area_up.dropdown-menu.show{
	transform: translate(0px, 58px)!important;
}
.ra_event_main_area_slider_items .owl-nav {
    margin-top: 0!important;
    height: 0;
}
.mu_dropdown ul li span:hover {
    background-color: #E4E6EFA3;
}
.mu_dropdown ul li span:hover {
    background-color: #E4E6EFA3;
}

.mu_dropdown ul li span {
    color: #3F4254;
    font-size: 14px;
    display: block;
    padding: 8px 20px;
    border-bottom: 3px solid transparent;
}
.mu_search_bar_reply .mu_dropdown{
	bottom: -170px!important;
}
.ra_sortable_body_main_area .profile-right-section-content span {
    font-weight: 300;
    display: inline-flex;
}
.dc_dots_area .dropdown-menu{
	transform: translate(0px, 15px)!important;z-index:200!important;
}
.success.dropdown-toggle{
    color: #fff;
    background-color: #fff;
    border-color: unset;
}
.dropdown-toggle.btn.btn-success {
    color: #eaedf30d;
    background-color: unset;
    border-color: unset;
	
}
.dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active, .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active, .show>.dropdown-toggle.btn.btn-success.dropdown-toggle,.dropdown-toggle.btn.btn-success:hover {
    color: #eaedf30d;
    background-color: unset;
    border-color: unset;
}
.dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled).active:focus, .dropdown-toggle.btn.btn-success:not(:disabled):not(.disabled):active:focus, .show>.dropdown-toggle.btn.btn-success.dropdown-toggle:focus {
    /*box-shadow: 0 0 0 0.2rem rgb(72 180 97 / 50%);*/
    box-shadow: none;
}
.mu_dropdown ul li a {
	cursor:pointer;
}
.ra_sortable_learning_page_area .mu_dropdown_menu_content ul li a:last-child {
    color: #0078BF;
    cursor: pointer;
}
.mu_search_bar button{
	text-transform: capitalize!important;
	display: flex;
}
.mu_search_bar button img {
    margin: 7px 0 0 40px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    cursor: default;
    background-color: #efe;
    border-color: #addead;
	background: #0078BF;
    color: #FFF!important;
    padding: 5px 12px;
    border-radius: 4px;
}
.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
	border-radius: 5px;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}
.mu_header_next_btn .pagination li{
	margin-left:2px;
	margin-right:2px;
}
.previous a{
	 border: 0!important;
    background-color: unset!important;
    background: url(/media/messages/less.png);
    background-repeat: no-repeat;
    background-size: 8px 13px;
    vertical-align: middle;
}
.next a{
	 border: 0!important;
    background-color: unset!important;
    background: url(/media/messages/greater.png);
    background-repeat: no-repeat;
    background-size: 8px 13px;
     background-position: right; 
}
.previous, .next{
	margin-right:10px!important;
}
.myclassroom-area .myclassroom-content{
	cursor:pointer;
}

.c-avatar {
  position:relative;
  display:inline-block;
}
.c-avatar__image {
  width:60px;
  height:60px;
  object-fit:cover;
  border-radius:100%;
}
.c-avatar__status {
  width:15px;
  height:15px;
  background:#99CC00;
  background:#009650;
  border:1px solid white;
  position:absolute;
  bottom:2%;
  right:2%;
  right:8%;
  border-radius:100%;
}
a{
	cursor:pointer;
}
.ra_event_main_area_slider_items .owl-theme .owl-dots .owl-dot{
	display:none;
}
.list-section .sb-avatar__text {
    border-radius: 5px;
    background: rgb(20 91 148)!important;
}
.ra_teacher_list_area_header_single_item_body.re_body_list_item2 img {
    
    border-radius: 100%;
}
.custom-control-label.re_custom-control-label_single_man img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
}
.custom-control-label.re_custom-control-label_single_man .avatar-title.sb-avatar{
	    margin-right: 10px;
    margin-top: 2px;
    margin: 2px 10px 0 10px;
}
.ra_schedule_calender_area.upcoming-area tbody td {
    border-right: 10px solid #eaedf3;
}
.proges_search .mu_dropdown {
    bottom: -130px!important;
}

#resources .mu_search_bar_reply .mu_dropdown {
    bottom: -130px!important;
}
.ju_single_table_content_design > ul > li{
	
}
.box_cmt{
	display: inline-table;
}

.scrol-li {
    min-height: 30px!important;
    height: inherit!important;
    max-height: 58px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	
	
}
.ui_single ul li{
	    overflow: hidden;
}
.left_dc_img .sb-avatar__text{
	border-radius:5px;
}
.dc_single_items .dc_describ_txt_area{
	width: 46%;
}
.mu_pdf_client_left .sb-avatar{
	margin-right:5px;
}
.mu_single_grid_boxs .mu_single_grid_box{
	display: grid;
}
.mu_single_grid_boxs  .mu_single_grid_box p{
	word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    width: 100%;
    overflow: hidden;
	    min-height: 45px;
}
.infor_profile .sb-avatar{
	margin-top: 7px;
    margin-left: 11px;
}
.mu_people_profile .sb-avatar{
	margin-right:10px;
}
.mu_messages_page .mu_search_bar_reply .mu_dropdown {
    bottom: -130px!important;
}
.mu_messages_page .previous, .mu_messages_page .next {
    margin-right: 0px!important;
    margin-top: 10px;
}

.mu_messages_page  .pagination>li>a, .mu_messages_page  .pagination>li>span{
	padding:5px 12px!important;
}
.mu_messages_page  .pagination>li.previous>a, .mu_messages_page .pagination>li.next>a{
   padding:7px 12px!important;
}
.head-panation .break-me{
	display:none;
}
.filter_name{
	text-transform: capitalize;
}
.icon-delete{
	width: 12px;
    height: auto;
}
.btn-save{
	text-transform: capitalize!important;
	width: auto!important;
}
.txt-hrf:hover{
	text-decoration: underline;
	text-decoration-color: #d8dae5;
}
.msg-ic{
	color: #719bbe;
    margin-right: 5px;
}
.img-prf-ms{
	margin-right:19px;
}
.inbox-messages-content .single-inbox-messages-content.alert-im {
    border-left: 4px solid #C55A11;
	border-radius: 0;
}
.teacher-name p{
	line-height: 17px;
}
#prgoress .ra_sortable_right_btn_area_sub_item_area{
	margin-left: 90px;
}
#prgoress-two .pro_download_area .dropdown-menu{
	left: -40px !important;
}
#resources .list_prog_heading{
    margin-top: 0px;
}
 #resources-two .mu_dropdown_menu_area {
    margin-top: 10px;
}
.mu_messages_page .mu_people_list_margin {
    margin: 0 0px 0 12px;
}
.att-file a{
	font-size:14px;
}

#join-class:hover, #join-class:active {
    background: #0078BF!important;
    color: #FFF!important;
}
#join-class{
	width: 110px;
    margin-top: 5px;
}
.contact-button3n{
    margin-top: 5px;
}
.contact-button3n a{
	width: 110px!important;
	width: 115px!important;
}
.ju_single_table_content_design>ul>li{
	height:90px;
}
.ui_single ul li{
	height:90px;
}
.ra_event_single_slider_area_bottom {
  width: 80%;
}
#schedule .ra_event_main_area_slider .owl-theme .owl-nav .owl-next img {
    display: block;
}
.symbol {
    display: inline-block!important;
    flex-shrink: 0;
    position: relative!important;
    border-radius: 0.42rem!important;
}

.mr-6, .mx-6 {
    margin-right: 1rem !important;
}
.symbol.symbol-20 > img {
    width: 100%!important;
    max-width: 30px!important;
    height: 30px!important;
}
 .navi-text {
    color: #3F4254;
}
.header-logo-dropdown .dropdown-item.width {
	display: flex;
    align-items: center;
    /* padding: 0.75rem 1.5rem; */
    width: auto;
	margin:0;
}
.h-80 {
    height: 57px !important;
    display: inline-block;
    width: auto!important;
    margin-bottom: 3px;
}
.max-w-150px {
   /* max-width: 150px !important;
	    max-width: 155px !important;*/
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	margin:1rem 0.75rem;
}

.ml-3, .mx-3 {
    margin-left: 0.75rem !important;
}
.header-logo-dropdown #dropdownMenuButton{
	display: inline-flex;
}
#home .owl-carousel .owl-item .single-slider-profile-left img {
    display: block;
    width: 40px;
	height:40px;
}
.header-top-content .h6,.header-top-content h6 {
    font-size: 1rem!important;
}
.header-top-content .h1,.header-top-content .h2,.header-top-content .h3,.header-top-content .h4,.header-top-content .h5,.header-top-content .h6,.header-top-content h1,.header-top-content h2,.header-top-content h3,.header-top-content h4,.header-top-content h5,.header-top-content h6 {
    font-weight: 500!important;
    line-height: 1.2;
}
.header-logo-dropdown .dropdown{
	display: inherit;
}
 

.view-course-button a button {
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    width: 180px;
    padding: 5px 10px;
    border-radius: 25px;
    text-align: center;
    text-transform: inherit;
    transition: .3s;
    margin: 0 auto;
    display: block;
	color: #0078bf!important;
    border: 1px solid #0078bf;
	background: #FFFFFF;
}
.view-course-button a button:hover {
    background: #0078BF;
    color: #FFFFFF!important;
}
.launch-course-button a button {
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
    background: #0078BF;
    border: none;
    width: 180px;
    padding: 5px 10px;
    border-radius: 25px;
    text-align: center;
    text-transform: inherit;
    transition: .3s;
    margin: 0 auto;
    display: block;
	color: #0078bf!important;
    border: 1px solid #0078bf;
	background: #FFFFFF;
}
.launch-course-button a button:hover {
    background: #0078BF;
    color: #FFFFFF!important;
}
#home .myclassroom-single-right-content-text {
    width: 40%;
}
#home .profile-section-div{
	width: 40%;
}
#home .myclassroom-single-left-cross-progress{
	width:130px;
}
#home .profile-left-section-content{
	width:42px;
	width:25%;
	width:20%;
}
#home .profile-right-section-content{
	width:120px;
	width:72%;
	width:77%
}
#learning .profile-left-section-content{
	width:42px
}
#learning .profile-right-section-content{
	width:120px;
	width:150px;
}
a.ahi{
	color: #3f4254!important;
    font-size: 12px!important;
	cursor: inherit!important;
	text-transform: inherit!important;
}
#learning .ra_sortable_area_menu_ex_area {
    flex: 0 0 57%;
    flex: 0 0 44%;
}
#prgoress .ra_sortable_area_menu_ex_area {
    flex: 0 0 52%;
    flex: 0 0 43%;
}
#prgoress .profile-left-section-content {
    width: 42px;
}
#prgoress .profile-right-section-content {
    width: 120px;
    width: 150px;
}

.ra_jackson_top .profile-image img{
	border-radius:100%!important;
}
#schedule .re_body_list_item4, #schedule .re_header_list_item4 {
    flex: 0 0 20%;
}
#schedule .ra_jackson_btn_area a {
    color: #0078BF;
    background: #fff;
    border: 1px solid #0078BF;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 50px;
    margin-left: 15px;
}
#schedule .ra_jackson_btn_area a:hover {
    background: #0078bf!important;
    color: #fff!important;
}
.joinclass{
	margin-top:5px!important;
	width:110px;
	text-align:center;
}
#schedule .re_body_list_item1,#schedule .re_header_list_item1 {
    flex: 0 0 30%;
}
#schedule .re_body_list_item2, #schedule .re_header_list_item2 {
    flex: 0 0 25%;
}
#schedule .ra_teacher_list_area_header_single_item_body_single_man{
	margin-left:5px;
}
#schedule .re_body_list_item1{
	cursor:pointer;
}
#learning-two .ra_repetable_area .top-progress-area .single-top-progress-area:nth-child(4){
	background: url('/media/learning/bg-progress40.png') !important;
	    background: #e4e6ef!important;
}
#learning-two .sb-avatar{
	margin-right:10px
}
#learning-two .ra_single_man h6 {
    font-size: 11px!important;
    color: #05233B;
    font-weight: normal!important;
}
#learning-two .left_dc_img {
    margin-right: 10px;
}
#learning-two .dc_jackson_img_area{
	    flex: 0 0 40%;
}
#learning-two .dc_middle_txt a{
	top:5px;
}
#learning-two .dc_middle_txt p{
	padding-left:20px;
}
#learning-two .right_dc_date_time{
	flex: 0 0 15%;
}
#learning-two .dc_single_items .dc_describ_txt_area{
	flex: 0 0 45%;
}
.enr-j-c{
	margin-top: 5px;
    width: 143px;
    text-align: center;
}
.fs11{
	font-size:11px!important;
}
.progess_muti{
	width:75px!important;
	display:inline-block;
	background: #dee2e6!important;
}
.progess_muti .barra-nivel1 {
    height: 7px!important;
	background: #ff5370!important;
}
.progess_muti .barra-nivel2 {
    height: 7px!important;
	background: #05233b!important;
}
.progess_muti .barra-nivel3 {
    height: 7px!important;
	background: #4099ff!important;
}
.progess_muti .barra-nivel4 {
    height: 7px!important;
	background: #2ed8b6!important;
}
.progess_muti .barra-nivel5 {
    height: 7px!important;
	background: #ffb64d!important;
}
#learning-two .toto-pg {
    margin-top: -18px!important;
}
.form-control.form-control-solid {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.form-mess{
	font-size:14px;
}
.mu_messages_all .mu_search_bar a {
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}

.mu_messages_all .mu_search_bar a {
    font-size: 14px;
    position: relative;
}
.mu_messages_all .mu_people_left_left{
	width: 235px;
}
#resources .custom_checkbox_area{
	width:230px;
}
#resources .mu_search_bar a {
    font-size: 14px;
    position: relative;
}
#resources .mu_search_bar a {
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
#new-message .mu_header_btn.mu_header_btn12.mu_header_btn12_2.mu_header_btn12_3 {
    margin-top: 30px;
  }
#schedule .ra_event_main_area h2{
	margin-top:10px;
}
.header-top-left-content .sb-avatar{
	margin-top:8px;
}
#resources .dc_middle_txt p{
	padding-left:0px;
}
#resources-two .mu_single_all_grid_boxs{
	display: inline-block;
	margin-left: -15px;
    margin-right: -15px;
	width: 100%;
}
#resources-two .mu_single_grid_boxs{
	display: inline-block;
    margin: 0 0.75%;
	width:18.5%;
	margin-bottom:30px;
}
#resources-two .mu_search_bar a {
    font-size: 14px;
    position: relative;
}
#resources-two .mu_search_bar a {
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
#resources-two .custom_checkbox_area{
	width:230px;
}
.react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
}
.backg-com.barra-nivel{
	background:#145B94!important;
	    margin: 1px 0;
}
.backg-inpr.barra-nivel{
	background:#b4c7e7!important;
	    margin: 1px 0;
}
.backg-none.barra-nivel{
	background:#E4E6EF!important;
	    margin: 1px 0;
}
.pdlr0{
	padding-left:0!important;
	padding-right:0!important;
}
.pdlr0 .progess_muti{
	width:100%!important;
	height: 15px;border-radius: 0;
}
.pdlr0 .progess_muti .barra-nivel{
	height: 15px;border-radius: 0;
}
.bdrdusl{
	    border-bottom-left-radius: 50px!important;
		border-top-left-radius: 50px!important;
}
.bdrdusr{
	    border-bottom-right-radius: 50px!important;
		border-top-right-radius: 50px!important;
}
.student_pages_content_area {
    min-height: 1100px;
    /* padding-bottom: 80px; */
    padding-bottom: 100px;
    /* padding-bottom: 700px; */
    /* padding-bottom: 1150px; */
    position: relative;
}
.mu_chart_area {
    width: 270px;
    background: #E4E6EF;
    padding: 26px 0 10px 0;
    border: 4px solid #FFF;
}
.left_infor_area .infor_profile img{
	border-radius:100%!important;
}
.mu_messages_area.mu_messages_area2 {
    width: 270px;
    padding: 31px 52px 18px 62px;
    margin-left: -100px;
}
.mu-tags{
	border-top: 1px solid #fffffff2;
}
.ra_teacher_list_area_header_single_item_body.re_body_list_item2 img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
#prgoress-two .ui_single ul li.pd20-10{
	padding:20px 0px!important;
}
#prgoress-two .ju_single_table_content_design > ul > li.ju_first{
	padding:20px!important;
}
#prgoress-two .box_cmt{
	padding-right:17px;
}
.single-myclassroom-slider-heading a {
    width: auto;
    text-align: center;
}
.css-1pahdxg-control{
	display:block;
	z-index:1!important;
}
#prgoress-two .ui_single .myclassroom-single-left-cross-progress{
	border-radius:0!important;
}
#prgoress-two .ui_single .barra-nivel{
	border-radius:0!important;
}

#prgoress-two .progress,#prgoress-two .progress-bar.nocolor{
	background: #fff!important;
}

.bg-success.backg-com{
	background:#145B94!important;
}
.bg-success.backg-inpr{
	background:#b4c7e7!important;
}
.bg-success.backg-none{
	background:#E4E6EF!important;
}
#prgoress-two .progress{
	border-radius:0;
}
.login-logo img {
    height: 60px;
    width: auto;
}
.login-logo{
	padding-bottom:8px;
}
.max-w-250px {
    /*max-width: 250px !important;*/
}

.font-weight-bold {
    font-weight: 500 !important;
}

.ml-3, .mx-3 {
    margin-left: 0.75rem !important;
	display: inline-block;
}
.rjdesign.active .adil {
    opacity: 0!important;
}
.mu_dis_content_img_left.let-l-chat {
        max-width: 115px;
    width: auto;
    min-width: 65px;
   /* min-width: 80px;*/
    position: relative;
}
.mu_dis_content_description_left{
	padding-left:10px;
}
.mu-chat1{
	margin-right:50px!important;
}
.mu_dis_right_header_rtr ul li a {
    margin-right: 1px;
}
.mu_message_scroll_right{
	height: 715px;
}
.mu_message_sin_content{
	min-width:300px;
	width:auto;
}
.mu_dis_message_area2 .ct-mss{
	min-height:250px;
	min-height: 650px;
	min-height: 680px;
}
#prgoress-two .mu_search_bar_reply .mu_dropdown {
    bottom: -130px!important;
}
.mu_dis_le_dropdown .mu_search_bar a {
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    position: relative;
}
.seach-dis .mu_search_bar input[type="search"] {
    background: #FFF;
    border: 1px solid #A6ADB1;
    padding: 7px 0px 7px 30px!important;
    outline: none;
    width: 190px!important;
    color: #505355;
    font-size: 12px!important;
    border-radius: 3px 0 0 3px;
}
.seach-dis .search_icon img {
    width: 16px;
    height: auto;
    cursor: pointer;
}
.seach-dis .mu_search_bar a {
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    padding: 6px 10px!important;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    position: relative;
}
.seach-dis .dropdown-menu.dropdown-menu{
	transform: translate(-170px, 28px)!important;
    width: 240px;
}
.seach-dis .custom_checkbox_area {
    padding: 10px;
    font-size: 12px;
}
.seach-dis .custom_checkbox_area label span {
    font-size: 12px;
    font-weight: 300;
}
.resources-area,.request-transcript-area {
    width: 100%;
    height: 96%;
    top: 4%;
}
.modalMsg .modal-dialog .modal-content {
    max-width: 340px!important;
    margin: auto;
}
.mu_dis_right_header{
	padding: 16px 30px;
}
.right_pro_college_area {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
}
.pro_search_clg {
    margin-right: 0;
}
.mu_message_sin_img .sb-avatar{
	margin-right:15px;
}
.btn-back{
	margin-right: 15px;
    color: #181c32!important;
    background: #efefef!important;
    border-color: #e7e4e4!important;
}

.single-change-password-right-input input{
	text-transform: inherit!important;
}
.ra_process_tracking_area_header_search {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
}
.btn-active{
	background: #DBE6EE!important;
}
.change-password-content .css-g1d714-ValueContainer,.css-2b097c-container{
	font-size:13px;
}
.change-password-area .change-password-content {
    max-width: 800px;
    max-width: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-left: 60px;
    margin-left: 0px;
    padding-top: 20px;
}
.change-password-area .change-password-right-content{
	margin-left:15px;
}

.change-password-area .css-yk16xz-control{
	border-color:#3F4254;
}
.lbl-for-check{
	font-weight: 300;
    font-size: 14px;
    
}
.lbl-for-check label{
	margin-left: 10px;
}
.talr{
	text-align:right;
}
.ra_process_tracking_area .jannle-marshall{
	width:auto;
}
.parent-page .ra_process_tracking_area .down-icon-four-update{
	margin-right:40px;
}
.btn-join-fire{
	float:right;
	position:absolute;
	right:100px;
	color:#fff!important;
	background: #c55a11;
    border-color: #c55a11;
}
.btn-join-fire:hover{
	color:#c55a11!important;
}
.table-notification td, .table-notification th {
    font-size: 13px;
}
.table-notification .far {
    font-size: 16px;
    color: rgba(37, 132, 215, 0.82);
}
.table-notification .fa-edit, .table-notification .fa-trash-alt {
   cursor:pointer;
}
.table-notification thead th {
    font-weight: 600;
}
.add-noti{
	float: right;
    background: #0078bf;
    border: 1px solid #0078bf;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 4px 10px;
}
.table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>thead>tr>td.active, .table>thead>tr>th.active {
    background-color: #f5f5f5;
}
.single-chating-left-image .sb-avatar{
	margin-right:10px;
}
.mu_dis_Single_message_box_option.mu_dis_message_area1_box2 {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
.mu_dis_message_option.option2{
	text-align: center;
    margin-top: 3px;
}
.mu_dis_message_option {
    text-align: center;
    margin-top: 0px;
}
.request-suport-ticket-area {	
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
}
.request-suport-ticket-area {
    width: 100%;
    height: 96%;
    top: 4%;
}
.request-suport-ticke-main-content {
    max-width: 515px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
}
.request-suport-ticke-main-content .single-change-password-right-input input{
	border: 1px solid #ced4da!important;
	font-weight: 400;
    line-height: 1.5;
    color: #495057;
	font-size: 1rem;
}
#support_content{
	width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
	color: #495057;
}
.ticket-header{
	font-size:17px!important;
	font-weight:500!important;
}

.send_tic:not([href]):not([tabindex]) {
    color: #fff;
    text-decoration: none;
}

.request-suport-ticke-main-content .single-change-password-right-input label {
    font-weight: 400;
    font-size: 14px;
    color: #3F4254;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.request-edit-student-area {	
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
}
.request-edit-student-area {
    width: 100%;
    height: 96%;
    top: 4%;
	
}
.displayNL{
	display:inline-table!important;
}
.request-edit-student-main-content {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
}
#form-edit-student .student-information-edit img {
    width: 20px;
    position: absolute;
    bottom: 37px;
    left: 95px;
}
.request-edit-student-main-content{
	background: #eae8f3;
    border-radius: 5px;
	padding: 20px;
}
#form-edit-student .student-information-heading-profile .sb-avatar__text{
	border:2px solid #fff;
}
.avartar_st{
	width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid #fff;
}
#form-edit-student  .form-check-label {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
}
#form-edit-student #ethnicity,#form-edit-student #emergency,
#form-edit-student #country, #form-edit-student #phone_type{
	border: 1px solid #3F4254;
	    padding: 5px 15px;
    height: inherit;
}
.react-datepicker-wrapper{
	width:100%!important;
}
.request-edit-student-area .all-same-button-design {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 35px;
}
.fnt-si12{
	font-size:12px!important;
}
.phonenum{
	width: 40%!important;
    display: inline-block!important;
}
.lbl-phone{
	width: 15%;
    display: inline-block!important;
    text-align: center;
}
.plugad {
    width: 18px;
    height: auto;
	    margin-left: 10px;
}
.div-table .table-responsive{
	background: #fff;
    margin-top: 10px;
    border-radius: 5px;
	border: 1px solid #bfd4e1;
}
.request-edit-student-area .btn-back{
	margin-left: 10px;
    padding: 5px 20px;
    background: #fff!important;
    border: 1px solid #dee2e6!important;
}
#alert-message button{
	margin-left:15px;
}
.request-edit-student-area .css-yk16xz-control{
	border-color: #3f4254;height:34px;min-height:34px;
}
.request-edit-student-area #input-group-dropdown-1{
	background-color: #fff;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
    height: 35px;
}
.request-edit-student-area .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.request-edit-student-area #input-group-dropdown-1:hover,.request-edit-student-area #input-group-dropdown-1:active,.request-edit-student-area #input-group-dropdown-1:focus{
	background-color:rgba(0,0,0,0.05);
	color: #6c757d;
}
.request-edit-student-area .mb-3.input-group  input{
	    border-left: 0!important;
}
.allow-dropdown.intl-tel-input{
	width:100%;
}
.PhoneInput{
	padding-top:3px;
}
.lean_detail .sb-avatar__text{
	border-radius:5px;
}
.lean_detail .sb-avatar {
    margin-right: 0px!important;
}
.txt-sb{
	text-transform: capitalize;
}
.name_tea .sb-avatar__text>div{
	font-size:12px!important;
}
.ra_resources_slider_area_middle.descrip-txt p{
	padding-right:0px!important;
}
.ra_resources_slider_area_middle h2{
	font-weight:500;
}
.img-file{
	width: 14px!important;
    display: inline-block!important;
    margin-right: 5px;
}
#resources .right_dc_img{
	min-width:240px;
}
#resources .right_dc_img a:nth-child(1) {
    margin-bottom: 0;
}
#resources-two .mu_grid_box_pdf_left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: flex-start;
    flex-direction: column;
    align-content: center;
}
#resources-two .mu_grid_box_pdf {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    min-height: 45px;
    flex-direction: column;
}
#resources-two .mu_pdf_client_left img {
    margin-right: 5px;
    width: 23px;
    height: 23px;
    border-radius: 100%;
}
.logo-sch {
	display: -webkit-flex!important;
    display: flex;
    -webkit-justify-content: flex-start!important;
    justify-content: flex-start;
    -webkit-align-items: center!important;
    align-items: center;
    width: auto;
    position: relative!important;
}
.logo-sch  .text-truncate{
	margin-right:50px!important;
}
.dc_middle_txt .displayed-text,.dc_middle_txt{
    color: #3F4254;
    font-weight: 400;
    font-size: 12px;
    padding-left: 0;
}
.dc_middle_txt {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.read-more-less--less,.read-more-less--more{
	color:blue;
}
.font12{
    font-size: 12px;
    font-weight: 400;
    color: #3e3f42;
    line-height: 15px;
}
.ct-sli-re{
	min-height: 60px!important;
    height: inherit!important;
    max-height: 100px!important;
    max-height: 35px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.ct-sli-re::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.ct-sli-re::-webkit-scrollbar-thumb {
    background-color: #fff;
   background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#145b94),color-stop(.6,#4ebfc2),to(#e4e6ef));
    border-radius: 10px;
	
}
.ct-sli-re::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
	margin: 0 30px;
}
.student_pages_content_area  a.btn_style_2:not([href]):not([tabindex]) {
    text-decoration: none;color: #0078BF;
}
.student_pages_content_area  a.btn_style_1:not([href]):not([tabindex]) {
    color: #fff;
}

.student_pages_content_area a.btn_style_1:not([href]):not([tabindex]):focus,.student_pages_content_area a.btn_style_1:not([href]):not([tabindex]):hover {
    color: #0078BF;
    text-decoration: none;
}
.mu_dis_Single_message_box a.cusor{
	    color: #337ab7;
    font-size: 13px;
}
.role-login{
	width: 100%;
    border: 1px solid #3F4254;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #3F4254;
    text-transform: inherit;
    outline: none;
}
a.li-chat{
	width: 90%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    color: #337ab7!important;
}
p.staff-sec{
    color: #3F4254;
    font-size: 12px;
    padding: 2px 10px;
    font-weight: 300;
}
#learning-two .ra_resources_right_area_single_item::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: -15px;
    width: 115%;
    height: 1px;
    background-color: #EAEDF3;
}
#learning-two  .ra_resources_right_area_single_item:last-child:after {
    content: "";
    position: absolute;
    background-color: transparent;
}
.header-profile-dropdown {
    padding: 0 20px 0 20px;
    position: relative;
}
#prgoress .proges_search .mu_dropdown {
    bottom: -162px!important;
}
.box_cmt_atance{
	height: 12px;
    width: 12px;
	margin-right:7px;
}
.request-recommendation-area {
    background: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 97%;
    top: 3%;
    left: 0;
    z-index: 999;
    display: none;
}
.help-contetn img {
    width: 80px;
    height: auto;
    margin-top: 0px;
}
.request-edit-student-area {
    width: 100%;
    height: 97%;
    top: 3%;
}
.resources-area, .request-transcript-area,#enrollment-request {
    width: 100%;
    height: 97%;
    top: 3%;
}
.progess_muti {
    width: 64px!important;
}
.Student_action_single_items, .st_another_single_deffrent{
	display:block;
}
.pdbt15{
	padding-bottom:25px;
}
.student_pages_content_area .student-information-text {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
	width: 95%;
    margin-top: 10px;
}
.information_biodata h4,.information_biodata h5 {
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    color: #3F4254;
    margin-top: 0;
	margin-bottom: 10px;
}
.student-information-single-info p{
	font-weight: 500;
    color: #3f4254;
    font-size: 11px;
	margin-bottom:6px;
}
.main_student_information_area {
    background: #FFF;
    margin-top: 14px;
    min-height: 500px;
}
.txt12{
	font-size:12px;
	font-size:11px;
}
.txt11{
	font-size:11px;
}
#schedule .in-class-sec .re_body_list_item2, #schedule .in-class-sec .re_header_list_item2 {
    -webkit-flex: 0 0 20%!important;
    flex: 0 0 20%;
	padding-right:10px;
}
#schedule .in-class-sec .re_body_list_item3,#schedule .in-class-sec .re_header_list_item3 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%!important;
	padding-right:10px;
}
#schedule .in-class-sec .re_body_list_item4,#schedule .in-class-sec .re_header_list_item4 {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%!important;
}
#schedule .ra_windows_main_area {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
	margin:10px 0;
}

.left_infor_area .infor_profile img {   
    width: 60px;
    height: 60px;
}
.ra_teacher_list_area_header_single_item_body.re_body_list_item1 p {
    /*font-size: 16px;*/
}
.request-suport-ticket-area {
    width: 100%;
    height: 97%;
    top: 3%;
}
#resources .right_dc_img a span {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Avenir';
}
#learning-two .dropdown-item.active, #learning-two .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #addedc;
}
#learning-two .dropdown-item h6{
	font-size:14px;
}
 #schedule .ra_event_single_slider_area h1 {
    width: 200px;
    text-align: left;
}
 #schedule .ra_event_single_slider_area {
    height: 140px;
}
#schedule .ra_event_single_slider_area p {
    text-align: left;
}
#home .col-big .myclassroom-single-left-cross-progress {
    width: 270px;
}
#home .col-big .myclassroom-single-right-content-text {
    width: 50%;
}
#home .col-big .myclassroom-single-right-content {
    flex: 0 0 30%;
    padding: 20px;
}
#home .col-big .myclassroom-single-left-content {
    flex: 0 0 70%;
}
#home .col-big .myclassroom-single-left-content-course-progress {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
#home .col-big .profile-left-section-content {
    width: initial;
}
#home .col-big .single-top-progress-area{
	padding:40px;
}
.student_pages_content_area .request-suport-ticket-area,
.resources-area, .request-transcript-area, #enrollment-request
{
	top:55px;
}
.event_holiday{
    background-color: #22b14c!important;
    border-left: 7px solid #025f1d!important;
}
.log-i-role{
	padding-top: 0!important;
    padding-bottom: 0!important;
    color: red!important;
}
.single-login-footer-left p {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: inherit;
    margin-right: 15px;
}
.ra_main_left_color_select .sb-avatar__text{
	border-radius:5px;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 0;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}
	.student_pages_content_area::-webkit-scrollbar {
            width: 1px;
        }
      
        /* Track */
        .student_pages_content_area::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 5px;
        }
      
        /* Handle */
        .student_pages_content_area::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 5px;
        }
      
        /* Handle on hover */
        .student_pages_content_area::-webkit-scrollbar-thumb:hover {
            background: #fff;
        }




 /* Designing for scroll-bar */
        .apoinre::-webkit-scrollbar {
            width: 1px;
        }
      
        /* Track */
        .apoinre::-webkit-scrollbar-track {
            background: inerhit;
            border-radius: 5px;
        }
      
        /* Handle */
        .apoinre::-webkit-scrollbar-thumb {
            background: #387ebe;
            border-radius: 5px;
        }
      
        /* Handle on hover */
        .apoinre::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
		
#learning-two #order-grades .ar-filter-area {
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 0 20px;
		margin-top: 30px;
		display: flex;
		align-items: center;
	}
#learning-two #order-grades .ar-filter-area {
		border: 1px solid #e4e6ef;
		border-radius: 5px;
	}
	

#learning-two #order-grades .mu_dropdown_menu_content.ar-drop-menu-cont {
    font-weight: 400;
}
#learning-two #order-grades .mu_dropdown_menu_content {
    -webkit-flex: 0 0 55%;
    flex: 0 0 55%;
}
#learning-two #order-grades .mu_dropdown_menu_content ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
}	
#learning-two #order-grades .ar-drop-menu-cont ul {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
#learning-two #order-grades .mu_dropdown_menu_content ul li {
    color: #3f4254;
    margin-right: 20px;
    font-size: 12px;
    padding: 18px 0;
}
#learning-two #order-grades .ar-filter-all-wrapper {
    display: -webkit-flex;
    display: flex;
    padding: 10px 0px 13px;
    margin-top: 1px;
	background: #e4e6ef;
}


#learning-two #order-grades .ar-search-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
	width:100%;
	padding:0 20px;
}
#learning-two #order-grades .ar-single-search.search1{
	flex: 0 0 15%;
}
#learning-two #order-grades .ar-single-search.search2{
	flex: 0 0 23%;
}
#learning-two #order-grades .ar-single-search.search3{
	flex: 0 0 15%;
}

#learning-two #order-grades .ar-single-search.search4{
	flex: 0 0 15%;
}
#learning-two #order-grades .ar-single-search.search5{
	flex: 0 0 13%;
}

#learning-two #order-grades .ar-single-search.search6{
	flex: 0 0 15%;
}

#learning-two #order-grades .ar-single-search.search7{
	flex: 0 0 16%;
}

#learning-two #order-grades .ar-single-search.search8{
	flex: 0 0 11%;
}

#learning-two #order-grades .ar-single-search.search9{
	flex: 0 0 9%;
}

#learning-two #order-grades .ar-single-search.search10{
	flex: 0 0 16%;
}
#learning-two #order-grades .cour-staf1 {
    flex: 0 0 15%;
}

#learning-two #order-grades .cour-staf2 {
    flex: 0 0 23%;
}

#learning-two #order-grades .cour-staf3 {
    flex: 0 0 15%;
}

#learning-two #order-grades .cour-staf4 {
    flex: 0 0 15%;
}

#learning-two #order-grades .cour-staf5 {
    flex: 0 0 13%;
}

#learning-two #order-grades .cour-staf6 {
    flex: 0 0 15%;
}

#learning-two #order-grades .cour-staf7 {
    flex: 0 0 16%;
}

#learning-two #order-grades .cour-staf8 {
    flex: 0 0 11%;text-align:center;
}

#learning-two #order-grades .cour-staf9 {
    flex: 0 0 9%;text-align:center;
}

#learning-two #order-grades .cour-staf10 {
    flex: 0 0 16%;
}
#learning-two #order-grades .ar-single-department-course {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 0 10px #e4e6ef;
    margin-bottom: 10px;
	background:#fff;
}

#learning-two #order-grades .course-sec p {
    color: #3f4254;
    font-size: 12px;
    padding: 2px 10px;
    font-weight: 300;
}
#learning-two #order-grades .ra_sortable_header_area_menu ul li{
	font-weight:400;
	margin-left:0;
}
#learning-two #order-grades .btn-card-view {
    font-size: 10px;
    border-radius: 15px;
    padding: 3px 12px;
    background: #00c1e6;
    color: #fdfffe;
    font-weight: 600;
}
.mu_icon_header_icons ul li a {
    margin: 0 15px;
}
.tooltip-inner {
    max-width: 200px;    
    background-color: #addedc;
    border-radius: .25rem;
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #05233b!important;
    font-size: 12px;
}
a.txt-link-more{
	font-size: 12px!important;
    color: #0078BF!important;
    text-decoration: underline!important;
    float: right!important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    top: 0;
    top: -2px;
    border-width: .4rem .4rem 0;
    border-top-color: #addedc;
}
.student-information-single-text-heading p{
	color: #3f4254;
    font-weight: 300;
	font-size:12px;
}
.fil-obj{
	display: -webkit-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    color: #3e3f42;
    font-weight: 300;
    cursor: pointer;
}
.fil-obj img{
	margin-right:15px;
}
.header-logo-dropdown .dropdown-item.widths{
	width: auto;
    margin: 8px;
	padding-left:5px;
}
.header-logo-dropdown .dropdown-item.widths div{
	display: flex;
    align-items: center;
}
.dropdown-item.widths .navi-text-school{
	font-size:13px;
}
.header-logo-dropdown .dropdown-menu.show{
	padding:0px 10px!important;
}
.header-profile-dropdown.header-logo-dropdown .dropdown-menu.show {
     padding: 0px!important; 
}
#message .mu_header_btn.mu_header_btn12.mu_header_btn12_2 a {
    margin-left: 5px;
}
#message .mu_single_letter {
    background: #FFF;
    padding: 0 0 80px 20px;
    border-top: 1px solid #EAEDF3;
}
#message .mu_profile_img_area_top{
	padding-right:14px;
}	
#message .mu_chart_header{
	padding: 15px 15px 15px 20px;
}
#message .subjec{
    font-size: 18px;
    margin-bottom: 15px;
}
#message .mu_profile_img_area_left img{
	height:52px;margin-right: 10px;
}
#message .img-prf-ms {
    margin-right: 10px;
}
a.link-detail{
	text-decoration: underline!important;color: blue !important;
}
#schedule .carousel-caption {
    position: initial;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    color: rgb(33, 37, 41);
    text-align: center;
}
#schedule .carousel-indicators{
	display:none;
}
#schedule .carousel-control-next,#schedule .carousel-control-prev{
	display:none;
}
#prgoress-two .myclassroom-single-right-right-content .dropdown button{
	padding:6px 24px;
}
.student-page #prgoress .ra_process_tracking_area .but_left_view{
	display: flex;
    flex-direction: row;
    align-items: center;
}
.student-page .pt_view_main_area.option {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.student-page #schedule #sche-calen .ra_calender_left_area_sub_area {
    padding-left: 12px;
}
a.txt-hrf:not([href]):not([tabindex]):focus, a.txt-hrf:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: underline;
}
.student-page .today-week-button a, .student-page .today-week-button a.btn_style_4 {
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    padding: 5px 17px;
    border: 1px solid #0078bf;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

.student-page .today-week-button a.btn_style_4 {
    color: #fff;
    background: #0078bf;
}
.student-page .today-week-button a {
    text-align: center!important;
    color: #0078bf;
}
.student-page .today-week-button a {
    width: 80px;
    height: 35px;
    border-radius: 28px;
    display: block;
    align-items: center;
    text-transform: capitalize;
    margin-right: 15px;
    padding-left: 17px;
    transition: .3s;
}
.student-page .today-week-button {
    display: flex;
    align-items: center;
}
.student-page .link-detail{
	display: initial;text-decoration: underline;
}
.student-page #message .seach-dis .dropdown-menu.dropdown-menu {
    transform: translate(0px, 28px)!important;
    width: 280px;
}
.student-page #message .seach-dis .dropdown-menu.dropdown-menu .custom_checkbox_area label span{
	width:110px!important;
}
.student-page #message .text-editor {
  width: 100%;
  margin: 0 auto;
}
.student-page #message .ql-editor {
  min-height: 300px;
}
.student-page #message .label-inline.mr-2{
	color:blue;
	font-size:13px;
}
.notification .badge{
	color:transparent!important;
}
#prgoress .ra_sort_left_area_body{
	flex: 0 0 22%;
}
#prgoress .ra_sortable_certificate_main{
	flex: 0 0 18%;
}
#prgoress .ra_sortable_certificate_main2 {
    flex: 0 0 46%;
}
#prgoress .ra_sortable_body_main_area .contact-button a {
    padding: 4px 10px;
    font-size: 11px;
}
#prgoress .ra_sortable_right_btn_area1{
	flex: 0 0 8%;
	margin-left:5px;
}
#prgoress .class_area_sort_flex{
	padding: 18px 8px 22px;
}
#prgoress .ra_sortable_certificate_sub_item1{
	flex: 0 0 15%;
}
#prgoress .ra_sortable_certificate_sub_item2{
	flex: 0 0 18%;
}#prgoress .ra_sortable_certificate_sub_item3{
	flex: 0 0 15%;
}#prgoress .ra_sortable_certificate_sub_item4{
	flex: 0 0 15%;
    text-align: center;
}#prgoress .ra_sortable_certificate_sub_item5{
	flex: 0 0 19%;
}#prgoress .ra_sortable_certificate_sub_item6{
	flex: 0 0 14%;
}
#prgoress .ra_sortable_certificate_sub_item5 p{
	font-size:13px;
}
#prgoress .ra_sortable_certificate_sub_item6 p{
	font-size:13px;
}
#prgoress .ra_sortable_certificate_main2_sub_item_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
#prgoress .ra_sortable_header_area_menu ul li{
	margin-left:3px;
}
.fosi{
	font-size:12px;
	margin-right:15px;
}
.dsflex{
	display:flex;
}
#learning .ra_sort_left_area_body {
    flex: 0 0 20%;
}
#learning .ra_sortable_learning_page_area .ra_sortable_certificate_main {
    flex: 0 0 18%;
}
#learning .class_area_sort_flex{
	display: flex;
    align-items: center;
    justify-content: flex-start;
}
#learning .ra_sortable_certificate_main2 {
    flex: 0 0 46%;
}
#learning .ra_sortable_body_main_area .contact-button a {
    padding: 4px 10px;
    font-size: 11px;
}
#learning .ra_sortable_right_btn_area1{
	flex: 0 0 8%;
	margin-left:5px;
}

#learning .ra_sortable_certificate_sub_item1{
	flex: 0 0 15%;
}
#learning .ra_sortable_certificate_sub_item2{
	flex: 0 0 18%;
}
#learning .ra_sortable_certificate_sub_item3{
	flex: 0 0 15%;
}
#learning .ra_sortable_certificate_sub_item4{
	flex: 0 0 15%;
    text-align: center;
}
#learning .ra_sortable_certificate_sub_item5{
	flex: 0 0 19%;
}
#learning .ra_sortable_certificate_sub_item6{
	flex: 0 0 14%;
}
#learning .ra_sortable_certificate_sub_item5 p{
	font-size:13px;
}
#learning .ra_sortable_certificate_sub_item6 p{
	font-size:13px;
}
#learning .ra_sortable_header_area_menu ul li{
	margin-left:3px;
}
#learning .class_area_sort_flex{
	padding: 18px 15px 22px;
}
#learning .ra_sortable_area_menu_ex_area h5{
	padding-left:17px;
}
.header-top-content .dropdown-item.width-two {
    width: 223px;
    width: auto;
	display:block;
}
#learning .ra_sort_left_area_body .barra-nivel {
    background: #145b94;
}
#learning .ra_sort_left_area_body .barra-nivel-weekly {
    background: #00A2A7;
}
#learning-two .ra_science_progress_area .barra-nivel {
    background: #145b94;
}
#learning-two .ra_science_progress_area .barra-nivel-weekly {
    background: #00A2A7;
}

#learning .myclassroom-single-right-content-text {
    width: 190px;
}
#learning .myclassroom-single-left-content-image {
    position: relative;
    width: 65px;
}
#learning .myclassroom-single-left-content-course-progress p{
	font-size:8px;
}
#prgoress .ra_sort_left_area_body .barra-nivel {
    background: #145b94;
}
#prgoress .ra_sort_left_area_body .barra-nivel-weekly {
    background: #00A2A7;
}
#home .myclassroom-single-right-content {
    flex: 0 0 34%;
    padding: 20px 5px;
}
#home .myclassroom-single-left-content {
    flex: 0 0 66%;   
    padding: 20px 10px;
    transition: .3s;
}
#home .myclassroom-single-left-content-image {
    position: relative;
    width: 65px;
}
#home .myclassroom-single-left-cross-progress .barra-nivel-weekly {
    background: #00A2A7;
}
.custom_checkbox_area input[type="checkbox"] {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    opacity: 1;
    border: 2px solid #3f51b5;
    border-radius: 3px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
}
.arabic .help-contetn {
    padding-left: 0px;
    padding-right: 30px;
}
.header-bottom-content ul.arabic {
    display: flex !important;
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.header-top-right-content.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.header-top-left-content.arabic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.header-top-content.arabic{
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
}
.header-top-content .dropdown-item.width-two.arabic {
    width: 223px;
    width: auto;
    display: block;
    text-align: right;
}
.home-text-content.arabic {
    text-align: right;
}
.home-progress-content .row.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.inbox-message-header.arabic {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.inbox-message-header.arabic a{
	color: rgb(20, 91, 148);
    display: flex;
    justify-content: flex-end;
}
.inbox-messages-content.arabic .single-inbox-date-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    flex-direction: row-reverse;
}
.inbox-messages-content.arabic p{
	text-align:right;
}
.inbox-messages-content.arabic .single-inbox-messages-content.unread {
    border-left: 0px solid #C55A11;
    border-right: 2px solid #C55A11;
}
.arabic .single-top-progress-heading-text{
	text-align:right;
}
.arabic .single-top-progress-count-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 15px;
    flex-direction: row-reverse;
}
.arabic .single-top-progress-count-text h5{
	margin-left:0;
	margin-right:8px;
}
.arabic .single-top-progress-last-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .single-top-progress-last-text .single-top-progress-count-text:nth-child(2) {
    margin-left: 0;
    margin-right: 15px;
}
.myclassroom-area.arabic .myclassroom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .myclassroom-header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .myclassroom-header-left span{
	margin-left:0;
	margin-right:15px;
}
.myclassroom-area.arabic .myclassroom-header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic p.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .myclassroom-header-single img{
	margin-left: 0;
	margin-right: 25px;
}
.myclassroom-area.arabic .myclassroom-content {
    background: #FFFFFF;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    border-radius: 6px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .myclassroome-single-left-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
#home .myclassroom-area.arabic .myclassroom-single-left-content-image{
	text-align:right;
}
.myclassroom-area.arabic .myclassroom-single-left-content-top-text h1{
	text-align:right;
}
.myclassroom-area.arabic .myclassroom-single-left-content-top-text p{
	    display: flex;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .profile-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    margin-right: 15px;
    flex-direction: row-reverse;
}
.myclassroom-area.arabic .profile-right-section-content {
    margin-left: 0;
    margin-right: 10px;
}
.myclassroom-area.arabic .teacher-name p {
    line-height: 17px;
    text-align: right;
}
.myclassroom-area.arabic .myclassroom-single-right-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
#learning .mu_search_bar a {
    font-size: 14px;
    position: relative;
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
#learning .ra_learningf_header_diable_area_sub_area h1.arabic{
	text-align:right;
}
#learning .dropdown_search_flex.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
#learning .arabic .mu_dropdown_menu_content ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
#learning .arabic .mu_dropdown_menu_content ul li{
	margin-left: 20px;
	margin-right: 0px;
}
#learning .arabic .mu_dropdown_menu_content ul li.arabic{
	display: flex;
    flex-direction: row-reverse;
}
#learning .arabic  .mu_search_bar form {
    display: flex;
    justify-content: flex-start;
}
 .home-text-content.arabic h4.arabic{
	display: flex;
    text-align: right;
    flex-direction: row-reverse;
}
#learning-two .ra_courch_area_header h2.arabic{
	display: flex;
    flex-direction: row-reverse;
}
#printto h4.arabic{
	display: flex;
    flex-direction: row-reverse;
}
#learning-two .ra_resources_top h4.arabic{
	text-align:right;
}
#learning-two .ra_resources_top p.arabic{
	text-align:right;
}
#learning-two .arabic .myclassroom-header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
#learning-two .myclassroom-header.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
#learning-two .arabic .myclassroom-header-left span{
	margin-left: 0px;
	margin-right: 15px;
}
#learning-two .arabic .ra_repetable_area .single-top-progress-heading-text p{
	text-align:right;
}
#learning-two .arabic .single-top-progress-count-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 15px;
    flex-direction: row-reverse;
}
#schedule .ra_event_main_area h2.arabic {
    margin-top: 10px;
    text-align: right;
}
#schedule .mu_view_icons ul.arabic {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
#schedule .mu_view_icons ul.arabic li:first-child{
	display: flex;
    flex-direction: row-reverse;
}
#schedule .mu_view_icons ul.arabic li{
	margin-left: 20px;
	margin-right: 0px;
}
.pro_college_heading.arabic {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    flex-direction: row-reverse;
}
.arabic .ra_process_tracking_area_header_search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
}
.rt_progress_main_area.arabic {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    flex-direction: row-reverse;
}
.arabic .right_rt_progress_sys{
	margin-left: 0px;
	margin-right: 15px;
}
.rt_progress_bar_design.arabic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .rt_progress_left_side h5{
	text-align:right;
}
.arabic .rt_progress_left_side .barra-nivel {
    height: 15px;
    background: #00A2A7;
    border-radius: 50px;
    text-align: right;
    float: right;
}
.arabic .rt_progress_left_side p{
	text-align:right;
}
.arabic .rt_progress_right_side{
	margin-left: 0px;
	margin-right: 10px;
}
.pt_view_main_area.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 45px;
    flex-direction: row-reverse;
}
.arabic .pt_left_view h3{
	display: flex;
    flex-direction: row-reverse;
}
.arabic .pt_right_view {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .pt_right_view a {
    margin-left: 0px;
    margin-right: 16px;
}
.dropdown_search_flex.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.mu_dropdown_menu_content ul.arabic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
#prgoress .mu_search_bar a {
    font-size: 14px;
    position: relative;
    border: none;
    background: #05233b;
    color: #fff;
    outline: none;
    padding: 8px 15px;
    margin-left: -5px;
    border-radius: 0 3px 3px 0;
}
.student-page .pt_view_main_area.option.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.student-page #prgoress .ra_process_tracking_area .arabic .but_left_view {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.dropdown_search_flex.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.arabic .mu_dropdown_menu_content ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .mu_dropdown_menu_content ul li{
	margin-left: 0;
	margin-right: 20px;
}
.arabic .mu_search_bar form {
    display: flex;
    justify-content: flex-start;
}
.arabic .mu_dropdown_menu_content ul li.arabic{
	display: flex;
    flex-direction: row-reverse;
}
#resources .arabic .mu_dropdown_menu_content ul li {
     margin-left: 20px; 
     margin-right: 0; 
}
.mu_messages.arabic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #145B94;
    padding-bottom: 10px;
    flex-direction: row-reverse;
}
.arabic .mu_messages_left {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .mu_messages_left img{
	margin-left:10px;
	margin-right:0;
}
.arabic .mu_li_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.mu_accordion2 .link.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.mu_accordion2 li .arabic i.fa-chevron-down{
	left: 25px;
    right: auto;
}
.mu_chart.arabic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}
.arabic .mu_chart_left {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.arabic .mu_chart_left img{
	margin-left:10px;
	margin-right:0;
}
.arabic .mu_chart_right{
	margin-right:12px;
	margin-left:0;
}
.mu_filter_announcement p.arabic{
	display: flex;
    flex-direction: row-reverse;
}
.student_action_heading.arabic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: row-reverse;
}
.arabic .st_single_action h5{
	padding-right: 20px;
    padding-left: 0;
    text-align: right;
}
 .arabic .student-information-single-info p{
	display: flex;
    flex-direction: row-reverse;
}
 .arabic .student-information-single-text-heading h3{
	text-align:right;
}
.alvs p{
	font-size:12px;
}
.alvs p:last-child{
	font-size: 12.5px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.header-logo-dropdown .dropdown.arabic {
    display: flex;
    justify-content: flex-end;
}
.header-logo-dropdown .arabic #dropdownMenuButton {
    display: inline-flex;
    flex-direction: row-reverse;
}
.arabic .down-icon img{
	left:0px; 
}
.arabic  .logo-sch .text-truncate {
       margin-right: 16px!important;
    margin-left: 34px!important;

}

a.sendE:not([href]):not([tabindex]) {
    color: #0078bf;
   cursor:pointer;
}
a.sendE:not([href]):not([tabindex]):focus, a.sendE:not([href]):not([tabindex]):hover{
	 text-decoration: underline!important;
}
 #user-tags .btn-sec,#user-tags .btn-sec.btn_style_1:hover {
    width: 120px;
    font-weight: 300;
    color: #0078bf;
    font-size: 12px;
    background: transparent;
    border: 1px solid #0078bf;
    padding: 4px 20px;
    display: inline-block;
    border-radius: 50px;
    transition: .3s ease-in-out;
	cursor:pointer;
}
 #user-tags .btn-sec.btn_style_1, #user-tags .btn-sec:hover {
    background: #0078bf;
    color: #fff;
}
.navi-status-scholl{
	padding-left: 5px;
    font-size: 9px;
}
.shoo-status-mes{
	font-size: 10px;
    color: red;
}
.mu_people_list_left {
    flex: 0 0 84%;
}
 .mu_people_left_left{
	flex: 0 0 33%;
}
 .mu_people_left_right{
	flex: 0 0 67%;
}
#send-message{
	display: block;
    font-size: 14px;
    border: 1px solid #0078BF;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;
	margin: 0px 12px 0px 0px;
	color:#fff;
}
#send-message:hover{
	color: #0069d9;
    background-color: #fff;
    border-color: #0062cc;
}
 .field-icon-new {
    float: right;
    position: relative;
    z-index: 2;
    top: -30px;
    right: 10px;
    cursor: pointer;
}
#studentInfo .myclassroom-single-right-right-content .dropdown #launchcourse {
    padding: 6px 24px;
    border-radius: 25px;
    font-weight: 300;
    margin-left: 14px;
}
#studentInfo .action-button-arrow i {
    font-size: 15px;
    right: 8px;
    bottom: 9px;
    position: absolute;
}
#dv-actions{
	display: flex;
    margin: 10px 0;
    justify-content: flex-end;
}
#studentInfo .student-information-heading-profile.name {
    position: relative;
    width: 70%;
    height: 60px;
	display: flex;
    align-items: first baseline;
}
#studentInfo .student-information-edit img.edit {
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 38px;
}
#studentInfo .learning-course-progress-single-content.white .view-content {
    padding: 15px;
    position: relative;
    min-height: 180px;
}
#studentInfo .learning-course-progress-single-content.white .inbox-message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
#studentInfo .inbox-message-left-header h1.title-port {
    font-size: 15px;
    color: #05233b;
    text-transform: capitalize;
    line-height: 1.2;
    font-weight: 500;
}
#studentInfo .student-information-single-info p {
    font-weight: 400;
    font-size: 12px;
    color: #3f4254;
    text-transform: inherit;
    padding-bottom: 8px;
}
#studentInfo .student-information-single-info p span {
    padding-left: 0;
}
#studentInfo .student-information-single-info .info span.txt-f600 {
    font-weight: 600;
}
#studentInfo  .learning-course-progress-single-content {
    flex: 0 0 24%;
}
#studentInfo .student-information-heading-right a {
    font-weight: 300;
    font-size: 12px;
    color: #0078bf;
    text-transform: inherit;
}
#studentInfo .myclassroom-single-right-right-content .dropdown-menu.show {
    left: inerhit!important;
}
.arabic .dropdown-item {
    text-align: right;
}
 #studentInfo .arabic .student-information-single-info p span, #studentInfo .student-information-single-info p.ar-txtp span {
    padding-left: 10px!important;
	text-align:right;
}
.txt-arc-ri{
	text-align: right;
    display: block;
}
.contact-button3n.btn-join a {
    width: 94px!important;
}
.arabic .la-ar{
    display: flex;
    flex-direction: row-reverse;
}
.arabic .single-myclass-right-content {
    width: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: first baseline;
}
#home .profile-right-section-content {
    margin-top: 8px;
    margin-left: 1px;
}
#home .profile-left-section-content img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
#home .myclassroom-single-left-content:hover {
 /* background: #E4E6E7;*/
 background: initial!important;
}

#home .myclassroom-single-right-content-text {
   cursor: pointer;
}
#home .myclassroom-single-right-content-text:hover {
  background: #E4E6E7;
}
.change-password-submit-button input.red {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    background: #e70b0b;
    border: none;
    padding: 5px 25px;
    border-radius: 30px;
    text-align: center;
    border-radius: ;
    order: ;
    border: 1px solid #e70b0b;
    transition: .3s;
}
.change-password-submit-button input.red :hover {
    background: transparent;
    color: #e70b0b;
  }
  .tippy-content .event-tooltip-content {
    text-align: left;
    background-color: aliceblue;
    font-size: 12px;
    padding: 5px;
	border-radius: 5px;
    border: 1px solid #fff;
    border: 1px solid #b4bcea;
}
#content-menu-object .custom-checkbox {
    display: contents;
    position: relative;
}
#home .single-myclassroom-slider-content {
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 15px;
    transition: .3s;
    width: 100%;
    display: inline-table;
}
 #form-edit-student .student-information-left-heading-image.ar-flex-end, .arabic .request-enrollment-main-content-heading p.ticket-header {
    display: flex;
    justify-content: flex-end;
}
.arabic #form-edit-student .student-information-edit img {
    width: 20px;
    position: absolute;
    bottom: 37px;
    right: 16px;
	left: unset;
}
.arabic #form-edit-student .change-password-submit-button{
	display: flex;
    justify-content: flex-end;
}
.b{
	z-index: 99999!important;
}
.massage-status{
  background: red;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin-left: 2px;
  min-width:13px;
}
.icon-copy{
	margin-left: 7px;
  color: blue;
  cursor:pointer;

}
.dis-role-tag-check{
	display: flex;
    justify-content: space-between;
}
.font500{
	font-weight:500;
}
.student-page #request-com .btn-sec {
  width: 120px;
  font-weight: 300;
  color: #0078bf;
  font-size: 12px;
  background: transparent;
  border: 1px solid #0078bf;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: .3s ease-in-out;
}
.student-page #request-com .btn-sec.btn_style_1, .student-page #request-com .btn-sec:hover {
  background: #0078bf;
  color: #fff;
}
.pdt20{
	padding-top: 20px!important;
}
.pdt40{
	padding-top: 40px!important;
}
.lh24px{
	line-height:24px;
}
 .login-google button{
  background-color: rgb(255, 255, 255)!important;
  display: inline-flex!important;
  align-items: center!important;
  color: rgba(0, 0, 0, 0.54)!important;
  box-shadow: rgba(0, 0, 0, 0.41) 0px 2px 2px 0px, rgba(0, 0, 0, 0.41) 0px 0px 1px 0px!important;
  padding: 0px!important;
  border-radius: 5px!important;
  border: 1px solid #3F4254!important;
}
.buttongoogle{
	background-color: rgb(255, 255, 255); display: inline-flex; align-items: center; color: rgba(0, 0, 0, 0.54); box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; padding: 0px; border-radius: 2px; border: 1px solid transparent; font-size: 14px; font-weight: 500; font-family: Roboto, sans-serif;
}
span.spgoog{
	padding: 10px 10px 10px 0px; font-weight: 500;
}
div.digoog{
	margin-right: 1px; background: rgb(255, 255, 255); padding: 10px; border-radius: 2px;
}
#message .mu_profile_img_area_content p img{
	/*width:100%; !important;*/
}
#tblidstudenr .sb-avatar__text {
    border: 2px solid #fff;
}
.text-blue{
	color:blue;
}
#edit-request-template #div-from-url .select-multi{
	width:95%!important;
}
#edit-request-template .div-ct-retem{
	display: flex;
    align-items: center;
    justify-content: space-between;

}
#request-rule .select-status
{
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 8px 0 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width:80%;
}
#request-com .select-role
{
  background: #fff;
  border: 1px solid;
  padding: 10px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 100%;
  height:40px;
}
.pdfViewer .page canvas
{
  margin: 20px!important;
  display: block;
}
.student-page .action-button-arrow i {
  font-size: 15px;
  right: 8px;
  bottom: 9px;
  position: absolute;
}
span.letter-grade{
  font-size: 15px!important;
  font-weight: 600!important;
  margin-right:1px;
}
.student-page #response {
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  background: #30bf39;
  border: 1px solid #baf0bd;
  padding: 4px 20px;
  display: inline-block;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
}
.fa-eye-pw{
	position: absolute;
    margin-left: -30px;
    margin-top: 8px;
	cursor:pointer;
	 z-index: 2;
}
.fa-eye-reset{
	float: right;
	  position: relative;
	  z-index: 2;
	  top: -30px;
	  right: 10px;
	  cursor: pointer;
}
.lear-foot a{
	font-size: 13px;
    color: #242323;
}
.ico-bage {
    height: 47px;
    width: auto;
   
}
#learning #title-learning ul li {
  font-weight: 600;
}
 #btn-run-edit:not(:disabled) {
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  color: #FFFFFF;
  background: #0078BF;
  border: none;
  padding: 5px 25px;
  border-radius: 30px;
  text-align: center;
  border-radius: ;
  order: ;
  border: 1px solid #0078BF;
  transition: .3s;
}
 #btn-run-edit:hover {
	background: transparent;
  color: #0078BF;
 }
 .myclassroom-single-left-content-top-text .linkcur{
	cursor: pointer; 
 }
 .myclassroom-single-left-content-top-text .linkcur:hover{
	text-decoration: underline; 
 }
 .student-page .view-page {
  color: #3F4254;
  font-size: 12px;
  font-weight: 300;
  margin-right: 5px;
}
.student-page .select-page {
  background: #FFF;
  border: 1px solid #A6ADB1;
  padding: 8px 0px 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 50px;
}
.student-page .div-view-page {
  margin-right: 10px;
}
.student-page .mu_main_bottom_next_change.message {
  display: flex;
  align-items: center;
  justify-content: flex-end!important;
}
#message .mu_main_bottom_next_change.message {
  display: flex;
  align-items: center;
  justify-content: flex-end!important;
}
#message .mu_main_bottom_next_change.message .select-page {
  background: #fff;
  border: 1px solid #a6adb1;
  padding: 8px 0 7px 3px;
  outline: none;
  color: #505355;
  font-size: 14px;
  border-radius: 3px;
  width: 50px;
}