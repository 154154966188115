::-webkit-scrollbar {
  width: 6px; height: 6px;
}
::-webkit-scrollbar-track {
  background: #FFFFFF;
}
::-webkit-scrollbar-thumb {
  background: #bebebe;   border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8b8b8b;
}
.teacher-page .single-attendance-header-present-content{
  flex: 0 0 16.5% !important;
}
.teacher-page .attendance-student-single-shortable.new-class{
  flex: 0 0 9% !important;
}
.teacher-page .single-attendance-student-profile-left-content{
  flex: 0 0 100% !important;
}
.teacher-page .single-attendance-student-profile-right-content{
  flex: 0 0 50% !important;
}
.teacher-page .attendance-student-single-shortable, .teacher-page .single-attendance-student-profile-content{
  flex: 0 0 40% !important;
}
.teacher-page .single-attendance-student-count-content{
  flex: 0 0 9.5% !important;
}
.teacher-page .single-attendance-student-two-content{
  flex: 0 0 55% !important;
}
.btn-nofocus:focus{
  box-shadow: none !important;
}
.btn-nofocus:hover{
  box-shadow: none !important; border: none;
}
.color-hover:hover{
  /* background-color: #fef6d5; */
}
.form-control2{
  font-size: 14px;
  color: #3F4254;
  display: block;
  border: 1px solid #3F4254 !important;
  width: 100%;
  max-width: 100% !important;
  outline: none;
  margin: 0px 0px !important;
}
.form-control-disabled{
  border: none !important; background-color: transparent !important;
}
input.form-control::placeholder {
  color: #bbb !important;
}
.bg-gray{
  background-color: #ccc !important;
  color: #000 !important;
}
.bg-orange{background-color: #C55A11; color: #fff;}
.bg-bold-red{background-color: #790000; color: #fff;}
.mandatory-viewing{
  position: fixed; top: 0; left: 0; right: 0; bottom: 0;
  width: 100%; height: 100%; background-color: #fff; z-index: 999999;
  display: flex; justify-content: center; padding: 20px 0 20px 0; overflow: auto;
}
.main-viewing{
  max-width: 900px; width: 100%;
}
.mandatory-footer{
  display: flex; justify-content: flex-end; align-items: center; padding: 20px 0 20px 0;
}
.div-table .table-responsive{
  border: none !important;
}
/* //////////////////////////// */
.custom-table{
  width: 100%; background-color: transparent; border: none !important;
}
.custom-table-body{
  background-color: #FFFFFF; border-radius: 4px;
}
.custom-table-head{
  background-color: transparent;
}
.custom-table-head th{
  background-color: #E4E6EF; padding: 12px 5px;
  font-weight: 600; font-size: 13px; line-height: 13px; color: #3f4254;
  border: none !important; font-family: Source Sans Pro;
  vertical-align: top;
}
.custom-table-row{
  background-color: #fff; 
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%)
}
.custom-table-row td{
  background-color: #FFFFFF; padding: 12px 5px;
  border: none !important; font-weight: 400; font-size: 13px;
  /* word-break: break-word; */
}
.custom-table-row td:first-child, .custom-table-head th:first-child{
  padding-left: 8px;
}
.custom-table-row td:last-child, .custom-table-head th:last-child{
  padding-right: 8px;
}
/* //////////////////////////// */
.select-multi .css-yk16xz-control{
  height: auto;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
/* /////////////////////////// */
.present, .absent, .absent-request, .excused, .holiday, .tardiness{
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #333;
  background: transparent;
  padding: 4px 5px;
  border: 1px solid #333;
  border-radius: 50px;
  width: 80px;
  margin: 0 5px;
  white-space: nowrap;
}
.present{
  border-color: #2ab34b;
}
.absent{
  border-color: #f72020;
}
.absent-request{
  border-color: #ff9500; width: 120px;
}
.excused{
  border-color: #f5cc00;
}
.holiday{
  border-color: #0772de;
}
.tardiness{
  border-color: #fcb147;
}
/* /////////////////////////// */
.active-present, .present:hover{
  background-color: #2ab34b !important; color: #FFFFFF !important;
}
.active-absent, .absent:hover{
  background-color: #f72020 !important; color: #FFFFFF !important;
}
.active-absent-request, .absent-request:hover{
  background-color: #ff9500 !important; color: #FFFFFF !important;
}
.active-excused, .excused:hover{
  background-color: #f5cc00 !important; color: #FFFFFF !important;
}
.active-holiday, .holiday:hover{
  background-color: #0772de !important; color: #FFFFFF !important;
}
.active-tardiness, .tardiness:hover{
  background-color: #fcb147 !important; color: #FFFFFF !important;
}
/* /////////////////////////// */
.modal-page{
  background: #FFFFFF;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 4%;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  overflow: auto;
}
.school-setup-menu-wrapper{
  width: 100%; background-color: #fff; box-shadow: 0 0 4px rgb(0 0 0 / 10%); border: 1px solid #ededed;
}
.school-setup-menu-header{
  width: 100%; padding: 10px 8px; background-color: #f4f4f4; font-size: 14px;
}
.school-setup-menu{
  /* padding: 10px 0px */
}
.school-setup-menu-link{
  width: 100%; padding: 10px; color:#333; font-size: 14px; cursor: pointer;
}
.school-setup-menu-link:hover{
  background-color: #f1f1f1;
}
.school-setup-menu-link.active{
  background-color: #dbe6ee;
}
.cursor-pointer{ cursor: pointer }
.cursor-move{ cursor: move }
.border-dotted{position: relative;}
.border-dotted:after {
  width: 100%; height: 100%; content: "";
  position: absolute; top: 0px; left: 0px; z-index: 1;
  border: 2px dotted #ccc;
}
.border-dashed{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px dashed #ccc;
}
.border-solid{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #0078bf;
}
.fixed-loading{
  position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);
  z-index: '9999999999999999 !important'
}
.dropdown-scroll{
  height: 600px; overflow: auto; display: block !important;
}
.active .tick-mark::before, .active .tick-mark::after{
  background-color: rgb(25, 179, 71);
}
.tick-mark{
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 2px;
  background-color: #ccc;
  content: "";
  transform: translateX(5px) rotate(-45deg);
  transform-origin: left bottom;
}
.tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  content: "";
  transform: translateX(5px) rotate(-45deg);
  transform-origin: left bottom;
}
.ilpd-course-list, .ilpd-course-list ul, .ilpd-course-list-inline, .ilpd-course-list-inline ul{
  width: 100%; background-color: #fff;
  margin: 10px 0px 0px 0px; padding: 0px;
  display: flex; align-items: center; justify-content: flex-start;
}
.ilpd-course-list-inline ul{
  display: -webkit-inline-box; overflow: auto;
}
.active-title{ background-color: #dbe6ee;}
.course-list{
  width: 100%; background-color: #dbe6ee;
  margin: 0px 0px 0px 0px; padding: 10px;
  height: 800px; overflow: auto;
}
.ilpd-course{
  max-width: auto; width: 100%; height: auto; float: left;
  margin: 0px 10px 10px 0px; padding: 10px;
  font-size: 12px; word-break: break-all;
  background-color: #fff; border: 2px solid #a4bbcc;
  border-left: 10px solid #a4bbcc;
}
.ilpd-course-empty{
  border: 2px dashed #a4bbcc; border-left: 10px solid #a4bbcc;
}
.ilpd-course:hover, .ilpd-course-empty:hover{
  box-shadow: 0 0 0 0.2rem #136ccb47;
}
.ilp_detail.active{
  box-shadow: 0 0 0 0.2rem #136ccb94;
}
.list-dots{
  display: flex; align-items: center; justify-content: start;
}
.credit-dot, .half-credit-dot, .credit-dot-empty{
  width: 10px; height: 10px; border-radius: 10px; background-color: #0772de; margin-left: 5px;
}
.half-credit-dot{
  width: 5px; height: 10px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.credit-dot-empty{
  background-color: #fff; border: 1px solid #aaa;
}
.list_document_items_area{
  word-break: break-word;
}
/* /////////////////////////////////////// */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0px;
  min-height: 18px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #FFFFFF;
}
/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #1c84fb;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  top: 2px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* /////////////////////////////////////// */
.text-lowercase { text-transform: lowercase;}
.text-no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.label.label-inline {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}
.label.label-light-primary {
  color: #6993FF;
  background-color: #E1E9FF;
  font-size: 11px;
}
.menu-right-wrapper{
  width: 100%; background-color: #fff; box-shadow: 0 0 4px rgb(0 0 0 / 10%); border: 1px solid #ededed;
}
.menu-right-header{
  width: 100%; padding: 10px 8px; background-color: #f4f4f4; font-size: 14px;
}
.menu-right{
  width: calc(100% - 10px);
  margin: 3px 0 3px 5px;
  border-radius: 3px;
  background-color: #eaefff;
}
.menu-right.active{
  background-color: #fef6d5;
}
.menu-right.level-2 {
  width: calc(100% - 25px);
  margin: 3px 0 3px 20px;
  background-color: #eff0f5;
}
.level-2 .menu-right-link{
  font-size: 13px;
}
.menu-right-link{
  width: 100%; padding: 10px; color:#333; font-size: 14px; cursor: pointer;
}
.menu-right:hover{
  background-color: #fef6d5; border-radius: 3px;
}
.btn-template-building-wrapper {
  width: auto; height: auto; border: 1px solid #3F4254; border-radius: 5px; overflow: hidden;
}
.btn-template-building {
  font-size: 16px; padding: 5px 15px;
}
.border-right {
  border-right: 1px solid #3F4254;
}
.btn-template-building:hover {
  background-color: #eeeeee;
}
.btn-template-building.active {
  color: #FFFFFF; background-color: #3f4254;
}
.btn-template-building-border-right:last-child {
  border-right: none;
}
.label-btn-template-building {
  font-size: 13px; font-weight: 500; color: #3d5468;
}
.custom-editor-class{
  min-height: 100px !important;
  max-height: 200px !important;
  background-color: #fff;
  padding: 0 10px;
}